import React from 'react';
import {
  DatePicker,
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

function ManualEntryDatePicker({
  value,
  onChange,
  allowFuture = false,
}) {
  const momentValue = value ? moment(value) : null;
  const onDateChange = (momentInstance) => {
    onChange(momentInstance.format('YYYY-MM-DD'));
  };

  return (
    <DatePicker
      disabledDate={allowFuture ? null : (current) => current > moment()}
      allowClear={false}
      value={momentValue}
      onChange={onDateChange}
    />
  );
}

ManualEntryDatePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  allowFuture: PropTypes.bool,
};

ManualEntryDatePicker.defaultProps = {
  value: undefined,
  allowFuture: false,
};

export default ManualEntryDatePicker;
