import React, { useMemo } from 'react';
import { Select, Space } from 'antd';
import PropTypes from 'prop-types';

export default function TableTimePreload({
  configProps,
  updateConfig,
  sections = [],
}) {
  const dateTimeOptions = useMemo(() => (
    sections?.reduce((acc, section) => {
      const { fields = [], name: sectionName } = section;
      return acc.concat(
        fields?.reduce((fieldAcc, field) => {
          const {
            id: fieldId,
            selectedType,
            configProps: {
              title: fieldTitle,
              hideTime,
              hideDate,
            } = {},
          } = field;
          if (selectedType !== 'dateTime' || hideTime || !hideDate) return fieldAcc;
          return fieldAcc.concat([{
            label: `${sectionName} - ${fieldTitle}`,
            value: fieldId,
          }]);
        }, []) ?? [],
      );
    }, []) ?? []
  ), [sections]);

  const startOptions = useMemo(() => (
    dateTimeOptions.filter((option) => option.value !== configProps.preloadEntryEndTimeField)
  ), [dateTimeOptions, configProps]);

  const endOptions = useMemo(() => (
    dateTimeOptions.filter((option) => option.value !== configProps.preloadEntryStartTimeField)
  ), [dateTimeOptions, configProps]);

  const updateValue = (key) => (value) => {
    updateConfig({ [key]: value });
  };

  return (
    <Space direction="vertical">
      <Space>
        Preload Start Time
      </Space>
      <Space>
        <Select
          options={startOptions}
          style={{ width: 300 }}
          allowClear
          placeholder="Select a Time Field"
          value={configProps.preloadEntryStartTimeField}
          onChange={updateValue('preloadEntryStartTimeField')}
        />
      </Space>
      <Space>
        Preload End Time
      </Space>
      <Space>
        <Select
          options={endOptions}
          style={{ width: 300 }}
          allowClear
          placeholder="Select a Time Field"
          value={configProps.preloadEntryEndTimeField}
          onChange={updateValue('preloadEntryEndTimeField')}
        />
      </Space>
    </Space>
  );
}

/* eslint-disable react/forbid-prop-types */
TableTimePreload.propTypes = {
  configProps: PropTypes.object,
  updateConfig: PropTypes.func.isRequired,
  sections: PropTypes.array,
};

TableTimePreload.defaultProps = {
  configProps: {},
  sections: [],
};
