import React from 'react';
import { Form } from 'antd';

import OnTraccrNumberInput from '../common/inputs/OnTraccrNumberInput';
import DisplayText from '../common/text/DisplayText';

import { currencyFormatter } from '../helpers/inputParsers';

export default ({
  title,
  name,
  isNotDisplay,
  onChange,
  value,
}) => {
  const displayValue = value ? currencyFormatter(value) : 'N/A';
  return (
    <Form.Item 
      name={name}
      label={title}
      style={{ marginBottom:0 }}
    >
      {isNotDisplay ? <OnTraccrNumberInput
        min={0}
        prefix='$'
        precision={2}
        formatter={currencyFormatter}
        parser={v => v.replace(/\$\s?|(,*)/g, '')}
        style={{ width: '100%' }}
        onChange={onChange}
      /> : <DisplayText title={displayValue}/>}
    </Form.Item>
  )
}