import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { DatePicker, Radio } from 'antd';
import DateFilter from '../../datePicker/DateFilter'
import { getDateRangeFromConfig } from '../../analytics/analytics.helpers'

export default ({
  onTypeChange,
  selectedType,
  range,
  onChange,
  onRangeChange,
  hideCustom = false,
  allowClear = true,
  renderDropdownInBody = false,
}) => {
  const [datePreset, setDatePreset] = useState(undefined);
  const [open,setOpen] = useState(false);

  const onPresetClicked = useCallback((datePreset) => {
    setDatePreset(datePreset)
    const presetRange = getDateRangeFromConfig({ dateRange: range, datePreset })
    onRangeChange(presetRange)
    onTypeChange('custom');
    setOpen(false);
  },[datePreset, onTypeChange, range, onRangeChange]);

  const group = [{
    label:'Pay Period', value:'payPeriod',
  },{
    label:'Week', value:'week',
  }];
  if (!hideCustom) {
    group.push({
      label:'Custom', value:'custom'
    });
  }
  const radios = (
    <Radio.Group
      options={group}
      onChange={(e) => {
        const {
          target:{ 
            value: type,
          } = {},
        } = e;
        onTypeChange(type);
        if (type !== 'custom') {
          setDatePreset(undefined);
        }
      }}
      value={selectedType}
    />
  );

  switch (selectedType) {
    case 'payPeriod':
    case 'week':
      return <DatePicker
        style={{ width: 250 }}
        format={`[${selectedType === 'week' ? 'Week' : 'Pay Period'} of] MMM Do YY`}
        disabledDate={(current) => current > moment().endOf('day')}
        renderExtraFooter={() => (
          <>
          {radios}
          <DateFilter datePreset={datePreset} handlePreset={onPresetClicked}/>
          </>
          )}
        picker='week'
        value={range[0]}
        open={open}
        onOpenChange={setOpen}
        onChange={onChange}
        getPopupContainer={node => node && !renderDropdownInBody ? node.parentNode : document.body}        
        allowClear={allowClear}
      />
    default:
      return <DatePicker.RangePicker
        style={{ width: 250 }}
        format='MMM Do YY'
        disabledDate={(current) => current > moment().endOf('day')}
        renderExtraFooter={() => (
          <>
          {radios}
          <DateFilter datePreset={datePreset} handlePreset={onPresetClicked}/>
          </>
          )}
        value={range}
        open={open}
        onOpenChange={setOpen}
        onChange={onRangeChange}
        getPopupContainer={node => node && !renderDropdownInBody ? node.parentNode : document.body}
        allowClear={allowClear}
      />
  }  
}