import React from 'react';
import { Row, Select } from 'antd';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

import OptionalRow from './OptionalRow';
import CheckboxRow from './CheckboxRow';
import MultiSignaturePreview from './MultiSignaturePreview';
import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import ConditionalRenderingRow from './ConditionalRenderingRow';

const configure = ({
  setConfigProps,
  configProps = {},
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  projectIdMap = {},
  vendors = [],
  equipment = [],
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  id,
  formTemplates = [],
  labels = [],
  contactAddressBooks = {},
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  buckets = [],
} = {}) => {
  const {
    optional,
    hasConditionalRendering = false,
    conditionalRenderingFormula,
    isHideInPDF,
  } = configProps;

  const setTitle = (e) => {
    const {
      target:{
        value,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      title:value,
    });
  };
  const setOptional = (e) => {
    const {
      target:{
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      optional:checked,
    });
  };

  const setLinkedField = (fieldId) => {
    setConfigProps({
      ...configProps,
      linkedField:fieldId,
    });
  }

  const setConditionalRenderingFormula = (formula) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: formula,
    });
  };

  const setHasConditionalRendering = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      hasConditionalRendering: checked,
    });
  };

  const setFieldTrigger = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      fieldTrigger: checked,
    });
  };

  const setHideInPDF = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      isHideInPDF: checked,
    });
  };

  const userFields = sections.reduce((acc,{ fields = [], name: sectionName }) => (
    acc.concat(
      fields.reduce((fieldAcc, field) => {
        const { selectedType, id, configProps: { dataType, title } = {} } = field;
        if (selectedType === 'dropdown' && dataType === 'Users') {
          return fieldAcc.concat([{ value: id, label: `${sectionName} - ${title}` }]);
        }
        return fieldAcc
      },[])
    )
  ),[]);

  return (
    <div>
      <Row className='form-required-field'>
        Title:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <OnTraccrTextInput
          textarea placeholder='Insert title here'
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <OptionalRow onChange={setOptional} optional={optional} disabled={disableOptional}/>
      <ConditionalRenderingRow
        onChange={setHasConditionalRendering}
        onFormulaChange={setConditionalRenderingFormula}
        id={id}
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
        sections={sections}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
      />
      <CheckboxRow
        title="Hide in PDF"
        checked={isHideInPDF}
        onChange={setHideInPDF}
        hoverText="Check this to hide this field in the PDF preview and export"
      />
      <Row className='form-required-field' style={{ marginTop: 15 }}>
        User Dropdown Field:
      </Row>
      <Row style={{ marginTop: 10 }} gutter={10}>
        <Select
          style={{ width: '100%', margin:'0px 10px'}}
          placeholder='Select user field'
          onSelect={setLinkedField}
          value={configProps.linkedField}
          optionFilterProp='label'
          options={userFields}
        />
      </Row>
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={setFieldTrigger}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
    </div>
  )
};

export default {
  configure,
  preview: (props) => <MultiSignaturePreview {...props}/>,
  title:'Multi Signature Field',
  description:'Collect signatures from multiple users'
};
