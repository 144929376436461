import { Common, TaskHelpers } from 'ontraccr-common';
import { DateTime } from 'luxon';
import { message } from 'antd';

import { timeKeyToDBKey } from '../state/Timecard.constants';

export default {};

export const getRuntimeInMillis = (entry = {}) => {
  const runTimeMs = TaskHelpers.getRuntimes(entry, false);
  const {
    regularTime,
    breakTime,
    overtime,
    doubleOT,
    pto,
  } = runTimeMs;

  const regularMillis = regularTime ? TaskHelpers.convertMillisecondsToHours(regularTime) : null;
  const breakMillis = breakTime ? TaskHelpers.convertMillisecondsToHours(breakTime) : null;
  const overtimeMillis = overtime ? TaskHelpers.convertMillisecondsToHours(overtime) : null;
  const doubleOTMillis = doubleOT ? TaskHelpers.convertMillisecondsToHours(doubleOT) : null;
  const ptoMillis = pto ? TaskHelpers.convertMillisecondsToHours(pto) : null;

  return {
    regularTime: regularMillis,
    breakTime: breakMillis,
    overtime: overtimeMillis,
    doubleOT: doubleOTMillis,
    pto: ptoMillis,
  };
};

export const convertRuntimesToDecimals = (runTimes) => {
  const innerDecimals = {};
  Object.entries(runTimes).forEach(([key, value]) => {
    if (Common.isNullOrUndefined(value) || value === '') {
      innerDecimals[key] = null;
      return;
    }
    const hours = Math.floor(value);
    const minutes = (value * 60) % 60;
    const parsedValue = Number((hours + (minutes / 60))?.toFixed(2));
    innerDecimals[key] = Number.isNaN(parsedValue) ? null : parsedValue;
  });

  return innerDecimals;
};

export const updateAggregateValues = (acc, value, key) => {
  if (!Common.isNullOrUndefined(value)) {
    acc[key] = Common.isNullOrUndefined(acc[key])
      ? value
      : value + acc[key];
  }
};

/**
 * Takes an array of distributionRows and distributes the runtimes based on the initialStartTimes
 * of each hour type of the original entry
 * @param {array} initialStartTimes - an object with the initial start times for each hour type
 *
 * {regularTime: DateTime, breakTime: DateTime, overtime: DateTime, doubleOT: DateTime}
 * @param {array} distributionRows - an array of distribution rows
 *
 * {regularTime: millis, breakTime: millis, overtime: millis, doubleOT: millis}
 * @param {object} entry - the original entry
 *
 * @param {object} initialEntryIds - IDs of existing entries
 *
 * @returns {array} - an array of new entries with the distributed runtimes
 */
export const distributeRuntimes = ({
  initialStartTimeMap = {},
  distributionRows = [],
  entry = {},
  initialEntryIds = new Set(),
}) => {
  const newEntries = {};
  let hasError = false;

  Object.entries(timeKeyToDBKey).forEach(([key, value]) => {
    if (hasError) return;
    distributionRows.forEach((row) => {
      if (hasError) return;
      const taskDate = TaskHelpers.getTaskDate(entry);
      if (!taskDate) {
        hasError = true;
        message.error('Failed to distribute hours');
        return;
      }

      const dayStart = taskDate.startOf('day');

      const offset = initialStartTimeMap?.[row.id]?.[key];
      if (initialEntryIds.has(row.id) && !offset?.isValid) return;
      const ourStart = offset ?? dayStart;
      if (!newEntries[row.id]) {
        newEntries[row.id] = {
          id: row.id,
          costcodeId: row.costcodeId,
          note: row.note,
          groupId: entry.groupId ?? entry.id,
        };
      }

      const startKey = value === '' ? 'startTime' : `${value}StartTime`;
      const endKey = value === '' ? 'endTime' : `${value}EndTime`;

      // Grab hour and minute offsets of current row's time
      const time = row[key];
      const currentTime = time ? DateTime.fromMillis(time) : null;
      const { hour, minute } = currentTime || {};

      if (hour || minute) {
        // Calculate new start and end times based on offset
        const newTime = ourStart.plus({
          hours: hour,
          minutes: minute,
        });

        // Set startTime and endTime for the current entry
        const startTime = ourStart.toMillis();
        const endTime = newTime.toMillis();

        newEntries[row.id][startKey] = startTime;
        newEntries[row.id][endKey] = endTime;
      } else {
        newEntries[row.id][startKey] = null;
        newEntries[row.id][endKey] = null;
      }
    });
  });

  if (hasError) return null;
  return Object.values(newEntries) ?? [];
};
