import React, { useState, useEffect } from 'react';
import ClockText from './ClockText';

import { TaskHelpers } from 'ontraccr-common';

import {
} from '../../helpers/time';

import { useInterval } from '../../helpers/helpers';

import colors from '../../constants/Colors';

const colorFromTask = (task = {}) => {
  if(!task.startTime || (task.startTime && task.endTime)) return colors.ONTRACCR_OPACITY_GRAY;
  if(task.type === 'break') return colors.ONTRACCR_DARK_YELLOW;
  return colors.ONTRACCR_BLACK;
};

const getRuntime = (day) => {
  const {
    currentTask,
  } = day;

  if(!currentTask || !currentTask.type || !currentTask.startTime) return '00:00:00';
  const {
    currentTime,
  } = TaskHelpers.getClockTimes({ formattedDay: day });
  return currentTime;
}

export default ({
  style = {},
  formattedDay = {},
}) => {
  const [runtime,setRuntime] = useState('00:00:00');
  const [color,setColor] = useState(colors.ONTRACCR_OPACITY_GRAY);
  const [tick,setTick] = useState(null);

  useEffect(() => {
    const {
      currentTask
    } = formattedDay;
    if(currentTask) {
      const {
        startTime,
        endTime,
        type = 'work',
      } = currentTask;
      const isRunning = startTime && !endTime && type !== 'break' && type !== 'pto';
      setColor(colorFromTask(currentTask));
      setRuntime(getRuntime(formattedDay));
      const newTick = isRunning ? 900 : null;
      if(newTick !== tick) setTick(newTick);
    }
  }, [formattedDay, tick]);

  useInterval(() => {
    setRuntime(getRuntime(formattedDay));
  },tick);

  return (
    <ClockText
      style={style}
      runtime={runtime}
      color={color}
    />
  );
};
