/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import DynamicAttributeConfigure from './DynamicAttributeConfigure';
import DynamicAttributePreview from './DynamcAttributePreview';

export default {
  configure: DynamicAttributeConfigure,
  preview: (props) => <DynamicAttributePreview {...props} />,
  title: 'Dynamic Attribute Field',
  description: 'Use this field to pull in custom fields from linked items',
};
