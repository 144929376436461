import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Button, Col, Drawer, Row, Select, Table, Typography,
} from 'antd';
import { TaskHelpers } from 'ontraccr-common';
import { useSelector } from 'react-redux';
import { isNullOrUndefined } from 'ontraccr-common/lib/Common';
import { DeleteOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import { getCostcodeOptions } from '../../clock/ManualEntry/manualEntryHelpers';
import { formatProjectLabelFromCompanySettings } from '../../projects/projectHelpers';
import HoursRedistributionDisplay from './HoursRedistributionDisplay';
import { timeKeyToTitle } from '../state/Timecard.constants';
import DisplayText from '../../common/text/DisplayText';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import { getIdMap, uuid } from '../../helpers/helpers';
import { prepareRedistributionPayload } from '../timecard.helpers';
import { generateResponseMap } from '../../forms/ResponderHelpers';
import Colors from '../../constants/Colors';
import { sortByStartTime } from '../../helpers/tasks';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';

import {
  convertRuntimesToDecimals,
  getRuntimeInMillis,
  updateAggregateValues,
  distributeRuntimes,
} from './hoursRedistribution.helpers';

const { Text } = Typography;

export default function HoursRedistributionDrawer({
  visible,
  entry,
  initialEntries,
  onClose,
  setEntries,
  customDataMap,
}) {
  const {
    id,
    userId,
    projectId,
    phaseId,
  } = entry ?? {};

  const projects = useSelector((state) => state.projects.projects);
  const phases = useSelector((state) => state.costcodes.phases);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const users = useSelector((state) => state.users.users);
  const { settings = {} } = useSelector((state) => state.settings.company);
  const { reqCostcode } = settings;

  const luxDate = useMemo(() => TaskHelpers.getTaskDate(entry)?.startOf('day'), [entry]);

  const [distributionRows, setDistributionRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const project = useMemo(() => (
    projectId ? projects.find((p) => p.id === projectId) : null
  ), [projectId, projects]);
  const phase = useMemo(() => (
    phaseId ? phases.find((p) => p.id === phaseId) : null
  ), [phaseId, phases]);
  const user = useMemo(() => (
    userId ? users.find((u) => u.id === userId) : null
  ), [userId, users]);

  const userLabel = useMemo(() => (user ? user.name : null), [user]);
  const phaseLabel = useMemo(() => (phase ? phase.name : null), [phase]);
  const projectLabel = useMemo(() => (project
    ? formatProjectLabelFromCompanySettings({
      name: project.name,
      number: project.number,
      settings,
    })
    : null
  ), [project, settings]);

  const costcodeOptions = useMemo(() => {
    const activeCostcodes = costcodes.filter((cc) => cc.active);

    const options = getCostcodeOptions({
      activeCostcodes, activePhases: phases, projectId, phaseId: phaseId ?? 'Unphased',
    });
    return options;
  }, [projectId, costcodes, phaseId, phases]);

  const selectedCostcodeIds = useMemo(() => {
    const costcodeSet = new Set();
    distributionRows.forEach(({ costcodeId: cId }) => costcodeSet.add(cId));
    return costcodeSet;
  }, [distributionRows]);

  const filteredInitialEntries = useMemo(() => (
    initialEntries.filter((e) => (
      e.projectId === projectId && e.phaseId === phaseId
    ))
  ), [initialEntries, projectId, phaseId]);

  const initialEntryIds = useMemo(() => (
    new Set(initialEntries.map((e) => e.id))
  ), [initialEntries]);

  const filteredEntryMap = useMemo(() => (
    getIdMap(filteredInitialEntries)
  ), [filteredInitialEntries]);

  const initialStartTimeMap = useMemo(() => {
    const m = {};
    initialEntries.forEach((e) => {
      if (!e) return;

      const {
        startTime,
        breakStartTime,
        otStartTime,
        doubleOTStartTime,
        ptoStartTime,
      } = e;

      const regularTime = startTime ? DateTime.fromMillis(startTime) : null;
      const breakTime = breakStartTime ? DateTime.fromMillis(breakStartTime) : null;
      const overtime = otStartTime ? DateTime.fromMillis(otStartTime) : null;
      const doubleOT = doubleOTStartTime ? DateTime.fromMillis(doubleOTStartTime) : null;
      const pto = ptoStartTime ? DateTime.fromMillis(ptoStartTime) : null;

      m[e.id] = {
        regularTime,
        breakTime,
        overtime,
        doubleOT,
        pto,
      };
    });
    return m;
  }, [initialEntries]);

  const aggregateDecimalRuntimes = useMemo(() => {
    const acc = {};
    filteredInitialEntries.forEach((e) => {
      const rowTimes = getRuntimeInMillis(e);

      Object.keys(rowTimes).forEach((key) => {
        updateAggregateValues(acc, rowTimes[key], key);
      });
    });
    return convertRuntimesToDecimals(acc);
  }, [filteredInitialEntries, luxDate]);

  const displayFields = useMemo(() => {
    const innerDisplayFields = [];

    Object.entries(aggregateDecimalRuntimes).forEach(([key, value]) => {
      if (isNullOrUndefined(value)) return;
      const parsedTitle = timeKeyToTitle[key];

      innerDisplayFields.push({
        key,
        title: parsedTitle,
        totalTime: value,
      });
    });

    return innerDisplayFields;
  }, [aggregateDecimalRuntimes]);

  const totalDistributedHours = useMemo(() => {
    const innerTotal = {};

    distributionRows.forEach((row) => {
      Object.entries(aggregateDecimalRuntimes).forEach(([key, value]) => {
        if (isNullOrUndefined(value)) return;
        if (!(key in innerTotal)) innerTotal[key] = 0;
        const parsedValue = parseFloat(row[key]);
        innerTotal[key] += Number.isNaN(parsedValue) ? 0 : parsedValue;
      });
    });

    return innerTotal;
  }, [distributionRows, aggregateDecimalRuntimes]);

  const errorMap = useMemo(() => {
    const innerErrorMap = {};

    Object.entries(totalDistributedHours).forEach(([key, value]) => {
      const originalTotal = aggregateDecimalRuntimes[key];
      innerErrorMap[key] = Math.abs(value - originalTotal) > 0.01;
    });

    return innerErrorMap;
  }, [totalDistributedHours, aggregateDecimalRuntimes]);

  const hasCostcodeError = useMemo(() => {
    if (!reqCostcode) return false;
    const hasError = distributionRows.some((row) => !row.costcodeId);
    return hasError;
  }, [distributionRows, reqCostcode]);

  const hasErrors = useMemo(() => {
    if (hasCostcodeError) return true;
    const hasError = Object.values(errorMap).some((error) => error);
    return hasError;
  }, [errorMap, hasCostcodeError]);

  const numFilteredEntries = initialEntries.length - filteredInitialEntries.length;

  useEffect(() => {
    setDistributionRows(
      filteredInitialEntries.map((e) => ({
        id: e.id,
        note: e.note,
        costcodeId: e.costcodeId,
        ...convertRuntimesToDecimals(
          getRuntimeInMillis(e),
        ),
      })),
    );
  }, [filteredInitialEntries, luxDate]);

  const addRow = useCallback(() => {
    const newRow = {
      id: uuid(),
      costcodeId: null,
      regularTime: 0,
      breakTime: 0,
      overtime: 0,
      doubleOT: 0,
    };
    setDistributionRows([...distributionRows, newRow]);
  }, [distributionRows]);

  const updateRow = useCallback((rowId, key, value, type) => {
    const updatedRows = distributionRows.map((row) => {
      if (row.id === rowId) {
        const parsedValue = (isNullOrUndefined(value) && type === 'hour') ? 0 : value;
        return { ...row, [key]: parsedValue };
      }
      return row;
    });
    setDistributionRows(updatedRows);
  }, [distributionRows, luxDate]);

  const deleteRow = useCallback((rowId) => {
    if (rowId === id) return;
    const updatedRows = distributionRows.filter((row) => row.id !== rowId);
    setDistributionRows(updatedRows);
  }, [distributionRows, id]);

  const resetRows = useCallback(() => {
    setDistributionRows(
      filteredInitialEntries.map((e) => ({
        id: e.id,
        note: e.note,
        costcodeId: e.costcodeId,
        ...convertRuntimesToDecimals(
          getRuntimeInMillis(e),
        ),
      })),
    );
  }, [filteredInitialEntries]);

  const renderHourInlineEdit = useCallback((value, record, key) => {
    const { id: rowId } = record;

    return (
      <OnTraccrTextInput
        value={String(value)}
        onChange={(e) => {
          const { target: { value: newHours } = {} } = e;
          const [whole, decimal] = newHours?.split('.') ?? [];
          let realDecimal = decimal;
          if (realDecimal?.length > 2) {
            realDecimal = decimal.slice(0, 2);
          }
          let fullHours = '';
          if (whole?.length) fullHours += whole;
          if (newHours?.includes('.')) {
            fullHours += '.';
            if (realDecimal?.length) fullHours += realDecimal;
          }
          const newValue = fullHours ?? '0';
          updateRow(rowId, key, newValue, 'hour');
        }}
      />
    );
  }, [updateRow]);

  const renderSelectInlineEdit = useCallback((record, key) => {
    const { id: rowId, [key]: val } = record;

    const innerOnChange = (newValue) => {
      updateRow(rowId, key, newValue, 'select');
    };

    const realCostcodeOptions = costcodeOptions.filter((cc) => (
      !selectedCostcodeIds.has(cc.value) || cc.value === val
    ));

    return (
      <Select
        style={{ width: 250 }}
        value={val}
        options={realCostcodeOptions}
        onChange={innerOnChange}
        allowClear
      />
    );
  }, [costcodeOptions, selectedCostcodeIds, updateRow]);

  const columns = useMemo(() => {
    const innerColumns = [
      {
        title: 'Cost Code',
        dataIndex: 'costcode',
        key: 'costcode',
        render: (_, record) => renderSelectInlineEdit(record, 'costcodeId'),
      },
    ];

    Object.entries(aggregateDecimalRuntimes).forEach(([key, hourValue]) => {
      if (isNullOrUndefined(hourValue)) return;

      innerColumns.push({
        title: timeKeyToTitle[key],
        dataIndex: key,
        key,
        render: (value, record) => renderHourInlineEdit(value, record, key),
      });
    });

    innerColumns.push({
      title: 'Notes',
      dataIndex: 'note',
      key: 'note',
      render: (_, record) => (
        <OnTraccrTextInput
          value={record.note}
          onChange={(e) => {
            updateRow(record.id, 'note', e?.target?.value);
          }}
        />
      ),
    });

    innerColumns.push({
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      render: (_, { id: rowId }) => (
        (rowId !== id) && (
        <Button
          type="text"
          onClick={() => deleteRow(rowId)}
        >
          <DeleteOutlined style={{ color: Colors.ONTRACCR_RED }} />
        </Button>
        )),
    });

    return innerColumns;
  }, [
    aggregateDecimalRuntimes,
    deleteRow,
    renderHourInlineEdit,
    renderSelectInlineEdit,
    updateRow,
  ]);

  const onSave = useCallback(async () => {
    if (distributionRows.length === 1
      && distributionRows[0].costcodeId === entry.costcodeId
      && distributionRows[0].note === entry.note
    ) {
      onClose();
      return; // Nothing Changed
    }

    setLoading(true);

    const newPayload = prepareRedistributionPayload({
      distributionRows,
      date: luxDate,
      entry,
    });

    const newEntries = distributeRuntimes({
      initialStartTimeMap,
      distributionRows: newPayload,
      initialEntryIds,
      entry,
    });
    if (!newEntries) {
      setLoading(false);
      return;
    }

    setEntries((prev) => {
      // If user edits the entry and then redistributes hours, the custom data will be transformed
      // into a flattened object so we do not need to convert it again
      const prevEntries = prev.filter((e) => !(e.id in filteredEntryMap));

      const parsedNewEntries = newEntries.map((e) => {
        const targetEntry = filteredEntryMap[e.id] ?? entry ?? {};
        const isUpdated = initialEntryIds.has(e.id);

        const {
          [targetEntry.id]: {
            data: customData = [],
            divisionId,
          } = {},
        } = customDataMap ?? {};

        const flattenedCustomData = Array.isArray(customData)
          ? generateResponseMap(customData)
          : customData;

        // New entries must have the custom data in the flattened object format
        const newCustomData = isUpdated ? customData : flattenedCustomData;
        return {
          isUpdated,
          ...targetEntry,
          ...e,
          customData: divisionId ? newCustomData : null,
          hourBased: isUpdated ? targetEntry?.hourBased : true,
        };
      });
      const fullNew = [...prevEntries, ...parsedNewEntries];
      fullNew.sort(sortByStartTime);
      return fullNew;
    });

    setLoading(false);
    onClose();
  }, [
    entry,
    filteredEntryMap,
    distributionRows,
    initialStartTimeMap,
    onClose,
    customDataMap,
    initialEntryIds,
  ]);

  const isVisible = useMemo(() => visible && !!entry, [visible, entry]);

  return (
    <Drawer
      title="Redistribute Hours"
      visible={isVisible}
      height={550}
      width={900}
      placement="left"
      onClose={onClose}
      maskClosable
    >
      <Col
        style={{
          width: '100%',
          paddingLeft: '1em',
          paddingRight: '1em',
          gap: 24,
          display: 'flex',
          flexDirection: 'column',
        }}
      >

        <Row>
          {userLabel && (
            <Col span={6}>
              <Text style={{ fontWeight: 600 }}> User </Text>
              <DisplayText title={userLabel} />
            </Col>
          )}

          {projectLabel && (
            <Col span={6}>
              <Text style={{ fontWeight: 600 }}> Project </Text>
              <DisplayText title={projectLabel} />
            </Col>
          )}

          {phaseLabel && (
            <Col span={6}>
              <Text style={{ fontWeight: 600 }}> Phase </Text>
              <DisplayText title={phaseLabel} />
            </Col>
          )}
        </Row>

        <Row style={{ width: '100%', marginBottom: '2em' }}>
          {displayFields.map(({ key, title, totalTime }) => (
            <Col key={key} span={6}>
              <HoursRedistributionDisplay
                title={title}
                distributedHours={totalDistributedHours?.[key] ?? 0}
                originalHours={totalTime}
                hasError={errorMap[key]}
              />
            </Col>
          ))}
        </Row>

        {
          numFilteredEntries > 0 && (
            <Row style={{ width: '100%' }}>
              <Alert
                style={{ width: '100%' }}
                message={`
                  ${numFilteredEntries} entr${numFilteredEntries === 1 ? 'y' : 'ies'} 
                ${numFilteredEntries === 1 ? 'is' : 'are'}
                not displayed because ${numFilteredEntries === 1 ? 'it' : 'they'}
                ${numFilteredEntries === 1 ? 'has a' : 'have'} 
                different project${numFilteredEntries === 1 ? '' : 's'} or 
                phase${numFilteredEntries === 1 ? '' : 's'}`}
                type="warning"
                showIcon
              />
            </Row>
          )
        }

        <Row style={{ width: '100%' }}>
          <Col style={{
            width: '100%', display: 'flex', flexDirection: 'column', gap: '5px',
          }}
          >

            {Object.entries(errorMap).map(([key, hasError]) => (
              hasError && (
              <Row key={key} style={{ width: '100%' }}>
                <Alert style={{ width: '100%' }} message={`${timeKeyToTitle[key]} hours do not match`} type="error" showIcon />
              </Row>
              )
            ))}

            {hasCostcodeError && (
            <Row style={{ width: '100%' }}>
              <Alert style={{ width: '100%' }} message="Cost code is required for all distributions" type="error" showIcon />
            </Row>
            )}
          </Col>
        </Row>

        <Row>

          <Row style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '1em',
          }}
          >
            <Text style={{ fontWeight: 600 }}> Distributions </Text>
            <OnTraccrButton title="Add" onClick={addRow} type="primary" />

          </Row>

          <Table
            style={{
              margin: 'auto',
              marginBottom: '4em',
              width: '100%',
            }}
            columns={columns}
            dataSource={distributionRows}
            pagination={false}
            rowKey="id"
          />

        </Row>

      </Col>

      <DrawerSubmitFooter
        loading={loading}
        onClose={() => {
          onClose();
          resetRows();
        }}
        onAction={resetRows}
        onSubmit={onSave}
        canSubmit={!hasErrors}
        actionTitle="Reset"
      />
    </Drawer>
  );
}

HoursRedistributionDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  entry: PropTypes.shape({
    id: PropTypes.string,
    userId: PropTypes.string,
    projectId: PropTypes.string,
    phaseId: PropTypes.string,
    costcodeId: PropTypes,
    state: PropTypes.string,
    hourBased: PropTypes.bool,
    customData: PropTypes.shape({}),
    startTime: PropTypes.number,
    breakStartTime: PropTypes.number,
    otStartTime: PropTypes.number,
    doubleOTStartTime: PropTypes.number,
    ptoStartTime: PropTypes.number,
    divisionId: PropTypes.string,
    note: PropTypes.string,
  }),
  initialEntries: PropTypes.arrayOf({
    id: PropTypes.string,
    userId: PropTypes.string,
    projectId: PropTypes.string,
    phaseId: PropTypes.string,
    costcodeId: PropTypes,
    state: PropTypes.string,
    hourBased: PropTypes.bool,
    customData: PropTypes.shape({}),
    startTime: PropTypes.number,
    breakStartTime: PropTypes.number,
    otStartTime: PropTypes.number,
    doubleOTStartTime: PropTypes.number,
    ptoStartTime: PropTypes.number,
    divisionId: PropTypes.string,
    note: PropTypes.string,
  }),
  setEntries: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  customDataMap: PropTypes.object,
};

HoursRedistributionDrawer.defaultProps = {
  visible: false,
  entry: null,
  initialEntries: [],
  customDataMap: {},
};
