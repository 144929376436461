import React from 'react';
import {
  Row,
  Col,
  Select,
  Switch,
  Checkbox,
} from 'antd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { PlusOutlined } from '@ant-design/icons';

import {
  fieldOption,
} from './formFieldsHelpers';
import OptionalRow from './OptionalRow';
import TitleRow from './TitleRow';
import LabourTablePreview from './LabourTablePreview';
import CustomSavedFormTablePreview from './CustomSavedFormTablePreview';
import MaterialTablePreview from './MaterialTablePreview';
import ChangeOrderTablePreview from './ChangeOrderTablePreview';
import ShiftTablePreview from './ShiftTable/ShiftTablePreview';
import CustomTablePreview from './CustomTablePreview';
import EquipmentTablePreview from './EquipmentTable/EquipmentTablePreview';
import TimeEntryTablePreview from './TimeEntryTable/TimeEntryTablePreview';
import CostCodeTablePreview from './CostCodeTable/CostCodeTablePreview';
import RequiredColumnsCheckbox from './RequiredColumnsCheckbox';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import HoverHelp from '../../../common/HoverHelp';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';

import TableFieldColumnCard from './TableFieldColumnCard';
import SimpleTextInputModal from '../../../common/modals/SimpleTextInputModal';

import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import FieldTriggerFlag from './FieldTriggerFlag';
import ConditionalRenderingRow from './ConditionalRenderingRow';
import OnTraccrNumberInput from '../../../common/inputs/OnTraccrNumberInput';
import DropdownFieldSubDataTypeSelect from './DropdownFieldSubDataTypeSelect';
import CheckboxRow from './CheckboxRow';
import { getLinkOptions } from './Attribute.helpers';
import AttributeTablePreview from './AttributeTablePreview';
import { TablePreload } from './TablePreload/TablePreload';

const types = [
  {
    key: 'Materials',
    title: 'Materials',
    description: 'User can select from existing Materials',
  },
  {
    key: 'Labour',
    title: 'Labour Hours',
    description: 'User can select from existing shifts and labour rates',
  },
  {
    key: 'Custom',
    title: 'Custom',
    description: 'User can enter custom data into the table',
  },
  {
    key: 'ChangeOrder',
    title: 'Change Orders',
    description: 'User can select from existing Change Orders',
  },
  {
    key: 'Shifts',
    title: 'Shifts',
    description: 'User can add one or more shifts',
  },
  {
    key: 'TimeEntry',
    title: 'Time Entry',
    description: 'User can enter manual time entries',
  },
  {
    key: 'Equipment',
    title: 'Equipment',
    description: 'User can select from existing Equipment',
    hasSubDataType: true,
    subDataTypePlaceholder: 'All',
  },
  {
    key: 'CostCodes',
    title: 'Cost Codes',
    description: 'User can select from existing Cost Codes',
  },
  {
    key: 'Attribute',
    title: 'Attribute',
    description: 'Displays multi-value attributes in a table format',
  },
];

const validCustomFieldTypes = new Set([
  'yes-no',
  'dropdown',
  'text',
  'dateTime',
  'attribute',
  'staticText',
  'gpsLocation',
  'table',
]);

const materialOptions = {
  name: 'Name',
  description: 'Description',
  partNumber: 'Part Number',
  units: 'Units',
  price: 'Material Price',
  labourPrice: 'Labour Price',
  quantity: 'Quantity',
  currentQuantity: 'Current Quantity',
  quantityAllocated: 'Quantity Allocated', // [2024-10-04] Not related to field of same name from material object
  location: 'Location',
  toLocation: 'To Location',
  debitOrCredit: 'Debit/Credit',
  addedDate: 'Date Added',
  updatedDate: 'Date Updated',
  cost: 'Material Cost',
  labourCost: 'Labour Cost',
  total: 'Total Material Cost',
  labourCostTotal: 'Total Labour Cost',
  labourAndMaterialTotal: 'Material Total',
  labourPriceTotal: 'Total Labour Price',
  notes: 'Notes',
  supplier: 'Supplier',
};

const labourOptions = {
  name: 'Name',
  rate: 'Rate',
  quantity: 'Quantity',
  total: 'Total',
};

const changeOrderOptions = {
  itemNumber: 'Item Number',
  description: 'Description',
  contractAmount: 'Contract Amount',
  percentageComplete: 'Percentage Complete',
  progressToDate: 'Progress To Date',
  previousBillings: 'Previous Billings',
  invoiceAmount: 'Invoice Amount',
};

const shiftOptions = {
  title: 'Title',
  dates: 'Dates',
  users: 'Users',
  description: 'Description',
};

const getTimeEntryOptions = (t, customFields) => ({
  user: 'User',
  date: 'Date',
  type: 'Type',
  division: 'Division',
  project: t('Project'),
  phase: 'Phase',
  costcode: 'Cost Code',
  local: 'Union Local',
  class: 'Work Classification',
  sageShift: 'Sage Shift',
  hourBased: 'Hour Based',
  time: 'Time',
  note: 'Note',
  status: 'Status',
  enteredVia: 'Entered Via',
  formattedDuration: 'Duration',
  formattedStartTime: 'Start Time',
  formattedEndTime: 'End Time',
  hourlyBillingRate: 'Hourly Billing Rate',
  userWage: 'User Wage',
  hourlyCost: 'Hourly Cost',
  dailyCost: 'Daily Cost',
  ...customFields,
});

const equipmentOptions = {
  name: 'Name',
  code: 'ID',
  hours: 'Hours',
  hourlyCost: 'Hourly Cost',
  hourlyBillingRate: 'Hourly Billing Rate',
  dailyCost: 'Daily Cost',
  dailyBillingRate: 'Daily Billing Rate',
  totalHourlyCost: 'Total Hourly Cost',
  totalHourlyBilling: 'Total Hourly Billing',
  addedDate: 'Date Added',
  updatedDate: 'Date Updated',
  currentProjectId: 'Location',
  toProjectId: 'To Location',
};

const getCostCodeOptions = (t) => ({
  project: t('Project'),
  costcode: 'Cost Code',
  amount: 'Amount',
});

const getAttributeOptions = ((t, attrType, attribute, equipmentCustomFields) => {
  switch (attrType) {
    case 'Project': {
      if (attribute === 'equipment') {
        return {
          ...equipmentOptions,
          ...equipmentCustomFields,
        };
      }
      return {};
    }
    default: {
      return {};
    }
  }
});

const getOptionsMap = ({
  t,
  timeEntryCustomFields,
  equipmentCustomFields,
  attrType,
  attribute,
}) => ({
  Materials: materialOptions,
  Labour: labourOptions,
  ChangeOrder: changeOrderOptions,
  Shifts: shiftOptions,
  TimeEntry: getTimeEntryOptions(t, timeEntryCustomFields),
  Equipment: equipmentOptions,
  CostCodes: getCostCodeOptions(t),
  Attribute: getAttributeOptions(t, attrType, attribute, equipmentCustomFields),
});

const getCustomFieldsForSection = (acc, customField) => {
  if (!customField) return;
  const { hidden, fields: ourFields, name: sectionName } = customField;
  if (hidden) return;
  ourFields?.forEach?.((subField) => {
    const {
      configProps: subFieldConfig = {},
      selectedType: subFieldType,
    } = subField;
    if (!validCustomFieldTypes.has(subFieldType)) return;
    if (subFieldType === 'table') {
      const {
        columns: subFieldCols = [],
      } = subFieldConfig;
      subFieldCols?.forEach?.((subFieldCol) => {
        const colTitle = `${subFieldConfig.title} - ${subFieldCol.name}`;
        const label = `${sectionName} - ${colTitle}`;
        acc[`${subField.id}-${subFieldCol.key}`] = {
          label,
          title: colTitle,
        };
      });
      return;
    }
    const label = `${sectionName} - ${subFieldConfig.title}`;
    acc[subField.id] = {
      label,
      title: subFieldConfig.title,
    };
  });
};

const getCustomFieldOptions = ({
  customFields,
  selectedDivisionId,
}) => (
  Object.values(customFields)?.reduce((acc, divCustomFields) => {
    const { fields, divisionId } = divCustomFields;
    if (selectedDivisionId && selectedDivisionId !== divisionId) return acc;

    fields?.forEach?.((customField) => {
      getCustomFieldsForSection(acc, customField);
    });
    return acc;
  }, {})
);

const getEquipmentCustomFieldOptions = (equipmentTypes) => (
  equipmentTypes.reduce((acc, type) => {
    const { customFields: { sections = [] } = {} } = type;
    sections?.forEach?.((section) => {
      getCustomFieldsForSection(acc, section);
    });
    return acc;
  }, {})
);

const configure = ({
  id,
  sectionId,
  sections,
  setConfigProps,
  configProps = {},
  configState = {},
  setConfigState,
  setCanSubmit,
  formattedCustomTables = [],
  customTables = {},
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  t,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  contactAddressBooks = {},
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  equipmentTypes = [],
  formTemplates = [],
  labels = [],
  customFields = [],
  buckets = [],
  fullEquipmentTypes = [],
} = {}) => {
  const {
    optional,
    columns = [],
    dataType = 'Custom',
    shouldSavePresetTableSelections = false,
    hasConditionalRendering = false,
    conditionalRenderingFormula,
    openLimit = true,
    numAnswers = 1,
    subDataType,
    shouldTransposeData = false,
    shouldShowEmptyCustomFields = true,
    warnOnConflict = false,
    attrType,
    attribute,
    linkField,
    enableWarningThreshold = false,
    warningThreshold = 0,
    linkedTimeEntryTable,
  } = configProps;

  const isTimeEntry = dataType === 'TimeEntry';
  const isEquipment = dataType === 'Equipment';
  const isAttribute = dataType === 'Attribute';

  const {
    addModalVisible,
  } = configState;
  const isCustom = dataType === 'Custom';

  const selectedDivisionId = divisions?.[0] ?? null;

  const customFieldColumnOptions = getCustomFieldOptions({
    customFields,
    selectedDivisionId,
  });

  const equipmentCustomFieldColumnOptions = getEquipmentCustomFieldOptions(fullEquipmentTypes);

  const onTitleChanged = ({ id: colKey, title }) => {
    const newColumns = columns.map((col) => {
      if (col.key !== colKey) return col;
      return {
        ...col,
        name: title,
        originalName: col.originalName || col.name,
      };
    });
    setConfigProps({
      ...configProps,
      columns: newColumns,
    });
  };

  const optionsMap = getOptionsMap({
    t,
    timeEntryCustomFields: customFieldColumnOptions,
    equipmentCustomFields: equipmentCustomFieldColumnOptions,
    attrType,
    attribute,
  });

  // Limited for now
  const attributeMap = {
    Project: {
      name: t('Project'),
      fields: [
        {
          type: 'equipment',
          name: 'Equipment',
          attr: 'equipment',
        },
      ],
    },
  };

  const setTitle = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      title: value,
    });
    setCanSubmit(value);
  };

  const updateAuthorLock = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      lockEntryToAuthor: checked,
      hideAddFromTeam: checked,
    });
  };

  const updateCheckbox = (key) => (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      [key]: checked,
    });
  };

  const updateConfig = (newData = {}) => {
    setConfigProps({
      ...configProps,
      ...newData,
    });
  };

  const updateLinkedTimeEntryTable = (newValue) => {
    setConfigProps({
      ...configProps,
      linkedTimeEntryTable: newValue,
    });
  };

  const onCustomOptionSelect = (key) => {
    const existing = new Set(
      columns.map((col) => col.key),
    );
    if (existing.has(key)) return;
    let nameValue;
    const {
      [dataType]: ourOptions = {},
    } = optionsMap;
    if ((dataType === 'TimeEntry' || dataType === 'Attribute') && key?.startsWith('field-')) {
      const {
        [key]: {
          title,
        } = {},
      } = ourOptions;
      nameValue = title;
    } else {
      const {
        [key]: newName = key,
      } = ourOptions;
      nameValue = newName;
    }
    const newConfig = {
      ...configProps,
      columns: columns.concat([{
        key,
        name: nameValue,
      }]),
    };
    setConfigProps(newConfig);
  };
  const onCustomOptionDeselect = (key) => {
    const newConfig = {
      ...configProps,
      columns: columns.filter((item) => item.key !== key),
    };
    setConfigProps(newConfig);
  };

  const onSelect = (newDataType) => {
    const newConfig = {
      ...configProps,
      dataType: newDataType,
      columns: [],
    };
    if (newDataType in customTables) {
      const {
        [newDataType]: {
          columns: customColumns,
        } = {},
      } = customTables;
      newConfig.columns = customColumns;
    }

    newConfig.hideAddNewButton = false;
    setConfigProps(newConfig);
  };

  const setShouldSavePresetTableSelections = (val) => {
    const newConfig = {
      ...configProps,
      shouldSavePresetTableSelections: val,
    };
    setConfigProps(newConfig);
  };

  const setConditionalRenderingFormula = (formula) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: formula,
    });
  };

  const setHasConditionalRendering = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      hasConditionalRendering: checked,
    });
  };

  const setAttrType = (value) => {
    const typeChanged = value !== attrType;
    setConfigProps({
      ...configProps,
      attrType: value,
      linkField: typeChanged ? null : configProps.linkField,
      attribute: typeChanged ? null : configProps.attribute,
    });
  };

  const setLinkField = (value) => {
    setConfigProps({
      ...configProps,
      linkField: value,
    });
  };

  const setAttribute = (e) => {
    const {
      [attrType]: {
        fields = [],
      } = {},
    } = attributeMap;
    const field = fields.find(({ attr }) => attr === e);

    setConfigProps({
      ...configProps,
      attribute: e,
      type: field.type,
      subfields: field.subfields,
    });
  };

  const onColumnDrag = (result) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) return;
    const newColumns = [...columns];
    newColumns.splice(source.index, 1);
    newColumns.splice(destination.index, 0, columns[source.index]);
    setConfigProps({
      ...configProps,
      columns: newColumns,
    });
  };

  let {
    [dataType]: options = {},
  } = optionsMap;
  if (dataType in customTables) {
    const {
      [dataType]: {
        columns: tableColumns = [],
      } = {},
    } = customTables;
    options = {};
    tableColumns.forEach((col) => {
      options[col.key] = col.name;
    });
  }

  const dataTypes = isExternalForm
    ? [types.find(({ key }) => key === 'Custom')]
    : types.concat(formattedCustomTables);

  const onAddCalculationColumn = (key) => {
    const existing = new Set(
      columns.map((col) => col.key),
    );
    if (existing.has(key)) {
      return;
    }
    const newConfig = {
      ...configProps,
      columns: columns.concat([{
        key,
        name: key,
        isCalculation: true,
      }]),
    };
    setConfigProps(newConfig);
    setConfigState({ ...configState, addModalVisible: false });
  };

  const calcColumnValidator = (newValue) => {
    const existing = new Set(
      columns.map((col) => col.key),
    );
    if (existing.has(newValue)) {
      return 'Column name must be unique';
    }
    return null;
  };

  const onNumberChange = (num) => {
    const newConfig = {
      ...configProps,
      numAnswers: num,
    };
    setConfigProps(newConfig);
  };

  const onOpenLimitChanged = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      openLimit: checked,
    });
  };

  const onWarningThresholdChange = (num) => {
    const newConfig = {
      ...configProps,
      warningThreshold: num,
    };
    setConfigProps(newConfig);
  };

  const relevantTimeEntryTables = [];

  if (isEquipment) {
    sections.forEach(( { fields = [], name: sectionName }) => {
      fields.forEach((field) => {
        if (field.configProps?.dataType === 'TimeEntry' && field.selectedType === 'table') {
          relevantTimeEntryTables.push({ value: field.id, label: `${sectionName} - ${field.configProps.title}` });
        }
      });
    });
  }

  const ourType = types.find((type) => type.key === dataType);
  const {
    hasSubDataType = false,
    subDataTypePlaceholder,
  } = ourType ?? configProps;

  const onSubDataTypeSelect = (newSubDataType) => {
    const newConfig = {
      ...configProps,
      subDataType: newSubDataType,
    };

    setConfigProps(newConfig);
  };

  const subDataTypeMap = {
    Equipment: equipmentTypes,
  };

  const subDataTypes = hasSubDataType
    ? subDataTypeMap[dataType]
    : [];

  const subDataTypeSet = new Set(subDataTypes?.map?.((type) => type.value));

  const filteredSubType = subDataType?.filter?.((type) => subDataTypeSet.has(type)) ?? [];

  const attributeOptions = [{
    name: `${t('Project')}`,
    value: 'Project', // For now just Project - Equipment
  }];
  const linkOptions = isAttribute ? getLinkOptions({ dataType: attrType, sections }) : [];
  return (
    <div>
      <Row className="form-required-field">
        Title:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <OnTraccrTextInput
          textarea
          placeholder="Insert title here"
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <OptionalRow
        onChange={updateCheckbox('optional')}
        optional={optional}
        disabled={disableOptional}
      />
      <ConditionalRenderingRow
        onFormulaChange={setConditionalRenderingFormula}
        conditionalRenderingFormula={conditionalRenderingFormula}
        onChange={setHasConditionalRendering}
        hasConditionalRendering={hasConditionalRendering}
        sections={sections}
        id={id}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
      />
      {(configProps.dataType !== 'Custom' && configProps.dataType !== 'Shifts' && !isAttribute) && (
      <Row gutter={10}>
        <Col>
          <Checkbox
            checked={configProps.preventEdits}
            onChange={updateCheckbox('preventEdits')}
          >
            Prevent data edits
          </Checkbox>
        </Col>
        <Col>
          <HoverHelp
            placement="topRight"
            content={(
              <div style={{ width: 300 }}>
                Check this box to prevent edits to data in the table.
                This will prevent users from modifying entries pulled into the table.
              </div>
              )}
          />
        </Col>
      </Row>
      )}
      { (configProps.dataType !== 'Custom' && configProps.dataType !== 'Shifts' && configProps.dataType !== 'Equipment') && (
        <Row style={{ marginTop: 10 }} gutter={10}>
          <Col>
            <Checkbox
              checked={configProps.hideAddNewButton}
              onChange={updateCheckbox('hideAddNewButton')}
            >
              Hide Add New Button
            </Checkbox>
          </Col>
          <Col>
            <HoverHelp
              placement="topRight"
              content={(
                <div style={{ width: 300 }}>
                  Check this box to hide the Add New button from the table.
                  This will prevent users from adding new items to the table.
                </div>
              )}
            />
          </Col>
        </Row>
      )}
      {(isTimeEntry || isEquipment)
        && (
        <Row style={{ marginTop: 10 }} gutter={10}>
          <Col>
            <Checkbox
              checked={configProps.hideAddFromTeam}
              onChange={updateCheckbox('hideAddFromTeam')}
              disabled={isTimeEntry && configProps.lockEntryToAuthor}
            >
              Hide Add from Team Button
            </Checkbox>
          </Col>
          <Col>
            <HoverHelp
              placement="topRight"
              content={(
                <div style={{ width: 300 }}>
                  Check this box to hide the Add from Team button from the table.
                </div>
              )}
            />
          </Col>
        </Row>
        )}
      {
        isTimeEntry && (
          <CheckboxRow
            checked={warnOnConflict}
            title="Warn user of time conflicts"
            onChange={updateCheckbox('warnOnConflict')}
            hoverText="Check this box if you want to show a warning when a user enters time that conflicts with existing time entries"
          />
        )
      }
      {isTimeEntry
        && (
        <Row style={{ marginTop: 10 }} gutter={10}>
          <Col>
            <Checkbox
              checked={configProps.lockEntryToAuthor}
              onChange={updateAuthorLock}
            >
              Lock time entry to form author
            </Checkbox>
          </Col>
          <Col>
            <HoverHelp
              placement="topRight"
              content={(
                <div style={{ width: 300 }}>
                  Check this box to lock time entry to form author.
                </div>
              )}
            />
          </Col>
        </Row>
        )}
      { isTimeEntry && (
        <Row style={{ marginTop: 15 }} align="middle" gutter={20}>
          <Col style={{ height: 32 }}>
            <OnTraccrNumberInput
              style={{ width: 100 }}
              value={warningThreshold}
              min={1}
              max={undefined}
              onChange={onWarningThresholdChange}
              disabled={!enableWarningThreshold}
            />
          </Col>
          <Col style={{ height: 32 }}>
            <Row align="middle" style={{ height: '100%' }}>
              <Col>
                <Checkbox
                  onChange={updateCheckbox('enableWarningThreshold')}
                  checked={enableWarningThreshold}
                >
                  Enable Warning Threshold (Hours)?
                </Checkbox>
              </Col>
              <Col>
                <HoverHelp placement="top" content="Check this box if you want entries that exceed this time threshold to display a warning on the form" />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row className="form-required-field" style={{ marginTop: 15 }}>
        Data Type:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <Col>
          <Select
            style={{ width: 350 }}
            placeholder="Select Data Type"
            onSelect={onSelect}
            value={configProps.dataType}
          >
            {
            dataTypes.map((type) => (
              <Select.Option value={type.key} key={type.title}>
                {fieldOption(type)}
              </Select.Option>
            ))
          }
          </Select>
          <DropdownFieldSubDataTypeSelect
            placeholder={subDataTypePlaceholder ?? `Select ${dataType} Type`}
            onChange={onSubDataTypeSelect}
            value={filteredSubType}
            mode="multiple"
            options={subDataTypes}
          />
          {isAttribute && (
            <Row style={{ marginTop: 5 }}>
              <Select
                style={{ width: 350 }}
                placeholder="Select attribute instance type"
                onChange={setAttrType}
                value={attrType}
                optionFilterProp="label"
              >
                {attributeOptions.map((item) => (
                  <Select.Option
                    value={item.value}
                    key={item.value}
                    label={item.name}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Row>
          )}
          {isAttribute && attrType && (
            <>
              <Row style={{ marginTop: 5 }}>
                <Select
                  style={{ width: 350 }}
                  placeholder={`Select attribute for ${attributeMap[attrType]?.name ?? attrType}`}
                  onChange={setAttribute}
                  value={attribute}
                  optionFilterProp="label"
                >
                  {attributeMap[attrType]?.fields.map((item) => (
                    <Select.Option
                      value={item.attr}
                      key={item.attr}
                      label={item.attr}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
              <Row style={{ marginTop: 5 }}>
                <Select
                  style={{ width: 350 }}
                  placeholder="Link to field"
                  onChange={setLinkField}
                  value={linkField}
                  optionFilterProp="label"
                  options={linkOptions}
                  allowClear
                />
              </Row>
            </>
          )}
        </Col>
      </Row>
      <div>
        <Row className="form-required-field" style={{ marginTop: 15 }} gutter={20}>
          <Col>
            Columns:
          </Col>
          <Col>
            <HoverHelp
              placement="topRight"
              content={(
                <div style={{ width: 300 }}>
                  Columns should be entered in the order you want them to appear (left to right),
                  but can be rearranged below.
                </div>
              )}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 10, marginLeft: 0, marginRight: 0 }}>
          <Select
            style={{ width: 350 }}
            mode={isCustom ? 'tags' : 'multiple'}
            tokenSeparators={[',']}
            placeholder="Enter column names"
            onSelect={onCustomOptionSelect}
            onDeselect={onCustomOptionDeselect}
            value={columns.map((col) => col.key)}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...isCustom ? { open: false } : {}}
          >
            {
              Object.keys(options).map((opt) => (
                <Select.Option value={opt} key={opt}>
                  {(isTimeEntry || isAttribute) && opt?.startsWith('field-') ? options[opt]?.label : options[opt]}
                </Select.Option>
              ))
            }
          </Select>
          <OnTraccrButton
            title="Add Calculation"
            icon={<PlusOutlined />}
            onClick={() => setConfigState({
              ...configState,
              addModalVisible: true,
            })}
            style={{ marginLeft: 15 }}
          />
        </Row>
        <Row style={{ marginTop: 5, marginLeft: 0, marginRight: 0 }} gutter={10}>
          <DragDropContext onDragEnd={onColumnDrag}>
            <Droppable droppableId="parent">
              {({ droppableProps, innerRef, placeholder }) => (
                <div
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...droppableProps}
                  ref={innerRef}
                  style={{ width: '100%' }}
                >
                  {
                    columns.map((col, idx) => (
                      <TableFieldColumnCard
                        id={col.key}
                        orderIndex={idx}
                        originalName={col.originalName || col.name}
                        name={col.name}
                        isCalculation={col.isCalculation}
                        isReadOnly={col.isReadOnly}
                        onDeleteClicked={() => { onCustomOptionDeselect(col.key); }}
                        key={col.key}
                        configProps={configProps}
                        setConfigProps={setConfigProps}
                        fieldId={id}
                        sectionId={sectionId}
                        sections={sections}
                        dataType={dataType}
                        divisions={divisions}
                        onTitleChanged={onTitleChanged}
                      />
                    ))
                  }
                  {placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Row>
        <Row>
          <RequiredColumnsCheckbox
            onChange={updateCheckbox('requiredColumns')}
            requiredColumns={configProps.requiredColumns}
            disabled={disableOptional}
          />
        </Row>
        { dataType === 'TimeEntry' && (
          <>
            <Row>
              <Row style={{ marginTop: 15 }} gutter={10}>
                <Col>
                  <Checkbox
                    checked={shouldTransposeData}
                    onChange={updateCheckbox('shouldTransposeData')}
                  >
                    Vertical Layout
                  </Checkbox>
                </Col>
                <Col>
                  <HoverHelp
                    placement="topRight"
                    content={(
                      <div style={{ width: 250 }}>
                        Check this box to display the table in a vertical layout.
                        Time entries will be displayed in the columns
                        and the fields will be displayed in the rows.
                      </div>
                    )}
                  />
                </Col>
              </Row>
            </Row>
            <Row>
              <Row style={{ marginTop: 15 }} gutter={10}>
                <Col>
                  <Checkbox
                    checked={shouldShowEmptyCustomFields}
                    onChange={updateCheckbox('shouldShowEmptyCustomFields')}
                  >
                    Show Empty Custom Fields?
                  </Checkbox>
                </Col>
                <Col>
                  <HoverHelp
                    placement="topRight"
                    content={(
                      <div style={{ width: 250 }}>
                        Check this box to show custom fields columns that have no data.
                      </div>
                    )}
                  />
                </Col>
              </Row>
            </Row>
          </>
        )}
        <TablePreload
          configProps={configProps}
          updateConfig={updateConfig}
          divisions={divisions}
          sections={sections}
          templateId={templateId}
        />
        { isEquipment && !!relevantTimeEntryTables?.length && (
        <>
          <Row style={{ marginTop: 10 }} gutter={10}>
            <Col>
              Link Equipment Table to Time Entry Table
            </Col>
            <Col>
              <HoverHelp
                placement="topRight"
                content={(
                  <div style={{ width: 300 }}>
                    Select the Time Entry table that you would like to link to this Equipment table.
                    It will link the hours entered in the Time Entry table to the Equipment table based on the assigned user.
                  </div>
                )}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Select
              style={{ width: 350 }}
              placeholder="Select Time Entry Table"
              onChange={updateLinkedTimeEntryTable}
              value={linkedTimeEntryTable}
              optionFilterProp="label"
              options={relevantTimeEntryTables}
              allowClear
            />
          </Row>
        </>
      )}
        {!isAttribute && (
          <>
            <Row style={{ marginTop: 15 }} gutter={20}>
              <Col>
                <Switch
                  checked={shouldSavePresetTableSelections && !configProps.preloadExistingEntries}
                  onChange={setShouldSavePresetTableSelections}
                  disabled={configProps.preloadExistingEntries}
                  style={{ marginRight: 10 }}
                />
                Preset Table Selections:
              </Col>
              <Col>
                <HoverHelp
                  placement="topRight"
                  content={(
                    <div style={{ width: 300 }}>
                      Use this option to select default rows for the table
                      which will appear every time a user completes the form.
                      <br />
                      <br />
                      Once this toggle is enabled, use the field preview underneath
                      to add your preset selections into the table.
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }} align="middle" gutter={20}>
              <Col style={{ height: 32 }}>
                <OnTraccrNumberInput
                  style={{ width: 100 }}
                  value={numAnswers}
                  min={1}
                  max={undefined}
                  onChange={onNumberChange}
                  disabled={openLimit}
                />
              </Col>
              <Col style={{ height: 32 }}>
                <Row align="middle" style={{ height: '100%' }}>
                  <Col>
                    <Checkbox
                      onChange={onOpenLimitChanged}
                      checked={openLimit}
                    >
                      Leave open?
                    </Checkbox>
                  </Col>
                  <Col>
                    <HoverHelp placement="top" content="Check this box if you want to remove restrictions on the number of answers" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        {!isBoardCards && !isExternalForm && (
          <FieldTriggerCheckbox
            onChange={updateCheckbox('fieldTrigger')}
            onEditableChange={setFieldTriggerEditable}
            sections={sections}
            projectId={projectId}
            templateId={templateId}
            divisions={divisions}
            configProps={configProps}
            setConfigProps={setConfigProps}
            name={name}
          />
        )}
      </div>
      <SimpleTextInputModal
        title="Add Calculation Column"
        visible={addModalVisible}
        onClose={() => setConfigState({
          ...configState,
          addModalVisible: false,
        })}
        validator={calcColumnValidator}
        onSave={onAddCalculationColumn}
        placeholder="Column Name"
      />
    </div>
  );
};

const preview = ({
  setPreviewProps,
  configProps = {},
  previewProps = {},
  projects = [],
  materials = [],
  equipment = [],
  equipmentTypes = [],
  changeOrderMap = {},
  isDisplay,
  id,
  responses = {},
  setResponses,
  responding = false,
  customerId,
  projectId,
  customTables = {},
  divisions,
  sections,
  templateId,
  showCondensedView,
  locked,
  t,
  inAddDrawer,
  createTimeTableIds,
  // Inline Editing Props for Time Entry Table
  vendorId,
  setCustomerIds,
  setProjectIds,
  setVendorIds,
  setContactIds,
  setCostcodeIds,
  setSubContractIds,
  setUserIds,
  setBucketId,
  userToLabel = {},
  customerToLabel = {},
  vendorToLabel = {},
  subContractMap = {},
  subContractCoMap = {},
  scheduleOfValues = {},
  selectedBucket,
  selectedBucketTypeToIdMap,
  selectedBucketTypes,
  projectEquipment,
  equipmentTypeMap,
  projectIds = [],
  onFieldTrigger,
} = {}) => {
  const {
    optional,
    title = 'Title goes here',
    dataType,
    subDataType,
    columns = [],
    fieldTrigger,
  } = configProps;

  const isCustomSavedFormTable = dataType in customTables;

  // Only load in preset data if there is no response
  // Preset data is unique to tables so we don't want to overwrite other responses
  if ((!responses[id] || !responses[id].values) && setResponses && configProps.presetData) {
    setResponses({
      ...responses,
      [id]: {
        values: configProps.presetData.selected || [],
      },
    });
  }

  // Equipment Filtered by Type
  const equipmentTypeSet = new Set(equipmentTypes.map((type) => type.id));
  const subDataTypeSet = new Set(
    subDataType?.filter?.((typeId) => equipmentTypeSet.has(typeId) || typeId === null) ?? [],
  );
  const filteredEquipment = equipment.filter((eq) => (
    eq.active
    && (!subDataTypeSet?.size || subDataTypeSet.has(eq.equipmentTypeId))
  ));

  return (
    <div style={{ pointerEvents: 'auto' }}>
      <TitleRow
        title={title}
        optional={optional}
        style={{ marginTop: 10 }}
        filter={
          fieldTrigger && !isDisplay
            ? (
              <FieldTriggerFlag
                sections={sections}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                onFieldTrigger={onFieldTrigger}
              />
            ) : null
        }
      />
      { dataType === 'Materials' && (
        <MaterialTablePreview
          materials={materials}
          columns={columns}
          previewProps={previewProps}
          setPreviewProps={setPreviewProps}
          isDisplay={isDisplay}
          id={id}
          responses={responses}
          setResponses={setResponses}
          responding={responding}
          customerId={customerId}
          projectId={projectId}
          configProps={configProps}
          showCondensedView={showCondensedView}
        />
      )}

      { dataType === 'Labour' && (
        <LabourTablePreview
          columns={columns}
          previewProps={previewProps}
          setPreviewProps={setPreviewProps}
          isDisplay={isDisplay}
          id={id}
          responses={responses}
          setResponses={setResponses}
          responding={responding}
          customerId={customerId}
          projectId={projectId}
          configProps={configProps}
          showCondensedView={showCondensedView}
        />
      )}
      { dataType === 'ChangeOrder' && (
        <ChangeOrderTablePreview
          columns={columns}
          previewProps={previewProps}
          setPreviewProps={setPreviewProps}
          isDisplay={isDisplay}
          id={id}
          responses={responses}
          setResponses={setResponses}
          responding={responding}
          changeOrderMap={changeOrderMap}
          configProps={configProps}
          showCondensedView={showCondensedView}
        />
      )}
      { dataType === 'Shifts' && (
        <ShiftTablePreview
          projectId={projectId}
          columns={columns}
          previewProps={previewProps}
          setPreviewProps={setPreviewProps}
          isDisplay={isDisplay}
          id={id}
          responses={responses}
          setResponses={setResponses}
          responding={responding}
          changeOrderMap={changeOrderMap}
          configProps={configProps}
          showCondensedView={showCondensedView}
          projects={projects}
          projectLocked={locked}
          inAddDrawer={inAddDrawer}
        />
      )}
      { dataType === 'TimeEntry' && (
        <TimeEntryTablePreview
          projectId={projectId}
          columns={columns}
          previewProps={previewProps}
          setPreviewProps={setPreviewProps}
          isDisplay={isDisplay}
          id={id}
          responses={responses}
          setResponses={setResponses}
          responding={responding}
          customerId={customerId}
          changeOrderMap={changeOrderMap}
          divisions={divisions}
          configProps={configProps}
          showCondensedView={showCondensedView}
          t={t}
          templateId={templateId}
          createTimeTableIds={createTimeTableIds}
          subContractMap={subContractMap}
          subContractCoMap={subContractCoMap}
          scheduleOfValues={scheduleOfValues}
          customerToLabel={customerToLabel}
          userToLabel={userToLabel}
          vendorToLabel={vendorToLabel}
          setCustomerIds={setCustomerIds}
          setProjectIds={setProjectIds}
          setVendorIds={setVendorIds}
          setContactIds={setContactIds}
          setCostcodeIds={setCostcodeIds}
          setSubContractIds={setSubContractIds}
          setUserIds={setUserIds}
          setBucketId={setBucketId}
          vendorId={vendorId}
          selectedBucket={selectedBucket}
          selectedBucketTypeToIdMap={selectedBucketTypeToIdMap}
          selectedBucketTypes={selectedBucketTypes}
        />
      )}
      { isCustomSavedFormTable && (
        <CustomSavedFormTablePreview
          columns={columns}
          previewProps={previewProps}
          setPreviewProps={setPreviewProps}
          isDisplay={isDisplay}
          id={id}
          responses={responses}
          setResponses={setResponses}
          responding={responding}
          configProps={configProps}
          customTables={customTables}
          showCondensedView={showCondensedView}
        />
      )}
      { dataType === 'Custom' && (
        <CustomTablePreview
          columns={columns}
          previewProps={previewProps}
          setPreviewProps={setPreviewProps}
          isDisplay={isDisplay}
          id={id}
          responses={responses}
          setResponses={setResponses}
          responding={responding}
          configProps={configProps}
          showCondensedView={showCondensedView}
        />
      )}
      {dataType === 'Equipment' && (
        <EquipmentTablePreview
          equipment={filteredEquipment}
          columns={columns}
          previewProps={previewProps}
          setPreviewProps={setPreviewProps}
          isDisplay={isDisplay}
          id={id}
          responses={responses}
          setResponses={setResponses}
          responding={responding}
          configProps={configProps}
          showCondensedView={showCondensedView}
          divisions={divisions}
        />
      )}
      {dataType === 'CostCodes' && (
        <CostCodeTablePreview
          id={id}
          configProps={configProps}
          columns={columns}
          previewProps={previewProps}
          setPreviewProps={setPreviewProps}
          responding={responding}
          responses={responses}
          setResponses={setResponses}
          isDisplay={isDisplay}
          showCondensedView={showCondensedView}
          projectIds={projectIds}
        />
      )}
      { dataType === 'Attribute' && (
        <AttributeTablePreview
          equipment={equipment}
          divisions={divisions}
          equipmentTypeMap={equipmentTypeMap}
          projectId={projectId}
          projects={projects}
          projectEquipment={projectEquipment}
          columns={columns}
          previewProps={previewProps}
          setPreviewProps={setPreviewProps}
          isDisplay={isDisplay}
          id={id}
          responses={responses}
          setResponses={setResponses}
          responding={responding}
          configProps={configProps}
          showCondensedView={showCondensedView}
        />
      )}
    </div>
  );
};

export default {
  configure,
  preview,
  title: 'Table',
  description: 'User can enter data into a table',
};
