import { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/react';

import Debouncer from '../../helpers/Debouncer';
import { getLinkId, loadForms } from '../../forms/formHelpers';

const debouncer = new Debouncer();

const getRelevantForms = async ({
  preloadDateField,
  preloadDateModifier = 'days',
  preloadDateCount,
  preloadProject,
  preloadForm,
  linkedStart,
  linkedEnd,
  linkedProjectId,
  linkedFormTemplateId,
}) => {
  try {
    const now = DateTime.local();
    const startTimeToFilter = preloadDateField
      ? linkedStart
      : now.startOf('day').minus({ [preloadDateModifier]: preloadDateCount });
    const endTimeToFilter = preloadDateField ? linkedEnd : now.endOf('day');
    const forms = await loadForms({
      projectId: preloadProject || linkedProjectId,
      formTemplateId: preloadForm || linkedFormTemplateId,
      startTime: startTimeToFilter.toMillis(),
      endTime: endTimeToFilter.toMillis(),
    });

    return forms;
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
};

export default function usePreloadHook({
  responses,
  id,
  setResponses,
  columns,
  selected,
  responding,
  configProps,
  type,
  setLoading,
}) {
  const {
    preloadProject,
    preloadForm,
    preloadDropdown,
    preloadFormDropdown,
    preloadExistingEntries,
    preloadDateField,
    preloadDateModifier,
    preloadDateCount,
  } = configProps ?? {};

  const [lastProjectId, setLastProjectId] = useState();
  const [lastFormTemplateId, setLastFormTemplateId] = useState();
  const [lastDateValue, setLastDateValue] = useState({});
  const [hasPreloaded, setHasPreloaded] = useState(false);

  const linkedProjectId = useMemo(() => (
    getLinkId({ responses, defaultValue: null, linkField: preloadDropdown })
  ), [responses, preloadDropdown]);

  const linkedFormTemplateId = useMemo(() => (
    getLinkId({ responses, defaultValue: null, linkField: preloadFormDropdown })
  ), [responses, preloadFormDropdown]);

  const linkedDateValue = useMemo(() => {
    if (!preloadDateField) return null;
    const {
      [preloadDateField]: dateResponse = {},
    } = responses;

    const { startTime, endTime, date } = dateResponse;

    if (date) { // date-time
      return {
        startTime: DateTime.fromMillis(date).startOf('day'),
        endTime: DateTime.fromMillis(date).endOf('day'),
      };
    }

    if (startTime && endTime) { // date-time range
      return {
        startTime: DateTime.fromMillis(startTime).startOf('day'),
        endTime: DateTime.fromMillis(endTime).endOf('day'),
      };
    }

    return null;
  }, [responses, preloadDateField]);

  // pre-load entries
  useEffect(() => {
    // early returns
    if (!responding) return;
    if (!preloadExistingEntries) return;
    if (!(preloadProject || linkedProjectId) || !(preloadForm || linkedFormTemplateId)) return;
    if (preloadDateField && !linkedDateValue) return;

    const { startTime: linkedStart, endTime: linkedEnd } = linkedDateValue ?? {};
    const { startTime: lastStart, endTime: lastEnd } = lastDateValue ?? {};

    const hasSameDate = linkedDateValue
      && linkedStart?.toMillis() === lastStart?.toMillis()
      && linkedEnd?.toMillis() === lastEnd?.toMillis();

    const hasSameProject = linkedProjectId && linkedProjectId === lastProjectId;
    const hasSameForm = linkedFormTemplateId && linkedFormTemplateId === lastFormTemplateId;

    const hasAlreadyPreloadedProject = (preloadProject && hasPreloaded) || hasSameProject;
    const hasAlreadyPreloadedForm = (preloadForm && hasPreloaded) || hasSameForm;

    // conditional returns
    if (
      (preloadDateField && hasSameDate && hasAlreadyPreloadedProject && hasAlreadyPreloadedForm)
      || (!preloadDateField && hasAlreadyPreloadedProject && hasAlreadyPreloadedForm)
    ) {
      return;
    }

    const preloadForms = async () => {
      setLoading(true);
      const existingForms = await getRelevantForms({
        preloadDateField,
        preloadDateModifier,
        preloadDateCount,
        preloadProject,
        preloadForm,
        linkedStart,
        linkedEnd,
        linkedProjectId,
        linkedFormTemplateId,
      });

      const relevantValues = [];
      existingForms?.forEach((
        {
          data: {
            sections = [],
          } = {},
        },
      ) => {
        sections.forEach(({ fields = [] }) => {
          fields.forEach(({
            response: {
              values = [],
              dataType,
            } = {},
          }) => {
            if (dataType !== type) return;
            relevantValues.push(...values.map((value) => ({ ...value, isPreloaded: true })));
          });
        });
      });

      setResponses((prev) => ({
        ...(prev ?? {}),
        [id]: {
          values: relevantValues,
          columns,
          dataType: type,
        },
      }));
      setLastProjectId(linkedProjectId);
      setLastFormTemplateId(linkedFormTemplateId);
      setLastDateValue(linkedDateValue);
      setHasPreloaded(true);
      setLoading(false);
    };

    debouncer.debounce(() => preloadForms(), 500);
  }, [
    configProps,
    id,
    setResponses,
    responses,
    linkedProjectId,
    linkedFormTemplateId,
    lastProjectId,
    lastFormTemplateId,
    hasPreloaded,
    selected,
    linkedDateValue,
    lastDateValue,
    type,
  ]);
}
