import React from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    Sentry.captureException(error, errorInfo);
  }

  render() {
    const {
      hasError,
    } = this.state;
    const { children, errorView } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return errorView;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  errorView: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  errorView: null,
};

export default ErrorBoundary;
