import React, { useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Table,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import BorderlessButton from '../../../../common/buttons/BorderlessButton';
import OnTraccrButton from '../../../../common/buttons/OnTraccrButton';

import ShiftTableAddDrawer from './ShiftTableAddDrawer';

import {
  getRepeatText,
  formatDay,
  formatRange,
  getAllDayValues,
} from '../../../../helpers/time';

import { getIdMap } from '../../../../helpers/helpers';
import DisplayText from '../../../../common/text/DisplayText';
import { generateId } from '../../../formHelpers';
import useCheckTableMaxRows from '../../../../common/hooks/useCheckTableMaxRows';
import { getCalculationTableColumn } from '../formFieldsHelpers';

const getShiftTimesText = (times, shift) => {
  if (!Array.isArray(times)) return {};
  const { repeat, repeatEndDate } = shift;
  const [startTime, endTime] = times ?? [];
  if (!startTime || !endTime) return {};
  const startDT = DateTime.fromMillis(startTime);
  const endDT = DateTime.fromMillis(endTime);
  const repeatEndDT = repeatEndDate ? DateTime.fromMillis(repeatEndDate) : null;
  const {
    isAllDay,
    isMultiDay,
  } = getAllDayValues(startDT, endDT);
  const rangeMode = isAllDay || isMultiDay;
  const repeatText = getRepeatText({ mode: repeat, datetime: startDT, endDate: repeatEndDT });
  let firstLine;
  if (rangeMode) {
    if (endDT.hasSame(startDT, 'day')) {
      firstLine = formatDay(startTime);
    } else {
      firstLine = formatRange(
        [startTime, endTime],
        isAllDay ? DateTime.DATE_MED : DateTime.DATETIME_MED,
      );
    }
  } else {
    firstLine = `${formatDay(startTime)} ${formatRange([startTime, endTime], DateTime.TIME_SIMPLE)}`;
  }
  return { firstLine, repeatText };
};

const getColumnMap = ({
  requiredColumns,
}) => ({
  title: {
    title: <div className={requiredColumns && 'form-required-field'}>Title</div>,
    dataIndex: 'title',
  },
  description: {
    title: <div className={requiredColumns && 'form-required-field'}>Description</div>,
    dataIndex: 'description',
  },
  users: {
    title: <div className={requiredColumns && 'form-required-field'}>Users</div>,
    dataIndex: 'users',
  },
  dates: {
    title: <div className={requiredColumns && 'form-required-field'}>Dates</div>,
    dataIndex: 'times',
    render: (times, record) => {
      const { firstLine, repeatText } = getShiftTimesText(times, record);

      if (!repeatText) return firstLine;

      return <span>{firstLine}<br />{repeatText}</span>;
    },
  },
});

const getColumns = ({
  onDelete,
  onEdit,
  columns,
  isDisplay,
  requiredColumns,
}) => {
  const cols = [];
  const colMap = getColumnMap({ requiredColumns });
  columns.forEach((col) => {
    if (col.key in colMap) {
      cols.push(colMap[col.key]);
    } else if (col.isCalculation) {
      cols.push(getCalculationTableColumn(col, {
        isDisplay,
      }));
    }
  });
  if (!isDisplay) {
    cols.push({
      title: '',
      dataIndex: '',
      width: 100,
      render: (_, record) => (
        <BorderlessButton
          iconNode={<EditOutlined />}
          onClick={() => onEdit(record)}
        />
      ),
    });
    cols.push({
      title: '',
      dataIndex: '',
      width: 100,
      render: (_, record) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: 'red' }} />}
          onClick={() => onDelete(record.id)}
        />
      ),
    });
  }

  return cols;
};

const decorateShift = (shift, userIdMap) => {
  const newShift = { ...(shift || {}) };

  const {
    userIds = [],
    times,
  } = newShift;

  const usersText = userIds.map((userId) => (userIdMap?.[userId]?.name))
    .filter((name) => name)
    .join(', ');

  const { firstLine, repeatText } = getShiftTimesText(times, newShift);
  let dates = firstLine;
  if (repeatText) dates += `\n${repeatText}`;

  newShift.users = usersText;
  newShift.dates = dates;

  return newShift;
};

function ShiftTablePreview({
  columns = [],
  previewProps = {},
  setPreviewProps,
  isDisplay,
  id,
  setResponses,
  responses = {},
  responding = false,
  configProps = {},
  showCondensedView,
  projectId,
  projects = [],
  projectLocked,
  inAddDrawer,
}) {
  const {
    requiredColumns,
  } = configProps ?? {};
  const values = previewProps.values || []; // For Responses
  const {
    values: previewSelected = [],
  } = previewProps;
  const {
    [id]: {
      values: responseSelected = [],
    } = {},
  } = responses;

  const selected = responding ? responseSelected : previewSelected;

  const users = useSelector((state) => state.users.users);
  const userIdMap = useMemo(() => getIdMap(users), [users]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedShift, setSelectedShift] = useState();

  const decoratedSelected = useMemo(() => (
    selected.map((shift) => decorateShift(shift, userIdMap))
  ), [selected, userIdMap]);

  const showDrawer = useCallback(() => setDrawerOpen(true), []);
  const hideDrawer = useCallback(() => {
    setDrawerOpen(false);
    setSelectedShift();
  }, []);

  const onSubmitChanges = useCallback((changedValues = {}) => {
    const fullObject = decorateShift({ ...changedValues }, userIdMap);
    let newSelected = decoratedSelected;
    if (!selectedShift) {
      fullObject.id = generateId();
      newSelected = decoratedSelected.concat([fullObject]);
    } else {
      newSelected = decoratedSelected.map((item) => {
        if (item.id !== selectedShift.id) return item;
        return {
          ...selectedShift,
          ...changedValues,
        };
      });
    }
    if (responding) {
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          values: newSelected,
          columns,
        },
      });
    } else {
      setPreviewProps({
        ...previewProps,
        values: newSelected,
      });
    }
    hideDrawer();
  }, [
    decoratedSelected,
    previewProps,
    responses,
    setResponses,
    id,
    selectedShift,
    hideDrawer,
    userIdMap,
  ]);

  const onDelete = useCallback((itemId) => {
    const newSelected = decoratedSelected.filter((item) => item.id !== itemId);
    if (responding) {
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          values: newSelected,
          columns,
        },
      });
    } else {
      setPreviewProps({
        ...previewProps,
        values: newSelected,
      });
    }
  }, [previewProps, decoratedSelected, id]);

  const onEdit = useCallback((selectedRow) => {
    setSelectedShift(selectedRow);
    setDrawerOpen(true);
  }, []);

  const tableColumns = useMemo(() => (
    getColumns({
      onEdit,
      onDelete,
      columns,
      isDisplay,
      requiredColumns,
    })
  ), [columns, isDisplay, onDelete, requiredColumns]);

  const dataSource = useMemo(() => (
    isDisplay && !responding ? values : decoratedSelected
  ), [isDisplay, responding, values, decoratedSelected]);

  const {
    shouldAddButtonBeEnabled = true,
  } = useCheckTableMaxRows({
    configProps,
    currentRowsLength: dataSource?.length,
  });

  return (
    <>
      <Row
        style={{
          marginTop: showCondensedView ? 0 : 15,
          width: !inAddDrawer ? '100%' : 600,
        }}
      >
        {!isDisplay && (
          <Row align="middle" justify="space-between" style={{ width: '100%', marginBottom: 10 }}>
            <Col>
              <Row gutter={20}>
                <Col>
                  <OnTraccrButton
                    title="Add New"
                    onClick={showDrawer}
                    icon={<PlusOutlined />}
                    disabled={!shouldAddButtonBeEnabled}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        { !showCondensedView || dataSource?.length ? (
          <Table
            style={{ width: '100%', overflow: 'auto' }}
            columns={tableColumns}
            size="small"
            pagination={false}
            dataSource={dataSource}
            scroll={{ x: 'fit-content' }}
          />
        ) : (
          <DisplayText title="No Shifts Selected" style={{ marginBottom: 0 }} />
        )}
      </Row>
      <ShiftTableAddDrawer
        visible={drawerOpen}
        onClose={hideDrawer}
        onSubmit={onSubmitChanges}
        selectedShift={selectedShift}
        projects={projects}
        projectId={projectId}
        projectLocked={projectLocked}
      />
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
ShiftTablePreview.propTypes = {
  columns: PropTypes.array,
  previewProps: PropTypes.object,
  setPreviewProps: PropTypes.func.isRequired,
  isDisplay: PropTypes.bool,
  id: PropTypes.string.isRequired,
  setResponses: PropTypes.func.isRequired,
  responses: PropTypes.object,
  responding: PropTypes.bool,
  configProps: PropTypes.shape({
    requiredColumns: PropTypes.bool,
  }),
  showCondensedView: PropTypes.bool,
  projects: PropTypes.array,
  projectId: PropTypes.string,
  projectLocked: PropTypes.bool,
  inAddDrawer: PropTypes.bool,
};

ShiftTablePreview.defaultProps = {
  columns: [],
  previewProps: {},
  isDisplay: false,
  responses: {},
  responding: false,
  configProps: {},
  showCondensedView: false,
  projects: [],
  projectId: null,
  projectLocked: false,
  inAddDrawer: false,
};

export default ShiftTablePreview;
