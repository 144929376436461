import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Checkbox, Col, Popover, Switch,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import HoverHelp from '../../../common/HoverHelp';
import BorderlessButton from '../../../common/buttons/BorderlessButton';
import FieldTriggerPopover from './FieldTriggerPopover';

function FieldTriggerCheckbox({
  onChange,
  onEditableChange,
  sections,
  projectId,
  templateId,
  divisions,
  configProps,
  setConfigProps,
  name,
}) {
  const {
    fieldTrigger: selected,
    fieldTriggerProps: {
      editable,
    } = {},
  } = configProps;

  const editableText = editable ? 'Editable' : 'Not Editable';

  return (
    <>
      <Row style={{ marginTop: 20 }} gutter={10}>
        <Col>
          Trigger Form:
        </Col>
        <Col>
          <HoverHelp
            placement="topRight"
            content={(
              <div style={{ width: 250 }}>
                Enable this option to add the ability to trigger a form from this field using
                the flag icon on the form.
                <br />
                <br />
                Configure form trigger settings with the wheel icon.
                <br />
                <br />
                If you set the option to
                <b> Editable</b>
                , the user filling out the form will be able to override the selected form and
                recipients to trigger.
                <br />
                <br />
                To configure field mappings to the triggered form, please note that the fields
                of this form template must first be saved.
              </div>
            )}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 5 }} gutter={10}>
        <Col style={{ paddingRight: 0 }}>
          <Checkbox
            onChange={onChange}
            checked={selected}
          >
            Enable
          </Checkbox>
        </Col>
        <Col style={{ padding: 0 }}>
          <Popover
            title="Configure Field Trigger"
            placement="top"
            trigger="click"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            content={(
              <FieldTriggerPopover
                sections={sections}
                projectId={projectId}
                templateId={templateId}
                divisions={divisions}
                setConfigProps={setConfigProps}
                configProps={configProps}
                name={name}
              />
            )}
          >
            <BorderlessButton
              style={{ width: 'auto', height: 20, padding: 0 }}
              iconNode={<SettingOutlined />}
            />
          </Popover>
        </Col>
        <Col>
          {selected && (
            <Row>
              <Col style={{ paddingLeft: '15px' }}>
                <Switch
                  checked={editable}
                  size="small"
                  onChange={(checked) => {
                    onEditableChange(checked);
                  }}
                />
              </Col>
              <Col style={{ paddingLeft: '5px' }}>
                {editableText}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
FieldTriggerCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  onEditableChange: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  projectId: PropTypes.string,
  templateId: PropTypes.string,
  divisions: PropTypes.arrayOf(PropTypes.string),
  configProps: PropTypes.object,
  setConfigProps: PropTypes.func.isRequired,
  name: PropTypes.string,
};

FieldTriggerCheckbox.defaultProps = {
  sections: [],
  projectId: null,
  templateId: null,
  divisions: [],
  configProps: {},
  name: null,
};

export default FieldTriggerCheckbox;
