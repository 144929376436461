import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Drawer,
  Table,
  Spin,
  message,
  Row,
} from 'antd';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { DateTime } from 'luxon';
import { getIdMap } from '../helpers/helpers';

import useToggle from '../common/hooks/useToggle';
import SliderDownloadButton from '../common/buttons/SliderDownloadButton';

import FormDetailView from '../forms/CompletedForms/FormDetailView';

import {
  getBoardDetailsColumn,
  getFormDetailsColumn,
} from './analytics.constants';
import { getFormById } from '../forms/state/forms.actions';
import { openCardDrawer } from '../boards/state/boards.actions';
import BoardCardDrawer from '../boards/BoardCardDrawer';

const tsToDate = (ts) => (
  ts
    ? DateTime.fromMillis(ts).toLocaleString(DateTime.DATETIME_MED)
    : ''
);

const getCardHeader = (showNumber) => {
  const header = ['Title'];
  if (showNumber) header.push('Number');
  header.push('Status');
  header.push('Created Date');
  return header;
};

const convertCardToRow = (showNumber) => (record) => {
  const {
    title, number, status, createdAt,
  } = record;
  const res = [title];
  if (showNumber) res.push(number);
  res.push(status);
  res.push(tsToDate(createdAt));
  return res;
};

const getFormHeader = (showNumber) => {
  const header = ['Name'];
  if (showNumber) header.push('Number');
  header.push('Created Date');
  header.push('Last Updated');
  return header;
};

const convertFormToRow = (showNumber) => (record) => {
  const {
    name, number, lastUpdated, createdAt,
  } = record;
  const res = [name];
  if (showNumber) res.push(number);
  res.push(tsToDate(createdAt));
  res.push(tsToDate(lastUpdated));
  return res;
};

function AnalyticsDetailDrawer({
  config,
  analyticsConfig,
  onClose,
}) {
  const {
    toggle: toggleLoading,
    isToggled: loading,
  } = useToggle();

  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.users);
  const boards = useSelector((state) => state.boards.boards);
  const userMap = useMemo(() => getIdMap(users), [users]);

  const [data, setData] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const fullSelectedBoard = useMemo(() => (
    boards[analyticsConfig?.boardId] || {}
  ), [boards, analyticsConfig]);

  const {
    [fullSelectedBoard.cardTypeId]: cardType,
  } = useSelector((state) => state.boards.cardTemplates);

  const showNumber = useMemo(() => data?.some((row) => row.number), [data]);

  const onDownload = useCallback(() => {
    if (!config?.title) return;
    const workbook = XLSX.utils.book_new();
    const mapFunc = analyticsConfig?.boardId
      ? convertCardToRow
      : convertFormToRow;
    const rows = data.map(mapFunc(showNumber));

    const header = analyticsConfig?.boardId
      ? getCardHeader(showNumber)
      : getFormHeader(showNumber);

    const sheet = XLSX.utils.aoa_to_sheet([header].concat(rows));
    XLSX.utils.book_append_sheet(
      workbook,
      sheet,
      config?.title?.slice(0, 31),
    );
    XLSX.writeFile(workbook, `${analyticsConfig?.title}-${config?.title}.xlsx`);
  }, [data, showNumber, config, analyticsConfig]);

  useEffect(() => {
    const loadData = async () => {
      const { boardId, formTemplateId } = analyticsConfig ?? {};
      toggleLoading();
      const payload = {
        ids: config.ids,
      };
      if (boardId) {
        payload.boardId = boardId;
      } else if (formTemplateId) {
        payload.formTemplateId = formTemplateId;
      }
      try {
        const { data: newData } = await axios.post('/analytics/details', payload);
        setData(newData ?? []);
      } catch (err) {
        message.error('Failed to load data');
      }
      toggleLoading();
    };

    if (!config?.ids?.length) {
      setData([]);
      return;
    }
    loadData();
  }, [analyticsConfig, config]);

  const tableColumns = useMemo(() => {
    const { boardId, formTemplateId } = analyticsConfig ?? {};
    if (boardId) {
      return getBoardDetailsColumn(showNumber);
    }
    if (formTemplateId) {
      return getFormDetailsColumn(showNumber);
    }
    return [];
  }, [analyticsConfig, data, showNumber]);

  const handleRowClick = useCallback((record) => {
    const { boardId, formTemplateId } = analyticsConfig ?? {};

    if (formTemplateId) {
      if (!record?.id) return;

      dispatch(getFormById(record.id));
      setShowForm(true);
    } else if (boardId) {
      dispatch(openCardDrawer({
        selectedCardId: record.id,
        editMode: false,
      }));
    }
  }, [analyticsConfig]);

  return (
    <>
      <Drawer
        title={config?.title}
        visible={!!config}
        onClose={onClose}
        width={700}
        bodyStyle={{ padding: 0 }}
        zIndex={900}
      >
        <SliderDownloadButton onDownload={onDownload} />
        <Table
          columns={tableColumns}
          dataSource={data}
          size="small"
          pagination={false}
          scroll={{
            y: 'calc(100vh - 94px)',
          }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            style: { cursor: 'pointer' },
          })}
        />
        {
        loading
          && (
            <Row style={{ position: 'absolute', inset: 0 }} justify="center" align="middle">
              <Spin />
            </Row>
          )
      }
        <FormDetailView
          visible={showForm}
          onClose={() => setShowForm(false)}
          userMap={userMap}
          shouldRedirect={false}
        />
      </Drawer>
      <BoardCardDrawer
        boardId={analyticsConfig?.boardId}
        cardTypeId={fullSelectedBoard.cardTypeId}
        divisions={fullSelectedBoard.divisions}
        showCardNumber={fullSelectedBoard.showCardNumber}
        useCardNumberAsCardTitle={cardType?.useCardNumberAsCardTitle}
      />
    </>
  );
}

AnalyticsDetailDrawer.propTypes = {
  config: PropTypes.shape({
    title: PropTypes.string,
    ids: PropTypes.arrayOf(PropTypes.string),
  }),
  analyticsConfig: PropTypes.shape({
    boardId: PropTypes.string,
    formTemplateId: PropTypes.string,
    title: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
};

AnalyticsDetailDrawer.defaultProps = {
  config: null,
  analyticsConfig: {},
};

export default AnalyticsDetailDrawer;
