import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { getLinkedIds, getLinkedValues } from 'ontraccr-common/lib/formFields/Attributes';
import { DATATYPES } from 'ontraccr-common/lib/Definitions';
import { areArraysEqual } from 'ontraccr-common/lib/Common';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import DisplayText from '../../../common/text/DisplayText';
import { isNullOrUndefined } from '../../../helpers/helpers';
import { formatFormDropdownList } from '../../formHelpers';
import { getSubContractsWithTotalChangesMap } from '../../../projects/ProjectScheduleOfValues/helpers';

const costCodeIdTransformer = (ccId) => ccId?.split('.')?.[1] ?? null;
const noIdTransformer = (val) => val;

function AttributePreviewField({
  id,
  isDisplay,
  configProps = {},
  responding = false,
  responses = {}, // Actively responding
  setResponses,
  previewProps = {}, // Completed form
  scheduleOfValues,
  divisions,
  equipment = [],
  projectEquipment = {},
  equipmentTypeMap = {},
  projectIdMap = {},
  costcodeIdMap = {},
  userIdMap = {},
  subContractMap = {},
  subContractCOMap = {},
  customerIds = [],
  projectIds = [],
  vendorIds = [],
  contactIds = [],
  costcodeIds = [],
  userIds = [],
  subContractIds = [],
  payableSubContractIds = [],
  bucketTemplateMap = {},
  hasBucketsLoaded,
  selectedProject,
  initialProjectIds,
  timecardProjectId,
}) {
  const divSet = useMemo(() => new Set(divisions), [divisions]);
  const { id: authorId } = useSelector((state) => state.profile.profile);
  const customerIdMap = useSelector((state) => state.customers.customers);
  const vendorIdMap = useSelector((state) => state.vendors.vendors);
  const globalAddressBooks = useSelector((state) => state.contacts.globalAddressBooks) ?? {};
  const subContractForms = useSelector((state) => state.forms.lightWeightSubContracts);

  const [lastCustomerIds, setLastCustomerIds] = useState();
  const [lastVendorIds, setLastVendorIds] = useState();
  const [lastProjectIds, setLastProjectIds] = useState();
  const [lastContactIds, setLastContactIds] = useState();
  const [lastCostcodeIds, setLastCostcodeIds] = useState();
  const [lastUserIds, setLastUserIds] = useState();
  const [lastSubContractIds, setLastSubContractIds] = useState();
  const [lastBucketIds, setLastBucketIds] = useState();
  const [lastPayableSubContractIds, setLastPayableSubContractIds] = useState();
  const [lastRelevantPayableSubContractIds, setLastRelevantPayableSubContractIds] = useState([]);
  const [subContractDetailsMap, setSubContractDetailsMap] = useState();

  const [initialValue, setInitialValue] = useState();
  const [useInitialValue, setUseInitialValue] = useState(true);

  const {
    value = '',
  } = previewProps || {};

  const {
    dataType = 'Customer',
    attribute = 'address',
    type = '',
    useFormAuthorAttributes,
    useParentAttributes,
    linkField,
    allowEdits = true,
  } = configProps;

  const realValue = useMemo(() => {
    if (!responding) return value;
    if (useInitialValue && initialValue) return initialValue;
    return responses[id]?.value ?? '';
  }, [responses, id, value, responding, useInitialValue, initialValue]);

  const onAttributeChange = (e) => {
    if (responding && setResponses) {
      const {
        target: {
          value: newValue,
        } = {},
      } = e;
      if (useInitialValue) setUseInitialValue(false);
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          value: newValue,
        },
      });
    }
  };

  const setAttr = (val) => {
    if (!setResponses) return;
    const oldValue = responses[id]?.value ?? '';
    if (!initialValue && responses[id]?.value) setInitialValue(oldValue);
    const newValue = isNullOrUndefined(val) ? '' : val;
    if (newValue !== oldValue) {
      // required for potential race condition bug
      // HARBOUR-2218: https://bitbucket.org/ontraccr/%7B9229913d-6515-4f3c-99c1-30ac79e98a41%7D/pull-requests/1031/diff
      setResponses((oldResponses) => ({
        ...oldResponses,
        [id]: {
          ...(oldResponses[id]),
          value: newValue,
        },
      }));
    }
  };

  // Linked Fields

  const linkedProjectIds = useMemo(() => (
    getLinkedIds({ responses, defaultValue: projectIds, linkField })
  ), [responses, projectIds, linkField]);

  // Custom Data Maps

  const subContractsWithTotalChanges = useMemo(() => {
    if (dataType !== DATATYPES.SubContract) return [];
    return getSubContractsWithTotalChangesMap({
      projectIds: linkedProjectIds,
      subContractMap,
      subContractCOMap,
    });
  }, [dataType, linkedProjectIds, subContractMap, subContractCOMap]);

  const relevantPayableSubContractIds = useMemo(() => {
    if (dataType !== DATATYPES.PayableSubContracts) return [];
    const data = formatFormDropdownList({
      formList: subContractForms,
      projectIdMap,
      projectId: projectIds[0],
      shouldFilterByProjects: true,
    });
    return data.map(({ id: pId }) => pId);
  }, [subContractForms, projectIdMap, projectIds]);

  useEffect(() => {
    const fetchSubContractDetails = async () => {
      if (!relevantPayableSubContractIds.length) {
        setSubContractDetailsMap();
        return;
      }

      if (areArraysEqual(relevantPayableSubContractIds, lastRelevantPayableSubContractIds)) return;

      try {
        const {
          data: fetchedSubContractDetailsMap,
        } = await axios.get('/subcontracts/details', {
          params: {
            ids: relevantPayableSubContractIds,
          },
        });
        setLastRelevantPayableSubContractIds(relevantPayableSubContractIds);
        setSubContractDetailsMap(fetchedSubContractDetailsMap);
      } catch (error) {
        Sentry.withScope(() => {
          Sentry.captureException(new Error('Error fetching subcontract details map'), error);
        });
        setSubContractDetailsMap();
      }
    };
    if (dataType === DATATYPES.PayableSubContracts) fetchSubContractDetails();
  }, [
    dataType,
    relevantPayableSubContractIds,
    lastRelevantPayableSubContractIds,
  ]);

  const [lastIds = [], setLastIds] = useMemo(() => {
    switch (dataType) {
      case DATATYPES.Customer: return [lastCustomerIds, setLastCustomerIds];
      case DATATYPES.Contact: return [lastContactIds, setLastContactIds];
      case DATATYPES.ProjectContract:
      case DATATYPES.Project: return [lastProjectIds, setLastProjectIds];
      case DATATYPES.Vendor: return [lastVendorIds, setLastVendorIds];
      case DATATYPES.Costcodes: return [lastCostcodeIds, setLastCostcodeIds];
      case DATATYPES.User: return [lastUserIds, setLastUserIds];
      case DATATYPES.SubContract: return [lastSubContractIds, setLastSubContractIds];
      case DATATYPES.Buckets: return [lastBucketIds, setLastBucketIds];
      case DATATYPES.PayableSubContracts: return [
        lastPayableSubContractIds,
        setLastPayableSubContractIds,
      ];
      default: return [[], () => {}];
    }
  }, [
    dataType,
    lastCustomerIds,
    lastContactIds,
    lastProjectIds,
    lastVendorIds,
    lastCostcodeIds,
    lastSubContractIds,
    lastUserIds,
    lastBucketIds,
    lastPayableSubContractIds,
  ]);

  const [data, defaultValue = []] = useMemo(() => {
    switch (dataType) {
      case DATATYPES.Customer: return [customerIdMap, customerIds];
      case DATATYPES.Contact: return [globalAddressBooks, contactIds];
      case DATATYPES.ProjectContract: return [scheduleOfValues, projectIds];
      case DATATYPES.Project: return [projectIdMap, projectIds];
      case DATATYPES.Vendor: return [vendorIdMap, vendorIds];
      case DATATYPES.Costcodes: return [costcodeIdMap, costcodeIds];
      case DATATYPES.User: return [userIdMap, userIds];
      case DATATYPES.SubContract: return [subContractsWithTotalChanges, subContractIds];
      case DATATYPES.Buckets: return [Object.values(bucketTemplateMap).flat(), []];
      case DATATYPES.PayableSubContracts: {
        return [
          Object.values(subContractDetailsMap || {}),
          payableSubContractIds,
        ];
      }
      default: return [{}, []];
    }
  }, [
    dataType,
    customerIdMap,
    globalAddressBooks,
    scheduleOfValues,
    projectIdMap,
    vendorIdMap,
    costcodeIdMap,
    userIdMap,
    subContractsWithTotalChanges,
    bucketTemplateMap,
    subContractIds,
    subContractDetailsMap,
    payableSubContractIds,
    customerIds,
    contactIds,
    projectIds,
    vendorIds,
    costcodeIds,
    userIds,
  ]);

  const subContractRowMap = useMemo(() => (
    dataType === DATATYPES.SubContract
      ? data?.reduce?.((acc, subContract) => {
        acc[subContract.rowId] = subContract.id;
        return acc;
      }, {})
      : []
  ), [data]);

  useEffect(() => {
    if (dataType === DATATYPES.PayableSubContracts && !subContractDetailsMap) return;
    if (dataType === DATATYPES.Buckets && !hasBucketsLoaded) return;
    let idTransformer = noIdTransformer;

    if (dataType === DATATYPES.Costcodes) idTransformer = costCodeIdTransformer;
    if (dataType === DATATYPES.SubContract) {
      idTransformer = (subContractId) => subContractRowMap[subContractId] ?? subContractId;
    }

    const newItems = getLinkedValues({
      dataType,
      attribute,
      type,
      useFormAuthorAttributes,
      useParentAttributes,
      linkField,
      responses,
      lastIds,
      currentValue: realValue,
      data,
      defaultValue,
      idTransformer,
      authorId,
      extraProps: {
        equipment,
        projectEquipment,
        equipmentTypeMap,
        divSet,
      },
      selectedProject,
      timecardProjectId,
    });

    if (!newItems) return;

    const {
      newLastIds,
      newValue,
    } = newItems;

    if (!areArraysEqual(newLastIds, lastIds) && useInitialValue) {
      setUseInitialValue(false);
    }

    setLastIds(newLastIds);
    setAttr(newValue);
  }, [
    initialValue,
    useInitialValue,
    dataType,
    attribute,
    type,
    useFormAuthorAttributes,
    useParentAttributes,
    linkField,
    responses,
    lastIds,
    realValue,
    data,
    subContractDetailsMap,
    defaultValue,
    equipment,
    projectEquipment,
    equipmentTypeMap,
    divSet,
    setLastIds,
    hasBucketsLoaded,
    selectedProject,
    subContractRowMap,
    initialProjectIds,
    timecardProjectId,
  ]);

  // Clear on reload

  useEffect(() => {
    setLastCustomerIds();
    setLastProjectIds();
    setLastVendorIds();
    setLastContactIds();
    setLastCostcodeIds();
    setLastUserIds();
    setLastSubContractIds();
    setLastBucketIds();
  }, [id]);

  const displayValue = useMemo(() => {
    if (allowEdits) {
      return (
        <OnTraccrTextInput
          placeholder="Attribute Value Goes Here"
          onChange={onAttributeChange}
          value={realValue}
          textarea={type === 'equipment'}
          style={{
            resize: 'none',
          }}
          autoSize
          readOnly={isDisplay}
        />
      );
    }

    return (
      <DisplayText title={realValue} />
    );
  }, [onAttributeChange, realValue, type, allowEdits, responses, id, isDisplay]);

  return displayValue;
}

/* eslint-disable react/forbid-prop-types */
AttributePreviewField.propTypes = {
  id: PropTypes.string.isRequired,
  configProps: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string,
  })).isRequired,
  responses: PropTypes.object.isRequired,
  previewProps: PropTypes.object.isRequired,
  setResponses: PropTypes.func.isRequired,
  scheduleOfValues: PropTypes.object.isRequired,
  responding: PropTypes.bool.isRequired,
  equipment: PropTypes.array,
  projectEquipment: PropTypes.object,
  equipmentTypeMap: PropTypes.object,
  subContractMap: PropTypes.object,
  subContractCOMap: PropTypes.object,
  projectIdMap: PropTypes.shape({}),
  costcodeIdMap: PropTypes.shape({}),
  userIdMap: PropTypes.shape({}),
  customerIds: PropTypes.array,
  projectIds: PropTypes.array,
  vendorIds: PropTypes.array,
  contactIds: PropTypes.array,
  costcodeIds: PropTypes.array,
  userIds: PropTypes.array,
  subContractIds: PropTypes.array,
  payableSubContractIds: PropTypes.array,
  divisions: PropTypes.arrayOf(PropTypes.string),
  bucketTemplateMap: PropTypes.shape({}),
  isDisplay: PropTypes.bool,
  hasBucketsLoaded: PropTypes.bool,
};

AttributePreviewField.defaultProps = {
  equipment: [],
  projectEquipment: {},
  equipmentTypeMap: {},
  subContractMap: {},
  projectIdMap: {},
  costcodeIdMap: {},
  userIdMap: {},
  customerIds: [],
  projectIds: [],
  vendorIds: [],
  contactIds: [],
  costcodeIds: [],
  userIds: [],
  subContractIds: [],
  payableSubContractIds: [],
  divisions: [],
  subContractCOMap: {},
  bucketTemplateMap: {},
  isDisplay: false,
  hasBucketsLoaded: false,
};

export default AttributePreviewField;
