import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Popconfirm, Row } from 'antd';
import PropTypes from 'prop-types';

import CompanyEditRow from '../../CompanyEditRow';
import DivisionSelector from '../../../common/inputs/DivisionSelector';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import SageShiftMapping from './SageShiftMapping';
import SageProjectPayMapping from './SageProjectPayMapping';
import DisplayText from '../../../common/text/DisplayText';
import Colors from '../../../constants/Colors';
import { updateSageDivisions, updateSageName } from '../../../sage/state/sage.actions';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import SageTimeSyncDrawer from '../Sage100/SageTimeSyncDrawer';
import SageJobStatusSelector from './SageJobStatusSelector';
import useToggle from '../../../common/hooks/useToggle';
import { isNullOrUndefined } from '../../../helpers/helpers';
import SageJobTypeDrawer from '../Sage100/SageJobTypeDrawer';
import SageDefaultCostcodeSelector from './SageDefaultCostcodeSelector';

export default function SageSettings({
  version = 'sage300',
  showImport,
  product,
  selectedIntegration,
  isDrawer = false,
  visible = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    settings: {
      enableSageShiftSelection,
    } = {},
  } = useSelector((state) => state.settings.company || {});
  const integrations = useSelector((state) => state.sage.integrations);
  const divisions = useSelector((state) => state.settings.divisions);

  const [integrationName, setIntegrationName] = useState('');

  const {
    name,
    divisions: selectedDivisions = [],
  } = useMemo(() => (
    integrations.find(({ id }) => id === selectedIntegration?.id) ?? {}
  ), [selectedIntegration, integrations]);

  useEffect(() => {
    setIntegrationName(name);
  }, [name]);

  const availableDivisions = useMemo(() => {
    const usedDivisions = integrations.reduce((acc, { divisions: integrationDivisions = [] }) => {
      integrationDivisions.forEach((id) => acc.add(id));
      return acc;
    }, new Set());

    return new Set(
      Object.values(divisions)
        .filter(({ id }) => selectedDivisions.includes(id) || !usedDivisions.has(id))
        .map(({ id }) => id),
    );
  }, [integrations, divisions, selectedDivisions]);

  const updateDivisions = useCallback((newDivisions) => {
    if (selectedIntegration) {
      dispatch(updateSageDivisions(version, selectedIntegration.id, [newDivisions]));
    }
  }, [version, selectedIntegration]);

  const flavourText = useMemo(() => (
    <div>
      Deactivating your Sage integration will disable any
      further syncing between Ontraccr and Sage.
      <br />
      <br />
      To disconnect your {product} instance, please contact <b>support@ontraccr.com</b>
    </div>
  ), [product]);

  const updateName = () => {
    dispatch(updateSageName(version, selectedIntegration.id, integrationName));
  };

  const { isToggled: isTimeSyncDrawerOpen, toggle: toggleTimeSyncDrawer } = useToggle();
  const { isToggled: isJobTypeDrawerOpen, toggle: toggleJobTypeDrawer } = useToggle();

  const showPopover = visible
    && !isNullOrUndefined(integrationName)
    && !isNullOrUndefined(name)
    && name !== integrationName;

  return (
    <div style={{ width: '100%', overflowY: 'auto' }}>
      <CompanyEditRow
        title="Name"
        minWidth={isDrawer ? '100%' : 440}
        maxWidth={isDrawer ? '100%' : 440}
      >
        <Popconfirm
          title="Update Name"
          visible={showPopover}
          okText="Update"
          onConfirm={updateName}
          onCancel={() => setIntegrationName(name)}
        >
          <OnTraccrTextInput
            value={integrationName}
            onChange={(e) => setIntegrationName(e.target.value)}
          />
        </Popconfirm>
      </CompanyEditRow>
      <CompanyEditRow
        title="Divisions"
        helpText="Set which divisions are linked to this integration"
        minWidth={isDrawer ? '100%' : 440}
        maxWidth={isDrawer ? '100%' : 440}
      >
        <DivisionSelector
          divisionId={selectedDivisions?.[0]}
          onChange={updateDivisions}
          allowClear={false}
          filter={availableDivisions}
          style={{ width: 300 }}
        />
      </CompanyEditRow>
      {/* Commenting out below as we currently only want to support 1:1, but may in future support multi division to single integration
      { (version === 'sage100') && (
        <CompanyEditRow
          title="Default Division"
          helpText="Set which division automatic imports will be added to by default"
        >
          <DivisionSelector
            divisionId={defaultDivisionId}
            onChange={updateDefaultDivision}
            allowClear={false}
          />
        </CompanyEditRow>
      )} */}
      { (version === 'sage100') && (
        <>
          <CompanyEditRow
            title="Default Costcode"
            helpText="Set a default costcode to sync time under for entries with a linked job but no costcode"
          >
            <SageDefaultCostcodeSelector
              version={version}
              selectedIntegration={selectedIntegration}
            />
          </CompanyEditRow>
          <CompanyEditRow
            title="Job statuses to sync"
            helpText="Set which categories of job statuses should be synced"
          >
            <SageJobStatusSelector
              version={version}
              selectedIntegration={selectedIntegration}
            />
          </CompanyEditRow>
          <CompanyEditRow
            title={`Job to ${t('Project')} Type Mapping`}
            helpText={`Configure conditions for Jobs to sync in as different ${t('Project')} Types based on their name`}
          >
            <OnTraccrButton
              title="Open"
              style={{ marginRight: 45 }}
              onClick={toggleJobTypeDrawer}
            />
          </CompanyEditRow>
          <SageJobTypeDrawer
            visible={isJobTypeDrawerOpen}
            onClose={toggleJobTypeDrawer}
            selectedIntegration={selectedIntegration}
          />
        </>
      )}
      <CompanyEditRow
        title="Import from Sage"
        helpText={`Import Users, ${t('Project', { count: 2 })} and Cost Codes from Sage into Ontraccr.`}
        minWidth={isDrawer ? '100%' : 440}
        maxWidth={isDrawer ? '100%' : 440}
      >
        <OnTraccrButton
          title="Set Up"
          style={{ marginRight: 45 }}
          onClick={async () => {
            showImport();
          }}
        />
      </CompanyEditRow>
      { (version === 'sage300') && (
        <>
          {enableSageShiftSelection && (
            <SageShiftMapping
              selectedIntegration={selectedIntegration}
            />
          )}
          <SageProjectPayMapping
            selectedIntegration={selectedIntegration}
          />
        </>
      )}

      { (version === 'sage100') && (
        <CompanyEditRow
          title="Time Entry Sync Report"
          helpText="See the status of synced time entries and retry failures"
        >
          <OnTraccrButton
            title="Open"
            style={{ marginRight: 45 }}
            onClick={toggleTimeSyncDrawer}
          />
        </CompanyEditRow>
      )}
      <SageTimeSyncDrawer
        visible={isTimeSyncDrawerOpen}
        onClose={toggleTimeSyncDrawer}
        selectedIntegration={selectedIntegration}
      />

      <Row
        style={{
          minHeight: 32,
          width: '100%',
          paddingLeft: 10,
        }}
        justify="space-between"
        align="middle"
      >
        <Col>
          <DisplayText
            title="Deactivate Sage Integration"
            style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
          />
        </Col>
      </Row>
      <DisplayText
        title={flavourText}
        style={{
          marginBottom: 20,
          maxWidth: 275,
          paddingLeft: 10,
          fontFamily: 'roboto-regular',
          color: Colors.ONTRACCR_OPACITY_GRAY,
        }}
      />
    </div>
  );
}

SageSettings.propTypes = {
  version: PropTypes.string,
  showImport: PropTypes.func,
  product: PropTypes.string,
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string,
  }),
  isDrawer: PropTypes.bool,
  visible: PropTypes.bool,
};

SageSettings.defaultProps = {
  version: 'sage300',
  showImport: null,
  product: 'Sage 300 CRE',
  selectedIntegration: null,
  isDrawer: false,
  visible: false,
};
