import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { FlagOutlined } from '@ant-design/icons';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import FieldTriggerPopover from './FieldTriggerPopover';

function FieldTriggerFlag({
  sections,
  templateId,
  projectId,
  divisions,
  configProps,
  responding,
  name,
  onFieldTrigger,
}) {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Popover
      title="Trigger Form"
      placement="left"
      trigger="click"
      onVisibleChange={(visible) => setShowPopover(visible)}
      visible={showPopover}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      content={(
        <FieldTriggerPopover
          sections={sections}
          templateId={templateId}
          projectId={projectId}
          divisions={divisions}
          configProps={configProps}
          responding={responding}
          name={name}
          onFieldTrigger={onFieldTrigger}
          setShowPopover={setShowPopover}
        />
      )}
    >
      <BorderlessButton
        iconNode={<FlagOutlined />}
        style={{
          width: 'auto',
          height: 20,
          padding: 0,
          marginRight: 10,
          color: 'inherit',
        }}
      />
    </Popover>
  );
}

/* eslint-disable react/forbid-prop-types */
FieldTriggerFlag.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  templateId: PropTypes.string,
  projectId: PropTypes.string,
  divisions: PropTypes.arrayOf(PropTypes.string),
  configProps: PropTypes.object,
  responding: PropTypes.bool,
  name: PropTypes.string,
  onFieldTrigger: PropTypes.func,
};

FieldTriggerFlag.defaultProps = {
  sections: [],
  templateId: null,
  projectId: null,
  divisions: [],
  configProps: {},
  responding: false,
  name: null,
  onFieldTrigger: null,
};

export default FieldTriggerFlag;
