import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { toTitleCase } from 'ontraccr-common/lib/Common';
import { TaskHelpers } from 'ontraccr-common';

import { TIME_TYPE_FLAG } from '../../../../constants/Flags';
import TypeOfTimePicker from './TypeOfTimePicker';
import OnTraccrTimePicker from '../../../../common/inputs/OnTraccrTimePicker';

const TIME_FORMAT = 'hh:mm a';

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
export default function TimeEntryTableStartEndTimeInput(props) {
  const {
    hourBased,
    date,
    modifier,
    timezone,
    value,
    onChange,
    onConfirm,
  } = props;

  const [type, setType] = useState('regular');
  const [timePickerOpen, setTimePickerOpen] = useState(false);

  const { paidFlags = [] } = useSelector((state) => state.settings.company);
  const { settings = {} } = useSelector((state) => state.settings.company);

  const {
    enableManualOT = false,
    showOriginalTimes,
  } = settings;

  const hasTimeTypeFlag = useMemo(() => paidFlags.includes(TIME_TYPE_FLAG), [paidFlags]);
  const safeDate = useMemo(() => (date.isValid ? date : DateTime.local()), [date]);

  const timeKey = useMemo(() => {
    if (type === 'regular') {
      return `${modifier}Time`;
    }

    return `${type}${toTitleCase(modifier)}Time`;
  }, [type, modifier]);

  const millisValue = useMemo(() => {
    const getDisplayFunc = modifier === 'start' ? TaskHelpers.getDisplayStart : TaskHelpers.getDisplayEnd;
    const millis = type === 'regular' && showOriginalTimes
      ? getDisplayFunc(value, showOriginalTimes)
      : value[timeKey];

    return millis ?? null;
  }, [type, showOriginalTimes, value, timeKey, modifier]);

  const onTimeChange = (newTime) => {
    if (!newTime) return;
    const dt = DateTime.fromMillis(newTime);

    const newTimeMillis = safeDate
      .setZone(timezone)
      .set({ hour: dt.hour, minute: dt.minute }).toMillis();

    const newValue = {
      ...value,
      [timeKey]: newTimeMillis,
    };

    if (type === 'regular') {
      const originalKey = modifier === 'start' ? 'originalStart' : 'originalEnd';
      newValue[originalKey] = newTimeMillis;
    }

    onChange(newValue);
  };

  const InputComponent = hourBased ? (
    <></>
  ) : (
    <OnTraccrTimePicker
      {...props}
      date={safeDate}
      timezone={timezone}
      format={TIME_FORMAT}
      value={millisValue}
      open={timePickerOpen}
      onChange={onTimeChange}
      onOpenChange={(open) => {
        setTimePickerOpen(open);
        if (!open) {
          onConfirm();
        }
      }}
    />
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
      }}
    >
      {!hourBased && !!hasTimeTypeFlag && (
        <TypeOfTimePicker type={type} setType={setType} enableManualOT={enableManualOT} />
      )}
      {InputComponent}
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
TimeEntryTableStartEndTimeInput.propTypes = {
  date: PropTypes.instanceOf(DateTime).isRequired,
  timezone: PropTypes.string,
  hourBased: PropTypes.bool,
  value: PropTypes.object,
  modifier: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

TimeEntryTableStartEndTimeInput.defaultProps = {
  hourBased: false,
  value: {},
  timezone: DateTime.local().zoneName,
};
