import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DashboardComponent from './DashboardComponent';

import CustomExportsTable from '../reports/Exports/CustomExportsTable';

export default function DashboardCustomExportWidget({
  onDelete,
  isEdit,
  exportId,
  height,
}) {
  const customExports = useSelector((state) => state.reports.customExports);
  const ourExport = useMemo(() => customExports.find((e) => e.id === exportId), [customExports]);

  const [headerHeight, setHeightHeight] = useState(32);

  const onHeaderResize = useCallback((e) => {
    const { height: newHeight } = e ?? {};
    if (!newHeight) return;
    setHeightHeight(newHeight);
  }, []);

  return (
    <DashboardComponent
      title={ourExport?.title}
      onDelete={onDelete}
      isEdit={isEdit}
    >
      <div
        style={{
          height: 'calc(100% - 40px)',
          marginTop: 10,
          width: '100%',
          paddingBottom: 5,
        }}
      >
        <CustomExportsTable
          key={ourExport?.id}
          selectedExport={ourExport}
          scroll={height - (headerHeight - 32)}
          onHeaderResize={onHeaderResize}
        />
      </div>
    </DashboardComponent>
  );
}

/* eslint-disable react/forbid-prop-types */
DashboardCustomExportWidget.propTypes = {
  exportId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  height: PropTypes.number.isRequired,
};

DashboardCustomExportWidget.defaultProps = {
  isEdit: false,
};
