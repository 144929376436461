/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

import { TIME_TYPE_FLAG } from '../../../../constants/Flags';
import OnTraccrNumberInput from '../../../../common/inputs/OnTraccrNumberInput';
import TypeOfTimePicker from './TypeOfTimePicker';

function TimeEntryTableHoursInput(props) {
  const {
    date,
    timezone,
    hourBased = false,
    value,
    onChange,
    onBlur,
    onConfirm,
  } = props;

  const { paidFlags = [] } = useSelector((state) => state.settings.company);
  const { settings = {} } = useSelector((state) => state.settings.company);

  const {
    enableManualOT = false,
  } = settings;

  const [type, setType] = useState('regular');

  const hasTimeTypeFlag = useMemo(() => paidFlags.includes(TIME_TYPE_FLAG), [paidFlags]);
  const safeDate = useMemo(() => (date.isValid ? date : DateTime.local()), [date]);

  const [startTimeKey, endTimeKey] = useMemo(() => {
    if (type === 'regular') {
      return [
        'startTime',
        'endTime',
      ];
    }

    const start = `${type}StartTime`;
    const end = `${type}EndTime`;

    return [
      start,
      end,
    ];
  }, [type]);

  const luxonEnd = useMemo(() => {
    const endMillis = value[endTimeKey];
    const startMillis = value[startTimeKey];

    const end = endMillis ? DateTime.fromMillis(endMillis, { zone: timezone }) : null;
    const start = startMillis ? DateTime.fromMillis(startMillis, { zone: timezone }) : null;

    if (hourBased) {
      return end ? safeDate.set({
        hour: end.hour,
        minute: end.minute,
      }) : safeDate.set({
        hour: 0,
        minute: 0,
      });
    }

    if (!start) {
      return safeDate.set({
        hour: 0,
        minute: 0,
      });
    }

    const diffInHours = end.diff(start, 'hours').hours;
    return safeDate.set({
      hour: Math.floor(diffInHours),
      minute: Math.round((diffInHours % 1) * 60),
    });
  }, [safeDate, endTimeKey, startTimeKey, value, hourBased, timezone]);

  const handleDecimalChange = (decimalValue) => {
    if (!decimalValue || Number.isNaN(parseFloat(decimalValue))) {
      onChange({ ...value, [startTimeKey]: null, [endTimeKey]: null });
      return;
    }

    const totalMinutes = decimalValue * 60;

    if (hourBased) {
      const dt = DateTime.fromISO(date, { zone: timezone }).startOf('day').set({
        hour: Math.floor(totalMinutes / 60),
        minute: Math.round(totalMinutes % 60),
      });

      onChange({
        ...value,
        [startTimeKey]: dt.set({ hour: 0, minute: 0 }).toMillis(),
        [endTimeKey]: dt.toMillis(),
      });
      return;
    }

    const startMillis = value[startTimeKey];

    if (!startMillis) return;

    const startDt = DateTime.fromMillis(startMillis);
    const endMillis = startDt.plus({ minutes: totalMinutes }).toMillis();

    onChange({
      ...value,
      [endTimeKey]: endMillis,
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
      }}
    >
      {hasTimeTypeFlag && (
        <TypeOfTimePicker type={type} setType={setType} enableManualOT={enableManualOT} />
      )}
      <OnTraccrNumberInput
        min={0}
        max={24}
        step={0.1}
        precision={2}
        value={
          (luxonEnd.hour || luxonEnd.minute)
            ? luxonEnd.hour + luxonEnd.minute / 60
            : undefined
        }
        onChange={handleDecimalChange}
        parser={(newValue) => newValue.replace(/[^\d.]/g, '')}
        onPressEnter={onConfirm}
        onBlur={(event) => {
          if (!hasTimeTypeFlag) {
            onBlur?.(event);
          }
          onConfirm();
        }}
      />
    </div>
  );
}

TimeEntryTableHoursInput.propTypes = {
  date: PropTypes.instanceOf(DateTime).isRequired,
  timezone: PropTypes.string,
  hourBased: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  value: PropTypes.shape({
    startTime: PropTypes.number,
    endTime: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  onConfirm: PropTypes.func.isRequired,
};

TimeEntryTableHoursInput.defaultProps = {
  hourBased: false,
  timezone: DateTime.local().zoneName,
  style: {},
};

export default TimeEntryTableHoursInput;
