import YesNoField from './YesNoField';
import DropdownField from './DropdownField';
import TextInput from './TextInput';
import AttachmentField from './AttachmentField';
import TableField from './TableField';
import CalculationField from './CalculationField';
import DateRangeField from './DateRangeField';
import DateTimeField from './DateTimeField';
import AttributeField from './AttributeField';
import PaymentField from './PaymentField';
import MultiSignatureField from './MultiSignatureField';
import StaticText from './StaticText';
import StaticAttachments from './StaticAttachments';
import GPSLocationFormField from './GPSLocationFormField';
import WeatherField from './WeatherField';
import DynamicAttribute from './DynamicAttribute/DynamicAttribute';

export default {
  'yes-no': YesNoField,
  dropdown: DropdownField,
  text: TextInput,
  attachment: AttachmentField,
  table: TableField,
  calculation: CalculationField,
  dateRange: DateRangeField,
  dateTime: DateTimeField,
  attribute: AttributeField,
  payment: PaymentField,
  multiSig: MultiSignatureField,
  staticText: StaticText,
  staticAttachments: StaticAttachments,
  gpsLocation: GPSLocationFormField,
  weather: WeatherField,
  dynamicAttribute: DynamicAttribute,
};

export const ADD_DRAWER_PROPS = {
  staticAttachments: {
    push: { distance: 600 },
    width: 400,
  },
};
