/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table, Row, Drawer, Col, Spin,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import { settingsColumns } from '../../boards/boardHelpers';
import CustomFieldFormMappingDrawer from '../../forms/CustomFieldFormMappingDrawer/CustomFieldFormMappingDrawer';
import {
  createCustomTypeFormMapping,
  deleteCustomTypeFormMapping,
  getCustomTypeFormMappings,
  updateCustomTypeFormMapping,
} from '../state/settings.actions';
import { getTemplates } from '../../forms/state/forms.actions';

export default function TypesFormMappingsDrawer({
  visible,
  onClose,
  type,
  typeId,
  divisions,
}) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const boardFormMappings = useSelector((state) => state.boards.formMappings);
  const equipmentFormMappings = useSelector((state) => state.equipment.formMappings);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState({});

  const formMappings = useMemo(() => {
    if (type === 'card') {
      return boardFormMappings;
    }
    if (type === 'equipment') {
      return equipmentFormMappings[typeId];
    }
    return [];
  }, [type, typeId, boardFormMappings, equipmentFormMappings]);

  useEffect(() => {
    const load = async () => {
      await dispatch(getCustomTypeFormMappings(type, typeId));
      await dispatch(getTemplates());
      setLoading(false);
    };

    setLoading(true);
    load();
  }, [type, typeId]);

  const showDrawer = useCallback(() => setDrawerVisible(true), []);
  const hideDrawer = useCallback(() => {
    setDrawerVisible(false);
    setSelectedMapping({});
  }, []);

  const onMappingSave = useCallback((newMapping) => {
    const {
      id,
    } = selectedMapping;
    const {
      mappingName,
      selectedForm,
      fieldMappings,
      useFormPermissions,
    } = newMapping;
    const boolUseForm = !!useFormPermissions;
    if (!id) {
      dispatch(createCustomTypeFormMapping(type, {
        name: mappingName,
        typeId,
        formTemplateId: selectedForm,
        fieldMappings,
        useFormPermissions: boolUseForm,
      }));
    } else {
      dispatch(updateCustomTypeFormMapping(type, id, {
        name: mappingName,
        typeId,
        formTemplateId: selectedForm,
        fieldMappings,
        useFormPermissions: boolUseForm,
      }));
    }
    hideDrawer();
  }, [dispatch, formMappings, selectedMapping, hideDrawer, type, typeId]);

  const onEdit = useCallback((record) => () => {
    setSelectedMapping(record);
    setDrawerVisible(true);
  }, [setDrawerVisible, setSelectedMapping]);

  const onDelete = useCallback((record) => () => {
    const { id } = record;
    dispatch(deleteCustomTypeFormMapping({ type, typeId, id }));
  }, [dispatch, type, typeId]);

  const columns = useMemo(settingsColumns(onEdit, onDelete), [onEdit, onDelete]);

  return (
    <Drawer
      title="Form Mappings"
      width={1000}
      onClose={onClose}
      visible={visible}
      bodyStyle={{
        padding: '0px 24px',
        marginBottom: 53,
      }}
    >
      <div>
        <Row style={{ marginTop: 10 }}>
          <OnTraccrButton title="Add" icon={<PlusOutlined />} onClick={showDrawer} disabled={loading} />
        </Row>
        <div className="file-list-container" style={{ marginTop: 15 }}>
          <Table
            dataSource={formMappings}
            columns={columns}
            size="small"
            pagination={false}
            scroll
            disabled={loading}
          />
        </div>
      </div>
      <CustomFieldFormMappingDrawer
        visible={drawerVisible}
        type={type}
        profileTypeId={typeId}
        divisions={divisions}
        onSubmit={onMappingSave}
        onClose={hideDrawer}
        data={selectedMapping}
        isSettings
      />
      {loading && (
        <Row
          style={{ position: 'absolute', inset: 0 }}
          justify="center"
          align="middle"
        >
          <Col>
            <Spin />
          </Col>
        </Row>
      )}
    </Drawer>
  );
}

TypesFormMappingsDrawer.propTypes = {
  divisions: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  typeId: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

TypesFormMappingsDrawer.defaultProps = {
  divisions: [],
  visible: false,
};
