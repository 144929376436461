import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { DateTime } from 'luxon';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import FormTextInput from '../common/inputs/FormTextInput';

import { getApprovals } from '../clock/state/clockin.actions';
import TimeTracking from '../state/timeTracking';

export const APPROVE_TIMECARD_SUBMISSION = 'Approve';
export const REJECT_TIMECARD_SUBMISSION = 'Reject';

function TimeCardApprovalButton({
  type,
  style = {},
  record,
  userId,
  onSubmit,
  isSummary,
}) {
  const dispatch = useDispatch();
  const initialValues = { note: null };
  const [form] = Form.useForm();

  const RejectionNote = (
    <Form form={form} initialValues={initialValues}>
      <FormTextInput
        name="note"
        isNotDisplay
        placeholder="Enter a rejection message"
        textProps={{
          autoComplete: 'off',
          textarea: true,
          rows: 4,
        }}
      />
    </Form>
  );

  const onApprove = useCallback(async (approved, note, tasks, day) => {
    const action = approved ? TimeTracking.approveTimecards : TimeTracking.rejectTimecards;
    const approvalTasks = tasks.map((task) => ({
      id: task.id,
      projectId: task.projectId,
    }));
    const payload = {
      tasks: approvalTasks,
      note,
      userId,
      day,
    };
    if (await dispatch(action(payload))) dispatch(getApprovals('On Approve/Reject'));
    if (onSubmit) onSubmit();
  }, [onSubmit, userId]);

  const showModal = useCallback((e) => (buttonType) => {
    e.stopPropagation();
    const approved = buttonType === APPROVE_TIMECARD_SUBMISSION;
    let day;
    let title;
    if (isSummary) {
      day = DateTime.fromMillis(record.endTime).toLocaleString(DateTime.DATE_FULL);
      title = `${buttonType} Time Entry: ${day}`;
    } else {
      day = `${record.month} ${record.dayOfMonth}`;
      title = `${buttonType} Timecard: ${day}, ${record.year}?`;
    }
    const tasks = isSummary ? [record] : (record?.dayTasks ?? []);
    CustomConfirmModal({
      title,
      okText: 'Confirm',
      cancelText: 'Cancel',
      content: !approved
        ? RejectionNote
        : null,
      onOk: async () => {
        const values = form.getFieldValue();
        if (values.note === '') values.note = null;
        await onApprove(approved, values.note, tasks, day);
      },
    });
  }, [record, onApprove, form, isSummary]);

  return (
    <OnTraccrButton
      title={type}
      onClick={(e) => showModal(e)(type)}
      style={{ margin: 2, ...style }}
    />
  );
}

/* eslint-disable react/forbid-prop-types */
TimeCardApprovalButton.propTypes = {
  record: PropTypes.object.isRequired,
  style: PropTypes.object,
  type: PropTypes.oneOf([APPROVE_TIMECARD_SUBMISSION, REJECT_TIMECARD_SUBMISSION]).isRequired,
  userId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  isSummary: PropTypes.bool.isRequired,
};

TimeCardApprovalButton.defaultProps = {
  style: {},
  onSubmit: null,
};

export default TimeCardApprovalButton;
