import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import {
  Divider,
  Drawer,
  Form,
  Tabs,
  Badge,
  Row,
  Col,
  Select,
  message,
  Spin,
  DatePicker,
  Checkbox,
  Button,
  Tooltip,
} from 'antd';
import { CloseOutlined, FormOutlined, WarningOutlined } from '@ant-design/icons';
import { Trie } from 'ontraccr-common';
import moment from 'moment';

import config from '../config';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import FilteredUserSelector from '../common/inputs/FilteredUserSelector';
import FormTextInput from '../common/inputs/FormTextInput';
import DisplayText from '../common/text/DisplayText';
import FullPhoto from '../common/photos/FullPhoto';
import FormColorPicker from '../common/inputs/FormColorPicker';
import Notes from '../notes/Notes';
import CustomerClientCommunications from '../contacts/customers/CustomerClientCommunications';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import DrawerFooter from '../common/containers/DrawerFooter';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import SocketService from '../sockets/SocketService';
import ProfileCircle from '../common/profile/ProfileCircle';
import colors from '../constants/Colors';

import FormBuilder from '../forms/FormBuilder/FormBuilder';
import FormResponseFields from '../forms/FormResponseFields';
import {
  getErrorsFromResponses,
  prepareResponsePayload,
  formsValuesAreDifferent,
} from '../forms/ResponderHelpers';
import {
  getRelevantSnapshotData,
  getSectionPermissionMap,
  parseCompletedForm,
  parseFilteredForm,
} from '../forms/formHelpers';

import Subtasks from '../subtasks/Subtasks';

import { downloadFile } from '../files/fileHelpers';
import { getIdMap, compareStringArrays } from '../helpers/helpers';
import Debouncer from '../helpers/Debouncer';

import { validateLink } from './boardHelpers';

import BoardLinkSelector from './BoardLinkSelector';
import BoardCardTimeline from './BoardCardTimeline';
import BoardCardEmails from './BoardCardEmails/BoardCardEmails';
import BoardCardStatusMoveWarningModal from './BoardCardStatusMoveWarningModal';
import BoardSaveModal from './BoardSaveModal';
import BoardCardAnalytics from './BoardAnalytics/BoardCardAnalytics';
import BoardCardShadowText from './BoardCardShadowText';
import BoardCardUpload from './BoardCardUpload/BoardCardUpload';
import BoardCardShadowClearModal from './BoardCardShadowClearModal';
import BoardCloseModal from './BoardCloseModal';

import {
  createCard,
  getBoardCardFiles,
  updateCard,
  addCardNote,
  getCardNotes,
  deleteCard,
  markCardTagsAsRead,
  moveCard,
  closeCardDrawer,
  uploadCards,
  getCardAttachments,
  getCardById,
  clearSelectedCard,
  getCardSnapshots,
  setSnapshotCardData,
  clearSnapshotCardData,
  setBoardTargetCard,
  clearShadow,
} from './state/boards.actions';
import BoardCardForms from './BoardCardForms';
import BorderlessButton from '../common/buttons/BorderlessButton';
import { onNextFileClick, showLeftFileCyleButton, showRightFileCycleButton } from '../helpers/fileHelpers';
import Permissions from '../auth/Permissions';
import useDivisionUsers from '../common/hooks/useDivisionUsers';
import AttachmentTab from '../attachment/AttachmentTab';
import HoverHelp from '../common/HoverHelp';
import BoardDatePicker from './BoardDatePicker';
import useToggle from '../common/hooks/useToggle';
import SnapshotDrawer from '../common/customFields/SnapshotDrawer';
import { getCustomFields } from '../timecards/state/timecards.actions';

import useBoardWriteAssignedPermissions from '../common/hooks/useBoardWriteAssignedPermissions';
import BoardCardReassignmentDrawer from './BoardCardReassignmentDrawer';

const { TabPane } = Tabs;
const DEFAULT_COLOR = '#FFFFFFFF';
const DETAILS_TAB_KEY = 'details';
const ANALYTICS_TAB_KEY = 'analytics';
const FORMS_TAB_KEY = 'forms';
const NOTES_TAB_KEY = 'notes';
const TIMELINE_TAB_KEY = 'timeline';
const CLIENT_COMMS_KEY = 'client_comms';
const EMAIL_KEY = 'emails';
const TASK_KEY = 'tasks';
const CREATE_TAB_KEY = 'create';
const UPLOAD_TAB_KEY = 'upload';
const ATTACHMENTS = 'attachments';

const LAST_UPLOAD_STEPS = 2;

const FOOTER_HEIGHT = 53;

const debouncer = new Debouncer();

const getHighestIndex = (cards = [], newStatusId) => (
  cards.reduce((curMax, card) => {
    if (card.statusId !== newStatusId) return curMax;
    return Math.max(curMax, card.orderIndex);
  }, -1)
);

const tabFillStyle = {
  position: 'absolute',
  left: 24,
  right: 24,
  top: 125,
  bottom: 0,
  width: 'auto',
};

export default function BoardCardDrawer({
  boardId,
  cardTypeId,
  divisions: boardDivisions = [],
  showCardNumber,
  useCardNumberAsCardTitle,
  userBoardPermissions = {},
  onBoardSelected,
}) {
  const {
    state: { activeTab: locationActiveTab } = {},
  } = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const cardTemplates = useSelector((state) => state.boards.cardTemplates);
  const users = useSelector((state) => state.users.users);
  const userTrie = useSelector((state) => state.users.userTrie);
  const cardFileMap = useSelector((state) => state.boards.cardFileMap);
  const cardNotesMap = useSelector((state) => state.boards.cardNotesMap);
  const selectedBoard = useSelector((state) => state.boards.selectedBoard);
  const boards = useSelector((state) => state.boards.boards);
  const company = useSelector((state) => state.settings.company);
  const tagsPerCard = useSelector((state) => state.boards.tagsPerCard);
  const myCardTags = useSelector((state) => state.boards.myCardTags);
  const projects = useSelector((state) => state.projects.projects);
  const portalAccounts = useSelector((state) => state.clientPortal.accounts);
  const unreadClientComms = useSelector((state) => state.customers.unreadClientComms);
  const attachments = useSelector((state) => state.boards.cardAttachments);
  const {
    visible,
    selectedCardId,
    statusId,
    editMode: propEdit,
    isLocked,
    onForm,
  } = useSelector((state) => state.boards.drawerConfig);
  const reduxCard = useSelector((state) => state.boards.selectedCard);
  const cardSnapshotMap = useSelector((state) => state.boards.cardSnapshotMap);
  const globalFileMap = useSelector((state) => state.files.fileMap);

  const {
    isFormsPermitted,
    writePerms,
    canCreate,
    canAssign,
    assignPerms,
  } = userBoardPermissions;

  const {
    statuses = [],
    cards = [],
    workflows = [],
  } = selectedBoard || {};

  const selectedCard = reduxCard?.id === selectedCardId ? reduxCard : null;
  const {
    id,
    title: selectedTitle,
    number: cardNumber,
    users: cardUsers,
    data,
    link: initialLink,
    color,
    statusId: cardStatusId,
    customerId: initialCustomerId,
    vendorId: initialVendorId,
    dateRange: selectedDateRange,
  } = selectedCard ?? {};

  const isShadow = selectedCard && selectedCard.boardId !== boardId;
  const boardPermsCanEdit = useBoardWriteAssignedPermissions({
    userBoardPermissions,
    cardUsers,
  });
  const canEdit = boardPermsCanEdit && !isShadow;

  const canAssignCard = useMemo(() => {
    if (isShadow) return false;
    if (canAssign) return true;
    if (assignPerms === 'assignAssigned') return cardUsers?.includes(Permissions.id);
    return false;
  }, [canAssign, assignPerms, cardUsers, isShadow]);

  const [initialStatusId, setInitialStatusId] = useState(statusId ?? cardStatusId);

  useEffect(() => {
    setInitialStatusId(statusId ?? cardStatusId);
  }, [statusId, cardStatusId]);

  const [responses, setResponses] = useState();
  const [submitTried, setSubmitTried] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileDetails, setSelectedFileDetails] = useState();
  const [activeTab, setActiveTab] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasInitialLoaded, setHasInitialLoaded] = useState(false);
  const [activeCreateUploadTab, setActiveCreateUploadTab] = useState(CREATE_TAB_KEY);
  const [uploadFile, setUploadFile] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [massUploadData, setMassUploadData] = useState();
  const [customerId, setCustomerId] = useState(initialCustomerId);
  const [vendorId, setVendorId] = useState(initialVendorId);
  const [dataLoading, setDataLoading] = useState(false);
  const [range, setRange] = useState(); // snapshot range
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [hasGotAttachments, setHasGotAttachments] = useState(false);
  const [showReassignDrawer, setShowReassignDrawer] = useState(false);

  const [showDate, setShowDate] = useState(!!selectedDateRange?.startDate);
  const [editingUsers, setEditingUsers] = useState([]);
  const currentUserId = Permissions.id;

  useEffect(() => {
    if (editMode) {
      SocketService.startEditingBoardCard(id, currentUserId);
    } else {
      SocketService.stopEditingBoardCard(id, currentUserId);
    }

    const handleBeforeUnload = () => {
      SocketService.stopEditingBoardCard(id, currentUserId);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      SocketService.stopEditingBoardCard(id, currentUserId);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [editMode, id]);

  useEffect(() => {
    const handleEditingBoardCard = (editors) => {
      // Exclude the current user from the list of editors
      setEditingUsers(editors.filter(({ userId }) => userId !== currentUserId));
    };

    SocketService.listenForBoardCardEditing(id, handleEditingBoardCard);

    return () => {
      SocketService.stopListeningForBoardCardEditing(id);
    };
  }, [id]);

  const relevantUsers = useMemo(() => {
    if (selectedCard) return cardUsers || [];
    if (!form || writePerms !== 'writeAssigned' || !Permissions.id || selectedCard || !visible) return [];
    return [Permissions.id];
  }, [
    form,
    writePerms,
    Permissions,
    selectedCard,
    visible,
  ]);

  const toggleShowDate = (e) => {
    const { target: { checked } } = e;
    setShowDate(checked);

    if (!checked) {
      form.setFieldsValue({
        dateRange: { startDate: null, endDate: null },
      });
    }
  };

  useEffect(() => {
    setShowDate(!!selectedDateRange?.startDate);
  }, [selectedDateRange]);

  const {
    isToggled: showSnapshotDrawer,
    toggle: toggleSnapshotDrawer,
  } = useToggle();

  const isCreate = activeCreateUploadTab === CREATE_TAB_KEY;

  const safeColor = color || DEFAULT_COLOR;
  const isAdd = !id;
  const isDisplay = !isAdd && !editMode;

  const {
    [id]: myTagCount = 0,
  } = tagsPerCard;

  const snapshots = useMemo(() => cardSnapshotMap[id] || [], [cardSnapshotMap, id]);

  const initialData = useMemo(() => (data ? { sections: data } : null), [data]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const moveBoardTriggers = useMemo(() => {
    const ourTriggers = workflows.filter((wf) => wf.type === 'moveBoard');
    const triggerMap = {};
    ourTriggers.forEach((trigger) => {
      triggerMap[trigger.initialStatusId] = trigger.name;
    });
    return triggerMap;
  }, [workflows]);
  const statusMap = useMemo(() => getIdMap(statuses), [statuses]);
  const inferredShowCardNumber = useCardNumberAsCardTitle ? false : showCardNumber;

  const ourCustomerId = useMemo(() => {
    if (!initialLink) return null;
    const {
      linkType,
      linkId,
    } = initialLink;
    if (linkType === 'customerId') return linkId;
    if (linkType === 'projectId') {
      const {
        [linkId]: { customerId: projectCustomer } = {},
      } = projectMap;
      return projectCustomer;
    }
    return null;
  }, [initialLink, projectMap]);

  const hasClientAccounts = useMemo(() => (
    portalAccounts.filter((acc) => acc.customerId === ourCustomerId).length > 0
  ), [ourCustomerId, portalAccounts]);

  const numClientCommsUnread = useMemo(() => (
    unreadClientComms.filter((note) => note.customerId === ourCustomerId).length
  ), [unreadClientComms, ourCustomerId]);

  const {
    boardUserTrie = {},
    boardUsers = [],
  } = useMemo(() => {
    const {
      [boardId]: {
        isPublic,
        creatorId,
      } = {},
    } = boards;
    if (isPublic) return { boardUsers: users, boardUserTrie: userTrie };
    const { userId: ownerId } = company || {};
    const { users: bUsers = [] } = selectedBoard ?? {};
    const bSet = new Set(bUsers.map((u) => u.userId));
    const fullUsers = [];
    const fullUserTrie = {};
    users.forEach((user) => {
      if (bSet.has(user.id) || user.id === ownerId || user.id === creatorId) {
        fullUsers.push(user);
        Trie.addToTrie(fullUserTrie, user);
      }
    });
    return {
      boardUsers: fullUsers,
      boardUserTrie: fullUserTrie,
    };
  }, [boards, selectedBoard, boardId, users, userTrie, company]);

  const fileMap = useMemo(() => {
    const {
      [id]: ourFileMap = {},
    } = cardFileMap;
    return ourFileMap;
  }, [cardFileMap, id]);
  const userMap = useMemo(() => getIdMap(users), [users]);
  const userOptions = useDivisionUsers();

  const ourCardTemplate = useMemo(() => {
    const {
      [cardTypeId]: template = {},
    } = cardTemplates;
    return template;
  }, [cardTypeId, cardTemplates]);

  const unreadNoteIds = useMemo(() => (
    new Set(myCardTags
      .filter((tag) => tag.cardId === id)
      .map((tag) => tag.cardNoteId))
  ), [myCardTags, id]);

  const {
    title: templateTitle = 'a',
    fields: sections,
  } = ourCardTemplate;

  const displaySections = useMemo(() => {
    if (!data || !Array.isArray(data)) return sections ?? [];
    const templateMap = {};
    sections?.forEach(({ id: sectionId, fields = [], settings }) => {
      if (!(sectionId in templateMap)) {
        templateMap[sectionId] = {
          _Settings: settings,
          settings,
        };
      }
      const subMap = templateMap[sectionId];
      fields.forEach((field) => {
        const { id: fieldId } = field;
        subMap[fieldId] = field;
      });
    });

    return parseCompletedForm({
      sections: data,
      fileMap,
      setSelectedFile,
      setSelectedFileDetails,
      templateMap,
    });
  }, [data, sections, fileMap]);

  const positionNames = useSelector((state) => state.settings.positionNames);
  const sectionPermissionMap = useMemo(() => getSectionPermissionMap({
    sections: isDisplay ? displaySections : (sections ?? []),
    users,
    positionNames,
    userId: Permissions.id,
  }), [sections, users, positionNames]);

  const showLeft = useMemo(
    () => showLeftFileCyleButton({ selectedFile, selectedFileDetails }),
    [selectedFile, selectedFileDetails],
  );

  const showRight = useMemo(
    () => showRightFileCycleButton({ selectedFile, selectedFileDetails, fileMap }),
    [selectedFile, selectedFileDetails, fileMap],
  );

  // snapshot data
  const relevantFormData = useMemo(() => (
    getRelevantSnapshotData({
      formData: displaySections,
      range,
      snapshots,
      setSelectedFile,
      setSelectedFileDetails,
    })
  ), [
    range,
    displaySections,
    snapshots,
  ]);

  const snapshotRange = useMemo(() => {
    if (snapshots.length > 1) {
      return [
        moment.unix(snapshots[snapshots.length - 1].timestamp / 1000).startOf('day'),
        moment.unix(snapshots[0].timestamp / 1000).endOf('day'),
      ];
    }
    return null;
  }, [snapshots]);

  const disabledDate = useCallback((current) => {
    if (!snapshotRange) return false;
    return current < snapshotRange[0] || current > snapshotRange[1];
  }, [snapshotRange]);

  useEffect(() => {
    if (visible && range?.length === 2) {
      const parsedFormData = parseFilteredForm(relevantFormData);
      dispatch(setSnapshotCardData(parsedFormData));
    } else {
      dispatch(clearSnapshotCardData());
    }
  }, [range, relevantFormData, visible]);

  const cleanup = useCallback(() => {
    setResponses({});
    setErrors({});
    setSubmitTried(false);
    setSelectedFile();
    setSelectedFileDetails();
    setEditMode(false);
    setActiveTab(DETAILS_TAB_KEY);
    form.resetFields();
    setLoading(false);
    setHasInitialLoaded(false);
    setActiveCreateUploadTab(CREATE_TAB_KEY);
    setUploadFile();
    setCurrentStep(0);
    setMassUploadData();
    setVendorId();
    setCustomerId();
    setRange();
    dispatch(clearSelectedCard());
    setHasGotAttachments(false);
    setAttachmentLoading(false);
    setShowReassignDrawer(false);
  }, [form]);

  const onNext = useCallback((isRight) => () => onNextFileClick({
    isRight,
    selectedFileDetails,
    fileMap,
    globalFileMap,
    dispatch,
    setSelectedFile,
    setSelectedFileDetails,
  }), [
    selectedFileDetails,
    fileMap,
    setSelectedFile,
    setSelectedFileDetails,
    globalFileMap,
  ]);

  const onDownload = useCallback(() => {
    const file = selectedFile instanceof File ? selectedFile : globalFileMap[selectedFile.id];
    downloadFile({ fileObject: file });
  }, [selectedFile]);

  const onTabChanged = useCallback(async (newActiveTab) => {
    setActiveTab(newActiveTab);
    if (
      newActiveTab === ATTACHMENTS
      && !attachmentLoading
      && !hasGotAttachments
    ) {
      setAttachmentLoading(true);
      const passed = await dispatch(getCardAttachments(id));
      setAttachmentLoading(false);
      if (passed) setHasGotAttachments(true);
    }
  }, [unreadNoteIds, id, attachmentLoading, hasGotAttachments]);

  const clearSelectedFile = useCallback(() => {
    setSelectedFile();
    setSelectedFileDetails();
  }, []);

  const onStatusChanged = useCallback(async (newStatusId) => {
    const {
      orderIndex: sourceIndex,
      statusId,
      color,
    } = selectedCard ?? {};
    if (statusId === newStatusId) return;
    const {
      cards = [],
    } = selectedBoard ?? {};
    const highestIndex = getHighestIndex(cards, newStatusId);
    const movePayload = {
      boardId,
      destinationId: newStatusId,
      destinationIndex: highestIndex + 1,
      sourceId: statusId,
      sourceIndex,
      color,
    };
    if (newStatusId in moveBoardTriggers) {
      BoardCardStatusMoveWarningModal({
        statusName: (statusMap[newStatusId] ?? {}).title,
        workflowName: moveBoardTriggers[newStatusId],
        onOk: async () => {
          if (await dispatch(moveCard(id, movePayload))) {
            dispatch(closeCardDrawer());
            message.success('Status Changed');
          }
        },
      });
    } else if (await dispatch(moveCard(id, movePayload))) {
      message.success('Status Changed');
    }
  }, [dispatch, id, boardId, selectedCard, selectedBoard, moveBoardTriggers, statusMap]);

  const onDelete = useCallback(() => {
    CustomConfirmModal({
      title: `Delete ${selectedTitle}?`,
      onOk: async () => {
        if (await dispatch(deleteCard(id))) {
          dispatch(closeCardDrawer());
        }
      },
    });
  }, [dispatch, id, selectedTitle]);

  const onSubmit = useCallback(async () => {
    if (isDisplay) {
      setEditMode(true);
      return;
    }
    setSubmitTried(true);
    setLoading(true);
    try {
      await form.validateFields();
      const {
        errorMap,
        wentThroughFields,
      } = getErrorsFromResponses({ sections: sections ?? [], responses, sectionPermissionMap });
      if ((!editMode && !wentThroughFields) || Object.keys(errorMap).length) {
        setLoading(false);
        return;
      }

      const {
        users: newUsers,
        title,
        link,
        dateRange,
        color: newColor,
        statusId: formStatusId,
      } = form.getFieldsValue();
      const newStatusId = isLocked ? initialStatusId : formStatusId;
      const highestIndex = getHighestIndex(cards, newStatusId);
      const { responses: preparedResponses, collected } = prepareResponsePayload({
        responses,
        sections: sections ?? [],
        customerId,
        vendorId,
      });
      if (editMode) {
        const {
          added: addedUsers,
          removed: removedUsers,
        } = compareStringArrays(cardUsers, newUsers);
        const editPayload = {
          title,
          addedUsers,
          removedUsers,
          responses: preparedResponses,
          dateRange,
          link,
          color: newColor,
          statusId: newStatusId,
          collected,
        };
        if (newStatusId in moveBoardTriggers) {
          BoardCardStatusMoveWarningModal({
            statusName: (statusMap[newStatusId] ?? {}).title,
            workflowName: moveBoardTriggers[newStatusId],
            onOk: async () => {
              if (await dispatch(updateCard(id, editPayload))) {
                dispatch(closeCardDrawer());
              }
            },
          });
        } else if (await dispatch(updateCard(id, editPayload))) {
          dispatch(closeCardDrawer());
        }

        setLoading(false);
        return;
      }

      if (await dispatch(createCard({
        title,
        users: newUsers,
        boardId,
        statusId: newStatusId,
        cardTypeId,
        responses: preparedResponses,
        dateRange,
        orderIndex: highestIndex + 1,
        link,
        color: newColor,
        collected,
      }))) {
        dispatch(closeCardDrawer());
      }
    } catch (err) {
      //
    }
    setLoading(false);
  }, [
    dispatch,
    sections,
    form,
    responses,
    boardId,
    cardTypeId,
    cards,
    isDisplay,
    id,
    editMode,
    cardUsers,
    sectionPermissionMap,
    customerId,
    vendorId,
    isLocked,
    initialStatusId,
  ]);

  const onCancel = useCallback(() => {
    if (loading) return;
    if (editMode) {
      const valuesHaveChanged = formsValuesAreDifferent({
        initialData: initialData || {},
        fileMap,
        sections: sections ?? [],
        responses,
      });
      if (valuesHaveChanged) {
        BoardSaveModal({
          onCancel: () => {
            setEditMode(false);
          },
          onSave: onSubmit,
        });
      } else {
        setEditMode(false);
      }
    } else if (!selectedCardId) {
      BoardCloseModal({
        onOk: () => {
          dispatch(closeCardDrawer());
        },
      });
    } else {
      dispatch(closeCardDrawer());
    }
  }, [editMode, responses, initialData, sections, fileMap, onSubmit, selectedCardId]);

  const onClose = useCallback(() => {
    if (loading) return;
    if (!editMode) {
      if (!selectedCardId) {
        BoardCloseModal({
          onOk: () => {
            dispatch(closeCardDrawer());
          },
        });
      } else {
        dispatch(closeCardDrawer());
      }
    } else {
      const valuesHaveChanged = formsValuesAreDifferent({
        initialData: initialData || {},
        fileMap,
        sections: sections ?? [],
        responses,
      });
      if (valuesHaveChanged) {
        BoardSaveModal({
          onCancel: () => {
            dispatch(closeCardDrawer());
          },
          onSave: onSubmit,
        });
      } else {
        dispatch(closeCardDrawer());
      }
    }
  }, [
    onSubmit,
    editMode,
    responses,
    initialData,
    sections,
    fileMap,
    loading,
    selectedCardId,
  ]);

  const onFormClicked = useCallback((e) => {
    e.stopPropagation();
    onForm(id);
  }, [onForm, id]);

  const loadData = async (cardId) => {
    await dispatch(getCardById(cardId));
    await dispatch(getBoardCardFiles(cardId));
    await dispatch(getCardSnapshots(cardId));
    dispatch(getCustomFields());
    setDataLoading(false);
    setHasInitialLoaded(true);
  };

  const onMassUploadBack = useCallback(() => setCurrentStep(currentStep - 1), [currentStep]);
  const onMassUploadNext = useCallback(async () => {
    if (isCreate) {
      onSubmit();
    } else if (currentStep < LAST_UPLOAD_STEPS) {
      if (currentStep === 0) {
        if (!initialStatusId) {
          message.error('A status must be selected');
          return;
        }
      }
      if (currentStep === 1) {
        const {
          link: {
            linkType,
            linkId,
          } = {},
        } = massUploadData;
        if (linkType && !linkId) {
          message.error('A link must be selected');
          return;
        }
      }
      setCurrentStep(currentStep + 1);
    } else {
      setLoading(true);

      let highestIndex = getHighestIndex(cards, initialStatusId);

      const filteredUpload = [];
      const { link = {}, data: uploadData = [] } = massUploadData;
      uploadData.forEach((card) => {
        if (!card.title) return;

        // prepare custom form data
        const customResponses = {};
        Object.keys(card).forEach((field) => {
          if (field.startsWith('field-')) {
            customResponses[field] = { value: card[field] };
          }
        });

        const { responses: preparedResponses } = prepareResponsePayload({
          responses: customResponses,
          sections: sections ?? [],
        });

        const newData = {
          title: card.title.toString(),
          color: card.color,
          responses: preparedResponses,
          boardId,
          cardTypeId,
          statusId: initialStatusId, // set to status card where + is clicked
          orderIndex: highestIndex + 1,
          link,
        };
        highestIndex += 1;
        filteredUpload.push(newData);
      });

      const numConflicts = uploadData.length - filteredUpload.length;
      if (numConflicts > 0) {
        message.warning(`${numConflicts} card${numConflicts > 1 ? 's' : ''} could not be imported due to empty titles`);
      }

      if (filteredUpload.length === 0) {
        dispatch(closeCardDrawer());
        setLoading(false);
        return;
      }

      if (await dispatch(uploadCards({
        cards: filteredUpload,
      }))) {
        dispatch(closeCardDrawer());
      }

      setLoading(false);
    }
  }, [
    isCreate,
    currentStep,
    onSubmit,
    massUploadData,
    boardId,
    cardTypeId,
    sections,
    initialStatusId,
    cards,
  ]);

  const onViewShadow = useCallback(() => {
    onClose();
    onBoardSelected(selectedCard.boardId);
    dispatch(setBoardTargetCard({
      cardId: selectedCard.id,
      statusId: selectedCard.statusId,
      boardId: selectedCard.boardId,
    }));
  }, [
    onClose,
    onBoardSelected,
    selectedCard,
  ]);

  const onClearShadow = useCallback((hasAccessToTargetBoard) => () => {
    BoardCardShadowClearModal({
      selectedBoard,
      cardId: selectedCard?.id,
      showCancel: hasAccessToTargetBoard,
      onClear: () => dispatch(clearShadow(selectedBoard?.id, selectedCard?.id)),
    });
  }, [selectedBoard, selectedCard]);

  useEffect(() => {
    if (!visible) cleanup();
  }, [visible, cleanup]);

  useEffect(() => {
    setEditMode(propEdit);
  }, [propEdit]);

  useEffect(() => {
    if (visible && selectedCardId) {
      setDataLoading(true);
      setTimeout(() => {
        loadData(selectedCardId);
      }, 250);
    }
  }, [visible, selectedCardId]);

  useEffect(() => {
    if (visible) setActiveTab(locationActiveTab);
  }, [visible, locationActiveTab]);

  useEffect(() => {
    const updateReadTags = async () => {
      await debouncer.debounce(() => {
        const isNotes = activeTab === NOTES_TAB_KEY;
        if (isNotes && unreadNoteIds.size > 0) {
          dispatch(markCardTagsAsRead(unreadNoteIds));
        }
      }, 1000);
    };
    if (visible) updateReadTags();
  }, [visible, activeTab, unreadNoteIds, dispatch]);

  useEffect(() => {
    const {
      errorMap,
    } = getErrorsFromResponses({ sections: sections ?? [], responses, sectionPermissionMap });
    if (submitTried) setErrors(errorMap);
  }, [responses, submitTried, sections, sectionPermissionMap]);

  useEffect(() => {
    form.setFieldsValue({
      title: selectedTitle,
      cardNumber,
      color: safeColor,
      dateRange: selectedDateRange,
      users: relevantUsers || [],
      link: initialLink || {},
      statusId: initialStatusId,
    });
  }, [
    selectedTitle,
    cardNumber,
    relevantUsers,
    selectedDateRange,
    initialLink,
    form,
    safeColor,
    initialStatusId,
  ]);

  const statusOptions = useMemo(() => statuses.map((status) => ({
    label: status.title,
    value: status.id,
  })), [statuses]);

  const drawerTitle = useMemo(() => {
    if (!selectedCard) return 'Card';
    if (!canEdit) {
      if (!isShadow && !canAssignCard) return selectedCard?.title;
      if (canAssignCard) {
        return (
          <Row gutter={20} align="middle" style={{ maxWidth: '100%' }}>
            <Col>
              {selectedCard.title}
            </Col>
            <div
              style={{
                position: 'absolute',
                top: '10px',
                right: '50px',
              }}
            >
              <OnTraccrButton
                title="Reassign Card"
                onClick={() => {
                  setShowReassignDrawer(true);
                }}
              />
            </div>
          </Row>
        );
      }
      const hasAccessToTargetBoard = selectedCard.boardId in boards;
      return (
        <Row gutter={10} align="middle" style={{ maxWidth: '100%' }}>
          <Col>
            {selectedCard.title}
          </Col>
          <Col>
            <BoardCardShadowText
              boardId={selectedCard.boardId}
              style={{ fontSize: 12, fontWeight: 200 }}
            />
          </Col>
          {hasAccessToTargetBoard
          && (
          <Col>
            <Button
              size="small"
              onClick={onViewShadow}
            >
              View
            </Button>
          </Col>
          )}
          {
            boardPermsCanEdit && (
            <Col>
              <Button
                size="small"
                type="primary"
                onClick={onClearShadow(hasAccessToTargetBoard)}
              >
                Clear Shadow
              </Button>
            </Col>
            )
          }
        </Row>
      );
    }
    if (isDisplay) {
      return (
        <Row align="middle" gutter={10}>
          <Col
            style={{
              maxWidth: 300,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {selectedCard?.title}
          </Col>
          <Col> - </Col>
          <Select
            value={selectedCard?.statusId}
            options={statusOptions}
            style={{ minWidth: 250 }}
            onSelect={onStatusChanged}
          />
          <Col flex="auto" />
          {!!isFormsPermitted && (
            <Col>
              <BorderlessButton iconNode={<FormOutlined />} style={{ top: '-3px' }} onClick={onFormClicked} />
            </Col>
          )}
          <Col flex="40px" />

        </Row>
      );
    }
    if (!isAdd && selectedCard) return `Edit ${selectedCard?.title}`;
    return `Create ${templateTitle} card`;
  }, [
    canEdit,
    boardPermsCanEdit,
    isShadow,
    selectedCard,
    isDisplay,
    isAdd,
    templateTitle,
    statusOptions,
    boards,
    canAssignCard,
    onStatusChanged,
    onViewShadow,
    onClearShadow,
  ]);

  const selectedUsersText = useMemo(() => {
    if (!relevantUsers) return '';
    const userNames = relevantUsers.map((assigneeId) => {
      const {
        [assigneeId]: user = {},
      } = userMap;
      return user.name;
    });
    return userNames.filter((name) => name).join(', ');
  }, [relevantUsers, userMap]);

  const initialVendorIds = useMemo(() => (
    initialVendorId ? [initialVendorId] : []
  ), [initialVendorId]);

  const headerView = useMemo(() => {
    if (isDisplay) {
      return (
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            title: selectedTitle,
            cardNumber,
            dateRange: selectedDateRange,
            color: safeColor,
            users: relevantUsers || [],
            link: initialLink || {},
            statusId: initialStatusId,
          }}
        >
          {inferredShowCardNumber ? (
            <Form.Item
              label="Card Number"
              name="cardNumber"
              style={{ marginBottom: isDisplay ? 0 : 10 }}
              labelCol={{
                style: {
                  paddingBottom: 0,
                  marginTop: 5,
                },
              }}
            >
              <DisplayText title={`#${cardNumber}`} />
            </Form.Item>
          ) : ''}
          {showDate && (
            <Form.Item
              label="Date"
              name="dateRange"
              style={{
                marginBottom: 0,
                paddingTop: 5,
              }}
            >
              <BoardDatePicker isDisplay={isDisplay} />
            </Form.Item>
          )}
          <Form.Item
            label="Assigned Users"
            name="users"
            style={{ marginBottom: isDisplay ? 0 : 10 }}
            labelCol={{
              style: {
                paddingBottom: 0,
                marginTop: 5,
              },
            }}
          >
            {selectedUsersText ? <DisplayText title={selectedUsersText} /> : <DisplayText title="none" />}
          </Form.Item>
          <Row justify="space-between" align="middle" gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Color"
                name="color"
                style={{ marginBottom: isDisplay ? 0 : 10, maxWidth: 150 }}
                labelCol={{
                  style: {
                    paddingBottom: 0,
                    marginTop: 5,
                  },
                }}
              >
                <FormColorPicker isNotDisplay={!isDisplay} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Link"
                name="link"
                style={{ marginBottom: isDisplay ? 0 : 10 }}
                labelCol={{
                  style: {
                    paddingBottom: 0,
                    marginTop: 5,
                  },
                }}
                rules={[
                  { validator: validateLink },
                ]}
              >
                <BoardLinkSelector isDisplay={isDisplay} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      );
    }

    return (
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          title: selectedTitle,
          dateRange: selectedDateRange,
          cardNumber,
          color: safeColor,
          users: relevantUsers || [],
          link: initialLink || {},
        }}
        style={{
          overflowX: 'hidden',
        }}
      >
        {!isDisplay && (
          <FormTextInput
            label={(
              <Row gutter={20}>
                <Col>
                  Title
                </Col>
                { useCardNumberAsCardTitle
                  ? (
                    <Col>
                      <HoverHelp
                        content={(
                          <div style={{ width: 300 }}>
                            This board&apos;s card type is set up to
                            use the card number as the card title.
                          </div>
                    )}
                      />
                    </Col>
                  ) : ''}
              </Row>
            )}
            name="title"
            isNotDisplay={!isDisplay}
            placeholder="Enter a title"
            value={selectedTitle}
            style={{ marginBottom: isDisplay ? 0 : 10 }}
            textProps={{
              disabled: useCardNumberAsCardTitle,
            }}
            rules={[{
              required: !useCardNumberAsCardTitle,
              message: 'Title is required',
            }]}
          />
        )}
        {(inferredShowCardNumber && cardNumber) ? (
          <Form.Item
            label="Card Number"
            name="cardNumber"
            style={{ marginBottom: isDisplay ? 0 : 10 }}
            labelCol={{
              style: {
                paddingBottom: 0,
                marginTop: 5,
              },
            }}
          >
            <DisplayText title={`#${cardNumber}`} />
          </Form.Item>
        ) : ''}
        <Row gutter={10}>
          <Col>
            <Checkbox
              checked={showDate}
              defaultValue={showDate}
              value={showDate}
              onChange={toggleShowDate}
            />
          </Col>
          <Col>
            Add dates?
          </Col>
        </Row>
        <Form.Item
          label="Date"
          name="dateRange"
          style={!showDate && {
            display: 'none',
          }}
        >
          <BoardDatePicker isDisplay={isDisplay} />
        </Form.Item>
        <Form.Item
          label="Assigned Users"
          name="users"
          style={{ marginBottom: isDisplay ? 0 : 10 }}
          labelCol={{
            style: {
              paddingBottom: 5,
              marginTop: 5,
            },
          }}
        >
          {
            isDisplay
              ? <DisplayText title={selectedUsersText} />
              : (
                <FilteredUserSelector
                  className="filter-user-select"
                  users={userOptions}
                  filterStyle={{
                    margin: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  selectStyle={{
                    flexGrow: 1,
                  }}
                  disabled={writePerms === 'writeAssigned' && !selectedCard}
                />
              )
          }
        </Form.Item>

        {!isLocked && (
          <Form.Item
            label="Status"
            name="statusId"
            rules={[{ required: true, message: 'Status is required' }]}
          >
            <Select
              options={statusOptions}
            />
          </Form.Item>
        )}
        <Form.Item
          label="Color"
          name="color"
          style={{ marginBottom: isDisplay ? 0 : 10, maxWidth: 150 }}
          labelCol={{
            style: {
              paddingBottom: 0,
              marginTop: 5,
            },
          }}
        >
          <FormColorPicker isNotDisplay={!isDisplay} />
        </Form.Item>
        <Form.Item
          label="Link"
          name="link"
          style={{ marginBottom: isDisplay ? 0 : 10 }}
          labelCol={{
            style: {
              paddingBottom: 0,
              marginTop: 5,
            },
          }}
          rules={[
            { validator: validateLink },
          ]}
        >
          <BoardLinkSelector isDisplay={isDisplay} />
        </Form.Item>
      </Form>
    );
  }, [
    form,
    selectedTitle,
    cardNumber,
    relevantUsers,
    selectedDateRange,
    userOptions,
    isDisplay,
    selectedUsersText,
    initialLink,
    safeColor,
    statusOptions,
    isLocked,
    showDate,
  ]);

  const subtaskQuery = useMemo(() => {
    if (!id) return undefined;
    return { cardId: id };
  }, [id]);

  return (
    <Drawer
      className={activeTab === ANALYTICS_TAB_KEY ? 'board-card-drawer-analytics' : 'board-card-drawer'}
      title={drawerTitle}
      onClose={onClose}
      closeIcon={
        loading
          ? <Spin />
          : <CloseOutlined />
      }
      visible={visible}
      bodyStyle={{
        padding: isDisplay ? '0px 24px' : '0px 24px 10px 0px',
        marginBottom: 53,
      }}
      push={false}
      getContainer={false}
      keyboard={isDisplay}
      maskClosable={!loading}
    >
      {
        dataLoading && (
          <Row justify="center" align="middle" style={{ height: '100%', width: '100%' }}>
            <Spin />
          </Row>
        )
      }
      {!dataLoading && isAdd && (
        <Tabs
          tabPosition="left"
          tabBarStyle={{ paddingTop: 24 }}
          onChange={setActiveCreateUploadTab}
          activeKey={activeCreateUploadTab}
        >
          <TabPane tab="Create" key={CREATE_TAB_KEY} className="boards-tab-pane">
            <FormResponseFields
              errors={errors}
              sections={sections}
              visible={visible}
              responses={responses}
              onResponsesChanged={setResponses}
              initialData={initialData}
              fileMap={fileMap}
              header={headerView}
              divisions={boardDivisions}
              sectionPermissionMap={sectionPermissionMap}
              initialCustomerId={initialCustomerId}
              initialVendorIds={initialVendorIds}
              onCustomerIdChanged={setCustomerId}
              onVendorIdChanged={setVendorId}
              style={{
                position: 'static',
                paddingLeft: '24px',
                overflowY: 'auto',
              }}
            />
          </TabPane>
          { canEdit && (
            <TabPane tab="Upload" key={UPLOAD_TAB_KEY} className="boards-tab-pane">
              <BoardCardUpload
                uploadFile={uploadFile}
                onFileChange={setUploadFile}
                currentStep={currentStep}
                onMassUploadDataChanged={setMassUploadData}
                sections={sections}
                initialStatusId={initialStatusId}
                visible={visible}
                useCardNumberAsCardTitle={!!useCardNumberAsCardTitle}
                statusId={initialStatusId}
                setStatusId={setInitialStatusId}
              />
            </TabPane>
          )}
        </Tabs>
      )}
      {editingUsers.length > 0 && editMode && (
      <div
        style={{
          backgroundColor: colors.ONTRACCR_RED,
          color: 'white',
          padding: '12px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          marginLeft: '16px',
          marginTop: '16px',
          fontSize: '12px',
          fontWeight: 'semi-bold',
          position: 'relative',
          overflow: 'visible',
        }}
      >
        <WarningOutlined style={{ fontSize: '20px' }} />
        <span>
          <strong>Warning:</strong> Multiple users are editing this card. Only the last
          saved version will be kept. To avoid conflicts, coordinate edits or save one at a time.
        </span>
        <div style={{ display: 'flex', gap: '8px' }}>
          {editingUsers.map(({ userId }) => (
            <Tooltip
              key={userId}
              title={`${userMap[userId].username} is editing this card.`}
              placement="bottom"
            >
              <span style={{ cursor: 'pointer', position: 'relative', zIndex: 10 }}>
                <ProfileCircle
                  id={`editor-${userId}`}
                  initials={userMap[userId].username.slice(0, 1)}
                  radius={15}
                  fontSize={14}
                />
              </span>
            </Tooltip>
          ))}
        </div>
      </div>
      )}

      {!dataLoading && editMode && hasInitialLoaded && (
        <FormResponseFields
          errors={errors}
          sections={sections}
          visible={visible}
          responses={responses}
          onResponsesChanged={setResponses}
          initialData={initialData}
          fileMap={fileMap}
          header={headerView}
          divisions={boardDivisions}
          sectionPermissionMap={sectionPermissionMap}
          initialCustomerId={initialCustomerId}
          initialVendorIds={initialVendorIds}
          onCustomerIdChanged={setCustomerId}
          onVendorIdChanged={setVendorId}
          style={{
            position: 'static',
            paddingLeft: '24px',
            overflowY: 'auto',
            marginBottom: 43, // Keep above drawer footer
          }}
        />
      )}
      {!dataLoading && isDisplay && (
        <Tabs
          tabBarStyle={{ margin: 0 }}
          onChange={onTabChanged}
          activeKey={activeTab}
        >
          <TabPane tab="Details" key={DETAILS_TAB_KEY}>
            {headerView}
            {displaySections && displaySections.length && (
              <>
                <Divider style={{ margin: 0 }} />
                <FormBuilder
                  isDisplay
                  initialSections={relevantFormData ?? []}
                  style={{
                    position: 'relative',
                    inset: 'unset',
                    marginBottom: (canEdit && snapshots.length > 1) ? 80 : 0,
                  }}
                  sectionPermissionMap={sectionPermissionMap}
                  divisions={boardDivisions}
                  isDetailView
                  shouldHideCustomRenderingFields
                >
                  {snapshots.length > 1 && (
                    <Row
                      justify="space-between"
                      style={{ padding: '20px 35px 10px 20px' }}
                    >
                      <Col>
                        <DatePicker.RangePicker
                          allowClear
                          style={{ width: 250 }}
                          format="MMM Do YY"
                          disabledDate={disabledDate}
                          minDate
                          value={range}
                          onChange={setRange}
                          getPopupContainer={(node) => node.parentNode}
                        />
                      </Col>
                      <OnTraccrButton
                        title="View Snapshots"
                        onClick={toggleSnapshotDrawer}
                      />
                    </Row>
                  )}
                </FormBuilder>
              </>
            )}
          </TabPane>
          <TabPane tab="Analytics" key={ANALYTICS_TAB_KEY}>
            <BoardCardAnalytics
              cardId={id}
              cardTypeId={cardTypeId}
              boardId={boardId}
            />
          </TabPane>
          <TabPane tab={<div className="board-card-tab-header">Forms</div>} key={FORMS_TAB_KEY}>
            <BoardCardForms
              id={id}
              topOffset={canEdit ? 0 : -FOOTER_HEIGHT}
            />
          </TabPane>
          <TabPane
            tab={(
              <div style={{ paddingRight: myTagCount ? 15 : 0 }}>
                <Badge count={myTagCount} offset={[5, -5]}>
                  Notes
                </Badge>
              </div>
            )}
            key={NOTES_TAB_KEY}
          >
            <Notes
              id={id}
              notes={cardNotesMap}
              getNotes={getCardNotes}
              addNote={addCardNote}
              style={{
                top: 115,
                bottom: canEdit ? FOOTER_HEIGHT : 0,
                left: 24,
                right: 24,
              }}
              users={boardUsers}
              userTrie={boardUserTrie}
              allowTags
              unreadSet={unreadNoteIds}
              allowUpload
              type="cardNotes"
              getAttachments={getCardAttachments}
            />
          </TabPane>
          <TabPane
            tab="Tasks"
            key={TASK_KEY}
            style={tabFillStyle}
          >
            <Subtasks
              query={subtaskQuery}
            />
          </TabPane>
          <TabPane tab="Timeline" key={TIMELINE_TAB_KEY}>
            <BoardCardTimeline
              active={activeTab === TIMELINE_TAB_KEY}
              cardId={id}
              canEdit={canEdit}
            />
          </TabPane>
          <TabPane tab="Attachment" key={ATTACHMENTS}>
            <AttachmentTab
              attachments={
                visible && activeTab === ATTACHMENTS
                  ? attachments
                  : {}
              }
            />
          </TabPane>
          {config.showClientPortal && hasClientAccounts && (
            <TabPane
              tab={(
                <div
                  style={{ paddingRight: numClientCommsUnread ? 15 : 0, paddingBottom: 0 }}
                >
                  <Badge count={numClientCommsUnread} offset={[5, -5]}>
                    Communication
                  </Badge>
                </div>
              )}
              key={CLIENT_COMMS_KEY}
            >
              <div id="board-card-client-comms">
                <CustomerClientCommunications
                  active={activeTab === CLIENT_COMMS_KEY}
                  customerId={ourCustomerId}
                  style={{
                    top: 0,
                  }}
                />
              </div>

            </TabPane>
          )}
          {selectedCard?.hasEmails && (
            <TabPane
              tab="Emails"
              key={EMAIL_KEY}
            >
              <div id="board-card-client-comms">
                <BoardCardEmails
                  visible={visible && activeTab === EMAIL_KEY}
                  id={id}
                />
              </div>

            </TabPane>
          )}
        </Tabs>
      )}
      {!dataLoading && (canEdit || canCreate) && !isDisplay && (
        <DrawerFooter>
          <Row justify="end" align="middle" gutter={8}>
            <OnTraccrButton
              title="Cancel"
              type="cancel"
              id="customer-add-project-footer-cancel"
              onClick={onCancel}
              loading={loading}
              disabled={loading}
            />
            {currentStep > 0 && (
              <OnTraccrButton
                title="Back"
                type="back"
                id="customer-add-project-footer-back"
                onClick={onMassUploadBack}
              />
            )}
            <OnTraccrButton
              title={currentStep < LAST_UPLOAD_STEPS && !isCreate ? 'Next' : 'Submit'}
              onClick={onMassUploadNext}
              disabled={!isCreate && !uploadFile}
              loading={loading}
            />
          </Row>
        </DrawerFooter>
      )}
      {!dataLoading && canEdit && isDisplay && (
        <DrawerSubmitFooter
          onClose={onCancel}
          onSubmit={onSubmit}
          submitTitle="Edit"
          loading={loading}
          onDelete={isDisplay ? onDelete : null}
        />
      )}
      <FullPhoto
        file={globalFileMap[selectedFile?.id || selectedFile?.autoSaveId]}
        type={selectedFile ? selectedFile.type : null}
        onClose={clearSelectedFile}
        onDownload={onDownload}
        onLeft={onNext(false)}
        onRight={onNext(true)}
        showLeft={showLeft}
        showRight={showRight}
        useApryse
      />
      <SnapshotDrawer
        open={visible && showSnapshotDrawer}
        onClose={toggleSnapshotDrawer}
        snapshots={snapshots}
        setSelectedFile={setSelectedFile}
        setSelectedFileDetails={setSelectedFileDetails}
        isBoardCard
        divisions={boardDivisions}
      />
      <BoardCardReassignmentDrawer
        visible={showReassignDrawer}
        onClose={() => setShowReassignDrawer(false)}
        cardId={id}
        cardUsers={cardUsers}
      />
    </Drawer>
  );
}

/* eslint-disable react/forbid-prop-types */
BoardCardDrawer.propTypes = {
  boardId: PropTypes.string.isRequired,
  cardTypeId: PropTypes.string.isRequired,
  divisions: PropTypes.array,
  userBoardPermissions: PropTypes.object,
  showCardNumber: PropTypes.number,
  useCardNumberAsCardTitle: PropTypes.number,
  onBoardSelected: PropTypes.func.isRequired,
};

BoardCardDrawer.defaultProps = {
  divisions: [],
  userBoardPermissions: {},
  showCardNumber: false,
  useCardNumberAsCardTitle: false,
};
