import { Drawer, Form } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import FormBuilder from '../../forms/FormBuilder/FormBuilder';
import IconPicker from '../../common/IconPicker/IconPicker';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';

export default function TypesAddEditDrawer({
  selectedEntry,
  visible,
  toggle,
  showIcon,
  setSections,
  onSave,
  form,
}) {
  const initialSections = useMemo(() => {
    if (selectedEntry) {
      return selectedEntry.customFields?.sections ?? [];
    }
    return [];
  }, [selectedEntry]);

  if (!visible) return null;
  return (
    <Drawer
      title={`${selectedEntry ? 'Edit' : 'Add'} Type`}
      visible={visible}
      onClose={toggle}
      width={800}
      destroyOnClose
      keyboard={false}
    >
      <Form form={form}>
        { showIcon && (
          <Form.Item name="icon">
            <IconPicker />
          </Form.Item>
        )}
        <Form.Item
          name="name"
          label="Name"
          style={{ width: showIcon ? 'inherit' : '100%' }}
        >
          <OnTraccrTextInput placeholder="Enter Name" disabled={selectedEntry?.isDefault} />
        </Form.Item>
      </Form>
      <FormBuilder
        isBoardCards
        onSectionsChanged={setSections}
        initialSections={initialSections}
        keyboard={false}
      />
      <DrawerSubmitFooter
        onSubmit={onSave}
        onClose={toggle}
      />
    </Drawer>
  );
}

TypesAddEditDrawer.propTypes = {
  selectedEntry: PropTypes.shape({
    customFields: PropTypes.shape({
      sections: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    isDefault: PropTypes.bool,
  }),
  visible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  showIcon: PropTypes.bool.isRequired,
  setSections: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.shape({}),
};

TypesAddEditDrawer.defaultProps = {
  selectedEntry: null,
  form: null,
};
