import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'antd';
import { DateTime } from 'luxon';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';

import UserClockInModal from '../users/UserClockInModal';

import TeamsSideList from './TeamsSideList';
import TeamDetail from './TeamDetail';
import TeamDrawer from './TeamDrawer';

import { getUsers } from '../users/state/users.actions';
import { getTeams } from './state/teams.actions';
import { getProjects } from '../projects/state/projects.actions';
import { getEquipment } from '../equipment/state/equipment.actions';
import { setBreadcrumb } from '../common/breadcrumbContainer/state/breadcrumb.actions';
import TimeTracking from '../state/timeTracking';

import Permissions from '../auth/Permissions';
import { getIdMap } from '../helpers/helpers';

import Clock from '../clock/Clock';

import useToggle from '../common/hooks/useToggle';

const BASE_CRUMBS = [{
  text: 'Staff',
  icon: 'team',
}];

const ADD = 'add';
const EDIT = 'edit';
export default function Teams() {
  const dispatch = useDispatch();

  const crumbs = useSelector((state) => state.breadcrumb);
  const teams = useSelector((state) => state.teams.teams);
  const projects = useSelector((state) => state.projects.projects);
  const phases = useSelector((state) => state.costcodes.phases);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const unions = useSelector((state) => state.unions);
  const users = useSelector((state) => state.users.users);
  const company = useSelector((state) => state.settings.company);
  const positions = useSelector((state) => state.settings.positions);
  const roundingPolicyPositionMap = useSelector((state) => state.settings.roundingPolicyPositionMap);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);

  const teamMap = useMemo(() => getIdMap(teams), [teams]);
  const userMap = useMemo(() => getIdMap(users), [users]);

  const divUsers = useMemo(() => {
    const divTeams = teams.filter(({ divisionIds = [] }) => (
      divisionIds?.some((dId) => selectedDivisions.has(dId))
    ));

    const divisionUsers = new Set();

    divTeams.forEach(({ members = [] }) => {
      members.forEach((m) => divisionUsers.add(m.id));
    });

    return Array.from(divisionUsers);
  }, [teams, selectedDivisions]);

  const [selectedTeamId, setSelectedTeamId] = useState();
  const [showDrawer, setShowDrawer] = useState(null);
  const [clockInUser, setClockInUser] = useState();

  const {
    isToggled: showClockInModal,
    toggle: toggleClockInModal,
  } = useToggle();

  const onCloseDrawer = useCallback(() => setShowDrawer(null), []);
  const onAddClicked = useCallback(() => {
    setShowDrawer(ADD);
  }, []);

  const onEditClicked = useCallback(() => {
    setShowDrawer(EDIT);
  }, []);

  const onUserClick = useCallback((user) => {
    toggleClockInModal();
    setClockInUser(user);
  }, []);

  const onTeamSelected = useCallback((newSelected) => {
    setSelectedTeamId(newSelected?.id);
  }, []);

  useEffect(() => {
    dispatch(setBreadcrumb(
      BASE_CRUMBS.concat([{
        text: `${(Permissions.has('TEAMS_WRITE') || Permissions.has('TEAMS_WRITE_ASSIGNED')) ? 'Manage' : 'View'} Teams`,
      }]),
    ));
    dispatch(getUsers());
    dispatch(getProjects());
    dispatch(getTeams());
    dispatch(getEquipment());
  }, []);

  useEffect(() => {
    dispatch(TimeTracking.getTimeEntries({
      startTime: DateTime.now().startOf('day').toMillis(),
      endTime: DateTime.now().endOf('day').toMillis(),
      getRunningTasks: true,
      userIds: divUsers,
    }));
  }, [divUsers]);

  const clock = useMemo(() => {
    const c = new Clock({
      clockin: (...args) => dispatch(TimeTracking.clockIn(...args)),
      clockout: (...args) => dispatch(TimeTracking.clockOut(...args)),
      switchTask: (...args) => dispatch(TimeTracking.switchTask(...args)),
      takeBreak: (...args) => dispatch(TimeTracking.startBreak(...args)),
      endBreak: (...args) => dispatch(TimeTracking.endBreak(...args)),
      manualEntry: (...args) => dispatch(TimeTracking.manualEntry(...args)),
    });
    c.setUser(clockInUser);
    c.users = users;
    c.settings = company?.settings ?? {};
    c.positions = positions;
    c.roundingPolicyPositionMap = roundingPolicyPositionMap;
    return c;
  }, [clockInUser, users, company, positions, roundingPolicyPositionMap]);

  const selectedTeam = selectedTeamId
    ? teamMap[selectedTeamId]
    : null;

  if (!Permissions.has('TEAMS_WRITE') && !Permissions.has('TEAMS_WRITE_ASSIGNED')) return <Redirect to="/dashboard" />;
  return (
    <BreadCrumbContainer crumbs={crumbs}>
      <Row style={{ height: '100%', width: '100%' }}>
        <Col span={8} style={{ height: '100%', paddingRight: 10 }}>
          <TeamsSideList
            onAddClicked={onAddClicked}
            onTeamSelected={onTeamSelected}
            selectedTeam={selectedTeam}
          />
        </Col>
        <Col span={16} style={{ height: '100%', paddingLeft: 10 }}>
          <TeamDetail
            team={selectedTeam}
            onEdit={onEditClicked}
            onUserClick={onUserClick}
          />
        </Col>
      </Row>
      <TeamDrawer
        visible={!!showDrawer}
        selectedTeam={showDrawer === EDIT ? selectedTeam : null}
        showDrawer={showDrawer}
        onClose={onCloseDrawer}
      />
      <UserClockInModal
        key={clockInUser?.id}
        visible={showClockInModal}
        user={userMap[clockInUser?.id]}
        projects={projects}
        phases={phases}
        costcodes={costcodes}
        unions={unions}
        onCloseClicked={toggleClockInModal}
        onClockIn={clock.onClockIn}
        onClockOut={clock.onClockOut}
        onSwitch={clock.onSwitch}
        onBreakStart={clock.onBreakStart}
        onBreakEnd={clock.onBreakEnd}
      />
    </BreadCrumbContainer>
  );
}
