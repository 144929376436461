import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Table } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import OnTraccrNumberInput from '../../../common/inputs/OnTraccrNumberInput';
import DisplayText from '../../../common/text/DisplayText';

import BillingRateSelector from '../../../billingRates/BillingRateSelector';

import { getLuxonRuntimeFromTask } from '../../../helpers/time';
import { getIdMap, isNullOrUndefined } from '../../../helpers/helpers';

import LabourHoursDrawer from './LabourHoursDrawer';
import { currencyParser, currencyFormatter } from '../../../helpers/inputParsers';
import { generateId } from '../../formHelpers';
import { getCalculationTableColumn } from './formFieldsHelpers';

const getTotal = (item) => parseFloat((item.rate * item.quantity).toFixed(2));

const CUSTOM_ID = 'custom';
const getLabourColumnMap = ({
  onUpdateValue,
  isDisplay,
  billingRates = {},
  billingRateNameMap = {},
  defaultBillingRate = {},
  requiredColumns,
  preventEdits,
}) => ({
  name: {
    title: <div className={requiredColumns && 'form-required-field'}>Name</div>,
    dataIndex: 'name',
    width: 200,
    render: (name, record) => {
      if (isDisplay) return name;
      const {
        [name]: {
          id: billingRateId,
        } = {},
      } = billingRateNameMap;
      return (
        <Row align="middle">
          <BillingRateSelector
            style={{ width: 200 }}
            isNotDisplay={!isDisplay}
            onlyNames
            billingRateId={record.isCustom ? CUSTOM_ID : billingRateId}
            customEntries={defaultBillingRate.isCustom ? [defaultBillingRate] : []}
            onChange={(value) => {
              let {
                [value]: ourRate = {},
              } = billingRates;
              if (value === CUSTOM_ID) ourRate = defaultBillingRate;
              onUpdateValue({
                id: record.id,
                newData: {
                  rate: ourRate.rate,
                  name: ourRate.name,
                  isCustom: ourRate.id === CUSTOM_ID,
                },
              });
            }}
          />
        </Row>
      );
    },
  },
  rate: {
    title: <div className={requiredColumns && 'form-required-field'}>Rate</div>,
    dataIndex: 'rate',
    width: 100,
    render: (rate, record) => {
      if (isDisplay || preventEdits) return !isNullOrUndefined(rate) ? `${currencyFormatter(rate)} / hr` : '';
      return (
        <Row align="middle">
          <OnTraccrNumberInput
            key={record.id}
            min={0}
            onChange={(value) => onUpdateValue({ id: record.id, newData: { rate: value } })}
            value={rate}
            formatter={currencyFormatter}
            parser={currencyParser}
          />
        </Row>
      );
    },
  },
  quantity: {
    title: <div className={requiredColumns && 'form-required-field'}>Quantity</div>,
    dataIndex: 'quantity',
    width: 100,
    render: (quantity, record) => {
      if (isDisplay || preventEdits) return quantity;
      return (
        <Row align="middle">
          <OnTraccrNumberInput
            min={0}
            onChange={(value) => onUpdateValue({ id: record.id, newData: { quantity: value } })}
            value={quantity}
          />
        </Row>
      );
    },
  },
  total: {
    title: 'Total',
    dataIndex: 'total',
    width: 100,
    render: (total) => {
      const totalText = total ? currencyFormatter(total) : '$0.00';
      if (isDisplay) return totalText;
      return (
        <Row align="middle">
          <DisplayText title={totalText} style={{ marginBottom: 0 }} />
        </Row>
      );
    },
  },
});

const labourColumns = ({
  onDelete,
  onUpdateValue,
  columns,
  isDisplay,
  billingRates,
  billingRateNameMap,
  defaultBillingRate,
  requiredColumns,
  preventEdits,
}) => {
  const cols = [];
  const labourColumnMap = getLabourColumnMap({
    onUpdateValue,
    isDisplay,
    billingRates,
    billingRateNameMap,
    defaultBillingRate,
    requiredColumns,
    preventEdits,
  });
  columns.forEach((col) => {
    if (col.key in labourColumnMap) {
      cols.push(labourColumnMap[col.key]);
    } else if (col.isCalculation) {
      cols.push(getCalculationTableColumn(col, {
        width: 100,
        isDisplay,
      }));
    }
  });
  if (!isDisplay) {
    cols.push({
      title: '',
      dataIndex: '',
      width: 100,
      render: (_, record) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: 'red' }} />}
          onClick={() => onDelete(record.id)}
        />
      ),
    });
  }
  return cols;
};

export default function LabourTablePreview({
  columns = [],
  previewProps = {},
  setPreviewProps,
  isDisplay,
  id,
  setResponses,
  responses = {},
  responding = false,
  projectId,
  customerId,
  configProps: {
    hideAddNewButton,
    requiredColumns,
    preventEdits,
  } = {},
  showCondensedView,
}) {
  const values = previewProps.values || []; // For Responses
  const {
    selected: previewSelected = [],
  } = previewProps;
  const {
    [id]: {
      values: responseSelected = [],
    } = {},
  } = responses;

  const selected = responding ? responseSelected : previewSelected;

  const [showDrawer, setShowerDrawer] = useState(false);
  const billingRates = useSelector((state) => state.billingRates);
  const customers = useSelector((state) => state.customers.customers);
  const projects = useSelector((state) => state.projects.projects);

  const billingRateNameMap = useMemo(() => getIdMap(Object.values(billingRates), 'name'), [billingRates]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);

  const defaultBillingRate = useMemo(() => {
    const {
      [projectId]: {
        billingRateId: projectBillingRateId,
        customerId: projectCustomerId = customerId,
        customBillingRate: customProjectBillingRate,
        name: projectName,
      } = {},
    } = projectMap;
    const {
      [projectCustomerId]: {
        billingRateId: customerBillingRateId,
        customBillingRate: customCustomerBillingRate,
        name: customerName,
      } = {},
    } = customers;
    if (projectBillingRateId) return billingRates[projectBillingRateId];
    if (customProjectBillingRate) {
      return {
        name: projectName,
        rate: customProjectBillingRate,
        isCustom: true,
        id: CUSTOM_ID,
      };
    }
    if (customerBillingRateId) return billingRates[customerBillingRateId];
    return {
      name: customerName,
      rate: customCustomerBillingRate,
      isCustom: true,
      id: CUSTOM_ID,
    };
  }, [projectMap, projectId, customerId, customers, billingRates]);

  const onAddClicked = useCallback(() => setShowerDrawer(true), []);
  const closeDrawer = useCallback(() => setShowerDrawer(false), []);

  const updateResponses = useCallback((newData = {}) => {
    setResponses({
      ...responses,
      [id]: {
        ...(responses[id]),
        ...newData,
      },
    });
  }, [responses, id, setResponses]);

  const onAddNewClicked = useCallback(() => {
    const newEntry = {
      id: generateId(),
    };
    if (defaultBillingRate) {
      newEntry.name = defaultBillingRate.name;
      newEntry.rate = defaultBillingRate.rate;
      newEntry.isCustom = defaultBillingRate.isCustom;
    }
    const newSelected = selected.concat([newEntry]);
    if (responding) {
      updateResponses({
        values: newSelected,
        columns,
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
  }, [selected, previewProps, responding, columns, updateResponses, defaultBillingRate]);

  const onSubmit = useCallback((selectedTasks) => {
    const runtimes = selectedTasks.map((task) => {
      const newEntry = {
        id: task.id,
        quantity: parseFloat(getLuxonRuntimeFromTask(task, { rawMs: true }).as('hours').toFixed(1)),
      };
      if (defaultBillingRate) {
        newEntry.name = defaultBillingRate.name;
        newEntry.rate = defaultBillingRate.rate;
        newEntry.total = getTotal(newEntry);
        newEntry.isCustom = defaultBillingRate.isCustom;
      }
      return newEntry;
    });
    const newSelected = selected.concat(runtimes);
    if (responding) {
      updateResponses({ values: newSelected, columns });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }

    setShowerDrawer(false);
  }, [selected, responding, previewProps, updateResponses, columns, defaultBillingRate]);

  const onDelete = useCallback((deletedId) => {
    const newSelected = selected.filter((item) => item.id !== deletedId);
    if (responding) {
      updateResponses({
        values: newSelected,
        columns,
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: selected.filter((item) => item.id !== deletedId),
      });
    }
  }, [previewProps, selected, updateResponses]);

  const onUpdateValue = useCallback(({ id: changedId, newData = {} }) => {
    const newSelected = [...selected].map((item) => {
      if (item.id !== changedId) return item;
      const newItem = {
        ...item,
        ...newData,
      };
      if (newItem.rate && (newItem.quantity || newItem.quantity === 0)) {
        newItem.total = getTotal(newItem);
      }
      return newItem;
    });
    if (responding) {
      updateResponses({
        values: newSelected,
        columns,
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
  }, [selected, responding, updateResponses, previewProps, columns]);

  const tableColumns = useMemo(() => (
    labourColumns({
      onDelete,
      onUpdateValue,
      columns,
      isDisplay,
      billingRates,
      billingRateNameMap,
      defaultBillingRate,
      requiredColumns,
      preventEdits,
    })
  ), [
    columns,
    isDisplay,
    onDelete,
    onUpdateValue,
    billingRates,
    billingRateNameMap,
    defaultBillingRate,
    requiredColumns,
    preventEdits,
  ]);

  const dataSource = useMemo(() => (
    isDisplay && !responding ? values : selected
  ), [isDisplay, responding, values, selected]);

  return (
    <Row style={{ marginTop: showCondensedView ? 0 : 15 }}>
      {!isDisplay && (
        <Row style={{ marginBottom: 10, width: '100%' }} gutter={20}>
          <Col>
            <OnTraccrButton
              title="Add from Time Cards"
              icon={<PlusOutlined />}
              onClick={onAddClicked}
            />
          </Col>
          <Col>
            { !hideAddNewButton && (
              <OnTraccrButton
                title="Add New"
                icon={<PlusOutlined />}
                onClick={onAddNewClicked}
              />
            )}
          </Col>
        </Row>
      )}
      { !showCondensedView || dataSource?.length ? (
        <Table
          style={{ width: '100%', overflow: 'auto' }}
          columns={tableColumns}
          size="small"
          pagination={false}
          dataSource={dataSource}
        />
      ) : (
        <DisplayText title="No Labour Hours" style={{ marginBottom: 0 }} />
      )}
      {!isDisplay && (
        <LabourHoursDrawer
          visible={showDrawer}
          onClose={closeDrawer}
          onSubmit={onSubmit}
        />
      )}
    </Row>
  );
}

LabourTablePreview.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    dataIndex: PropTypes.string,
    key: PropTypes.string,
  })),
  responding: PropTypes.bool,
  responses: PropTypes.objectOf(PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      rate: PropTypes.number,
      quantity: PropTypes.number,
      total: PropTypes.number,
    })),
    columns: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      dataIndex: PropTypes.string,
      key: PropTypes.string,
    })),
  })),
  setResponses: PropTypes.func,
  id: PropTypes.string,
  isDisplay: PropTypes.bool,
  previewProps: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      rate: PropTypes.number,
      quantity: PropTypes.number,
      total: PropTypes.number,
    })),
  }),
  setPreviewProps: PropTypes.func,
  projectId: PropTypes.string,
  customerId: PropTypes.string,
  configProps: PropTypes.shape({
    hideAddNewButton: PropTypes.bool,
    requiredColumns: PropTypes.bool,
  }),
  showCondensedView: PropTypes.bool,
};

LabourTablePreview.defaultProps = {
  columns: [],
  previewProps: {},
  responding: false,
  responses: {},
  setPreviewProps: null,
  setResponses: null,
  id: null,
  isDisplay: false,
  projectId: null,
  customerId: null,
  configProps: {},
  showCondensedView: false,
};
