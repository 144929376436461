import BorderlessButton from '../common/buttons/BorderlessButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export const settingsColumns = (onEdit, onDelete) => () => [
  {
    title:'Name',
    dataIndex: 'name',
  },{
    dataIndex: 'edit',
    width: 50,
    render:(_,record) => (
      <BorderlessButton iconNode={<EditOutlined/>} onClick={onEdit(record)}/>
    ),
  },{
    dataIndex: 'delete',
    width: 50,
    render:(_,record) => (
      <BorderlessButton iconNode={<DeleteOutlined style={{ color: 'red' }}/>} onClick={onDelete(record)}/>
    ),
  }
]

export const cardTitleValidator = (id, cardTitleMap) => (_, value) => {
  const lowerValue = value ? value.toLowerCase() : value;
  const {
    [lowerValue]: {
      id: existingId,
      title: existingTitle,
    } = {},
  } = cardTitleMap;
  if (existingId && existingId !== id) return Promise.reject(`Card with title ${existingTitle} already exists`);
  return Promise.resolve();
};

export const formatCardTitle = (card) => {
  const prefix = card.number ? `${card.number} - ` : '';
  const suffix = card.title || '';
  return `${prefix}${suffix}`;
};

export const validateLink = async (_rule, value) => {
  if (
    (value?.linkType && !value?.linkId)
    || (value?.linkId && !value?.linkType)
  ) return Promise.reject(new Error('Please select a valid link'));
  return Promise.resolve();
};
