import axios from 'axios';
import { message } from 'antd';
import { archive, request } from '../../helpers/requests';
import { formatPayloadCustomData } from '../../equipment/state/equipment.service';


const formatTemplatePayload = async (payload) => {
  const { newCostcodes = [], ...rest } = payload;
  const realPayload = { ...rest };

  if (newCostcodes?.length) {
    realPayload.newCostcodes = await Promise.all(
      newCostcodes.map(async (costcode) => {
        const {
          customData: formattedCustomData,
          files: formattedFiles,
        } = await formatPayloadCustomData(costcode.customData);
        return {
          ...costcode,
          customData: formattedCustomData,
          files: formattedFiles,
        };
      }),
    );
  }

  return realPayload;
}

const CostCodeService = {
  getAll: (lastUpdated) => request({
    call: axios.get('/costcodes', { params: { lastUpdated, getDiff: true, getArchived: true } }),
    errMsg: 'Could not get cost codes',
    hideSuccessToast: true,
  }),
  getAllPhases: async () => {
    try {
      const { data } = await axios.get('/phases');
      return data;
    } catch (err) {
      message.error('Could not retrieve phases');
      return null;
    }
  },
  create: async (costcode) => {
    const payload = { ...costcode };
    const {
      customData: formattedCustomData,
      files: formattedFiles,
    } = await formatPayloadCustomData(costcode.customData);
    payload.customData = formattedCustomData;
    payload.files = formattedFiles;

    return request({
      call: axios.post('/costcodes', {
        costcodes: [payload],
      }),
      errMsg: (err) => {
        const {
          message: errorMessage = 'Bad Request',
        } = err;
        return errorMessage !== 'Bad Request' ? errorMessage : 'Could not create costcode';
      },
    });
  },
  createMany: (costcodes) => {
    const ccs = costcodes.map((costcode) => {
      const obj = {
        id: costcode.id,
        name: costcode.name.toString(),
        description: costcode.description.toString(),
        code: costcode.code.toString(),
        divisionId: costcode.divisionId.toString(),
        categoryId: costcode.categoryId,
        hourlyWage: costcode.hourlyWage,
        dailyWage: costcode.dailyWage,
        wageAdjustment: costcode.wageAdjustment,
        wageMultiplier: costcode.wageMultiplier,
        hourlyBillingRate: costcode.hourlyBillingRate,
        dailyBillingRate: costcode.dailyBillingRate,
        customData: costcode.customData,
        intuitId: costcode.intuitId,
      };
      if (costcode.projectId) {
        obj.projectId = costcode.projectId;
      }
      return obj;
    });

    return request({
      call: axios.post('/costcodes', {
        costcodes: ccs,
      }),
      errMsg: (err) => {
        const {
          message: errorMessage = 'Bad Request',
        } = err;
        return errorMessage !== 'Bad Request' ? errorMessage : 'Could not create costcodes';
      },
    });
  },
  update: async (id, payload) => {
    try {
      const realPayload = { ...payload };
      const {
        customData: formattedCustomData,
        files: formattedFiles,
      } = await formatPayloadCustomData(payload.customData);
      realPayload.customData = formattedCustomData;
      realPayload.files = formattedFiles;
      const data = await axios.put(`/costcodes/${id}`, realPayload);
      message.success('Cost code updated.');
      return data;
    } catch (err) {
      message.error('Could not update costcode');
      return {};
    }
  },
  delete: (id) => request({
    call: axios.delete(`/costcodes/${id}`),
    errMsg: 'Failed to delete costcode',
    successMsg: 'Costcode deleted successfully',
  }),
  archive: (id, active) => archive({
    id, active, type: 'costcodes',
  }),
  getCategories: () => request({
    call: axios.get('/costcodes/categories'),
    errMsg: 'Failed to get costcode categories',
    hideSuccessToast: true,
  }),
  createTemplate: async (payload) => {
    const formattedPayload = await formatTemplatePayload(payload);
    return request({
      call: axios.post('/costcodes/templates', formattedPayload),
      errMsg: 'Failed to create costcode template',
    });
  },
  getTemplates: () => request({
    call: axios.get('/costcodes/templates'),
    errMsg: 'Failed to get costcode templates',
    hideSuccessToast: true,
  }),
  updateTemplate: async (id, payload) => {
    const formattedPayload = await formatTemplatePayload(payload);
    return request({
      call: axios.put(`/costcodes/templates/${id}`, formattedPayload),
      errMsg: 'Failed to update costcode template',
    });
  },
  deleteTemplate: (id) => request({
    call: axios.delete(`/costcodes/templates/${id}`),
    errMsg: 'Failed to delete costcode template',
    successMsg: 'Costcode template deleted successfully',
  }),
  getBillingData: (query) => request({
    call: axios.get('/costcodes/billing', { params: query }),
    errMsg: 'Failed to get costcode billing data',
    hideSuccessToast: true,
  }),
  getCustomFieldTemplate: () => request({
    call: axios.get('/costcodes/customFieldTemplate'),
    errMsg: 'Failed to get costcode custom field template',
    hideSuccessToast: true,
  }),
  updateCustomFieldTemplate: (payload) => request({
    call: axios.post('/costcodes/customFieldTemplate', payload),
    errMsg: 'Failed to update costcode custom field template',
  }),
  getCustomData: (id) => request({
    call: axios.get(`/costcodes/${id}/data`, { params: { getSignedUrls: false } }),
    errMsg: 'Failed to get costcode custom data',
    hideSuccessToast: true,
  }),
};

export default CostCodeService;
