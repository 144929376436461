import { DateTime } from 'luxon';
import moment from 'moment';

import { isNullOrUndefined } from '../helpers/helpers';

export default {};

export const formatDate = (dt, dateBreakdown) => {
  switch (dateBreakdown) {
    case 'year':
      return dt.toFormat('y');
    case 'quarter':
      return dt.toFormat('Q q yy');
    case 'month':
      return dt.toFormat('LLL yy');
    case 'day':
    case 'week':
    default:
      return dt.toFormat('LLL d yy');
  }
};

export const getNumBuckets = ([startMoment, endMoment] = [], dateBreakdown) => {
  const startDT = DateTime.fromMillis(startMoment.valueOf()).startOf('day');
  const endDT = DateTime.fromMillis(endMoment.valueOf()).endOf('day');
  const diff = endDT.diff(startDT);
  return Math.ceil(diff.as(dateBreakdown));
};

export const getRowName = ({ breakdown = [], record = {}, fieldTitle }) => {
  const breakdownByUser = breakdown.includes('user');
  const breakdownByStatus = breakdown.includes('status');
  const breakdownByColor = breakdown.includes('color');
  const {
    status = 'Archived Status',
    user = 'Unassigned',
    title,
    color,
  } = record;
  if (title) return title;
  if (!breakdownByUser && !breakdownByStatus && !breakdownByColor) return fieldTitle;
  let base = '';
  if (breakdownByStatus) base += status;
  if (breakdownByUser) base += `${base.length > 0 ? '-' : ''}${user}`;
  if (breakdownByColor) base += `${base.length > 0 ? '-' : ''}${color ?? '#FFFFFFFF'}`;
  return base;
};

export const formatTimeInStatus = (rawValue) => {
  const value = parseFloat(rawValue);
  if (Number.isNaN(value)) return rawValue;
  // Value is in days
  if (value > 365) {
    const years = Math.floor(value / 365);
    const months = Math.floor((value - (years * 365)) / 30);
    return `${years} yr ${months}mo`;
  }
  if (value > 30) {
    const months = Math.floor(value / 30);
    const days = Math.floor(value - (months * 30));
    return `${months}mo ${days}d`;
  }
  if (value > 0) {
    return `${value.toFixed(2)}d`;
  }
  if (value > (1 / 24)) {
    const hours = value * 24;
    return `${hours.toFixed(2)}hr`;
  }
  const minutes = value * 24 * 60;
  return `${minutes.toFixed(2)}min`;
};

export const dateRangeToDayDiffs = (dateRange) => {
  if (!dateRange || dateRange.length < 2) return [];
  const [startTime, endTime] = dateRange;
  if (isNullOrUndefined(startTime)
    || Number.isNaN(startTime)
    || isNullOrUndefined(endTime)
    || Number.isNaN(endTime)
  ) return [];
  const now = DateTime.local();
  const startDT = DateTime.fromMillis(startTime).startOf('day');
  const endDT = DateTime.fromMillis(endTime).startOf('day');
  return [
    Math.floor(now.diff(startDT).as('days')),
    Math.floor(now.diff(endDT).as('days')),
  ];
};

export const dayDiffsToDateRange = (dayDiffs) => {
  if (!dayDiffs || dayDiffs.length < 2) return null;
  const [startDiff, endDiff] = dayDiffs;
  if (isNullOrUndefined(startDiff)
    || Number.isNaN(startDiff)
    || isNullOrUndefined(endDiff)
    || Number.isNaN(endDiff)
  ) return null;
  const now = DateTime.local();
  return [
    now.minus({ days: startDiff }).toMillis(),
    now.minus({ days: endDiff }).toMillis(),
  ];
};


const BREAKDOWN_KEY_TO_DATA_KEY = {
  user: 'userId',
  status: 'statusId',
  boardCard: 'cardTitle',
  boardStatus: 'boardStatus',
  hourType: 'hourType',
  division: 'divisionId',
  team: 'teamId',
  project: 'projectId',
  costcode: 'costcodeId',
};

export const filterData = ({
  fullData = [],
  filters = {},
  breakdown = [],
}) => {
  const bSet = new Set(breakdown);
  const filterSets = [];

  Object.keys(filters).forEach((filterKey) => {
    filterSets.push({
      filterKey,
      filterSet: new Set(filters[filterKey]),
    });
  });

  return fullData.filter((datum) => (
    filterSets.every(({ filterKey, filterSet }) => (
      !bSet.has(filterKey)
      || filterSet.size === 0
      || filterSet.has(datum[BREAKDOWN_KEY_TO_DATA_KEY[filterKey]])
    ))
  ));
};

export const getValidCharts = (type) => {
  switch (type) {
    case 'dropdown':
    case 'yes-no':
      return new Set(['pie', 'stackedLine']);
    case 'conversion':
    case 'timeInStatus':
    case 'numberOfCards':
    case 'numberOfSubmissions':
    case 'newCardsInStatus':
    case 'hoursWorked':
      return new Set(['line', 'bar', 'number', 'table']);
    case 'text':
    case 'table':
    case 'calculation':
    default:
      return new Set(['line', 'bar', 'number', 'table', 'list']);
  }
};

export const rangePresetToTimestamps = (preset) => {
  const now = moment().valueOf();
  switch(preset) {
    case 'This Week': return [moment().startOf('week').valueOf(), now];
    case 'This Month': return [moment().startOf('month').valueOf(), now];
    case 'This Quarter': return [moment().startOf('quarter').valueOf(), now];
    case 'This Year': return [moment().startOf('year').valueOf(), now];
    case 'Last 7 Days': return [moment().subtract(7, 'days').valueOf(), now];
    case 'All Time': return [moment().year(2018).startOf('year').valueOf(), now];
    case 'Last 30 Days':
    default:
      return [moment().subtract(30, 'days').valueOf(), now];
  }
};

export const getDateRangeFromConfig = ({ datePreset, dateRange }) => {
  if (datePreset) {
    const [startTime, endTime] = rangePresetToTimestamps(datePreset);
    return [
      moment(startTime),
      moment(endTime)
    ];
  }
  if (!dateRange || dateRange.length < 2) return null;
  return [
    moment(dateRange[0]),
    moment(dateRange[1])
  ];
};

export const canBreakdownByField = ({
  configProps: {
    openLimit,
    numAnswers = 1,
  } = {},
  selectedType
}) => {
  if (selectedType !== 'yes-no' && selectedType !== 'dropdown') return false;
  if (selectedType === 'yes-no') return true;
  return !openLimit && numAnswers === 1;
};

export const getFieldOptions = ({
  sectionName,
  field: {
    id: fieldId,
    configProps: {
      title: fieldName,
      columns = [],
    } = {},
    selectedType,
  } = {},
}) => {
  if (selectedType === 'table') {
    return columns.map((col) => (
      { label: `${sectionName} - ${fieldName} - ${col.name}`, value: `${fieldId}/${col.key}` }
    ));
  }
  return [{ label: `${sectionName} - ${fieldName}`, value: fieldId }];
};

export const getFieldType = (fieldId, fieldMap = {}) => {
  if (
    fieldId === 'numberOfCards'
    || fieldId === 'timeInStatus'
    || fieldId === 'conversion'
    || fieldId === 'newCardsInStatus'
    || fieldId === 'numberOfSubmissions'
    || fieldId === 'hoursWorked'
    || !fieldId
  ) return fieldId;
  if (fieldId.includes('/')) {
    const [realFieldId] = fieldId.split('/');
    return (fieldMap[realFieldId] ?? {}).selectedType;
  }
  return (fieldMap[fieldId] ?? {}).selectedType;
};