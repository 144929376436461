import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Col,
  Row,
  Select,
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { toTitleCase } from '../../../helpers/helpers';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import Colors from '../../../constants/Colors';
import BorderlessButton from '../../../common/buttons/BorderlessButton';

const validFieldTypes = new Set([
  'text',
  'attribute',
  'yes-no',
  'dropdown',
  'table',
]);

const validDropdownTypes = new Set([
  'Customers',
  'Custom',
  'Projects',
  'Users',
  'Costcodes',
  'Vendors',
  'Equipment',
  'Forms',
  'Labels',
  'Contacts',
  'Buckets',
]);

const validTableTypes = new Set([
  'TimeEntry',
]);

const getTimeEntryTableFields = (t, field) => {
  if (!field) return [];
  const { name, id } = field;
  return [
    {
      ...field,
      id: `${id}.total_hours`,
      name: `${name}: Any Entry Total Hours`,
      selectedType: 'text',
    },
    {
      ...field,
      id: `${id}.work_hours`,
      name: `${name}: Any Entry Work Hours`,
      selectedType: 'text',
    },
    {
      ...field,
      id: `${id}.break_hours`,
      name: `${name}: Any Entry Break Hours`,
      selectedType: 'text',
    },
    {
      ...field,
      id: `${id}.projectId`,
      name: `${name}: Any Entry ${t('Project')}`,
      selectedType: 'dropdown',
      configProps: {
        dataType: 'Projects',
      },
    },
    {
      ...field,
      id: `${id}.costcodeId`,
      name: `${name}: Any Entry Cost Code`,
      selectedType: 'dropdown',
      configProps: {
        dataType: 'Costcodes',
      },
    },
    {
      ...field,
      id: `${id}.costcode_code`,
      name: `${name}: Any Entry Cost Code - Code`,
      selectedType: 'text',
    },
    {
      ...field,
      id: `${id}.costcode_name`,
      name: `${name}: Any Entry Cost Code - Name`,
      selectedType: 'text',
    },
  ];
};

export default function ConditionalRenderingBuilder({
  sections = [],
  id,
  onChange,
  conditionalRenderingFormula = {},
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  labels = [],
  contactAddressBooks = {},
  buckets = [],
  showDelete = false,
  showAdd = false,
  onAddClicked,
  onDeleteClicked,
  isSection = false,
}) {
  const { t } = useTranslation();
  const [selectedField, setSelectedField] = useState();
  const [selectedOperator, setSelectedOperator] = useState('=');
  const [selectedValue, setSelectedValue] = useState();
  const [selectedType, setSelectedType] = useState();

  useEffect(() => {
    const { field, operator, value, type } = conditionalRenderingFormula ?? {};
    setSelectedField(field);
    setSelectedOperator(operator);
    setSelectedValue(value);
    setSelectedType(type);
  }, [conditionalRenderingFormula]);

  const flatFields = useMemo(() => {
    const relevantFields = [];

    sections.forEach(({ fields: sectionFields = [], name, id: sId }) => (
      // Include same table for calculation columns
      sectionFields
        .filter(({
          id: fieldId,
          selectedType,
          configProps: {
            dataType,
          } = {},
        }) => (
          fieldId !== id
          && validFieldTypes.has(selectedType)
          && (selectedType !== 'dropdown' || validDropdownTypes.has(dataType))
          && (selectedType !== 'table' || validTableTypes.has(dataType))
        ))
        .forEach((field = {}) => {
          const { configProps: { title } = {}, selectedType } = field;
          const formattedName = `${name} - ${title}`;

          if (selectedType === 'table') {
            relevantFields.push(
              ...getTimeEntryTableFields(t, { ...field, name: formattedName, sectionId: sId }),
            );
            return;
          }

          relevantFields.push({ ...field, name: formattedName, sectionId: sId });
        })
    ));
    return relevantFields;
  }, [sections, id]);

  const formatCostcodeName = useCallback((costcode) => {
    const { projectId, name, phaseId } = costcode;
    const project = projectIdMap[projectId];
    const phase = phases.find((p) => p.id === phaseId);
    return `${project?.name ?? 'Global'} - ${phase?.name ?? 'Unphased'} - ${name}`;
  }, [projectIdMap, phases]);

  const getDropdownData = useCallback((dataType, { filterKey, filterValue }) => {
    switch (dataType) {
      case 'Customers':
        return customers.filter((c) => c.active);
      case 'Projects':
        return projects.filter((p) => p.active);
      case 'Users':
        return users.filter((u) => u.active);
      case 'Costcodes':
        return costcodes
          .filter((c) => c.active)
          .map((c) => ({
            id: c.id,
            name: formatCostcodeName(c),
          }));
      case 'Vendors':
        return vendors.filter((v) => v.active);
      case 'Equipment':
        return equipment.filter((e) => e.active);
      case 'Forms':
        return formTemplates.filter((f) => f.active);
      case 'Labels':
        return labels.map((label) => ({ id: label.id, name: label.title }));
      case 'Contacts':
        return Object.values(contactAddressBooks).flat();
      case 'Custom':
        return (flatFields.find(choose => choose.id === selectedField)?.configProps.customOptions || []);
      case 'Buckets':
        return buckets.filter((b) => !filterKey || !filterValue || b[filterKey] === filterValue);
      default:
        return [];
    }
  }, [
    selectedField,
    flatFields,
    customers,
    projects,
    users,
    costcodes,
    vendors,
    equipment,
    formTemplates,
    labels,
    contactAddressBooks,
    buckets,
    formatCostcodeName,
  ]);

  const possibleOperators = useMemo(() => {
    const { selectedType } = flatFields.find(({ id: fieldId }) => fieldId === selectedField) || {};
    const equalityTerm = selectedType === 'dropdown' ? 'include' : 'equal';
    const baseOperators = [
      {
        value: '=',
        label: `${toTitleCase(equalityTerm)}s`,
      },
      {
        value: '!=',
        label: `Does not ${equalityTerm}`,
      },
    ];

    if (selectedType === 'dropdown' || selectedType === 'yes-no') return baseOperators;
    baseOperators.push(
      {
        value: 'has',
        label: 'Includes',
      },
      {
        value: '!has',
        label: 'Does not include',
      },
      {
        value: 'contains',
        label: 'Contains',
      },
      {
        value: '!contains',
        label: 'Does not contain',
      }
    );

    return baseOperators;
  }, [selectedField, flatFields]);

  const onSelectedValueChange = useCallback((newValue) => {
    setSelectedValue(newValue);
    onChange({
      field: selectedField,
      operator: selectedOperator,
      value: newValue,
      type: selectedType,
    });
  }, [selectedField, selectedOperator, onChange, selectedType]);

  const onSelectedOperatorChange = (newOperator) => {
    setSelectedOperator(newOperator);
    onChange({
      field: selectedField,
      operator: newOperator,
      value: selectedValue,
      type: selectedType,
    });
  };

  const ValueInput = useMemo(() => {
    const {
      selectedType: fieldSelectedType,
      configProps: {
        dataType,
        subDataType,
      } = {},
    } = flatFields.find(({ id: fieldId }) => fieldId === selectedField) || {};
    if (!fieldSelectedType) return null;
    switch (fieldSelectedType) {
      case 'yes-no':
        return (
          <Select
            style={{ width: 250 }}
            value={selectedValue}
            onChange={onSelectedValueChange}
          >
            <Select.Option value="yes">Yes</Select.Option>
            <Select.Option value="no">No</Select.Option>
            <Select.Option value="n/a">N/A</Select.Option>
          </Select>
        );
      case 'dropdown': {
        const filter = dataType === 'Buckets' ? { filterKey: 'bucketTemplateId', filterValue: subDataType } : {};
        const dropdownData = getDropdownData(dataType, filter);
        return (
          <Select
            style={{ width: 350 }}
            value={selectedValue || []}
            allowClear
            onChange={onSelectedValueChange}
            optionFilterProp="label"
            showSearch
            mode="multiple"
          >
            {dropdownData.map(({ id: value, name: label }) => (
              <Select.Option key={value} value={value} label={label}>
                {label}
              </Select.Option>
            ))}
          </Select>
        );
      }
      default:
        return (
          <OnTraccrTextInput
            style={{ width: 150 }}
            value={selectedValue}
            onChange={(e) => onSelectedValueChange(e.target.value)}
          />
        );
    }
  }, [
    selectedField,
    selectedValue,
    flatFields,
    getDropdownData,
    onSelectedValueChange,
  ]);

  const onFieldChange = (newField) => {
    setSelectedField(newField);
    setSelectedValue(null);
    onChange({
      field: newField,
      operator: selectedOperator,
      value: null,
      type: selectedType,
    });
  };

  return (
    <>
      <Row gutter={16} style={{ paddingTop: 15 }}>
        <Col>
          <Select
            style={{ width: 525 }}
            value={selectedField}
            onChange={onFieldChange}
          >
            {flatFields.map(({ id: fieldId, name: fieldName }) => (
              <Select.Option key={fieldId} value={fieldId}>
                {fieldName}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            style={{ width: 200 }}
            value={selectedOperator}
            onChange={onSelectedOperatorChange}
          >
            {possibleOperators.map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          {ValueInput}
        </Col>
        { !!showDelete && (
          <Col>
            <BorderlessButton
              style={{ background: isSection ? 'transparent' : 'white' }}
              iconNode={(
                <DeleteOutlined
                  style={{
                    color: Colors.ONTRACCR_RED,
                    marginLeft: 0,
                  }}
                />
              )}
              onClick={onDeleteClicked}
            />
          </Col>
        )}
      </Row>
      { !!showAdd && (
        <OnTraccrButton
          type="primary"
          style={{ marginTop: 15 }}
          icon={<PlusOutlined />}
          title="Add Another Condition"
          onClick={onAddClicked}
        />
      )}
    </>
  );
}

ConditionalRenderingBuilder.propTypes = {
  onChange: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  id: PropTypes.string,
  conditionalRenderingFormula: PropTypes.shape({
    field: PropTypes.string,
    operator: PropTypes.string,
    value: PropTypes.string,
  }),
  customers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  costcodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  phases: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  projectIdMap: PropTypes.shape({}),
  vendors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  equipment: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  formTemplates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  })),
  buckets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    bucketTemplateId: PropTypes.string,
  })),
  contactAddressBooks: PropTypes.shape({}),
  showDelete: PropTypes.bool,
  showAdd: PropTypes.bool,
  onAddClicked: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  isSection: PropTypes.bool,
};

ConditionalRenderingBuilder.defaultProps = {
  sections: [],
  id: null,
  conditionalRenderingFormula: {},
  customers: [],
  projects: [],
  users: [],
  costcodes: [],
  phases: [],
  projectIdMap: {},
  vendors: [],
  equipment: [],
  formTemplates: [],
  labels: [],
  contactAddressBooks: {},
  buckets: [],
  showDelete: false,
  showAdd: false,
  onAddClicked: null,
  onDeleteClicked: null,
  isSection: false,
};
