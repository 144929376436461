import React, { useCallback, useState, useMemo } from 'react';
import { Row, Select } from 'antd';
import { ToolOutlined } from '@ant-design/icons';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';

import { getType } from './workflowHelpers';

const HOVER_TEXT = `
Use this step to update equipment location/project. The project choosen in the To Location column will be used to update the equipment location/project.
`;

const DISABLED_TEXT = 'This step requires at least one Equipment table field with the To Location column.';

export default function WorkflowUpdateEquipmentNode({
  setElements,
  setDataMap,
  isDisplay,
  sections = [],
} = {}) {
  return function _({
    id,
    draggable,
    disabled,
    data = {},
  }) {
    const {
      fields: initialSelected = [],
    } = data;
    const [selected, setSelected] = useState(initialSelected);

    const setters = {
      fields: setSelected,
    };
    const onChange = useCallback((key) => (newValue) => {
      setDataMap((dataMap) => {
        const {
          [id]: oldData = {},
        } = dataMap;
        return {
          ...dataMap,
          [id]: {
            ...oldData,
            [key]: newValue,
          },
        };
      });
      setters[key](newValue);
    }, [id, setDataMap]);

    const equipmentTableFields = useMemo(() => (
      sections.reduce((sAcc, section) => {
        if (!section || !section.fields) return sAcc;
        const { fields = [] } = section;
        return sAcc.concat(
          fields.reduce((fAcc, field) => {
            const { id: fieldId, configProps: { title, columns } = {} } = field;
            const type = getType(field);
            if (type === 'table - Equipment' && columns?.some((col) => col.name === 'To Location')) fAcc.push({ label: title, value: fieldId });
            return fAcc;
          }, []),
        );
      }, [])
    ), [sections]);

    const title = `Update Equipment${disabled ? ' - DISABLED' : ''}`;

    return (
      <WorkflowActionNode
        id={id}
        title={title}
        Icon={ToolOutlined}
        type="updateEquipment"
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : {}}
      >
        {!draggable && (
        <div>
          <Row style={{ marginTop: 5 }}>
            Select Equipment Table Field(s):
          </Row>
          <Row style={{ margin: '10px 0px' }}>
            <Select
              mode="multiple"
              onChange={onChange('fields')}
              value={selected}
              options={equipmentTableFields}
              optionFilterProp="label"
            />
          </Row>
          <WorkflowHandle type="target" position="top" disabled={isDisplay} />
        </div>
        )}
      </WorkflowActionNode>
    );
  };
}
