export default {};

export const getAttributeTypes = (t) => [
  { value: 'projects', label: t('Projects') },
  // Support these later
  // { value: 'costcodes', label: 'Cost Codes' },
  // { value: 'users', label: 'Users' },
];

export const INVALID_TYPES = new Set([
  'attachment',
  'staticAttachments',
  'multiSig',
  'payment',
  'table',
  'weather',
]);
