import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { createFormMapping, deleteFormMapping, updateFormMapping } from './state/boards.actions';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import { settingsColumns } from './boardHelpers';
import CustomFieldFormMappingDrawer from '../forms/CustomFieldFormMappingDrawer/CustomFieldFormMappingDrawer';


export default ({
  boardId,
  divisions,
  cardTypeId,
}) => {
  const dispatch = useDispatch();

  const formMappings = useSelector(state => state.boards.formMappings);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState({});

  const showDrawer = useCallback(() => setDrawerVisible(true),[]);
  const hideDrawer = useCallback(() => {
    setDrawerVisible(false);
    setSelectedMapping({});
  },[]);

  const onMappingSave = useCallback((newMapping) => {
    const {
      id
    } = selectedMapping;
    const {
      mappingName,
      selectedForm,
      fieldMappings,
      useFormPermissions,
    } = newMapping;
    const boolUseForm = !!useFormPermissions;
    if (!id) {
      dispatch(createFormMapping({
        name: mappingName,
        typeId: boardId,
        formTemplateId: selectedForm,
        fieldMappings,
        useFormPermissions: boolUseForm,
      }))
    } else {
      dispatch(updateFormMapping(id, {
        name: mappingName,
        typeId: boardId,
        formTemplateId: selectedForm,
        fieldMappings,
        useFormPermissions: boolUseForm,
      }))
    }
    hideDrawer();
  },[dispatch, formMappings, selectedMapping, hideDrawer]);

  const onEdit = useCallback((record) => () => {
    setSelectedMapping(record);
    setDrawerVisible(true);
  },[setDrawerVisible, setSelectedMapping]);
  
  const onDelete = useCallback((record) => () => {
    const { id, } = record;
    dispatch(deleteFormMapping(id))
  },[dispatch]);

  const columns = useMemo(settingsColumns(onEdit, onDelete),[onEdit, onDelete]);

  return (
    <>
      <div>
        <Row style={{ marginTop: 10 }}>
          <OnTraccrButton title='Add' icon={<PlusOutlined/>} onClick={showDrawer}/>
        </Row>
        <div className='file-list-container' style={{ marginTop: 15 }}>
          <Table
            dataSource={formMappings}
            columns={columns}
            size='small'
            pagination={false}
            scroll
          />
        </div>
      </div>
      <CustomFieldFormMappingDrawer
        visible={drawerVisible}
        boardId={boardId}
        profileTypeId={cardTypeId}
        divisions={divisions}
        onSubmit={onMappingSave}
        onClose={hideDrawer}
        data={selectedMapping}
        isSettings
        type="card"
      />
    </>
  )
}