import React, { useMemo } from 'react';
import {
  Row, Col, Select,
  Space,
} from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function TableProjectPreload({
  configProps,
  updateConfig,
  divisions,
  sections = [],
  hideTitle = false,
}) {
  const projects = useSelector((state) => state.projects.projects);

  const divSet = useMemo(() => new Set(divisions), [divisions]);

  const projectOptions = useMemo(() => (
    projects
      .filter((proj) => proj.active && divSet.has(proj.divisionId))
      .map((proj) => ({
        value: proj.id,
        label: `${proj.number} - ${proj.name}`,
      }))
  ), [projects, divSet]);

  const projectDropdownOptions = sections.reduce((acc, section) => {
    const { fields = [], name: sectionName } = section;
    return acc.concat(
      fields.reduce((fieldAcc, field) => {
        const {
          id: fieldId,
          selectedType,
          configProps: {
            dataType: fieldDataType,
            title: fieldTitle,
          } = {},
        } = field;
        if (selectedType !== 'dropdown') return fieldAcc;
        if (fieldDataType !== 'Projects') return fieldAcc;
        return fieldAcc.concat([{
          label: `${sectionName} - ${fieldTitle}`,
          value: fieldId,
        }]);
      }, []),
    );
  }, []);

  const updateValue = (key) => (value) => {
    let newVal = value;
    if (key === 'preloadDateCount' && !value) newVal = 1;
    if (key === 'preloadDateModifier' && newVal === 'weeks') {
      updateConfig({
        [key]: newVal,
        preloadDateCount: 1,
      });
    } else {
      updateConfig({ [key]: newVal });
    }
  };

  return (
    <Space direction="vertical">
      {!hideTitle && (
        <Row style={{ marginTop: 10 }}>
          Load New Entries
        </Row>
      )}
      <Row align="middle" gutter={10} style={{ marginLeft: hideTitle ? 0 : 10 }}>
        <Col>
          With Project
        </Col>
        <Col>
          <Select
            options={projectOptions}
            style={{ width: 500 }}
            optionFilterProp="label"
            allowClear
            showSearch
            placeholder="Select a Project"
            value={configProps.preloadProject}
            disabled={configProps.preloadDropdown}
            onChange={updateValue('preloadProject')}
          />
        </Col>
      </Row>
      <Row align="middle" gutter={10} style={{ marginLeft: hideTitle ? 0 : 10 }}>
        <Col>
          Or Project Dropdown
        </Col>
        <Col>
          <Select
            style={{ width: 500 }}
            options={projectDropdownOptions}
            optionFilterProp="label"
            allowClear
            showSearch
            placeholder="Select a Project Dropdown Field"
            value={configProps.preloadDropdown}
            disabled={configProps.preloadProject}
            onChange={updateValue('preloadDropdown')}
          />
        </Col>
      </Row>
    </Space>
  );
}

/* eslint-disable react/forbid-prop-types */
TableProjectPreload.propTypes = {
  configProps: PropTypes.object.isRequired,
  updateConfig: PropTypes.func.isRequired,
  divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
  sections: PropTypes.array,
  hideTitle: PropTypes.bool,
};

TableProjectPreload.defaultProps = {
  sections: [],
  hideTitle: false,
};

export default TableProjectPreload;
