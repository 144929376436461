import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HoverHelp from '../../../common/HoverHelp';

import ReportSkeleton from '../ReportSkeleton';
import { ReportColumn } from '../ReportColumnHeader';
import getEmployeeProjectFilters from '../EmployeeProjectFilters';

import {
  filterOnCols,
  isValidTaskHelper,
  getProjectNames,
} from '../../reportHelpers';

import config from '../../../config';
import { getIdMap } from '../../../helpers/helpers';

import {
  computeData,
  getDateArray,
} from './staffTimesheets.helpers';

import Debouncer from '../../../helpers/Debouncer';

import {
  TYPE_MAP,
  DEFAULT_ENABLED_FILTERS,
  DEFAULT_WORK_TYPE,
} from './staffTimesheets.constants';

import { formatProjectLabelFromCompanySettings } from '../../../projects/projectHelpers';

const debouncer = new Debouncer();

const getAllColumns = () => {
  let allColumns = [
    ReportColumn('Employee', {
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => a?.name?.localeCompare?.(b?.name),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    }),
    ReportColumn('Employee ID', {
      key: 'employeeId',
      dataIndex: 'employeeId',
      sorter: (a, b) => a?.employeeId?.localeCompare?.(b?.employeeId),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    }),
  ];
  if (config.showDivisions) {
    allColumns.push(ReportColumn('Division', {
      sorter: (a, b) => a?.division?.localeCompare?.(b?.division),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    }));
  }
  allColumns = allColumns.concat([
    ReportColumn('Project', {
      sorter: (a, b) => a?.project?.localeCompare?.(b?.project),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    }),
    ReportColumn('Cost Code', {
      key: 'costcode',
      dataIndex: 'costcode',
    }),
    ReportColumn('Service Location', {
      key: 'serviceLocation',
      dataIndex: 'serviceLocation',
    }),
    ReportColumn('Reg Hrs', {
      key: 'regularHours',
      dataIndex: 'regularHours',
    }),
    ReportColumn('PTO Hrs', {
      key: 'ptoHours',
      dataIndex: 'ptoHours',
    }),
    ReportColumn('OT Hrs', {
      key: 'otHours',
      dataIndex: 'otHours',
    }),
    ReportColumn('Total Hrs', {
      key: 'totalHours',
      dataIndex: 'totalHours',
    }),
  ]);

  return allColumns;
};

function StaffTimesheets({
  renderColFilters,
  renderRowFilters,
  columns,
  users,
  projects,
  costcodes,
  onColumnsChanged,
  zeroHoursEnabled: propZeroHoursEnabled,
  onZeroHoursChanged,
  height,
  onDataChanged,
  onFiltersChanged,
  settings,
  isRounded,
  userDivisions,
  timeEntryUserMap,
  dateRange,
  divisions,
  onRef,
}) {
  const { t } = useTranslation();
  const allColumns = useMemo(() => getAllColumns(), []);

  const getColumns = useCallback(() => {
    const dateRows = getDateArray(dateRange).map((date) => (
      ReportColumn(date, {
        key: date,
        dataIndex: date,
      })
    ));
    if (config.showDivisions) {
      return [allColumns[0], allColumns[1]].concat(dateRows).concat(allColumns.slice(2));
    }
    return [allColumns[0]].concat(dateRows).concat(allColumns.slice(1));
  }, [dateRange]);

  const [checkedColumns, setCheckedColumns] = useState(
    new Set(getColumns().map((col) => col.key).filter(filterOnCols(columns))),
  );
  const [stateColumns, setStateColumns] = useState(
    getColumns().filter((col) => checkedColumns.has(col.key)),
  );
  const [filters, setFilters] = useState({
    users,
    projects: new Set(getProjectNames(projects, settings).concat('None')),
    costcodes: new Set(costcodes.map((cc) => cc.code).concat('None')),
  });
  const [enabledFilters, setEnabledFilters] = useState(DEFAULT_ENABLED_FILTERS);
  const [workType, setWorkType] = useState(DEFAULT_WORK_TYPE);
  const [zeroHoursEnabled, setZeroHoursEnabled] = useState(propZeroHoursEnabled);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtersChanged, setFiltersChanged] = useState(false);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const divisionMap = useMemo(() => getIdMap(divisions), [divisions]);

  const onZeroHoursToggle = useCallback(() => {
    setZeroHoursEnabled(!zeroHoursEnabled);
    onZeroHoursChanged(!zeroHoursEnabled);
  }, [zeroHoursEnabled]);

  const toggleFilter = useCallback((item, checked) => {
    const {
      key: itemKey,
    } = item;

    const newChecked = new Set(checkedColumns);

    if (checked) {
      newChecked.add(itemKey);
    } else {
      newChecked.delete(itemKey);
    }
    const newCols = getColumns().filter((col) => newChecked.has(col.key));
    setStateColumns(newCols);
    setCheckedColumns(newChecked);
  }, [
    getColumns,
    checkedColumns,
    allColumns,
  ]);

  const updateRowFilters = useCallback((key, checked) => {
    setEnabledFilters({
      ...enabledFilters,
      [key]: checked,
    });
    toggleFilter({ key }, checked);
  }, [enabledFilters, toggleFilter]);

  const isValidTask = useCallback(isValidTaskHelper({
    dateRange,
    filters,
    projectIdMap,
    costcodeIdMap,
    workType,
    settings,
  }), [
    dateRange,
    filters,
    projectIdMap,
    costcodeIdMap,
    workType,
    settings,
  ]);

  const getAggregateKey = useCallback((
    projectName,
    costcodeName,
    serviceLocationText,
  ) => {
    const {
      costcode,
      project,
      serviceLocation,
    } = enabledFilters;
    const keys = [projectName, costcodeName, serviceLocationText];
    const taskFilters = [serviceLocation, costcode, project];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const index in taskFilters) {
      const filter = taskFilters[index];
      if (filter) return keys.join('-');
      keys.pop();
    }
    return 'All';
  }, [
    enabledFilters,
    filters,
  ]);

  const dates = useMemo(() => getDateArray(dateRange), [dateRange]);

  const colFilters = useMemo(() => {
    const cols = config.showDivisions
      ? [{
        title: 'Division',
        key: 'division',
        checked: checkedColumns.has('division'),
      },
      ]
      : [];
    dates.forEach((date) => {
      cols.push({
        title: date,
        key: date,
        checked: checkedColumns.has(date),
      });
    });
    return cols.concat([{
      title: 'Hours (Regular)',
      key: 'regularHours',
      checked: checkedColumns.has('regularHours'),
    }, {
      title: 'Hours (PTO)',
      key: 'ptoHours',
      checked: checkedColumns.has('ptoHours'),
    }, {
      title: 'Hours (OT)',
      key: 'otHours',
      checked: checkedColumns.has('otHours'),
    }, {
      title: 'Total Hours',
      key: 'totalHours',
      checked: checkedColumns.has('totalHours'),
    }]);
  }, [checkedColumns, dates]);

  const rowFilters = useMemo(() => {
    const { projects: selectedProjects } = filters;
    const relevantCostcodes = costcodes.filter((costcode) => {
      if (selectedProjects.size === 0) return !costcode.projectId;
      const project = projectIdMap[costcode.projectId];
      if (!project) return false;
      const projectLabel = formatProjectLabelFromCompanySettings({
        name: project.name,
        number: project.number,
        settings,
      });
      return selectedProjects.has(projectLabel);
    });

    return getEmployeeProjectFilters({
      onFilterToggle: (type, checked) => {
        setFiltersChanged(true);
        if (type === 'users') {
          setFilters({
            ...filters,
            users: users.filter((user) => checked.has(user.name)),
          });
          return;
        }
        if (type === 'workType') {
          const newWorkType = new Set();
          Array.from(checked).forEach((checkedType) => {
            TYPE_MAP[checkedType]?.forEach((newType) => {
              newWorkType.add(newType);
            });
          });
          setWorkType(newWorkType);
          return;
        }
        setFilters({
          ...filters,
          [type]: checked,
        });
      },
      onCheckChanged: (type, checked) => updateRowFilters(type, checked),
      users,
      projects,
      costcodes: relevantCostcodes,
      showPhase: false,
      checkedColumns,
      showWorkType: true,
      showServiceLocations: true,
      settings,
      t,
    });
  }, [
    checkedColumns,
    users,
    projects,
    costcodes,
    projectIdMap,
    updateRowFilters,
    t,
  ]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const newData = await debouncer.debounce(() => (
        computeData({
          ...filters,
          onDataChanged,
          onFiltersChanged,
          settings,
          isRounded,
          userDivisions,
          timeEntryUserMap,
          zeroHoursEnabled,
          isValidTask,
          getAggregateKey,
          divisionMap,
          projectIdMap,
          costcodeIdMap,
          dateRange,
          dates,
        })
      ), 200);
      setLoading(false);
      setData(newData);
    };
    getData();
  }, [
    onDataChanged,
    onFiltersChanged,
    settings,
    isRounded,
    userDivisions,
    timeEntryUserMap,
    zeroHoursEnabled,
    isValidTask,
    getAggregateKey,
    divisionMap,
    projectIdMap,
    costcodeIdMap,
    filters,
    dateRange,
    dates,
  ]);

  useEffect(() => {
    if (filtersChanged) return;
    setFilters({
      users,
      projects: new Set(getProjectNames(projects, settings).concat('None')),
      costcodes: new Set(costcodes.map((cc) => cc.code).concat('None')),
    });
  }, [
    users,
    projects,
    costcodes,
    settings,
    filtersChanged,
  ]);

  useEffect(() => {
    onRef({
      onFilterToggle: toggleFilter,
    });
  }, [onRef, toggleFilter]);

  useEffect(() => {
    onZeroHoursChanged(zeroHoursEnabled);
  }, [
    onZeroHoursChanged,
  ]);

  useEffect(() => {
    onColumnsChanged(stateColumns);
  }, [
    stateColumns,
  ]);

  useEffect(() => {
    const newColumns = getColumns(dateRange);
    setStateColumns(newColumns);
    setCheckedColumns(
      new Set(getColumns().map((col) => col.key).filter(filterOnCols(columns))),
    );
  }, [dateRange, columns]);

  return (
    <ReportSkeleton
      useVirtualization
      onDataChanged={onDataChanged}
      data={data}
      columns={stateColumns}
      height={height}
      loading={loading}
      colFilters={renderColFilters(colFilters)}
      rowFilters={renderRowFilters(rowFilters)}
      zeroHoursConfig={{
        checked: zeroHoursEnabled,
        onCheckChanged: onZeroHoursToggle,
        hoverHelp: (
          <HoverHelp
            placement="topRight"
            content={(
              <div style={{ width: 250 }}>
                Check this box to show all the users with zero time cards.
              </div>
            )}
          />
        ),
      }}
    />
  );
}

/* eslint-disable react/forbid-prop-types */
StaffTimesheets.propTypes = {
  renderColFilters: PropTypes.func.isRequired,
  renderRowFilters: PropTypes.func.isRequired,
  columns: PropTypes.array,
  users: PropTypes.array,
  projects: PropTypes.array,
  costcodes: PropTypes.array,
  onColumnsChanged: PropTypes.func.isRequired,
  zeroHoursEnabled: PropTypes.bool,
  onZeroHoursChanged: PropTypes.func.isRequired,
  height: PropTypes.number,
  onDataChanged: PropTypes.func.isRequired,
  onFiltersChanged: PropTypes.func.isRequired,
  settings: PropTypes.object,
  isRounded: PropTypes.bool,
  userDivisions: PropTypes.object,
  timeEntryUserMap: PropTypes.object,
  dateRange: PropTypes.array.isRequired,
  divisions: PropTypes.array,
  onRef: PropTypes.func.isRequired,
};

StaffTimesheets.defaultProps = {
  columns: [],
  users: [],
  projects: [],
  costcodes: [],
  zeroHoursEnabled: false,
  height: 450,
  settings: {},
  isRounded: false,
  userDivisions: {},
  timeEntryUserMap: {},
  divisions: [],
};

export default StaffTimesheets;
