export const READ_ALL_PERM = 'readAll';
export const READ_ASSIGNED_PERM = 'readAssigned';
export const READ_PERM_TYPES = [
  { value: READ_ASSIGNED_PERM, label: 'View Assigned' },
  { value: READ_ALL_PERM, label: 'View All' },
];

export const WRITE_ALL_PERM = 'writeAll';
export const WRITE_ASSIGNED_PERM = 'writeAssigned';
export const WRITE_PERM_TYPES = [
  { value: WRITE_ASSIGNED_PERM, label: 'Edit Assigned' },
  { value: WRITE_ALL_PERM, label: 'Edit All' },
];

export const ASSIGN_ALL_PERM = 'assignAll';
export const ASSIGN_ASSIGNED_PERM = 'assignAssigned';
export const ASSIGN_PERM_TYPES = [
  { value: ASSIGN_ASSIGNED_PERM, label: 'Assign Own' },
  { value: ASSIGN_ALL_PERM, label: 'Assign All' },
];
