import {
  CREATE_BOARD_CARD_TEMPLATE,
  DELETE_BOARD_CARD_TEMPLATE,
  GET_BOARD_CARD_TEMPLATES,
  UPDATE_BOARD_CARD_TEMPLATE,
  GET_BOARDS,
  CREATE_BOARD,
  GET_BOARD_DETAILS,
  CREATE_CARD,
  MOVE_CARD,
  GET_BOARD_CARD_FILES,
  UPDATE_CARD,
  DELETE_CARD,
  TOGGLE_BOARD_LOCK,
  GET_BOARD_CARD_NOTES,
  ADD_BOARD_CARD_NOTE,
  DELETE_BOARD,
  UPDATE_BOARD,
  GET_CARD_LINKS,
  GET_CARDS_BY_LINK,
  GET_MY_CARD_TAGS,
  MARK_CARD_TAGS_AS_READ,
  GET_CARD_ATTACHMENTS,
  GET_CARD_EVENTS,
  REORDER_CARDS,
  SWITCH_BOARD_VIEW_TYPE,
  CLOSE_CARD_DRAWER,
  OPEN_CARD_DRAWER,
  GET_BOARDS_LIBRARY,
  GET_BOARDS_LIBRARY_TEMPLATE,
  IMPORT_FROM_BOARD_LIBRARY,
  GET_CARD_EMAILS,
  GET_CARD_EMAIL_THREAD,
  GET_CARD_EMAIL_FILES,
  SET_BOARD_TARGET_CARD,
  OPEN_FORM_MAP_DRAWER,
  CLOSE_FORM_MAP_DRAWER,
  OPEN_FORM_DRAWER,
  CLOSE_FORM_DRAWER,
  DELETE_BOARD_FORM_MAPPING,
  UPDATE_BOARD_FORM_MAPPING,
  CREATE_BOARD_FORM_MAPPING,
  GET_BOARD_FORM_MAPPINGS,
  CREATE_BOARD_TEMPLATE,
  UPDATE_BOARD_TEMPLATE,
  GET_BOARD_TEMPLATES,
  DELETE_BOARD_TEMPLATE,
  MASS_UPLOAD_CARDS,
  GET_CARD_BY_ID,
  CLEAR_SELECTED_CARD,
  SWITCH_BOARD_GANTT_VIEW_TYPE,
  SWITCH_BOARD_GANTT_ZOOM_SCALE,
  GET_CARD_SNAPSHOTS,
  SET_SNAPSHOT_CARD_DATA,
  CLEAR_SNAPSHOT_CARD_DATA,
  SET_BOARD_FILTERS,
  CLEAR_CARD_SHADOW,
  SET_CARD_DRAGGING,
  GET_ALL_BOARDS_DETAILS,
} from '../../state/actionTypes';
import Analytics from '../../helpers/Analytics';
import BoardService from './boards.service';

export const getBoardLibrary = () => async (dispatch) => {
  const { data } = await BoardService.getLibrary();
  if (!data) return false;
  dispatch({
    type: GET_BOARDS_LIBRARY,
    payload: {
      library: data,
    },
  });
  return true;
};

export const getBoardLibraryTemplate = (key) => async (dispatch) => {
  const { data } = await BoardService.getBoardLibraryTemplate(key);
  if (!data) return false;
  dispatch({
    type: GET_BOARDS_LIBRARY_TEMPLATE,
    payload: {
      templateData: data,
      key,
    },
  });
  return true;
};

export const importFromBoardLibrary = (payload) => async (dispatch) => {
  const { data } = await BoardService.importFromLibrary(payload);
  if (!data) return false;
  dispatch({
    type: IMPORT_FROM_BOARD_LIBRARY,
    payload: data,
  });
  return true;
};

export const createBoardCardTemplate = (payload) => async (dispatch) => {
  Analytics.track('BoardCardTemplate/Create');
  const { data } = await BoardService.createBoardCardTempates(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_BOARD_CARD_TEMPLATE,
    payload: {
      newCardTemplate: data,
    },
  });
  return true;
};

export const getBoardCardTemplates = (payload) => async (dispatch) => {
  const { data: boardCardTemplates } = await BoardService.getBoardCardTemplates(payload);
  if (!boardCardTemplates) return false;
  dispatch({
    type: GET_BOARD_CARD_TEMPLATES,
    payload: {
      boardCardTemplates,
    },
  });
  return true;
};

export const deleteBoardCardTemplate = (id, password) => async (dispatch) => {
  Analytics.track('BoardCardTemplate/Delete');
  const { data } = await BoardService.deleteCardTemplate(id, password);
  if (!data) return false;
  dispatch({
    type: DELETE_BOARD_CARD_TEMPLATE,
    payload: {
      cardTemplateId: id,
    },
  });
  return true;
};

export const updateBoardCardTemplate = (id, payload) => async (dispatch) => {
  Analytics.track('BoardCardTemplate/Update');
  const { data } = await BoardService.updateBoardCardTemplate(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_BOARD_CARD_TEMPLATE,
    payload: {
      id,
      newData: payload,
    },
  });
  return true;
};

export const getBoards = () => async (dispatch) => {
  const { data: boards } = await BoardService.getBoards();
  if (!boards) return false;
  dispatch({
    type: GET_BOARDS,
    payload: {
      boards,
    },
  });
  return true;
};

export const createBoard = (payload) => async (dispatch) => {
  Analytics.track('Board/Create');
  const { data: newBoard } = await BoardService.createBoard(payload);
  if (!newBoard) return false;
  dispatch({
    type: CREATE_BOARD,
    payload: {
      newBoard,
    },
  });
  return true;
};

export const updateBoard = (id, payload) => async (dispatch) => {
  Analytics.track('Board/Update');
  const {
    data: {
      statuses: newStatuses,
      cards: updatedCards,
    },
  } = await BoardService.updateBoard(id, payload);
  if (!newStatuses) return false;
  dispatch({
    type: UPDATE_BOARD,
    payload: {
      id,
      newData: payload,
      newStatuses,
      updatedCards,
    },
  });
  return true;
};

export const deleteBoard = (id, password) => async (dispatch) => {
  Analytics.track('Board/Delete');
  const { data: newBoard } = await BoardService.deleteBoard(id, password);
  if (!newBoard) return false;
  dispatch({
    type: DELETE_BOARD,
    payload: {
      id,
    },
  });
  return true;
};

export const toggleBoardLock = (id, isLocked, pin) => async (dispatch) => {
  const { data } = await BoardService.toggleBoardLock(id, isLocked, pin);
  if (!data) return false;
  dispatch({
    type: TOGGLE_BOARD_LOCK,
    payload: {
      id,
      isLocked,
    },
  });
  return true;
};

export const getBoardDetails = (id, shouldUpdateSelectedBoard = true) => async (dispatch) => {
  const { data: boardDetails } = await BoardService.getBoardDetails(id);
  if (!boardDetails) return false;
  dispatch({
    type: GET_BOARD_DETAILS,
    payload: {
      boardId: id,
      boardDetails,
      shouldUpdateSelectedBoard,
    },
  });
  return true;
};

export const getBoardCardFiles = (id) => async (dispatch) => {
  const { data: fileMap } = await BoardService.getBoardCardFiles(id);
  if (!fileMap) return false;
  dispatch({
    type: GET_BOARD_CARD_FILES,
    payload: {
      id,
      fileMap,
    },
  });
  return true;
};

export const getCardById = (id) => async (dispatch) => {
  Analytics.track('BoardCard/Create');
  const { data: card } = await BoardService.getCardById(id);
  if (!card) return false;
  dispatch({
    type: GET_CARD_BY_ID,
    payload: {
      card,
    },
  });
  return true;
};

export const clearSelectedCard = () => ({
  type: CLEAR_SELECTED_CARD,
});

export const createCard = (payload) => async (dispatch) => {
  Analytics.track('BoardCard/Create');
  const { data: newCard } = await BoardService.createCard(payload);
  if (!newCard) return false;
  dispatch({
    type: CREATE_CARD,
    payload: {
      newCard,
    },
  });
  return true;
};

export const uploadCards = (payload) => async (dispatch) => {
  Analytics.track('BoardCard/Upload');
  const { data } = await BoardService.massUpload(payload);
  if (!data) return false;
  dispatch({
    type: MASS_UPLOAD_CARDS,
    payload: {
      newCards: data,
    },
  });
  return true;
};

export const updateCard = (id, payload) => async (dispatch) => {
  Analytics.track('BoardCard/Update');
  const { data: newData } = await BoardService.updateCard(id, payload);
  if (!newData) return false;
  dispatch({
    type: UPDATE_CARD,
    payload: {
      id,
      newData,
      addedUsers: payload.addedUsers,
      removedUsers: payload.removedUsers,
    },
  });
  return true;
};

export const deleteCard = (id) => async (dispatch) => {
  Analytics.track('BoardCard/Delete');
  const { data } = await BoardService.deleteCard(id);
  if (!data) return false;
  dispatch({
    type: DELETE_CARD,
    payload: {
      id,
    },
  });
  return true;
};

export const getCardEvents = (id) => async (dispatch) => {
  const { data: events } = await BoardService.getCardEvents(id);
  if (!events) return false;
  dispatch({
    type: GET_CARD_EVENTS,
    payload: {
      id,
      events,
    },
  });
  return true;
};

export const getCardAttachments = (id) => async (dispatch) => {
  const { data } = await BoardService.getCardAttachments(id);
  if (!data) return false;
  dispatch({
    type: GET_CARD_ATTACHMENTS,
    payload: {
      cardAttachments: data,
    },
  });
  return true;
};

export const getCardEmails = (id) => async (dispatch) => {
  const { data: emails } = await BoardService.getCardEmails(id);
  if (!emails) return false;
  dispatch({
    type: GET_CARD_EMAILS,
    payload: {
      id,
      emails,
    },
  });
  return true;
};

export const getCardEmailThread = (threadId) => async (dispatch) => {
  const { data: thread } = await BoardService.getCardEmailThread(threadId);
  if (!thread) return false;
  dispatch({
    type: GET_CARD_EMAIL_THREAD,
    payload: {
      threadId,
      thread,
    },
  });
  return true;
};

export const getCardEmailFile = ({ threadId, file }) => async (dispatch) => {
  const { data: fullFile } = await BoardService.getCardEmailFile({ file, threadId });
  if (!fullFile) return false;
  dispatch({
    type: GET_CARD_EMAIL_FILES,
    payload: {
      file,
      fullFile,
    },
  });
  return true;
};

export const getCardNotes = (id) => async (dispatch) => {
  const { data } = await BoardService.getCardNotes(id);
  if (!data) return false;
  const { notes, fileMap } = data;
  dispatch({
    type: GET_BOARD_CARD_NOTES,
    payload: {
      id,
      notes,
      fileMap,
    },
  });
  return true;
};

export const addCardNote = ({
  id, text, tags, files,
}) => async (dispatch) => {
  const { data: newNote } = await BoardService.addCardNote({
    id, text, tags, files,
  });
  if (!newNote) return false;
  dispatch({
    type: ADD_BOARD_CARD_NOTE,
    payload: newNote,
  });
  return true;
};

export const getCardLinks = () => async (dispatch) => {
  const { data: cardLinks } = await BoardService.getCardLinks();
  if (!cardLinks) return false;
  dispatch({
    type: GET_CARD_LINKS,
    payload: {
      cardLinks,
    },
  });
  return true;
};

export const getCardsByLink = (linkType, linkId) => async (dispatch) => {
  const { data: cardUserMap } = await BoardService.getCardsByLink(linkType, linkId);
  if (!cardUserMap) return false;
  dispatch({
    type: GET_CARDS_BY_LINK,
    payload: {
      cardUserMap,
    },
  });
  return true;
};

export const getMyCardTags = ({ hasRead = false } = {}) => async (dispatch) => {
  const { data: myCardTags } = await BoardService.getMyCardTags(hasRead);
  if (!myCardTags) return false;
  dispatch({
    type: GET_MY_CARD_TAGS,
    payload: {
      myCardTags,
      hasRead,
    },
  });
  return true;
};

export const markCardTagsAsRead = (noteIds) => async (dispatch) => {
  const { data } = await BoardService.markCardTagsAsRead(noteIds);
  if (!data) return false;
  dispatch({
    type: MARK_CARD_TAGS_AS_READ,
    payload: {
      noteIds,
    },
  });
  return true;
};

export const reorderCards = ({ type, field }) => ({
  type: REORDER_CARDS,
  payload: {
    type,
    field,
  },
});

export const openCardDrawer = (drawerConfig) => ({
  type: OPEN_CARD_DRAWER,
  payload: {
    drawerConfig,
  },
});

export const closeCardDrawer = () => ({
  type: CLOSE_CARD_DRAWER,
});

export const openFormMapDrawer = (drawerConfig) => ({
  type: OPEN_FORM_MAP_DRAWER,
  payload: {
    drawerConfig,
  },
});

export const closeFormMapDrawer = () => ({
  type: CLOSE_FORM_MAP_DRAWER,
});

export const openFormDrawer = (drawerConfig) => ({
  type: OPEN_FORM_DRAWER,
  payload: {
    drawerConfig,
  },
});

export const closeFormDrawer = () => ({
  type: CLOSE_FORM_DRAWER,
});

export const switchViewType = (viewType) => {
  window.localStorage.setItem('boardViewType', viewType);

  return {
    type: SWITCH_BOARD_VIEW_TYPE,
    payload: {
      viewType,
    },
  };
};

export const switchGanttViewType = (ganttViewType) => {
  window.localStorage.setItem('boardGanttViewType', ganttViewType);

  return {
    type: SWITCH_BOARD_GANTT_VIEW_TYPE,
    payload: {
      ganttViewType,
    },
  };
};

export const switchGanttZoomScale = (ganttZoomScale) => {
  window.localStorage.setItem('boardGanttZoomScale', ganttZoomScale);

  return {
    type: SWITCH_BOARD_GANTT_ZOOM_SCALE,
    payload: {
      ganttZoomScale,
    },
  };
};

export const setBoardTargetCard = (payload) => ({
  type: SET_BOARD_TARGET_CARD,
  payload,
});

export const setBoardFilters = (filters) => ({
  type: SET_BOARD_FILTERS,
  payload: { filters },
});

export const moveCard = (id, payload) => async (dispatch) => {
  const {
    boardId,
    destinationId,
    destinationIndex,
    sourceId,
    sourceIndex,
  } = payload;
  // Optimistically move card
  dispatch({
    type: MOVE_CARD,
    payload: {
      id,
      ...payload,
    },
  });
  const { data } = await BoardService.moveCard(id, {
    boardId,
    sourceId,
    sourceIndex,
    destinationId,
    destinationIndex,
  });
  if (!data) {
    // Move failed to persist in backend, revert
    dispatch({
      type: MOVE_CARD,
      payload: {
        id,
        ...payload,
        destinationId: sourceId,
        destinationIndex: sourceIndex,
        sourceId: destinationId,
        sourceIndex: destinationIndex,
      },
    });
    return false;
  }
  return true;
};

export const getFormMappings = (boardId) => async (dispatch) => {
  const { data } = await BoardService.getFormMappings('boards', boardId);
  if (!data) return false;
  dispatch({
    type: GET_BOARD_FORM_MAPPINGS,
    payload: {
      formMappings: data,
    },
  });
  return true;
};

export const createFormMapping = (payload) => async (dispatch) => {
  Analytics.track('BoardFormMapping/Create');
  const { data } = await BoardService.createFormMapping('boards', payload);
  if (!data) return false;
  dispatch({
    type: CREATE_BOARD_FORM_MAPPING,
    payload: {
      newMapping: data,
    },
  });
  return true;
};

export const updateFormMapping = (id, payload) => async (dispatch) => {
  Analytics.track('BoardFormMapping/Update');
  const { data } = await BoardService.updateFormMapping('boards', id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_BOARD_FORM_MAPPING,
    payload: {
      id,
      newData: payload,
    },
  });
  return true;
};

export const deleteFormMapping = (id) => async (dispatch) => {
  Analytics.track('BoardFormMapping/Delete');
  const { data } = await BoardService.deleteFormMapping('boards', id);
  if (!data) return false;
  dispatch({
    type: DELETE_BOARD_FORM_MAPPING,
    payload: {
      mappingId: id,
    },
  });
  return true;
};

export const createBoardTemplate = (payload) => async (dispatch) => {
  const { data: { template, card } = {} } = await BoardService.createBoardTemplate(payload);
  if (!template) return false;
  dispatch({
    type: CREATE_BOARD_TEMPLATE,
    payload: {
      boardTemplate: template,
    },
  });
  if (card) {
    dispatch({
      type: CREATE_BOARD_CARD_TEMPLATE,
      payload: {
        newCardTemplate: card,
      },
    });
  }
  return true;
};

export const updateBoardTemplate = (id, payload) => async (dispatch) => {
  const { data: { template, card } = {} } = await BoardService.updateBoardTemplate(id, payload);
  if (!template) return false;
  dispatch({
    type: UPDATE_BOARD_TEMPLATE,
    payload: {
      id,
      boardTemplate: template,
    },
  });
  if (card) {
    dispatch({
      type: CREATE_BOARD_CARD_TEMPLATE,
      payload: {
        newCardTemplate: card,
      },
    });
  }
  return true;
};

export const getBoardTemplates = () => async (dispatch) => {
  const { data: boardTemplates } = await BoardService.getBoardTemplates();
  if (!boardTemplates) return false;
  dispatch({
    type: GET_BOARD_TEMPLATES,
    payload: {
      boardTemplates,
    },
  });
  return true;
};

export const deleteBoardTemplate = (id) => async (dispatch) => {
  const { data } = await BoardService.deleteBoardTemplate(id);
  if (!data) return false;
  dispatch({
    type: DELETE_BOARD_TEMPLATE,
    payload: {
      id,
    },
  });
  return true;
};

export const getCardSnapshots = (cardId) => async (dispatch) => {
  const { data } = await BoardService.getCardSnapshots(cardId);
  if (!data) return false;
  dispatch({
    type: GET_CARD_SNAPSHOTS,
    payload: {
      cardId,
      snapshots: data,
    },
  });
  return true;
};

export const clearShadow = (boardId, cardId) => async (dispatch) => {
  const { data } = await BoardService.clearShadow(boardId, cardId);
  if (!data) return false;
  dispatch({
    type: CLEAR_CARD_SHADOW,
    payload: {
      cardId,
      boardId,
    },
  });
  return true;
};

export const clearSnapshotCardData = () => ({
  type: CLEAR_SNAPSHOT_CARD_DATA,
});

export const setSnapshotCardData = (filteredData) => ({
  type: SET_SNAPSHOT_CARD_DATA,
  payload: {
    filteredData,
  },
});

export const setCardDragging = (isDragging) => ({
  type: SET_CARD_DRAGGING,
  payload: {
    isDragging,
  },
});

export const getAllBoardsDetails = () => async (dispatch) => {
  const { data } = await BoardService.getAllDetails();
  if (!data) return false;
  dispatch({
    type: GET_ALL_BOARDS_DETAILS,
    payload: {
      data,
    },
  });
  return true;
};

export const reassignCard = (id, payload) => async (dispatch) => {
  Analytics.track('BoardCard/Reassign');
  const { data: newData } = await BoardService.reassignCard(id, payload);
  if (!newData) return false;
  dispatch({
    type: UPDATE_CARD,
    payload: {
      id,
      addedUsers: payload.addedUsers,
      removedUsers: payload.removedUsers,
    },
  });
  return true;
};
