import { sortByObjProperty } from '../../common/helpers';
import {
  GET_LABELS,
  CREATE_LABEL,
  UPDATE_LABEL,
  DELETE_LABEL,
  CREATE_USER,
  UPDATE_USER_BY_ID,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  CREATE_VENDOR,
  UPDATE_VENDOR,
  CREATE_EQUIPMENT,
  UPDATE_EQUIPMENT,
} from '../../state/actionTypes';

const initialState = [];

const customLabelHelper = (state, labels, type) => {
  const newLabels = [];
  const labelIds = new Set();
  state.forEach((label) => {
    newLabels.push(label);
    labelIds.add(label.id);
  });
  labels.forEach((label) => {
    if (labelIds.has(label.labelId)) return;
    newLabels.push({ id: label.labelId, title: label.title, type });
    labelIds.add(label.labelId);
  });
  return newLabels.sort(sortByObjProperty('title'));
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_LABELS: {
      const {
        payload: labels = [],
      } = action;
      return labels.sort(sortByObjProperty('title'));
    }
    case CREATE_LABEL: {
      const {
        payload: {
          label: {
            id,
            title,
            color,
            type,
          },
        } = {},
      } = action;
      return state.concat([{
        id, title, type, color,
      }]).sort(sortByObjProperty('title'));
    }
    case DELETE_LABEL: {
      const {
        payload: {
          labelId,
        } = {},
      } = action;
      return state.filter(({ id: stateLabelId }) => stateLabelId !== labelId);
    }
    case UPDATE_LABEL: {
      const {
        payload: {
          labelId,
          title,
          color,
        } = {},
      } = action;
      return state.map((label) => {
        if (label.id !== labelId) return label;
        return { ...label, title, color };
      }).sort(sortByObjProperty('title'));
    }
    case CREATE_USER:
    case UPDATE_USER_BY_ID: {
      const {
        payload: {
          user: {
            labels = [],
            addedLabels = [],
          } = {},
        } = {},
      } = action;
      const labelArray = action.type === CREATE_USER ? labels : addedLabels;
      return customLabelHelper(state, labelArray, 'users');
    }
    case CREATE_CUSTOMER:
    case UPDATE_CUSTOMER: {
      const {
        payload: {
          labels = [],
          addedLabels = [],
        } = {},
      } = action;
      const labelArray = action.type === CREATE_CUSTOMER ? labels : addedLabels;
      return customLabelHelper(state, labelArray, 'customers');
    }
    case CREATE_VENDOR:
    case UPDATE_VENDOR: {
      const {
        payload: {
          labels = [],
          addedLabels = [],
        } = {},
      } = action;
      const labelArray = action.type === CREATE_VENDOR ? labels : addedLabels;
      return customLabelHelper(state, labelArray, 'vendors');
    }
    case CREATE_EQUIPMENT:
    case UPDATE_EQUIPMENT: {
      const {
        payload: {
          equipment: {
            labels = [],
          } = {},
          createdLabels = [],
        } = {},
      } = action;
      const labelArray = action.type === CREATE_EQUIPMENT ? labels : createdLabels;
      return customLabelHelper(
        state,
        labelArray.map((label) => ({ labelId: label.id, title: label.title })),
        'equipment',
      );
    }
    default:
      return state;
  }
};
