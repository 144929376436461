import React, { useMemo, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form, Select, Divider, Row, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import DisplayText from '../text/DisplayText';
import OnTraccrButton from '../buttons/OnTraccrButton';

import ContactAddView from '../../contacts/ContactAddView';
import { getRelationSet } from '../../helpers/helpers';

/**
 * Contact selector component:
 *   - gives the user the ability to select an existing contact (customer/vendor)
 *   - gives the user the ability to create a new contact (customer/vendor)
 */
export default function ContactSelector({
  isNotDisplay,
  contact: propContact,
  formRef,
  optionFilter = {}, // optional prop which allows selector options to be filtered
  isVendor,
  formStyle = {},
  formLabelStyle = {},
  zIndex = 1000,
  divisionId,
  shouldFilterByDivision,
  setCustomerId,
}) {
  const { t } = useTranslation();
  const contactKey = isVendor ? 'vendorId' : 'customerId';
  const contactType = isVendor ? 'vendor' : 'customer';

  const form = useRef();
  const projects = useSelector((state) => state.projects.projects);
  const customers = useSelector((state) => state.customers.customers);
  const vendors = useSelector((state) => state.vendors.vendors);

  const [showDrawer, setShowDrawer] = useState(false);
  const [customList, setCustomList] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);

  const projectCustomerRelations = useMemo(() => (
    getRelationSet({
      groupBy: 'id',
      groupVal: 'customerId',
      arr: projects,
    })
  ), [projects]);

  const relevantContactList = useMemo(() => {
    if (isVendor) {
      return Object.values(vendors).filter((v) => (
        v.active
        && (!shouldFilterByDivision || v.divisionIds.some((divId) => divId === divisionId))
      ));
    }

    return Object.values(customers).filter((c) => {
      const { id: cId, active, divisionIds = [] } = c || {};
      const { projectId } = optionFilter || {};

      let ourRelations = new Set();
      if (projectId) ourRelations = projectCustomerRelations[projectId];

      return active
        && (!projectId || ourRelations.has(cId))
        && (!shouldFilterByDivision || divisionIds.some((divId) => divId === divisionId));
    });
  }, [
    isVendor,
    shouldFilterByDivision,
    vendors,
    customers,
    divisionId,
    optionFilter,
    projectCustomerRelations,
  ]);

  // Prepares select options by combining existing contacts with newly created contacts:
  const fullList = useMemo(() => (
    relevantContactList.concat(customList).map(({ name, id }) => ({
      key: id,
      value: id,
      label: name,
    }))
  ), [relevantContactList, customList]);

  const closeDrawer = useCallback(() => {
    setShowDrawer(false);
    setCurrentStep(0);
    form.current.resetFields();
  }, [form]);

  const onChange = useCallback((contactId) => {
    if(formRef && formRef.current) {
      const values = formRef.current.getFieldsValue();
      if(typeof contactId === 'string' && contactId.length === 36) {
        // Selected existing contact
        formRef.current.setFieldsValue({
          ...values,
          [contactKey]: contactId,
          [contactType]: null,
        });
        if(setCustomerId) setCustomerId(contactId);
      } else {
        const ourContact = customList.find((contact) => contact.id === contactId);
        formRef.current.setFieldsValue({
          ...values,
          [contactKey]: contactId,
          [contactType]: ourContact,
        });
        if(setCustomerId) setCustomerId(contactId);
      }
    }
  }, [formRef, contactKey, contactType, customList]);

  const onAddClicked = useCallback(() => setShowDrawer(true),[]);
  const onBack = useCallback(() => setCurrentStep(currentStep - 1),[currentStep]);
  const onCreateContact = useCallback(async () => {
    if(currentStep === 0) {
      await form.current.validateFields();
      return setCurrentStep(1);
    }
    const contact = form.current.getFieldsValue();
    const formValues = formRef.current.getFieldsValue();
    const newId = DateTime.local().toMillis();
    const newContact = {
      ...contact,
      id: newId,
    };
    if(!isVendor && newContact.materialDiscount) newContact.materialDiscount /= 100;
    formRef.current.setFieldsValue({
      ...formValues,
      [contactKey]: newId,
      [contactType]: newContact,
    });
    closeDrawer();
    setCustomList(customList.concat([newContact]));
  },[form, formRef, closeDrawer, contactKey, contactType, customList, currentStep]);

  const dropdownRenderHandler = useCallback((menu) => (
    <div style={{ width: '100%', padding: 5}}>
      {menu}
      <Divider style={{ margin: '4px 0px 8px 0px' }} />
      <Row justify='end'>
        <OnTraccrButton
          icon={ <PlusOutlined />}
          onClick={onAddClicked}
          title='Add'
        />
      </Row>
    </div>
    ), []);

  return (
    <>
      <Form.Item
        name={contactKey}
        label={isVendor ? 'Vendor' : t('Customer')}
        style={{ marginBottom:0, ... formStyle }}
        labelCol={formLabelStyle}
      >
        {isNotDisplay ? (
          <Select
            placeholder={isVendor ? 'Select a Vendor' : `Select a ${t('Customer').toLowerCase()}`}
            showSearch
            allowClear
            optionFilterProp="label"
            onChange={onChange}
            dropdownRender={dropdownRenderHandler}
            options={fullList}
            disabled={!divisionId && shouldFilterByDivision}
          />
        ) : <DisplayText title={propContact} />}
      </Form.Item>
      {/* DONT DELETE BELOW -- it is required to create/set contact using setFieldsValue() */}
      <Form.Item name={contactType} />
      <Drawer
        title={isVendor ? 'Add Vendor' : `Add ${t('Customer')}`}
        visible={showDrawer}
        onClose={closeDrawer}
        width={800}
        zIndex={zIndex}
      >
        <ContactAddView
          isVendor={isVendor}
          form={{
            formRef: form,
            isAdd: true,
            currentStep,
          }}
        />
        <div className='drawer-footer'>
          <Row justify='end' gutter={10}>
            <OnTraccrButton
              title='Cancel'
              type='cancel'
              style={{ marginRight: 8 }}
              onClick={closeDrawer}
            />
            {
              currentStep > 0 &&
              <OnTraccrButton
                title='Back'
                type='back'
                style={{ marginRight: 8 }}
                onClick={onBack}
              />
            }
            <OnTraccrButton
              title={currentStep === 0 ? 'Next' : 'Submit'}
              onClick={onCreateContact}
            />
          </Row>
        </div>
      </Drawer>
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
ContactSelector.propTypes = {
  isNotDisplay: PropTypes.bool.isRequired,
  contact: PropTypes.string.isRequired,
  formRef: PropTypes.object.isRequired,
  optionFilter: PropTypes.object,
  isVendor: PropTypes.bool.isRequired,
  formStyle: PropTypes.object,
  formLabelStyle: PropTypes.object,
  zIndex: PropTypes.number,
  divisionId: PropTypes.string,
  shouldFilterByDivision: PropTypes.bool,
  setCustomerId: PropTypes.func,
};

ContactSelector.defaultProps = {
  zIndex: 1000,
  optionFilter: {},
  formStyle: {},
  formLabelStyle: {},
  divisionId: null,
  shouldFilterByDivision: false,
  setCustomerId: null,
};
