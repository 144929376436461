export default {};

export const currencyFormatter = (value) => {
  let ourValue = value ?? 0;
  if (typeof value === 'number') {
    ourValue = ourValue.toFixed(2);
  }
  return `$ ${ourValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace((/(?<=\.\d{2}).*/g), '');
};

export const currencyParser = (value) => value.replace(/\$\s?|(,*)/g, '');

export const percentFormatter = (value) => `${value} %`;
