import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  Divider,
  Table,
  Popover,
  Tabs,
} from 'antd';
import PropTypes from 'prop-types';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';

import WorkflowConfigureSourceSelector from './selectors/WorkflowConfigureSourceSelector';

import { getSourceFieldMap, getType } from './workflowHelpers';

import { getTemplateDetails } from '../state/forms.actions';
import { createTargetToSourceMap } from '../formHelpers';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import sortByString, { calculateStringSimilarity } from '../../helpers/helpers';

const { TabPane } = Tabs;

export default function WorkFlowTriggerConfigureDrawer({
  visible,
  onClose,
  onSubmit,
  targetFormId,
  sourceName,
  sourceSections = [],
  fieldMappings = {},
  onFieldMappingsChange,
  divisionId,
  templateId,
  parentLogicalLoopNode = null,
  zIndex = 1000,
  isFieldValid = () => true,
  canMapShiftToTime,
}) {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.forms.templates);
  const customTables = useSelector((state) => state.forms.customTables);
  const [showAllTemplates, setShowAllTemplates] = useState(false);
  const [selectedForm, setSelectedForm] = useState();

  const divTemplates = useMemo(() => {
    const templateArr = Object.values(templates)
      .filter((template) => template.id !== templateId
        && (template.divisionId === divisionId)
        && template.active
        && template.notDeleted);
    templateArr.sort(sortByString('name'));
    return templateArr;
  }, [
    templates,
    divisionId,
    templateId,
  ]);

  useEffect(() => {
    if (visible && !selectedForm && targetFormId?.startsWith('field-')) {
      setShowAllTemplates(true);
      setSelectedForm(divTemplates[0]?.id);
    }
  }, [visible, selectedForm, targetFormId, divTemplates]);

  useEffect(() => {
    if (visible && targetFormId && !targetFormId?.startsWith('field-')) {
      setShowAllTemplates(false);
      setSelectedForm(targetFormId);
    }
  }, [visible, targetFormId]);

  useEffect(() => {
    if (selectedForm && selectedForm !== templateId) {
      dispatch(getTemplateDetails(selectedForm));
    }
  }, [selectedForm, templateId]);

  const ourTemplate = useMemo(() => {
    const {
      [selectedForm]: targetForm = {},
    } = templates;

    const newForm = {
      ...targetForm,
    };

    if (!newForm.formData && newForm.jsonFormData) {
      try {
        newForm.formData = JSON.parse(newForm.jsonFormData);
      } catch (e) {
        // fail silently
      }
    }
    return newForm;
  }, [templates, selectedForm]);

  const {
    formData: {
      name: targetName,
      sections: targetSections = [],
    } = {},
  } = ourTemplate;

  const {
    flattenedTargetFields,
    doesFormDropdownExist,
  } = useMemo(() => {
    const fields = [];
    let hasFormDropdownField = false;

    targetSections.forEach((section) => {
      const { fields: sectionFields = [], name: sectionName } = section;
      sectionFields.forEach((field) => {
        const {
          configProps: {
            title: fieldTitle,
            dataType,
          } = {},
          selectedType,
        } = field;

        if (isFieldValid(field, customTables)) {
          if (selectedType === 'dropdown' && dataType === 'CompletedForms') {
            hasFormDropdownField = true;
          }

          fields.push({
            ...field,
            title: `${sectionName} - ${fieldTitle}`,
            fieldTitle,
          });
        }
      });
    });
    return {
      flattenedTargetFields: fields,
      doesFormDropdownExist: hasFormDropdownField,
    };
  }, [targetSections, isFieldValid, customTables]);

  const sourceFieldMap = useMemo(() => (
    getSourceFieldMap(sourceSections, parentLogicalLoopNode, doesFormDropdownExist)
  ), [sourceSections, parentLogicalLoopNode, doesFormDropdownExist]);

  const columns = useMemo(() => (
    [
      {
        dataIndex: 'sourceField',
        title: 'Source Field',
        width: 425,
        render: (_, record) => {
          const {
            id: targetId,
          } = record;
          const type = getType(record);
          const {
            [type]: eligibleSourceFields = [],
          } = sourceFieldMap;

          let allFields = eligibleSourceFields;

          if (type === 'table - TimeEntry' && canMapShiftToTime) {
            allFields = allFields.concat(
              sourceFieldMap['table - Shifts'] ?? [],
            );
          }

          const relevantFieldMappings = fieldMappings?.version === 'v2'
            ? createTargetToSourceMap(fieldMappings[selectedForm])
            : fieldMappings;

          const {
            [targetId]: defaultValue,
          } = relevantFieldMappings;

          const eligibleSourceFieldIds = new Set(allFields.map(({ id }) => id));

          // Remove the field mapping if the default value is no longer eligible
          if (defaultValue && !eligibleSourceFieldIds.has(defaultValue)) {
            const fieldMappings = { ...relevantFieldMappings };
            delete fieldMappings[targetId];
            onFieldMappingsChange(fieldMappings, selectedForm);
          }

          return (
            <WorkflowConfigureSourceSelector
              fieldMappings={relevantFieldMappings}
              defaultValue={defaultValue}
              onFieldMappingsChange={onFieldMappingsChange}
              targetId={targetId}
              eligibleSourceFields={allFields}
              formId={selectedForm}
              value={defaultValue}
            />
          );
        },
      }, {
        dataIndex: 'title',
        title: 'Target Field',
      }]
  ), [
    sourceFieldMap,
    fieldMappings,
    selectedForm,
    onFieldMappingsChange,
    canMapShiftToTime,
  ]);

  const autoMap = useCallback(() => {
    const targetFieldMap = {};

    flattenedTargetFields.forEach((field) => {
      const {
        id: targetId,
        fieldTitle,
      } = field;

      const type = getType(field);
      const {
        [type]: eligibleSourceFields = [],
      } = sourceFieldMap;

      const relevantFieldMappings = fieldMappings?.version === 'v2'
        ? createTargetToSourceMap(fieldMappings[selectedForm])
        : fieldMappings;

      const {
        [targetId]: defaultValue,
      } = relevantFieldMappings;

      // If a user has already selected a value, do not override
      if (defaultValue) {
        targetFieldMap[targetId] = {
          sourceId: defaultValue,
        };
        return;
      }

      eligibleSourceFields.forEach((sourceField) => {
        const {
          id: sourceId,
          fieldTitle: sourceTitle,
        } = sourceField;

        const stringSimilarity = calculateStringSimilarity(fieldTitle, sourceTitle);
        if (targetFieldMap[targetId]?.stringSimilarity > stringSimilarity) {
          return;
        }

        targetFieldMap[targetId] = {
          sourceId,
          stringSimilarity,
        };
      });
    });

    Object.keys(targetFieldMap).forEach((targetId) => {
      if (targetFieldMap[targetId].stringSimilarity < 0.3) {
        delete targetFieldMap[targetId];
        return;
      }

      targetFieldMap[targetId] = targetFieldMap[targetId].sourceId;
    });

    const newData = {
      ...fieldMappings,
      ...targetFieldMap,
    };

    onFieldMappingsChange(newData, selectedForm);
  }, [
    flattenedTargetFields,
    sourceFieldMap,
    selectedForm,
    fieldMappings,
    onFieldMappingsChange,
  ]);

  return (
    <Drawer
      title="Configure Field Mapping"
      width={1000}
      onClose={onClose}
      visible={visible}
      zIndex={zIndex}
    >
      Configure which fields from this <b>{sourceName}</b> form are loaded into the fields for the <b>{showAllTemplates ? 'selected' : targetName}</b> form
      <Popover
        placement="left"
        content={(
          <div style={{ maxWidth: 200 }}>
            Auto map will attempt to map fields with similar names or type for the selected form.
            If no fields are mapped, you will need to manually map the fields.
          </div>
        )}
      >
        <OnTraccrButton
          title="Auto Map"
          onClick={autoMap}
          style={{ float: 'right' }}
        />
      </Popover>
      <Divider style={{ marginBottom: 0 }} />
      { showAllTemplates && (
        <Tabs
          tabPosition="left"
          activeTab={selectedForm}
          onChange={setSelectedForm}
          destroyInactiveTabPane
          style={{ height: '100%' }}
        >
          {divTemplates.map((template) => (
            <TabPane
              tab={(
                <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {template.name}
                </div>
              )}
              key={template.id}
            >
              <Table
                dataSource={flattenedTargetFields}
                columns={columns}
                pagination={false}
                size="small"
                scroll={{ y: 'calc(100vh - 218px)' }}
                rowKey="id"
              />
            </TabPane>
          ))}
        </Tabs>
      )}
      { !showAllTemplates && (
        <Table
          dataSource={flattenedTargetFields}
          columns={columns}
          pagination={false}
          size="small"
          scroll={{ y: 'calc(100vh - 218px)' }}
          rowKey="id"
        />
      )}
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
};

WorkFlowTriggerConfigureDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  targetFormId: PropTypes.string.isRequired,
  sourceName: PropTypes.string.isRequired,
  sourceSections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      configProps: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    })).isRequired,
  })).isRequired,
  fieldMappings: PropTypes.shape({
    version: PropTypes.string,
  }).isRequired,
  onFieldMappingsChange: PropTypes.func.isRequired,
  divisionId: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  parentLogicalLoopNode: PropTypes.string,
  zIndex: PropTypes.number,
  isFieldValid: PropTypes.func,
  canMapShiftToTime: PropTypes.bool,
};

WorkFlowTriggerConfigureDrawer.defaultProps = {
  parentLogicalLoopNode: null,
  zIndex: 1000,
  isFieldValid: () => true,
  canMapShiftToTime: false,
};
