import React from 'react';
import { Popover, Row, Select } from 'antd';
import PropTypes from 'prop-types';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

export default function ScheduleTeamSelector({
  isDisplay,
  value,
  onChange,
  teamMap,
  getFromMap,
  getFormView,
  basicOptions,
  activeTeams,
  onTeamClick,
}) {
  return (
    <>
      {!!value?.length && !isDisplay && (
        <Popover
          trigger="click"
          placement="topRight"
          content={(
            value?.map((teamId) => (
              <Row key={teamId} style={{ marginBottom: 10, display: 'block' }}>
                <span style={{ fontWeight: 650, paddingRight: 15 }}>
                  {getFromMap({ id: teamId, idMap: teamMap })}
                </span>
                <OnTraccrButton title="Add All Members" onClick={() => onTeamClick(teamId, true)} style={{ marginRight: 5 }} />
                <OnTraccrButton title="Remove All Members" onClick={() => onTeamClick(teamId)} type="cancel" />
              </Row>
            ))
          )}
        >
          <OnTraccrButton title="View Teams" type="primary" />
        </Popover>
      )}
      {getFormView({
        isDisplay,
        value: getFromMap({ ids: value ?? [], idMap: teamMap }),
        input: (
          <Select
            placeholder="Select a Team"
            optionLabelProp="label"
            optionFilterProp="label"
            showSearch
            onChange={onChange}
            allowClear
            mode="multiple"
            style={{ paddingTop: 10 }}
            value={value}
          >
            {basicOptions(activeTeams)}
          </Select>
        ),
      })}
    </>
  );
}

ScheduleTeamSelector.propTypes = {
  isDisplay: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  teamMap: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({}),
  }),
  getFromMap: PropTypes.func.isRequired,
  getFormView: PropTypes.func.isRequired,
  basicOptions: PropTypes.func.isRequired,
  activeTeams: PropTypes.arrayOf(PropTypes.shape({})),
  onTeamClick: PropTypes.func.isRequired,
};

ScheduleTeamSelector.defaultProps = {
  isDisplay: false,
  value: [],
  teamMap: {},
  activeTeams: [],
};
