import { Table } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import sortByString, { getIdMap } from '../helpers/helpers';
import FilteredUserSelector from '../common/inputs/FilteredUserSelector';
import useDivisionUsers from '../common/hooks/useDivisionUsers';

function UserAssignmentTable({
  selectedUsers = [],
  onUsersChanged,
  scroll,
}) {
  const positionNames = useSelector((state) => state.settings.positionNames);
  const users = useSelector((state) => state.users.users);
  const userMap = useMemo(() => getIdMap(users), [users]);

  const fullSelectedUsers = useMemo(() => (
    selectedUsers.map((id) => userMap[id])
  ), [selectedUsers, userMap]);
  const selectedUserMap = useMemo(() => getIdMap(fullSelectedUsers), [fullSelectedUsers]);

  const fullUserOptions = useDivisionUsers();

  const userOptions = useMemo(() => (
    fullUserOptions.filter(({ id }) => !selectedUserMap[id])
  ), [fullUserOptions, selectedUserMap]);

  const onSelect = useCallback(([selectedKeys]) => {
    onUsersChanged(selectedKeys);
  }, [onUsersChanged]);

  const onAddUser = useCallback(([newUserId]) => {
    if (newUserId && !selectedUsers.includes(newUserId)) {
      onUsersChanged([
        ...selectedUsers,
        newUserId,
      ]);
    }
  }, [selectedUsers, onUsersChanged]);

  const cols = useMemo(() => [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: sortByString('name'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      width: 125,
      filters: positionNames.map(({ name }) => ({
        text: name,
        value: name,
      })),
      onFilter: (position, user) => user.position === position,
      sorter: sortByString('position'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
  ], [positionNames]);

  return (
    <>
      <FilteredUserSelector
        users={userOptions}
        onChange={onAddUser}
        value={[]}
      />
      <div className="file-list-container" style={{ marginTop: 15 }}>
        <Table
          size="small"
          pagination={false}
          columns={cols}
          dataSource={fullSelectedUsers}
          style={{ width: '100%', height: '100%' }}
          scroll={scroll}
          rowSelection={{
            fixed: true,
            onChange: (...args) => onSelect(args),
            selectedRowKeys: selectedUsers,
            preserveSelectedRowKeys: true,
          }}
          rowKey="id"
        />
      </div>
    </>
  )
}

export default UserAssignmentTable;
