import {
  GET_SCHEDULE,
  CREATE_SHIFT,
  UPDATE_SHIFT,
  APPLY_SHIFT,
  DELETE_SHIFT,
  SET_SCHEDULE_SCALE,
  HOVER_MONTHLY_SCHEDULE_ENTRY,
  VIEW_SHIFT,
  GET_SHIFT_EVENT_FORM_DATA,
  RESET_GANTT_STATE,
  GET_GANTT_SCHEDULES,
  CREATE_GANTT_SCHEDULE,
  UPDATE_GANTT_SCHEDULE,
  DELETE_GANTT_SCHEDULE,
  GET_GANTT_SCHEDULE_ROWS,
  ADD_GANTT_SCHEDULE_ROW,
  UPDATE_GANTT_SCHEDULE_ROW,
  DELETE_GANTT_SCHEDULE_ROW,
  GET_GANTT_SCHEDULE_SETTINGS,
  UPDATE_GANTT_SCHEDULE_SETTINGS,
  GET_GANTT_SCHEDULE_HOLIDAYS,
  UPDATE_GANTT_SCHEDULE_HOLIDAYS,
  ADD_GANTT_SCHEDULE_HOLIDAYS,
  DELETE_GANTT_SCHEDULE_HOLIDAYS,
  IMPORT_GANTT_SCHEDULE,
  GET_GANTT_SCHEDULE_LABELS,
  UPDATE_GANTT_SCHEDULE_FILTERS,
  GET_USER_GANTT_SCHEDULE_FILTER_VIEWS,
  CREATE_USER_GANTT_SCHEDULE_FILTER_VIEW,
  UPDATE_USER_GANTT_SCHEDULE_FILTER_VIEW,
  DELETE_USER_GANTT_SCHEDULE_FILTER_VIEW,
  GET_SCHEDULE_GROUP,
  SET_BIWEEKLY_MODAL_CONFIG,
  UPDATE_SHIFT_AFTER_DRAG,
  GET_USER_FIELD_SCHEDULE_FILTER_VIEWS,
  UPDATE_FIELD_SCHEDULE_FILTERS,
  CREATE_USER_FIELD_SCHEDULE_FILTER_VIEW,
  UPDATE_USER_FIELD_SCHEDULE_FILTER_VIEW,
  DELETE_USER_FIELD_SCHEDULE_FILTER_VIEW,
  UPLOAD_SHIFTS,
} from '../../state/actionTypes';

import {
  addShift,
  addUsersToMap,
  decorateShift,
  deleteShift,
} from '../scheduleHelpers';
import {
  parseSettings,
  convertToClientModel,
  sortHolidays,
} from '../GanttSchedule/ganttScheduleHelpers';
import { INITIAL_SCALE } from '../schedule.constants';

import { compareStringArrays } from '../../helpers/helpers';

const initialGanttState = {
  ganttScheduleRows: [],
  ganttScheduleSettings: {},
  ganttScheduleHolidays: [],
  ganttScheduleLabels: [],
  ganttScheduleFilters: {},
  ganttScheduleFilterViews: [],
};

const initialFieldState = {
  fieldScheduleFilters: {},
  fieldScheduleFilterViews: [],
};

const initialState = {
  shifts: {},
  shiftMap: {},
  groupShifts: [],
  scale: INITIAL_SCALE,
  hoverShift: {},
  viewingShift: false,
  shiftUsers: {}, // { [userId]: [shiftId0, shiftId1] };
  eventFormData: {}, // For events with prefilled data
  ganttSchedules: [],
  biweeklyModalConfig: {},
  ...initialGanttState,
  ...initialFieldState,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEDULE: {
      const {
        payload: {
          schedule = {},
        } = {},
      } = action;
      let shifts = {};
      const idMap = {};
      let userMap = {};
      Object.values(schedule).forEach((shift) => {
        shifts = addShift(shifts, shift);
        idMap[shift.id] = shift;
        userMap = addUsersToMap(userMap, shift);
      });
      return {
        ...state,
        shifts,
        shiftMap: idMap,
        shiftUsers: userMap,
      };
    }
    case UPLOAD_SHIFTS:
    case CREATE_SHIFT: {
      const {
        payload: {
          shifts: newShifts = [],
        } = {},
      } = action;
      const {
        shifts = {},
        shiftMap = {},
        shiftUsers = {},
        ganttScheduleRows = [],
      } = state;
      let newShiftState = shifts;
      const newShiftMap = { ...shiftMap };
      let newUserMap = { ...shiftUsers };

      const ganttScheduleConnectionMap = {};

      newShifts.forEach((shift) => {
        if (shift.id in shiftMap) return;
        newShiftMap[shift.id] = shift;
        newShiftState = addShift(newShiftState, shift);
        newUserMap = addUsersToMap(newUserMap, shift);

        const {
          ganttScheduleRowId,
          id,
        } = shift;

        if (ganttScheduleRowId) {
          if (!ganttScheduleConnectionMap[ganttScheduleRowId]) {
            ganttScheduleConnectionMap[ganttScheduleRowId] = [];
          }
          ganttScheduleConnectionMap[ganttScheduleRowId].push(id);
        }
      });

      let newGanttScheduleRows = ganttScheduleRows;

      if (Object.keys(ganttScheduleConnectionMap).length) {
        newGanttScheduleRows = ganttScheduleRows.map((row) => {
          const { id: rowId } = row;
          const shiftConnections = ganttScheduleConnectionMap[rowId] ?? [];
          return {
            ...row,
            shiftConnections,
          };
        });
      }

      return {
        ...state,
        shifts: newShiftState,
        shiftMap: newShiftMap,
        shiftUsers: newUserMap,
        ganttScheduleRows: newGanttScheduleRows,
      };
    }
    case UPDATE_SHIFT_AFTER_DRAG:
    case UPDATE_SHIFT: {
      const {
        payload: {
          shifts: updatedShifts = [],
        } = {},
      } = action;
      const {
        shifts = {},
        shiftMap = {},
        shiftUsers = {},
      } = state;
      let newShifts = shifts;
      const newShiftMap = { ...shiftMap };
      const newUserMap = { ...shiftUsers };
      updatedShifts.forEach((shift) => {
        const {
          [shift.id]: oldShift = {},
        } = shiftMap;
        const updateShift = decorateShift(shift);
        const fullNewShift = {
          ...oldShift,
          ...updateShift,
        };

        if (oldShift.id) newShifts = deleteShift(newShifts, oldShift);
        newShifts = addShift(newShifts, fullNewShift);
        newShiftMap[shift.id] = fullNewShift;
      });

      // User changes will be uniform across all shifts;
      if (updatedShifts.length > 0) {
        const shiftIds = updatedShifts.map((shift) => shift.id);
        const shiftSet = new Set(shiftIds);
        const [firstUpdateShift = {}] = updatedShifts;
        const {
          [firstUpdateShift.id]: oldShift = {},
        } = shiftMap;
        const {
          removed: deletedUsers = [],
          added: addedUsers = [],
        } = compareStringArrays(oldShift.users, firstUpdateShift.users);
        if (deletedUsers.length > 0) {
          deletedUsers.forEach((userId) => {
            newUserMap[userId] = newUserMap[userId].filter((shiftId) => !shiftSet.has(shiftId));
          });
        }
        if (addedUsers.length > 0) {
          addedUsers.forEach((userId) => {
            const {
              [userId]: oldUserShiftIds = [],
            } = newUserMap;
            newUserMap[userId] = oldUserShiftIds.concat(shiftIds);
          });
        }
      }
      return {
        ...state,
        shifts: newShifts,
        shiftMap: newShiftMap,
        shiftUsers: newUserMap,
      };
    }
    case APPLY_SHIFT: {
      const {
        payload: { appliedUser },
      } = action;
      const {
        shifts = {},
        shiftMap = {},
      } = state;

      const newShiftMap = { ...shiftMap };
      let newShifts = shifts;

      const updatedAppliedUser = { userId: appliedUser.userId, status: appliedUser.status };

      const oldShift = newShiftMap[appliedUser.shiftId];

      const updatedShift = {
        ...oldShift,
        appliedUsers: oldShift?.appliedUsers ? [...oldShift.appliedUsers] : [],
      };

      const existingAppliedUserIndex = updatedShift.appliedUsers.findIndex(
        (user) => user.userId === appliedUser.userId,
      );

      if (existingAppliedUserIndex !== -1) {
        updatedShift.appliedUsers[existingAppliedUserIndex] = updatedAppliedUser;
      } else {
        updatedShift.appliedUsers.push(updatedAppliedUser);
      }

      updatedShift.applicationStatus = appliedUser.status;

      newShiftMap[appliedUser.shiftId] = updatedShift;

      if (oldShift) {
        newShifts = deleteShift(newShifts, oldShift);
      }
      newShifts = addShift(newShifts, updatedShift);

      return {
        ...state,
        shifts: newShifts,
        shiftMap: newShiftMap,
      };
    }

    case DELETE_SHIFT: {
      const {
        payload: {
          shiftIds = [],
        } = {},
      } = action;

      const {
        shifts = {},
        shiftMap = {},
        shiftUsers = {},
      } = state;
      const newShiftUsers = { ...shiftUsers };
      const deleteShifts = shiftIds.map((shiftId) => shiftMap[shiftId]).filter((shift) => shift);
      const newShiftMap = { ...shiftMap };
      let newStateShifts = shifts;
      const deletedShiftSet = new Set();
      deleteShifts.forEach(({ id: shiftId }) => {
        const oldShift = newShiftMap[shiftId];
        delete newShiftMap[shiftId];
        if (!oldShift) return;
        newStateShifts = deleteShift(newStateShifts, oldShift);
        deletedShiftSet.add(shiftId);
      });
      Object.keys(newShiftUsers).forEach((userId) => {
        newShiftUsers[userId] = newShiftUsers[userId]
          .filter((shiftId) => !deletedShiftSet.has(shiftId));
      });
      return {
        ...state,
        shifts: newStateShifts,
        shiftMap: newShiftMap,
        shiftUsers: newShiftUsers,
      };
    }
    case SET_SCHEDULE_SCALE: {
      const {
        payload: {
          scale,
        } = {},
      } = action;
      return {
        ...state,
        scale,
      };
    }
    case HOVER_MONTHLY_SCHEDULE_ENTRY: {
      const {
        payload: {
          shift: hoverShift,
        } = {},
      } = action;
      return {
        ...state,
        hoverShift,
      };
    }
    case VIEW_SHIFT: {
      const {
        payload: {
          visible,
        } = {},
      } = action;
      return {
        ...state,
        viewingShift: visible,
      };
    }
    case SET_BIWEEKLY_MODAL_CONFIG: {
      const {
        payload: {
          config,
        } = {},
      } = action;
      return {
        ...state,
        biweeklyModalConfig: config,
      };
    }
    case GET_SHIFT_EVENT_FORM_DATA: {
      const {
        payload: {
          formTemplate: {
            data,
            fileMap,
          } = {},
        } = {},
      } = action;
      return {
        ...state,
        eventFormData: {
          data,
          fileMap,
        },
      };
    }
    case RESET_GANTT_STATE:
      return {
        ...state,
        ...initialGanttState,
      };
    case GET_GANTT_SCHEDULES:
    case CREATE_GANTT_SCHEDULE:
    case DELETE_GANTT_SCHEDULE:
      return {
        ...state,
        ganttSchedules: action.payload.ganttSchedules,
      };
    case UPDATE_GANTT_SCHEDULE:
      return {
        ...state,
        ganttSchedules: action.payload.ganttSchedules,
        ganttScheduleRows: convertToClientModel(action.payload.ganttScheduleRows),
      };
    case GET_GANTT_SCHEDULE_ROWS:
      return {
        ...state,
        ganttScheduleRows: convertToClientModel(action.payload.ganttScheduleRows),
      };
    case ADD_GANTT_SCHEDULE_ROW:
    case UPDATE_GANTT_SCHEDULE_ROW:
    case DELETE_GANTT_SCHEDULE_ROW: {
      const existingRowSet = new Set(action.payload.ganttScheduleRows.map((row) => row.id));
      const newRows = [
        ...convertToClientModel(action.payload.ganttScheduleRows),
        ...state.ganttScheduleRows.filter((row) => (
          !existingRowSet.has(row.id) && row.id !== action.payload.deleteId
        )),
      ].sort((a, b) => a.startDate - b.startDate);

      return {
        ...state,
        ganttScheduleRows: newRows,
      };
    }
    case GET_GANTT_SCHEDULE_HOLIDAYS:
      return {
        ...state,
        ganttScheduleHolidays: sortHolidays(action.payload.ganttScheduleHolidays),
      };
    case ADD_GANTT_SCHEDULE_HOLIDAYS:
    case UPDATE_GANTT_SCHEDULE_HOLIDAYS:
    case DELETE_GANTT_SCHEDULE_HOLIDAYS:
      return {
        ...state,
        ganttScheduleRows: convertToClientModel(action.payload.ganttScheduleRows),
        ganttScheduleHolidays: sortHolidays(action.payload.ganttScheduleHolidays),
      };
    case GET_GANTT_SCHEDULE_SETTINGS:
      return {
        ...state,
        ganttScheduleSettings: parseSettings(action.payload.ganttScheduleSettings[0]),
      };
    case UPDATE_GANTT_SCHEDULE_SETTINGS:
      return {
        ...state,
        ganttScheduleSettings: parseSettings(action.payload.ganttScheduleSettings),
        ganttScheduleRows: convertToClientModel(action.payload.ganttScheduleRows),
      };
    case IMPORT_GANTT_SCHEDULE:
      return {
        ...state,
        ganttScheduleRows: convertToClientModel(action.payload.ganttScheduleRows),
        ganttScheduleHolidays: sortHolidays(action.payload.ganttScheduleHolidays),
        ganttScheduleSettings: parseSettings(action.payload.ganttScheduleSettings),
      };
    case GET_GANTT_SCHEDULE_LABELS:
      return {
        ...state,
        ganttScheduleLabels: action.payload.ganttScheduleLabels,
      };
    case UPDATE_GANTT_SCHEDULE_FILTERS:
      return {
        ...state,
        ganttScheduleFilters: !action.payload.ganttScheduleFilters
          ? {}
          : {
            ...state.ganttScheduleFilters,
            ...action.payload.ganttScheduleFilters,
          },
      };
    case GET_USER_GANTT_SCHEDULE_FILTER_VIEWS:
    case CREATE_USER_GANTT_SCHEDULE_FILTER_VIEW:
    case UPDATE_USER_GANTT_SCHEDULE_FILTER_VIEW:
    case DELETE_USER_GANTT_SCHEDULE_FILTER_VIEW:
      return {
        ...state,
        ganttScheduleFilterViews: action.payload.ganttScheduleFilterViews,
      };
    case GET_SCHEDULE_GROUP:
      return {
        ...state,
        groupShifts: action.payload.shifts,
      };
    case UPDATE_FIELD_SCHEDULE_FILTERS:
      return {
        ...state,
        fieldScheduleFilters: !action.payload.fieldScheduleFilters
          ? {}
          : {
            ...state.fieldScheduleFilters,
            ...action.payload.fieldScheduleFilters,
          },
      };
    case GET_USER_FIELD_SCHEDULE_FILTER_VIEWS:
    case CREATE_USER_FIELD_SCHEDULE_FILTER_VIEW:
    case UPDATE_USER_FIELD_SCHEDULE_FILTER_VIEW:
    case DELETE_USER_FIELD_SCHEDULE_FILTER_VIEW:
      return {
        ...state,
        fieldScheduleFilterViews: action.payload.fieldScheduleFilterViews,
      };
    default:
      return state;
  }
};
