import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, Timeline } from 'antd';
import {
  ClockCircleOutlined,
  StopOutlined,
  ScheduleOutlined,
  BellOutlined,
  FormOutlined,
  FileAddOutlined,
  DiffOutlined,
  FileExcelOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import FullPhoto from '../common/photos/FullPhoto';
import PhotoReel from '../common/photos/PhotoReel';
import TimelineStepText from '../common/containers/TimelineStepText';
import FormDetailView from '../forms/CompletedForms/FormDetailView';

import { getIdMap } from '../helpers/helpers';
import { getFileType, downloadFile } from '../files/fileHelpers';

import { getEquipmentHistory } from './state/equipment.actions';
import { getEquipmentUpdateText } from './equipmentHistoryHelpers';

import { NONE_EQUIPMENT_TYPE } from './equipmentFilters';
import { getFormById } from '../forms/state/forms.actions';

const bold = (text) => (
  <span style={{ fontFamily: 'roboto-regular' }}>
    {text}
    {' '}
  </span>
);

export default function EquipmentHistoryList({
  id,
  projectId,
  style = {},
}) {
  const dispatch = useDispatch();
  const {
    equipment = [],
    equipmentHistory = {},
    fileMap = {},
    statuses,
    equipmentTypes,
  } = useSelector((state) => state.equipment);
  const users = useSelector((state) => state.users.users);
  const projects = useSelector((state) => state.projects.projects);
  const divisions = useSelector((state) => state.settings.divisions);
  const labels = useSelector((state) => state.labels);
  const equipmentLabels = useMemo(() => labels.filter((label) => label.type === 'equipment'), [labels]);

  const [selectedFile, setSelectedFile] = useState();
  const [showForm, setShowForm] = useState(false);

  const onFormClick = useCallback((formId) => {
    if (!formId) return;
    dispatch(getFormById(formId));
    setShowForm(true);
  }, []);

  const onClearSelectedFile = useCallback(() => setSelectedFile(), []);
  const onDownload = useCallback(() => {
    if (!selectedFile) return;
    const { path, name } = selectedFile;
    const fullPath = path ? `${path}/$` : name;
    downloadFile({
      fileDetails: { name, fullPath },
    });
  }, [selectedFile]);

  useEffect(() => {
    if (id) dispatch(getEquipmentHistory(id));
  }, [dispatch, id]);

  const equipmentTypesWithNone = useMemo(() => [
    { id: NONE_EQUIPMENT_TYPE, name: 'None' },
    ...equipmentTypes.filter((eq) => !eq.isDefault),
  ], [equipmentTypes]);

  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const userMap = useMemo(() => getIdMap(users), [users]);
  const equipmentMap = useMemo(() => getIdMap(equipment), [equipment]);
  const equipmentTypesMap = useMemo(() => getIdMap(equipmentTypesWithNone), [equipmentTypes]);
  const labelMap = useMemo(() => getIdMap(equipmentLabels), [equipmentLabels]);
  const ourHistory = useMemo(() => {
    const {
      [id]: historyList = [],
    } = equipmentHistory;

    const now = DateTime.local().toMillis();
    return historyList
      .filter((e) => !projectId || e.projectId === projectId)
      .reverse()
      .map((entry) => {
        const {
          id: entryId,
          timestamp,
          userId,
          type,
          equipmentId,
          projectId,
          metadata,
        } = entry;
        const {
          title,
          fileIds = [],
          location,
          formWorkflow,
          formName,
          formId,
        } = metadata ?? {};
        const {
          [equipmentId]: { name: equipmentName = 'this equipment' } = {},
        } = equipmentMap;
        const {
          [projectId]: { name: projectName } = {},
        } = projectMap;
        const {
          [userId]: { name: userName } = {},
        } = userMap;

        const defaultedUser = userName ?? 'Someone';

        const locationText = projectName ?? location;

        let text = '';
        let format;
        let icon;
        let extra;
        switch (type) {
          case 'Check In': {
            if (formWorkflow === 'updateEquipment') {
              const suffix = locationText ? (
                <span>
                  to
                  {' '}
                  {bold(locationText)}
                </span>
              ) : '';
              text = (
                <span>
                  {bold(defaultedUser)} submitted the
                  {' '}
                  <button
                    type="button"
                    onClick={() => onFormClick(formId)}
                    style={{
                      background: 'none',
                      border: 'none',
                      padding: 0,
                      margin: 0,
                      color: 'blue',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    {bold(formName)}
                  </button>

                  {' '}
                  form which triggered an update equipment workflow step that checked in
                  {' '}
                  {bold(equipmentName)}
                  {' '}
                  {suffix}
                </span>
              );
              icon = (
                <ThunderboltOutlined style={{ fontSize: 20 }} />
              );
            } else {
              const suffix = locationText ? (
                <span>
                  to
                  {' '}
                  {bold(locationText)}
                </span>
              ) : '';
              text = (
                <span>
                  {bold(defaultedUser)}
                  {' '}
                  checked in
                  {' '}
                  {bold(equipmentName)}
                  {' '}
                  {suffix}
                </span>
              );
              icon = <ClockCircleOutlined style={{ color: 'green' }} />;
            }
            break;
          }
          case 'Check Out': {
            const suffix = locationText ? (
              <span>
                from
                {' '}
                {bold(locationText)}
              </span>
            ) : '';
            text = (
              <span>
                {bold(defaultedUser)}
                {' '}
                checked out
                {' '}
                {bold(equipmentName)}
                {' '}
                {suffix}
              </span>
            );
            icon = <StopOutlined style={{ color: 'red' }} />;
            break;
          }
          case 'Task Created': {
            const suffix = title ? (
              <span>
                a
                {' '}
                {bold(title)}
                {' '}
                task
              </span>
            ) : 'a task';
            text = (
              <span>
                {bold(defaultedUser)}
                {' '}
                created
                {' '}
                {suffix}
              </span>
            );
            icon = <ScheduleOutlined style={{ color: 'black' }} />;
            break;
          }
          case 'Task Due': {
            if (userName) {
              const suffix = title ? (
                <span>
                  a
                  {' '}
                  {bold(title)}
                  {' '}
                  task
                </span>
              ) : 'a task';
              text = (
                <span style={timestamp < now ? { color: 'red' } : {}}>
                  {bold(userName)}
                  {' '}
                  has
                  {' '}
                  {suffix}
                  {' '}
                  due
                </span>
              );
            } else {
              const prefix = title ? (
                <span>
                  A
                  {bold(title)}
                  {' '}
                  task
                </span>
              ) : 'A task';
              text = (
                <span style={timestamp < now ? { color: 'red' } : {}}>
                  {prefix}
                  {' '}
                  is due
                </span>
              );
            }

            format = DateTime.DATE_MED;
            icon = <BellOutlined style={{ color: timestamp < now ? 'red' : 'black' }} />;
            break;
          }
          case 'Form Submitted': {
            const suffix = title ? (
              <span>
                a
                {' '}
                {bold(title)}
                {' '}
                form
              </span>
            ) : 'a form';
            text = (
              <span>
                {bold(defaultedUser)}
                {' '}
                has submitted
                {' '}
                {suffix}
              </span>
            );
            icon = <FormOutlined style={{ color: 'black' }} />;
            break;
          }
          case 'File Upload': {
            const files = fileIds.map((fileId) => fileMap[fileId]).filter((file) => file);
            const suffix = files.length === 1 ? '' : 's';
            text = (
              <span>
                {bold(defaultedUser)}
                {' '}
                uploaded
                {' '}
                {bold(files.length)}
                {' '}
                file
                {suffix}
              </span>
            );
            icon = <FileAddOutlined style={{ color: 'black' }} />;
            extra = (
              <PhotoReel
                small
                photos={files}
                onClick={(idx) => setSelectedFile(files[idx])}
              />
            );
            break;
          }
          case 'File Removed': {
            const files = fileIds.map((fileId) => fileMap[fileId]).filter((file) => file);
            const suffix = files.length === 1 ? '' : 's';
            const fileNames = files.map((file) => (
              <>
                {`- ${file.name}`}
                <br />
              </>
            ));
            text = (
              <>
                <span>
                  {bold(defaultedUser)}
                  {' '}
                  Removed
                  {' '}
                  {bold(files.length)}
                  {' '}
                  file
                  {suffix}
                  :
                </span>
                <br />
                {fileNames}
              </>
            );
            icon = <FileExcelOutlined style={{ color: 'red' }} />;
            break;
          }
          case 'Profile Update': {
            const updateText = getEquipmentUpdateText({
              metadata,
              userMap,
              equipmentTypesMap,
              statuses,
              projectMap,
              divisions,
              labelMap,
            });
            text = (
              <span>
                {bold(defaultedUser)}
                {' '}
                {updateText}
              </span>
            );
            icon = <DiffOutlined style={{ color: 'black' }} />;
            break;
          }
          default:
            break;
        }

        return {
          entryId,
          type,
          title: type,
          timestamp,
          text,
          format,
          icon,
          extra,
        };
      });
  }, [id, equipmentHistory, equipmentMap, projectId, projectMap, userMap, fileMap]);

  return (
    <>
      <div style={{ overflowY: 'auto', ...style }}>
        <Timeline>
          {
              ourHistory.map((entry) => {
                const {
                  entryId,
                  extra,
                  icon,
                } = entry;
                return (
                  <Timeline.Item dot={icon} key={entryId}>
                    <TimelineStepText
                      {...entry}
                    />
                    {extra}
                  </Timeline.Item>
                );
              })
            }
        </Timeline>

        {
          ourHistory.length === 0
          && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      </div>
      <FullPhoto
        url={selectedFile ? selectedFile.url : null}
        type={selectedFile ? getFileType(selectedFile) : null}
        onClose={onClearSelectedFile}
        onDownload={onDownload}
        useApryse
      />
      <FormDetailView
        visible={showForm}
        onClose={() => setShowForm(false)}
        userMap={userMap}
        shouldRedirect={false}
      />
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
EquipmentHistoryList.propTypes = {
  id: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  style: PropTypes.object,
};

EquipmentHistoryList.defaultProps = {
  projectId: undefined,
  style: {},
};
