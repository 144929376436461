import { DateTime, Duration } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';
import moment from 'moment';

import Permissions from '../auth/Permissions';
import PDFTableExport from '../common/pdf/PDFExport/PDFTableExport';

import { roundTotalRuntime } from '../helpers/time';
import { isNullOrUndefined, mergeSets, toTitleCase } from '../helpers/helpers';
import { taskIsOutsideWorkingHours } from '../common/clock/clock.helpers';
import { timeKeyToTitle } from './state/Timecard.constants';

import { loadManualEntryData, stripSecondsAndMilliseconds } from '../clock/ManualEntry/manualEntryHelpers';
import { generateResponseMap } from '../forms/ResponderHelpers';
import { getBase64ImageFromURL } from '../projects/ProjectScheduleOfValues/PDF/sov.pdf.helpers';

export default {};

// CONSTANTS:
export const EXPORT_TYPE_PDF = 'PDF Export';
export const EXPORT_TYPE_EXCEL = 'Excel Export';

const TIMECARDS_PDF_EXPORT_TABLE_HEADER = (showSignature) => [
  { text: 'Date', style: 'tableHeader' },
  { text: 'Name', style: 'tableHeader' },
  { text: 'Type', style: 'tableHeader' },
  { text: 'Regular Start', style: 'tableHeader' },
  { text: 'Regular Stop', style: 'tableHeader' },
  { text: 'Break Start', style: 'tableHeader' },
  { text: 'Break Stop', style: 'tableHeader' },
  { text: 'Overtime Start', style: 'tableHeader' },
  { text: 'Overtime Stop', style: 'tableHeader' },
  { text: 'DT Overtime Start', style: 'tableHeader' },
  { text: 'DT Overtime Stop', style: 'tableHeader' },
  { text: 'PTO Start', style: 'tableHeader' },
  { text: 'PTO Stop', style: 'tableHeader' },
  { text: 'Details', style: 'tableHeader' },
  { text: '', style: 'tableHeader' },
  { text: 'Entry', style: 'tableHeader' },
  { text: 'Total', style: 'tableHeader' },
  ...(showSignature ? [{ text: 'Signature', style: 'tableHeader' }] : []),
];

const shouldShowSignature = ({ tasks, summaryExportFields, dataMap }) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const task of tasks) {
    const { data: customData = [], divisionId } = dataMap[task.id] ?? {};
    const signatureField = summaryExportFields[divisionId];
    if (signatureField) {
      const customFieldResponseMap = generateResponseMap(customData);
      const { values = [] } = customFieldResponseMap[signatureField] ?? {};
      if (values.length) return true;
    }
  }
  return false;
};

export const msToString = (ms) => {
  if (ms === 86400000) {
    return '24:00:00';
  }
  if (ms > 24 * 60 * 60 * 1000) {
    return `${Math.floor(moment.duration(ms).asHours())}:${moment.utc(ms % (60 * 60 * 1000)).format('mm:ss')}`;
  }
  return `${moment.utc(ms).format('HH:mm:ss')}`;
};

// HELPERS:

export const getCanEditUnapproved = (userId, position) => (
  Permissions.has(`TIMECARD_EDIT_${Permissions.formatPosition(position)}`)
    || (userId === Permissions.id && Permissions.has('TIMECARD_EDIT_SELF'))
);

export const getCanEditApproved = (isIndividual, userId, position) => (
  (isIndividual && Permissions.has('APPROVED_TIMECARD_EDIT_SELF'))
    || (userId === Permissions.id && Permissions.has('APPROVED_TIMECARD_EDIT_SELF'))
    || (position && userId !== Permissions.id && Permissions.has(`APPROVED_TIMECARD_EDIT_${Permissions.formatPosition(position)}`))
);

export const decorateTasks = ({
  tasks,
  checkGeofence,
  projectIdMap = {},
  costcodeIdMap = {},
  isIndividual,
  canEdit: canEditAll,
  user = {},
  workingHours = {},
  userMap = {},
  tabletMap,
  isSummary,
}) => (
  tasks.map((task) => {
    const {
      projectId,
      costcodeId,
      startLatitude,
      startLongitude,
      endLatitude,
      endLongitude,
      state,
      userId,
      enteredMetadata,
      startMetadata,
      endMetadata,
      isPending,
      formUserId,
    } = task;
    let {
      id,
      position,
    } = user;
    let canEditSpecificTask = false;
    if (isSummary) {
      const ourUser = userMap?.[userId] ?? {};
      id = ourUser?.id ?? null;
      position = ourUser?.position ?? null;

      canEditSpecificTask = getCanEditUnapproved(id, position);
    }
    const isApproved = state === 'approved';
    const canEditApproved = isApproved && getCanEditApproved(isIndividual, id, position);
    const canEditUnapproved = !isApproved && (canEditAll || canEditSpecificTask);
    const canEditTask = (canEditApproved || canEditUnapproved) && !isPending;
    const {
      [projectId]: project = {},
    } = projectIdMap;

    const {
      [costcodeId]: costcode = {},
    } = costcodeIdMap;

    let geofenceViolation = false;
    if (checkGeofence) {
      if (startLatitude && startLongitude) {
        geofenceViolation = TaskHelpers.isGeofenceViolation({
          location: {
            latitude: startLatitude,
            longitude: startLongitude,
          },
          project,
        });
      }
      if (endLatitude && endLongitude) {
        geofenceViolation = geofenceViolation || TaskHelpers.isGeofenceViolation({
          location: {
            latitude: endLatitude,
            longitude: endLongitude,
          },
          project,
        });
      }
    }

    const {
      enteredViaMetadata,
      enteredViaText,
    } = TaskHelpers.parseEnteredViaMetadata({
      enteredMetadata,
      startMetadata,
      endMetadata,
      userMap,
      tabletMap,
    }) ?? {};

    return {
      ...task,
      projectName: project?.name,
      projectNumber: project?.number,
      costCode: costcode.code,
      fullCostcode: costcode.id ? `${costcode.code} - ${costcode.name}` : null,
      geofenceViolation,
      canEdit: canEditTask,
      outsideWorkingHours: taskIsOutsideWorkingHours(task, workingHours[position] ?? {}),
      enteredViaMetadata,
      enteredVia: enteredViaText,
      formUserName: userMap[formUserId]?.name,
    };
  })
);

/**
 * Gets formatted date for pdf export
 * @param {number} timestamp
 * @returns {string}
 */
export const formatDate = (timestamp, zone) => {
  if (!timestamp) return { text: '' };
  return DateTime.fromMillis(timestamp, { zone })
    .toLocaleString({
      weekday: 'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
};

/**
 * Gets 12-hour time formatted for pdf export
 * @param {number} timestamp
 * @returns {object}
 */
const formatTime = (timestamp, zone) => {
  if (!timestamp || typeof timestamp !== 'number') return { text: '' };
  const time = DateTime.fromMillis(timestamp, { zone })
    .toLocaleString({
      hour: '2-digit',
      minute: 'numeric',
      timeZoneName: 'short',
    });
  return { text: time || '' };
};

/**
 * Gets project details formatted for pdf export
 * @param {string} projectName
 * @param {string} phase
 * @param {string} costcode
 * @returns {object}
 */
const formatProjectDetails = ({
  t,
  projectName,
  phase,
  costcode,
}) => ({
  text: `${t('Project')}: ${projectName || '-'}\nPhase: ${phase || '-'}\nCost Code: ${costcode || '-'}`,
  alignment: 'left',
});

/**
 * Gets formatted duration for pdf export
 * @param {number} runtime
 * @param {object}
 */
const formatRunTime = (runtime) => {
  if (isNullOrUndefined(runtime) || typeof runtime !== 'number') return { text: '' };
  const minutesRounded = Math.round(runtime / 60000);
  const duration = Duration.fromObject({ minutes: minutesRounded }).toFormat('hh:mm');
  return { text: duration ?? '' };
};

/**
 * Creates a pdf row(s) representing a task
 * @param {boolean} isFirst - is first task of day
 * @param {boolean} isLast - is last task of day
 * @param {number} spacerCount - the number of empty rows between days
 * @param {object} task
 * @param {object} runtimeMap
 * @param {number} roundingInterval
 * @param {object} userMap
 * @param {bool} showOriginalTimes
 * @returns {array} pdf task row(s) to add
 */
const createTaskRow = ({
  t,
  isFirst,
  isLast,
  spacerCount = 3,
  task = {},
  runtimeMap = {},
  roundingInterval,
  userMap = {},
  roundingType,
  roundingSetting,
  showOriginalTimes,
  signatures,
  tableHeader,
  showSignature,
  phaseMap,
}) => {
  const {
    projectName,
    costCode,
    phaseId,
    startTime,
    originalStart,
    endTime,
    originalEnd,
    breakStartTime,
    breakEndTime,
    otStartTime,
    otEndTime,
    doubleOTStartTime,
    doubleOTEndTime,
    ptoStartTime,
    ptoEndTime,
    note,
    type,
    hourBased,
    timezone,
    userId,
  } = task;
  const phase = phaseMap?.[phaseId]?.name;
  const taskEndTime = TaskHelpers.getEndTime(task);
  const taskStartTime = TaskHelpers.getStartTime(task);
  const timestamp = taskEndTime || taskStartTime;
  const runtimes = TaskHelpers.getRuntimes(task);
  const runtime = runtimes.regularTime + runtimes.overtime + runtimes.doubleOT;
  let rows = [];
  const formattedType = toTitleCase(type);
  const ourUsername = userMap[userId]?.name;
  if (isFirst) {
    const formattedDate = formatDate(timestamp, timezone);
    const descriptiveDayRow = [
      formattedDate,
      ourUsername,
      formattedType,
      hourBased ? '' : formatTime(TaskHelpers.getDisplayStart({ startTime, originalStart }, showOriginalTimes), timezone),
      hourBased ? '' : formatTime(TaskHelpers.getDisplayEnd({ endTime, originalEnd }, showOriginalTimes), timezone),
      hourBased ? '' : formatTime(breakStartTime, timezone),
      hourBased ? '' : formatTime(breakEndTime, timezone),
      hourBased ? '' : formatTime(otStartTime, timezone),
      hourBased ? '' : formatTime(otEndTime, timezone),
      hourBased ? '' : formatTime(doubleOTStartTime, timezone),
      hourBased ? '' : formatTime(doubleOTEndTime, timezone),
      hourBased ? '' : formatTime(ptoStartTime, timezone),
      hourBased ? '' : formatTime(ptoEndTime, timezone),
      formatProjectDetails({
        t,
        projectName,
        phase,
        costcode: costCode,
      }),
      { text: note, alignment: 'left' },
      formatRunTime(runtime),
      formatRunTime(
        roundTotalRuntime(
          runtimeMap[`${userId}-${formattedDate}`],
          roundingInterval,
          roundingType,
          roundingSetting,
        ),
      ),
      ...(showSignature ? [{
        layout: 'noBorders',
        table: {
          headerRows: 0,
          widths: [50, '*'],
          body: signatures,
        },
      }] : []),
    ];
    rows.push(descriptiveDayRow);
  } else {
    const regularTaskRow = [
      { text: '' },
      ourUsername,
      formattedType,
      hourBased ? '' : formatTime(TaskHelpers.getDisplayStart({ startTime, originalStart }, showOriginalTimes), timezone),
      hourBased ? '' : formatTime(TaskHelpers.getDisplayEnd({ endTime, originalEnd }, showOriginalTimes), timezone),
      hourBased ? '' : formatTime(breakStartTime, timezone),
      hourBased ? '' : formatTime(breakEndTime, timezone),
      hourBased ? '' : formatTime(otStartTime, timezone),
      hourBased ? '' : formatTime(otEndTime, timezone),
      hourBased ? '' : formatTime(doubleOTStartTime, timezone),
      hourBased ? '' : formatTime(doubleOTEndTime, timezone),
      hourBased ? '' : formatTime(ptoStartTime, timezone),
      hourBased ? '' : formatTime(ptoEndTime, timezone),
      formatProjectDetails({
        t,
        projectName,
        phase,
        costcode: costCode,
      }),
      { text: note, alignment: 'left' },
      formatRunTime(runtime),
      { text: '' },
      ...(showSignature ? [{
        layout: 'noBorders',
        table: {
          headerRows: 0,
          widths: [50, '*'],
          body: signatures,
        },
      }] : []),
    ];
    rows.push(regularTaskRow);
  }
  if (isLast) {
    const emptySpacerRow = new Array(tableHeader.length).fill('');
    rows = rows.concat(Array(spacerCount).fill(emptySpacerRow));
  }
  return rows;
};

/**
 * Creates daily run time map
 * @param {array} tasks
 * @returns {object}
 */
const getDailyRuntimeMap = (tasks = []) => {
  const map = {};
  tasks.forEach((task) => {
    const startTime = TaskHelpers.getStartTime(task);
    const endTime = TaskHelpers.getEndTime(task);
    if (!endTime) return;

    const {
      timezone,
      userId,
    } = task || {};

    const timestamp = endTime || startTime;
    const runtimes = TaskHelpers.getRuntimes(task);
    const runtime = runtimes.regularTime + runtimes.overtime + runtimes.doubleOT;
    const key = `${userId}-${formatDate(timestamp, timezone)}`;
    if (!map[key]) {
      map[key] = runtime;
      return;
    }
    map[key] += runtime;
  });
  return map;
};

/**
 * Prepares the task in pdf export format (array of arrays),
 * If there are no tasks return empty row
 * @param {object} t - i18n translation function
 * @param {number} roundingInterval
 * @param {bool} isSummary
 * @param {object} userMap
 * @param {array} tasks
 * @param {string} roundingType
 * @param {bool} showOriginalTimes - Whether to show original or mutating times
 * @returns {string[][]} - pdf rows
 */
const refinePDFExportTaskData = async ({
  t,
  roundingInterval,
  isSummary,
  userMap,
  tasks = [],
  roundingType,
  roundingSetting,
  showOriginalTimes,
  dataMap,
  fileMap,
  summaryExportFields,
  tableHeader,
  showSignature,
  phaseMap,
}) => {
  let sortedTasks = tasks;
  if (!isSummary) {
    sortedTasks = tasks
      .map((task) => ({ ...task })) // use map to return new tasks to allow sort (mutability)
      .sort((a, b) => TaskHelpers.getEndTime(a) - TaskHelpers.getEndTime(b));
  }
  const dailyRuntimeMap = getDailyRuntimeMap(sortedTasks);
  const dayLabelsAdded = new Set();
  const taskRows = [];
  await Promise.all(sortedTasks.map(async (task, index) => {
    const {
      id,
      timezone,
      userId,
    } = task || {};
    const nextTask = sortedTasks[index + 1] || {};
    const currentTaskStartTimeStamp = TaskHelpers.getStartTime(task);
    const currentTaskEndTimeStamp = TaskHelpers.getEndTime(task);
    const nextTaskStartTimeStamp = TaskHelpers.getStartTime(nextTask);
    const nextTaskEndTimeStamp = TaskHelpers.getEndTime(nextTask);
    const currentTaskTS = currentTaskEndTimeStamp || currentTaskStartTimeStamp;
    const nextTaskTS = nextTaskStartTimeStamp || nextTaskEndTimeStamp;
    const currentTaskFormattedDateKey = `${userId}-${formatDate(currentTaskTS, timezone)}`;
    const nextTaskFormattedDateKey = `${userId}-${formatDate(nextTaskTS, timezone)}`;
    let isFirstTaskOfDay = false;
    let isLastTaskOfDay = false;
    if (!dayLabelsAdded.has(currentTaskFormattedDateKey)) {
      isFirstTaskOfDay = true;
      dayLabelsAdded.add(currentTaskFormattedDateKey);
    }
    if (nextTaskFormattedDateKey !== currentTaskFormattedDateKey) {
      isLastTaskOfDay = true;
    }

    const { data: customData = [], divisionId } = dataMap[id] ?? {};

    const signatures = [];
    if (showSignature && divisionId) {
      const signatureField = summaryExportFields[divisionId];
      const customFieldResponseMap = generateResponseMap(customData);
      const { values = [] } = customFieldResponseMap[signatureField] ?? {};
      await Promise.all(values.map(async ({ sig, name }) => {
        const { url } = fileMap[sig] ?? {};
        if (url) {
          let image;
          try {
            image = await getBase64ImageFromURL(url);
          } catch (error) {
            // pass
          }
          let signature = { text: '' };
          if (image) {
            signature = { image, fit: [100, 50] };
          }
          signatures.push([{ text: name }, signature]);
        }
      }));
    }

    taskRows.push(...createTaskRow({
      t,
      isFirst: isFirstTaskOfDay,
      isLast: isLastTaskOfDay,
      task,
      runtimeMap: dailyRuntimeMap,
      roundingInterval,
      userMap,
      roundingType,
      roundingSetting,
      showOriginalTimes,
      signatures: signatures.length ? signatures : [{ text: '' }],
      tableHeader,
      showSignature,
      phaseMap,
    }));
  }));
  return taskRows;
};

/**
 * Adjusts/prepares timecard data structure for pdf export
 * @param {object} t - i18n translation function
 * @param {object} companyImageURL
 * @param {object} user
 * @param {number} start - millis
 * @param {number} end - millis
 * @param {array} tasks
 */
export const timecardPDFExportAdapter = async ({
  t,
  companyImageURL,
  user: {
    name: userName = '',
    employeeId = '',
  } = {},
  start = 0,
  end = 0,
  tasks,
  roundingInterval,
  isSummary,
  userMap = {},
  roundingType,
  roundingSetting,
  showOriginalTimes,
  summaryExportFields = {},
  phaseMap = {},
}) => {
  const pdfTableExport = new PDFTableExport();
  // Prepare/Construct Header:
  const logoEntry = await PDFTableExport.getCompanyLogo(companyImageURL);
  const timeRangeLabel = PDFTableExport.getTimeRangeLabel(start, end);
  const leftColumn = PDFTableExport.createColumnList({
    content: [
      { text: isSummary ? 'Summary' : userName, style: 'header' },
      employeeId ? { text: `ID: ${employeeId}`, style: 'subHeader' } : { text: '' },
    ],
  });
  const rightColumn = PDFTableExport.createColumnList({
    content: [
      { text: timeRangeLabel, style: 'header' },
      { text: '' },
    ],
  });
  const header = PDFTableExport.createHeader({
    useLogo: true,
    logoEntry,
    columns: [leftColumn, rightColumn],
  });
  const { dataMap, fileMap } = await loadManualEntryData(tasks.map(({ id }) => id), true);
  const showSignature = shouldShowSignature({ tasks, summaryExportFields, dataMap });
  // Prepare/Construct Body:
  const tableHeader = TIMECARDS_PDF_EXPORT_TABLE_HEADER(showSignature);
  const bodyRows = await refinePDFExportTaskData({
    t,
    roundingInterval: roundingInterval || 1,
    isSummary,
    userMap,
    tasks,
    roundingType,
    roundingSetting,
    showOriginalTimes,
    dataMap,
    fileMap,
    summaryExportFields,
    tableHeader,
    showSignature,
    phaseMap,
  });
  const bodyTable = PDFTableExport.createTable({
    widths: [50, 50, 30, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 80, '*', 30, 30, 150],
    header: tableHeader,
    rows: bodyRows,
  });

  // Export PDF:
  const intervalStart = DateTime.fromMillis(start).toLocaleString(DateTime.DATE_MED);
  const intervalEnd = DateTime.fromMillis(end).toLocaleString(DateTime.DATE_MED);
  const title = `${isSummary ? 'Summary' : userName} ${intervalStart} - ${intervalEnd} Timecard Breakdown`;
  pdfTableExport.export({
    name: title,
    header,
    body: [bodyTable],
    pageOrientation: 'landscape',
    pageSize: 'A3',
  });
};

export const getRuntime = ({
  startTime,
  endTime,
  type,
}) => {
  if (!(endTime && endTime > startTime) || type === 'break') {
    return 0;
  }

  return endTime - startTime;
};

export const roundDayRuntime = (runtime, roundingInterval, roundingType, roundingSetting) => {
  if (roundingType === 'taskTime') return runtime;

  return TaskHelpers.getRoundedRuntime(
    runtime,
    { roundingInterval, roundingSetting },
  );
};

export const prepareRedistributionPayload = ({ distributionRows = [], date }) => {
  const newPayload = distributionRows.map((row) => {
    const newRow = { ...row };

    Object.keys(timeKeyToTitle).forEach((key) => {
      if (!newRow[key]) return;
      const parsedValue = parseFloat(newRow[key]);
      const decimalValue = Number.isNaN(parsedValue) ? 0 : parsedValue;
      const hours = Math.floor(decimalValue);
      const minutes = Math.round((decimalValue - hours) * 60);
      const millis = date.set({ hours, minutes }).toMillis();
      newRow[key] = millis;
    });

    return newRow;
  });

  return newPayload;
};

// returns a time range with added buffer for any ot pay calculations
export const getTimesFromTimeRange = (timeRange) => {
  let [startDT, endDT] = timeRange;
  if (startDT) {
    startDT = startDT.minus({ days: 15 }).startOf('day'); // ot buffer (calculateOT)
  } else {
    startDT = DateTime.local().minus({ days: 15 }).startOf('day');
  }
  if (endDT) {
    endDT = endDT.plus({ days: 5 }).endOf('day');
  } else {
    endDT = DateTime.local().plus({ days: 5 }).endOf('day');
  }
  return {
    startTime: startDT.toMillis(),
    endTime: endDT.toMillis(),
  };
};

export const getUsersFromSelectedDivisions = ({
  users,
  selectedDivisions,
  divisions,
}) => {
  const selectedDivUsers = Object.values(divisions)
    .filter(({ id }) => selectedDivisions.has(id))
    .map(({ users: divUsers = new Set() }) => divUsers);
  const mergedSelectedDivUsers = mergeSets(selectedDivUsers);
  return users.filter((user) => mergedSelectedDivUsers.has(user.id) && user.active);
};

export const hasEditedTimes = (editedTask, originalTask) => {
  if (!editedTask || !originalTask || editedTask.type === 'break') return false;

  const {
    startTime: eStart,
    endTime: eEnd,
    otStartTime: eOtStart,
    otEndTime: eOtEnd,
    breakStartTime: eBreakStart,
    breakEndTime: eBreakEnd,
    doubleOTStartTime: eDOtStart,
    doubleOTEndTime: eDOtEnd,
    ptoStartTime: ePtoStart,
    ptoEndTime: ePtoEnd,
    originalStart: eOriginalStart,
    originalEnd: eOriginalEnd,
  } = editedTask;
  const {
    startTime,
    endTime,
    otStartTime,
    otEndTime,
    breakStartTime,
    breakEndTime,
    doubleOTStartTime,
    doubleOTEndTime,
    ptoStartTime,
    ptoEndTime,
    originalStart,
    originalEnd,
  } = originalTask;

  /* eslint-disable max-len */
  return (
    (eStart && startTime && stripSecondsAndMilliseconds(eStart) !== stripSecondsAndMilliseconds(startTime))
    || (eEnd && endTime && stripSecondsAndMilliseconds(eEnd) !== stripSecondsAndMilliseconds(endTime))
    || (eOtStart && otStartTime && stripSecondsAndMilliseconds(eOtStart) !== stripSecondsAndMilliseconds(otStartTime))
    || (eOtEnd && otEndTime && stripSecondsAndMilliseconds(eOtEnd) !== stripSecondsAndMilliseconds(otEndTime))
    || (eBreakStart && breakStartTime && stripSecondsAndMilliseconds(eBreakStart) !== stripSecondsAndMilliseconds(breakStartTime))
    || (eBreakEnd && breakEndTime && stripSecondsAndMilliseconds(eBreakEnd) !== stripSecondsAndMilliseconds(breakEndTime))
    || (eDOtStart && doubleOTStartTime && stripSecondsAndMilliseconds(eDOtStart) !== stripSecondsAndMilliseconds(doubleOTStartTime))
    || (eDOtEnd && doubleOTEndTime && stripSecondsAndMilliseconds(eDOtEnd) !== stripSecondsAndMilliseconds(doubleOTEndTime))
    || (ePtoStart && ptoStartTime && stripSecondsAndMilliseconds(ePtoStart) !== stripSecondsAndMilliseconds(ptoStartTime))
    || (ePtoEnd && ptoEndTime && stripSecondsAndMilliseconds(ePtoEnd) !== stripSecondsAndMilliseconds(ptoEndTime))
    || (eOriginalStart && originalStart && stripSecondsAndMilliseconds(originalStart) !== stripSecondsAndMilliseconds(eOriginalStart))
    || (eOriginalEnd && originalEnd && stripSecondsAndMilliseconds(eOriginalEnd) !== stripSecondsAndMilliseconds(originalEnd))
  );
  /* eslint-enable max-len */
};

export const getPendingTaskText = ({
  formUserName,
  formCreatedAt,
  formName,
  formNumber,
}) => {
  const submitterName = formUserName ?? 'Unknown';
  const formattedCreated = formCreatedAt
    ? DateTime.fromMillis(formCreatedAt).toLocaleString(DateTime.DATETIME_MED)
    : null;

  let formText = '';
  if (formNumber) formText += `${formNumber} - `;
  if (formName) formText += formName;

  return `Pending entry from form ${formText}, submitted by ${submitterName} on ${formattedCreated}`;
};
