import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Drawer, Row, Col, Form, Select,
} from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Permissions from '../auth/Permissions';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import DrawerFooter from '../common/containers/DrawerFooter';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import DivisionSelector from '../common/inputs/DivisionSelector';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import useToggle from '../common/hooks/useToggle';

import {
  validateName,
  validateTeamMembers,
  getSupervisorsAndWorkers,
  getUpdatePayload,
} from './teams.helpers';

import {
  createTeam,
  updateTeam,
  archiveTeam,
  deleteTeam,
} from './state/teams.actions';

import { getIdMap } from '../helpers/helpers';

function TeamDrawer({
  visible,
  selectedTeam,
  onClose,
  showDrawer,
}) {
  const dispatch = useDispatch();

  const viewAssignedTeamsOnly = Permissions.has('TEAMS_WRITE_ASSIGNED') && !Permissions.has('TEAMS_WRITE');
  const teams = useSelector((state) => state.teams.teams);
  const users = useSelector((state) => state.users.users);
  const projects = useSelector((state) => state.projects.projects);
  const divisions = useSelector((state) => state.settings.divisions);
  const equipment = useSelector((state) => state.equipment.equipment);

  const equipmentMap = useMemo(() => getIdMap(equipment), [equipment]);
  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    id,
    active,
    name,
  } = selectedTeam ?? {};
  const title = selectedTeam
    ? `Edit team ${name}`
    : 'Add Team';

  const [divisionIds, setDivisionIds] = useState(new Set([]));
  const {
    isToggled: loading,
    toggle: toggleLoading,
  } = useToggle();

  const onValuesChange = useCallback((_newValues, allValues) => {
    const { divisionIds: newDivs = [] } = allValues;
    const update = { ...allValues };
    const divIdSet = new Set(newDivs ?? []);
    setDivisionIds(divIdSet);

    const divisionUsers = new Set();
    newDivs.forEach((divId) => {
      const {
        [divId]: {
          users: divUsers = new Set(),
        } = {},
      } = divisions;
      divUsers.forEach((userId) => divisionUsers.add(userId));
    });

    update.supervisors = update.supervisors?.filter((userId) => divisionUsers.has(userId)
     || (viewAssignedTeamsOnly && userId === Permissions.id)) ?? [];
    update.workers = update.workers?.filter((userId) => divisionUsers.has(userId)) ?? [];
    update.projects = update.projects?.filter((projectId) => {
      const fullProject = projectIdMap[projectId] ?? {};
      return fullProject.divisionId && divIdSet.has(fullProject.divisionId);
    });
    update.equipment = update.equipment?.filter((eqId) => {
      const fullEquipment = equipmentMap[eqId] ?? {};
      return fullEquipment?.divisionIds?.some((dId) => divIdSet.has(dId));
    });
    form.setFieldsValue(update);
    form.validateFields();
  }, [form, divisionIds, divisions, equipmentMap, viewAssignedTeamsOnly]);

  const onSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields();

      const prom = !selectedTeam?.id
        ? dispatch(createTeam(values))
        : dispatch(updateTeam(id, getUpdatePayload(selectedTeam, values)));
      toggleLoading();
      if (await prom) {
        onClose();
      }
      toggleLoading();
    } catch (err) {
      // silence error from form.validateFields
    }
  }, [selectedTeam, form]);

  const showModal = useCallback((type, onOk) => {
    CustomConfirmModal({
      title: `${type} ${name}?`,
      onOk,
    });
  }, [name]);

  const onDelete = useCallback(() => {
    showModal('Delete', async () => {
      toggleLoading();
      if (await dispatch(deleteTeam(id))) {
        onClose();
      }
      toggleLoading();
    });
  }, [showModal, id]);

  const onArchive = useCallback(() => {
    showModal(active ? 'Archive' : 'Activate', async () => {
      toggleLoading();
      if (await dispatch(archiveTeam(id, !active))) {
        onClose();
      }
      toggleLoading();
    });
  }, [showModal, id, active]);

  useEffect(() => {
    if (!visible) {
      setDivisionIds(new Set([]));
      form.resetFields();
    }
  }, [visible, form]);

  useEffect(() => {
    if (selectedTeam) {
      const {
        supervisors,
        workers,
      } = getSupervisorsAndWorkers(selectedTeam);
      const formData = {
        ...selectedTeam,
        workers,
        supervisors,
        // For some reason backend returns projects as [{ id, name }]
        projects: selectedTeam?.projects?.map((project) => project.id) ?? [],
      };
      form.setFieldsValue(formData);
      setDivisionIds(new Set(selectedTeam?.divisionIds ?? []));
    }
  }, [form, selectedTeam]);

  useEffect(() => {
    if (showDrawer === 'add') {
      const supervisors = form.getFieldValue('supervisors') || [];

      if (viewAssignedTeamsOnly) {
        form.setFieldsValue({ supervisors: [...supervisors, Permissions.id] });
      }
    }
  }, [showDrawer, form, viewAssignedTeamsOnly]);

  const projectOptions = useMemo(() => (
    projects
      .filter((project) => project.active && divisionIds.has(project.divisionId))
      .map((project) => ({
        value: project.id,
        label: project.number
          ? `${project.number} - ${project.name}`
          : project.name,
      }))
  ), [projects, divisionIds]);

  const equipmentOptions = useMemo(() => (
    equipment
      .filter((eq) => eq.active && eq?.divisionIds?.some((dId) => divisionIds.has(dId)))
      .map((eq) => ({
        value: eq.id,
        label: eq.name,
      }))
  ), [equipment, divisionIds]);

  const userOptions = useMemo(() => {
    const divIds = Array.from(divisionIds);
    const divisionUsers = new Set();
    divIds.forEach((divId) => {
      const {
        [divId]: {
          users: divUsers = new Set(),
        } = {},
      } = divisions;
      divUsers.forEach((userId) => divisionUsers.add(userId));
    });
    return users.filter((user) => (
      user.active && (divisionUsers.has(user.id)
      || (viewAssignedTeamsOnly && user.id === Permissions.id))
    )).map((user) => ({
      value: user.id,
      label: user.name,
      disabled: user.id === Permissions.id && viewAssignedTeamsOnly,
    }));
  }, [divisions, users, divisionIds, viewAssignedTeamsOnly]);

  return (
    <Drawer
      title={title}
      visible={visible}
      width={500}
      onClose={onClose}
    >

      <Form
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Name"
              style={{ marginBottom: 20 }}
              rules={[
                { required: true, message: 'Please enter a name' },
                { validator: validateName(id, teams) },
              ]}
            >
              <OnTraccrTextInput style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="divisionIds"
              label="Divisions"
              style={{ marginBottom: 20 }}
              rules={[
                { required: true, message: 'Please select at least one division' },
              ]}
              valuePropName="divisions"
            >
              <DivisionSelector style={{ width: '100%' }} mode="multiple" />
            </Form.Item>
            <Form.Item
              name="projects"
              key="projects"
              label={t('Project', { count: 2 })}
              style={{ marginBottom: 20 }}
            >
              <Select
                options={projectOptions}
                optionFilterProp="label"
                showSearch
                mode="multiple"
              />
            </Form.Item>
            <Form.Item
              name="equipment"
              key="equipment"
              label="Equipment"
              style={{ marginBottom: 20 }}
            >
              <Select
                options={equipmentOptions}
                optionFilterProp="label"
                showSearch
                mode="multiple"
              />
            </Form.Item>
            <Form.Item
              name="supervisors"
              key="supervisors"
              label="Supervisors"
              style={{ marginBottom: 20 }}
              rules={[
                { required: true, message: 'Team must have at least one supervisor' },
                { validator: validateTeamMembers(form) },
              ]}
            >
              <Select
                optionFilterProp="label"
                showSearch
                mode="multiple"
                options={userOptions}
              />
            </Form.Item>

            <Form.Item
              name="workers"
              key="workers"
              label="Team Members"
              style={{ marginBottom: 20 }}
              rules={[
                { required: true, message: 'Team cannot be empty' },
                { validator: validateTeamMembers(form) },
              ]}
            >
              <Select
                optionFilterProp="label"
                showSearch
                mode="multiple"
                options={userOptions}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <DrawerFooter>
        <Row justify="space-between" gutter={10} align="middle">
          <Col>
            <Row justify="start" align="middle" gutter={20}>
              {
                selectedTeam
                && (
                  <OnTraccrButton
                    title={active ? 'Archive' : 'Activate'}
                    type="back"
                    onClick={onArchive}
                    loading={loading}
                    style={{ marginLeft: 10 }}
                  />
                )
              }
              {!active && selectedTeam && (
                <OnTraccrButton
                  title="Delete"
                  type="back"
                  onClick={onDelete}
                  loading={loading}
                  style={{ marginLeft: 10 }}
                />
              )}
            </Row>
          </Col>
          <Col>
            <OnTraccrButton
              title="Cancel"
              type="cancel"
              style={{ marginRight: 8 }}
              onClick={onClose}
            />
            <OnTraccrButton
              title="Submit"
              onClick={onSubmit}
              loading={loading}
            />
          </Col>
        </Row>
      </DrawerFooter>
    </Drawer>
  );
}

TeamDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  selectedTeam: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onClose: PropTypes.func.isRequired,
  showDrawer: PropTypes.string,
};

TeamDrawer.defaultProps = {
  selectedTeam: null,
  showDrawer: null,
};

export default TeamDrawer;
