import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Input, Row, Table } from 'antd';

// Import Components:
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import DateRangePicker from '../../common/datepicker/DateRangePicker';

// Import Constants/Helpers:
import FormPoLiveSummaryColumns from './InvoiceLiveSummaryColumns';
import { INVOICE_DRAWER_ADD_MODE, INVOICE_PROP } from './invoiceConstants';
import Permissions from '../../auth/Permissions';

/** Form Invoice Tab Header */
export default function InvoiceTabHeader({
  invoices = [],
  searchInput,
  useSummary,
  useRange,
  onRangeChange = () => null,
  onSearchInputChange,
  onAddClick,
  isClosed,
  hideUpload,
}) {
  const selectedForm = useSelector((state) => state.forms.selectedForm);

  const onSearchInputChangeHandler = useCallback((e) => onSearchInputChange(e.target.value), []);
  const onAddClickHandler = useCallback(() => onAddClick(INVOICE_DRAWER_ADD_MODE), []);

  const costToDate = useMemo(() => invoices
    .reduce((acc, currInv) => currInv.amount ? acc + currInv.amount : acc, 0), [invoices]);

  return (
    <div className="invoices-tab-header">
      <Row type="flex" justify="start" gutter={14} style={{ height: 32, marginBottom: 10 }}>
        <Col>
          <Input.Search
            className="searchbar"
            placeholder="Search"
            value={searchInput}
            onChange={onSearchInputChangeHandler}
            allowClear
          />
        </Col>
        <Col style={{ marginLeft: 15 }}>
          {Permissions.has('INVOICES_WRITE') && !isClosed && !hideUpload && (
            <OnTraccrButton
              title="Upload Invoice"
              onClick={onAddClickHandler}
            />
          )}
        </Col>
        {useRange && (
          <Col style={{ marginLeft: 'auto' }}>
            <DateRangePicker onRangeChange={onRangeChange} />
          </Col>
        )}
      </Row>
      {useSummary && (
        <Table
          columns={FormPoLiveSummaryColumns}
          dataSource={[{
            formValue: selectedForm.formValue,
            formName: selectedForm.templateName,
            formNumber: selectedForm.number,
            costToDate,
          }]}
          pagination={false}
          className="live-summary-table"
        />
      )}
    </div>
  );
}

InvoiceTabHeader.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape(INVOICE_PROP)),
  searchInput: PropTypes.string.isRequired,
  useSummary: PropTypes.bool,
  useRange: PropTypes.bool,
  onRangeChange: PropTypes.func,
  onSearchInputChange: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  isClosed: PropTypes.bool,
  hideUpload: PropTypes.bool,
};

InvoiceTabHeader.defaultProps = {
  isClosed: false,
  hideUpload: false,
  onRangeChange: null,
  useRange: false,
  useSummary: false,
  invoices: [],
};
