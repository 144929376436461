import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col, Table } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import OnTraccrNumberInput from '../../../common/inputs/OnTraccrNumberInput';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

import { currencyParser, currencyFormatter } from '../../../helpers/inputParsers';
import ChangeOrderDrawer from './ChangeOrderDrawer';
import DisplayText from '../../../common/text/DisplayText';
import { generateId } from '../../formHelpers';
import useCheckTableMaxRows from '../../../common/hooks/useCheckTableMaxRows';
import { getCalculationTableColumn } from './formFieldsHelpers';

const defaultTextRender = ({
  onValueChanged,
  key,
  isDisplay,
  textarea,
}) => function render(val, record) {
  if (isDisplay) return val;
  return (
    <OnTraccrTextInput
      textarea={textarea}
      value={val}
      onChange={(e) => {
        const {
          target: {
            value,
          } = {},
        } = e;
        onValueChanged(record.id, { [key]: value });
      }}
    />
  );
};

const defaultNumberRender = ({
  onValueChanged,
  key,
  isDisplay,
  parser = currencyParser,
  formatter = currencyFormatter,
}) => function render(val, record) {
  if (isDisplay) {
    if (!val) return '';
    return currencyFormatter(val);
  }
  return (
    <OnTraccrNumberInput
      key={record.id}
      min={0}
      onChange={(value) => onValueChanged(record.id, { [key]: value })}
      value={val}
      formatter={formatter}
      parser={parser}
    />
  );
};

const getChangeOrderColumnMap = ({
  onValueChanged,
  isDisplay,
  requiredColumns,
}) => ({
  itemNumber: {
    title: <div className={requiredColumns && 'form-required-field'}>Item Number</div>,
    dataIndex: 'itemNumber',
    width: 100,
    render: defaultTextRender({
      onValueChanged,
      key:
      'itemNumber',
      isDisplay,
      textarea: false,
    }),
  },
  description: {
    title: <div className={requiredColumns && 'form-required-field'}>Description</div>,
    dataIndex: 'description',
    width: 100,
    render: defaultTextRender({
      onValueChanged,
      key:
      'description',
      isDisplay,
      textarea: true,
    }),
  },
  contractAmount: {
    title: <div className={requiredColumns && 'form-required-field'}>Contract Amount</div>,
    dataIndex: 'contractAmount',
    width: 100,
    render: defaultNumberRender({ onValueChanged, key: 'contractAmount', isDisplay }),
  },
  percentageComplete: {
    title: <div className={requiredColumns && 'form-required-field'}>Percentage Complete</div>,
    dataIndex: 'percentageComplete',
    width: 100,
    render: defaultNumberRender({
      onValueChanged,
      key: 'percentageComplete',
      isDisplay,
      parser: (val) => (parseFloat(val)).toFixed(2),
      formatter: (val) => `${val} %`,
    }),
  },
  progressToDate: {
    title: <div className={requiredColumns && 'form-required-field'}>Progress To Date</div>,
    dataIndex: 'progressToDate',
    width: 100,
    render: defaultNumberRender({ onValueChanged, key: 'progressToDate', isDisplay }),
  },
  previousBillings: {
    title: <div className={requiredColumns && 'form-required-field'}>Previous Billings</div>,
    dataIndex: 'previousBillings',
    width: 100,
    render: defaultNumberRender({ onValueChanged, key: 'previousBillings', isDisplay }),
  },
  invoiceAmount: {
    title: <div className={requiredColumns && 'form-required-field'}>Invoice Amount</div>,
    dataIndex: 'invoiceAmount',
    width: 100,
    render: defaultNumberRender({ onValueChanged, key: 'invoiceAmount', isDisplay }),
  },
});

const changeOrderColumns = ({
  onDelete,
  onValueChanged,
  columns,
  isDisplay,
  defaultChangeOrder,
  requiredColumns,
}) => {
  const cols = [];
  const changeOrderColumnMap = getChangeOrderColumnMap({
    onValueChanged,
    isDisplay,
    defaultChangeOrder,
    requiredColumns,
  });
  columns.forEach((col) => {
    if (col.key in changeOrderColumnMap) {
      cols.push(changeOrderColumnMap[col.key]);
    } else if (col.isCalculation) {
      cols.push(getCalculationTableColumn(col, {
        width: 100,
        isDisplay,
      }));
    }
  });

  if (!isDisplay) {
    cols.push({
      title: '',
      width: 100,
      dataIndex: '',
      render: (_, record) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: 'red' }} />}
          onClick={() => onDelete(record.id)}
        />
      ),
    });
  }

  return cols;
};

export default function ChangeOrderTablePreview({
  columns = [],
  previewProps = {},
  id,
  setPreviewProps,
  isDisplay,
  setResponses,
  responses = {},
  responding = false,
  configProps = {},
  changeOrderMap = {},
  showCondensedView,
}) {
  const {
    hideAddNewButton,
    requiredColumns,
    preventEdits,
  } = configProps ?? {};
  const values = previewProps.values || []; // For Responses
  const {
    selected: previewSelected = [],
  } = previewProps;
  const {
    [id]: {
      values: responseSelected = [],
    } = {},
  } = responses;

  const selected = responding ? responseSelected : previewSelected;

  const [showDrawer, setShowDrawer] = useState(false);
  const defaultChangeOrder = {
    itemNumber: 'CO1',
    description: '',
    contractAmount: 0,
    percentageComplete: 0,
    progressToDate: 0,
    previousBillings: 0,
    invoiceAmount: 0,
  };

  const closeDrawer = useCallback(() => setShowDrawer(false), []);
  const onAddClicked = useCallback(() => setShowDrawer(true), []);

  const updateResponses = useCallback((newData = {}) => {
    setResponses({
      ...responses,
      [id]: {
        ...(responses[id]),
        ...newData,
      },
    });
  }, [responses, id, setResponses]);

  const onAddNewClicked = useCallback(() => {
    const newEntry = {
      id: generateId(),
      ...defaultChangeOrder,
    };

    const newSelected = selected.concat([newEntry]);
    if (responding) {
      updateResponses({
        values: newSelected,
        columns,
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
  }, [selected, previewProps, responding, columns, updateResponses]);

  const onSubmit = useCallback((changeOrderIds) => {
    const oldChangeOrderSet = new Set(selected.map((s) => s.id));
    const newChangeOrders = [];

    changeOrderIds.forEach((changeOrderId) => {
      if (!changeOrderId) {
        newChangeOrders.push({
          id: generateId(),
          ...defaultChangeOrder,
        });
        return;
      }

      if (!oldChangeOrderSet.has(changeOrderId)) {
        const [newProjectId, newChangeOrderId] = changeOrderId.split('.');
        const newChangeOrder = changeOrderMap[newProjectId]?.find((c) => c.id === newChangeOrderId);

        if (!newChangeOrder) {
          return;
        }

        newChangeOrders.push({
          ...newChangeOrder,
          itemNumber: `CO${newChangeOrder.itemNumber}`,
          id: changeOrderId,
        });
      }
    });

    const newSelected = selected.concat(newChangeOrders).filter((nco) => !!nco);
    if (responding) {
      updateResponses({ values: newSelected, columns });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }

    setShowDrawer(false);
  }, [selected, responding, previewProps, updateResponses, columns]);

  const onDelete = useCallback((deletedId) => {
    const newSelected = selected.filter((item) => item.id !== deletedId);
    if (responding) {
      updateResponses({
        values: newSelected,
        columns,
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: selected.filter((item) => item.id !== deletedId),
      });
    }
  }, [previewProps, selected, updateResponses]);

  const onValueChanged = useCallback((changedId, newData = {}) => {
    const newSelected = [...selected].map((item) => {
      if (item.id !== changedId) return item;
      return {
        ...item,
        ...newData,
      };
    });

    if (responding) {
      updateResponses({
        values: newSelected,
        columns,
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
  }, [selected, responding, updateResponses, previewProps, columns]);

  const tableColumns = useMemo(() => (
    changeOrderColumns({
      onDelete,
      onValueChanged,
      columns,
      isDisplay: isDisplay || preventEdits,
      defaultChangeOrder,
      requiredColumns,
    })
  ), [columns, isDisplay, onDelete, onValueChanged, requiredColumns, preventEdits]);

  const dataSource = useMemo(() => (
    isDisplay && !responding ? values : selected
  ), [isDisplay, responding, values, selected]);

  const {
    maxExistingAllowed,
    shouldAddButtonBeEnabled = true,
  } = useCheckTableMaxRows({
    configProps,
    currentRowsLength: dataSource?.length,
  });

  return (
    <Row style={{ marginTop: showCondensedView ? 0 : 15 }}>
      {!isDisplay && (
        <Row style={{ marginBottom: 10, width: '100%' }} gutter={20}>
          <Col>
            <OnTraccrButton
              title="Add from Change Orders"
              icon={<PlusOutlined />}
              onClick={onAddClicked}
              disabled={!shouldAddButtonBeEnabled}
            />
          </Col>
          <Col>
            { !hideAddNewButton && (
              <OnTraccrButton
                title="Add New"
                icon={<PlusOutlined />}
                onClick={onAddNewClicked}
                disabled={!shouldAddButtonBeEnabled}
              />
            )}
          </Col>
        </Row>
      )}
      { !showCondensedView || dataSource?.length ? (
        <Table
          style={{ width: '100%', overflow: 'auto' }}
          columns={tableColumns}
          size="small"
          pagination={false}
          dataSource={dataSource}
        />
      ) : (
        <DisplayText title="No Change Orders Selected" style={{ marginBottom: 0 }} />
      )}
      {!isDisplay && (
        <ChangeOrderDrawer
          visible={showDrawer}
          onClose={closeDrawer}
          onSubmit={onSubmit}
          changeOrderMap={changeOrderMap}
          maxExistingAllowed={maxExistingAllowed}
        />
      )}
    </Row>
  );
}
ChangeOrderTablePreview.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
  })),
  previewProps: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.shape({})),
    selected: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  id: PropTypes.string,
  setPreviewProps: PropTypes.func,
  isDisplay: PropTypes.bool,
  setResponses: PropTypes.func,
  responses: PropTypes.shape({}),
  responding: PropTypes.bool,
  changeOrderMap: PropTypes.shape({}),
  configProps: PropTypes.shape({
    hideAddNewButton: PropTypes.bool,
    requiredColumns: PropTypes.bool,
  }),
  showCondensedView: PropTypes.bool,
};

ChangeOrderTablePreview.defaultProps = {
  columns: [],
  previewProps: {},
  id: null,
  setPreviewProps: null,
  isDisplay: false,
  setResponses: null,
  responses: {},
  responding: false,
  changeOrderMap: {},
  configProps: {},
  showCondensedView: false,
};
