import React, { useCallback, useState } from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';

import { INVOICES_TAB, PURCHASE_ORDERS_TAB } from '../payables/Payables';
import PayablesList from '../common/forms/PayablesList';
import FormInvoices from '../payables/invoices/FormInvoices';

const { TabPane } = Tabs;

export default function ProjectPayables({
  id,
  visible,
  isNotDisplay,
}) {
  const [activeTab, setActiveTab] = useState(INVOICES_TAB);

  const onTabChange = useCallback((tab) => setActiveTab(tab), []);

  return (
    <Tabs
      onChange={onTabChange}
      activeKey={activeTab}
      tabPosition="left"
      style={{
        position: 'absolute',
        top: 120,
        right: 30,
        left: 10,
      }}
      tabBarStyle={{
        width: 130,
      }}
    >
      <TabPane
        tab="Invoices"
        key={INVOICES_TAB}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <FormInvoices
          visible={visible && activeTab === INVOICES_TAB}
          projectId={id}
          hideUpload
          height="calc(80vh - 150px)"
        />
      </TabPane>
      <TabPane
        tab="Purchase Order"
        key={PURCHASE_ORDERS_TAB}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <PayablesList
          id={id}
          visible={visible && activeTab === PURCHASE_ORDERS_TAB}
          type="Projects"
          isNotDisplay={isNotDisplay}
        />
      </TabPane>
    </Tabs>
  );
}

ProjectPayables.propTypes = {
  id: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  isNotDisplay: PropTypes.bool,
};

ProjectPayables.defaultProps = {
  isNotDisplay: false,
};
