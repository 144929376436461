import React from 'react';
import PropTypes from 'prop-types';
import {
  List, Tag, Space, Tooltip,
} from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons';

export default function ScheduleApplicantSelector({
  entireUserMap,
  users,
  appliedUsers,
  onUpdateStatus,
  hasWritePerm,
}) {
  const userMap = users.reduce((acc, user) => {
    acc[user.id] = user.name;
    return acc;
  }, {});

  const tagColor = (status) => {
    switch (status) {
      case 'accepted': return 'green';
      case 'rejected': return 'red';
      case 'withdrawn': return 'orange';
      default: return 'blue';
    }
  };

  return (
    <List
      bordered
      dataSource={appliedUsers}
      renderItem={({ userId, status }) => {
        const userFromEntireMap = entireUserMap[userId];

        const name = userMap[userId]
        ?? userFromEntireMap?.name
        ?? 'Unknown User';

        const notInTeam = !userMap[userId];

        return (
          <List.Item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span style={{ fontSize: '14px', fontWeight: 500 }}>{name}</span>
            {notInTeam ? (
              <Tooltip
                title="This user is not part of the selected division or team. To accept or reject them, either change the division or remove the team filter, or add the user to the division or team."
                placement="left"
              >
                <InfoCircleOutlined style={{ fontSize: '20px', color: 'orange' }} />
              </Tooltip>
            ) : (
              <Space>
                <Tag color={tagColor(status)}>{status}</Tag>
                <CheckCircleTwoTone
                  twoToneColor={status === 'accepted' ? 'green' : '#ccc'}
                  style={{
                    fontSize: '24px',
                    cursor: 'pointer',
                  }}
                  onClick={hasWritePerm && !notInTeam ? () => onUpdateStatus(userId, 'accepted') : null}
                />
                <CloseCircleTwoTone
                  twoToneColor={status === 'rejected' ? 'red' : '#ccc'}
                  style={{
                    fontSize: '24px',
                    cursor: 'pointer',
                  }}
                  onClick={hasWritePerm && !notInTeam ? () => onUpdateStatus(userId, 'rejected') : null}
                />
              </Space>
            )}
          </List.Item>
        );
      }}
    />
  );
}

ScheduleApplicantSelector.propTypes = {
  appliedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onUpdateStatus: PropTypes.func.isRequired, // Function to handle status updates
  hasWritePerm: PropTypes.bool.isRequired,
  entireUserMap: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
