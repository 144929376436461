import PropTypes from 'prop-types';
import React from 'react';
import { Col, Drawer } from 'antd';
import DynamicRemindersField from './reminder/DynamicRemindersField';

export default function ScheduleReminderDrawer({
  form,
  isDisplay,
  visible,
  onClose,
  selectedDivisionId,
  selectedProjectId,
}) {
  return (
    <Drawer
      width={1000}
      visible={visible}
      onClose={onClose}
      title="Reminders"
      maskClosable={false}
      forceRender
    >
      <Col>
        <DynamicRemindersField
          type="reminders"
          isDisplay={isDisplay}
        />
        <DynamicRemindersField
          form={form}
          type="externalReminders"
          isDisplay={isDisplay}
          selectedDivisionId={selectedDivisionId}
          selectedProjectId={selectedProjectId}
        />
      </Col>
    </Drawer>
  );
}

ScheduleReminderDrawer.propTypes = {
  isDisplay: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  selectedDivisionId: PropTypes.string,
  selectedProjectId: PropTypes.string,
  form: PropTypes.shape({}),
};

ScheduleReminderDrawer.defaultProps = {
  isDisplay: false,
  visible: false,
  selectedDivisionId: undefined,
  selectedProjectId: undefined,
  form: undefined,
};
