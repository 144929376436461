import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';

import Permissions from '../auth/Permissions';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import ArchiveModal from '../common/modals/ArchiveModal';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';

import MaterialsHeader from './MaterialsHeader';
import MaterialsTable from './MaterialsTable';
import MaterialDrawer from './MaterialDrawer';
import MaterialFolderAddDrawer from './MaterialFolderAddDrawer';
import MaterialBulkChangeDrawer from './MaterialBulkChangeDrawer';
import MaterialDestinationDrawer from './MaterialDestinationDrawer';
import MaterialPermsDrawer from './MaterialPermsDrawer';

import {
  getRelevantDeleteWarning,
  prepareMaterialLocations,
} from './materialsHelpers';

import {
  createMaterial,
  getMaterials,
  archiveMaterial,
  updateMaterial,
  deleteMaterial,
  bulkUpdateMaterials,
  createMaterialsFolder,
  updateMaterialsFolder,
  moveMaterials,
  copyMaterials,
  uploadMaterials,
  massArchive,
  massDelete,
} from './state/materials.actions';
import { getEquipment } from '../equipment/state/equipment.actions';
import { getCardLinks } from '../boards/state/boards.actions';

import { convertPascalCaseToCamelCase } from '../forms/FormBuilder/FormFields/TimeEntryTable/TimeEntryTable.helpers';
import FilterDropdown from '../common/FilterDropdown';
import { sortByObjProperty } from '../common/helpers';

const INITIAL_CRUMBS = [{
  text: 'Materials',
  icon: 'materials',
}];

const filters = [[
  [
    {
      title: 'Type',
      key: 'type',
      children: [
        {
          title: 'Material',
          key: 'Material',
        },
        {
          title: 'Assembly',
          key: 'Assembly',
        },
      ],
    },
  ],
]];

export default function Materials() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    state: {
      targetId: locationStateId,
    } = {},
  } = useLocation();

  const materials = useSelector((state) => state.materials.materials);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const globalMaterialLocations = useSelector((state) => (
    state.globalMaterialLocations.globalMaterialLocations
  ));

  const [crumbs, setCrumbs] = useState(INITIAL_CRUMBS);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [isDisplay, setIsDisplay] = useState(false);
  const [lastCreate, setLastCreate] = useState(DateTime.local().toMillis());
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [bulkChangeType, setBulkChangeType] = useState();
  const [bulkChangeCostType, setBulkChangeCostType] = useState();
  const [selectedColumns, setSelectedColumns] = useState();
  const [showFolderAdd, setShowFolderAdd] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [loading, setLoading] = useState(false);
  const [showDestinationDrawer, setShowDestinationDrawer] = useState({
    visible: false,
    isCopy: false,
  });
  const [selectedPermsMaterial, setSelectedPermsMaterial] = useState(false);
  const [isAssembly, setIsAssembly] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    type: new Set([
      'Material',
      'Assembly',
    ]),
  });

  const onShowPerms = useCallback((permsMaterial) => setSelectedPermsMaterial(permsMaterial), []);
  const onHidePerms = useCallback(() => setSelectedPermsMaterial(), []);

  const filterIsAtDefault = useMemo(() => {
    const {
      type = new Set(),
    } = activeFilters;
    return type.has('Material')
      && type.has('Assembly');
  }, [activeFilters]);

  const onFilter = useCallback((checkedFilters, filterCategory) => {
    // Need to filter out header values
    setActiveFilters({
      ...activeFilters,
      [filterCategory]: new Set(checkedFilters),
    });
  }, [activeFilters]);

  const openMoveDrawer = useCallback(() => (
    setShowDestinationDrawer({ visible: true, isCopy: false })
  ), []);
  const closeMoveDrawer = useCallback(() => (
    setShowDestinationDrawer({ visible: false, isCopy: false })
  ), []);
  const openCopyDrawer = useCallback(() => (
    setShowDestinationDrawer({ visible: true, isCopy: true })
  ), []);
  const closeCopyDrawer = useCallback(() => (
    setShowDestinationDrawer({ visible: false, isCopy: true })
  ), []);
  const closeDrawer = useCallback(() => {
    setShowDrawer(false);
    setSelectedMaterial();
    setIsAssembly();
  }, []);

  const onAddClicked = useCallback((isAddAssembly = false) => {
    setSelectedMaterial();
    setShowDrawer(true);
    setIsDisplay(false);
    setIsAssembly(isAddAssembly);
  }, []);

  const onAddFolderClicked = useCallback(() => setShowFolderAdd(true), []);
  const closeFolderAdd = useCallback(() => {
    setSelectedFolder({});
    setShowFolderAdd(false);
  }, []);
  const onFolderEdit = useCallback((record) => {
    setSelectedFolder(record);
    setShowFolderAdd(true);
  }, []);

  const onFolderSubmit = useCallback(async (folderData) => {
    const { id: groupId = null } = crumbs[crumbs.length - 1];
    if (selectedFolder.id) {
      // Edit
      const { name: oldName, divisionId: oldDivisionId } = selectedFolder;
      const update = {};
      if (folderData.name !== oldName) update.name = folderData.name;
      if (folderData.divisionId !== oldDivisionId) update.divisionId = folderData.divisionId;
      if (
        Object.keys(update).length === 0
        || await dispatch(updateMaterialsFolder(selectedFolder.id, update))
      ) {
        setShowFolderAdd(false);
      }
    } else if (await dispatch(createMaterialsFolder({ ...folderData, groupId }))) {
      setShowFolderAdd(false);
    }
    setLastCreate(DateTime.local().toMillis());
  }, [dispatch, selectedFolder, crumbs]);

  const onAddMaterial = useCallback(async ({
    values,
    locations,
    shouldAutoCalcCost,
    shouldAutoCalcLabourCost,
    assemblyMaterials,
    isAssembly: payloadIsAssembly,
  }) => {
    const { id: groupId = null } = crumbs[crumbs.length - 1];
    const newValues = {
      ...values,
      groupId,
      markup: values.markup ? values.markup / 100 : values.markup,
      shouldAutoCalcCost: !!shouldAutoCalcCost,
      shouldAutoCalcLabourCost: !!shouldAutoCalcLabourCost,
      isAssembly: !!payloadIsAssembly,
      assemblyMaterials,
      labourMarkup: values.labourMarkup ? values.labourMarkup / 100 : values.labourMarkup,
    };
    if (selectedMaterial && selectedMaterial.id) {
      const locationPayload = prepareMaterialLocations({
        newLocations: locations,
        oldLocations: selectedMaterial.locations,
      });
      delete newValues.groupId; // Can only update group via "Move" API
      delete newValues.isAssembly;

      const payload = {
        ...newValues,
        ...locationPayload,
      };

      if (await dispatch(updateMaterial(selectedMaterial.id, payload))) {
        setShowDrawer(false);
        setIsAssembly();
        setSelectedMaterial();
      }
    } else {
      const { createdLocations } = prepareMaterialLocations({
        newLocations: locations,
      });

      const payload = {
        ...newValues,
        createdLocations,
      };
      if (await dispatch(createMaterial(payload))) {
        setShowDrawer(false);
        setIsAssembly();
      }
      return;
    }
    setLastCreate(DateTime.local().toMillis());
  }, [selectedMaterial, crumbs]);

  const onMassUpload = useCallback(async (massUploadData = []) => {
    if (massUploadData.length === 0) {
      setShowDrawer(false);
      setIsAssembly();
      return;
    }
    const { id: groupId = null } = crumbs[crumbs.length - 1];
    const parsedMaterials = massUploadData.map((mat) => ({
      ...mat,
      groupId,
    }));
    if (await dispatch(uploadMaterials({
      materials: parsedMaterials,
    }))) {
      setShowDrawer(false);
      setIsAssembly();
    }
  }, [crumbs]);

  const onMoveMaterial = useCallback(async (newLocation) => {
    const payload = {
      materialIds: selectedRecords.map((material) => material.id),
      groupId: newLocation,
    };

    setLoading(true);
    const res = await dispatch(moveMaterials(payload));
    setLoading(false);
    if (res) {
      closeMoveDrawer();
      setSelectedRecords([]);
      setLoading(false);
    }
  }, [dispatch, selectedRecords]);

  const onCopyMaterial = useCallback(async (copyDestinationId) => {
    const payload = {
      materialIds: selectedRecords.map((material) => material.id),
      groupId: copyDestinationId,
    };

    setLoading(true);
    const res = await dispatch(copyMaterials(payload));
    setLoading(false);
    if (res) {
      closeCopyDrawer();
      setSelectedRecords([]);
    }
  }, [dispatch, selectedRecords]);

  const onRowClick = useCallback((record) => {
    if (record.isFolder) {
      setSelectedRecords([]);
      const newCrumbs = [...crumbs];
      newCrumbs.push({
        text: record.name,
        id: record.id,
      });
      setCrumbs(newCrumbs);
    } else {
      setIsDisplay(true);
      setSelectedMaterial(record);
      setShowDrawer(true);
    }
  }, [crumbs]);

  const onSearchClick = useCallback((record) => {
    if (!record) return false;
    const { id, groupId: recordGroupId } = record;
    let groupId = recordGroupId;
    const newCrumbs = [];
    if (record.isFolder) {
      newCrumbs.push({
        text: record.name,
        id: record.id,
      });
    }
    while (groupId) {
      const parent = materials[groupId];
      if (!parent) {
        /*
          Something strange has happened.
          Backend should always return upstream folders
          if user has permission to a material in their downstream
        */
        return false;
      }
      newCrumbs.push({
        text: parent.name,
        id: parent.id,
      });
      groupId = parent.groupId;
    }
    newCrumbs.reverse();
    setCrumbs(INITIAL_CRUMBS.concat(newCrumbs));
    if (record.isFolder) {
      setSelectedRecords([]);
    } else {
      const fullMaterial = materials[id];
      setTimeout(() => {
        setIsDisplay(true);
        setSelectedMaterial(fullMaterial);
        setShowDrawer(true);
      }, 250);
    }
    return true;
  }, [crumbs, materials]);

  const onBack = useCallback(() => {
    const newCrumbs = [...crumbs];
    newCrumbs.pop();
    setCrumbs(newCrumbs);
  }, [crumbs]);

  const onEdit = useCallback(() => setIsDisplay(false), []);
  const onArchive = useCallback(() => {
    ArchiveModal({
      type: selectedMaterial.isAssembly ? 'Assembly' : 'Material',
      content: getRelevantDeleteWarning(materials, [selectedMaterial.id]),
      item: selectedMaterial,
      onOk: async () => {
        if (await dispatch(archiveMaterial(selectedMaterial.id, !selectedMaterial.active))) {
          setSelectedMaterial();
          setShowDrawer(false);
          setIsAssembly();
        }
        return true;
      },
    });
  }, [selectedMaterial, materials, selectedMaterial]);

  const onDelete = useCallback(() => {
    if (!selectedMaterial) return;
    CustomConfirmModal({
      title: `Delete ${selectedMaterial.isAssembly ? 'Assembly' : ''} '${selectedMaterial.name}'?`,
      content: getRelevantDeleteWarning(materials, [selectedMaterial.id]),
      okText: 'Delete',
      cancelText: 'Cancel',
      async onOk() {
        if (await dispatch(deleteMaterial(selectedMaterial.id))) {
          closeDrawer();
        }
      },
    });
  }, [selectedMaterial, closeDrawer, materials]);

  const onBulkChangeClicked = useCallback(({ type, costType = 'material' }) => {
    setBulkChangeType(type);
    setBulkChangeCostType(costType);
  }, []);

  const closeBulkChangeDrawer = useCallback(() => {
    setBulkChangeType();
    setBulkChangeCostType();
  }, []);

  const onBulkChange = useCallback(async (change) => {
    if (selectedRecords.length === 0 || !bulkChangeType || !bulkChangeCostType) {
      closeBulkChangeDrawer();
      return;
    }
    if (await dispatch(bulkUpdateMaterials({
      materialIds: selectedRecords.map((record) => record.id),
      type: convertPascalCaseToCamelCase(bulkChangeType),
      costType: bulkChangeCostType,
      change: bulkChangeType === 'Markup' ? change / 100 : change, // Markup uses percent as decimal
    }))) {
      closeBulkChangeDrawer();
      setSelectedRecords([]);
    }
  }, [bulkChangeType, bulkChangeCostType, selectedRecords, dispatch, closeBulkChangeDrawer]);

  const onColumnChange = useCallback((newColumns) => {
    setSelectedColumns(new Set(newColumns));
  }, []);

  const onMassArchive = useCallback(() => {
    const selectedIDs = selectedRecords.map((record) => record.id);
    if (selectedIDs.length === 0) return;

    const selectedContainsFolder = selectedRecords.some((record) => record.isFolder);
    const modalTitle = selectedContainsFolder
      ? 'Archiving a folder will inactivate all of its content. Would you like to proceed?'
      : `Archive ${selectedIDs.length} materials?`;

    CustomConfirmModal({
      title: modalTitle,
      content: getRelevantDeleteWarning(materials, selectedIDs),
      okText: 'Archive',
      cancelText: 'Cancel',
      async onOk() {
        if (await dispatch(massArchive(selectedIDs))) {
          setSelectedRecords([]);
        }
      },
    });
  }, [dispatch, selectedRecords, materials]);

  const onMassDelete = useCallback(() => {
    const selectedIDs = selectedRecords.map((record) => record.id);
    if (selectedIDs.length === 0) return;

    CustomConfirmModal({
      title: `Delete ${selectedIDs.length} materials?`,
      content: getRelevantDeleteWarning(materials, selectedIDs),
      okText: 'Delete',
      cancelText: 'Cancel',
      async onOk() {
        if (await dispatch(massDelete(selectedIDs))) {
          setSelectedRecords([]);
        }
      },
    });
  }, [selectedRecords, materials]);

  useEffect(() => {
    dispatch(getMaterials());
    dispatch(getEquipment());
    dispatch(getCardLinks());
  }, [dispatch]);

  useEffect(() => {
    const {
      [locationStateId]: ourMaterial,
    } = materials;

    if (ourMaterial) {
      setSelectedMaterial(ourMaterial);
      setIsDisplay(true);
      setTimeout(() => setShowDrawer(true), 250);
    }
  }, [locationStateId, materials]);

  const data = useMemo(() => {
    const { id: currentFolderId = null } = crumbs[crumbs.length - 1];
    const folders = [];
    const items = [];
    Object.values(materials).forEach((mat) => {
      if (mat.groupId === currentFolderId
        && selectedDivisions.has(mat.divisionId)
      ) {
        if (mat.isFolder) {
          folders.push(mat);
        } else if (activeFilters.type.has(mat.isAssembly ? 'Assembly' : 'Material')) {
          items.push(mat);
        }
      }
    });
    folders.sort(sortByObjProperty('name'));
    items.sort(sortByObjProperty('name'));
    return folders.concat(items);
  }, [materials, selectedDivisions, crumbs, activeFilters]);

  // Dynamically set default materialLocation to reference default globalMaterialLocation
  const defaultLocation = useMemo(() => {
    const defaultGlobalMaterialLocation = globalMaterialLocations.filter((loc) => loc.locationText === 'DEFAULT');
    if (defaultGlobalMaterialLocation[0]?.id) {
      return [{
        id: 'DEFAULT',
        quantity: 0,
        quantityAllocated: 0,
        isDefault: true,
        globalMaterialLocationsId: defaultGlobalMaterialLocation[0].id,
      }];
    }
    return [{
      id: 'DEFAULT',
      quantity: 0,
      quantityAllocated: 0,
      isDefault: true,
    }];
  }, [globalMaterialLocations]);

  if (!Permissions.match('MATERIALS')) {
    history.replace('/dashboard');
    return null;
  }

  return (
    <BreadCrumbContainer crumbs={crumbs}>
      <MaterialsHeader
        onAdd={onAddClicked}
        onFolderAdd={onAddFolderClicked}
        selected={selectedRecords}
        onBulkChangeClicked={onBulkChangeClicked}
        onColumnChange={onColumnChange}
        onBack={crumbs.length > 1 ? onBack : null}
        onMove={openMoveDrawer}
        onCopy={openCopyDrawer}
        onArchive={onMassArchive}
        onDelete={onMassDelete}
        onSearchClick={onSearchClick}
        filter={(
          <FilterDropdown
            filters={filters}
            activeFilters={activeFilters}
            onFilter={onFilter}
          />
        )}
        filterActive={filterIsAtDefault}
      />
      <MaterialsTable
        onFolderEdit={onFolderEdit}
        data={data}
        onRowClick={onRowClick}
        onSelect={setSelectedRecords}
        selectedColumns={selectedColumns}
        selected={selectedRecords}
        onShare={onShowPerms}
      />
      <MaterialDrawer
        key={selectedMaterial ? selectedMaterial.id : `add-${lastCreate}`}
        visible={showDrawer}
        onClose={closeDrawer}
        onSubmit={onAddMaterial}
        isNotDisplay={!isDisplay}
        item={selectedMaterial}
        defaultLocation={defaultLocation}
        onEdit={onEdit}
        onArchive={onArchive}
        onDelete={onDelete}
        onMassUpload={onMassUpload}
        isAssembly={isAssembly}
      />
      <MaterialBulkChangeDrawer
        visible={bulkChangeType}
        type={bulkChangeType}
        costType={bulkChangeCostType}
        onClose={closeBulkChangeDrawer}
        selected={selectedRecords}
        onSubmit={onBulkChange}
      />
      <MaterialFolderAddDrawer
        key={selectedFolder ? selectedFolder.id : `add-${lastCreate}`}
        item={selectedFolder}
        visible={showFolderAdd}
        onClose={closeFolderAdd}
        onSubmit={onFolderSubmit}
      />
      <MaterialDestinationDrawer
        loading={loading}
        isCopy={showDestinationDrawer.isCopy}
        visible={showDestinationDrawer.visible}
        onClose={showDestinationDrawer.isCopy ? closeCopyDrawer : closeMoveDrawer}
        selected={selectedRecords}
        onSubmit={showDestinationDrawer.isCopy ? onCopyMaterial : onMoveMaterial}
      />
      <MaterialPermsDrawer
        selectedMaterial={selectedPermsMaterial}
        onClose={onHidePerms}
      />
    </BreadCrumbContainer>
  );
}
