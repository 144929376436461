import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';

import {
  getAllTimesText,
  getName,
  getProjectRowNames,
  TextRow,
} from './manualEntryHelpers';
import { getIdMap, toTitleCase } from '../../helpers/helpers';

import colors from '../../constants/Colors';

const ManualEntryCardDetails = function ManualEntryCardDetails({
  date,
  startTime,
  endTime,
  originalStart,
  originalEnd,
  breakStartTime,
  breakEndTime,
  otStartTime,
  otEndTime,
  doubleOTStartTime,
  doubleOTEndTime,
  ptoStartTime,
  ptoEndTime,
  projectId,
  phaseId,
  costcodeId,
  localId,
  classId,
  sageShiftId,
  type,
  hourBased,
  timezone,
  usersName,
  approvers = [],
}) {
  const { t } = useTranslation();
  const showProjectRow = (projectId || phaseId || costcodeId);
  const showUnionRow = (localId || classId || sageShiftId);

  const projects = useSelector((state) => state.projects.projects);
  const phases = useSelector((state) => state.costcodes.phases);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const { locals = [], classes = [] } = useSelector((state) => state.unions);
  const sageShifts = useSelector((state) => state.sage.shifts);
  const company = useSelector((state) => state.settings.company);

  const {
    settings: {
      showOriginalTimes = false,
    } = {},
  } = company ?? {};

  const {
    project,
    projectName,
    phaseName,
    costcodeName,
  } = useMemo(() => getProjectRowNames({
    projectId,
    phaseId,
    costcodeId,
    projects,
    phases,
    costcodes,
  }), [
    projectId,
    phaseId,
    costcodeId,
    projects,
    phases,
    costcodes,
  ]);

  const localIdMap = useMemo(() => getIdMap(locals), [locals]);
  const classIdMap = useMemo(() => getIdMap(classes), [classes]);

  const localName = useMemo(() => getName(localId, localIdMap), [localId, localIdMap]);
  const className = useMemo(() => getName(classId, classIdMap), [classId, classIdMap]);
  const shiftName = useMemo(() => getName(sageShiftId, sageShifts), [sageShiftId, sageShifts]);

  const timeText = useMemo(() => getAllTimesText({
    hourBased,
    startTime,
    endTime,
    breakStartTime,
    breakEndTime,
    otStartTime,
    otEndTime,
    doubleOTStartTime,
    doubleOTEndTime,
    ptoStartTime,
    ptoEndTime,
    timezone,
    originalStart,
    originalEnd,
  }, {
    showOriginalTimes,
    showZeroTimes: true,
  }), [
    hourBased,
    startTime,
    endTime,
    originalStart,
    originalEnd,
    breakStartTime,
    breakEndTime,
    otStartTime,
    otEndTime,
    doubleOTStartTime,
    doubleOTEndTime,
    ptoStartTime,
    ptoEndTime,
    timezone,
    showOriginalTimes,
  ]);
  const typeTitle = useMemo(() => {
    if (type === 'pto') return 'PTO';
    if (type) {
      return toTitleCase(type);
    }

    return '';
  }, [type]);
  const isBreak = type === 'break';
  const color = isBreak ? colors.ONTRACCR_DARK_YELLOW : 'inherit';

  return (
    <Col>
      <Row align="middle">
        <Col style={{
          fontSize: 18,
          fontWeight: '500',
        }}
        >
          {date ? moment(date).format('MMM Do yy') : moment(endTime).format('MMM Do yy')}
        </Col>
        {typeTitle && (
          <Col style={{ color, marginLeft: 10 }}>
            {typeTitle}
          </Col>
        )}
      </Row>
      {timeText && (
        <Row>
          <Col style={{ margin: '0px 0px' }}>
            {timeText}
          </Col>
        </Row>
      )}
      {showProjectRow && (
        <Row gutter={10}>
          {project && <Col><TextRow title={t('Project')} text={projectName} /></Col>}
          {phaseName && <Col><TextRow title="Phase" text={phaseName} /></Col>}
          {costcodeName && <Col><TextRow title="Cost Code" text={costcodeName} /></Col>}
        </Row>
      )}
      {showUnionRow && (
        <Row gutter={10}>
          {localName && <Col><TextRow title="Union Local" text={localName} /></Col>}
          {className && <Col><TextRow title="Work Classification" text={className} /></Col>}
          {shiftName && <Col><TextRow title="Shift" text={shiftName} /></Col>}
        </Row>
      )}
      {usersName && (
        <Row gutter={10}>
          <Col><TextRow title="User" text={usersName} /></Col>
        </Row>
      )}
      {
        approvers?.length > 0 && (
          <Row gutter={10}>
            <Col>
              <TextRow
                title="Approved By"
                text={approvers.map(({ name } = {}) => <div key={name}>{name}</div>)}
              />
            </Col>
          </Row>
        )
      }
    </Col>
  );
};

ManualEntryCardDetails.propTypes = {
  date: PropTypes.string,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  originalStart: PropTypes.number,
  originalEnd: PropTypes.number,
  breakStartTime: PropTypes.number,
  breakEndTime: PropTypes.number,
  otStartTime: PropTypes.number,
  otEndTime: PropTypes.number,
  doubleOTStartTime: PropTypes.number,
  doubleOTEndTime: PropTypes.number,
  ptoStartTime: PropTypes.number,
  ptoEndTime: PropTypes.number,
  projectId: PropTypes.string,
  phaseId: PropTypes.string,
  costcodeId: PropTypes.string,
  localId: PropTypes.string,
  classId: PropTypes.string,
  sageShiftId: PropTypes.string,
  type: PropTypes.string.isRequired,
  hourBased: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]).isRequired,
  timezone: PropTypes.string.isRequired,
  usersName: PropTypes.string.isRequired,
  approvers: PropTypes.arrayOf(PropTypes.string),
};

ManualEntryCardDetails.defaultProps = {
  date: null,
  projectId: null,
  phaseId: null,
  costcodeId: null,
  localId: null,
  classId: null,
  sageShiftId: null,
  originalStart: null,
  originalEnd: null,
  breakStartTime: null,
  breakEndTime: null,
  otStartTime: null,
  otEndTime: null,
  doubleOTStartTime: null,
  doubleOTEndTime: null,
  ptoStartTime: null,
  ptoEndTime: null,
  approvers: [],
};

export default ManualEntryCardDetails;
