import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIdMap } from 'ontraccr-common/lib/Common';
import Permissions from '../../auth/Permissions';

export default function useBoardPermissions({
  users,
  creatorId,
}) {
  const company = useSelector((state) => state.settings.company);
  const userBoardPermissions = useMemo(() => {
    const permissionMap = getIdMap(users, 'userId');
    const {
      id: userId,
    } = Permissions;
    const {
      [userId]: {
        formPermission,
        writePerms,
        assignPerms,
      } = {},
    } = permissionMap;

    const isOwnerOrCreator = (company && company.userId === userId) || (userId === creatorId);

    return {
      isOwnerOrCreator,
      isFormsPermitted: isOwnerOrCreator || !!formPermission,
      canEdit: isOwnerOrCreator || writePerms === 'writeAll',
      writePerms: isOwnerOrCreator ? 'writeAll' : writePerms,
      canCreate: isOwnerOrCreator || writePerms === 'writeAll' || writePerms === 'writeAssigned',
      canAssign: isOwnerOrCreator ? false : (!writePerms && assignPerms === 'assignAll'),
      assignPerms: isOwnerOrCreator ? null : assignPerms,
    };
  }, [Permissions, users, creatorId, company]);
  return userBoardPermissions;
}
