import {
  GET_SAGE_SHIFTS,
  CREATE_SAGE_SHIFT,
  UPDATE_SAGE_SHIFT,
  DELETE_SAGE_SHIFT,
  GET_SAGE_PAY_IDS,
  CREATE_SAGE_PAY_MAPPING,
  DELETE_SAGE_PAY_MAPPING,
  UPDATE_SAGE_PAY_MAPPING,
  GET_SAGE_INTEGRATIONS,
  UPDATE_SAGE_DEFAULT_DIVISION,
  UPDATE_SAGE_DIVISIONS,
  UPDATE_SAGE_NAME,
  UPDATE_SAGE_JOB_STATUSES,
  UPDATE_SAGE_DEFAULT_COSTCODE,
} from '../../state/actionTypes';

import { getIdMap } from '../../helpers/helpers';
import { updateObject, removeFromObject } from '../../helpers/stateHelpers';

const initialState = {
  integrations: [],
  shifts: {},
  payIds: [],
  payIdMappings: [],
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SAGE_INTEGRATIONS: {
      const {
        payload: {
          integrations = [],
        } = {},
      } = action;
      return { ...state, integrations };
    }
    case GET_SAGE_SHIFTS: {
      const {
        payload: {
          shifts = [],
        } = {},
      } = action;
      return { ...state, shifts: getIdMap(shifts) };
    }
    case CREATE_SAGE_SHIFT: {
      const {
        payload: {
          newShift = {},
        } = {},
      } = action;
      const newShifts = { ...state.shifts };
      newShifts[newShift.id] = newShift;
      return { ...state, shifts: newShifts };
    }
    case UPDATE_SAGE_SHIFT: {
      const {
        payload: {
          id,
          newData = {},
        } = {},
      } = action;
      return {
        ...state,
        shifts: updateObject({ oldObject: state.shifts, id, newData }),
      };
    }
    case DELETE_SAGE_SHIFT: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      return {
        ...state,
        shifts: removeFromObject({ oldObject: state.shifts, id }),
      };
    }
    case GET_SAGE_PAY_IDS: {
      const {
        payload: {
          sagePayIds = [],
          payIdMappings = [],
        } = {},
      } = action;
      return {
        ...state,
        payIds: sagePayIds,
        payIdMappings,
      };
    }
    case CREATE_SAGE_PAY_MAPPING: {
      const {
        payload: newMapping = {},
      } = action;
      const { payIdMappings: oldMappings = [] } = state;
      return {
        ...state,
        payIdMappings: oldMappings.concat(newMapping),
      };
    }
    case DELETE_SAGE_PAY_MAPPING: {
      const {
        payload: { projectId } = {},
      } = action;
      const { payIdMappings: oldMappings = [] } = state;
      return {
        ...state,
        payIdMappings: oldMappings.filter((mapping) => mapping.projectId !== projectId),
      };
    }
    case UPDATE_SAGE_PAY_MAPPING: {
      const {
        payload: {
          projectId,
          sagePayId,
        } = {},
      } = action;
      const { payIdMappings: oldMappings = [] } = state;
      return {
        ...state,
        payIdMappings: oldMappings.map((mapping) => {
          if (mapping.projectId !== projectId) return mapping;
          return {
            ...mapping,
            sagePayId,
          };
        }),
      };
    }
    case UPDATE_SAGE_DEFAULT_DIVISION: {
      const {
        payload: {
          integrationId,
          divisionId,
        } = {},
      } = action;

      const integrations = state.integrations.map((integration) => {
        if (integration.id !== integrationId) return integration;
        return {
          ...integration,
          defaultDivisionId: divisionId,
        };
      });

      return {
        ...state,
        integrations,
      };
    }
    case UPDATE_SAGE_DIVISIONS: {
      const {
        payload: {
          integrationId,
          divisions,
          defaultDivisionId,
        } = {},
      } = action;

      const integrations = state.integrations.map((integration) => {
        if (integration.id !== integrationId) return integration;
        return {
          ...integration,
          divisions,
          defaultDivisionId,
        };
      });

      return {
        ...state,
        integrations,
      };
    }
    case UPDATE_SAGE_NAME: {
      const {
        payload: {
          integrationId,
          name,
        } = {},
      } = action;

      const integrations = state.integrations.map((integration) => {
        if (integration.id !== integrationId) return integration;
        return {
          ...integration,
          name,
        };
      });

      return {
        ...state,
        integrations,
      };
    }
    case UPDATE_SAGE_JOB_STATUSES: {
      const {
        payload: {
          integrationId,
          jobStatuses,
        } = {},
      } = action;

      const integrations = state.integrations.map((integration) => {
        if (integration.id !== integrationId) return integration;
        return {
          ...integration,
          jobStatuses,
        };
      });

      return {
        ...state,
        integrations,
      };
    }
    case UPDATE_SAGE_DEFAULT_COSTCODE: {
      const {
        payload: {
          integrationId,
          costcodeId,
        } = {},
      } = action;

      const integrations = state.integrations.map((integration) => {
        if (integration.id !== integrationId) return integration;
        return {
          ...integration,
          defaultCostcodeId: costcodeId,
        };
      });

      return {
        ...state,
        integrations,
      };
    }
    default:
      return state;
  }
};
