import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Table, Drawer } from 'antd';
import { getLogColumns } from './ScheduleOfValuesColumns';
import PhotoReel from '../../common/photos/PhotoReel';
import FullPhoto from '../../common/photos/FullPhoto';
import { getFileType, downloadFile, getFileDetails } from '../../files/fileHelpers';

const LogRow = ({
  record = { files: [] },
  setSelectedFile,
  children,
  ...restProps
}) => {
  const onPreviewClick = useCallback((index) => {
    const file = record.files[index];
    if (file) {
      setSelectedFile({
        ...file,
        type: getFileType(file),
      });
    }
  },[record.files]);

  return (
    <>
      <tr {...restProps} className="log-table-row">
        {children}
      </tr>
      <tr className="log-table-file-row">
        { record.files.length > 0 &&
          <div className='livefeed-file-reel-container'>
            <PhotoReel
              files={record.files}
              shadow
              small
              onClick={onPreviewClick}
            />
          </div>
        }
      </tr>
    </>
  )
};

const LogTable = ({
  columns,
  dataSource,
  ...restProps
}) => {
  const components = {
    body: {
      row: LogRow,
    }
  };

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={true}
      components={components}
      expandable={{
        defaultExpandAllRows: true
      }}
      onRow={(record) => ({
        record,
        ...restProps,
      })}
      rowKey="id"
    />
  );
};

const ScheduleOfValuesLog = ({
  logs,
  onClose,
  visible,
  onPdf,
  onEdit,
  onHistory,
}) => {
  const [ selectedFile, setSelectedFile ] = useState();
  const [ fileDetails, setFileDetails] = useState({})

  const clearFileDetails = useCallback(() => setFileDetails({}),[]);

  useEffect(() => {
    const getDetails = async (file) => {
      const details = await getFileDetails(file);
      if (details) setFileDetails(details);
    }

    if(selectedFile) {
      getDetails(selectedFile);
    } else {
      clearFileDetails();
    }
  },[selectedFile, clearFileDetails]);

  const clearSelectedFile = useCallback(() => setSelectedFile(null),[]);

  return (
    <Drawer
      title="Log"
      visible={visible}
      onClose={onClose}
      width={750}
      bodyStyle={{ padding: 0 }}
    >
      <LogTable
        columns={getLogColumns({ onPdf, onEdit, onHistory })}
        dataSource={logs}
        pagination={true}
        expandable={{
          defaultExpandAllRows: true
        }}
        setSelectedFile={setSelectedFile}
        rowKey="id"
      />
      <FullPhoto
        url={fileDetails.url}
        type={fileDetails.type}
        onClose={clearSelectedFile}
        onDownload={() => {
          downloadFile({ fileDetails });
        }}
        useApryse
      />
    </Drawer>
  );
};

export default ScheduleOfValuesLog;
