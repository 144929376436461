import React, { useCallback, useMemo } from 'react';
import { Row, Table } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import DisplayText from '../../../common/text/DisplayText';
import { generateId } from '../../formHelpers';
import useCheckTableMaxRows from '../../../common/hooks/useCheckTableMaxRows';
import { calculationTableRender } from './formFieldsHelpers';

export default function CustomTablePreview({
  columns,
  isDisplay,
  previewProps = {},
  setPreviewProps,
  id,
  setResponses,
  responses = {},
  responding = false,
  configProps = {},
  showCondensedView,
}) {
  const {
    requiredColumns,
    preventEdits,
  } = configProps ?? {};
  const {
    values = [], // For Responses
    selected: previewSelected = [],
  } = previewProps;

  const {
    [id]: {
      values: responseSelected = [{}],
    } = {},
  } = responses;

  const selected = responding ? responseSelected : previewSelected;

  const updateResponses = useCallback((newData = {}) => {
    setResponses({
      ...responses,
      [id]: {
        ...(responses[id]),
        ...newData,
      },
    });
  }, [responses, id, setResponses]);

  const onDelete = useCallback((deleteIndex) => () => {
    const newSelected = [...selected];
    newSelected.splice(deleteIndex, 1);

    if (responding) {
      updateResponses({
        values: newSelected,
        columns,
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
  }, [previewProps, selected, updateResponses, setPreviewProps]);

  const onDataChanged = useCallback(({ col, index, value }) => {
    const updatedSelected = [...selected];

    const newRow = {
      ...updatedSelected[index],
      [col.name]: value,
    };

    if (!newRow.id) {
      newRow.id = generateId();
    }

    updatedSelected[index] = newRow;

    if (responding) {
      updateResponses({
        values: updatedSelected,
        columns,
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: updatedSelected,
      });
    }
  }, [updateResponses, setPreviewProps, previewProps, selected, responses]);

  const tableColumns = useMemo(() => {
    const cols = columns.map((col) => ({
      title: <div className={requiredColumns && 'form-required-field'}>{col.name}</div>,
      dataIndex: col.name,
      render: (text, record, index) => {
        if (col.isCalculation) {
          return calculationTableRender(col, isDisplay)(text);
        }
        return isDisplay || preventEdits ? text : (
          <OnTraccrTextInput
            textarea
            style={{
              minWidth: '10em',
            }}
            onChange={({
              target: {
                value,
              } = {},
            }) => onDataChanged({ col, index, value })}
            value={record[col.name]}
          />
        );
      },
    }));
    if (!isDisplay) {
      cols.push({
        title: '',
        dataIndex: '',
        width: 100,
        render: (_1, _2, index) => (
          <BorderlessButton
            iconNode={<DeleteOutlined style={{ color: 'red' }} />}
            onClick={onDelete(index)}
          />
        ),
      });
    }
    return cols;
  }, [isDisplay, columns, onDataChanged, onDelete, requiredColumns, preventEdits]);

  const lastRowEmpty = selected.length
    ? Object.values(selected[selected.length - 1]).every((val) => val === '')
    : false;

  const onAddNewClicked = useCallback(() => {
    const newEntry = columns.reduce((result, item) => {
      // eslint-disable-next-line no-param-reassign
      result[item.name] = '';
      return result;
    }, {});

    newEntry.id = generateId();

    const newSelected = selected.concat([newEntry]);
    if (responding) {
      updateResponses({
        values: newSelected,
        columns,
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
  }, [selected, previewProps, responding, columns, setResponses]);

  const dataSource = useMemo(() => (
    isDisplay && !responding ? values : selected
  ), [isDisplay, responding, values, selected]);

  const {
    shouldAddButtonBeEnabled = true,
  } = useCheckTableMaxRows({
    configProps,
    currentRowsLength: dataSource?.length,
  });

  return (
    <Row>
      {!isDisplay && (
        <Row>
          <OnTraccrButton
            type="primary"
            title="Add Row"
            icon={<PlusOutlined />}
            disabled={lastRowEmpty || !shouldAddButtonBeEnabled}
            onClick={onAddNewClicked}
            style={{
              margin: 10,
            }}
          />
        </Row>
      )}
      { !showCondensedView || dataSource?.length ? (
        <Table
          style={{
            width: '100%',
            overflow: 'auto',
          }}
          columns={tableColumns}
          size="small"
          pagination={false}
          dataSource={dataSource}
        />
      ) : (
        <DisplayText title="No Items Selected" style={{ marginBottom: 0 }} />
      )}
    </Row>
  );
}

CustomTablePreview.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  isDisplay: PropTypes.bool,
  previewProps: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  id: PropTypes.string,
  setResponses: PropTypes.func,
  responses: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      values: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  responding: PropTypes.bool,
  setPreviewProps: PropTypes.func,
  configProps: PropTypes.shape({
    requiredColumns: PropTypes.bool,
  }),
  showCondensedView: PropTypes.bool,
};

CustomTablePreview.defaultProps = {
  columns: [],
  isDisplay: false,
  previewProps: {},
  id: null,
  setResponses: null,
  responses: {},
  responding: false,
  setPreviewProps: null,
  configProps: {},
  showCondensedView: false,
};
