import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';

import UserAssignmentTable from '../forms/UserAssignmentTable';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import { reassignCard } from './state/boards.actions';
import { compareStringArrays } from '../helpers/helpers';

export default function BoardCardReassignmentDrawer({
  visible,
  onClose,
  cardId,
  cardUsers = [],
}) {
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (!visible) {
      setSelectedUsers([]);
    } else if (cardUsers.length) {
      setSelectedUsers(cardUsers);
    }
  }, [visible, cardUsers]);

  const onReassignCard = useCallback(async () => {
    try {
      const {
        added: addedUsers,
        removed: removedUsers,
      } = compareStringArrays(cardUsers, selectedUsers);
      if (!addedUsers.length && !removedUsers.length) return;
      if (await dispatch(reassignCard(cardId, { addedUsers, removedUsers }))) {
        onClose();
      }
    } catch (err) {
      //
    }
  }, [cardId, onClose, selectedUsers]);

  return (
    <Drawer
      visible={visible}
      title="Reassign Card"
      onClose={onClose}
      width={500}
    >
      <UserAssignmentTable
        selectedUsers={selectedUsers}
        onUsersChanged={setSelectedUsers}
        scroll={{ y: 'calc(100vh - 300px)' }}
      />
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onReassignCard}
        canSubmit={selectedUsers.length}
      />
    </Drawer>
  );
}

BoardCardReassignmentDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  cardId: PropTypes.string.isRequired,
  cardUsers: PropTypes.arrayOf(PropTypes.string),
};

BoardCardReassignmentDrawer.defaultProps = {
  visible: false,
  onClose: null,
  cardUsers: [],
};
