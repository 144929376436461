import React from 'react';
import { Row, Select } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import TitleRow from './TitleRow';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

import OptionalRow from './OptionalRow';

import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import FieldTriggerFlag from './FieldTriggerFlag';
import ConditionalRenderingRow from './ConditionalRenderingRow';

import { CURRENCIES } from '../../forms.constants';

const configure = ({
  id,
  setConfigProps,
  configProps = {},
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  customers = [],
  vendors = [],
  equipment = [],
  formTemplates = [],
  labels = [],
  contactAddressBooks = {},
  buckets = [],
} = {}) => {
  const {
    optional,
    hasConditionalRendering = false,
    conditionalRenderingFormula,
    currency = 'cad',
  } = configProps;

  const setTitle = (e) => {
    const {
      target:{
        value,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      title:value,
    });
  };

  const setOptional = (e) => {
    const {
      target:{
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      optional:checked,
    });
  };

  const onFieldChange = (newField) => {
    setConfigProps({
      ...configProps,
      linkedField: newField,
    });
  };

  const onCurrencyChange = (newCur) => {
    setConfigProps({
      ...configProps,
      currency: newCur,
    });
  };

  const setFieldTrigger = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      fieldTrigger: checked,
    });
  };

  const setHasConditionalRendering = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      hasConditionalRendering: checked,
    });
  };

  const setConditionalRenderingFormula = (newVal) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: newVal,
    });
  };

  const allFields = sections.map(({ fields = [] }) => fields).flat();
  const availableFields = allFields.reduce((acc, field) => {
      const {
        id: fieldId,
        selectedType,
        configProps: {
          title,
        } = {},
      } = field;
      if (selectedType !== 'text' && selectedType !== 'calculation') return acc;
      return acc.concat([{
        id: fieldId,
        title,
      }]);
  }, [])
  return (
    <div>
      <Row className='form-required-field'>
        Title:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <OnTraccrTextInput
          textarea placeholder='Insert title here'
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <OptionalRow onChange={setOptional} optional={optional} disabled={disableOptional}/>
      <ConditionalRenderingRow
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
        onChange={setHasConditionalRendering}
        onFormulaChange={setConditionalRenderingFormula}
        sections={sections}
        id={id}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
      />
      <Row className='form-required-field' style={{ marginTop: 15 }}>
        Field to pull payment amount from:
      </Row>
      <Select
        style={{ width: '100%', margin:'0px 10px'}}
        placeholder='Select Field'
        onChange={onFieldChange}
        value={configProps.linkedField}
        optionFilterProp='label'
      >
        {availableFields.map((item) => (
          <Select.Option
            value={item.id}
            key={item.id}
            label={item.title}
          >
            {item.title}
          </Select.Option>
        ))}
      </Select>
      <Row className='form-required-field' style={{ marginTop: 15 }}>
        Currency:
      </Row>
      <Select
        style={{ width: '100%', margin:'0px 10px'}}
        placeholder="Select Currency"
        onChange={onCurrencyChange}
        value={currency}
        options={CURRENCIES}
      />
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={setFieldTrigger}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
    </div>
  )
};

const preview = ({
  configProps = {},
  previewProps = {},
  responding = false,
  isDisplay,
  id,
  responses = {},
  sections,
  projectId,
  templateId,
  divisions,
  onFieldTrigger,
} = {}) => {
  const {
    optional,
    title = 'Title goes here',
    fieldTrigger,
  } = configProps;
  const {
    succeeded,
  } = previewProps;

  const {
    [id]: {
      succeeded: responseSucceeded,
    } = {},
  } = responses;

  let body;
  if (succeeded || responseSucceeded) {
    body = (
      <Row align='top' style={{ lineHeight: 1 }}>
        <CheckCircleOutlined style={{ color:'green', paddingRight: 10 }}/>
        Payment Collected
      </Row>
    )
  } else if(!isDisplay){
    body = (
      responding ?
      <>Payment can only be collected from the mobile app</>
      : <BorderlessButton
      title='Click to accept payment'
      />
    );
  }

  return (
    <div>
      <TitleRow
        title={title}
        optional={optional}
        filter={
          fieldTrigger && !isDisplay
            ? (
              <FieldTriggerFlag
                sections={sections}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                onFieldTrigger={onFieldTrigger}
              />
            ) : null
        }
      />
      <Row style={{ marginTop: 15 }}>
        {body}
      </Row>
    </div>
  );
}

export default {
  configure,
  preview,
  title:'Payment',
  description:'This field allows users to collect payments.'
};
