import React from 'react';
import { Select, Tooltip } from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { Buckets } from 'ontraccr-common';

import { getIdMap, isNullOrUndefined } from '../helpers/helpers';
import Colors from '../constants/Colors';
import DisplayText from '../common/text/DisplayText';
import BorderlessButton from '../common/buttons/BorderlessButton';

const getName = (ids, map, type) => {
  if (!ids?.length) return `No ${type} Selected`;
  return ids.map((id) => map[id]?.name).join(', ');
};

export const bucketLinkTypeToDisplayName = (t, type, bucketTemplateMap = {}) => {
  switch (type) {
    case 'project-one':
      return t('Project', { count: 1 });
    case 'project-many':
      return t('Project', { count: 0 });
    case 'customer-one':
      return 'Customer';
    case 'customer-many':
      return 'Customers';
    case 'equipment-one':
    case 'equipment-many':
      return 'Equipment';
    default: {
      const templateId = type?.split('-')?.[0];
      const templateName = bucketTemplateMap[templateId]?.name;
      return templateName
        ? `${templateName}(s)`
        : t('Project', { count: 0 });
    }
  }
};

// Gets names of all downstream entities
export const getDownstreamName = ({
  entityType,
  downstreamType,
  downstreamIds,
  projectMap,
  bucketMap,
  bucketTemplateMap,
  t,
  equipmentMap,
}) => {
  if (entityType === 'ProjectGroups') return getName(downstreamIds, projectMap, bucketLinkTypeToDisplayName(t, downstreamType, bucketTemplateMap));
  switch (downstreamType) {
    case 'project-one':
    case 'project-many':
      return getName(
        downstreamIds,
        projectMap,
        bucketLinkTypeToDisplayName(t, downstreamType, bucketTemplateMap),
      );
    case 'equipment-one':
    case 'equipment-many':
      return getName(
        downstreamIds,
        equipmentMap,
        bucketLinkTypeToDisplayName(t, downstreamType, bucketTemplateMap),
      );
    default:
      return getName(
        downstreamIds,
        bucketMap,
        bucketLinkTypeToDisplayName(t, downstreamType, bucketTemplateMap),
      );
  }
};

// Gets names of all upstream entities
export const getUpstreamName = ({
  upstreamType,
  upstreamIds,
  customers,
  bucketMap,
  bucketTemplateMap,
  t,
}) => {
  switch (upstreamType) {
    case 'customer-one':
    case 'customer-many':
      return getName(
        upstreamIds,
        customers,
        bucketLinkTypeToDisplayName(t, upstreamType, bucketTemplateMap),
      );
    default:
      return getName(
        upstreamIds,
        bucketMap,
        bucketLinkTypeToDisplayName(t, upstreamType, bucketTemplateMap),
      );
  }
};

export const getMaxAllowed = ({
  entityType,
  upstreamTypes,
  downstreamTypes,
}) => {
  if (entityType === 'ProjectGroups') return null;

  const types = upstreamTypes ?? downstreamTypes;
  let maxAllowed = null;

  types?.forEach((type) => {
    switch (type) {
      case 'customer-one':
      case 'project-one':
      case 'equipment-one':
        maxAllowed = 1;
        break;
      default:
        break;
    }
  });

  return maxAllowed;
};

export const formatBucketPayload = (payload, upstreamTypes = [], downstreamTypes = []) => {
  const formattedPayload = { ...payload };

  upstreamTypes.concat(downstreamTypes).forEach((type) => {
    if (payload[type] && !Array.isArray(payload[type])) {
      formattedPayload[type] = [payload[type]];
    }
  });

  return formattedPayload;
};

const getSelectedIdsForType = (bucket, type) => {
  switch (type) {
    case 'customers':
      return bucket['customer-one'] ?? bucket['customer-many'] ?? [];
    case 'projects':
      return bucket['project-one'] ?? bucket['project-many'] ?? [];
    case 'equipment':
      return bucket['equipment-one'] ?? bucket['equipment-many'] ?? [];
    default:
      return bucket[type] ?? [];
  }
};

/**
 * @function getBucketSelect - generates a select component for selecting buckets for a given entity
 * @param {array<string>} selectedBuckets - List of selected buckets
 * @param {array<>} buckets - List of all buckets
 * @param {object} customers - Map of all customers
 * @param {object} projectMap - Map of all projects
 * @param {object} bucketMap - Map of all buckets]
 * @param {object} equipmentMap - Map of all equipment
 * @param {string} type - Type of the entity to link to
 * @param {boolean} getDownstream - Whether to get downstream or upstream buckets
 * @returns {React.Component} - Select component
 */
export const getBucketSelect = ({
  selectedBuckets = [],
  buckets,
  customers = {},
  projectMap = {},
  bucketMap = {},
  equipmentMap = {},
  type,
  getDownstream = false,
  isDisplay = false,
}) => {
  const relevantBuckets = [];
  const selectedBucketSet = new Set(selectedBuckets);

  const typeKey = getDownstream ? 'downstreamTypes' : 'upstreamTypes';
  let relevantMap = {};

  switch (type) {
    case 'projects':
      relevantMap = projectMap;
      break;
    case 'customers':
      relevantMap = customers;
      break;
    case 'equipment':
      relevantMap = equipmentMap;
      break;
    default:
      relevantMap = bucketMap;
      break;
  }

  buckets.forEach((bucket) => {
    const bucketTypeSet = new Set(bucket[typeKey]?.map(Buckets.bucketLinkTypeToEntity));
    if (bucketTypeSet.has(type)) {
      const maxAllowed = getMaxAllowed({
        [typeKey]: bucket[typeKey],
      });

      let disabledErrorMessage = null;
      const selectedIds = getSelectedIdsForType(bucket, type);

      if (
        !isNullOrUndefined(maxAllowed)
        && selectedIds?.length >= maxAllowed
        && !selectedBucketSet.has(bucket.id)
      ) {
        disabledErrorMessage = `Bucket is already linked to: ${relevantMap[selectedIds[0]]?.name} and only allows max of ${maxAllowed} link(s).`;
      }

      relevantBuckets.push({
        ...bucket,
        isDisabled: !!disabledErrorMessage,
        errorMessage: disabledErrorMessage,
      });
    }
  });

  if (isDisplay) {
    return {
      BucketSelect: (
        <DisplayText title={getName(selectedBuckets, getIdMap(relevantBuckets), 'Buckets')} />
      ),
      options: relevantBuckets,
    };
  }

  return {
    BucketSelect: (
      <Select
        optionFilterProp="name"
        mode="multiple"
        defaultValue={selectedBuckets}
      >
        {relevantBuckets.map((bucket) => (
          <Select.Option
            key={bucket.id}
            value={bucket.id}
            label={bucket.name}
            disabled={bucket.isDisabled}
          >
            { !!bucket.isDisabled && (
              <Tooltip title={bucket.errorMessage}>
                <WarningOutlined
                  style={{
                    color: Colors.ONTRACCR_DARK_YELLOW,
                  }}
                />
              </Tooltip>
            )}
            {bucket.name}
          </Select.Option>
        ))}
      </Select>
    ),
    options: relevantBuckets,
  };
};

export const getBucketColumns = ({
  onRemove,
  downstreamKey,
} = {}) => {
  const bucketTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
  ];

  if (onRemove) {
    bucketTableColumns.push({
      title: '',
      dataIndex: 'actions',
      render: (_, record) => (
        <BorderlessButton
          style={{ padding: 0, width: 20, color: Colors.ONTRACCR_RED }}
          iconNode={<DeleteOutlined style={{ marginLeft: 0 }} />}
          onClick={() => onRemove(record.id, 'bucket')}
        />
      ),
    });
  }

  return bucketTableColumns;
};
