import React, { useCallback, useState } from 'react';
import { DollarOutlined, SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Row, Select } from 'antd';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowSimpleMappingConfigureDrawer from './WorkflowSimpleMappingConfigureDrawer';
import WorkflowHandle from './WorkflowHandle';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';

import useToggle from '../../common/hooks/useToggle';

import { updateData } from './workflowHelpers';

const getHoverText = (t) => `
Use this step to add a change order to the ${t('Project').toLowerCase()} contract.
`;

const getDisabledText = (t) => `This step requires at least one Text field and one ${t('Project')} Select field`;

const getChangeOrderFields = (t) => [
  { key: 'description', text: 'Description', type: 'text' },
  { key: 'contractAmount', text: 'Contract Amount', type: 'text' },
  { key: 'projectId', text: t('Project'), type: 'dropdown - Projects' },
  { key: 'subcontractId', text: 'Sub-Contract', type: 'dropdown - Sub-Contracts' },
];

const requiredFields = new Set(['description', 'contractAmount', 'projectId']);

const sectionOptions = [
  {
    label: 'Changes',
    value: 'changes',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];

export default function WorkflowUpdateContractNode({
  setElements,
  setDataMap,
  isDisplay,
  name,
  sections = [],
} = {}) {
  return function _({
    id,
    draggable,
    disabled,
    data = {},
  }) {
    const { t } = useTranslation();
    const HOVER_TEXT = getHoverText(t);
    const DISABLED_TEXT = getDisabledText(t);
    const changeOrderFields = getChangeOrderFields(t);
    const title = `Update Contract${disabled ? ' - DISABLED' : ''}`;

    const {
      section: initialSection = 'changes',
      customSection: initialCustomSection = '',
      fieldMappings: initialFieldMappings = {},
    } = data;

    const [selectedSection, setSelectedSection] = useState(initialSection);
    const [customSection, setCustomSection] = useState(initialCustomSection);
    const [fieldMappings, setFieldMappings] = useState(initialFieldMappings);

    const {
      isToggled: isMappingDrawerVisible,
      toggle: toggleMappingDrawer,
    } = useToggle();

    const setMap = {
      section: setSelectedSection,
      customSection: setCustomSection,
      fieldMappings: setFieldMappings,
    };

    const onDataChange = useCallback((key) => (newValue) => {
      if (!setDataMap || !id) return;
      setMap[key]?.(newValue);
      setDataMap(updateData(id, { [key]: newValue }));
    }, [id, setDataMap]);

    return (
      <WorkflowActionNode
        id={id}
        title={title}
        Icon={DollarOutlined}
        type="updateContract"
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : {}}
      >
        {!draggable && (
          <div>
            <Row style={{ marginTop: 10 }}>
              Section to Update:
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onDataChange('section')}
                value={selectedSection}
                optionFilterProp="label"
                options={sectionOptions}
              />
            </Row>
            {selectedSection === 'custom' && (
              <>
                <Row style={{ marginTop: 10 }}>
                  Custom Section Name:
                </Row>
                <Row style={{ margin: '10px 0px' }}>
                  <OnTraccrTextInput
                    onChange={(e) => {
                      const {
                        target: {
                          value: newValue,
                        } = {},
                      } = e;
                      onDataChange('customSection')(newValue);
                    }}
                    value={customSection}
                    disabled={selectedSection !== 'custom'}
                  />
                </Row>
              </>
            )}
            <Row style={{ marginTop: 10 }}>
              <BorderlessButton
                title="Configure"
                style={{ margin: 5 }}
                iconNode={<SettingOutlined />}
                onClick={toggleMappingDrawer}
              />
            </Row>
            <WorkflowSimpleMappingConfigureDrawer
              id={id}
              visible={isMappingDrawerVisible}
              onClose={toggleMappingDrawer}
              onSubmit={toggleMappingDrawer}
              sourceName={name}
              sourceSections={sections}
              fieldMappings={fieldMappings}
              onFieldMappingsChange={onDataChange('fieldMappings')}
              data={data}
              setDataMap={setDataMap}
              fields={changeOrderFields}
              requiredFields={requiredFields}
            />
            <WorkflowHandle type="target" position="top" disabled={isDisplay} />
          </div>
        )}
      </WorkflowActionNode>
    );
  };
}
