import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { TaskHelpers } from 'ontraccr-common';

import { ReportColumn } from './ReportColumnHeader';
import ReportSkeleton from './ReportSkeleton';
import getEmployeeProjectFilters from './EmployeeProjectFilters';

import { getNames } from '../../helpers/helpers';
import { msToHours } from '../../helpers/time';

import {
  filterOnCols,
  sortAlphabetically,
  toFixed,
  getEstimatedHours,
  prepareReportData,
  getDateKey,
  isValidTaskHelper,
} from '../reportHelpers';
import { formatProjectLabelFromCompanySettings } from '../../projects/projectHelpers';

const allColumns = [
  ReportColumn('Project', {
    fixed: 'left',
  }),
  ReportColumn('Phase'),
  ReportColumn('Cost Code', {
    key: 'costcode',
    dataIndex: 'costcode',
  }),
  ReportColumn('Est. Hrs', {
    key: 'estimatedHours',
    dataIndex: 'estimatedHours',
  }),
  ReportColumn('Act. Hrs', {
    key: 'actualHours',
    dataIndex: 'actualHours',
  }),
  ReportColumn('Rem. Hrs', {
    key: 'remainingHours',
    dataIndex: 'remainingHours',
  }),
  ReportColumn('% Hrs Used', {
    key: 'hoursUsed',
    dataIndex: 'hoursUsed',
  }),
];

const dateFormat = 'MMM D';

class ProjectStatus extends React.Component {
  constructor(props) {
    super(props);
    const {
      height = 450,
      costcodes = [],
      projects = [],
      phases = [],
      onColumnsChanged,
      columns,
    } = this.props;

    this.checkedColumns = new Set(allColumns.map((col) => col.key).filter(filterOnCols(columns)));

    this.state = {
      columns: allColumns.filter((col) => this.checkedColumns.has(col.key)),
      height,
      phases: new Set(getNames(phases).concat('None')),
      projects: new Set(getNames(projects).concat('None')),
      costcodes: new Set(costcodes.filter((cc) => cc.active !== 0).map((cc) => cc.code).concat('None')),
      enabledFilters: {
        project: true,
        costcode: true,
        phase: true,
      },
      showProgressModal: false,
    };
    onColumnsChanged(this.state.columns);

    this.onResize = this.resize.bind(this);
    this.onFilterToggle = this.toggleFilter.bind(this);

    const {
      costcodeIdMap,
      phaseMap,
      unphasedHours,
      projectIdMap,
    } = prepareReportData({ costcodes, projects, phases });
    this.costcodeIdMap = costcodeIdMap;
    this.phaseMap = phaseMap;
    this.unphasedHours = unphasedHours;
    this.projectIdMap = projectIdMap;
  }

  componentDidMount() {
    const {
      onRef,
    } = this.props;
    onRef(this);
    this.resize();
  }

  componentDidUpdate(prevProps) {
    const {
      height: prevHeight,
    } = prevProps;
    const {
      height,
    } = this.props;
    if (height !== prevHeight) {
      this.setState({
        height,
      });
    }
  }

  componentWillUnmount() {
    const {
      onRef,
    } = this.props;
    onRef(null);
  }

  getDates() {
    const {
      dateRange = [moment(), moment()],
    } = this.props;

    const startDate = moment.isMoment(dateRange[0])
      ? dateRange[0].clone() : moment();
    const endDate = dateRange[1];
    const dates = [];
    while (startDate.isBefore(endDate)) {
      dates.push(startDate.format(dateFormat));
      startDate.add(1, 'd');
    }
    return dates;
  }

  getColFilters() {
    return [{
      title: 'Estimated Hrs',
      key: 'estimatedHours',
      checked: this.checkedColumns.has('estimatedHours'),
    }, {
      title: 'Actual Hrs',
      key: 'actualHours',
      checked: this.checkedColumns.has('actualHours'),
    }, {
      title: 'Remaining Hrs',
      key: 'remainingHours',
      checked: this.checkedColumns.has('remainingHours'),
    }, {
      title: '% Hrs Used',
      key: 'hoursUsed',
      checked: this.checkedColumns.has('hoursUsed'),
    }];
  }

  getRowFilters() {
    const {
      costcodes = [],
      projects = [],
      phases = [],
      settings = {},
      t,
    } = this.props;
    const {
      projects: selectedProjects,
    } = this.state;

    const relevantCostcodes = costcodes.filter((costcode) => {
      if (!costcode.active) return false;
      if (selectedProjects.size === 0) return !costcode.projectId;
      const project = this.projectIdMap[costcode.projectId];
      return project && selectedProjects.has(project.name);
    });

    const relevantPhases = selectedProjects.size === 0
      ? phases : phases.filter((phase) => {
        const project = this.projectIdMap[phase.projectId];
        return project && selectedProjects.has(project.name);
      });

    return getEmployeeProjectFilters({
      onFilterToggle: (type, checked) => {
        this.setState({
          [type]: checked,
        });
      },
      onCheckChanged: (type, checked) => {
        this.updateRowFilters({
          [type]: checked,
          [`${type}Description`]: checked,
        });
      },
      projects,
      phases: relevantPhases,
      costcodes: relevantCostcodes,
      showEmployee: false,
      projectCheckDisabled: true,
      checkedColumns: this.checkedColumns,
      settings,
      t,
    });
  }

  getAggregateKey(projectName, phaseName, costcodeName) {
    const {
      enabledFilters: {
        costcode,
        project,
        phase,
      },
    } = this.state;

    const keys = [projectName, phaseName, costcodeName];
    const filters = [costcode, phase, project];
    const filterNames = ['costcode', 'phase', 'project'];
    for (const index in filters) {
      const filter = filters[index];
      if (filter) return { key: keys.join('-'), lowestFilter: filterNames[index] };
      keys.pop();
    }
    return { key: 'All', lowestFilter: 'All' };
  }

  updateRowFilters(newFilters) {
    this.setState({
      enabledFilters: {
        ...this.state.enabledFilters,
        ...newFilters,
      },
    });
    for (const key in newFilters) {
      this.toggleFilter({ key }, newFilters[key]);
    }
  }

  toggleFilter(item, checked) {
    const {
      key: itemKey,
    } = item;

    if (checked) {
      this.checkedColumns.add(itemKey);
    } else {
      this.checkedColumns.delete(itemKey);
    }
    this.setState({
      columns: allColumns.filter((col) => this.checkedColumns.has(col.key)),
    });
  }

  resize() {
    const {
      height,
    } = this.props;
    this.setState({
      height,
    });
  }

  failsFilter(filters, item, key = 'name') {
    if (!item && !filters.has('None')) return true;
    if (item && !filters.has(item[key])) return true;
    return false;
  }

  formatData() {
    const {
      dateRange,
      users = [],
      onColumnsChanged,
      onDataChanged,
      onFiltersChanged,
      timeEntryUserMap = {},
      settings = {},
    } = this.props;

    const {
      projects,
      costcodes,
      phases,
      columns,
    } = this.state;

    const [startDate, endDate] = dateRange;
    const data = [];
    const aggregator = {};

    users.forEach((user) => {
      const tasks = timeEntryUserMap[user?.id] ?? [];
      tasks.forEach((task) => {
        const isValidTask = isValidTaskHelper({
          filters: {
            projects,
            costcodes,
            phases,
          },
          dateRange,
          projectIdMap: this.projectIdMap,
          costcodeIdMap: this.costcodeIdMap,
          phaseMap: this.phaseMap,
          checkPhase: true,
          settings,
        })(task);

        if (!isValidTask) return;

        const project = this.projectIdMap[task.projectId];
        const phase = this.phaseMap[task.phaseId];
        const costcode = this.costcodeIdMap[task.costcodeId];
        const {
          name,
          number,
        } = project || {};

        const projectLabel = formatProjectLabelFromCompanySettings({ name, number, settings });

        const costCode = costcode ? costcode.code : 'None';
        const projectName = project ? projectLabel : 'None';
        const phaseName = phase ? phase.name : 'None';
        const { key: aggKey, lowestFilter } = (
          this.getAggregateKey(projectName, phaseName, costCode)
        );

        if (!(aggKey in aggregator)) {
          aggregator[aggKey] = {
            project: project ? projectLabel : null,
            costcode: costcode ? costcode.code : null,
            costcodeDescription: costcode ? costcode.description : null,
            estimatedHours: getEstimatedHours({
              lowestFilter, project, phase, costcode, unphasedHours: this.unphasedHours,
            }),
            actualHours: 0,
            phase: phase ? phase.name : null,
            phaseDescription: phase ? phase.description : null,
            completed: [0.0],
            startDate,
            progressGraph: {},
            key: aggKey,
          };
        }
        const ourAgg = aggregator[aggKey];
        const runtimes = TaskHelpers.getRuntimes(task, false);
        const runTime = runtimes.regularTime + runtimes.overtime + runtimes.doubleOT;
        const endDateKey = getDateKey(task, dateFormat);
        const totalHours = msToHours(runTime);
        ourAgg.actualHours += totalHours;
        if (ourAgg.estimatedHours > 0) {
          const prevValue = ourAgg.progressGraph[endDateKey] || 0;
          ourAgg.progressGraph[endDateKey] = prevValue
            + (ourAgg.actualHours / ourAgg.estimatedHours);
        }
      });
    });

    Object.values(this.costcodeIdMap).forEach((item) => {
      if (!(item.projectId)) return;
      const project = this.projectIdMap[item.projectId];
      if (this.failsFilter(projects, project)) return;

      const costcode = this.costcodeIdMap[item.id];
      if (this.failsFilter(costcodes, costcode, 'code')) return;

      const phase = this.phaseMap[item.phaseId];

      if (this.failsFilter(phases, phase)) return;

      const {
        name,
        number,
      } = project || {};

      const projectLabel = formatProjectLabelFromCompanySettings({ name, number, settings });

      const costCode = costcode ? costcode.code : 'None';
      const projectName = project ? projectLabel : 'None';
      const phaseName = phase ? phase.name : 'None';

      const { key: aggKey, lowestFilter } = this.getAggregateKey(projectName, phaseName, costCode);

      if (!(aggKey in aggregator)) {
        aggregator[aggKey] = {
          project: project ? projectLabel : null,
          costcode: costcode ? costcode.code : null,
          estimatedHours: getEstimatedHours({
            lowestFilter, project, phase, costcode, unphasedHours: this.unphasedHours,
          }),
          actualHours: 0,
          phase: phase ? phase.name : null,
          completed: [0.0],
          startDate,
          progressGraph: {},
          key: aggKey,
        };
      }
    });

    // TODO: REFACTOR
    Object.keys(aggregator).forEach((key) => {
      const hours = aggregator[key];
      data.push({
        ...hours,
        estimatedHours: toFixed(hours.estimatedHours, false),
        actualHours: toFixed(hours.actualHours, false),
        remainingHours: toFixed(Math.max(0, hours.estimatedHours - hours.actualHours), false),
        hoursUsed: hours.estimatedHours && hours.estimatedHours !== 0
          ? `${toFixed(100 * (hours.actualHours / hours.estimatedHours), false)}%` : null,
      });
    });
    const res = [...data].sort(sortAlphabetically());
    onColumnsChanged(columns);
    onDataChanged(res);
    onFiltersChanged({
      users,
      projects,
      costcodes,
      phases,
    });
    return res;
  }

  render() {
    const {
      renderColFilters,
      renderRowFilters,
    } = this.props;
    const {
      columns = [],
      height,
    } = this.state;
    const data = this.formatData();

    return (
      <ReportSkeleton
        data={data}
        columns={columns}
        height={height}
        colFilters={renderColFilters(this.getColFilters())}
        rowFilters={renderRowFilters(this.getRowFilters())}
      />
    );
  }
}

export default withTranslation()(ProjectStatus);
