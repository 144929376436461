import {
  GET_MATERIALS,
  CREATE_MATERIALS,
  ARCHIVE_MATERIALS,
  UPDATE_MATERIAL,
  DELETE_MATERIAL,
  BULK_UPDATE_MATERIALS,
  CREATE_MATERIALS_FOLDER,
  UPDATE_MATERIALS_FOLDER,
  MOVE_MATERIALS,
  GET_MATERIAL_LOCATIONS,
  MASS_UPLOAD_MATERIALS,
  MASS_ARCHIVE_MATERIALS,
  MASS_DELETE_MATERIALS,
  GET_MATERIAL_UPDATE_HISTORY,
  MATERIAL_DEBIT,
  COPY_MATERIALS,
  GET_MATERIAL_ACCESS_LIST,
  GRANT_MATERIAL_ACCESS,
  REVOKE_MATERIAL_ACCESS,
  GET_QUICKBOOKS_MATERIALS,
} from '../../state/actionTypes';

import { updateObject, removeFromObject } from '../../helpers/stateHelpers';
import { toTitleCase } from '../../helpers/helpers';

const initialState = {
  materials: {},
  lastUpdated: 0,
  lastAttempt: 0,
  updateHistory: {},
  materialAccessLists: {},
  quickBooksMaterials: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_MATERIALS: {
      const {
        materials: oldMaterials = {},
      } = state;
      const {
        payload: {
          materials = {},
          lastUpdated = 0,
        },
      } = action;
      return {
        ...state,
        materials: {
          ...oldMaterials,
          ...materials,
        },
        lastUpdated,
      };
    }
    case CREATE_MATERIALS_FOLDER:
    case CREATE_MATERIALS: {
      const {
        payload: {
          material = {},
        } = {},
      } = action;
      const newMaterials = { ...state.materials };
      newMaterials[material.id] = {
        ...material,
        active: 1,
      };
      return { ...state, materials: newMaterials };
    }
    case MASS_UPLOAD_MATERIALS: {
      const {
        payload: {
          materials = [],
        } = {},
      } = action;
      const newMaterials = { ...state.materials };
      materials.forEach((material) => {
        newMaterials[material.id] = {
          ...material,
          active: 1,
        };
      });
      return { ...state, materials: newMaterials };
    }
    case ARCHIVE_MATERIALS: {
      const {
        payload: {
          id,
          active,
        } = {},
      } = action;
      const newMaterials = updateObject({
        oldObject: state.materials,
        id,
        newData: { active: active ? 1 : 0 },
      });
      return { ...state, materials: newMaterials };
    }
    case MASS_ARCHIVE_MATERIALS: {
      const {
        payload: {
          materialIds = [],
        } = {},
      } = action;
      const newMaterials = { ...state.materials };
      materialIds.forEach((materialId) => {
        const {
          [materialId]: oldState = {},
        } = newMaterials;
        newMaterials[materialId] = {
          ...oldState,
          active: 0,
        };
      });
      return { ...state, materials: newMaterials };
    }
    case MASS_DELETE_MATERIALS: {
      const {
        payload: {
          materialIds = [],
        } = {},
      } = action;
      const newMaterials = { ...state.materials };
      materialIds.forEach((materialId) => {
        delete newMaterials[materialId];
      });
      return { ...state, materials: newMaterials };
    }
    case UPDATE_MATERIALS_FOLDER:
    case UPDATE_MATERIAL: {
      const {
        payload: {
          id,
          newData = {},
        } = {},
      } = action;
      const newMaterials = updateObject({
        oldObject: state.materials,
        id,
        newData,
      });
      return { ...state, materials: newMaterials };
    }
    case DELETE_MATERIAL: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const newMaterials = removeFromObject({
        oldObject: state.materials,
        id,
      });
      return { ...state, materials: newMaterials };
    }
    case BULK_UPDATE_MATERIALS: {
      const {
        payload: {
          materialIds = [],
          change,
          type,
          costType,
        } = {},
      } = action;
      const key = costType === 'material' ? type : `${costType}${toTitleCase(type)}`;
      const newMaterials = { ...state.materials };
      materialIds.forEach((id) => {
        const {
          [id]: existingMaterial = {},
        } = newMaterials;
        const {
          [key]: existingValue,
          isFolder,
          isAssembly,
          shouldAutoCalcCost,
          shouldAutoCalcLabourCost,
        } = existingMaterial;
        if (isFolder || (key === 'markup' && isAssembly)) return;
        newMaterials[id] = {
          ...existingMaterial,
          [key]: existingValue + change,
          shouldAutoCalcCost: type === 'cost' && costType === 'material' ? false : shouldAutoCalcCost,
          shouldAutoCalcLabourCost: type === 'cost' && costType === 'labour' ? false : shouldAutoCalcLabourCost,
        };
      });
      return { ...state, materials: newMaterials };
    }
    case MOVE_MATERIALS: {
      const {
        payload: {
          materialIds = [],
          groupId,
        } = {},
      } = action;
      const newMaterials = { ...state.materials };
      materialIds.forEach((id) => {
        const {
          [id]: existingMaterial = {},
        } = newMaterials;
        newMaterials[id] = {
          ...existingMaterial,
          groupId,
        };
      });
      return { ...state, materials: newMaterials };
    }
    case COPY_MATERIALS: {
      const {
        materials: oldMaterials = {},
      } = state;
      const {
        payload: {
          materials: updatedMaterials = {},
          lastUpdated = 0,
        },
      } = action;
      return {
        ...state,
        materials: {
          ...oldMaterials,
          ...updatedMaterials,
        },
        lastUpdated,
      };
    }
    case GET_MATERIAL_LOCATIONS: {
      const {
        payload: {
          id,
          locations = [],
        } = {},
      } = action;
      if (!(id in state.materials)) return state;
      const newMaterials = { ...state.materials };
      newMaterials[id].locations = locations;
      return { ...state, materials: newMaterials };
    }
    case GET_MATERIAL_UPDATE_HISTORY: {
      const {
        payload: {
          id,
          history = [],
        } = {},
      } = action;
      const { updateHistory: oldHistory = {} } = state;
      const newHistory = { ...oldHistory };
      newHistory[id] = history;
      return { ...state, updateHistory: newHistory };
    }
    case MATERIAL_DEBIT: {
      const {
        payload: {
          materialDiffMap: materialDebitMap = {},
          newMaterialLocations = [],
          quantityType,
        } = {},
      } = action;
      const { materials: oldMaterials = {} } = state;
      const newMaterials = { ...oldMaterials };

      const newLocationMaterialMap = {};
      newMaterialLocations.forEach((location) => {
        const { materialId } = location;
        if (!newLocationMaterialMap[materialId]) {
          newLocationMaterialMap[materialId] = [];
        }
        newLocationMaterialMap[materialId].push(location);
      });

      Object.keys(newLocationMaterialMap).forEach((materialId) => {
        const {
          [materialId]: oldMaterial = {},
        } = newMaterials;
        const oldLocations = oldMaterial.locations || [];
        newMaterials[materialId] = {
          ...oldMaterial,
          locations: [
            ...oldLocations,
            ...newLocationMaterialMap[materialId],
          ],
        };
      });

      Object.keys(materialDebitMap).forEach((materialId) => {
        const {
          [materialId]: oldMaterial = {},
        } = newMaterials;
        const oldQuantity = oldMaterial[quantityType] ?? 0;
        newMaterials[materialId] = {
          ...oldMaterial,
          [quantityType]: Math.max(oldQuantity - materialDebitMap[materialId], 0),
        };
      });
      return { ...state, materials: newMaterials };
    }
    case GET_MATERIAL_ACCESS_LIST: {
      const {
        payload: {
          materialId,
          accessList = [],
        } = {},
      } = action;
      const {
        materialAccessLists = {},
      } = state;
      const newAccessLists = { ...materialAccessLists };
      newAccessLists[materialId] = new Set(accessList);
      return {
        ...state,
        materialAccessLists: newAccessLists,
      };
    }
    case GRANT_MATERIAL_ACCESS: {
      const {
        payload: {
          materialId,
          userIds = [],
        } = {},
      } = action;
      const {
        materialAccessLists = {},
      } = state;
      const newAccessLists = { ...materialAccessLists };
      const {
        [materialId]: materialAccessSet = new Set(),
      } = newAccessLists;
      userIds.forEach((userId) => materialAccessSet.add(userId));
      newAccessLists[materialId] = materialAccessSet;
      return {
        ...state,
        materialAccessLists: newAccessLists,
      };
    }
    case REVOKE_MATERIAL_ACCESS: {
      const {
        payload: {
          materialId,
          userIds = [],
        } = {},
      } = action;
      const {
        materialAccessLists = {},
      } = state;
      const newAccessLists = { ...materialAccessLists };
      const {
        [materialId]: materialAccessSet = new Set(),
      } = newAccessLists;
      userIds.forEach((userId) => materialAccessSet.delete(userId));
      newAccessLists[materialId] = materialAccessSet;
      return {
        ...state,
        materialAccessLists: newAccessLists,
      };
    }
    case GET_QUICKBOOKS_MATERIALS: {
      const {
        payload: {
          data: quickBooksMaterials,
        } = {},
      } = action;
      return {
        ...state,
        quickBooksMaterials,
      };
    }
    default:
      return state;
  }
};
