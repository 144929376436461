import analytics from '../../helpers/Analytics';
import {
  GET_ALL_APPROVALS,
} from '../../state/actionTypes';
import ClockInService from './clockin.service';

export default {};
export const getApprovals = (source = '') => async (dispatch) => {
  analytics.track('GetApprovals', { source });
  const { data } = await ClockInService.getAllApprovals();
  if (!data) return false;
  dispatch({
    type: GET_ALL_APPROVALS,
    payload: {
      approvals: data,
    },
  });
  return true;
};
