import moment from 'moment';
import { DateTime } from 'luxon';
import { Common } from 'ontraccr-common';

import { getIdMap } from '../helpers/helpers';
import { timeIsBetween } from '../helpers/time';

import { formatProjectLabelFromCompanySettings } from '../projects/projectHelpers';

export default {};

export const filterOnCols = (columns) => (col) => !columns
  || (columns.has && columns.has(col))
  || columns.length === 0 || (columns.includes && columns.includes(col));

export const getUnique = (arr, key) => Array.from(
  new Set(
    arr.map((item) => (item ? item[key] : {})),
  ),
);

export const sortAlphabetically = (ascending = true) => (a, b) => {
  // equal items sort equally
  if (a && b && a.project === b.project) {
    return 0;
  }
  // nulls sort after anything else
  if (a.project === null) {
    return 1;
  }
  if (b.project === null) {
    return -1;
  }
  // otherwise, if we're ascending, lowest sorts first
  if (ascending) {
    return a.project < b.project ? -1 : 1;
  }
  // if descending, highest sorts first

  return a.project < b.project ? 1 : -1;
};

export const toFixed = (val, currency = true) => (val !== null && val !== undefined ? val.toLocaleString(undefined, currency ? { style: 'currency', currency: 'USD' } : {}) : null);

export const getEstimatedHours = ({
  lowestFilter, project, phase, costcode, unphasedHours = {},
}) => {
  let estimatedHours = 0;
  if (lowestFilter === 'project') {
    estimatedHours = project ? project.labourHours : 0;
  } else if (lowestFilter === 'phase') {
    if (phase) {
      estimatedHours = phase.hours;
    } else if (project && project.id && project.id in unphasedHours) {
      estimatedHours = unphasedHours[project.id];
    } else {
      estimatedHours = 0;
    }
  } else if (lowestFilter === 'costcode') {
    estimatedHours = costcode ? costcode.hours : 0;
  }
  return estimatedHours;
};

export const prepareReportData = ({ costcodes = [], projects = [], phases = [] }) => {
  const phasedCostcodeSet = new Set();
  const costcodeIdMap = getIdMap(costcodes);
  const projectIdMap = getIdMap(projects);
  const phaseMap = {};
  phases.forEach(({
    name, description, id, hours = 0, costcodeId,
  }) => {
    if (!(id in phaseMap)) {
      phaseMap[id] = {
        name,
        description,
        hours: hours || 0,
      };
    } else {
      phaseMap[id].hours += hours;
    }
    phasedCostcodeSet.add(costcodeId);
    if (costcodeId in costcodeIdMap) {
      costcodeIdMap[costcodeId].hours = hours;
      costcodeIdMap[costcodeId].phaseId = id;
    }
  });

  const unphasedHours = {};
  costcodes.forEach((costcode) => {
    const { id, projectId, hours } = costcode;
    if (!phasedCostcodeSet.has(id) && projectId) {
      const {
        [projectId]: projectUnphased = 0,
      } = unphasedHours;
      unphasedHours[projectId] = projectUnphased + hours;
    }
  });
  return {
    projectIdMap, phaseMap, costcodeIdMap, unphasedHours,
  };
};

export const getDivision = ({
  userDivisions = {},
  divisionMap = {},
  project = {},
  user = {},
}) => {
  if (project.divisionId in divisionMap) {
    const {
      [project.divisionId]: {
        name,
      } = {},
    } = divisionMap;
    return name;
  }
  if (user.id in userDivisions) {
    const {
      [user.id]: divisions = [],
    } = userDivisions;
    const ourDivision = divisions.find((divisionId) => divisionId in divisionMap);
    const {
      [ourDivision]: {
        name,
      } = {},
    } = divisionMap;
    return name;
  }
  return null;
};

export const getDateKey = (task, format = 'MMM D') => {
  if (!task?.endTime) return '';
  return moment(task.endTime).tz(task.timezone ?? DateTime.local().zoneName).format(format);
};

export const failsFilter = (filters, item, key = 'name') => {
  if (!item && !filters.has('None')) return true;
  return item && !filters.has(item[key]);
};

const failsProjectFilter = (filters, item, settings) => {
  if (!item) return !filters.has('None');
  const label = formatProjectLabelFromCompanySettings({
    name: item.name, number: item.number, settings,
  });
  return !filters.has(label);
};

export const isValidTaskHelper = ({
  filters = {},
  dateRange = [],
  projectIdMap = {},
  costcodeIdMap = {},
  phaseMap = {},
  checkPhase,
  workType,
  settings = {},
}) => (task) => {
  const {
    projects: filterProject,
    costcodes: filterCostcodes,
    phases: filterPhases,
  } = filters;

  if (workType && !workType.has(task.type)) return false;
  const [startDate, endDate] = dateRange;


  if (
    (!task.startTime || !task.endTime)
    && (!task.ptoStartTime || !task.ptoEndTime)
  ) return false;

  const useRegularTimes = task.endTime && task.startTime;

  const taskEndTime = useRegularTimes ? task.endTime : task.ptoEndTime;
  const taskStartTime = useRegularTimes ? task.startTime : task.ptoStartTime;
  const taskToCheck = {
    ...task,
    endTime: taskEndTime,
    startTime: taskStartTime,
  };

  if (!(timeIsBetween(taskToCheck, startDate, endDate, settings?.useEndDateOvernight))) return false;

  const project = projectIdMap[task.projectId];
  if (task.projectId && !project) return false; // Not part of our selected divisions
  if (failsProjectFilter(filterProject, project, settings)) return false;

  const costcode = costcodeIdMap[task.costcodeId];
  if (failsFilter(filterCostcodes, costcode, 'code')) return false;

  if (checkPhase) {
    const phase = phaseMap[task.phaseId];

    if (failsFilter(filterPhases, phase ? { name: phase?.name } : null)) return false;
  }

  return true;
};

export const getProjectNames = (projects, settings) => (
  projects.map((project) => (
    formatProjectLabelFromCompanySettings({ name: project.name, number: project.number, settings })
  ))
);

const isGoodTime = (startTime, endTime) => (
  !Common.isNullOrUndefined(startTime)
  && !Common.isNullOrUndefined(endTime)
);

export const isGoodTask = (task) => (
  isGoodTime(task.startTime, task.endTime)
  || isGoodTime(task.otStartTime, task.otEndTime)
  || isGoodTime(task.breakStartTime, task.breakEndTime)
  || isGoodTime(task.doubleOTStartTime, task.doubleOTEndTime)
  || isGoodTime(task.ptoStartTime, task.ptoEndTime)
);