import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import fieldTypes from '../formFields.types';

function DynamicAttributePreview(props) {
  const {
    configProps: {
      title,
      optional,
      dataType,
      fieldId,
    } = {},
    ...rest
  } = props;

  const projectCustomFields = useSelector((state) => state.projects.customFieldTemplate);
  const ccCustomFields = useSelector((state) => state.costcodes.customFieldTemplate);
  const userCustomFields = useSelector((state) => state.users.customFieldTemplate);

  const ourField = useMemo(() => {
    if (!fieldId || !dataType) return {};
    let fields = [];
    switch (dataType) {
      case 'projects':
        fields = projectCustomFields?.fields ?? [];
        break;
      case 'costcodes':
        fields = ccCustomFields?.fields ?? [];
        break;
      case 'users':
        fields = userCustomFields?.fields ?? [];
        break;
      default:
        fields = [];
    }
    return fields.find((field) => field.id === fieldId);
  }, [fieldId, dataType, projectCustomFields, userCustomFields, ccCustomFields]);

  const {
    [ourField?.selectedType]: {
      preview = () => null,
    } = {},
  } = fieldTypes;

  return preview({
    ...rest,
    configProps: {
      ...ourField?.configProps ?? {},
      title,
      optional,
    },
  });
}

/* eslint-disable react/forbid-prop-types */
DynamicAttributePreview.propTypes = {
  configProps: PropTypes.object,
};

DynamicAttributePreview.defaultProps = {
  configProps: {},
};

export default DynamicAttributePreview;
