/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useEffect, useMemo, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PropTypes } from 'prop-types';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import CardGrid from '../common/cardGrid/cardGrid';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';

import EquipmentCheckModal from './EquipmentCheckModal';
import EquipmentAddView from './EquipmentAddView';
import EquipmentCard from './EquipmentCard';
import { getDefaultFilters, NONE_EQUIPMENT_TYPE } from './equipmentFilters';

import { compareArrays, getIdMap, sortByLocalCompare } from '../helpers/helpers';

import {
  getEquipment,
  createEquipment,
  archiveEquipment,
  deleteEquipment,
  updateEquipment,
  checkIn,
  checkOut,
  getStatuses,
  getEquipmentTypes,
} from './state/equipment.actions';

import { getProjectEquipment } from '../projects/state/projects.actions';
import { getCardLinks } from '../boards/state/boards.actions';
import { getLabels } from '../labels/state/labels.actions';
import {
  getEmails,
} from '../emails/state/email.actions';
import { getUserLabels } from '../users/state/users.actions';

import Permissions from '../auth/Permissions';
import { getErrorsFromResponses, prepareResponsePayload } from '../forms/ResponderHelpers';

import { getDisplayColumns } from './equipment.helpers';
import { getTemplates } from '../forms/state/forms.actions';
import { getBuckets } from '../buckets/state/buckets.actions';
import useToggle from '../common/hooks/useToggle';

import EquipmentFilterDrawer from './EquipmentFilterDrawer';

const CRUMBS = [{
  text: 'Equipment',
  icon: 'equipment',
}];

const getField = (eq) => {
  if (!eq.code) return eq.name;
  return `${eq.code}${eq.name}`;
};

const searchTieBreaker = (searchTerm, a, b) => sortByLocalCompare('name')(a, b);

export const attachCustomDataToEquipment = ({
  equipment,
  customFields,
}) => {
  const responses = equipment.customData;

  const {
    errorMap,
  } = getErrorsFromResponses({ sections: customFields, responses });
  if (Object.keys(errorMap).length > 0) return { error: true, errorMap };
  const { responses: preparedResponses } = prepareResponsePayload({
    sections: customFields,
    responses,
  });

  return {
    ...equipment,
    customData: preparedResponses,
  };
};

export const attachQRCodeToEquipment = (equipment) => {
  const {
    qrCodeValue,
    qrCodeFormat,
    ...rest
  } = equipment;

  return {
    ...rest,
    qrCode: {
      value: qrCodeValue,
      format: qrCodeFormat,
    },
  };
};

function Equipment({ history }) {
  const hasWritePerms = Permissions.has('EQUIPMENT_WRITE');
  const hasReadPerms = Permissions.has('EQUIPMENT_READ');
  const dispatch = useDispatch();
  const {
    state: {
      targetId: locationStateId,
    } = {},
    pathname,
  } = useLocation();
  let cardGridRef = useRef(null);
  const {
    equipment = [],
    equipmentProjects = {},
    equipmentFiles = {},
    statuses = {},
    equipmentTypes = [],
  } = useSelector((state) => state.equipment);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const labels = useSelector((state) => state.labels);
  const divisions = useSelector((state) => state.settings.divisions);

  const statusIds = useMemo(() => Object.keys(statuses), [statuses]);
  const ourLabels = useMemo(() => labels.filter((label) => label.type === 'equipment'), [labels]);
  const equipmentTypesWithNone = useMemo(() => [
    {
      id: NONE_EQUIPMENT_TYPE,
      name: 'None',
    },
    ...equipmentTypes.filter((eq) => !eq.isDefault),
  ], [equipmentTypes]);

  const [activeFilters, setActiveFilters] = useState(getDefaultFilters({
    labels: ourLabels,
    statuses,
    equipmentTypes: equipmentTypesWithNone,
  }));
  const [hasModifiedFilters, setHasModifiedFilters] = useState(false);
  const [selected, setSelected] = useState({});
  const [errors, setErrors] = useState({});
  const [customFields, setCustomFields] = useState([]);
  const [activeSort, setActiveSort] = useState('Name: Ascending');
  const [isListView, setIsListView] = useState(window.localStorage.getItem('equipmentViewType') === 'true');

  const {
    isToggled: filterViewOpen,
    toggle: toggleFilterView,
  } = useToggle();

  const closeModal = useCallback(() => setSelected({}), []);
  const onFilter = useCallback((newFilters) => {
    setActiveFilters(newFilters);
    setHasModifiedFilters(true);
    toggleFilterView();
  }, [activeFilters]);

  const onAdd = useCallback((newEquipment) => {
    const formattedEquipment = attachCustomDataToEquipment({
      equipment: newEquipment,
      customFields,
    });

    if (formattedEquipment.error) {
      setErrors(formattedEquipment.errorMap);
      return false;
    }

    setErrors({});

    const equipmentWithQR = attachQRCodeToEquipment(formattedEquipment);
    return dispatch(createEquipment(equipmentWithQR));
  }, [customFields]);

  const onEdit = useCallback((id, data) => {
    const ourEquipment = equipment.find((eq) => eq.id === id);
    if (!ourEquipment) return true;
    const {
      [id]: oldFiles = [],
    } = equipmentFiles;
    const {
      [id]: ourProjects = [],
    } = equipmentProjects;
    const oldSet = new Set(ourProjects);

    const formattedData = attachCustomDataToEquipment({
      equipment: data,
      customFields,
    });

    if (formattedData.error) {
      setErrors(formattedData.errorMap);
      return false;
    }

    const payload = { ...formattedData };
    const {
      projectIds = [],
      files: newFiles = [],
    } = payload;
    const addedProjects = [];

    projectIds.forEach((projectId) => {
      if (!oldSet.has(projectId)) {
        addedProjects.push(projectId);
      } else {
        oldSet.delete(projectId);
      }
    });

    const deletedProjects = Array.from(oldSet);
    delete payload.projectIds;

    const {
      removed: removedFiles,
      added: addedFiles,
    } = compareArrays(oldFiles, newFiles);

    const oldLabels = ourEquipment.labels ?? [];
    let newLabels = payload.labels;
    if (!newLabels) newLabels = oldLabels;

    const {
      removed: removedLabels,
      added: addedLabels,
    } = compareArrays(
      oldLabels,
      newLabels,
    );

    delete payload.labels;
    setErrors({});

    const equipmentWithQR = attachQRCodeToEquipment(payload);

    return dispatch(updateEquipment(id, {
      ...equipmentWithQR,
      addedProjects,
      deletedProjects,
      addedFiles,
      removedFiles: removedFiles.map((file) => file.id),
      addedLabels: addedLabels.map((label) => ({ labelId: label.id, title: label.title })),
      removedLabels: removedLabels.map((label) => label.id),
    }));
  }, [customFields, equipment, equipmentProjects, equipmentFiles]);

  const onArchive = useCallback((archivedEq) => {
    const mode = archivedEq.active ? 'Archive' : 'Activate';

    CustomConfirmModal({
      title: `${mode} Equipment '${archivedEq.name}'?`,
      okText: mode,
      cancelText: 'Cancel',
      onOk() {
        dispatch(archiveEquipment(archivedEq.id, !archivedEq.active));
      },
    });
  }, [dispatch]);

  const onDelete = useCallback((deletedEq) => {
    CustomConfirmModal({
      title: `Delete Equipment '${deletedEq.name}'?`,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        dispatch(deleteEquipment(deletedEq.id));
      },
    });
  }, [dispatch]);

  const onCheckIn = useCallback(async ({ id, payload }) => {
    const {
      projectId,
      location,
    } = payload;
    if (
      (selected?.currentProjectId && selected?.currentProjectId === projectId)
      || (selected?.currentLocationText && selected?.currentLocationText === location)
    ) {
      setSelected({});
      return;
    }
    if (await dispatch(checkIn(id, payload))) {
      setSelected({});
    }
  }, [dispatch, selected]);

  const onCheckOut = useCallback(async ({ id, payload }) => {
    if (await dispatch(checkOut(id, payload))) {
      setSelected({});
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEquipment());
    dispatch(getProjectEquipment());
    dispatch(getCardLinks());
    dispatch(getLabels());
    dispatch(getEmails());
    dispatch(getUserLabels());
    dispatch(getStatuses());
    dispatch(getEquipmentTypes());
    dispatch(getTemplates());
    dispatch(getBuckets());
  }, []);

  useEffect(() => {
    if (hasModifiedFilters) return;
    setActiveFilters(getDefaultFilters({
      labels: ourLabels,
      statuses,
      equipmentTypes: equipmentTypesWithNone,
    }));
  }, [hasModifiedFilters, ourLabels, statuses, equipmentTypesWithNone]);

  useEffect(() => {
    const locationEquipment = equipment.find((eq) => eq.id === locationStateId);
    if (cardGridRef?.displayItem && locationEquipment) {
      setTimeout(() => {
        cardGridRef.displayItem(locationEquipment);
        history?.replace(pathname); // Clear location state
      }, 250);
    }
  }, [cardGridRef, locationStateId, equipment, history, pathname]);

  useEffect(() => {
    const cachedSort = window.localStorage.getItem('equipmentSort') || 'Name: Ascending';
    setActiveSort(cachedSort);
  }, []);

  const filterSets = useMemo(() => {
    const setObj = {};
    Object.keys(activeFilters).forEach((key) => {
      setObj[key] = new Set(activeFilters[key]);
    });
    return setObj;
  }, [activeFilters]);

  const ourEquipment = useMemo(() => {
    const numLabels = ourLabels.length;
    const allLabelsSelected = filterSets.labels?.size >= numLabels;
    const projectSet = filterSets?.projects ?? new Set();
    const relevantEquipment = equipment.filter((eq) => (
      filterSets.active.has(eq.active)
      && (
        allLabelsSelected
        || ourLabels.length === 0
        || eq.labels?.some((label) => filterSets.labels.has(label?.id))
      )
      && filterSets.status.has(eq.statusId)
      && filterSets.equipmentTypeId.has(eq.equipmentTypeId ?? NONE_EQUIPMENT_TYPE)
      && eq.divisionIds.find((divisionId) => selectedDivisions.has(divisionId))
      && filterSets?.availability.has(eq.availability)
      && (
        projectSet.size === 0
        || projectSet.has(eq.currentProjectId)
        || (projectSet.has(eq.lastProjectId) && !projectSet.currentProjectId)
        || equipmentProjects[eq.id]?.some((eqProjectId) => projectSet.has(eqProjectId))
      )
    ));
    const mod = activeSort === 'Name: Ascending' ? 1 : -1;
    relevantEquipment.sort((a, b) => sortByLocalCompare('name')(a, b) * mod);
    return relevantEquipment;
  }, [equipment, selectedDivisions, filterSets, ourLabels, activeSort, equipmentProjects]);

  const filterIsAtDefault = useMemo(() => {
    const {
      active,
      labels: labelFilter = new Set(),
      status: statusFilter = new Set(),
      equipmentTypeId: equipmentTypeFilter = new Set(),
      availability: availabilityFilter = new Set(),
      projects: projectSet = new Set(),
    } = filterSets;

    return active.has(1)
      && !active.has(0)
      && availabilityFilter.has(0)
      && availabilityFilter.has(1)
      && (ourLabels.length === 0 || ourLabels.every((label) => labelFilter.has(label.id)))
      && statusIds.every((statusId) => statusFilter.has(statusId))
      && equipmentTypesWithNone.every(
        ({ id }) => equipmentTypeFilter.has(id),
      )
      && projectSet.size === 0;
  }, [filterSets, ourLabels, statusIds, equipmentTypesWithNone]);

  const sortData = useMemo(() => ({
    sortOptions: [
      {
        title: 'Name: Ascending',
        icon: 'sort-ascending',
        action: () => {
          window.localStorage.setItem('equipmentSort', 'Name: Ascending');
          setActiveSort('Name: Ascending');
        },
      },
      {
        title: 'Name: Descending',
        icon: 'sort-descending',
        action: () => {
          window.localStorage.setItem('equipmentSort', 'Name: Descending');
          setActiveSort('Name: Descending');
        },
      },
    ],
    sortActive: activeSort,
  }), [activeSort]);

  const itemView = useCallback((props) => (
    <EquipmentCard {...props} onTimerClick={setSelected} />
  ), []);

  const addView = useCallback((form) => (
    <EquipmentAddView
      {...form}
      equipment={equipment}
      errors={errors}
      customFields={customFields}
      setCustomFields={setCustomFields}
    />
  ), [equipment, customFields, errors]);

  const editView = useCallback((form, formProps) => (
    <EquipmentAddView
      {...form}
      formProps={formProps}
      equipment={equipment}
      errors={errors}
      customFields={customFields}
      setCustomFields={setCustomFields}
    />
  ), [equipment, customFields, errors]);

  const onViewTypeChanged = useCallback((checked) => {
    window.localStorage.setItem('equipmentViewType', checked);
    setIsListView(checked);
  }, []);

  const equipmentTypeMap = useMemo(() => getIdMap(equipmentTypes), [equipmentTypes]);

  const displayColumns = useMemo(() => (
    getDisplayColumns({
      divisions,
      statuses,
      equipmentTypeMap,
    })
  ), [divisions, statuses, equipmentTypeMap]);

  if (!hasReadPerms) {
    history.replace('/dashboard');
    return null;
  }

  return (
    <BreadCrumbContainer crumbs={CRUMBS}>
      <CardGrid
        onRef={(ref) => {
          cardGridRef = ref;
        }}
        dataSource={ourEquipment}
        itemView={itemView}
        itemDimensions={{ width: 239, height: 190 }}
        add={hasWritePerms ? {
          title: 'Enter Equipment Details',
          formView: addView,
          width: 1200,
          onClose: onAdd,
        } : null}
        edit={{
          title: 'Edit Equipment Details',
          formView: editView,
          width: 1200,
          onClose: onEdit,
          getTitle: ({ name, editing }) => (editing ? `Edit ${name}` : name),
          canEdit: () => hasWritePerms,
          canArchive: () => hasWritePerms,
        }}
        onArchive={onArchive}
        onDelete={onDelete}
        onFilterOpen={toggleFilterView}
        filterActive={!filterIsAtDefault}
        sort={isListView ? {} : sortData}
        isListView={isListView}
        onToggleListViewSwitch
        onViewTypeChanged={onViewTypeChanged}
        displayColumns={displayColumns}
        search={{
          getField,
          tieBreaker: searchTieBreaker,
        }}
      />
      <EquipmentCheckModal
        {...selected}
        visible={selected.id}
        onCloseClicked={closeModal}
        onCheckIn={onCheckIn}
        onCheckOut={onCheckOut}
      />
      <EquipmentFilterDrawer
        visible={filterViewOpen}
        onClose={toggleFilterView}
        currentFilters={activeFilters}
        onFilterApply={onFilter}
      />
    </BreadCrumbContainer>
  );
}

export default Equipment;

Equipment.propTypes = {
  history: PropTypes.string.isRequired,
};
