import React, { useEffect, useMemo, useState } from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';

import OnTraccrMap from '../../../common/map/OnTraccrMap';
import getGoogleMapKey from '../../../common/keys';

import TitleRow from './TitleRow';
import FieldTriggerFlag from './FieldTriggerFlag';
import { uuid } from '../../../helpers/helpers';

export default function GPSLocationFormFieldPreview({
  previewProps = {},
  configProps = {},
  id,
  responses = {},
  setResponses,
  responding = false,
  isDisplay,
  sections = [],
  templateId,
  divisions,
  projectId,
  bucketTemplateMap = {},
  onFieldTrigger,
  relevantGPSBucketFieldMap = {},
}) {
  const [lastBucketIds, setLastBucketIds] = useState();

  const {
    optional,
    title = 'Title goes here',
    fieldTrigger,
    numAnswers = 1,
    openLimit,
    bucketField,
    useCoordinates,
  } = configProps;

  const {
    [id]: {
      values,
    } = {},
  } = responses ?? {};

  const {
    values: previewValues,
  } = previewProps ?? {};

  const onPlaceChanged = ({
    lat,
    lng,
    address,
  }) => {
    if (isDisplay || !setResponses) return;

    setResponses((oldResponses) => {
      const {
        [id]: {
          values: oldPins = [],
        } = {},
      } = oldResponses ?? {};

      let newValues = oldPins;
      if (!useCoordinates || !oldPins?.length) {
        newValues = oldPins.concat([{
          lat,
          lng,
          address,
          id: uuid(),
        }]);
      } else if (useCoordinates && oldPins.length === 1) {
        const newPin = {
          ...oldPins[0],
          lat,
          lng,
          address,
        };
        newValues = [newPin];
      }

      return {
        ...oldResponses,
        [id]: {
          values: newValues,
        },
      };
    });
  };

  const onMarkerDrag = ({
    lat,
    lng,
    id: pinId,
  }) => {
    if (isDisplay || !setResponses) return;
    setResponses((oldResponses) => {
      const {
        [id]: {
          values: oldPins = [],
        } = {},
      } = oldResponses ?? {};
      return {
        ...oldResponses,
        [id]: {
          values: oldPins.map((pin) => {
            if (pin.id !== pinId) return pin;
            return {
              ...pin,
              lat,
              lng,
              address: null,
            };
          }),
        },
      };
    });
  };

  const onDeleteMarker = (marker) => () => {
    if (isDisplay || !setResponses) return;
    setResponses((oldResponses) => {
      const {
        [id]: {
          values: oldPins = [],
        } = {},
      } = oldResponses ?? {};
      return {
        ...oldResponses,
        [id]: {
          values: oldPins.filter((pin) => pin.id !== marker.id),
        },
      };
    });
  };

  const markers = responding ? values : previewValues;

  const allBuckets = useMemo(() => (
    Object.values(bucketTemplateMap).flat()
  ), [bucketTemplateMap]);

  const areBucketsEqual = (buckets1, buckets2) => {
    if (!buckets1 && !buckets2) return true;
    if (!buckets1 || !buckets2) return false;

    const bucket1Set = new Set(buckets1);
    let areEqual = true;

    buckets2.forEach((bucket) => {
      if (!bucket1Set.has(bucket)) areEqual = false;
      bucket1Set.delete(bucket);
    });

    // Bucket 1 should be empty if they are equal
    return areEqual && !bucket1Set.size;
  };

  const setLocationBasedOffBucketLocations = (locations) => {
    if (openLimit || locations.length <= numAnswers) {
      setResponses((oldResponses) => ({
        ...oldResponses,
        [id]: {
          values: locations,
        },
      }));
    } else {
      setResponses((oldResponses) => ({
        ...oldResponses,
        [id]: {
          values: locations.slice(0, numAnswers - 1),
        },
      }));
    }
  };

  useEffect(() => {
    const relevantBucketId = relevantGPSBucketFieldMap[id] || bucketField;
    const selectedBuckets = responses?.[relevantBucketId]?.values;
    const selectedBucketIds = selectedBuckets?.map((bucket) => bucket.id);
    const selectedBucketSet = new Set(selectedBucketIds);

    // We do not want to override existing data
    if (markers && selectedBuckets && !lastBucketIds) {
      setLastBucketIds(selectedBucketIds);
      return;
    }

    // If the buckets are the same, we do not need to update the markers
    if (areBucketsEqual(lastBucketIds, selectedBucketIds)) return;

    const relevantBuckets = allBuckets
      .filter((bucket) => selectedBucketSet.has(bucket.id));

    // Set response equal to the relevant buckets location custom fields
    const locations = [];
    relevantBuckets?.forEach((bucket) => {
      bucket?.customData?.fields?.forEach((field) => {
        if (field.type === 'gpsLocation') {
          locations.push(...field.response?.values ?? []);
        }
      });
    });

    setLocationBasedOffBucketLocations(locations);
    setLastBucketIds(selectedBucketIds);
  }, [
    id,
    openLimit,
    numAnswers,
    responses,
    bucketField,
    allBuckets,
    markers,
    lastBucketIds,
    relevantGPSBucketFieldMap,
  ]);

  const canAddPin = !isDisplay
    && (openLimit || !values?.length || values.length < numAnswers);

  const markerRender = (marker) => (
    <div>
      {marker.address ? (
        <span>
          <b>Address: </b>
          {marker.address}
        </span>
      ) : (
        <>
          <span>
            <b>Latitude: </b>
            {marker.lat}
          </span>
          <br />
          <span>
            <b>Longitude: </b>
            {marker.lng}
          </span>
        </>
      )}
      <br />
      <br />
      {!isDisplay
      && (
      <button
        type="button"
        onClick={onDeleteMarker(marker)}
      >
        Delete
      </button>
      )}
    </div>
  );

  const defaultCoordinates = useMemo(() => {
    if (!useCoordinates || !markers?.length) return null;
    const [firstMarker] = markers;
    return `${firstMarker.lat}, ${firstMarker.lng}`;
  }, [useCoordinates, markers]);

  return (
    <div>
      <TitleRow
        title={title}
        optional={optional}
        filter={
          (isDisplay || !fieldTrigger)
            ? null
            : (
              <FieldTriggerFlag
                sections={sections}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                onFieldTrigger={onFieldTrigger}
              />
            )
        }
      />
      <Row style={{ marginTop: 15, position: 'relative', overflow: 'hidden' }}>
        <OnTraccrMap
          showSearch={canAddPin || useCoordinates}
          googleMapURL={getGoogleMapKey()}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: 400, width: '100%' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          options={{
            fullscreenControl: false,
          }}
          showPin={false}
          onPlaceChanged={canAddPin || useCoordinates ? onPlaceChanged : null}
          onClick={canAddPin ? onPlaceChanged : null}
          onMarkerDrag={onMarkerDrag}
          markersAreDraggable={!isDisplay}
          markers={markers}
          lockBounds={markers?.length === 1}
          markerRender={markerRender}
          useCoordinates={useCoordinates}
          defaultCoordinates={defaultCoordinates}
        />
      </Row>
    </div>
  );
}

GPSLocationFormFieldPreview.propTypes = {
  previewProps: PropTypes.shape({}),
  configProps: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  responses: PropTypes.shape({}),
  setResponses: PropTypes.func,
  responding: PropTypes.bool,
  isDisplay: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  templateId: PropTypes.string,
  divisions: PropTypes.arrayOf(PropTypes.string),
  projectId: PropTypes.string,
  bucketTemplateMap: PropTypes.shape({}),
  onFieldTrigger: PropTypes.func,
  relevantGPSBucketFieldMap: PropTypes.shape({}),
};

GPSLocationFormFieldPreview.defaultProps = {
  previewProps: {},
  configProps: {},
  responses: {},
  setResponses: null,
  responding: false,
  isDisplay: false,
  sections: [],
  templateId: null,
  divisions: [],
  projectId: null,
  bucketTemplateMap: {},
  onFieldTrigger: null,
  relevantGPSBucketFieldMap: {},
};
