import React from 'react';
import { Row } from 'antd';

import OptionalRow from './OptionalRow';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import ConditionalRenderingRow from './ConditionalRenderingRow';

export default ({
  id,
  setConfigProps,
  configProps = {},
  setCanSubmit,
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  contactAddressBooks = {},
  labels = [],
  buckets = [],
} = {}) => {
  const {
    optional,
    hasConditionalRendering = false,
    conditionalRenderingFormula,
  } = configProps;

  const setTitle = (e) => {
    const {
      target:{
        value,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      title:value,
    });
    setCanSubmit(value);
  };

  const setOptional = (e) => {
    const {
      target:{
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      optional:checked,
    });
  };


  const setConditionalRenderingFormula = (newVal) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: newVal,
    });
  };

  const setHasConditionalRendering = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      hasConditionalRendering: checked,
    });
  };

  const setFieldTrigger = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      fieldTrigger: checked,
    });
  };

  return (
    <div>
      <Row className='form-required-field'>
        Title:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <OnTraccrTextInput
          textarea placeholder='Insert title here'
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <OptionalRow onChange={setOptional} optional={optional} disabled={disableOptional}/>
      <ConditionalRenderingRow
        id={id}
        sections={sections}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
        onChange={setHasConditionalRendering}
        onFormulaChange={setConditionalRenderingFormula}
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
      />
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={setFieldTrigger}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
    </div>
  )
};
