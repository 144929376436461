import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DivisionSelector from '../../common/inputs/DivisionSelector';

import CompanySettingsCard from '../CompanySettingsCard';
import SettingsCardHeader from '../SettingsCardHeader';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import FormBuilder from '../../forms/FormBuilder/FormBuilder';

import {
  getCustomFields,
  setCustomFields,
} from '../../timecards/state/timecards.actions';
import { getCustomTables } from '../../forms/state/forms.actions';

function CustomFields({
  visible,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const divisions = useSelector((state) => state.settings.divisions);
  const customFields = useSelector((state) => state.timecards.customFields);

  const [fields, setFields] = useState([]);
  const [divisionId, setDivisionId] = useState();

  const onSaveFields = useCallback(() => {
    dispatch(setCustomFields({ fields, divisionId }));
  }, [dispatch, fields, divisionId]);

  useEffect(() => {
    if (visible) {
      dispatch(getCustomFields());
      dispatch(getCustomTables());
    }
  }, [dispatch, visible]);

  useEffect(() => {
    if (!divisionId || !(divisionId in customFields)) return;
    const {
      [divisionId]: { fields: newFields = [] } = {},
    } = customFields;
    setFields(newFields);
  }, [customFields, divisionId]);

  useEffect(() => {
    const divisionIds = Object.keys(divisions);
    if (!divisionId && divisionIds.length > 0) {
      setDivisionId(divisionIds[0]);
    }
  }, [divisionId, divisions]);

  const initialFields = useMemo(() => {
    const {
      [divisionId]: { fields: sections = [] } = {},
    } = customFields;

    const responseSections = sections.filter((section) => section.id !== 'timecard');
    return [
      ...responseSections,
      {
        name: 'Time Card',
        id: 'timecard',
        hidden: true,
        fields: [
          {
            configProps: {
              title: 'Total Hours',
              required: false,
            },
            id: 'total_hours',
            selectedType: 'text',
          },
          {
            configProps: {
              title: 'Work Hours',
              required: false,
            },
            id: 'work_hours',
            selectedType: 'text',
          },
          {
            configProps: {
              title: 'Break Hours',
              required: false,
            },
            id: 'break_hours',
            selectedType: 'text',
          },
          {
            configProps: {
              title: t('Project'),
              required: false,
              dataType: 'Projects',
              optional: true,
            },
            id: 'project',
            selectedType: 'dropdown',
          },
          {
            configProps: {
              title: 'Cost Code',
              required: false,
              dataType: 'Costcodes',
              optional: true,
            },
            id: 'costcode',
            selectedType: 'dropdown',
          },
          {
            configProps: {
              title: 'Cost Code - Code',
              required: false,
            },
            id: 'costcode_code',
            selectedType: 'text',
          },
          {
            configProps: {
              title: 'Cost Code - Name',
              required: false,
            },
            id: 'costcode_name',
            selectedType: 'text',
          },
        ],
      },
    ];
  }, [customFields, divisionId]);

  const title = useMemo(() => {
    const divisionList = Object.keys(divisions);
    if (divisionList.length <= 1) return 'Custom TimeCard Fields';
    return (
      <DivisionSelector
        divisionId={divisionId}
        onChange={setDivisionId}
        allowClear={false}
        style={{ width: 250 }}
      />
    );
  }, [divisions, divisionId]);

  return (
    <Row
      id="divisions-container"
      style={{
        width: '100%',
        height: '95%',
        marginLeft: 10,
        marginTop: 30,
        position: 'relative',
      }}
    >
      <SettingsCardHeader
        title={title}
        style={{ width: '100%', paddingRight: 50 }}
      >
        <OnTraccrButton
          title="Save"
          type="primary"
          onClick={onSaveFields}
        />
      </SettingsCardHeader>
      <CompanySettingsCard
        title=""
        style={{
          position: 'absolute',
          top: 30,
          bottom: 20,
          right: 50,
          left: 0,
          padding: 0,
          height: 'auto',
        }}
        containerStyle={{ paddingLeft: 5, paddingRight: 5, width: '100%', border: 'none' }}
      >
        <div
          className="form-builder-container"
          style={{
            width: '100%', height: '100%', position: 'relative'
          }}
        >
          <FormBuilder
            key={divisionId}
            style={{ inset: 0 }}
            isBoardCards
            onSectionsChanged={setFields}
            initialSections={initialFields}
            divisions={[divisionId]}
            isTimeCard
            showParentAttributeCheckbox
          />
        </div>
      </CompanySettingsCard>
    </Row>
  );
}

CustomFields.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default CustomFields;
