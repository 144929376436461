import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import {
  BarChartOutlined,
  GlobalOutlined,
  HomeOutlined,
  ProjectOutlined,
  SettingOutlined,
  ShareAltOutlined,
  SwapOutlined,
  TeamOutlined,
  UserOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  FileOutlined,
  FormOutlined,
  FieldTimeOutlined,
  ToolOutlined,
  ContactsOutlined,
  GoldOutlined,
  GroupOutlined,
  BoxPlotOutlined,
  ExperimentOutlined,
  TableOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  ProfileOutlined,
} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import Badge from '../common/badge';

import colors from '../constants/Colors';
import config from '../config';

import {
  ROUTE_TO_TEXT,
} from './menu.constants';

import Permissions from '../auth/Permissions';

import { getNumberOfAssignedAndDraftForms } from '../forms/formHelpers';
import IconPickerMap from '../common/IconPicker/iconPickerMap';

const { SubMenu } = Menu;

const badgeStyle = {
  paddingTop: '0',
  paddingBottom: '1px',
  fontSize: '12px',
  position: 'absolute',
  lineHeight: '21px',
  paddingRight: '0.5em',
  paddingLeft: '0.5em',
  top: '0.2em',
};

const getDocumentsMenu = ({
  formCount = 0,
  routeMap,
}) => {
  const hasFormsPerms = Permissions.has('FORMS_READ');
  const badge = hasFormsPerms && formCount ? <Badge count={formCount} style={badgeStyle} /> : null;
  const hasFilePerms = Permissions.has('FILES_READ');
  const fileMenuItem = hasFilePerms ? (
    <Menu.Item key="files">
      <FileOutlined />
      <span>{routeMap.files}</span>
    </Menu.Item>
  ) : null;
  if (!config.showForms) return fileMenuItem;
  if (!hasFormsPerms && !hasFilePerms) return null;
  return (
    <SubMenu
      key="documents"
      title={(
        <span>
          <FileOutlined />
          <span>Documents</span>
          {badge}
        </span>
      )}
    >
      {fileMenuItem}
      {hasFormsPerms && (
        <Menu.Item key="forms">
          <FormOutlined />
          <span>{routeMap.forms}</span>
          {badge}
        </Menu.Item>
      )}
    </SubMenu>
  );
};

const getScheduleMenu = (routeMap) => {
  if (!config.showGantt) {
    return (
      Permissions.match('SCHEDULE') && config.showSchedule && (
        <Menu.Item key="schedule">
          <CalendarOutlined />
          <span>Scheduling</span>
        </Menu.Item>
      )
    );
  }
  return (
    (Permissions.match('PROJECT_GANTT_SCHEDULE_READ')
    || (Permissions.match('SCHEDULE') && config.showSchedule)) && (
      <SubMenu
        key="scheduling"
        title={(
          <span>
            <CalendarOutlined />
            <span>Scheduling</span>
          </span>
        )}
      >
        { Permissions.match('PROJECT_GANTT_SCHEDULE_READ') && (
          <Menu.Item key="gantt-schedule">
            <BoxPlotOutlined />
            <span>{routeMap['gantt-schedule']}</span>
          </Menu.Item>
        )}
        { Permissions.match('SCHEDULE') && config.showSchedule && (
          <Menu.Item key="schedule">
            <CalendarOutlined />
            <span>{routeMap.schedule}</span>
          </Menu.Item>
        )}
      </SubMenu>
    )
  );
};

const getTimeCardMenu = ({ approvalCount, routeMap }) => (
  <SubMenu
    key="timecards"
    title={(
      <span>
        <FieldTimeOutlined />
        <span>Time Cards</span>
        {approvalCount ? <Badge count={approvalCount} style={badgeStyle} /> : null}
      </span>
    )}
  >
    { Permissions.has('TIMECARD_VIEW_SELF') && (
      <Menu.Item key="timecards/my">
        <FieldTimeOutlined />
        <span>{routeMap['timecards/my']}</span>
      </Menu.Item>
    )}
    <Menu.Item key="timecards/team">
      <CarryOutOutlined />
      <span>{routeMap['timecards/team']}</span>
      {approvalCount ? <Badge count={approvalCount} style={badgeStyle} /> : null}
    </Menu.Item>
  </SubMenu>
);

export default function AppMenu({
  approvalCount,
  onOpenChange,
  onMenuPicked,
  selectedKeys,
  openKeys,
}) {
  const { t } = useTranslation();
  const formCount = useSelector((state) => {
    const {
      forms: {
        approvals = {},
      } = {},
    } = state;
    return Object.values(approvals).filter((approval) => approval.status !== 'Cancelled').length + getNumberOfAssignedAndDraftForms(state.forms);
  });
  const boardTags = useSelector((state) => state.boards.myCardTags);
  const projectTypes = useSelector((state) => state.projects.projectTypes);
  const bucketTemplates = useSelector((state) => state.buckets.bucketTemplates);

  const routeMap = ROUTE_TO_TEXT(t);

  const relevantBucketTemplates = useMemo(() => (
    bucketTemplates.filter(({ shouldShowPage }) => shouldShowPage)
  ), [bucketTemplates]);

  const hasProjectPermissions = Permissions.has('PROJECTS_WRITE')
    || Permissions.has('PROJECTS_VIEW_ALL')
    || Permissions.has('PROJECTS_VIEW_ASSIGNED');

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onMenuPicked}
      defaultSelectedKeys={[]}
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      mode="inline"
      theme="dark"
      style={{
        backgroundColor: colors.ONTRACCR_BLACK,
      }}
    >

      <Menu.Item key="dashboard">
        <HomeOutlined />
        <span>{routeMap.dashboard}</span>
      </Menu.Item>

      <Menu.Item key="subtasks">
        <ProfileOutlined />
        <span>{routeMap.subtasks}</span>
      </Menu.Item>

      {getScheduleMenu(routeMap)}

      {getTimeCardMenu({ approvalCount, routeMap })}

      {(Permissions.match('USERS') || Permissions.has('TEAMS_WRITE') || Permissions.has('TEAMS_WRITE_ASSIGNED')) && (
        <SubMenu
          key="staff"
          title={(
            <span>
              <UserOutlined />
              <span>Staff</span>
            </span>
          )}
        >
          {Permissions.match('USERS') && (
            <Menu.Item key="users">
              <UserOutlined />
              <span>{`${Permissions.match('USERS') ? 'Manage' : 'View'} Users`}</span>
            </Menu.Item>
          )}
          {(Permissions.has('TEAMS_WRITE') || Permissions.has('TEAMS_WRITE_ASSIGNED')) && (
            <Menu.Item key="teams">
              <TeamOutlined />
              <span>{`${(Permissions.has('TEAMS_WRITE') || Permissions.has('TEAMS_WRITE_ASSIGNED')) ? 'Manage' : 'View'} Teams`}</span>
            </Menu.Item>
          )}
          {Permissions.has('USERS_MAP_READ') && (
            <Menu.Item key="users/map">
              <EnvironmentOutlined />
              <span>User Map</span>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      <SubMenu
        key="contacts"
        title={(
          <span>
            <ContactsOutlined />
            <span>Contacts</span>
          </span>
        )}
      >
        {Permissions.match('CUSTOMERS') && (
          <Menu.Item key="contacts/customers">
            <ContactsOutlined />
            <span>{routeMap['contacts/customers']}</span>
          </Menu.Item>
        )}
        {Permissions.match('VENDORS') && (
          <Menu.Item key="contacts/vendors">
            <CarryOutOutlined />
            <span>{routeMap['contacts/vendors']}</span>
          </Menu.Item>
        )}
        {(Permissions.match('CUSTOMERS') || Permissions.match('VENDORS')) && (
          <Menu.Item key="contacts/addressbook">
            <ContactsOutlined />
            <span>{routeMap['contacts/addressbook']}</span>
          </Menu.Item>
        )}
      </SubMenu>

      { Permissions.has('BUCKETS_READ') && relevantBucketTemplates.map((bucketTemplate) => {
        const Icon = IconPickerMap[bucketTemplate.icon];
        return (
          <Menu.Item key={`buckets/${bucketTemplate.id}`}>
            <Icon />
            <span>{bucketTemplate.name}</span>
          </Menu.Item>
        );
      })}

      { hasProjectPermissions && (
        <SubMenu
          key="projects"
          title={(
            <span>
              <ProjectOutlined />
              <span>{t('Project', { count: 2 })}</span>
            </span>
          )}
        >
          <Menu.Item key="projects">
            <ProjectOutlined />
            <span>{routeMap.projects}</span>
          </Menu.Item>
          <Menu.Item key="projectGroups">
            <GroupOutlined />
            <span>{routeMap.projectGroups}</span>
          </Menu.Item>
          {projectTypes.map((projectType) => {
            const Icon = IconPickerMap[projectType.icon];
            return (
              <Menu.Item key={`projects/type/${projectType.id}`}>
                <Icon />
                <span>{projectType.name}</span>
              </Menu.Item>
            );
          })}
        </SubMenu>
      )}

      {(config.showPayablesPage
        && (Permissions.match('INVOICES') || Permissions.match('PURCHASE_ORDERS'))) && (
        <Menu.Item
          key="payables"
        >
          <DollarOutlined />
          <span>{routeMap.payables}</span>
        </Menu.Item>
      )}

      {Permissions.has('COSTCODES_WRITE') && (
        <SubMenu
          key="costcode"
          title={(
            <span>
              <SwapOutlined />
              <span>Cost Codes</span>
            </span>
          )}
        >
          <Menu.Item key="costcodes/global">
            <GlobalOutlined />
            <span>{routeMap['costcodes/global']}</span>
          </Menu.Item>
          <Menu.Item key="costcodes/project-specific">
            <ShareAltOutlined />
            <span>{routeMap['costcodes/project-specific']}</span>
          </Menu.Item>
        </SubMenu>
      )}

      {Permissions.match('EQUIPMENT') && config.showEquipment && (
        <Menu.Item key="equipment">
          <ToolOutlined />
          <span>{routeMap.equipment}</span>
        </Menu.Item>
      )}

      {Permissions.match('MATERIALS') && config.showMaterials && (
        <Menu.Item key="materials">
          <GoldOutlined />
          <span>{routeMap.materials}</span>
        </Menu.Item>
      )}

      {Permissions.match('BOARDS') && config.showBoards && (
        <Menu.Item key="boards">
          <GroupOutlined />
          <span>{routeMap.boards}</span>
          {boardTags.length > 0
            ? <Badge count={boardTags.length} style={badgeStyle} />
            : null}
        </Menu.Item>
      )}

      {getDocumentsMenu({ formCount, routeMap })}

      {Permissions.match('REPORTS_VIEW') && (
        <SubMenu
          key="reports-parent"
          title={(
            <span>
              <BarChartOutlined />
              <span>Reports</span>
            </span>
          )}
        >
          <Menu.Item key="reports">
            <BarChartOutlined />
            <span>{routeMap.reports}</span>
          </Menu.Item>
          <Menu.Item key="exports">
            <TableOutlined />
            <span>{routeMap.exports}</span>
          </Menu.Item>
        </SubMenu>
      )}

      {config.showAnalytics && Permissions.match('REPORTS_VIEW') && (
        <Menu.Item key="analytics">
          <ExperimentOutlined />
          <span>{routeMap.analytics}</span>
        </Menu.Item>
      )}

      {Permissions.has('SETTINGS_WRITE') && (
        <Menu.Item key="settings">
          <SettingOutlined />
          <span>{routeMap.settings}</span>
        </Menu.Item>
      )}
    </Menu>
  );
}
