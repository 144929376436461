/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Card,
  Row,
  Statistic,
} from 'antd';
import PropTypes from 'prop-types';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

import useToggle from '../common/hooks/useToggle';

const BASE_WIDTH = 580;
const WEEKEND_WIDTH = 320;
const BASE_COLUMNS = 5;
const WEEKEND_COLUMNS = 3;

export const getValueStyle = (color) => ({
  fontSize: 18,
  padding: '0px',
  color,
});

const weekendStatRow = (day, colWidth, regular, ot, doubleOT) => (
  <>
    <div style={{ width: colWidth }}>
      <Statistic title={`${day} Hours`} value={regular.toFixed(2)} valueStyle={getValueStyle()} />
    </div>
    <div style={{ width: colWidth }}>
      <Statistic title={`${day} OT`} value={ot.toFixed(2)} valueStyle={getValueStyle()} />
    </div>
    <div style={{ width: colWidth }}>
      <Statistic title={`${day} DT OT`} value={doubleOT.toFixed(2)} valueStyle={getValueStyle()} />
    </div>
  </>
);

export default function UserStats({
  regularHours = 0,
  otHours = 0,
  doubleOtHours = null,
  ptoHours = null,
  saturdayHours = null,
  saturdayOTHours = null,
  saturdayDoubleOTHours = null,
  sundayHours = null,
  sundayOTHours = null,
  sundayDoubleOTHours = null,
}) {
  const {
    toggle,
    isToggled: isExpanded,
  } = useToggle(false);

  const totalValue = regularHours + otHours + doubleOtHours + ptoHours + saturdayHours
  + saturdayOTHours + saturdayDoubleOTHours + sundayHours + sundayOTHours + sundayDoubleOTHours;

  const showSaturday = saturdayHours || saturdayOTHours || saturdayDoubleOTHours;
  const showSunday = sundayHours || sundayOTHours || sundayDoubleOTHours;

  let weekendColumns = WEEKEND_COLUMNS * 2;
  let weekendWidth = WEEKEND_WIDTH * 2;

  if (!showSaturday || !showSunday) {
    weekendColumns = WEEKEND_COLUMNS;
    weekendWidth = WEEKEND_WIDTH;
  }

  // Guarantee equal width columns
  const colWidth = isExpanded
    ? `calc(${100 / (BASE_COLUMNS + weekendColumns)}% - ${25 / 7}px)`
    : `calc(${100 / BASE_COLUMNS}% - ${25 / 4}px)`;

  return (
    <Card
      className="timecard-stats-container"
      style={{ width: isExpanded ? BASE_WIDTH + weekendWidth : BASE_WIDTH }}
      bodyStyle={{
        padding: 3,
        marginLeft: 12,
      }}
    >
      <Row>
        <div style={{ width: colWidth }}>
          <Statistic title="Regular Hours" value={regularHours.toFixed(2)} valueStyle={getValueStyle('green')} />
        </div>
        <div style={{ width: colWidth }}>
          <Statistic title="OT Hours" value={(otHours + saturdayOTHours + sundayOTHours).toFixed(2)} valueStyle={getValueStyle('orange')} />
        </div>
        <div style={{ width: colWidth }}>
          <Statistic title="DT OT Hours" value={(doubleOtHours + saturdayDoubleOTHours + sundayDoubleOTHours).toFixed(2)} valueStyle={getValueStyle('red')} />
        </div>
        <div style={{ width: colWidth }}>
          <Statistic title="PTO Hours" value={ptoHours.toFixed(2)} valueStyle={getValueStyle('blue')} />
        </div>
        { isExpanded && (
          <>
            {(showSaturday) ? weekendStatRow('Saturday', colWidth, saturdayHours, saturdayOTHours, saturdayDoubleOTHours) : null}
            {(showSunday) ? weekendStatRow('Sunday', colWidth, sundayHours, sundayOTHours, sundayDoubleOTHours) : null}
          </>
        )}
        <div style={{ width: colWidth }}>
          <Statistic
            title="Total Hours"
            value={parseFloat((totalValue).toFixed(2))}
            valueStyle={getValueStyle()}
          />
        </div>
        { !!(showSaturday || showSunday) && (
          <div style={{ display: 'flex', alignItems: 'center', width: 25 }}>
            <div className="timecard-stats-button" onClick={toggle}>
              { isExpanded
                ? <DoubleLeftOutlined />
                : <DoubleRightOutlined />}
            </div>
          </div>
        )}
      </Row>
    </Card>
  );
};

UserStats.propTypes = {
  regularHours: PropTypes.number,
  otHours: PropTypes.number,
  doubleOtHours: PropTypes.number,
  ptoHours: PropTypes.number,
  saturdayHours: PropTypes.number,
  saturdayOTHours: PropTypes.number,
  saturdayDoubleOTHours: PropTypes.number,
  sundayHours: PropTypes.number,
  sundayOTHours: PropTypes.number,
  sundayDoubleOTHours: PropTypes.number,
};

UserStats.defaultProps = {
  regularHours: 0,
  otHours: 0,
  doubleOtHours: null,
  ptoHours: null,
  saturdayHours: null,
  saturdayOTHours: null,
  saturdayDoubleOTHours: null,
  sundayHours: null,
  sundayOTHours: null,
  sundayDoubleOTHours: null,
};
