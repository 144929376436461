import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import GridLayout from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import DashboardClock from '../../dashboard/DashboardClock';
import DashboardQuickStats from '../../dashboard/DashboardQuickStats';
import LiveFeed from '../../dashboard/LiveFeed/LiveFeed';
import DashboardAnalyticsWidget from '../../dashboard/DashboardAnalyticsWidget';
import DashboardTasksWidget from '../../dashboard/DashboardTasksWidget';
import DashboardCustomExportWidget from '../../dashboard/DashboardCustomExportWidget';

import OnTraccrEmpty from '../OnTraccrEmpty';

const RESIZE_HANDLES = ['s', 'e', 'se'];
const NUM_COLS = 12;
const ITEM_MARGIN = 10;
const MARGINS = [10, 10];

export default function DashboardContainer({
  currentLayout,
  setCurrentLayout,
  savedReports,
  analyticsData,
  selectedDashboardId,
  isEdit,
  projectId,
  cardId,
  analyticsType,
  layoutKey,
  rightMargin = 0,
  ourUsers,
  history,
  onClockIn,
  onClockOut,
  onSwitch,
  onBreakStart,
  onBreakEnd,
  onUserClick,
  onEditWidget,
}) {
  const containerRef = useRef(null);

  const phases = useSelector((state) => state.costcodes.phases);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const projects = useSelector((state) => state.projects.projects);
  const unions = useSelector((state) => state.unions);
  const approvals = useSelector((state) => state.approvals.approvals);
  const users = useSelector((state) => state.users.users);
  const settings = useSelector((state) => state.settings.company.settings);

  const [containerWidth, setContainerWidth] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);

  const deleteWidget = useCallback((key) => {
    const newCurrentLayout = currentLayout.filter((filteredLayout) => filteredLayout.i !== key);
    setCurrentLayout(newCurrentLayout);
  }, [currentLayout]);

  const getDimensions = useCallback(() => {
    if (containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current;
      setContainerWidth(offsetWidth);
      setContainerHeight(offsetHeight);
    }
  }, []);

  useEffect(() => {
    getDimensions();

    window.addEventListener('resize', getDimensions);

    return () => {
      window.removeEventListener('resize', getDimensions);
    };
  }, [getDimensions]);

  const rowHeight = (containerHeight - (NUM_COLS * ITEM_MARGIN)) / NUM_COLS;
  return (
    <div style={{ height: '100%', width: '100%' }} ref={containerRef}>
      <GridLayout
        width={containerWidth - rightMargin}
        rowHeight={rowHeight}
        cols={NUM_COLS}
        margin={MARGINS}
        layout={currentLayout}
        isDraggable={isEdit}
        isResizable={isEdit}
        resizeHandles={RESIZE_HANDLES}
        onLayoutChange={(newLayout) => {
          // GridLayout doesn't maintain 'settings', so copy them over
          const settingsMap = {};
          currentLayout.forEach(({ i: widgetKey, settings: widgetSettings }) => {
            settingsMap[widgetKey] = widgetSettings;
          });
          const newLayoutWithSettings = newLayout.map((layoutItem) => ({
            ...layoutItem,
            settings: settingsMap[layoutItem.i],
          }));
          setCurrentLayout(newLayoutWithSettings);
        }}
      >
        {
          currentLayout.map((layoutItem) => {
            const [itemType, itemId] = layoutItem.i?.split('-') ?? [];
            switch (itemType) {
              case 'clock':
                return (
                  <div key="clock" style={{ overflow: 'hidden' }}>
                    <DashboardClock
                      user={ourUsers[0]}
                      settings={settings}
                      costcodes={costcodes}
                      phases={phases}
                      projects={projects}
                      unions={unions}
                      onClockIn={onClockIn}
                      onClockOut={onClockOut}
                      onSwitch={onSwitch}
                      onBreakStart={onBreakStart}
                      onBreakEnd={onBreakEnd}
                      onDelete={() => deleteWidget('clock')}
                      isEdit={isEdit}
                    />
                  </div>
                );
              case 'quickStats':
                return (
                  <div key="quickStats" style={{ overflow: 'hidden' }}>
                    <DashboardQuickStats
                      users={users}
                      onUserClick={onUserClick}
                      approvals={approvals}
                      onDelete={() => deleteWidget('quickStats')}
                      isEdit={isEdit}
                    />
                  </div>
                );
              case 'liveFeed':
                return (
                  <div key="liveFeed" style={{ overflow: 'hidden' }}>
                    <LiveFeed
                      history={history}
                      onDelete={() => deleteWidget('liveFeed')}
                      isEdit={isEdit}
                    />
                  </div>
                );
              case 'tasks':
                return (
                  <div key="tasks" style={{ overflow: 'hidden' }}>
                    <DashboardTasksWidget
                      layout={layoutItem}
                      onDelete={() => deleteWidget('tasks')}
                      onEdit={onEditWidget('tasks')}
                      isEdit={isEdit}
                    />
                  </div>
                );
              case 'timeTrackingExports':
              case 'formExports': {
                const height = (
                  layoutItem.h * containerHeight
                ) / NUM_COLS;
                return (
                  <div key={layoutItem.i}>
                    <DashboardCustomExportWidget
                      exportId={itemId}
                      onDelete={() => deleteWidget(layoutItem.i)}
                      isEdit={isEdit}
                      height={height - 200}
                    />
                  </div>
                );
              }
              default:
                return (
                  <div key={layoutItem.i} style={{ overflow: 'hidden' }}>
                    <DashboardAnalyticsWidget
                      analyticsId={layoutItem.i}
                      onDelete={() => deleteWidget(layoutItem.i)}
                      key={`${layoutItem.i}-${layoutKey ?? 'none'}-${selectedDashboardId}`}
                      savedReports={savedReports}
                      analyticsData={analyticsData}
                      type={analyticsType}
                      projectId={projectId}
                      cardId={cardId}
                      isEdit={isEdit}
                    />
                  </div>
                );
            }
          })
        }
      </GridLayout>
      {currentLayout.length === 0 && <OnTraccrEmpty />}
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
DashboardContainer.propTypes = {
  currentLayout: PropTypes.array.isRequired,
  setCurrentLayout: PropTypes.func.isRequired,
  savedReports: PropTypes.object,
  analyticsData: PropTypes.object,
  selectedDashboardId: PropTypes.string,
  isEdit: PropTypes.bool,
  projectId: PropTypes.string,
  cardId: PropTypes.string,
  analyticsType: PropTypes.string,
  layoutKey: PropTypes.number,
  rightMargin: PropTypes.number,
  ourUsers: PropTypes.array,
  history: PropTypes.object,
  onClockIn: PropTypes.func,
  onClockOut: PropTypes.func,
  onSwitch: PropTypes.func,
  onBreakStart: PropTypes.func,
  onBreakEnd: PropTypes.func,
  onUserClick: PropTypes.func,
  onEditWidget: PropTypes.func,
};

DashboardContainer.defaultProps = {
  savedReports: {},
  analyticsData: {},
  selectedDashboardId: null,
  isEdit: false,
  projectId: undefined,
  cardId: undefined,
  analyticsType: undefined,
  layoutKey: 0,
  rightMargin: 0,
  ourUsers: [],
  history: undefined,
  onClockIn: undefined,
  onClockOut: undefined,
  onSwitch: undefined,
  onBreakStart: undefined,
  onBreakEnd: undefined,
  onUserClick: undefined,
  onEditWidget: undefined,
};
