import React from 'react';
import { Popover, Typography } from 'antd';
import { DateTime } from 'luxon';
import sortByString from '../../helpers/helpers';

import { getFormattedCurrency, getFormattedDate, renderProjectColumn } from '../../forms/formHelpers';
import SearchFormFilterHeader from '../../forms/CompletedForms/SearchFormFilterHeader';

const { Text } = Typography;

export default function getFormInvoiceColumns({
  projectFilters,
  vendorFilters,
  customerFilters,
  statusFilters,
  t,
  settings = {},
  projectIdMap = {},
  isProject,
}) {
  const cols = [
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      align: 'center',
      width: 150,
      sorter: sortByString('invoiceNumber'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: 150,
      sorter: sortByString('description'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: getFormattedCurrency,
      align: 'center',
      width: 140,
      sorter: (a, b) => a.amount - b.amount,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 120,
      filters: statusFilters,
      onFilter: (value, record) => record.statusId === value,
    },
  ];

  if (!isProject) {
    cols.push({
      title: t('Project', { count: 2 }),
      dataIndex: 'projects',
      key: 'projects',
      align: 'center',
      width: 120,
      filters: projectFilters,
      filterDropdown: SearchFormFilterHeader,
      render: (projects) => renderProjectColumn({
        projects,
        projectIdMap,
        settings,
      }),
      onFilter: (value, record) => {
        const projectIdSet = new Set(record.projects);
        return projectIdSet.has(value);
      },
    });
  }

  cols.push(
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      align: 'center',
      width: 120,
      filters: vendorFilters,
      filterDropdown: SearchFormFilterHeader,
      onFilter: (value, record) => record.vendorId === value,
    },
    {
      title: t('Customer', { count: 2 }),
      dataIndex: 'customers',
      key: 'customers',
      align: 'center',
      width: 120,
      filters: customerFilters,
      filterDropdown: SearchFormFilterHeader,
      render: (customers) => {
        const customerString = customers.join(', ');
        return (
          <Popover
            placement="top"
            content={<div style={{ width: 250 }}>{customerString}</div>}
          >
            <div>{customerString}</div>
          </Popover>
        );
      },
      onFilter: (value, record) => {
        const customerIdSet = new Set(record.customerIds);
        return customerIdSet.has(value);
      },
    },
    {
      title: 'Date Issued',
      dataIndex: 'dateIssued',
      key: 'dateIssued',
      render: getFormattedDate,
      align: 'center',
      width: 120,
      sorter: (a, b) => a.dateIssued - b.dateIssued,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (dueDate, record) => {
        const { status } = record || {};
        const formattedDate = getFormattedDate(dueDate);
        const currentDate = DateTime.local().toMillis();
        const type = dueDate && currentDate > dueDate && status !== 'Paid' ? 'danger' : null;
        return <Text type={type}>{formattedDate}</Text>;
      },
      align: 'center',
      width: 120,
      sorter: (a, b) => a.dueDate - b.dueDate,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'From QBO',
      dataIndex: 'fromQBOTimestamp',
      key: 'fromQBOTimestamp',
      align: 'center',
      width: 120,
      render: (timestamp) => (
        timestamp ? getFormattedDate(timestamp) : 'N/A'
      ),
    },
  );

  return cols;
}
