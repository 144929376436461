import React, { useState, useCallback, useMemo } from 'react';
import { Drawer, Row } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';
import FormExport from '../FormExport/FormExport';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import BorderlessButton from '../../common/buttons/BorderlessButton';

const HOVER_TEXT = `
Use this step to save a PDF copy of completed forms to your
preferred destination.
`;

export default ({
  onNodeUpdate,
  sections = [],
  collected = {},
  drawOptions = [],
  fileMap = {},
  formRef,
  onPDFChange,
  setDataMap,
  isDisplay,
  useStandardTemplate,
} = {}) => function ({ id, draggable, data = {} }) {
  const {
    exportLocation: initialExportLocation,
    useFormProjects: initialUseFormProjects,
    projectSubfolder: initialProjectSubfolder,
  } = data;
  const [exportLocation, setExportLocation] = useState(initialExportLocation);
  const [useFormProjects, setUseFormProjects] = useState(initialUseFormProjects);
  const [projectSubfolder, setProjectSubfolder] = useState(initialProjectSubfolder);
  const [showPDF, setShowPDF] = useState(false);
  const openPreview = useCallback(() => setShowPDF(true), []);
  const hidePreview = useCallback(() => setShowPDF(false), []);

  const savePreview = useCallback(() => {
    if (formRef && formRef.current) {
      const {
        exportLocation: newExportLocation,
        useFormProjects: newUseFormProjects,
        projectSubfolder: newProjectSubfolder,
      } = formRef.current.getFieldsValue();
      const update = {};
      if (newExportLocation) {
        setExportLocation(newExportLocation);
        update.exportLocation = newExportLocation;
      }
      if (newUseFormProjects) {
        setUseFormProjects(newUseFormProjects);
        update.useFormProjects = newUseFormProjects;
      }
      if (newProjectSubfolder) {
        const isValidSubfolder = newProjectSubfolder.slice(-1) === '/';
        const subfolderPath = isValidSubfolder ? newProjectSubfolder : `${newProjectSubfolder}/`;
        setProjectSubfolder(isValidSubfolder ? newProjectSubfolder : `${newProjectSubfolder}/`);
        update.projectSubfolder = subfolderPath;
      }
      if (setDataMap) {
        setDataMap((dataMap) => ({
          ...dataMap,
          [id]: {
            ...(dataMap?.[id]?.oldData || {}),
            ...update,
          },
        }));
      }
      setShowPDF(false);
    }
  }, []);

  const title = useMemo(() => (
    useFormProjects
      ? `{Project}/${projectSubfolder || ''}`
      : exportLocation || 'Set'
  ), [useFormProjects, exportLocation, projectSubfolder]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div>
      <WorkflowActionNode
        title="PDF Export"
        Icon={FilePdfOutlined}
        type="pdf"
        id={id}
        draggable={draggable}
        onNodeUpdate={onNodeUpdate}
        onPreview={openPreview}
        isDisplay={isDisplay}
        hover={HOVER_TEXT}
      >
        {!draggable && (
          <div>
            <Row style={{ marginTop: 5 }}>
              Select Export Location:
            </Row>
            <BorderlessButton
              title={title}
              onClick={openPreview}
              style={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap', textOverflow: 'ellipsis' }}
            />
            <WorkflowHandle id={`${id}-target`} type="target" position="top" disabled={isDisplay} />
          </div>
        )}
      </WorkflowActionNode>
      <Drawer
        title="PDF Export"
        width={700}
        visible={showPDF}
        onClose={hidePreview}
      >
        <FormExport
          formRef={formRef}
          sections={sections}
          collected={collected}
          drawOptions={drawOptions}
          onPDFChange={onPDFChange}
          exportLocation={exportLocation}
          useFormProjects={useFormProjects}
          projectSubfolder={projectSubfolder}
          fileMap={fileMap}
          useStandardTemplate={useStandardTemplate}
        />
        <div className="drawer-footer">
          <Row justify="end" gutter={10}>
            <OnTraccrButton
              title="Cancel"
              type="cancel"
              style={{ marginRight: 8 }}
              onClick={hidePreview}
            />
            <OnTraccrButton
              title="Save"
              onClick={savePreview}
            />
          </Row>
        </div>
      </Drawer>
    </div>
  );
};
