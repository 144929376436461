import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Table,
  Input,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import BorderlessButton from '../common/buttons/BorderlessButton';
import OnTraccrNumberInput from '../common/inputs/OnTraccrNumberInput';

import { includesTerm } from '../helpers/helpers';
import AssemblyMaterialSelector from './AssemblyMaterialSelector';

const getColumns = ({
  isNotDisplay,
  onQuantityChanged,
  onMaterialSelect,
  onMaterialDelete,
  selectedMaterialSet,
  divisionMaterials,
}) => {
  const cols = [
    {
      title: <div className="material-table-location-header">Material</div>,
      width: 150,
      key: 'materialId',
      sorter: (a, b) => {
        const aVal = a.materialName.toLowerCase();
        const bVal = b.materialName.toLowerCase();
        return aVal.localeCompare(bVal);
      },
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => {
        const {
          materialName,
          materialId,
        } = record;

        if (!isNotDisplay) return <div className="material-table-location-value">{materialName}</div>;
        return (
          <AssemblyMaterialSelector
            value={materialId}
            record={record}
            onSelect={onMaterialSelect}
            selectedMaterialSet={selectedMaterialSet}
            options={divisionMaterials}
          />
        );
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      render: (quantity, record) => (
        isNotDisplay
          ? (
            <OnTraccrNumberInput
              key={record.id}
              defaultValue={quantity}
              step={1}
              precision={2}
              onChange={(value) => onQuantityChanged(record.id, value)}
              style={{ display: 'flex' }}
            />
          ) : quantity
      ),
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      sorter: (a, b) => a.cost - b.cost,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Labour Cost',
      dataIndex: 'labourCost',
      sorter: (a, b) => a.labourCost - b.labourCost,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      sorter: (a, b) => a.totalCost - b.totalCost,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Total Labour Cost',
      dataIndex: 'totalLabourCost',
      sorter: (a, b) => a.totalLabourCost - b.totalLabourCost,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
  ];
  if (!isNotDisplay) return cols;
  cols.push({
    title: '',
    width: 15,
    dataIndex: 'delete',
    render: (_, record) => (
      record.isDefault
        ? null
        : (
          <BorderlessButton
            iconNode={<DeleteOutlined style={{ color: 'red', paddingLeft: 0 }} />}
            onClick={() => onMaterialDelete(record.id)}
          />
        )
    ),
  });
  return cols;
};

export default function AssemblyMaterials({
  id,
  assemblyMaterials = [],
  onChanged,
  isNotDisplay,
  divisionId,
}) {
  const materialMap = useSelector((state) => (state.materials.materials));
  const [searchStr, setSearchStr] = useState();

  const materials = useMemo(() => (
    Object.values(materialMap)
  ), [materialMap]);

  const divisionMaterials = useMemo(() => (
    materials.filter((material) => (
      material.divisionId === divisionId
      && material.active
      && !material.isFolder
      && !material.isAssembly
    ))
  ), [materials, divisionId]);

  const selectedMaterialSet = new Set(
    assemblyMaterials.map((assemblyMaterial) => assemblyMaterial.materialId),
  );

  useEffect(() => {
    if (!assemblyMaterials?.length || !divisionId) return;
    const filteredAssemblyMaterials = assemblyMaterials.filter((assemblyMaterial) => (
      !assemblyMaterial.materialId
      || materialMap[assemblyMaterial.materialId]?.divisionId === divisionId
    ));

    if (filteredAssemblyMaterials.length === assemblyMaterials.length) return;
    onChanged(filteredAssemblyMaterials);
  }, [divisionId, assemblyMaterials, materialMap, onChanged]);

  const onSearch = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchStr(value);
  }, []);

  const onAddNewMaterial = useCallback(() => {
    const newAssemblies = assemblyMaterials.concat([{
      id: DateTime.local().toMillis(),
      assemblyId: id,
      isNew: true,
      quantity: 0,
    }]);
    onChanged(newAssemblies);
  }, [assemblyMaterials, onChanged, id]);

  const updateAssemblyMaterial = useCallback(({ materialId, newData = {} }) => (
    onChanged(
      assemblyMaterials.map((material) => {
        if (material.id !== materialId) return material;
        return {
          ...material,
          ...newData,
        };
      }),
    )
  ), [assemblyMaterials, onChanged]);

  const onMaterialDelete = useCallback((materialId) => {
    onChanged(assemblyMaterials.filter((assemblyMaterial) => assemblyMaterial.id !== materialId));
  }, [onChanged, assemblyMaterials]);

  const onDataChanged = useCallback((key) => (materialId, newValue) => (
    updateAssemblyMaterial({ materialId, newData: { [key]: newValue } })
  ), [updateAssemblyMaterial]);

  const onMaterialSelect = useCallback((materialId, newData) => (
    updateAssemblyMaterial({
      materialId,
      newData,
    })
  ), [updateAssemblyMaterial]);

  const columns = useMemo(() => getColumns({
    isNotDisplay,
    selectedMaterialSet,
    divisionMaterials,
    onQuantityChanged: onDataChanged('quantity'),
    onQuantityAllocatedChanged: onDataChanged('quantityAllocated'),
    onMaterialSelect,
    onMaterialDelete,
  }), [
    isNotDisplay,
    selectedMaterialSet,
    divisionMaterials,
    onDataChanged,
    onMaterialSelect,
    onMaterialDelete,
  ]);

  const parsedAssemblyMaterials = useMemo(() => (
    assemblyMaterials.map((assemblyMaterial) => ({
      ...assemblyMaterial,
      materialName: materialMap[assemblyMaterial.materialId]?.name || '',
      cost: materialMap[assemblyMaterial.materialId]?.cost || 0,
      labourCost: materialMap[assemblyMaterial.materialId]?.labourCost || 0,
      totalCost: (
        (materialMap[assemblyMaterial.materialId]?.cost || 0) * (assemblyMaterial.quantity || 0)
      ).toFixed(2),
      totalLabourCost: (
        (materialMap[assemblyMaterial.materialId]?.labourCost || 0) * (assemblyMaterial.quantity || 0)
      ).toFixed(2),
    }))
  ), [assemblyMaterials, materialMap]);


  const tableData = useMemo(() => {
    if (!searchStr) return parsedAssemblyMaterials;
    return parsedAssemblyMaterials.filter((assemblyMaterial) => {
      const name = materialMap[assemblyMaterial.materialId]?.name || '';
      return includesTerm(name, searchStr);
    });
  }, [searchStr, materialMap, parsedAssemblyMaterials]);

  return (
    <>
      <div id="material-location-title">Materials</div>
      <Row justify="space-between" align="middle" className="material-location-header">
        <Col>
          <Input.Search
            className="searchbar"
            placeholder="Search"
            onChange={onSearch}
            allowClear
          />
        </Col>
        <Col>
          {isNotDisplay && (
            <OnTraccrButton
              title="Add"
              icon={<PlusOutlined />}
              onClick={onAddNewMaterial}
            />
          )}
        </Col>
      </Row>
      <Table
        className="material-location-table"
        columns={columns}
        dataSource={tableData}
        size="small"
        pagination={false}
        rowKey="id"
      />
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
AssemblyMaterials.propTypes = {
  id: PropTypes.string.isRequired,
  assemblyMaterials: PropTypes.array,
  onChanged: PropTypes.func.isRequired,
  isNotDisplay: PropTypes.bool.isRequired,
  divisionId: PropTypes.string.isRequired,
};

AssemblyMaterials.defaultProps = {
  assemblyMaterials: [],
};
