import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Select } from 'antd';

import { sortByLocalCompare } from '../../helpers/helpers';

export default ({
  selected,
  onChange,
  isSettings,
  type, // card, equipment
  profileTypeId,
  divisions,
}) => {
  const cardFormMappings = useSelector((state) => state.boards.formMappings);
    const templates = useSelector(state => state.forms.templates);
  const equipmentFormMappings = useSelector((state) => state.equipment.formMappings);
  const assignedFormTemplates = useSelector((state) => state.forms.assignedFormTemplates);

  const formMappings = useMemo(() => {
    if (type === 'card') {
      return cardFormMappings;
    }
    if (type === 'equipment') {
      return equipmentFormMappings[profileTypeId] ?? [];
    }
    return [];
  }, [type, profileTypeId, cardFormMappings, equipmentFormMappings]);

  const divSet = useMemo(() => new Set(divisions), [divisions]);

  const divisionTemplatSet = useMemo(() => new Set(
    Object.values(templates).filter((template) => (
      template.active
      && template.notDeleted
      && !template.isExternalForm
      && (divSet.size === 0 || divSet.has(template.divisionId))
    )).map((template) => template.id)
  ), [templates, divSet]);

  const templateSet = useMemo(() => (
    new Set(assignedFormTemplates.map((template) => template.id))
  ), [assignedFormTemplates]);

  const relevantMappings = useMemo(() => {
    if (isSettings) return formMappings;
    return formMappings.filter((template) => {
      if (!divisionTemplatSet.has(template.formTemplateId)) return false;
      if (!template.useFormPermissions) return true;
      return templateSet.has(template.formTemplateId);
    });
  }, [formMappings, templateSet, divisionTemplatSet, isSettings]);


  const selectOptions = useMemo(() => {
    const sortedMappings = [...relevantMappings];
    sortedMappings.sort(sortByLocalCompare());
    return sortedMappings.map((mapping) => {
      const { id, name } = mapping;
      return (
      <Select.Option
        value={id}
        key={id}
        label={name}
      >
        {name}
      </Select.Option>
    )});
  },[relevantMappings]);

  return (
    <>
      <Row style={{ marginTop: 5 }}>
        Select Form Mapping
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <Select
          style={{width:'100%'}}
          allowClear
          value={selected}
          dropdownMatchSelectWidth={false}
          onChange={onChange}
        >
          {selectOptions}
        </Select>
      </Row>
    </>
  );
}