import axios from 'axios';

import { request } from '../../helpers/requests';
import { constructFormPayloadForAPI } from '../../forms/ResponderHelpers';
import { decorateFormWithShallowFiles } from '../../forms/formHelpers';
import { parseAndUploadFiles } from '../../files/fileHelpers';

export default {
  getLibrary: () => request({
    call: axios.get('/board/library'),
    errMsg: 'Could not get boards library',
    hideSuccessToast: true,
  }),
  getBoardLibraryTemplate: (key) => request({
    call: axios.get(`/board/library/${key}`, { params: { getPreview: false } }),
    errMsg: 'Could not get boards library template',
    hideSuccessToast: true,
  }),
  importFromLibrary: (payload) => request({
    call: axios.post('/boards/import', payload),
    errMsg: 'Could not import boards library template',
    hideSuccessToast: true,
  }),
  createBoardCardTempates: (payload) => request({
    call: axios.post('/boards/cards/templates', payload),
    errMsg: 'Could not create board card template',
  }),
  getBoardCardTemplates: () => request({
    call: axios.get('/boards/cards/templates'),
    errMsg: 'Could not get board card templates',
    hideSuccessToast: true,
  }),
  deleteCardTemplate: (id, password) => request({
    call: axios.delete(`/boards/cards/templates/${id}`, { data: { password } }),
    errMsg: 'Could not delete board card template',
  }),
  updateBoardCardTemplate: (id, payload) => request({
    call: axios.put(`/boards/cards/templates/${id}`, payload),
    errMsg: 'Could not update board card template',
  }),
  getBoards: () => request({
    call: axios.get('/boards'),
    errMsg: 'Could not get boards',
    hideSuccessToast: true,
  }),
  createBoard: (payload) => request({
    call: axios.post('/boards', payload),
    errMsg: 'Could not create board',
  }),
  updateBoard: (id, payload) => request({
    call: axios.put(`/boards/${id}`, payload),
    errMsg: 'Could not update board',
  }),
  deleteBoard: (id, password) => request({
    call: async () => axios.delete(`/boards/${id}`, { data: { password } }),
    errMsg: 'Could not delete board',
  }),
  toggleBoardLock: (id, isLocked, pin) => request({
    call: axios.put(`/boards/${id}/locked`, { isLocked, pin }),
    errMsg: `Could not ${isLocked ? 'lock' : 'unlock'} board`,
  }),
  getBoardDetails: (id) => request({
    call: axios.get(`/boards/${id}`, { params: { getFullCards: false } }),
    errMsg: 'Could not get board details',
    hideSuccessToast: true,
  }),
  getCardById: (id) => request({
    call: axios.get(`/boards/cards/${id}`),
    errMsg: 'Could not get card',
    hideSuccessToast: true,
  }),
  moveCard: (id, payload) => request({
    call: axios.put(`/boards/cards/${id}/move`, payload),
    errMsg: 'Could not move card',
    hideSuccessToast: true,
  }),
  getBoardCardFiles: (id) => request({
    call: async () => {
      const { data: fileMap = {}, err } = await axios.get(`/boards/cards/${id}/files`);
      if (!fileMap) return { err };
      await decorateFormWithShallowFiles({ fileMap });
      return { data: fileMap };
    },
    errMsg: 'Could not get board card files',
    hideSuccessToast: true,
  }),
  deleteCard: (id) => request({
    call: axios.delete(`/boards/cards/${id}`),
    errMsg: 'Could not delete board card',
  }),
  getCardNotes: (id) => request({
    call: axios.get(`/boards/cards/${id}/notes`, { params: { getFileMap: true } }),
    errMsg: 'Could not get board card notes',
    hideSuccessToast: true,
  }),
  addCardNote: ({
    id, text, tags, files,
  }) => request({
    call: async () => {
      const parsedFiles = await parseAndUploadFiles(files);
      const result = await axios.post(`/boards/cards/${id}/note`, { note: text, tags, files: parsedFiles });
      return result;
    },
    errMsg: 'Could not add board card note',
  }),
  getCardEvents: (id) => request({
    call: axios.get(`/boards/cards/${id}/events`),
    errMsg: 'Could not get board card events',
    hideSuccessToast: true,
  }),
  getCardAttachments: (id) => request({
    call: axios.get(`/boards/cards/${id}/attachments`),
    errMsg: 'Could not get board card attachments',
    hideSuccessToast: true,
  }),
  getCardEmails: (id) => request({
    call: axios.get(`/boards/cards/${id}/emails`),
    errMsg: 'Could not get board card emails',
    hideSuccessToast: true,
  }),
  getCardEmailThread: (threadId) => request({
    call: axios.get(`/gmail/thread/${threadId}`),
    errMsg: 'Could not get email thread',
    hideSuccessToast: true,
  }),
  getCardEmailFile: ({ threadId, file }) => request({
    call: async () => {
      const { data, err } = await axios.get('gmail/file', {
        params: {
          messageId: file.messageId, attachmentId: file.attachmentId, threadId,
        },
        responseType: 'arraybuffer',
      });
      if (err || !data) return { err };
      return { data: new File([data], file.name, { type: file.type }) };
    },
    errMsg: 'Could not get file',
    hideSuccessToast: true,
  }),
  getCardLinks: () => request({
    call: axios.get('/boards/cards/links'),
    errMsg: 'Could not get board card links',
    hideSuccessToast: true,
  }),
  getCardsByLink: (linkType, linkId) => request({
    call: axios.get('/boards/cards', { params: { linkType, linkId } }),
    errMsg: 'Could not get cards for link',
    hideSuccessToast: true,
  }),
  getMyCardTags: (hasRead = false) => request({
    call: axios.get('/boards/cards/notes/tags', { params: { hasRead } }),
    errMsg: 'Could not get card tags',
    hideSuccessToast: true,
  }),
  markCardTagsAsRead: (noteIds) => request({
    call: axios.put('/boards/cards/notes/read', { ids: Array.from(noteIds) }),
    errMsg: 'Could not mark card tags as read',
    hideSuccessToast: true,
  }),
  createCard: ({
    title,
    responses,
    boardId,
    cardTypeId,
    statusId,
    dateRange = {},
    orderIndex,
    color,
    users = [],
    link = {},
    collected,
  }) => request({
    call: async () => {
      const {
        data: {
          sections,
        } = {},
        files,
      } = await constructFormPayloadForAPI({
        form: { responses },
        addSectionId: true,
      });
      const payload = {
        title,
        data: sections,
        files: files.map((file) => ({ ...file, isNew: true })),
        dateRange,
        users,
        boardId,
        cardTypeId,
        statusId,
        orderIndex,
        link,
        color,
        collected,
      };
      try {
        const { data: response } = await axios.post('/boards/cards', payload);
        return { data: response };
      } catch (error) {
        throw new Error('Failed to create card');
      }
    },
    errMsg: 'Could not create card',
  }),
  massUpload: ({ cards }) => request({
    call: async () => {
      const parsedCards = await Promise.all(cards.map(async (card) => {
        const { responses } = card;

        const newCard = { ...card };
        delete newCard.responses;

        const {
          data: {
            sections,
          } = {},
        } = await constructFormPayloadForAPI({
          form: {
            collected: {}, responses,
          },
        });
        return {
          ...newCard,
          data: sections,
        };
      }));

      try {
        const { data } = await axios.post('/boards/cards/upload', { cards: parsedCards });
        return { data };
      } catch (error) {
        throw new Error('Failed to upload cards');
      }
    },
    errMsg: 'Could not upload cards',
  }),
  updateCard: (id, {
    title,
    dateRange = {},
    color,
    responses,
    addedUsers = [],
    removedUsers = [],
    link = {},
    statusId,
    collected,
  }) => request({
    call: async () => {
      const {
        data: {
          sections,
        } = {},
        files,
      } = await constructFormPayloadForAPI({
        form: { responses },
        addSectionId: true,
      });
      const payload = {
        title,
        dateRange,
        data: sections,
        files: files.map((file) => ({ ...file, isNew: true })),
        addedUsers,
        removedUsers,
        link,
        color,
        statusId,
        collected,
      };
      try {
        const { data: response } = await axios.put(`/boards/cards/${id}`, payload);
        return { data: response };
      } catch (error) {
        throw new Error('Failed to update card');
      }
    },
    errMsg: 'Could not update card',
  }),
  generateForm: (id, payload) => request({
    call: axios.post(`/boards/cards/${id}/form`, payload),
    errMsg: 'Could not generate form',
  }),
  getFormMappings: (type, boardId) => request({
    call: axios.get(`/${type}/${boardId}/forms/mappings`),
    errMsg: 'Could not get form mappings',
    hideSuccessToast: true,
  }),
  createFormMapping: (type, payload) => request({
    call: axios.post(`/${type}/forms/mappings`, payload),
    errMsg: 'Could not create form mapping',
  }),
  updateFormMapping: (type, id, payload) => request({
    call: axios.put(`/${type}/forms/mappings/${id}`, payload),
    errMsg: 'Could not update form mapping',
  }),
  deleteFormMapping: (type, id) => request({
    call: axios.delete(`/${type}/forms/mappings/${id}`),
    errMsg: 'Could not delete form mapping',
  }),
  createBoardTemplate: (payload) => request({
    call: axios.post('/boards/templates', payload),
    errMsg: 'Could not create board template',
  }),
  getBoardTemplates: () => request({
    call: axios.get('/boards/templates'),
    errMsg: 'Could not get board templates',
    hideSuccessToast: true,
  }),
  deleteBoardTemplate: (id) => request({
    call: axios.delete(`/boards/templates/${id}`),
    errMsg: 'Could not delete board template',
  }),
  updateBoardTemplate: (id, payload) => request({
    call: axios.put(`/boards/templates/${id}`, payload),
    errMsg: 'Could not update board template',
  }),
  getCardSnapshots: (cardId) => request({
    call: async () => {
      const { data: snapshots } = await axios.get(`/boards/cards/${cardId}/snapshots`);
      const parsedSnapshots = await Promise.all(snapshots.map(async (snapshot) => {
        await decorateFormWithShallowFiles(snapshot);
        return snapshot;
      }));
      return { data: parsedSnapshots };
    },
    errMsg: 'Could not get card snapshots',
    hideSuccessToast: true,
  }),
  getAllDetails: () => request({
    call: axios.get('/boards/details'),
    errMsg: 'Could not get board details',
    hideSuccessToast: true,
  }),
  clearShadow: (boardId, cardId) => request({
    call: axios.delete(`/boards/${boardId}/cards/${cardId}/shadow`),
    errMsg: 'Could not delete card shadow',
  }),
  reassignCard: (id, payload) => request({
    call: axios.put(`/boards/cards/${id}/reassign`, payload),
    errMsg: 'Could not reassign card',
  }),
};
