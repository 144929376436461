import React from 'react';
import {
  Checkbox,
  Col,
  Row,
  Select,
} from 'antd';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import OptionalRow from './OptionalRow';
import AttributeFieldPreview from './AttributeFieldPreview';
import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import ConditionalRenderingRow from './ConditionalRenderingRow';
import CheckboxRow from './CheckboxRow';
import HoverHelp from '../../../common/HoverHelp';
import DropdownFieldSubDataTypeSelect from './DropdownFieldSubDataTypeSelect';
import TitleRow from './TitleRow';
import FieldTriggerFlag from './FieldTriggerFlag';
import { getAttributeOptions, getLinkOptions } from './Attribute.helpers';

const getSubDataTypeMap = (bucketTemplates = []) => ({
  Buckets: bucketTemplates.map(({ id, name }) => ({
    label: name,
    value: id,
  })),
});

const configure = ({
  setConfigProps,
  configProps = {},
  setCanSubmit,
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  t,
  attributeMap,
  id,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  projectIdMap = {},
  phases = [],
  vendors = [],
  equipment = [],
  formTemplates = [],
  labels = [],
  contactAddressBooks = {},
  buckets = [],
  bucketTemplates,
  isExistingField = false,
  isTimeCard = false,
  showParentAttributeCheckbox = false,
} = {}) => {
  const attributeOptions = getAttributeOptions(t);
  const subDataTypeMap = getSubDataTypeMap(bucketTemplates);

  const {
    optional,
    hasConditionalRendering = false,
    conditionalRenderingFormula,
    dataType,
    useFormAuthorAttributes,
    useParentAttributes,
    allowEdits = true,
    subDataType,
    attribute,
    linkField,
  } = configProps;

  const ourType = attributeOptions.find((type) => type.value === dataType);

  const {
    hasSubDataType = false,
  } = ourType ?? configProps;

  const setTitle = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      title: value,
    });
    setCanSubmit(value);
  };

  const setOptional = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      optional: checked,
    });
  };

  const setAllowEdits = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    const newOps = {
      ...configProps,
      allowEdits: checked,
    };
    if (!checked) newOps.optional = true;
    setConfigProps(newOps);
  };

  const setDataType = (newDataType) => {
    const typeChanged = newDataType !== configProps.dataType;
    const relevantType = attributeOptions.find(({ value }) => value === newDataType);
    setConfigProps({
      ...configProps,
      dataType: newDataType,
      useFormAuthorAttributes: false,
      linkField: typeChanged ? null : configProps.linkField,
      attribute: typeChanged ? null : configProps.attribute,
      hasSubDataType: relevantType?.hasSubDataType,
    });
  };

  const setAttribute = (e) => {
    const {
      [hasSubDataType ? subDataType : dataType]: {
        fields = [],
      } = {},
    } = attributeMap;
    const field = fields.find(({ attr }) => attr === e);

    setConfigProps({
      ...configProps,
      attribute: e,
      type: field.type,
      subfields: field.subfields,
    });
  };

  const setFieldTrigger = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      fieldTrigger: checked,
    });
  };

  const setConditionalRenderingFormula = (formula) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: formula,
    });
  };

  const setFormAuthorAttributes = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;

    setConfigProps({
      ...configProps,
      useFormAuthorAttributes: checked,
    });
  };

  const setParentAttributes = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;

    setConfigProps({
      ...configProps,
      useParentAttributes: checked,
    });
  };

  const setHasConditionalRendering = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      hasConditionalRendering: checked,
    });
  };

  const setLinkField = (value) => {
    setConfigProps({
      ...configProps,
      linkField: value,
    });
  };

  const setSubDataType = (value) => {
    setConfigProps({
      ...configProps,
      subDataType: value,
    });
  };

  const linkOptions = getLinkOptions({
    dataType,
    sections,
    hasSubDataType,
    subDataType,
  });

  const subDataTypes = hasSubDataType
    ? subDataTypeMap[dataType]
    : [];

  const detailType = `${isTimeCard ? 'Time Card ' : ''}Project`;

  return (
    <div>
      <Row className="form-required-field">
        Title:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <OnTraccrTextInput
          textarea
          placeholder="Insert title here"
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <OptionalRow
        onChange={setOptional}
        optional={optional}
        disabled={disableOptional || !allowEdits}
      />
      <CheckboxRow
        title="Allow Edits"
        checked={allowEdits}
        onChange={setAllowEdits}
        hoverText="Disable this checkbox to prevent users from editing the attribute value"
      />
      <ConditionalRenderingRow
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
        onChange={setHasConditionalRendering}
        onFormulaChange={setConditionalRenderingFormula}
        sections={sections}
        id={id}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
      />
      <Row style={{ marginTop: 10 }} gutter={10}>
        <Select
          style={{ width: '100%', margin: '0px 10px' }}
          placeholder="Select attribute instance type"
          onChange={setDataType}
          value={configProps.dataType}
          optionFilterProp="label"
        >
          {attributeOptions.map((item) => (
            <Select.Option
              value={item.value}
              key={item.value}
              label={item.name}
            >
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Row>
      { hasSubDataType && (
        <DropdownFieldSubDataTypeSelect
          placeholder={`Select ${dataType} instance`}
          onChange={setSubDataType}
          value={subDataType}
          options={subDataTypes}
          disabled={isExistingField}
          mode="single"
          rowStyle={{ marginLeft: -5, marginRight: -5 }}
          selectStyle={{ margin: '0px 10px' }}
        />
      )}
      {
          dataType && (!hasSubDataType || subDataType)
          && (
            <>
              <Row style={{ marginTop: 10 }} gutter={10}>
                <Select
                  style={{ width: '100%', margin: '0px 10px' }}
                  placeholder={`Select attribute for ${attributeMap[dataType]?.name ?? dataType}`}
                  onChange={setAttribute}
                  value={attribute}
                  optionFilterProp="label"
                >
                  {attributeMap[hasSubDataType ? subDataType : dataType]?.fields.map((item) => (
                    <Select.Option
                      value={item.attr}
                      key={item.attr}
                      label={item.attr}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
              <Row style={{ marginTop: 10 }} gutter={10}>
                <Select
                  style={{ width: '100%', margin: '0px 10px' }}
                  placeholder="Link to field"
                  onChange={setLinkField}
                  value={linkField}
                  optionFilterProp="label"
                  options={linkOptions}
                  allowClear
                />
              </Row>
            </>
          )
        }
      {dataType === 'User' && (
        <Row style={{ marginTop: 15 }} gutter={10}>
          <Col>
            <Checkbox
              onChange={setFormAuthorAttributes}
              checked={useFormAuthorAttributes}
            >
              Use Form Author
            </Checkbox>
          </Col>
          <Col>
            <HoverHelp
              placement="topRight"
              content={(
                <div style={{ width: 250 }}>
                  Check this box to auto-populate the field with the
                  {' '}
                  <b>form authors</b>
                  {' '}
                  details.
                  <br />
                  <br />
                  This takes priority over any other user drop-down fields.
                </div>
              )}
            />
          </Col>
        </Row>
      )}
      {(dataType === 'Customer' || dataType === 'Project') && showParentAttributeCheckbox && (
        <Row style={{ marginTop: 15 }} gutter={10}>
          <Col>
            <Checkbox
              onChange={setParentAttributes}
              checked={useParentAttributes}
            >
              Use {detailType} Details
            </Checkbox>
          </Col>
          <Col>
            <HoverHelp
              placement="topRight"
              content={(
                <div style={{ width: 250 }}>
                  Check this box to auto-populate the field with the
                  <b> {detailType.toLowerCase()} </b>
                  details.
                  <br />
                  <br />
                  This takes priority over any other {dataType.toLowerCase()} drop-down fields.
                </div>
                      )}
            />
          </Col>
        </Row>
      )}
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={setFieldTrigger}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
    </div>
  );
};

const preview = (props = {}) => {
  const {
    configProps = {},
    projectId,
    responding = false,
    sections,
    templateId,
    divisions,
    isDisplay,
    onFieldTrigger,
  } = props;

  const {
    optional,
    title = 'Title goes here',
    fieldTrigger,
    allowEdits = true,
  } = configProps;

  return (
    <>
      <TitleRow
        title={title}
        optional={optional || !allowEdits}
        filter={
          fieldTrigger && !isDisplay
            ? (
              <FieldTriggerFlag
                sections={sections}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                onFieldTrigger={onFieldTrigger}
              />
            ) : null
        }
      />
      <Row style={{ marginTop: 15, minHeight: 32 }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <AttributeFieldPreview {...props} />
      </Row>
    </>

  );
};

export default {
  configure,
  preview,
  title: 'Attribute',
  description: 'Use this field to display a specific attribute based on an input selection in a single dropdown in the form',
};
