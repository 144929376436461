import React, {
  useMemo, useEffect, useCallback, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Drawer,
  Divider,
  Table,
  Row,
  Col,
  Spin,
  Checkbox,
} from 'antd';
import { DateTime } from 'luxon';
import { getIdMap, toTitleCase } from 'ontraccr-common/lib/Common';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import HoverHelp from '../../common/HoverHelp';
import OnTraccrCheckbox from '../../common/inputs/OnTraccrCheckbox';
import FormSelector from '../FormWorkflows/selectors/FormSelector';
import FormsTable from '../FormsTable';
import MappingSelector from '../../boards/BoardCardForms/MappingSelector';
import WorkflowConfigureSourceSelector from '../FormWorkflows/selectors/WorkflowConfigureSourceSelector';
import { getSourceFieldMap, getType } from '../FormWorkflows/workflowHelpers';
import {
  closeFormMapDrawer,
  getBoardCardFiles,
  getCardById,
  openFormDrawer,
} from '../../boards/state/boards.actions';
import { getDrafts, getTemplateDetails } from '../state/forms.actions';
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import { fillFormFromProfile } from '../formHelpers';
import { getEquipmentCustomData, getEquipmentFiles } from '../../equipment/state/equipment.actions';

const nativeFieldsByType = {
  card: {
    text: [
      { id: 'cardTitle', key: 'title', title: 'Card Title' },
    ],
  },
  equipment: {
    text: [
      { id: 'code', key: 'code', title: 'Equipment ID' },
      { id: 'name', key: 'name', title: 'Equipment Name' },
      { id: 'hourlyCost', key: 'hourlyCost', title: 'Hourly Cost' },
      { id: 'hourlyBillingRate', key: 'hourlyBillingRate', title: 'Hourly Billing Rate' },
      { id: 'dailyCost', key: 'dailyCost', title: 'Daily Cost' },
      { id: 'dailyBillingRate', key: 'dailyBillingRate', title: 'Daily Billing Rate' },
    ],
  },
};

const getNativeFieldKeys = (type) => {
  const fieldTypes = nativeFieldsByType[type];
  return Object.values(fieldTypes).reduce((acc, fieldTypeArr) => acc.concat(fieldTypeArr), []);
};

export default function CustomFieldFormMappingDrawer({
  visible,
  type, // card, equipment
  data = {},
  selectedProfileId,
  profileTypeId,
  divisions = [],
  onSubmit,
  onClose,
  isSettings,
}) {
  const dispatch = useDispatch();

  const templates = useSelector((state) => state.forms.templates);
  const circularFieldIds = useSelector((state) => state.forms.circularFieldIds);
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);
  const cardFormMappings = useSelector((state) => state.boards.formMappings);
  const equipmentFormMappings = useSelector((state) => state.equipment.formMappings);
  const cardFileMap = useSelector((state) => state.boards.cardFileMap);
  const reduxCard = useSelector((state) => state.boards.selectedCard);
  const projects = useSelector((state) => state.projects.projects);
  const isFiltered = useSelector((state) => state.boards.isFiltered);
  const filteredSnapshotData = useSelector((state) => state.boards.filteredSnapshotData);

  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const users = useSelector((state) => state.users.users);
  const usersMap = useMemo(() => getIdMap(users), [users]);
  const equipment = useSelector((state) => state.equipment.equipment);
  const equipmentMap = useMemo(() => getIdMap(equipment), [equipment]);
  const equipmentTypes = useSelector((state) => state.equipment.equipmentTypes);
  const equipmentTypeMap = useMemo(() => getIdMap(equipmentTypes), [equipmentTypes]);
  const customers = useSelector((state) => state.customers.customers);
  const {
    manual,
  } = useSelector((state) => state.forms.drafts);
  const {
    customData: equipmentCustomData,
    customDataFiles: equipmentCustomDataFiles,
  } = useSelector((state) => state.equipment);

  const formMappings = useMemo(() => {
    if (type === 'card') {
      return cardFormMappings;
    }
    if (type === 'equipment') {
      return equipmentFormMappings[profileTypeId];
    }
    return [];
  }, [type, profileTypeId, cardFormMappings, equipmentFormMappings]);

  const selectedProfile = useMemo(() => {
    if (type === 'card') {
      return reduxCard?.id === selectedProfileId ? reduxCard : null;
    }
    if (type === 'equipment') {
      return equipmentMap[selectedProfileId];
    }
    return {};
  }, [selectedProfileId, equipmentMap, reduxCard]);

  const selectedTitle = useMemo(() => {
    if (type === 'card') {
      return selectedProfile?.title;
    }
    return selectedProfile?.name;
  }, [selectedProfile]);

  const { id, link: cardLink } = selectedProfile ?? {};
  const profileData = useMemo(() => {
    if (type === 'card') {
      return selectedProfile?.data;
    }
    if (type === 'equipment') {
      return equipmentCustomData;
    }
    return {};
  }, [type, selectedProfile, equipmentCustomData]);

  const [loading, setLoading] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState();
  const [selectedForm, setOurSelectedForm] = useState();
  const [fieldMappings, setFieldMappings] = useState({});
  const [profileIsLoading, setProfileIsLoading] = useState(false);
  const [shouldMapFilteredData, setShouldMapFilteredData] = useState(false);
  const [useFormPermissions, setUseFormPermissions] = useState(false);

  const draftList = useMemo(() => (
    Object.values(manual)
      .filter(
        ({ cardId }) => cardId === selectedProfileId,
      )
      .map((draft) => {
        const {
          id: draftId,
          lastUpdated,
          templateId,
          data: draftData,
        } = draft;

        return {
          id: draftId,
          name: draftData?.name ?? '',
          lastUpdated,
          templateId,
          circularFieldIds: draftData?.circularFieldIds,
        };
      })
  ), [manual, selectedProfileId]);

  const draftListMap = useMemo(() => getIdMap(draftList), [draftList]);

  useEffect(() => {
    if (visible && selectedForm) {
      dispatch(getTemplateDetails(
        selectedForm,
        undefined,
        {
          fieldMappings,
        },
      ));
    }
  }, [visible, selectedForm, fieldMappings]);

  useEffect(() => {
    const loadProfile = async () => {
      setProfileIsLoading(true);
      if (type === 'card') await dispatch(getCardById(selectedProfileId));
      if (type === 'equipment') await dispatch(getEquipmentCustomData(selectedProfileId));
      setProfileIsLoading(false);
    };

    if (
      visible && selectedProfileId
      && (
        (type === 'card' && reduxCard?.id !== selectedProfileId)
        || (type === 'equipment' && selectedProfile.id !== selectedProfileId)
      )
    ) {
      loadProfile();
    }
  }, [visible, reduxCard, selectedProfile, selectedProfileId]);

  useEffect(() => {
    if (visible && selectedProfileId && type === 'card') dispatch(getDrafts({ cardId: selectedProfileId }));
  }, [visible, selectedProfileId]);

  const sourceSections = useMemo(() => {
    if (type === 'card') {
      const {
        [profileTypeId]: template = {},
      } = cardTemplates;
      return template?.fields;
    }
    if (type === 'equipment') {
      const defaultEquipmentType = equipmentTypes.find((eqType) => eqType.isDefault);
      const equipmentType = equipmentTypeMap[profileTypeId] ?? defaultEquipmentType;
      return equipmentType?.customFields?.sections;
    }
    return [];
  }, [type, profileTypeId, cardTemplates, equipmentTypeMap]);

  const ourTemplate = useMemo(() => {
    const {
      [selectedForm]: targetForm = {},
    } = templates;
    return targetForm;
  }, [selectedForm, templates]);

  const fileMap = useMemo(() => {
    if (type === 'card') {
      const {
        [selectedProfileId]: ourFileMap = {},
      } = cardFileMap;
      return ourFileMap;
    }
    return equipmentCustomDataFiles;
  }, [cardFileMap, equipmentCustomDataFiles, selectedProfileId]);

  const {
    formData: {
      name: targetName,
      sections: targetSections = [],
    } = {},
  } = ourTemplate;

  const [mappingName, setMappingName] = useState(targetName);

  const cleanup = () => {
    setOurSelectedForm();
    setFieldMappings({});
    setLoading(false);
    setMappingName();
    setSelectedMapping();
    setProfileIsLoading(false);
    setShouldMapFilteredData(false);
    setUseFormPermissions(false);
  };

  const flattenedTargetFields = useMemo(() => {
    const fields = [];
    targetSections.forEach((section) => {
      const { fields: sectionFields = [], name: sectionName } = section;
      sectionFields.forEach((field) => {
        const {
          configProps: {
            title: fieldTitle,
          } = {},
        } = field;
        fields.push({
          ...field,
          title: `${sectionName} - ${fieldTitle}`,
        });
      });
    });
    return fields;
  }, [targetSections]);

  const sourceFieldMap = useMemo(() => {
    const newSourceFieldMap = getSourceFieldMap(sourceSections);

    const nativeFields = nativeFieldsByType[type] || {};
    Object.entries(nativeFields).forEach(([fieldType, fields]) => {
      if (!newSourceFieldMap[fieldType]) {
        newSourceFieldMap[fieldType] = [];
      }
      fields.forEach((field) => {
        newSourceFieldMap[fieldType].push(field);
      });
    });
    return newSourceFieldMap;
  }, [sourceSections, type]);

  const columns = useMemo(() => [{
    dataIndex: 'sourceField',
    title: 'Source Field',
    width: 425,
    render: (_, record) => {
      const {
        id: targetId,
      } = record;
      const fieldType = getType(record);
      const {
        [fieldType]: eligibleSourceFields = [],
      } = sourceFieldMap;

      const {
        [targetId]: defaultValue,
      } = fieldMappings;

      return (
        <WorkflowConfigureSourceSelector
          fieldMappings={fieldMappings}
          value={defaultValue}
          onFieldMappingsChange={setFieldMappings}
          targetId={targetId}
          eligibleSourceFields={eligibleSourceFields}
        />
      );
    },
  }, {
    dataIndex: 'title',
    title: 'Target Field',
  }], [fieldMappings, sourceFieldMap, setFieldMappings, getType]);

  const onCloseDefault = useCallback(() => {
    dispatch(closeFormMapDrawer());
    cleanup();
  }, []);

  const onCloseClicked = useCallback(() => {
    if (onClose) {
      onClose();
      cleanup();
    } else {
      onCloseDefault();
    }
  }, [onClose, onCloseDefault]);

  const onSubmitCreateForm = useCallback(async (selectedDraft) => {
    const relevantDraft = draftListMap[selectedDraft?.id];
    if (relevantDraft) {
      await dispatch(getTemplateDetails(
        relevantDraft.templateId,
        relevantDraft.id,
        {
          circularFieldIds: relevantDraft.circularFieldIds,
        },
      ));
    }

    let customData = profileData;
    if (type === 'card' && isFiltered && shouldMapFilteredData) {
      customData = filteredSnapshotData;
    }

    setLoading(true);
    const {
      formData: filledForm,
      ids,
    } = fillFormFromProfile({
      profileType: type,
      profileId: id,
      formTemplate: ourTemplate,
      nativeData: selectedProfile,
      nativeFields: getNativeFieldKeys(type),
      customData,
      mappings: fieldMappings,
      projects: projectMap,
      customers,
      users: usersMap,
      equipment: equipmentMap,
      circularFieldIds,

      cardLink,
    });

    const config = {
      visible: true,
      isEdit: false,
      fileMap,
      ids,
    };
    if (type === 'card') {
      config.cardId = selectedProfileId;
    } else if (type === 'equipment') {
      config.equipmentId = selectedProfileId;
    }

    if (relevantDraft) {
      config.assignedForm = { draftId: relevantDraft.id };
    } else {
      config.formData = filledForm;
    }

    await dispatch(openFormDrawer(config));
    dispatch(closeFormMapDrawer());
    cleanup();
    setLoading(false);
  }, [
    type,
    id,
    selectedProfile,
    ourTemplate,
    profileData,
    fieldMappings,
    selectedProfileId,
    fileMap,
    cardLink,
    projectMap,
    customers,
    usersMap,
    equipmentMap,
    isFiltered,
    filteredSnapshotData,
    shouldMapFilteredData,
    draftListMap,
    circularFieldIds,
  ]);

  const onSubmitClicked = useCallback(() => {
    if (onSubmit) {
      onSubmit({
        selectedForm,
        fieldMappings,
        mappingName,
        useFormPermissions,
      });
      cleanup();
    } else {
      onSubmitCreateForm();
    }
  }, [
    onSubmit,
    onSubmitCreateForm,
    selectedForm,
    fieldMappings,
    mappingName,
    useFormPermissions,
  ]);

  const onMappingChange = useCallback((mappingId) => {
    const newMapping = formMappings.find((mapping) => mapping.id === mappingId);
    if (newMapping) {
      const {
        name,
        fieldMappings: newFieldMappings = {},
        formTemplateId,
        useFormPermissions: newUseFormPerm,
      } = newMapping;
      setMappingName(name);
      setFieldMappings(newFieldMappings);
      setOurSelectedForm(formTemplateId);
      setSelectedMapping(mappingId);
      setUseFormPermissions(newUseFormPerm);
    } else {
      cleanup();
    }
  }, [formMappings]);

  const onNameChange = useCallback(async (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setMappingName(value);
  }, []);

  useEffect(() => {
    if (data && data.id && visible) {
      const {
        name,
        fieldMappings: newFieldMappings = {},
        formTemplateId,
        useFormPermissions: newUseFormPerm,
      } = data;
      setMappingName(name);
      setFieldMappings(newFieldMappings);
      setOurSelectedForm(formTemplateId);
      setSelectedMapping(data.id);
      setUseFormPermissions(newUseFormPerm);
    }
  }, [data, visible]);

  const onFormChange = useCallback((form) => {
    setSelectedMapping();
    setFieldMappings({});
    setUseFormPermissions(false);
    setOurSelectedForm(form);
  }, []);

  useEffect(() => {
    if (visible && selectedProfileId && type === 'card') {
      dispatch(getBoardCardFiles(selectedProfileId));
    }
    if (visible && selectedProfileId && type === 'equipment') {
      dispatch(getEquipmentFiles(selectedProfileId));
    }
  }, [visible, selectedProfileId]);

  const body = (
    profileIsLoading
      ? (
        <Row style={{ height: '100%', width: '100%' }} align="middle" justify="center">
          <Spin />
        </Row>
      )
      : (
        <MappingSelector
          onChange={onMappingChange}
          selected={selectedMapping}
          isSettings={isSettings}
          type={type}
          profileTypeId={profileTypeId}
          divisions={divisions}
        />
      )
  );

  const draftTableColumns = [
    {
      title: 'Draft Name',
      dataIndex: 'name',
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdated',
      render: (lastUpdated) => (lastUpdated
        ? DateTime.fromMillis(lastUpdated).toLocaleString(DateTime.DATETIME_SHORT)
        : '-'),
    },
  ];

  return (
    <Drawer
      title={`Generate Form from ${toTitleCase(type)}`}
      width={1000}
      onClose={onCloseClicked}
      visible={visible}
      bodyStyle={{
        padding: '0px 24px',
        marginBottom: 53,
      }}
    >
      { !selectedProfileId && (
        <Row gutter={25}>
          <Col span={16}>
            <Row style={{ marginTop: 5 }}>
              Mapping Name
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <OnTraccrTextInput value={mappingName} onChange={onNameChange} />
            </Row>
          </Col>
          <Col span={8}>
            <Row style={{ marginTop: 5 }} gutter={10}>
              <Col>Use Form Permissions</Col>
              <Col>
                <HoverHelp
                  content={(
                    <div style={{ maxWidth: 300 }}>
                      If checked, this form will only be available to users and roles
                      assigned to the form
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <OnTraccrCheckbox
                value={useFormPermissions}
                onChange={setUseFormPermissions}
              />
            </Row>
          </Col>
        </Row>
      ) }
      {selectedProfileId && (
        body
      )}
      {!profileIsLoading && (
        <FormSelector
          divisions={divisions}
          onChange={onFormChange}
          selected={selectedForm}
          hideExternalForms
        />
      )}
      {selectedForm && (
        <>
          {selectedProfileId && (
            <>
              <div>
                Configure which fields from this
                {' '}
                <b>{selectedTitle}</b>
                {' '}
                {type} are loaded into the fields for the
                {' '}
                <b>{targetName}</b>
                {' '}
                form
                {' '}
              </div>
              { type === 'card' && (
                <div style={{ marginTop: 10 }}>
                  <Checkbox
                    checked={shouldMapFilteredData}
                    onChange={(e) => setShouldMapFilteredData(e.target.checked)}
                    disabled={!isFiltered}
                  >
                    Map filtered data into the form
                  </Checkbox>
                </div>
              )}
            </>
          )}
          <Divider />
          <Table
            dataSource={flattenedTargetFields}
            columns={columns}
            pagination={false}
            size="small"
            rowKey="id"
          />
        </>
      )}
      {!profileIsLoading && selectedProfileId && type === 'card' && draftList.length > 0 && (
        <FormsTable
          title="Active Drafts"
          data={draftList}
          onClickRow={onSubmitCreateForm}
          columns={draftTableColumns}
          loading={loading}
          style={{ marginTop: 20 }}
        />
      )}
      <DrawerSubmitFooter
        onClose={onCloseClicked}
        onSubmit={onSubmitClicked}
        loading={profileIsLoading || loading || !ourTemplate?.formData}
        submitTitle={!selectedProfileId ? 'Save' : 'Continue'}
        canSubmit={!!selectedForm && ourTemplate?.formData && !profileIsLoading}
      />
    </Drawer>
  );
}

/* eslint-disable react/forbid-prop-types */
CustomFieldFormMappingDrawer.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.object,
  type: PropTypes.string.isRequired,
  selectedProfileId: PropTypes.string,
  profileTypeId: PropTypes.string,
  divisions: PropTypes.array,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isSettings: PropTypes.bool,
};

CustomFieldFormMappingDrawer.defaultProps = {
  visible: false,
  data: {},
  selectedProfileId: null,
  profileTypeId: null,
  divisions: [],
  onSubmit: null,
  onClose: null,
  isSettings: false,
};
