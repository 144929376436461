import React from 'react';
import {
  Row, DatePicker, TimePicker, Col,
} from 'antd';
import moment from 'moment';
import { DateTime } from 'luxon';
import TitleRow from './TitleRow';

import FieldTriggerFlag from './FieldTriggerFlag';
import { localToZoneTS, zoneToLocalTS } from '../../../clock/ManualEntry/manualEntryHelpers';
import DateTimeSelectionCheckbox from './DateTimeSelectionCheckbox';
import titleOnlyConfigure from './titleOnlyConfigure';

const DATE_FORMAT = 'MMM Do YY';
const TIME_FORMAT = 'hh:mm a';
const TWENTY_FOUR_HOUR_TIME_FORMAT = 'HH:mm';

const configure = ({
  id,
  setConfigProps,
  configProps = {},
  setCanSubmit,
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  contactAddressBooks = {},
  labels = [],
  buckets = [],
} = {}) => {
  const titleOnlyConfig = titleOnlyConfigure({
    id,
    setConfigProps,
    configProps,
    setCanSubmit,
    sections,
    isExternalForm,
    templateId,
    divisions,
    projectId,
    name,
    isBoardCards,
    setFieldTriggerEditable,
    disableOptional,
    customers,
    projects,
    users,
    costcodes,
    phases,
    projectIdMap,
    vendors,
    equipment,
    formTemplates,
    contactAddressBooks,
    labels,
    buckets,
  });

  const setHideDate = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;

    const {
      hideTime,
    } = configProps;

    if (hideTime && checked) {
      setConfigProps({
        ...configProps,
        hideTime: false,
        hideDate: checked,
      });
    } else {
      setConfigProps({
        ...configProps,
        hideDate: checked,
      });
    }
  };

  const setHideTime = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;

    const {
      hideDate,
    } = configProps;

    if (hideDate && checked) {
      setConfigProps({
        ...configProps,
        hideDate: false,
        hideTime: checked,
      });
    } else {
      setConfigProps({
        ...configProps,
        hideTime: checked,
      });
    }
  };

  const setUse24HourTime = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;

    setConfigProps({
      ...configProps,
      use24HourTime: checked,
    });
  };

  return (
    <div>
      {titleOnlyConfig}

      <DateTimeSelectionCheckbox
        setHideDate={setHideDate}
        setHideTime={setHideTime}
        setUse24HourTime={setUse24HourTime}
        configProps={configProps}
        setConfigProps={setConfigProps}
      />
    </div>
  );
};

const preview = ({
  id,
  configProps = {},
  responses = {}, // Actively responding
  previewProps = {}, // Completed form
  setPreviewProps,
  setResponses,
  responding = false,
  sections,
  isExternalForm,
  projectId,
  templateId,
  divisions,
  isDisplay,
  onFieldTrigger,
} = {}) => {
  const {
    optional,
    title = 'Title goes here',
    fieldTrigger,
    hideDate = false,
    hideTime = false,
    use24HourTime = false,
  } = configProps;

  const {
    [id]: ourResponses = {},
  } = responses;

  const {
    date,
    time,
    timezone = DateTime.local().zoneName,
  } = responding ? ourResponses : previewProps;

  const dateMoment = date ? moment(zoneToLocalTS(date, timezone)) : null;
  const timeMoment = time ? moment(zoneToLocalTS(time, timezone)) : null;

  const onDateChanged = (key) => (newDateMoment) => {
    const newDate = newDateMoment
      ? localToZoneTS(newDateMoment.valueOf(), timezone)
      : newDateMoment;
    if (responding) {
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id] ?? {}),
          [key]: newDate,
          timezone,
        },
      });
    } else {
      setPreviewProps({
        ...previewProps,
        [key]: newDate,
        timezone,
      });
    }
  };

  return (
    <div style={{ minWidth: 200 }}>
      <TitleRow
        title={title}
        optional={optional}
        filter={
          fieldTrigger && !isDisplay
            ? (
              <FieldTriggerFlag
                sections={sections}
                isExternalForm={isExternalForm}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                onFieldTrigger={onFieldTrigger}
              />
            ) : null
        }
      />

      <Row
        style={{
          margin: '10px 0px',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
        }}
        gutter={0}
      >

        {!hideDate
          && (
            <Col style={{ flexGrow: '1' }}>
              <DatePicker
                style={{
                  width: '100%',
                }}
                onChange={onDateChanged('date')}
                value={dateMoment}
                format={DATE_FORMAT}
              />
            </Col>
          )}

        {!hideTime
          && (
            <Col style={{ flexGrow: '1' }}>
              <TimePicker
                style={{
                  width: '100%',
                }}
                onChange={onDateChanged('time')}
                value={timeMoment}
                format={use24HourTime ? TWENTY_FOUR_HOUR_TIME_FORMAT : TIME_FORMAT}
                use12Hours={!use24HourTime}
              />
            </Col>
          )}
      </Row>
    </div>
  );
};

export default {
  configure,
  preview,
  title: 'Date/Time',
  description: 'This field allows users to pick a single date/time',
};
