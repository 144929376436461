import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Popover, Row, Col } from 'antd';
import {
  PaperClipOutlined,
  AlertOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import ProfileCircle from '../common/profile/ProfileCircle';
import ProfileAvatar from '../common/ProfileAvatar';
import Permissions from '../auth/Permissions';

import {
  positionToQuarter,
  quarterToText,
  getTextColor,
  getBackgroundColor,
  getStatusIcon
} from './scheduleHelpers';

import { getIdMap } from '../helpers/helpers';

import colors from '../constants/Colors';
import { BOTTOM_BUFFER } from './schedule.constants';

const userCircleFromScale = (scale) => {
  if(scale < 0.75) return 10;
  if(scale < 1.25) return 12;
  return 14;
}
const prefixFromScale = (scale,realHeight) => {
  if(scale === 0.25 && realHeight < 10) return 'schedule-item-micro';
  if(scale < 0.75) return 'schedule-item-small';
  if(scale < 1.25) return 'schedule-item-regular';
  return 'schedule-item-large';
}

const getClassName = (isWeek, scale, realHeight) => {
  const suffix = isWeek ? 'schedule-item-week' : 'schedule-item';
  return `${prefixFromScale(scale,realHeight)} ${suffix}`
}

const FileView = ({ files = [] }) => {
  const fl = files.length;
  if(fl === 0) return null;
  return(
    <div>
      <br/>
      <PaperClipOutlined style={{ marginRight: 5 }}/>
      <span>{fl.toString()} file{fl === 1 ? '' :'s'} attached</span>
    </div>
  );
};

const UserView = ({ name, hasMultiple, scheduleColor }) => {
  const scale = useSelector(state => state.schedule.scale);
  if(!name) return null;
  const [first = ' ',last = ' '] = name.split(' ').filter((word) => word && word.length);
  const initials = first[0] + last[0];
  const circleStyle = hasMultiple && scheduleColor
  ? { backgroundColor: scheduleColor }
  : {};
  const textColor = hasMultiple && scheduleColor ? getTextColor(scheduleColor) : undefined;
  return (
    <ProfileCircle
      radius={userCircleFromScale(scale)}
      initials={initials}
      className='schedule-item-user-circle'
      style={circleStyle}
      accentColor={textColor}
    />
  );
};

const UserListView = ({ shiftId, users = [], userMap = {} }) => {
  const ul = users.length;
  if(ul === 0) return null; // Should only happen if the shift is not complete
  const fullUsers = users.map((userId) => userMap[userId] || {});
  return (
    <Popover
      placement='bottomRight'
      content={
        <div className='schedule-item-user-popover-container'>
          {
            fullUsers.map((user, idx) => (
              <div key={shiftId + user.id}>
                <ProfileAvatar
                  role={user.position}
                  name={user.name}
                />
                {idx < fullUsers.length - 1 &&
                  <div className='schedule-item-user-popover-divider'/>
                }
              </div>
            ))
          }
        </div>
      }
    >
      <div className='schedule-item-user-list'>
        {fullUsers.map((user) => (
          <UserView key={shiftId + user.id} {...user} hasMultiple={users.length > 1}/>
        ))}
      </div>
    </Popover>
  );
};

const ProjectInfo = ({ value, type }) => {
  if(!value) return null;
  return (
    <>
    <br/>
    {type}: {value}
    </>
  )
}

export default (props) => {
  const ourProps = {...props};
  const {
    style = {},
    isWeek,
    shift: {
      id,
      top,
      bottom,
      left,
      width,
      title,
      files,
      users,
      zIndex = 1,
      projectId,
      phaseId,
      costcodeId,
      isEvent,
      color,
      isDraft,
      appliedUsers,
    },
    allDay,
  } = ourProps;
  delete ourProps.shift;
  delete ourProps.style;
  delete ourProps.isWeek;
  delete ourProps.hideTime;
  delete ourProps.allDay;

  const { t } = useTranslation();
  const scale = useSelector(state => state.schedule.scale);
  const userMap = useSelector((state) => {
    const {
      users: {
        users: stateUsers = [],
      } = {},
    } = state;
    return getIdMap(stateUsers);
  });
  const projectMap = useSelector((state) => {
    const {
      projects: {
        projects: stateProjects = [],
      } = {},
    } = state;
    return getIdMap(stateProjects);
  });
  const { phaseMap, costcodeMap } = useSelector((state) => {
    const {
      costcodes: {
        phases: statePhases = [],
        costcodes: stateCostcodes = [],
      } = {},
    } = state;
    return {
      phaseMap: getIdMap(statePhases),
      costcodeMap: getIdMap(stateCostcodes)
    };
  });

  const isMyShift = useMemo(() => users && users.includes(Permissions.id),[users]);
  const [currentUserStatus, setCurrentUserStatus] = useState();

  useEffect(() => {
    if (appliedUsers && appliedUsers.length === 0) return;
    const shiftStatus = appliedUsers?.find((u) => u.userId === Permissions.id)?.status;
    setCurrentUserStatus(shiftStatus);
  }, [ourProps, appliedUsers]);

  const projectName = useMemo(() => {
    const {
      [projectId]:{
        name,
      } = {},
    } = projectMap;
    return name;
  },[projectId,projectMap]);

  const phaseName = useMemo(() => {
    const {
      [phaseId]:{
        name,
      } = {},
    } = phaseMap;
    return name;
  },[phaseId,phaseMap]);

  const costcodeText = useMemo(() => {
    const {
      [costcodeId]:{
        code,
        description,
      } = {},
    } = costcodeMap;
    if(!code || !description) return null;
    if(isWeek || width <= 35) return code;
    return `${code} - ${description}`;
  },[costcodeId, costcodeMap, isWeek, width]);

  const backgroundColor = getBackgroundColor(users, userMap, color, isMyShift, isEvent, isDraft);

  const textColor = useMemo(() => (
    isEvent
    ? colors.ONTRACCR_BLACK
    : getTextColor(backgroundColor)
  ), [isEvent, backgroundColor]);

  const body = useMemo(() => {
    const start = quarterToText(positionToQuarter(top));
    const end = quarterToText(positionToQuarter(bottom ? bottom : top + 25));
    const text = `${start} - ${end}`;
    if(!title) return (
      <div className='schedule-item-text' style={{ color: textColor }}>
        {text}
      </div>
    );
    const dayBreakPoints = [20,50,75,150];
    const weekBreakPoints = [20,100,150,300];
    const breakPoints = isWeek ? weekBreakPoints : dayBreakPoints;
    const height = (bottom ? bottom - top : 0) * scale;
    const heightThresh = isWeek ? 100 : 50;
    if(allDay) return (
      <div className='schedule-item-text' style={{ color: textColor }}>
        {title} {isDraft ? getStatusIcon(currentUserStatus) : null}
      </div>
    )
    if(height < heightThresh) {
      return (
        <div className='schedule-item-text' style={{ color: textColor }}>
        {`${title}  ${text}`} {isDraft ? getStatusIcon(currentUserStatus) : null}
      </div>
      )
    }
    return (
      <div className='schedule-item-text' style={{ color: textColor }}>
        <Row justify='space-between'>
          <Col>
            {title}
            <br/>{text}
            <br/>
            {height > breakPoints[1]
              &&
              <ProjectInfo value={projectName} type={t('Project')}/>
            }
            {height > breakPoints[2]
              &&
              <ProjectInfo value={phaseName} type='Phase'/>
            }
            {height > breakPoints[2]
              &&
              <ProjectInfo value={costcodeText} type='Cost Code'/>
            }
            {height > breakPoints[3] && <FileView files={files}/>}
          </Col>
          <Col>
            {
              isEvent ?
              <AlertOutlined style={{ color: textColor, position:'absolute', top: -2, right: 2 }}/>
              : null
            }
            {!isDraft ? <UserListView shiftId={id} users={users} userMap={userMap}/> : null}
          </Col>
          {isDraft ? 
            <Col> 
              {getStatusIcon(currentUserStatus, '18px', '1rem')}
            </Col> 
          : null}
        </Row>
      </div>
    );
  },[
    id,
    allDay,
    title, top, bottom,
    files, users, userMap, isWeek, scale,
    projectName, phaseName, costcodeText, textColor
  ]);

  const realTop = (top * scale) + 10;
  const realHeight = (bottom ? bottom - top - BOTTOM_BUFFER : 25) * scale;
  return (
    <div
      key={title}
      className={getClassName(isWeek,scale,realHeight)}
      style={{
        top: realTop,
        height: realHeight,
        width: `${width}%`,
        left: `${left}%`,
        zIndex,
        backgroundColor,
        ...style,
        color: textColor,
        opacity: isDraft ? 0.5 : 1,
      }}
      {...ourProps}
    >
      {!allDay && <div className='schedule-item-top-anchor'/>}
      {body}
      {!allDay && <div className='schedule-item-bottom-anchor'/>}
    </div>
  );
}