import { DateTime } from 'luxon';
import { safeExtractFromObject } from './helpers';

export const counters = {
  tablets: {
    getTablets: null,
  },
  timecards: {
    getCustomFields: null,
  },
  users: {
    getUsers: null,
    getUserTeams: null,
  },
  customers: {
    getCustomers: null,
    getCustomerLabels: null,
  },
  equipment: {
    getEquipment: null,
    getEquipmentTypes: null,
  },
  forms: {
    getCustomTables: null,
    getAssignedFormTemplates: null,
    getSubContractDetails: {},
  },
  projects: {
    getProjects: null,
    getProjectScheduleOfValues: null,
    getScheduleOfValueSections: null,
    getProjectEquipment: null,
  },
  labels: {
    getLabels: null,
    getVendorLabels: null,
  },
  costcodes: {
    getAll: null,
  },
};

export const counterWasRecentlyCalled = (stringPath, debounceTime = 1) => {
  const now = DateTime.local();
  const ourCounter = safeExtractFromObject(counters, stringPath);

  if (ourCounter && now.diff(ourCounter).as('minutes') < debounceTime) return true;
  return false;
};

export const resetCounters = () => {
  Object.keys(counters).forEach((topLevelKey) => {
    const subLevel = counters[topLevelKey];
    Object.keys(subLevel).forEach((subKey) => {
      subLevel[subKey] = null;
    });
  });
};

export default {};
