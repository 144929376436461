export const validFieldTypes = new Set([
  'text',
  'attribute',
  'calculation',
  'yes-no',
  'dropdown',
  'table',
  'dateTime',
  'dateRange',
]);

export const validDropdownTypes = new Set([
  'Customers',
  'Custom',
  'Projects',
  'Users',
  'Costcodes',
  'Vendors',
  'Equipment',
  'Forms',
  'Labels',
  'Contacts',
  'Cards',
]);

export const hiddenColumns = {
  TimeEntry: {
    phase: true,
    costcode: true,
  },
  Materials: {
    addedDate: true,
    updatedDate: true,
  },
  Equipment: {
    addedDate: true,
    updatedDate: true,
  },
};

// Map table columns to search field configurations
const tableMap = {
  Materials: {
    name: { type: 'text' },
    description: { type: 'text' },
    partNumber: { type: 'text' },
    units: { type: 'number' },
    price: { type: 'number' },
    quantity: { type: 'number' },
    location: { type: 'dropdown', dataType: 'MaterialLocations' },
    toLocation: { type: 'dropdown', dataType: 'MaterialLocations' },
    debitOrCredit: {
      type: 'dropdown',
      dataType: [
        { id: 'Debit', name: 'Debit' },
        { id: 'Credit', name: 'Credit' },
      ],
    },
    total: { type: 'number' },
    addedDate: { type: 'dateRange' },
    cost: { type: 'number' },
    labourCost: { type: 'number' },
    labourCostTotal: { type: 'number' },
    labourPriceTotal: { type: 'number' },
    labourAndMaterialTotal: { type: 'number' },
  },
  Labour: {
    name: { type: 'text' },
    rate: { type: 'number' },
    quantity: { type: 'number' },
    total: { type: 'number' },
  },
  ChangeOrder: {
    itemNumber: { type: 'text' },
    description: { type: 'text' },
    contractAmount: { type: 'number' },
    percentageComplete: { type: 'number' },
    progressToDate: { type: 'number' },
    previousBillings: { type: 'number' },
    invoiceAmount: { type: 'number' },
  },
  Shifts: {
    title: { type: 'text' },
    dates: { type: 'dateRange' },
    users: { type: 'dropdown', dataType: 'Users' },
    description: { type: 'text' },
  },
  TimeEntry: {
    user: { type: 'dropdown', dataType: 'Users' },
    date: { type: 'dateRange' },
    type: {
      type: 'dropdown',
      dataType: [
        { id: 'work', name: 'Work' },
        { id: 'break', name: 'Break' },
        { id: 'service', name: 'Service' },
        { id: 'overtime', name: 'Overtime' },
      ],
    },
    division: { type: 'dropdown', dataType: 'Divisions' },
    project: { type: 'dropdown', dataType: 'Projects' },
    phase: { type: 'dropdown', dataType: 'Time Phases' },
    costcode: { type: 'dropdown', dataType: 'Time Costcodes' },
    local: { type: 'dropdown', dataType: 'Locals' },
    class: { type: 'dropdown', dataType: 'Classes' },
    sageShift: { type: 'text' }, // ..??
    hourBased: { type: 'boolean' },
    time: { type: 'text' },
    note: { type: 'text' },
    status: {
      type: 'dropdown',
      dataType: [
        { id: 'unsubmitted', name: 'Unsubmitted' },
        { id: 'submitted', name: 'Submitted' },
        { id: 'approved', name: 'Approved' },
      ],
    },
    enteredVia: { type: 'text' }, // ..?
  },
  Equipment: {
    name: { type: 'text' },
    code: { type: 'text' },
    hours: { type: 'number' },
    hourlyCost: { type: 'cost' },
    hourlyBillingRate: { type: 'cost' },
    dailyCost: { type: 'cost' },
    dailyBillingRate: { type: 'cost' },
    totalHourlyCost: { type: 'cost' },
    totalHourlyBilling: { type: 'cost' },
    addedDate: { type: 'dateRange' },
  },
};

export const getTableColumnType = (table, column) => tableMap[table]?.[column] || { type: 'text' };
