import { currencyFormatter } from '../../../helpers/inputParsers';

import { getNumberText } from '../helpers';

export const getBase64ImageFromURL = (url) => new Promise((resolve, reject) => {
  const img = document.createElement('img');
  img.setAttribute('crossOrigin', 'anonymous');
  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    resolve(dataURL);
  };
  img.onerror = (error) => {
    reject(error);
  };
  img.src = url && url instanceof File ? URL.createObjectURL(url) : url;
});

export const getTableRow = (row, includeNumberCol = false) => {
  const rows = [{
    text: getNumberText(row),
    style: 'tableCell',
    alignment: 'center',
  }];
  if (includeNumberCol) {
    rows.push({ text: row.number ?? '', style: 'tableCell' });
  }

  rows.push(
    { text: row.description, style: 'tableCell' },
    { text: currencyFormatter(row.contractAmount), style: 'tableCell' },
    {
      text: `${(row.percentageComplete * 100).toFixed(2)}%`,
      style: 'tableCell',
      alignment: 'center',
    },
    { text: currencyFormatter(row.progressToDate), style: 'tableCell' },
    { text: currencyFormatter(row.previousBillings), style: 'tableCell' },
    { text: currencyFormatter(row.invoiceAmount), style: 'tableCell' },
  );

  return rows;
};

export const getSubContractCOHeader = (text) => [{
  text,
  style: 'tableHeader',
  alignment: 'left',
  colSpan: 8,
}];

export const getChangeOrderSummary = (summaryRow, includeEmpty = false) => [
  {
    text: 'TOTAL CHANGES',
    style: 'tableHeader',
    alignment: 'right',
    colSpan: 2,
  },
  {},
  ...includeEmpty
    ? [{}] : [],
  {
    text: currencyFormatter(summaryRow.totalChanges),
    style: 'tableHeader',
    alignment: 'left',
  },
  {
    text: `${(
      summaryRow.totalChanges
        ? ((summaryRow.changeOrderProgressToDate / summaryRow.totalChanges) * 100)
        : 0
    ).toFixed(2)}%`,
    style: 'tableHeader',
  },
  {
    text: currencyFormatter(summaryRow.changeOrderProgressToDate),
    style: 'tableHeader',
    alignment: 'left',
  },
  {
    text: currencyFormatter(summaryRow.changeOrderPreviousBillings),
    style: 'tableHeader',
    alignment: 'left',
  },
  {
    text: currencyFormatter(summaryRow.changeOrderInvoiceAmount),
    style: 'tableHeader',
    alignment: 'left',
  },
];

export const getNonHoldbackSummary = (summaryRow) => [
  {
    text: 'TOTAL NON-HOLDBACK',
    style: 'tableHeader',
    alignment: 'right',
    colSpan: 2,
  },
  {},
  {
    text: currencyFormatter(summaryRow.totalNonHoldbackAmount),
    style: 'tableHeader',
    alignment: 'left',
  },
  {
    text: `${(
      summaryRow.totalNonHoldbackAmount
        ? ((summaryRow.nonHoldbackProgressToDate / summaryRow.totalNonHoldbackAmount) * 100)
        : 0
    ).toFixed(2)}%`,
    style: 'tableHeader',
  },
  {
    text: currencyFormatter(summaryRow.nonHoldbackProgressToDate),
    style: 'tableHeader',
    alignment: 'left',
  },
  {
    text: currencyFormatter(summaryRow.nonHoldbackPreviousBillings),
    style: 'tableHeader',
    alignment: 'left',
  },
  {
    text: currencyFormatter(summaryRow.nonHoldbackInvoiceAmount),
    style: 'tableHeader',
    alignment: 'left',
  },
];

export const basePdfDefinition = {
  styles: {
    tableHeader: {
      bold: true,
      fontSize: 10,
      color: 'black',
      alignment: 'center',
    },
    tableCell: {
      fontSize: 10,
    },
    pdfHeader: {
      marginBottom: 30,
    },
    pdfHeaderTable: {
      marginLeft: 50,
    },
    pdfFooter: {
      fontSize: 10,
    },
  },
};
