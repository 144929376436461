import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { Table } from 'antd';

import sortByString, { getIdMap } from '../../../helpers/helpers';
import UserAssignmentSelector from '../../FormWorkflows/selectors/UserAssignmentSelector';
import BorderlessButton from '../../../common/buttons/BorderlessButton';

const types = [
  'User',
  'Role',
];

export default function TableFieldColumnPermissions({
  onPermissionChanged,
  permissions,
  divisions,
}) {
  const users = useSelector((state) => state.users.users);
  const userMap = useMemo(() => getIdMap(users), [users]);
  const positionNames = useSelector((state) => state.settings.positionNames);
  const positionMap = useMemo(() => getIdMap(positionNames), [positionNames]);

  const onAdd = useCallback((newId) => {
    if (!newId) return;
    let type;
    if (positionMap[newId]) {
      type = 'Position';
    }
    if (userMap[newId]) {
      type = 'User';
    }
    onPermissionChanged({
      ...permissions,
      [newId]: {
        id: newId,
        type,
      },
    });
  }, [onPermissionChanged, permissions, userMap, positionMap]);

  const onDelete = useCallback(({ id } = {}) => () => {
    if (!id) return;
    const newValue = { ...permissions };
    delete newValue[id];
    onPermissionChanged(newValue);
  }, [onPermissionChanged, permissions]);

  const tableValues = useMemo(() => (
    Object.values(permissions).map((record) => {
      let name;
      switch (record.type) {
        case 'Position':
          name = positionMap[record.id]?.name;
          break;
        case 'User':
          name = userMap[record.id]?.name;
          break;
        default:
          name = '';
          break;
      }
      return {
        ...record,
        name,
      };
    })
  ), [permissions, userMap, positionMap]);

  const cols = useMemo(() => [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 125,
      filters: types.map((name) => ({
        text: name,
        value: name,
      })),
      onFilter: (type, record) => record.type === type,
      sorter: sortByString('type'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: sortByString('name'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '',
      dataIndex: '',
      width: 50,
      render: (_1, record) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: 'red' }} />}
          onClick={onDelete(record)}
        />
      ),
    },
  ], [onDelete]);

  return (
    <>
      <UserAssignmentSelector
        text="Restrict Permissions"
        type="users"
        divisions={divisions}
        onChange={onAdd}
        mode="single"
        showPositions
      />
      <div className="file-list-container" style={{ marginTop: 15 }}>
        <Table
          size="small"
          pagination={false}
          columns={cols}
          dataSource={tableValues}
          style={{ width: '100%', height: '100%' }}
          rowKey="id"
          locale={{ emptyText: 'No restrictions' }}
        />
      </div>
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
TableFieldColumnPermissions.propTypes = {
  divisions: PropTypes.array,
  onPermissionChanged: PropTypes.func,
  permissions: PropTypes.object,
};

TableFieldColumnPermissions.defaultProps = {
  divisions: undefined,
  permissions: {},
  onPermissionChanged: () => {},
};
