import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import CompanyEditRow from './CompanyEditRow';
import RoleCheckboxes from './RoleCheckboxes';
import {
  reports,
  writeControlledPermissions,
  checkBoxDisabled,
} from './permissionConstants';

const formatPerm = (key, appendKey) => `${key.toUpperCase()}${appendKey ? '_WRITE' : ''}`;
const isChecked = (position, key, appendKey) => (
  position?.some((perm) => perm === formatPerm(key, appendKey))
);

function RolePermissonRow({
  roleName,
  permission: {
    key,
    title,
    helpText,
    multi = false,
    divider = true,
    style,
    appendKey = true,
    CustomView,
  } = {},
  onCheckChanged,
}) {
  const { t } = useTranslation();

  const settings = useSelector((state) => state.settings.company?.settings);
  const positions = useSelector((state) => state.settings.positions);

  let body;
  if (CustomView) {
    body = <CustomView roleName={roleName} />;
  } else {
    body = multi
      ? (
        RoleCheckboxes({
          myRole: roleName,
          positions,
          key,
          reports,
          callback: onCheckChanged,
          disabled: key === 'manual_entry' && !settings?.enableManualEntry,
          t,
        })
      )
      : (
        <Checkbox
          onChange={() => {
            onCheckChanged(formatPerm(key, appendKey));
            const myPerms = positions?.[roleName] ?? [];
            if (writeControlledPermissions.has(key)) {
              const isWorkDoneKey = key === 'reports_work_done_view';
              let hasRead = false;
              let hasWrite = false;
              let readKey;
              let writeKey;
              if (isWorkDoneKey) {
                readKey = 'REPORTS_WORK_DONE_VIEW';
                writeKey = 'REPORTS_WORK_DONE_EDIT';
              } else {
                const keyArray = key.split('_');
                keyArray.pop();
                const keyType = keyArray.join('_').toUpperCase();
                readKey = `${keyType}_READ`;
                writeKey = `${keyType}_WRITE`;
              }
              myPerms.forEach((perm) => {
                if (perm === readKey) hasRead = true;
                if (perm === writeKey) hasWrite = true;
              });
              // Toggle WRITE off if READ is toggled off
              if (hasRead && hasWrite) onCheckChanged(writeKey);
            }

            if (key === 'teams' && !myPerms.includes('TEAMS_WRITE_ASSIGNED')) {
              onCheckChanged('TEAMS_WRITE_ASSIGNED');
            }
          }}
          style={{ marginRight: 56 }}
          checked={isChecked(positions?.[roleName], key, appendKey)}
          disabled={checkBoxDisabled(key, positions?.[roleName], roleName)}
        />
      );
  }

  return (
    <CompanyEditRow
      key={key}
      title={title}
      helpText={helpText}
      divider={divider}
      style={style}
      childrenOnNewLine={!!CustomView}
    >
      {body}
    </CompanyEditRow>
  );
}

RolePermissonRow.propTypes = {
  roleName: PropTypes.string.isRequired,
  permission: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    helpText: PropTypes.string,
    multi: PropTypes.bool,
    divider: PropTypes.bool,
    style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    appendKey: PropTypes.bool,
    CustomView: PropTypes.func,
  }).isRequired,
  onCheckChanged: PropTypes.func.isRequired,
};

export default RolePermissonRow;
