import React, { useCallback, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  Row, Col, Typography, Popover, Checkbox,
  Divider,
  Tag,
} from 'antd';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  DragOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import BorderlessButton from '../../../common/buttons/BorderlessButton';
import CalculationDropdown from './CalculationDropdown';
import FormCheckbox from '../../FormDetails/FormCheckbox';
import DisplayText from '../../../common/text/DisplayText';
import { PERMISSION_EXCLUDED_COLUMNS } from './TimeEntryTable/TimeEntryTable.constants';
import TableFieldColumnPermissions from './TableFieldColumnPermissions';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import colors from '../../../constants/Colors';

function TableFieldColumnCard({
  fieldId,
  sectionId,
  sections = [],
  id = '',
  orderIndex,
  name,
  isCalculation,
  isReadOnly,
  onDeleteClicked,
  configProps,
  setConfigProps,
  dataType,
  divisions,
  originalName,
  onTitleChanged,
}) {
  const {
    formulaMap = {},
    tagsMap = {},
    columnPermissions = {},
  } = configProps;

  const col = useMemo(() => {
    const { columns = [] } = configProps;
    return columns[orderIndex] || {};
  }, [configProps, orderIndex]);

  const formula = formulaMap[id];
  const tags = tagsMap[id] || [];
  const onFormulaChange = ((newFormula) => {
    setConfigProps({
      ...configProps,
      formulaMap: {
        ...formulaMap,
        [id]: newFormula,
      },
    });
  });

  const onTagsChange = ((newTags) => {
    setConfigProps({
      ...configProps,
      tagsMap: {
        ...tagsMap,
        [id]: newTags,
      },
    });
  });

  const onColumnSettingChange = useCallback((key, value) => {
    setConfigProps((prevConfigProps) => {
      const { columns = [] } = prevConfigProps;
      const newColumns = columns.map((column) => {
        if (column.key !== id) {
          return column;
        }
        return {
          ...column,
          [key]: value,
        };
      });

      return {
        ...prevConfigProps,
        columns: newColumns,
      };
    });
  }, [setConfigProps, id]);

  const onReadOnlyChange = useCallback((e) => {
    const { target: { checked = false } } = e;
    onColumnSettingChange('isReadOnly', checked);
  }, [onColumnSettingChange]);

  const onColumnPermissionChange = useCallback((newPermissions) => {
    setConfigProps({
      ...configProps,
      columnPermissions: {
        ...columnPermissions,
        [id]: newPermissions,
      },
    });
  }, [configProps, columnPermissions, id]);

  const showColumnPermissions = dataType === 'TimeEntry' && !PERMISSION_EXCLUDED_COLUMNS.has(id);

  const columnSettings = useMemo(() => {
    const settings = (
      <Col style={{ width: showColumnPermissions ? '30em' : '15em' }}>
        <Row>
          <DisplayText title="Column Settings" />
        </Row>
        <FormCheckbox
          title="Hide in PDF"
          value={col.isHideInPDF}
          onCheckChange={(val) => onColumnSettingChange('isHideInPDF', val)}
          tooltip="Check this to hide this column in the PDF preview and export"
        />
        {
          isCalculation && (
          <FormCheckbox
            title="Is Currency?"
            value={col.isCurrency}
            onCheckChange={(val) => onColumnSettingChange('isCurrency', val)}
            tooltip="Check this box if you want the result to have a '$' prefix (e.g. $25.00)"
          />
          )
        }
        {showColumnPermissions && (
          <>
            <Divider />
            <TableFieldColumnPermissions
              divisions={divisions}
              permissions={columnPermissions[id]}
              onPermissionChanged={onColumnPermissionChange}
            />
          </>
        )}
      </Col>
    );

    return settings;
  }, [
    col,
    isCalculation,
    onColumnSettingChange,
    divisions,
    showColumnPermissions,
    columnPermissions,
    id,
  ]);

  return (
    <Draggable draggableId={id.toString()} index={orderIndex}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          ref={innerRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...draggableProps}
          className="boards-status-card"
        >
          <Row justify="space-between" align="middle">
            <Col flex="18px">
              <Row justify="center" style={{ height: '100%', paddingRight: 5 }} align="middle">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <DragOutlined {...dragHandleProps} className="boards-status-dragger" />
              </Row>
            </Col>
            <Col flex="auto">
              {dataType === 'TimeEntry' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: 5,
                  }}
                >
                  <OnTraccrTextInput
                    value={name}
                    onChange={(e) => onTitleChanged({ id, title: e.target.value })}
                    style={{ maxWidth: '300px' }}
                  />
                  {name !== originalName && (
                  <div style={{
                    display: 'flex', alignItems: 'center', gap: 6, flexShrink: 0,
                  }}
                  >
                    <Typography.Text type="secondary">System Default Name: </Typography.Text>
                    <Tag style={{ backgroundColor: colors.ONTRACCR_RED, color: 'white' }}>{originalName}</Tag>
                  </div>
                  )}
                </div>
              ) : (
                <div style={{ marginLeft: 5 }}>
                  <Typography.Text>
                    {name}
                  </Typography.Text>
                </div>
              )}
            </Col>
            {isCalculation && (
            <Col flex="350px">
              <CalculationDropdown
                id={fieldId}
                sectionId={sectionId}
                onFormulaChange={onFormulaChange}
                onTagsChange={onTagsChange}
                formula={formula}
                tags={tags}
                sections={sections}
                calculationColumn={id}
                hideExplanation
              />
            </Col>
            )}
            {id === 'quantityAllocated' && (
              <Col flex="350px">
                <Checkbox checked={isReadOnly} onChange={onReadOnlyChange}> Read Only </Checkbox>
              </Col>
            )}
            <Col flex="30px" />
            <Col flex="30px" style={{ position: 'relative' }}>
              <Popover
                trigger="click"
                content={columnSettings}
                placement="topLeft"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
              >
                <SettingOutlined style={{ margin: 0 }} />
              </Popover>
            </Col>
            <Col flex="30px">
              <BorderlessButton
                iconNode={<DeleteOutlined style={{ margin: 0, color: 'red' }} />}
                onClick={onDeleteClicked}
              />
            </Col>
          </Row>
        </div>
      )}
    </Draggable>
  );
}

export default TableFieldColumnCard;

TableFieldColumnCard.propTypes = {
  fieldId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  id: PropTypes.string,
  orderIndex: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isCalculation: PropTypes.bool,
  onDeleteClicked: PropTypes.func.isRequired,
  configProps: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    formulaMap: PropTypes.shape({}),
    tagsMap: PropTypes.shape({}),
    columnPermissions: PropTypes.shape({}),
  }),
  setConfigProps: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  isReadOnly: PropTypes.bool,
  dataType: PropTypes.string,
  divisions: PropTypes.arrayOf(PropTypes.string),
  originalName: PropTypes.string.isRequired,
  onTitleChanged: PropTypes.func.isRequired,
};

TableFieldColumnCard.defaultProps = {
  id: '',
  isCalculation: false,
  configProps: {},
  sections: [],
  isReadOnly: false,
  dataType: null,
  divisions: undefined,
};
