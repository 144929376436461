import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Row, Col, Popover } from 'antd';
import { DateTime } from 'luxon';
import { DesktopOutlined, MobileOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import ProfileCircle from '../../common/profile/ProfileCircle';
import colors from '../../constants/Colors';
import { downloadFile, getFileDetails, getFileType } from '../../files/fileHelpers';
import FullPhoto from '../../common/photos/FullPhoto';
import HistoryEntryFileDisplay from './HistoryEntryFileDisplay';
import HistoryEntryDiff from './HistoryEntryDiff';
import HistoryEntryDetails from './HistoryEntryDetails';
import {
  PROGRESS_UPDATE_TYPE_ACTIVITY,
  PROGRESS_UPDATE_TYPE_CURRENCY,
  PROGRESS_UPDATE_TYPE_INVOICE_ADD,
  PROGRESS_UPDATE_TYPE_INVOICE_DELETE,
  PROGRESS_UPDATE_TYPE_INVOICE_UPDATE,
  PROGRESS_UPDATE_TYPE_LOGGED_HOURS,
  PROGRESS_UPDATE_TYPE_NOTES,
  PROGRESS_UPDATE_TYPE_QUANTITY,
  PROGRESS_UPDATE_TYPE_UPDATE_COST_ADD,
} from './historyConstants';
import { initialsFromName } from '../../helpers/users';

const bold = { fontFamily: 'roboto-bold' };

const DeviceIcon = ({device}) => {
  if (!device) return null;
  let title = 'Web';
  let icon = <DesktopOutlined/>;
  if (device === 'mobile') {
    title = 'Mobile';
    icon = <MobileOutlined />;
  }
  return (
    <Popover content={`Updated from the ${title} app`}>
      {icon}
    </Popover>
  );
};

export default function HistoryEntry({
  isSov,
  device,
  description = '',
  duration,
  timestamp,
  field,
  invoiceNumber,
  invoiceVendor ='',
  distributedAmount,
  pastValue,
  newValue,
  type,
  diff,
  files = [],
  isSubmission = false,
  isFirstEntry = false,
  templateName = '',
  user: {
    name: userName = '',
  } = {},
  costcodeIdMap = {},
  costcodeId,
  endTime,
  otEndTime,
  doubleOTEndTime,
  submissionTimestamp,
}) {
  const [selectedFile, setSelectedFile] = useState();
  const [fileDetails, setFileDetails] = useState({});
  const initials = initialsFromName(userName);
  const timeText = DateTime.fromMillis(timestamp)
    .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
  const isLoggedHours = type === PROGRESS_UPDATE_TYPE_LOGGED_HOURS;

  const clearSelectedFile = useCallback(() => setSelectedFile(null), []);
  const clearFileDetails = useCallback(() => setFileDetails({}), []);

  const generateSovText = () => {
    if (isFirstEntry) {
      if (field) return field;
      return 'created';
    }

    if (field !== 'Notes') {
      return `updated the ${field} of`;
    }

    return 'added a note to';
  };

  const generateProgressText = () => {
    switch (type) {
      case PROGRESS_UPDATE_TYPE_INVOICE_ADD:
        return 'linked an invoice to';
      case PROGRESS_UPDATE_TYPE_INVOICE_UPDATE:
        return 'updated an invoice amount of';
      case PROGRESS_UPDATE_TYPE_INVOICE_DELETE:
        return 'removed an invoice from';
      case PROGRESS_UPDATE_TYPE_CURRENCY:
        return 'updated the actual spend of';
      case PROGRESS_UPDATE_TYPE_QUANTITY:
        return 'updated the quantity of material used for';
      case PROGRESS_UPDATE_TYPE_NOTES:
        return 'added a note to';
      case PROGRESS_UPDATE_TYPE_ACTIVITY:
        return 'updated the activity of';
      case PROGRESS_UPDATE_TYPE_LOGGED_HOURS:
        return `logged ${duration} hours`;
      case PROGRESS_UPDATE_TYPE_UPDATE_COST_ADD:
        return 'linked an update cost step to';
      default:
        return 'updated';
    }
  };

  const { dailyBilling, hourlyBilling } = useMemo(() => {
    if (type !== PROGRESS_UPDATE_TYPE_LOGGED_HOURS) {
      return {};
    }

    const ourCostcode = costcodeIdMap?.[costcodeId];
    const {
      wageHistory: costcodeWageHistory = [],
      hourlyBillingRate: costcodeHourlyBillingRate,
      dailyBillingRate: costcodeDailyBillingRate,
    } = ourCostcode;

    const relevantEndTime = endTime || otEndTime || doubleOTEndTime;
    const costcodeEntry = costcodeWageHistory.find((w) => w.timestamp <= relevantEndTime);
    const hourlyBillingRate = costcodeEntry?.hourlyBillingRate ?? costcodeHourlyBillingRate;
    const dailyBillingRate = costcodeEntry?.dailyBillingRate ?? costcodeDailyBillingRate;

    return {
      dailyBilling: dailyBillingRate,
      hourlyBilling: dailyBillingRate ? 0 : hourlyBillingRate,
    };
  }, [
    costcodeId,
    costcodeIdMap,
    type,
    endTime,
    otEndTime,
    doubleOTEndTime,
  ]);

  const onPreviewClick = useCallback((index) => {
    const file = files[index];
    if (file) {
      setSelectedFile({
        ...file,
        type: getFileType(file),
      });
    }
  }, [files]);

  useEffect(() => {
    const getDetails = async (file) => {
      const details = await getFileDetails(file);
      if (details) setFileDetails(details);
    };

    if (selectedFile) {
      getDetails(selectedFile);
    } else {
      clearFileDetails();
    }
  }, [selectedFile, clearFileDetails]);

  const hasBilling = hourlyBilling || dailyBilling;

  return (
    <>
      <Row align="middle" justify="space-between" gutter={10} style={{ margin: '10px 0px' }}>
        <Col span={4}>
          <ProfileCircle
            initials={initials}
          />
        </Col>
        <Col span={20}>
          <Row justify="space-between" align="middle">
            <Col style={{ maxWidth: 'calc(100% - 210px)' }}>
              <span style={{ ...bold, marginRight: 5 }}>{userName}</span>
              {isSov ? generateSovText() : generateProgressText()}
              <span style={{ ...bold, marginLeft: 5 }}>{description}</span>
              {isSov && isSubmission ? ' due to submission of SoV' : '' }
              {submissionTimestamp ? ` for Progress Bill ${DateTime.fromMillis(submissionTimestamp).toLocaleString(DateTime.DATETIME_SHORT)}` : ''}
            </Col>
            {!isSov && (
              <Col style={{ minWidth: 30 }}>
                <DeviceIcon device={device} />
              </Col>
            )}
            <Col>
              {!isFirstEntry && !isLoggedHours && (
                <HistoryEntryDiff
                  value={newValue}
                  lastValue={pastValue}
                  diff={diff}
                  type={type}
                  field={field}
                />
              )}
            </Col>
          </Row>
          {!isSov && (invoiceNumber || templateName || hasBilling) && (
            <HistoryEntryDetails
              invoiceNumber={invoiceNumber}
              templateName={templateName}
              vendor={invoiceVendor}
              amount={distributedAmount}
              style={{ fontSize: 12 }}
              dailyBilling={dailyBilling}
              hourlyBilling={hourlyBilling}
            />
          )}
          <Row style={{ fontSize: 10, color: colors.ONTRACCR_OPACITY_GRAY }}>
            {timeText}
          </Row>
        </Col>
        <HistoryEntryFileDisplay
          files={files}
          onPreviewClick={onPreviewClick}
        />
      </Row>
      <FullPhoto
        url={fileDetails.url}
        type={fileDetails.type}
        title={fileDetails.name}
        onClose={clearSelectedFile}
        onDownload={() => {
          downloadFile({ fileDetails });
        }}
        useApryse
      />
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
HistoryEntry.propTypes = {
  costcodeIdMap: PropTypes.object,
  costcodeId: PropTypes.string.isRequired,
  endTime: PropTypes.number,
  otEndTime: PropTypes.number,
  doubleOTEndTime: PropTypes.number,
  submissionTimestamp: PropTypes.number,
};

HistoryEntry.defaultProps = {
  costcodeIdMap: {},
  endTime: 0,
  otEndTime: 0,
  doubleOTEndTime: 0,
  submissionTimestamp: null,
};
