import axios from 'axios';
import { request } from '../../helpers/requests';

const SageService = {
  getIntegrations: (version) => request({
    call: axios.get('/sage/integrations', { params: { version } }),
    errMsg: 'Failed to get sage integrations',
    hideSuccessToast: true,
  }),
  getShifts: (query) => request({
    call: axios.get('/sage/shifts', { params: query }),
    errMsg: 'Failed to get sage shifts',
    hideSuccessToast: true,
  }),
  createShift: (payload) => request({
    call: axios.post('/sage/shift', payload),
    errMsg: 'Failed to create sage shift',
  }),
  updateShift: (id, payload) => request({
    call: axios.put(`/sage/shift/${id}`, payload),
    errMsg: 'Failed to update sage shift',
  }),
  deleteShift: (id) => request({
    call: axios.delete(`/sage/shift/${id}`),
    errMsg: 'Failed to delete sage shift',
  }),
  getPayIDs: (query) => request({
    call: axios.get('/sage/pays', { params: query }),
    errMsg: 'Failed to get Sage pay IDs',
    hideSuccessToast: true,
  }),
  createPayMapping: (integrationId, payload) => request({
    call: axios.post(`/sage/${integrationId}/pays`, payload),
    errMsg: 'Failed to create sage pay mapping',
  }),
  updatePayMapping: (integrationId, projectId, sagePayId) => request({
    call: axios.put(`/sage/${integrationId}/pays/${projectId}`, { sagePayId }),
    errMsg: 'Failed to update sage pay mapping',
  }),
  deletePayMapping: (integrationId, projectId) => request({
    call: axios.delete(`/sage/${integrationId}/pays/${projectId}`),
    errMsg: 'Failed to delete sage pay mapping',
  }),
  updateDefaultDivision: (integrationId, divisionId) => request({
    call: axios.post(`/sage/${integrationId}/division`, { divisionId }),
    errMsg: 'Failed to update default sage division',
  }),
  updateDivisions: (version, integrationId, divisions) => request({
    call: axios.post(`/sage/${version}/${integrationId}/divisions`, { divisions }),
    errMsg: 'Failed to update sage divisions',
  }),
  updateName: (version, integrationId, name) => request({
    call: axios.post(`/sage/${version}/${integrationId}/name`, { name }),
    errMsg: 'Failed to update sage name',
  }),
  updateJobStatuses: (version, integrationId, jobStatuses) => request({
    call: axios.post(`/sage/${version}/${integrationId}/jobs/statuses`, { jobStatuses }),
    errMsg: 'Failed to update sage job statuses',
  }),
  updateDefaultCostcode: (version, integrationId, costcodeId) => request({
    call: axios.post(`/sage/${version}/${integrationId}/costcode`, { costcodeId }),
    errMsg: 'Failed to update default costcode',
  }),
};

export default SageService;
