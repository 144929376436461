import React, { useMemo } from 'react';
import {
  Row, Col, Select,
  Space,
} from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function TableFormPreload({
  configProps,
  updateConfig,
  divisions,
  sections = [],
  templateId,
}) {
  const formTemplates = useSelector((state) => state.forms.templates);

  const divSet = useMemo(() => new Set(divisions), [divisions]);

  const formTemplateOptions = useMemo(() => (
    Object.values(formTemplates)
      .filter((ft) => (
        ft.active
        && ft.notDeleted
        && divSet.has(ft.divisionId)
        && ft.id !== templateId
      ))
      .map((ft) => ({
        value: ft.id,
        label: ft.name,
      }))
  ), [formTemplates, divSet, templateId]);

  const formDropdownOptions = sections.reduce((acc, section) => {
    const { fields = [], name: sectionName } = section;
    return acc.concat(
      fields.reduce((fieldAcc, field) => {
        const {
          id: fieldId,
          selectedType,
          configProps: {
            dataType: fieldDataType,
            title: fieldTitle,
          } = {},
        } = field;
        if (selectedType !== 'dropdown') return fieldAcc;
        if (fieldDataType !== 'Forms') return fieldAcc;
        return fieldAcc.concat([{
          label: `${sectionName} - ${fieldTitle}`,
          value: fieldId,
        }]);
      }, []),
    );
  }, []);

  const updateValue = (key) => (value) => {
    updateConfig({ [key]: value });
  };

  return (
    <Space direction="vertical">
      <Row align="middle" gutter={10} style={{ marginLeft: 0 }}>
        <Col>
          With Form Template
        </Col>
        <Col>
          <Select
            options={formTemplateOptions}
            style={{ width: 500 }}
            optionFilterProp="label"
            allowClear
            showSearch
            placeholder="Select a Form Template"
            value={configProps.preloadForm}
            disabled={configProps.preloadFormDropdown}
            onChange={updateValue('preloadForm')}
          />
        </Col>
      </Row>
      <Row align="middle" gutter={10} style={{ marginLeft: 0 }}>
        <Col>
          Or Form Template Dropdown
        </Col>
        <Col>
          <Select
            style={{ width: 500 }}
            options={formDropdownOptions}
            optionFilterProp="label"
            allowClear
            showSearch
            placeholder="Select a Form Template Dropdown Field"
            value={configProps.preloadFormDropdown}
            disabled={configProps.preloadForm}
            onChange={updateValue('preloadFormDropdown')}
          />
        </Col>
      </Row>
    </Space>
  );
}

/* eslint-disable react/forbid-prop-types */
TableFormPreload.propTypes = {
  configProps: PropTypes.object.isRequired,
  updateConfig: PropTypes.func.isRequired,
  divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
  sections: PropTypes.array,
  templateId: PropTypes.string,
};

TableFormPreload.defaultProps = {
  sections: [],
  templateId: null,
};

export default TableFormPreload;
