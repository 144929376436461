import React, {
  useMemo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Table,
  Input,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import BorderlessButton from '../common/buttons/BorderlessButton';
import OnTraccrNumberInput from '../common/inputs/OnTraccrNumberInput';

import { getLocations } from './state/materials.actions';

import { getIdMap, includesTerm } from '../helpers/helpers';

import MaterialLocationSelector from './MaterialLocationSelector';

const getName = ({ equipmentId, globalMaterialLocationsId } = {}, combinedLocationsMap = {}) => {
  if (equipmentId && equipmentId in combinedLocationsMap) {
    const {
      [equipmentId]: {
        name = '',
      } = {},
    } = combinedLocationsMap;
    return name;
  }
  const {
    [globalMaterialLocationsId]: {
      locationText = '',
    } = {},
  } = combinedLocationsMap;
  return locationText;
};

const getColumns = ({
  isNotDisplay,
  combinedLocationsMap,
  onQuantityChanged,
  onQuantityAllocatedChanged,
  onLocationSelect,
  onLocationDelete,
}) => {
  const cols = [
    {
      title: <div className="material-table-location-header">Location</div>,
      key: 'location',
      width: 175,
      sorter: (a, b) => {
        const aName = getName(a, combinedLocationsMap) || '';
        const bName = getName(b, combinedLocationsMap) || '';
        const aVal = aName.toLowerCase();
        const bVal = bName.toLowerCase();
        if (aVal < bVal) return -1;
        if (aVal > bVal) return 1;
        return 0;
      },
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => {
        const {
          equipmentId,
          globalMaterialLocationsId,
        } = record;

        let value = '';
        const displayName = getName(record, combinedLocationsMap);
        if (equipmentId && equipmentId in combinedLocationsMap) {
          const {
            [equipmentId]: {
              id: eqId,
            } = {},
          } = combinedLocationsMap;
          value = eqId;
        } else if (globalMaterialLocationsId && globalMaterialLocationsId in combinedLocationsMap) {
          value = globalMaterialLocationsId;
        }
        if (!isNotDisplay) return <div className="material-table-location-value">{displayName}</div>;
        return (
          <MaterialLocationSelector
            value={value}
            record={record}
            onLocationSelect={onLocationSelect}
          />
        );
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      render: (quantity, record) => (
        isNotDisplay
          ? (
            <OnTraccrNumberInput
              key={record.id}
              defaultValue={quantity}
              step={1}
              precision={2}
              onChange={(value) => onQuantityChanged(record.id, value)}
            />
          ) : quantity
      ),
    },
    {
      title: 'Quantity Allocated',
      dataIndex: 'quantityAllocated',
      sorter: (a, b) => a.quantityAllocated - b.quantityAllocated,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      render: (quantity, record) => (
        isNotDisplay
          ? (
            <OnTraccrNumberInput
              key={record.id}
              defaultValue={quantity}
              min={0}
              step={1}
              precision={2}
              onChange={(value) => onQuantityAllocatedChanged(record.id, value)}
            />
          )
          : quantity
      ),
    },
  ];
  if (!isNotDisplay) return cols;
  cols.push({
    title: '',
    dataIndex: 'delete',
    render: (_, record) => (
      record.isDefault
        ? null
        : (
          <BorderlessButton
            iconNode={<DeleteOutlined style={{ color: 'red', paddingLeft: 0 }} />}
            onClick={() => onLocationDelete(record.id)}
          />
        )
    ),
  });
  return cols;
};

export default function MaterialLocation({
  id,
  locations = [],
  onLocationsChanged,
  isNotDisplay,
}) {
  const dispatch = useDispatch();
  const equipment = useSelector((state) => state.equipment.equipment);
  const globalMaterialLocations = useSelector((state) => (
    state.globalMaterialLocations.globalMaterialLocations
  ));

  const [searchStr, setSearchStr] = useState();

  const onSearch = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchStr(value);
  }, []);

  const onAddNewLocation = useCallback(() => {
    const newLocs = locations.concat([{
      id: DateTime.local().toMillis(),
      isNew: true,
      quantity: 0,
      quantityAllocated: 0,
    }]);
    onLocationsChanged(newLocs);
  }, [locations, onLocationsChanged]);

  const updateLocation = useCallback(({ locationId, newData = {} }) => (
    onLocationsChanged(
      locations.map((location) => {
        if (location.id !== locationId) return location;
        return {
          ...location,
          ...newData,
        };
      }),
    )
  ), [locations, onLocationsChanged]);

  const onLocationDelete = useCallback((locationId) => {
    onLocationsChanged(locations.filter((loc) => loc.id !== locationId));
  }, [onLocationsChanged, locations]);

  const onDataChanged = useCallback((key) => (locationId, newValue) => (
    updateLocation({ locationId, newData: { [key]: newValue } })
  ), [updateLocation]);

  const onLocationSelect = useCallback((locationId, newData) => (
    updateLocation({
      locationId,
      newData,
    })
  ), [updateLocation]);

  const combinedLocationsMap = useMemo(() => {
    const equipmentMap = getIdMap(equipment);
    const globalMaterialLocationsMap = getIdMap(globalMaterialLocations);
    return { ...equipmentMap, ...globalMaterialLocationsMap };
  }, [equipment, globalMaterialLocations]);

  const columns = useMemo(() => getColumns({
    isNotDisplay,
    equipment,
    combinedLocationsMap,
    onQuantityChanged: onDataChanged('quantity'),
    onQuantityAllocatedChanged: onDataChanged('quantityAllocated'),
    onLocationSelect,
    onLocationDelete,
  }), [
    isNotDisplay,
    equipment,
    combinedLocationsMap,
    onDataChanged,
    onLocationSelect,
    onLocationDelete,
  ]);

  useEffect(() => {
    if (!dispatch || !id) return;
    dispatch(getLocations(id));
  }, [id]);

  const tableData = useMemo(() => {
    if (!searchStr) return locations;
    return locations.filter((loc) => {
      const name = getName(loc, combinedLocationsMap);
      return includesTerm(name, searchStr);
    });
  }, [searchStr, combinedLocationsMap, locations]);

  return (
    <>
      <div id="material-location-title">Locations</div>
      <Row justify="space-between" align="middle" className="material-location-header">
        <Col>
          <Input.Search
            className="searchbar"
            placeholder="Search"
            onChange={onSearch}
            allowClear
          />
        </Col>
        <Col>
          {isNotDisplay && (
            <OnTraccrButton
              title="Add"
              icon={<PlusOutlined />}
              onClick={onAddNewLocation}
            />
          )}
        </Col>
      </Row>
      <Table
        className="material-location-table"
        columns={columns}
        dataSource={tableData}
        size="small"
        pagination={false}
        rowKey="id"
      />
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
MaterialLocation.propTypes = {
  id: PropTypes.string.isRequired,
  locations: PropTypes.array,
  onLocationsChanged: PropTypes.func.isRequired,
  isNotDisplay: PropTypes.bool.isRequired,
};

MaterialLocation.defaultProps = {
  locations: [],
};
