import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu, Spin, Tree } from 'antd';
import {
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  PlusOutlined,
  CloseOutlined,
  StarOutlined,
  StarFilled,
  ReloadOutlined,
} from '@ant-design/icons';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';

import {
  createNewDashboard,
  selectDashboard,
  setDashboardEdit,
  deleteSavedDashboard,
  favoriteDashboard,
} from './state/dashboard.actions';

import { getCustomExports } from '../reports/state/reports.actions';

import colors from '../constants/Colors';

import Debouncer from '../helpers/Debouncer';

const DEFAULT_WIDGETS = [
  { title: 'Clock', id: 'clock' },
  { title: 'Quick Stats', id: 'quickStats' },
  { title: 'Live Feed', id: 'liveFeed' },
  { title: 'Tasks', id: 'tasks' },
];
const FILLED_STAR = <StarFilled style={{ fontSize: 16, color: 'gold' }}/>;
const debouncer = new Debouncer();
export default ({
  onSave,
  onEditCancel: propEditCancel,
  onAddWidget: propAddWidget,
  onReloadClicked: propOnReload,
  currentLayout = [],
}) => {
  const dispatch = useDispatch();
  const {
    id: dashboardId,
    title = 'Dashboard',
    isFavorite,
  } = useSelector(state => state.dashboard.selectedDashboard ?? {});
  const savedDashboards = useSelector(state => state.dashboard.savedDashboards ?? []);
  const edit = useSelector(state => state.dashboard.isEdit);
  const savedReports = useSelector(state => state.analytics.savedReports);
  const customExports = useSelector((state) => state.reports.customExports);

  const [currentTitle, setCurrentTitle] = useState(title);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const onEditClicked = (e) => {
    e.stopPropagation();
    dispatch(setDashboardEdit({ isEdit: true }));
    setDropdownVisible(false);
  };

  const onTextChange =(e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setCurrentTitle(value);
  };

  const onDeleteClicked = (e) => {
    e.stopPropagation();
    CustomConfirmModal({
      title: `Delete Dashboard '${currentTitle}' ?`,
      onOk: () => {
        dispatch(deleteSavedDashboard({ id: dashboardId }));
      },
      okText: 'Delete',
    });
  };
  
  const onSaveClicked = (e) => {
    e.stopPropagation();
    onSave(currentTitle);
  };

  const onEditCancel = (e) => {
    e.stopPropagation();
    setCurrentTitle(title);
    dispatch(setDashboardEdit({ isEdit: false }));
    propEditCancel();
  }

  const onDashboardSelected = ({ key }) => {
    if (key === 'new') {
      dispatch(createNewDashboard());
    } else if (key === 'default') {
      dispatch(selectDashboard({}));
    } else {
      dispatch(selectDashboard({ id: key }));
    }
    setDropdownVisible(false);
  }

  const onAddWidget = ({ key, domEvent: e }) => {
    e.stopPropagation();
    const [itemType, itemId] = key?.split('-') ?? [];
    propAddWidget(
      itemType === 'formExports' || itemType === 'timeTrackingExports'
      ? key
      : itemId
    );
  }

  const onFavoriteToggle = (e) => {
    e.stopPropagation();
    dispatch(favoriteDashboard({
      id: dashboardId,
      isFavorite,
    }));
  };

  const onReloadClicked = (e) => {
    e.stopPropagation();
    if (loading) return;
    setLoading(true);
    debouncer.debounce(() => {
      setLoading(false);
      propOnReload();
    }, 1000);
  }

  useEffect(() => {
    setCurrentTitle(title);
  },[title]);

  useEffect(() => {
    dispatch(setDashboardEdit({ isEdit: false }))
    dispatch(getCustomExports());
  },[]);

  const titleNode = edit
    ? <OnTraccrTextInput
        autoFocus
        defaultValue={currentTitle}
        onChange={onTextChange}
        style={{ width: 250 }}
        onClick={(e) => e.stopPropagation()}
      />
    : currentTitle;

  const layoutSet = new Set(currentLayout.map((l) => l.i));

  const {
    widgetCount,
    widgets,
  } = useMemo(() => {
    const validDefaults = DEFAULT_WIDGETS.filter((l) => !layoutSet.has(l.id));
    let count = validDefaults.length;
    const wGroups = [{
      id: 'default',
      title: 'Default',
      items: validDefaults,
    }, {
      id: 'forms',
      title: 'Forms',
      items: [],
    }, {
      id: 'formExports',
      title: 'Form Exports',
      items: [],
    }, {
      id: 'boards',
      title: 'Boards',
      items: [],
    }, {
      id: 'timeTrackingExports',
      title: 'Time Tracking Exports',
      items: [],
    }];
    Object.values(savedReports).forEach((savedReport) => {
      if (layoutSet.has(savedReport.id)) return;
      if (savedReport.boardId) {
        wGroups[3].items.push(savedReport)
        count += 1;
      } else if (savedReport.formTemplateId) {
        wGroups[1].items.push(savedReport)
        count += 1;
      }
    });
    customExports.forEach((customExport) => {
      if (customExport.type === 'forms') {
        if (layoutSet.has(`formExports-${customExport.id}`)) return;
        wGroups[2].items.push(customExport)
        count += 1;
      } else if (customExport.type === 'timeEntries') {
        if (layoutSet.has(`timeTrackingExports-${customExport.id}`)) return;
        wGroups[4].items.push(customExport)
        count += 1;
      }
    })
    return {
      widgetCount: count,
      widgets: wGroups.filter((group) => group.items.length > 0),
    }
  }, [layoutSet, savedReports, customExports]);

  const favoriteIcon = (dashboard) => (
    <span style={{ marginLeft: 5 }}>{
      dashboard.isFavorite
        ? FILLED_STAR
        : <StarOutlined style={{ fontSize: 16 }}/>
      }
    </span>
  );

  return (
    <>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu onClick={onDashboardSelected}>
            <Menu.Item key='new'>
              New Dashboard <span><PlusOutlined/></span>
            </Menu.Item>
            <Menu.Item key='default'>
              Default Dashboard
            </Menu.Item>
            {savedDashboards.map((dashboard) => (
                <Menu.Item
                  key={dashboard.id}
                >
                  {dashboard.title}
                  {dashboard.isFavorite
                    ? <span style={{ marginLeft: 5 }}>{FILLED_STAR}</span>
                    : null
                  }
                </Menu.Item>
            ))}
          </Menu>
        }
        visible={dropdownVisible}
        onVisibleChange={setDropdownVisible}
      >
        <span {...edit ? {} : { id: 'board-crumb-button'}}>
          {titleNode}
        </span>
      </Dropdown>
      {!edit && dashboardId &&
        <span className='analytics-title-button' onClick={onFavoriteToggle}>
          {favoriteIcon({ isFavorite })}
        </span>
      }
      {!edit && dashboardId && dashboardId !== 'new' &&
        <span className='analytics-title-button' onClick={onReloadClicked}>
          {loading ? <Spin size='small' style={{ marginTop: 5 }}/> : <ReloadOutlined />}
        </span>
      }
      {!edit && dashboardId && <span className='analytics-title-button' onClick={onEditClicked}>
        <EditOutlined/>
      </span>}
      {!edit && dashboardId && dashboardId !== 'new' && <span className='analytics-title-button' onClick={onDeleteClicked}>
        <DeleteOutlined style={{ color: colors.ONTRACCR_RED }}/>
      </span>}
      {edit && <span className='analytics-title-button' onClick={onEditCancel}>
        <CloseOutlined/>
      </span>}
      {edit && currentTitle.length > 0 && <span className='analytics-title-button' onClick={onSaveClicked}>
        <SaveOutlined/>
      </span>}
      {
        edit && currentLayout.length < 9 && widgetCount > 0 &&
        <span style={{ marginLeft: 40 }}>
          <Dropdown
            trigger={['click']}
            onClick={(e) => e.stopPropagation()}
            overlay={
              <Menu onClick={onAddWidget}>
                {widgets.map((widgetGroup) => (
                    <Menu.SubMenu
                      title={widgetGroup.title}
                      key={widgetGroup.id}
                      popupClassName="ant-dropdown-menu-submenu dashboard-widget-submenu"
                    >
                    {
                      widgetGroup.items.map((widget) => (
                        <Menu.Item
                          key={`${widgetGroup.id}-${widget.id}`}
                        >
                          {widget.title}
                        </Menu.Item>
                      ))
                    }
                  </Menu.SubMenu>
                ))}
              </Menu>
            }
          >
            <OnTraccrButton title='Add Widget' icon={<PlusOutlined/>}/>
          </Dropdown>
          
        </span>
      }
    </>
  );
}