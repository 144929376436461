import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Drawer, Table, Input, Row, Col, DatePicker, Popconfirm,
} from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';

import SimpleShiftTable from './SimpleShiftTable';

import sortByString, { getIdMap, includesTerm } from '../helpers/helpers';
import useDivisionUsers from '../common/hooks/useDivisionUsers';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

const parseRange = ([
  dt0 = DateTime.local().minus({ days: 7 }),
  dt1 = DateTime.local(),
] = []) => [moment(dt0.toMillis()), moment(dt1.toMillis())];

function WhoseScheduledDrawer({
  onClick,
  users: propUsers,
  startDate,
  endDate,
  width,
  scheduledUserIds,
  showUsersScheduledToShift,
  onUserRemove,
}) {
  const userShiftMap = useSelector((state) => state.schedule.shiftUsers);
  const shiftMap = useSelector((state) => state.schedule.shiftMap);
  const users = useDivisionUsers();
  const projects = useSelector((state) => state.projects.projects);
  const { phases, costcodes } = useSelector((state) => state.costcodes);

  const activeUsers = useMemo(() => users.filter((user) => user.active), [users]);
  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const phaseIdMap = useMemo(() => getIdMap(phases), [phases]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const [drawerOpen, setDrawerOpen] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [dateRange, setDateRange] = useState(parseRange([startDate, endDate]));

  const showDrawer = useCallback(() => setDrawerOpen(true), []);
  const closeDrawer = useCallback(() => setDrawerOpen(false), []);

  const onDateChange = useCallback(([startMoment, endMoment]) => {
    const startDT = DateTime.fromMillis(startMoment.valueOf());
    const endDT = DateTime.fromMillis(endMoment.valueOf());
    setDateRange([
      startDT.startOf('day').toMillis(),
      endDT.endOf('day').toMillis(),
    ]);
  }, []);

  const onSearch = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchTerm(value);
  }, []);

  const expandedRowRender = useCallback(({ shifts = [] }) => (
    <SimpleShiftTable shifts={shifts} />
  ), []);

  useEffect(() => {
    if (!drawerOpen) {
      const safeStart = startDate ?? DateTime.local();
      const safeEnd = endDate ?? DateTime.local();
      setSearchTerm();
      setDateRange([safeStart.toMillis(), safeEnd.toMillis()]);
    }
  }, [drawerOpen, startDate, endDate]);

  const {
    scheduledUsers,
    scheduledUserCount,
  } = useMemo(
    () => {
      let count = 0;
      const [startTs, endTs] = dateRange;
      const scheduled = (propUsers || activeUsers).map((user) => {
        const { id: userId, name } = user;
        if (!(userId in userShiftMap) || userShiftMap[userId].length === 0) {
          return {
            userId, name, shifts: [], numberOfShifts: 0,
          };
        }
        const {
          [userId]: shifts = [],
        } = userShiftMap;
        const timeBoxedShifts = shifts.filter((shiftId) => {
          const {
            [shiftId]: { startTime = 0, endTime = 0, isDraft } = {},
          } = shiftMap;
          return ((startTs <= startTime && endTs >= startTime)
         || (startTs <= endTime && endTs >= endTime)) && !isDraft;
        }).map((shiftId) => {
          const {
            [shiftId]: shift = {},
          } = shiftMap;
          const { projectId, phaseId, costcodeId } = shift;
          const {
            [projectId]: { name: projectName } = {},
          } = projectIdMap;
          const {
            [phaseId]: { name: phaseName } = {},
          } = phaseIdMap;
          const {
            [costcodeId]: { name: costcodeName } = {},
          } = costcodeIdMap;
          return {
            ...shift,
            projectName,
            phaseName,
            costcodeName,
          };
        });
        timeBoxedShifts.sort((a, b) => a.startTime - b.startTime);
        if (timeBoxedShifts.length > 0) count += 1;
        return {
          userId, name, shifts: timeBoxedShifts, numberOfShifts: timeBoxedShifts.length,
        };
      });
      scheduled.sort((u0, u1) => u1.shifts.length - u0.shifts.length);
      return { scheduledUsers: scheduled, scheduledUserCount: count };
    },
    [
      userShiftMap, shiftMap, activeUsers, propUsers,
      dateRange, projectIdMap, phaseIdMap, costcodeIdMap,
    ],
  );

  const tableData = useMemo(() => (
    searchTerm
      ? scheduledUsers.filter((user) => includesTerm(user.name, searchTerm))
      : scheduledUsers
  ), [searchTerm, scheduledUsers]);

  const rangeMoments = useMemo(() => dateRange.map((ts) => moment(ts)), [dateRange]);

  const columns = useMemo(() => {
    const cols = [];
    if (showUsersScheduledToShift) {
      cols.push({
        title: '',
        dataIndex: 'isScheduled',
        width: 50,
        render: (_, record) => {
          if (!scheduledUserIds.has(record.userId)) return null;
          return (
            <Popconfirm
              placement="left"
              title={`${record.name} is assigned to this shift`}
              okText="Remove"
              zIndex={1100}
              onConfirm={() => onUserRemove?.(record.userId)}
              trigger="click"
            >
              <DeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>
          );
        },
      });
    }
    return cols.concat([{
      title: 'Name',
      dataIndex: 'name',
      sorter: sortByString('name'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    }, {
      title: 'Number of Shifts',
      dataIndex: 'numberOfShifts',
      sorter: (a, b) => a.numberOfShifts - b.numberOfShifts,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      width: 150,
    }]);
  }, [showUsersScheduledToShift, scheduledUserIds, onUserRemove]);

  const title = `${scheduledUserCount} User${scheduledUserCount === 1 ? '' : 's'} scheduled`;
  return (
    <>
      <OnTraccrButton
        title={title}
        onClick={showDrawer}
      />
      <Drawer
        title="Users Scheduled"
        visible={drawerOpen}
        onClose={closeDrawer}
        width={width}
        bodyStyle={{ padding: 0 }}
      >
        <Row className="whose-scheduled-search" justify="space-between">
          <Col span={12}>
            <Input.Search
              className="searchbar"
              placeholder="Search"
              onChange={onSearch}
              allowClear
            />
          </Col>
          <Col>
            <DatePicker.RangePicker
              style={{ width: 250 }}
              value={rangeMoments}
              onChange={onDateChange}
              allowClear={false}
              getPopupContainer={(node) => node.parentNode}
              format="MMM Do YY"
            />
          </Col>
        </Row>
        <Table
          columns={columns}
          scroll={{
            y: 'calc(100vh - 145px)',
          }}
          rowKey="userId"
          rowClassName={(record) => (
            !scheduledUserIds.has(record.userId) && onClick
              ? 'whose-scheduled-row'
              : undefined
          )}
          size="small"
          dataSource={tableData}
          pagination={false}
          onRow={(record) => (!scheduledUserIds.has(record.userId) && onClick ? ({
            onClick: () => onClick(record.userId),
          }) : ({}))}
          expandable={{
            rowExpandable: ({ shifts = [] }) => shifts.length > 0,
            expandedRowRender,
          }}
        />
      </Drawer>
    </>
  );
}

WhoseScheduledDrawer.propTypes = {
  onClick: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  startDate: PropTypes.instanceOf(DateTime),
  endDate: PropTypes.instanceOf(DateTime),
  width: PropTypes.number,
  scheduledUserIds: PropTypes.instanceOf(Set),
  showUsersScheduledToShift: PropTypes.bool,
  onUserRemove: PropTypes.func,
};

WhoseScheduledDrawer.defaultProps = {
  onClick: undefined,
  users: undefined,
  startDate: undefined,
  endDate: undefined,
  width: 800,
  scheduledUserIds: new Set(),
  showUsersScheduledToShift: false,
  onUserRemove: null,
};

export default WhoseScheduledDrawer;
