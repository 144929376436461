import {
  Checkbox,
  Col,
  Drawer,
  Form,
  Row,
  Select,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import IconPicker from '../../common/IconPicker/IconPicker';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import HoverHelp from '../../common/HoverHelp';
import SimpleBucketTemplateModal from './SimpleBucketTemplateModal';
import FormBuilder from '../../forms/FormBuilder/FormBuilder';

export default function BucketTemplateAddEditDrawer({
  selectedEntry,
  visible,
  toggle,
  showIcon,
  onSave,
  form,
  setSections,
}) {
  const { t } = useTranslation();
  const bucketTemplates = useSelector((state) => state.buckets.bucketTemplates);

  const [selectedUpstreamTypes, setSelectedUpstreamTypes] = useState([]);
  const [selectedDownstreamTypes, setSelectedDownstreamTypes] = useState([]);
  const [newLinkIds, setNewLinkIds] = useState([]);

  const initialSections = useMemo(() => {
    if (selectedEntry) {
      return selectedEntry.customFields?.sections ?? [];
    }
    return [];
  }, [selectedEntry]);

  useEffect(() => {
    if (visible && selectedEntry) {
      setSelectedUpstreamTypes(selectedEntry.upstreamTypes);
      setSelectedDownstreamTypes(selectedEntry.downstreamTypes);
      return;
    }

    // eslint-disable-next-line consistent-return
    return () => {
      setSelectedUpstreamTypes([]);
      setSelectedDownstreamTypes([]);
      setNewLinkIds([]);
    };
  }, [selectedEntry, visible]);

  useEffect(() => {
    form?.setFieldsValue({
      upstreamTypes: selectedUpstreamTypes,
      downstreamTypes: selectedDownstreamTypes,
    });
  }, [selectedUpstreamTypes, selectedDownstreamTypes]);

  const shouldOptionBeDisabled = ({
    templateId,
    type,
    previousSet,
    currentSet,
  }) => {
    const suffix = type === 'one' ? 'many': 'one';
    // Disable the alternate option if the other is selected
    // ie. if customer-one is selected, customer-many should be disabled
    if (currentSet.has(`${templateId}-${suffix}`)) return true;
    // Disable both options if already exists on template
    // or else we would have to remove all relevant links
    // ie. if customer-one or customer-many is selected previously
    return previousSet.has(`${templateId}-${type}`) || previousSet.has(`${templateId}-${suffix}`);
  };

  const {
    upstreamBucketOptions,
    downstreamBucketOptions,
  } = useMemo(() => {
    const previousUpstreamSet = new Set(selectedEntry?.upstreamTypes);
    const previousDownstreamSet = new Set(selectedEntry?.downstreamTypes);
    const upstreamSet = new Set(selectedUpstreamTypes);
    const downstreamSet = new Set(selectedDownstreamTypes);

    return bucketTemplates.reduce((acc, { id, name }) => {
      if (id === selectedEntry?.id) return acc;
      if (!downstreamSet.has(`${id}-one`) && !downstreamSet.has(`${id}-many`)) {
        acc.upstreamBucketOptions.push(
          {
            label: `Single ${name}`,
            value: `${id}-one`,
            disabled: shouldOptionBeDisabled({
              templateId: id,
              type: 'one',
              previousSet: previousUpstreamSet,
              currentSet: upstreamSet,
            }),
          },
          {
            label: `Multiple ${name}`,
            value: `${id}-many`,
            disabled: shouldOptionBeDisabled({
              templateId: id,
              type: 'many',
              previousSet: previousUpstreamSet,
              currentSet: upstreamSet,
            }),
          },
        );
      }

      if (!upstreamSet.has(`${id}-one`) && !upstreamSet.has(`${id}-many`)) {
        acc.downstreamBucketOptions.push(
          {
            label: `Single ${name}`,
            value: `${id}-one`,
            disabled: shouldOptionBeDisabled({
              templateId: id,
              type: 'one',
              previousSet: previousDownstreamSet,
              currentSet: downstreamSet,
            }),
          },
          {
            label: `Multiple ${name}`,
            value: `${id}-many`,
            disabled: shouldOptionBeDisabled({
              templateId: id,
              type: 'many',
              previousSet: previousDownstreamSet,
              currentSet: downstreamSet,
            }),
          },
        );
      }

      return acc;
    }, {
      upstreamBucketOptions: [
        {
          label: 'Single Customer',
          value: 'customer-one',
          disabled: shouldOptionBeDisabled({
            templateId: 'customer',
            type: 'one',
            previousSet: previousUpstreamSet,
            currentSet: upstreamSet,
          }),
        },
        {
          label: 'Multiple Customers',
          value: 'customer-many',
          disabled: shouldOptionBeDisabled({
            templateId: 'customer',
            type: 'many',
            previousSet: previousUpstreamSet,
            currentSet: upstreamSet,
          }),
        },
      ],
      downstreamBucketOptions: [
        {
          label: `Single ${t('Project')}`,
          value: 'project-one',
          disabled: shouldOptionBeDisabled({
            templateId: 'project',
            type: 'one',
            previousSet: previousDownstreamSet,
            currentSet: downstreamSet,
          }),
        },
        {
          label: `Multiple ${t('Project', { count: 2 })}`,
          value: 'project-many',
          disabled: shouldOptionBeDisabled({
            templateId: 'project',
            type: 'many',
            previousSet: previousDownstreamSet,
            currentSet: downstreamSet,
          }),
        },
        {
          label: 'Single Equipment',
          value: 'equipment-one',
          disabled: shouldOptionBeDisabled({
            templateId: 'equipment',
            type: 'one',
            previousSet: previousDownstreamSet,
            currentSet: downstreamSet,
          }),
        },
        {
          label: 'Multiple Equipment',
          value: 'equipment-many',
          disabled: shouldOptionBeDisabled({
            templateId: 'equipment',
            type: 'many',
            previousSet: previousDownstreamSet,
            currentSet: downstreamSet,
          }),
        },
      ],
    });
  }, [
    selectedEntry,
    selectedUpstreamTypes,
    selectedDownstreamTypes,
  ]);

  const onSubmit = () => {
    const previousUpstreamSet = new Set(selectedEntry?.upstreamTypes);
    const previousDownstreamSet = new Set(selectedEntry?.downstreamTypes);

    const linkIds = [];

    selectedUpstreamTypes.forEach((type) => {
      if (previousUpstreamSet.has(type)) return;
      const templateId = type.split('-')[0];
      if (templateId.length === 36) linkIds.push(templateId);
    });

    selectedDownstreamTypes.forEach((type) => {
      if (previousDownstreamSet.has(type)) return;
      const templateId = type.split('-')[0];
      if (templateId.length === 36) linkIds.push(templateId);
    });

    // We need to set the mapping for the new links
    if (linkIds.length) setNewLinkIds(linkIds);
    else onSave();
  };

  const onClose = () => setNewLinkIds([]);

  if (!visible) return null;
  return (
    <>
      <Drawer
        title={`${selectedEntry ? 'Edit' : 'Add'} Bucket`}
        visible={visible}
        onClose={toggle}
        width={600}
        destroyOnClose
        keyboard={false}
      >
        <Form form={form}>
          <Form.Item name="icon">
            <IconPicker />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            style={{ width: showIcon ? 'inherit' : '100%' }}
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <OnTraccrTextInput placeholder="Enter Name" />
          </Form.Item>
          <Form.Item
            name="upstreamTypes"
            label="Parent"
          >
            <Select
              placeholder="Select Parent"
              mode="multiple"
              optionLabelProp="label"
              options={upstreamBucketOptions}
              showSearch
              onChange={setSelectedUpstreamTypes}
            />
          </Form.Item>
          <Form.Item
            name="downstreamTypes"
            label="Children"
          >
            <Select
              placeholder="Select Children"
              mode="multiple"
              optionLabelProp="label"
              options={downstreamBucketOptions}
              showSearch
              onChange={setSelectedDownstreamTypes}
            />
          </Form.Item>
          <Form.Item
            name="shouldShowPage"
            label={(
              <Row gutter={5}>
                <Col>
                  Show Page
                </Col>
                <Col>
                  <HoverHelp
                    content={(
                      <div style={{ width: 300 }}>
                        If you check this box, a new page will show up in the sidebar for this bucket.
                      </div>
                    )}
                  />
                </Col>
              </Row>
            )}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Form>
        <FormBuilder
          isBoardCards
          onSectionsChanged={setSections}
          initialSections={initialSections}
          keyboard={false}
          style={{
            position: 'inherit',
          }}
        />
        <DrawerSubmitFooter
          onSubmit={onSubmit}
          onClose={toggle}
        />
      </Drawer>
      <SimpleBucketTemplateModal
        templateName={form?.getFieldValue('name')}
        onClose={onClose}
        visible={newLinkIds.length > 0}
        linkIds={newLinkIds}
        onSave={onSave}
      />
    </>
  );
}

BucketTemplateAddEditDrawer.propTypes = {
  selectedEntry: PropTypes.shape({
    id: PropTypes.string,
    upstreamTypes: PropTypes.arrayOf(PropTypes.string),
    downstreamTypes: PropTypes.arrayOf(PropTypes.string),
    customFields: PropTypes.shape({
      sections: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  visible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  showIcon: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func,
  }),
  setSections: PropTypes.func.isRequired,
};

BucketTemplateAddEditDrawer.defaultProps = {
  selectedEntry: null,
  form: null,
};
