import * as XLSX from 'xlsx';
import moment from 'moment';
import { DateTime } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';

import { roundTotalRuntime } from '../helpers/time';
import { toTitleCase } from '../helpers/helpers';

const getViewColumnToExportMap = (t) => ({
  name: 'NAME',
  date: 'DATE',
  totalTime: 'DURATION',
  hasViolation: 'VIOLATION',
  hasRejection: 'REJECTION',
  StartTime: 'START TIME',
  EndTime: 'END TIME',
  RegularStartTime: 'REGULAR START TIME',
  RegularEndTime: 'REGULAR END TIME',
  OTStartTime: 'OT START TIME',
  OTEndTime: 'OT END TIME',
  BreakStartTime: 'BREAK START TIME',
  BreakEndTime: 'BREAK END TIME',
  DoubleOTStartTime: 'DT OT START TIME',
  DoubleOTEndTime: 'DT OT END TIME',
  Duration: 'DURATION',
  RegularDuration: 'REGULAR DURATION',
  BreakDuration: 'BREAK DURATION',
  OTDuration: 'OT DURATION',
  DTOTDuration: 'DT OT DURATION',
  PTODuration: 'PTO DURATION',
  Type: 'TYPE',
  Project: `${t('Project').toUpperCase()} NAME`,
  Phase: 'PHASE',
  CostCode: 'COST CODE',
  Union: 'UNION',
  LocalClass: 'LOCAL CLASS',
  WorkClass: 'WORK CLASS',
  Notes: 'NOTES',
  Status: 'STATUS',
  ApprovedBy: 'APPROVED BY',
  enteredVia: 'ENTERED VIA',
});

const formatDate = (timestamp) => {
  if (!timestamp) return '';
  return moment(timestamp).format('MMM Do YYYY');
};
const formatTime = (timestamp, zone) => {
  if (!timestamp) return '';
  return DateTime.fromMillis(timestamp, { zone })
    .toLocaleString({
      hour: '2-digit',
      minute: 'numeric',
      timeZoneName: 'short',
    });
};

const formatRuntime = (duration) => {
  const milliseconds = parseInt((duration % 1000) / 100, 10);
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? `0${hours}` : hours;
  minutes = (minutes < 10) ? `0${minutes}` : minutes;
  seconds = (seconds < 10) ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
};

const createDayRow = ({
  dayRows = [],
  lastDay,
  timeSummary,
  roundingInterval,
  firstWrite,
  roundingType,
  roundingSetting,
}) => {
  let rows = [];
  const runtime = formatRuntime(
    roundTotalRuntime(timeSummary, roundingInterval, roundingType, roundingSetting)
  );
  const [hours, minutes] = runtime.split(':');
  if (!firstWrite) rows.push([]);
  rows.push([`SUMMARY: ${lastDay.toUpperCase()}`, '', '', '', '', `${hours}:${minutes}`]);
  rows = rows.concat(dayRows);
  return rows;
};

const getBaseExcelHeader = (t) => [
  'DATE',
  'NAME',
  'REGULAR START TIME',
  'REGULAR END TIME',
  'BREAK START TIME',
  'BREAK END TIME',
  'OT START TIME',
  'OT END TIME',
  'DT OT START TIME',
  'DT OT END TIME',
  'DURATION',
  'REGULAR DURATION',
  'OT DURATION',
  'DT OT DURATION',
  'PTO DURATION',
  'TYPE',
  `${t('Project').toUpperCase()} NAME`,
  'PHASE',
  'COST CODE',
  'UNION',
  'LOCAL CLASS',
  'WORK CLASS',
  'NOTES',
  'ENTERED VIA',
  'APPROVED BY',
  'VIOLATION',
  'STATUS',
];

export default ({
  t,
  user,
  tasks,
  start,
  end,
  regularHours,
  otHours,
  doubleOtHours,
  saturdayHours,
  saturdayOTHours,
  saturdayDoubleOTHours,
  sundayHours,
  sundayOTHours,
  sundayDoubleOTHours,
  totalPay,
  roundingInterval,
  roundingType,
  roundingSetting,
  isSummary,
  userMap = {},
  showOriginalTimes,
  mainColumns,
  customColumns,
  unionMap = {},
  unionLocalMap = {},
  classMap = {},
  phaseMap = {},
  customFieldMap = {},
  divisionIds = new Set(),
}) => {
  const colMap = getViewColumnToExportMap(t);

  const mainColSet = new Set(
    (mainColumns ?? []).map((col) => colMap[col]),
  );

  const headerRow = getBaseExcelHeader(t).filter((col) => (
    mainColSet.size === 0 || mainColSet.has(col)
  ));

  customColumns?.forEach((fieldId) => {
    const customField = customFieldMap[fieldId];

    if (customField && divisionIds.has(customField.divisionId)) {
      if (customField.columns?.length) {
        customField.columns?.forEach((col) => {
          headerRow.push(`${customField.title} - ${col.name}`.toUpperCase());
        });
      } else {
        headerRow.push(customField.title.toUpperCase());
      }
    }
  });

  const headerSet = new Set(headerRow);

  const title = `${user} ${formatDate(start)} - ${formatDate(end)} Timecard Breakdown`;
  const workbook = XLSX.utils.book_new();
  const showSaturday = !isSummary && (saturdayHours || saturdayOTHours || saturdayDoubleOTHours);
  const showSunday = !isSummary && (sundayHours || sundayOTHours || sundayDoubleOTHours);
  const firstRow = ['Employee:', user, '', formatDate(start), '-', formatDate(end)];
  if (!isSummary) {
    firstRow.push(...['REGULAR:', 'OVERTIME:', 'DOUBLE OVERTIME:', 'TOTAL PAY:']);
  }
  let rows = [
    firstRow,
    !isSummary && ['', '', '', '', '', '', regularHours, otHours, doubleOtHours, totalPay],
    showSaturday && ['', '', '', '', '', '', 'SATURDAY HOURS:', 'SATURDAY OT HOURS:', 'SATURDAY DT OT HOURS:'],
    showSaturday && ['', '', '', '', '', '', saturdayHours, saturdayOTHours, saturdayDoubleOTHours],
    showSunday && ['', '', '', '', '', '', 'SUNDAY HOURS:', 'SUNDAY OT HOURS:', 'SUNDAY DT OT HOURS:'],
    showSunday && ['', '', '', '', '', '', sundayHours, sundayOTHours, sundayDoubleOTHours],
    [],
    headerRow,
  ].filter(Boolean);

  let lastDay = null;
  let firstWrite = true;
  let dayRows = [];
  let timeSummary = 0;
  let lastUser = null;
  tasks.forEach((task, idx) => {
    const {
      projectName,
      costCode,
      phaseId,
      startTime,
      originalStart,
      endTime,
      originalEnd,
      breakStartTime,
      breakEndTime,
      otStartTime,
      otEndTime,
      doubleOTStartTime,
      doubleOTEndTime,
      state,
      note,
      type,
      hourBased,
      timezone,
      userId,
      classId,
      geofenceViolation,
      outsideWorkingHours,
      enteredVia,
      approvers,
    } = task;

    const phase = phaseMap[phaseId]?.name;

    const {
      [classId]: unionClass,
    } = classMap ?? {};

    const {
      [unionClass?.localId]: unionLocal,
    } = unionLocalMap ?? {};

    const {
      [unionLocal?.unionId]: union,
    } = unionMap ?? {};

    const timestamp = TaskHelpers.getEndTime(task) ?? TaskHelpers.getStartTime(task);
    const runtimes = TaskHelpers.getRuntimes(task);
    const totalRuntime = runtimes.regularTime + runtimes.overtime + runtimes.doubleOT;
    const dayKey = formatDate(timestamp);
    const ourUsername = userMap[userId]?.name;
    if (dayKey !== lastDay || userId !== lastUser) {
      if (dayRows.length > 0 && timeSummary) {
        rows = rows.concat(createDayRow({
          dayRows,
          lastDay,
          timeSummary,
          roundingInterval: roundingInterval || 1,
          firstWrite,
          roundingType,
          roundingSetting,
        }));
        firstWrite = false;
      }
      lastUser = userId;
      lastDay = dayKey;
      dayRows = [];
      timeSummary = 0;
    }

    let violationValue = '';
    if (geofenceViolation) {
      violationValue += 'This Time Card has a geofence breach. ';
    }
    if (outsideWorkingHours) {
      violationValue += 'This Time Card is outside of working hours.';
    }

    const values = [
      { key: 'DATE', value: dayKey },
      { key: 'NAME', value: ourUsername },
      { key: 'REGULAR START TIME', value: hourBased ? '' : formatTime(TaskHelpers.getDisplayStart({ startTime, originalStart }, showOriginalTimes), timezone) },
      { key: 'REGULAR END TIME', value: hourBased ? '' : formatTime(TaskHelpers.getDisplayEnd({ endTime, originalEnd }, showOriginalTimes), timezone) },
      { key: 'BREAK START TIME', value: hourBased ? '' : formatTime(breakStartTime, timezone) },
      { key: 'BREAK END TIME', value: hourBased ? '' : formatTime(breakEndTime, timezone) },
      { key: 'OT START TIME', value: hourBased ? '' : formatTime(otStartTime, timezone) },
      { key: 'OT END TIME', value: hourBased ? '' : formatTime(otEndTime, timezone) },
      { key: 'DT OT START TIME', value: hourBased ? '' : formatTime(doubleOTStartTime, timezone) },
      { key: 'DT OT END TIME', value: hourBased ? '' : formatTime(doubleOTEndTime, timezone) },
      { key: 'DURATION', value: formatRuntime(totalRuntime) },
      { key: 'REGULAR DURATION', value: formatRuntime(runtimes.regularTime) },
      { key: 'OT DURATION', value: formatRuntime(runtimes.overtime) },
      { key: 'DT OT DURATION', value: formatRuntime(runtimes.doubleOT) },
      { key: 'PTO DURATION', value: formatRuntime(runtimes.pto) },
      { key: 'TYPE', value: toTitleCase(type) },
      { key: `${t('Project').toUpperCase()} NAME`, value: projectName },
      { key: 'PHASE', value: phase },
      { key: 'COST CODE', value: costCode },
      { key: 'UNION', value: union?.name ?? '' },
      { key: 'LOCAL CLASS', value: unionLocal?.name ?? '' },
      { key: 'WORK CLASS', value: unionClass?.name ?? '' },
      { key: 'NOTES', value: note },
      { key: 'ENTERED VIA', value: enteredVia },
      {
        key: 'APPROVED BY',
        value: approvers
          ?.filter((approver) => !!approver)
          .map((approver) => approver.name)
          .join(','),
      },
      { key: 'VIOLATION', value: violationValue },
      { key: 'STATUS', value: state ? toTitleCase(state) : 'Unsubmitted' },
    ];

    const row = values.filter((col) => headerSet.has(col.key))
      .map((col) => col.value);

    dayRows.push(row);

    customColumns?.forEach((fieldId) => {
      const customField = customFieldMap[fieldId];
      const data = task[fieldId];
      if (customField && divisionIds.has(customField.divisionId)) {
        if (customField.columns?.length) {
          customField.columns?.forEach((col) => {
            row.push(data?.[col.key] ?? '');
          });
        } else {
          row.push(data ?? '');
        }
      }
    });

    timeSummary += totalRuntime;
    if (idx === tasks.length - 1 && dayRows.length > 0 && timeSummary) {
      rows = rows.concat(createDayRow({
        dayRows,
        lastDay,
        timeSummary,
        roundingInterval: roundingInterval || 1,
        firstWrite,
        roundingType,
        roundingSetting,
      }));
    }
  });

  rows.push([]);

  const sheet = XLSX.utils.aoa_to_sheet(rows);
  // Sheet name cannot exceed 31 char.
  XLSX.utils.book_append_sheet(workbook, sheet, title.slice(0, 31));
  XLSX.writeFile(workbook, `${title}.xlsx`);
};
