import React from 'react';

import { Alert } from 'antd';

function OntraccrMapErrorView() {
  return (
    <Alert
      message="Error"
      description="Google Maps failed to load"
      type="error"
      showIcon
    />
  );
}

export default OntraccrMapErrorView;