import React from 'react';
import {
  Row, Col, Checkbox, Select,
  Space,
} from 'antd';
import PropTypes from 'prop-types';

import HoverHelp from '../../../../common/HoverHelp';
import OnTraccrNumberInput from '../../../../common/inputs/OnTraccrNumberInput';
import TableProjectPreload from './TableProjectPreload';
import TableFormPreload from './TableFormPreload';

const DATE_MOD_OPTIONS = [
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Week' },
];

const STATUS_OPTIONS = [
  { value: 'unsubmitted', label: 'Unsubmitted' },
  { value: 'submitted', label: 'Submitted' },
  { value: 'approved', label: 'Approved' },
];

function TablePreloadSettings({
  configProps,
  updateConfig,
  sections = [],
  divisions,
  dataType,
  templateId,
}) {
  const isTimeEntry = dataType === 'TimeEntry';

  const dateFieldOptions = sections.reduce((acc, section) => {
    const { fields = [], name: sectionName } = section;
    return acc.concat(
      fields.reduce((fieldAcc, field) => {
        const {
          id: fieldId,
          selectedType,
          configProps: {
            title: fieldTitle,
          } = {},
        } = field;
        if (selectedType !== 'dateRange' && selectedType !== 'dateTime') return fieldAcc;
        return fieldAcc.concat([{
          label: `${sectionName} - ${fieldTitle}`,
          value: fieldId,
        }]);
      }, []),
    );
  }, []);

  const getMax = () => {
    const {
      preloadDateModifier,
    } = configProps ?? {};

    return preloadDateModifier === 'weeks' ? 1 : 7;
  };

  const updateValue = (key) => (value) => {
    let newVal = value;
    if (key === 'preloadDateCount' && !value) newVal = 1;
    if (key === 'preloadDateModifier' && newVal === 'weeks') {
      updateConfig({
        [key]: newVal,
        preloadDateCount: 1,
      });
    } else {
      updateConfig({ [key]: newVal });
    }
  };

  return (
    <Space direction="vertical">
      { !!isTimeEntry && (
        <Row gutter={10}>
          <Col>
            <Checkbox
              checked={
                configProps.preloadExistingEntries
                  && !configProps.shouldSavePresetTableSelections
              }
              onChange={(e) => updateConfig({
                preloadExistingEntries: e?.target?.checked,
                preloadDateCount: e?.target?.checked ? 1 : null,
                preloadDateModifier: e?.target?.checked ? 'days' : null,
              })}
              disabled={configProps.shouldSavePresetTableSelections}
            >
              Preload Existing Time Entries
            </Checkbox>
          </Col>
          <Col>
            <HoverHelp
              placement="topRight"
              content={(
                <div style={{ width: 300 }}>
                  Check this box if you want to preload existing time entries
                  based off the filters below
                </div>
              )}
            />
          </Col>
        </Row>
      )}
      {
        configProps.preloadExistingEntries
        && (
          <Space direction="vertical" style={{ paddingLeft: isTimeEntry ? 20 : 0 }}>
            <Row align="middle" gutter={10}>
              <Col>
                In the last
              </Col>
              <Col style={{ height: 32 }}>
                <OnTraccrNumberInput
                  min={1}
                  max={getMax()}
                  defaultValue={1}
                  precision={0}
                  value={configProps.preloadDateCount ?? 1}
                  onChange={updateValue('preloadDateCount')}
                  style={{ width: 100 }}
                  disabled={configProps.preloadDateField}
                />
              </Col>
              <Col>
                <Select
                  options={DATE_MOD_OPTIONS}
                  style={{ width: 100 }}
                  value={configProps.preloadDateModifier ?? 'days'}
                  onSelect={updateValue('preloadDateModifier')}
                  disabled={configProps.preloadDateField}
                />
              </Col>
            </Row>
            <Row align="middle" gutter={10}>
              <Col>
                Or Date Field
              </Col>
              <Col>
                <Select
                  style={{ width: 500 }}
                  options={dateFieldOptions}
                  optionFilterProp="label"
                  allowClear
                  showSearch
                  placeholder="Select a Date Field"
                  value={configProps.preloadDateField}
                  onChange={updateValue('preloadDateField')}
                />
              </Col>
            </Row>
            {isTimeEntry && (
              <Row align="middle" gutter={10}>
                <Col>
                  With Status
                </Col>
                <Col>
                  <Select
                    options={STATUS_OPTIONS}
                    style={{ width: 500 }}
                    allowClear
                    mode="multiple"
                    value={configProps.preloadStatuses}
                    onChange={updateValue('preloadStatuses')}
                  />
                </Col>
              </Row>
            )}
            {!isTimeEntry && (
              <>
                <TableFormPreload
                  configProps={configProps}
                  updateConfig={updateConfig}
                  divisions={divisions}
                  sections={sections}
                  templateId={templateId}
                />
                <TableProjectPreload
                  configProps={configProps}
                  updateConfig={updateConfig}
                  divisions={divisions}
                  sections={sections}
                  hideTitle
                />
              </>
            )}
          </Space>
        )
      }
    </Space>
  );
}

/* eslint-disable react/forbid-prop-types */
TablePreloadSettings.propTypes = {
  configProps: PropTypes.object.isRequired,
  updateConfig: PropTypes.func.isRequired,
  sections: PropTypes.array,
  dataType: PropTypes.string.isRequired,
  divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
  templateId: PropTypes.string,
};

TablePreloadSettings.defaultProps = {
  sections: [],
  templateId: null,
};

export default TablePreloadSettings;
