import React, { useMemo, useCallback, useState, useEffect } from 'react';

import WorkflowNotificationNode from './WorkflowNotificationNode';
import { updateData } from './workflowHelpers';
import OnTraccrCheckbox from '../../common/inputs/OnTraccrCheckbox';

const HOVER_TEXT = `
Assigned users are the people you want to fill out the form.
`;

export default ({
  setDataMap,
  isDisplay,
  divisionId,
  isExternalForm,
  setIsSharedForm,
}) => ({
  id,
  draggable,
  data,
}) => {
  const {
    sharedForm: initialSharedForm,
  } = data || {};

  const [localIsSharedForm, setLocalIsSharedForm] = useState(initialSharedForm);

  useEffect(() => {
    setLocalIsSharedForm(initialSharedForm);
  }, [initialSharedForm]);

  const onCheckChanged = useCallback((key) => (checked) => {
    setDataMap(updateData(id, { [key]: checked }));
    setIsSharedForm(checked);
    setLocalIsSharedForm(checked);
  },[setDataMap, id]);

  const children = useMemo(() => (
    <OnTraccrCheckbox
      style={{ width: '100%', margin: '5px 0px' }}
      value={localIsSharedForm}
      onChange={onCheckChanged('sharedForm')}
      hoverNode={(
        <div style={{ width: 300 }}>
          Check this box if you want to create one form and assign it to multiple people <br/> <br/>
          Leave this box unchecked if you want a seperate form for each eligible person.
          Note that this setting only works for manual forms.
        </div>
      )}
      label='Shared Form?'
    />
  ), [initialSharedForm, onCheckChanged, localIsSharedForm]);

  return (
    <WorkflowNotificationNode
      type='users'
      id={id}
      draggable={draggable}
      data={data}
      style={{
        width: 300,
      }}
      setDataMap={setDataMap}
      isDisplay={isDisplay}
      hover={HOVER_TEXT}
      divisionId={divisionId}
      isExternalForm={isExternalForm}
    >
      {children}
    </WorkflowNotificationNode>
  );
}
