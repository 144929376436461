import React from 'react';
import { Popconfirm, Row } from 'antd';

import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';

export default ({
  title,
  showPopover,
  onCancel,
  onConfirm,
  onChange,
  value,
  hasChildren,
  formatter,
  max,
  min = 0,
  precision,
  style = {},
  disabled = false,
}) => (
  <Popconfirm
    visible={showPopover}
    title={title}
    okText='Update'
    onConfirm={onConfirm}
    onCancel={onCancel}
  >
    {
    hasChildren ?
      <Row align='middle' style={{ height:'100%', paddingLeft: 10 }}>
        {`${value}%`}
      </Row>

    :
      <OnTraccrNumberInput
        disabled={disabled}
        min={min}
        max={max}
        value={value}
        formatter={formatter}
        onChange={onChange}
        precision={precision}
        style={{
          width: 80,
          borderRadius: 6,
          borderColor: '#323232',
          ...style,
        }}
        onPressEnter={onConfirm}
      />
    }
  </Popconfirm>
)
