import sortByString, { getIdMap } from './helpers';

export default {};
export const updateArray = ({
  oldArray,
  id,
  newData,
}) => {
  const newArray = [...oldArray];
  return newArray.map((item) => {
    if (item.id !== id) return item;
    return {
      ...item,
      ...newData,
    };
  }).sort(sortByString('name'));
};

export const updateObject = ({
  oldObject,
  id,
  newData,
}) => {
  const newObject = { ...oldObject };
  newObject[id] = {
    ...oldObject[id],
    ...newData,
  };
  return newObject;
};

export const removeFromObject = ({
  oldObject,
  id,
}) => {
  const newObject = { ...oldObject };
  delete newObject[id];
  return newObject;
};

/**
 * Updates redux state based off old, new and deleted data
 * @param {Array[object]} oldData existing state array
 * @param {Array[object]} newData new state data, either created or updated
 * @param {Array[string]} deletedData items that have been deleted
 * @returns {Array[object]} The new state
*/
export const updateStateFromDiff = ({
  oldData = [],
  newData = [],
  deletedData = [],
}) => {
  const deleteSet = new Set(deletedData);

  const newDataMap = getIdMap(newData);

  const finalState = oldData.reduce((acc, item) => {
    if (deleteSet.has(item.id)) return acc;
    if (item.id in newDataMap) {
      acc.push(newDataMap[item.id]);
      delete newDataMap[item.id];
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

  // Add new data that isnt in state
  Object.values(newDataMap).forEach((cc) => {
    finalState.push(cc);
  });

  return finalState;
};

