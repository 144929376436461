import React from 'react';

import CustomConfirmModal from '../common/modals/CustomConfirmModal';

export default ({
  onOk,
}) => (
  CustomConfirmModal({
    title: 'Are you sure you want to close?',
    content: (
      <div style={{ textAlign: 'center', width: '100%' }}>
        All entered data will be lost if you close the card.
      </div>
    ),
    okText: 'Close',
    cancelText: 'Cancel',
    onOk,
  })
);
