import React, { useMemo, useCallback } from 'react';
import { Row, Typography } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ListCard from '../common/ListCard';
import colors from '../constants/Colors';

import Permissions from '../auth/Permissions';
import { getIdMap } from '../helpers/helpers';
import { bucketLinkTypeToDisplayName, getDownstreamName, getUpstreamName } from './bucketHelpers';

export const getName = (ids, map, type) => {
  if (!ids?.length) return `No ${type} Selected`;
  return ids.map((id) => map[id]?.name).join(', ');
};

export default function BucketCard({
  id,
  name,
  description,
  upstreamTypes,
  downstreamTypes,
  onEdit,
  onDelete,
  onClick,
  style,
  entityType,
  ...bucketProps
}) {
  const { t } = useTranslation();
  const customers = useSelector((state) => state.customers.customers);
  const projects = useSelector((state) => state.projects.projects);
  const bucketTemplates = useSelector((state) => state.buckets.bucketTemplates);
  const buckets = useSelector((state) => state.buckets.buckets);
  const equipment = useSelector((state) => state.equipment.equipment);

  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const bucketMap = useMemo(() => getIdMap(buckets), [buckets]);
  const equipmentMap = useMemo(() => getIdMap(equipment), [equipment]);
  const bucketTemplateMap = useMemo(() => getIdMap(bucketTemplates), [bucketTemplates]);

  const hasWritePerms = Permissions.has(
    entityType === 'ProjectGroups' ? 'PROJECTS_WRITE' : 'BUCKETS_WRITE',
  );

  const DownstreamRow = useCallback(({
    downstreamType,
    downstreamIds,
  }) => (
    <Row key={downstreamType}>
      <Typography.Text
        ellipsis
        style={{
          width: '100%',
          fontFamily: 'roboto-light',
          fontSize: 10,
          color: colors.DARK_GRAY,
          lineHeight: '12px',
          marginTop: 5,
        }}
      >
        <span>{bucketLinkTypeToDisplayName(t, downstreamType, bucketTemplateMap)}: </span>
        {getDownstreamName({
          entityType,
          downstreamType,
          downstreamIds,
          projectMap,
          bucketMap,
          bucketTemplateMap,
          equipmentMap,
          t,
        })}
      </Typography.Text>
    </Row>
  ), [bucketTemplateMap, entityType, projectMap, bucketMap, equipmentMap]);

  return (
    <ListCard
      style={style}
      onEdit={hasWritePerms ? () => onEdit({
        id,
        name,
        description,
        upstreamTypes,
        downstreamTypes,
      }) : null}
      onDelete={hasWritePerms ? () => onDelete({
        id, name,
      }) : null}
      onClick={() => onClick({
        id,
        name,
        description,
        upstreamTypes,
        downstreamTypes,
      })}
      bodyStyle={{ height: '100%' }}
    >
      <Row
        style={{
          height: 17,
          width: '100%',
          paddingBottom: 25,
          paddingTop: 6,
          overflowWrap: 'anywhere',
        }}
        type="flex"
      >
        <Typography.Text
          ellipsis
          style={{
            width: '100%',
            fontFamily: 'roboto-bold',
            fontSize: 16,
            color: colors.ONTRACCR_BLACK,
          }}
        >
          {name}
        </Typography.Text>
      </Row>
      { !!description && (
        <Row>
          <Typography.Text
            ellipsis
            style={{
              width: '100%',
              fontFamily: 'roboto-light',
              fontSize: 10,
              color: colors.DARK_GRAY,
              lineHeight: '12px',
              marginTop: 5,
            }}
          >
            {description}
          </Typography.Text>
        </Row>
      )}
      { !!upstreamTypes?.length && upstreamTypes.map((upstreamType) => (
        <Row key={upstreamType}>
          <Typography.Text
            ellipsis
            style={{
              width: '100%',
              fontFamily: 'roboto-light',
              fontSize: 10,
              color: colors.DARK_GRAY,
              lineHeight: '12px',
              marginTop: 5,
            }}
          >
            <span>{bucketLinkTypeToDisplayName(t, upstreamType, bucketTemplateMap)}: </span>
            {getUpstreamName({
              t,
              upstreamType,
              upstreamIds: bucketProps[upstreamType] ?? [],
              customers,
              bucketMap,
              bucketTemplateMap,
            })}
          </Typography.Text>
        </Row>
      ))}
      { entityType === 'ProjectGroups' && (
        <DownstreamRow
          downstreamType="project-many"
          downstreamIds={bucketProps.downstreamIds}
        />
      )}

      { !!downstreamTypes?.length && downstreamTypes.map((downstreamType) => (
        <DownstreamRow
          key={downstreamType}
          downstreamType={downstreamType}
          downstreamIds={bucketProps[downstreamType] ?? []}
        />
      ))}
    </ListCard>
  );
}

BucketCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  upstreamTypes: PropTypes.arrayOf(PropTypes.string),
  downstreamTypes: PropTypes.arrayOf(PropTypes.string),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  entityType: PropTypes.string.isRequired,
};

BucketCard.defaultProps = {
  style: {},
  description: null,
  upstreamTypes: [],
  downstreamTypes: [],
};
