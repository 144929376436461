import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Divider,
  Badge,
  message,
  Select,
} from 'antd';
import axios from 'axios';
import { DateTime, IANAZone } from 'luxon';
import { useTranslation } from 'react-i18next';

import CompanyEditRow from '../../CompanyEditRow';

import DisplayText from '../../../common/text/DisplayText';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import TimezoneSelector from '../../../common/inputs/TimezoneSelector';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

import { useInterval } from '../../../helpers/helpers';
import Debouncer from '../../../helpers/Debouncer';
import useToggle from '../../../common/hooks/useToggle';

import Colors from '../../../constants/Colors';

import EclipseConfigureDrawer from './EclipseConfigureDrawer';
import EclipseWorkflows from './EclipseWorkflows';
import EclipseUserPayTypeDrawer from './EclipseUserPayTypeDrawer';
import ErrorRecipientSelector from '../ErrorRecipientSelector';

const MATERIAL_SYNC_OPTIONS = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
];

const flavourText = (
  <div>
    Deactivating your Eclipse integration will disable any
    further syncing between Ontraccr and Eclipse.
    <br />
    <br />
    To disconnect your Eclipse instance, please contact
    {' '}
    <b>support@ontraccr.com</b>
  </div>
);

const INPUT_STYLE = {
  marginLeft: 10,
  marginRight: 40,
  marginBottom: 20,
  maxWidth: 440,
  width: '100%',
};
const DIVIDER_STYLE = {
  margin: 0,
  backgroundColor: Colors.ONTRACCR_OPACITY_GRAY,
  maxWidth: 440,
  minWidth: 440,
};

const debouncers = {
  username: new Debouncer(),
  password: new Debouncer(),
  endpoint: new Debouncer(),
  besGroup: new Debouncer(),
};

const update = async (payload) => {
  try {
    await axios.put('/eclipse/config', payload);
  } catch (err) {
    message.error('Failed to update Eclipse config');
  }
};

const loadSyncTimes = async ({
  setLastMaterialWrite,
  setLastSync,
  setConfig,
}) => {
  const {
    data: {
      lastSync: syncTime,
      lastMaterialWrite: materialWriteTime,
    } = {},
  } = await axios.get('/eclipse/sync/time');
  const { data: newConfig } = setConfig
    ? await axios.get('/eclipse/config')
    : {};
  if (syncTime && newConfig.timezone) {
    const zone = newConfig.timezone && IANAZone.isValidZone(newConfig.timezone)
      ? IANAZone.create(newConfig.timezone) : 'utc';
    setLastSync(DateTime.fromMillis(syncTime).setZone(zone));
  }
  if (materialWriteTime && newConfig.timezone) {
    const zone = newConfig.timezone && IANAZone.isValidZone(newConfig.timezone)
      ? IANAZone.create(newConfig.timezone) : 'utc';
    setLastMaterialWrite(DateTime.fromMillis(syncTime).setZone(zone));
  }
  if (newConfig) setConfig(newConfig);
};

const computeNextWriteTime = ({
  timezone,
  materialWriteIntervalFrequency,
} = {}) => {
  const zone = timezone && IANAZone.isValidZone(timezone)
    ? IANAZone.create(timezone) : 'utc';
  // 00:00:00 Pacific Time
  const targetDay = DateTime
    .local()
    .setZone('America/Vancouver')
    .plus({ day: 1 })
    .startOf('day');

  if (materialWriteIntervalFrequency === 'weekly') {
    // Sunday 00:00:00
    return targetDay.endOf('week').startOf('day').setZone(zone);
  }
  return targetDay.setZone(zone);
};

function Eclipse({
  visible,
}) {
  const { t } = useTranslation();
  const [lastSync, setLastSync] = useState();
  const [lastMaterialWrite, setLastMaterialWrite] = useState();
  const [config, setConfig] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);

  const {
    isToggled: payTypeDrawerOpen,
    toggle: togglePayTypeDrawer,
  } = useToggle();

  const onOpenDrawer = useCallback(() => setDrawerVisible(true), []);
  const onCloseDrawer = useCallback(() => setDrawerVisible(false), []);

  const onTextChanged = useCallback((type) => (e) => {
    if (!(type in debouncers)) return;
    const {
      target: { value } = {},
    } = e;
    setConfig({
      ...config,
      [type]: value,
    });

    debouncers[type].debounce(() => {
      if (value) update({ [type]: value });
    }, 250);
  }, [config]);

  const onTimezoneSelect = useCallback(async (newTimezone) => {
    const newConfig = {
      ...config,
      timezone: newTimezone,
    };
    setConfig(newConfig);
    await update(newConfig);
  }, [config]);

  const onMaterialFrequencySelect = useCallback(async (newFrequency) => {
    const newConfig = {
      ...config,
      materialWriteIntervalFrequency: newFrequency,
    };
    setConfig(newConfig);
    await update(newConfig);
  }, [config]);

  useEffect(() => {
    if (visible) {
      loadSyncTimes({
        setLastSync,
        setLastMaterialWrite,
        setConfig,
      });
    } else {
      setLastSync();
      setLastMaterialWrite();
      setConfig({});
    }
  }, [visible]);

  useInterval(() => {
    if (visible) {
      loadSyncTimes({
        setLastSync,
        setLastMaterialWrite,
      });
    } else {
      setLastSync();
      setLastMaterialWrite();
    }
  }, [1000 * 60 * 5]); // Once every 5 minutes

  const now = DateTime.local();

  const hasValidIntegration = !(
    !config.username
    || !config.password
    || !config.endpoint
    || !config.besGroup
    || !config.timezone
  );
  return (
    <div style={{ width: '100%', overflowY: 'auto' }}>
      <CompanyEditRow
        title="Last Sync"
        helpText="The last time your Eclipse instance was synced with Ontraccr"
      >
        <Badge
          color={
            !lastSync || now.diff(lastSync).as('minutes') > 30
              ? 'red'
              : 'green'
          }
          style={{ paddingRight: 10 }}
        />
        <span style={{ fontSize: 12 }}>
          {
            lastSync
              ? lastSync.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
              : 'Never'
          }
        </span>
      </CompanyEditRow>
      <CompanyEditRow
        title="Configure"
        helpText={`Configure Divisions, ${t('Project', { count: 0 })}, Materials, etc.`}
      >
        <OnTraccrButton
          title="Set Up"
          onClick={onOpenDrawer}
          disabled={!hasValidIntegration}
        />
      </CompanyEditRow>

      <CompanyEditRow
        title="Username"
        helpText="The username of the Eclipse account"
        divider={false}
      />
      <OnTraccrTextInput
        style={INPUT_STYLE}
        onChange={onTextChanged('username')}
        autocomplete="off"
        value={config.username}
      />
      <Divider style={DIVIDER_STYLE} />
      <CompanyEditRow
        title="Password"
        helpText="The password of the Eclipse account"
        divider={false}
      />
      <OnTraccrTextInput
        style={INPUT_STYLE}
        onChange={onTextChanged('password')}
        password
        autoComplete="new-password"
        value={config.password}
      />
      <Divider style={DIVIDER_STYLE} />
      <CompanyEditRow
        title="Endpoint"
        helpText="Your Eclipse endpoint"
        divider={false}
      />
      <OnTraccrTextInput
        style={INPUT_STYLE}
        onChange={onTextChanged('endpoint')}
        value={config.endpoint}
      />
      <Divider style={DIVIDER_STYLE} />
      <CompanyEditRow
        title="BES Group"
        helpText="Your Eclipse BES Group"
        divider={false}
      />
      <OnTraccrTextInput
        style={INPUT_STYLE}
        onChange={onTextChanged('besGroup')}
        value={config.besGroup}
      />
      <Divider style={DIVIDER_STYLE} />
      <CompanyEditRow
        title="Timezone"
        helpText="The timezone of your Eclipse server"
        divider={false}
      />
      <TimezoneSelector
        style={INPUT_STYLE}
        onSelect={onTimezoneSelect}
        value={config.timezone}
      />
      <Divider style={DIVIDER_STYLE} />
      <CompanyEditRow
        title="Material Sync Frequency"
        helpText="How frequently Ontraccr will write Material usage data to Eclipse"
        divider={false}
      />
      <Select
        style={{
          ...INPUT_STYLE,
          marginBottom: 10,
        }}
        onSelect={onMaterialFrequencySelect}
        options={MATERIAL_SYNC_OPTIONS}
        value={config.materialWriteIntervalFrequency}
      />
      <Row justify="space-between" style={{ padding: '0px 10px' }}>
        <Col style={{ fontSize: 12, color: Colors.ONTRACCR_OPACITY_GRAY }}>
          Last Write Time:
        </Col>
        <Col style={{ fontSize: 12 }}>
          {
            lastMaterialWrite
              ? lastMaterialWrite.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
              : 'Never'
          }
        </Col>
      </Row>
      <Row justify="space-between" style={{ padding: '0px 10px 10px 10px' }}>
        <Col style={{ fontSize: 12, color: Colors.ONTRACCR_OPACITY_GRAY }}>
          Estimate Next Write Time:
        </Col>
        <Col style={{ fontSize: 12 }}>
          {
            computeNextWriteTime(config)
              .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
          }
        </Col>
      </Row>
      <Divider style={DIVIDER_STYLE} />
      <CompanyEditRow
        title="Pay Types"
        helpText="Manager Ontraccr's database of Eclipse Employee Pay Types"
      >
        <OnTraccrButton
          title="View"
          onClick={togglePayTypeDrawer}
          disabled={!hasValidIntegration}
        />
      </CompanyEditRow>
      <ErrorRecipientSelector
        integration="Eclipse"
        recipientsKey="eclipseErrorRecipients"
        selectorStyle={INPUT_STYLE}
      />
      <EclipseWorkflows />
      <Divider style={DIVIDER_STYLE} />
      <Row style={{ minHeight: 32, width: '100%', paddingLeft: 10 }} justify="space-between" align="middle">
        <Col>
          <DisplayText
            title="Deactivate Eclipse Integration"
            style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
          />
        </Col>
      </Row>
      <DisplayText
        title={flavourText}
        style={{
          marginBottom: 20,
          maxWidth: 275,
          paddingLeft: 10,
          fontFamily: 'roboto-regular',
          color: Colors.ONTRACCR_OPACITY_GRAY,
        }}
      />
      <EclipseConfigureDrawer
        visible={drawerVisible}
        onClose={onCloseDrawer}
      />
      <EclipseUserPayTypeDrawer
        visible={payTypeDrawerOpen}
        onClose={togglePayTypeDrawer}
      />
    </div>
  );
}

Eclipse.propTypes = {
  visible: PropTypes.bool,
};

Eclipse.defaultProps = {
  visible: false,
};

export default Eclipse;
