import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Select } from 'antd';

import FormBuilder from '../../forms/FormBuilder/FormBuilder';
import CompanySettingsCard from '../CompanySettingsCard';
import SettingsCardHeader from '../SettingsCardHeader';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import { getCostcodeCustomFieldTemplate, updateCostcodeCustomFieldTemplate } from '../../costcodes/state/costcodes.actions';
import {
  getUserCustomFieldTemplate,
  updateUserCustomFieldTemplate,
} from '../../users/state/users.actions';
import { getCustomFields } from '../../timecards/state/timecards.actions';

import {
  getCertificationCustomFieldTemplate,
  updateCertificationCustomFieldTemplate,
} from '../../certifications/state/certifications.actions';
import { getProjectCustomFieldTemplate, updateProjectCustomFieldTemplate } from '../../projects/state/projects.actions';

const TYPES_OPTIONS = [
  { value: 'costcodes', label: 'Cost Codes' },
  { value: 'users', label: 'Users' },
  { value: 'usercertifications', label: 'User Certifications' },
  { value: 'vendorcertifications', label: 'Vendor Certifications' },
  { value: 'projects', label: 'Projects' },
];

export default function CustomFields() {
  const dispatch = useDispatch();
  const costcodeCustomFields = useSelector((state) => state.costcodes.customFieldTemplate);
  const userCustomFields = useSelector((state) => state.users.customFieldTemplate);
  const projectCustomFields = useSelector((state) => state.projects.customFieldTemplate);
  const {
    users: {
      certificationCustomFieldTemplate: userCertificationCustomFields,
    } = {},
    vendors: {
      certificationCustomFieldTemplate: vendorCertificationCustomFields,
    } = {},
  } = useSelector(
    (state) => state.certifications,
  );

  const [sections, setSections] = useState([]);
  const [type, setType] = useState(TYPES_OPTIONS[0].value);

  const {
    sections: customFields,
  } = useMemo(() => {
    switch (type) {
      case 'costcodes':
        return costcodeCustomFields;
      case 'users':
        return userCustomFields;
      case 'usercertifications':
        return userCertificationCustomFields;
      case 'vendorcertifications':
        return vendorCertificationCustomFields;
      case 'projects':
        return projectCustomFields;
      default:
        return costcodeCustomFields;
    }
  }, [
    type,
    costcodeCustomFields,
    userCustomFields,
    userCertificationCustomFields,
    vendorCertificationCustomFields,
    projectCustomFields,
  ]);

  useEffect(() => {
    if (type === 'users') dispatch(getUserCustomFieldTemplate());
    if (type === 'costcodes') dispatch(getCostcodeCustomFieldTemplate());
    if (type === 'usercertifications') dispatch(getCertificationCustomFieldTemplate('users'));
    if (type === 'vendorcertifications') dispatch(getCertificationCustomFieldTemplate('vendors'));
    if (type === 'projects') dispatch(getProjectCustomFieldTemplate());
  }, [type]);

  useEffect(() => {
    dispatch(getCustomFields());
  }, []);

  const onSaveFields = () => {
    if (type === 'users') dispatch(updateUserCustomFieldTemplate({ sections }));
    if (type === 'costcodes') dispatch(updateCostcodeCustomFieldTemplate({ sections }));
    if (type === 'usercertifications') dispatch(updateCertificationCustomFieldTemplate('users', { sections }));
    if (type === 'vendorcertifications') dispatch(updateCertificationCustomFieldTemplate('vendors', { sections }));
    if (type === 'projects') dispatch(updateProjectCustomFieldTemplate({ sections }));
  };

  const TypeSelector = useMemo(() => (
    <Select
      style={{ width: 350 }}
      value={type}
      onSelect={setType}
      options={TYPES_OPTIONS}
    />
  ), [type, TYPES_OPTIONS]);

  return (
    <Row
      id="divisions-container"
      style={{
        width: '100%',
        height: '95%',
        marginLeft: 10,
        marginTop: 30,
        position: 'relative',
      }}
    >
      <SettingsCardHeader
        title={TypeSelector}
        style={{ width: '100%', paddingRight: 50 }}
      >
        <OnTraccrButton
          title="Save"
          type="primary"
          onClick={onSaveFields}
        />
      </SettingsCardHeader>
      <CompanySettingsCard
        title=""
        style={{
          position: 'absolute',
          top: 30,
          bottom: 20,
          right: 50,
          left: 0,
          padding: 0,
          height: 'auto',
        }}
        containerStyle={{
          paddingLeft: 5,
          paddingRight: 5,
          width: '100%',
          border: 'none',
        }}
      >
        <div
          className="form-builder-container"
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          <FormBuilder
            key={type}
            isBoardCards
            style={{ inset: 0 }}
            onSectionsChanged={setSections}
            initialSections={customFields}
            keyboard={false}
            showParentAttributeCheckbox={type === 'projects'}
          />
        </div>
      </CompanySettingsCard>
    </Row>
  );
}
