import {
  GET_EQUIPMENT,
  CREATE_EQUIPMENT,
  ARCHIVE_EQUIPMENT,
  DELETE_EQUIPMENT,
  UPDATE_EQUIPMENT,
  GET_EQUIPMENT_HISTORY,
  GET_PROJECT_EQUIPMENT,
  CHECK_IN_EQUIPMENT,
  CHECK_OUT_EQUIPMENT,
  GET_EQUIPMENT_FILES,
  GET_EQUIPMENT_NOTES,
  ADD_EQUIPMENT_NOTE,
  GET_EQUIPMENT_STATUSES,
  CREATE_STATUS,
  DELETE_STATUS,
  UPDATE_STATUS,
  GET_EQUIPMENT_TYPES,
  GET_EQUIPMENT_CUSTOM_DATA,
  UPDATE_EQUIPMENT_AVAILABILITY,
  GET_EQUIPMENT_CUSTOM_DATA_MAP,
  GET_EQUIPMENT_FORM_MAPPINGS,
  CREATE_EQUIPMENT_FORM_MAPPINGS,
  UPDATE_EQUIPMENT_FORM_MAPPINGS,
  DELETE_EQUIPMENT_FORM_MAPPINGS,
} from '../../state/actionTypes';

import { sortByLocalCompare } from '../../helpers/helpers';

const initialState = {
  equipment: [],
  equipmentHistory: {}, // { [equipmentId]: [list of equipmentEvents] }
  projectEquipment: {}, // { [projectId]: [list of equipmentIds] }
  equipmentProjects: {}, // { [equipmentId]: [list of projectIds] },
  equipmentFiles: {}, // { [equipmentId]: [list of files] },
  notes: {},
  fileMap: {}, // { [fileId]: fileObj }
  statuses: {},
  equipmentTypes: [],
  customData: [],
  customDataFiles: {},
  customDataMap: {},
  formMappings: {},
};

const update = ({
  state,
  id,
  newData,
}) => {
  const {
    equipment: oldEquipment = [],
  } = state;
  const newEquipment = [...oldEquipment].map((eq) => {
    if (eq.id !== id) return eq;
    return {
      ...eq,
      ...newData,
    };
  }).sort(sortByLocalCompare('name'));
  return {
    ...state,
    equipment: newEquipment,
  };
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_EQUIPMENT: {
      const {
        payload: {
          equipment = [],
        } = {},
      } = action;
      const newEq = [...equipment];
      newEq.sort(sortByLocalCompare('name'));
      return {
        ...state,
        equipment: newEq,
      };
    }
    case CREATE_EQUIPMENT: {
      const {
        payload: {
          equipment,
          files = [],
        } = {},
      } = action;
      const equipmentDetails = { ...equipment };
      const {
        projectIds = [],
      } = equipmentDetails;
      delete equipmentDetails.projectIds;
      const {
        equipment: stateEquipment = [],
        equipmentProjects: stateEP = {},
        equipmentFiles: stateEF = {},
      } = state;
      const newEquipment = [...stateEquipment];
      if (!newEquipment.some((eq) => eq.id === equipmentDetails.id)) {
        newEquipment.push({
          ...equipmentDetails,
          active: 1,
        });
      }
      newEquipment.sort(sortByLocalCompare('name'));

      const newEquipmentProjects = { ...stateEP };
      newEquipmentProjects[equipmentDetails.id] = projectIds;

      const newEquipmentFiles = { ...stateEF };
      newEquipmentFiles[equipmentDetails.id] = files;
      return {
        ...state,
        equipment: newEquipment,
        equipmentProjects: newEquipmentProjects,
        equipmentFiles: newEquipmentFiles,
      };
    }
    case ARCHIVE_EQUIPMENT: {
      const {
        payload: {
          id,
          active,
        } = {},
      } = action;
      const {
        equipment: stateEquipment = [],
      } = state;
      const newEquipment = [...stateEquipment];
      return {
        ...state,
        equipment: newEquipment.map((eq) => {
          if (eq.id !== id) return eq;
          return {
            ...eq,
            active: active ? 1 : 0,
          };
        }),
      };
    }
    case DELETE_EQUIPMENT: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const {
        equipment: stateEquipment = [],
      } = state;
      const newEquipment = [...stateEquipment];
      return {
        ...state,
        equipment: newEquipment.filter((eq) => eq.id !== id),
      };
    }
    case UPDATE_EQUIPMENT: {
      const {
        payload: {
          id,
          newData = {},
          createdLabels = [],
        } = {},
      } = action;

      const {
        equipmentProjects: stateEP = {},
        equipmentFiles: stateEF = {},
        equipment: stateEq = [],
      } = state;

      const ourEquipment = stateEq.find((eq) => eq.id === id);
      if (!ourEquipment) return state;

      const newDetails = { ...newData };
      const {
        addedProjects = [],
        deletedProjects = [],
        addedFiles = [],
        removedFiles = [],
        removedLabels = [],
      } = newDetails;
      delete newDetails.addedProjects;
      delete newDetails.deletedProjects;

      delete newDetails.addedFiles;
      delete newDetails.removedFiles;

      delete newDetails.addedLabels;
      delete newDetails.removedLabels;

      const newEquipmentProjects = { ...stateEP };
      const newEquipmentFiles = { ...stateEF };
      const {
        [id]: oldEp = [],
      } = newEquipmentProjects;

      const {
        [id]: oldFiles = [],
      } = newEquipmentFiles;

      const deleteSet = new Set(deletedProjects);
      const fileDeleteSet = new Set(removedFiles);
      const labelDeleteSet = new Set(removedLabels);

      const newEP = oldEp.filter((projectId) => !deleteSet.has(projectId)).concat(addedProjects);
      newEquipmentProjects[id] = newEP;

      const newEF = oldFiles.filter((file) => !fileDeleteSet.has(file.id)).concat(addedFiles);
      newEquipmentFiles[id] = newEF;

      const {
        labels: existingLabels = [],
      } = ourEquipment;

      newDetails.labels = existingLabels
        .filter((label) => !labelDeleteSet.has(label.id))
        .concat(createdLabels);

      const newState = update({
        state,
        id,
        newData: newDetails,
      });
      return {
        ...newState,
        equipmentProjects: newEquipmentProjects,
        equipmentFiles: newEquipmentFiles,
      };
    }
    case GET_EQUIPMENT_HISTORY: {
      const {
        payload: {
          id,
          history: equipmentHistory,
          fileMap = {},
        },
      } = action;
      const {
        equipmentHistory: oldHistory = {},
        fileMap: oldFileMap = {},
      } = state;
      const newHistory = { ...oldHistory };
      newHistory[id] = equipmentHistory;
      const newFileMap = { ...oldFileMap, ...fileMap };
      return {
        ...state,
        equipmentHistory: newHistory,
        fileMap: newFileMap,
      };
    }
    case GET_PROJECT_EQUIPMENT: {
      const {
        payload = {},
      } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case UPDATE_EQUIPMENT_AVAILABILITY: {
      const {
        payload = {},
      } = action;
      const {
        equipmentId,
        availability,
      } = payload;
      const newState = update({
        state,
        id: equipmentId,
        newData: {
          availability,
        },
      });

      return {
        ...newState,
      };
    }
    case CHECK_IN_EQUIPMENT: {
      const {
        payload = {},
      } = action;
      const {
        equipmentId,
        timestamp,
        projectId,
        metadata: { location } = {},
      } = payload;
      const newState = update({
        state,
        id: equipmentId,
        newData: {
          currentProjectId: projectId,
          currentLocationText: location,
          lastTask: {
            ...payload,
            startTime: timestamp,
            location,
          },
        },
      });

      const { equipmentHistory = {} } = state;
      const {
        [equipmentId]: ourHistory = [],
      } = equipmentHistory;
      const newEqH = { ...equipmentHistory };
      newEqH[equipmentId] = ourHistory.concat([payload]);

      return {
        ...newState,
        equipmentHistory: newEqH,
      };
    }

    case CHECK_OUT_EQUIPMENT: {
      const {
        payload = {},
      } = action;
      const {
        equipmentId, timestamp, userId,
      } = payload;
      const {
        equipment: oldEquipment = [],
      } = state;
      const newEquipment = oldEquipment.map((eq) => {
        if (eq.id !== equipmentId) return eq;
        const { lastTask = {} } = eq;
        return {
          ...eq,
          currentProjectId: null,
          currentLocationText: null,
          lastProjectId: lastTask?.projectId,
          lastTask: {
            ...lastTask,
            userId,
            endTime: timestamp,
            type: 'Check Out',
          },
        };
      });

      const { equipmentHistory = {} } = state;
      const {
        [equipmentId]: ourHistory = [],
      } = equipmentHistory;
      const newEqH = { ...equipmentHistory };
      newEqH[equipmentId] = ourHistory.concat([payload]);
      return {
        ...state,
        equipment: newEquipment,
        equipmentHistory: newEqH,
      };
    }
    case GET_EQUIPMENT_FILES: {
      const {
        payload: {
          id,
          files = [],
        },
      } = action;
      const newState = { ...state };
      const { equipmentFiles: oldFiles = {} } = state;
      const newFiles = { ...oldFiles };
      newFiles[id] = files;
      newState.equipmentFiles = newFiles;
      return newState;
    }
    case GET_EQUIPMENT_NOTES: {
      const {
        payload: {
          id,
          data: notes = [],
        } = {},
      } = action;
      const { notes: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      existingNotes[id] = notes;
      existingNotes[id].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        notes: existingNotes,
      };
    }
    case ADD_EQUIPMENT_NOTE: {
      const {
        payload = {},
      } = action;
      const { equipmentId } = payload;
      const { notes: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      const {
        [equipmentId]: existingUserNotes = [],
      } = existingNotes;
      existingNotes[equipmentId] = existingUserNotes.concat([payload]);
      existingNotes[equipmentId].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        notes: existingNotes,
      };
    }
    case GET_EQUIPMENT_STATUSES: {
      const {
        payload: {
          statuses = {},
        } = {},
      } = action;
      return {
        ...state,
        statuses,
      };
    }
    case CREATE_STATUS: {
      const {
        payload: {
          type,
          status = {},
        } = {},
      } = action;
      if (type !== 'equipment') return state;
      const { statuses: oldStatuses = {} } = state;
      const newStatuses = { ...oldStatuses };
      newStatuses[status.id] = status;
      return {
        ...state,
        statuses: newStatuses,
      };
    }
    case UPDATE_STATUS: {
      const {
        payload: {
          type,
          id,
          status,
        } = {},
      } = action;
      if (type !== 'equipment') return state;
      const { statuses: oldStatuses = {} } = state;
      const newStatuses = { ...oldStatuses };
      newStatuses[id] = { id, status };
      return {
        ...state,
        statuses: newStatuses,
      };
    }
    case DELETE_STATUS: {
      const {
        payload: {
          type,
          id,
        } = {},
      } = action;
      if (type !== 'equipment') return state;
      const { statuses: oldStatuses = {} } = state;
      const newStatuses = { ...oldStatuses };
      delete newStatuses[id];
      return {
        ...state,
        statuses: newStatuses,
      };
    }
    case GET_EQUIPMENT_TYPES:
      return {
        ...state,
        equipmentTypes: action.payload.customTypes,
      };
    case GET_EQUIPMENT_CUSTOM_DATA: {
      const {
        data: {
          data = [],
          fileMap = {},
        } = {},
      } = action.payload;
      return {
        ...state,
        customData: data,
        customDataFiles: fileMap,
      };
    }
    case GET_EQUIPMENT_CUSTOM_DATA_MAP: {
      const {
        data: {
          customDataMap = {},
        } = {},
      } = action.payload;
      return {
        ...state,
        customDataMap: {
          ...state.customDataMap,
          ...customDataMap,
        },
      };
    }
    case GET_EQUIPMENT_FORM_MAPPINGS: {
      const {
        formMappings,
        typeId,
      } = action.payload;

      return {
        ...state,
        formMappings: {
          ...state.formMappings,
          [typeId]: formMappings,
        },
      };
    }
    case CREATE_EQUIPMENT_FORM_MAPPINGS: {
      const {
        newMapping,
      } = action.payload;

      return {
        ...state,
        formMappings: {
          ...state.formMappings,
          [newMapping.typeId]: [
            ...(state.formMappings[newMapping.typeId] || []),
            newMapping,
          ],
        },
      };
    }
    case UPDATE_EQUIPMENT_FORM_MAPPINGS: {
      const {
        id,
        newData,
      } = action.payload;

      const updatedMappings = (state.formMappings[newData.typeId] || []).map((mapping) => (
        mapping.id === id
          ? { ...newData, id }
          : mapping
      ));

      return {
        ...state,
        formMappings: {
          ...state.formMappings,
          [newData.typeId]: updatedMappings,
        },
      };
    }
    case DELETE_EQUIPMENT_FORM_MAPPINGS: {
      const {
        typeId,
        mappingId,
      } = action.payload;

      const updatedMappings = (state.formMappings[typeId] || [])
        .filter((mapping) => mapping.id !== mappingId);

      return {
        ...state,
        formMappings: {
          ...state.formMappings,
          [typeId]: updatedMappings,
        },
      };
    }
    default:
      return state;
  }
};
