import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { useDrag } from 'react-dnd';

import { setHoverShift } from '../state/schedule.actions';
import { getTextColor, getBackgroundColor, getStatusIcon } from '../scheduleHelpers';

import Permissions from '../../auth/Permissions';
import { getIdMap } from '../../helpers/helpers';

export default function ({
  user,
  shift,
  weekStart,
  day,
  onClick,
  endDate,
}) {
  const dispatch = useDispatch();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'MonthMultiDayEntry',
    item: { id: shift.id, userId: user?.id, shift },
    isDragging: (monitor) => shift?.id === monitor.getItem()?.shift.id,
    canDrag: () => {
      /*
        Dragging is weird for multi day shifts.
        Drag preview only shows for the first day
      */
      const shiftDt = DateTime.fromMillis(shift.startTime);

      return shiftDt.hasSame(day, 'day');
    },
    collect: (monitor) => ({
      isDragging: shift?.id === monitor?.getItem()?.shift.id,
    }),
  }));

  const hoverShift = useSelector((state) => state.schedule.hoverShift);
  const stateUsers = useSelector((state) => state.users.users);
  const handleClick = useCallback((e) => {
    e.stopPropagation();
    onClick(shift);
  }, [shift, onClick]);

  const onMouseEnter = useCallback(() => {
    dispatch(setHoverShift(shift));
  }, [shift, dispatch]);

  const onMouseLeave = useCallback(() => {
    dispatch(setHoverShift({}));
  }, [dispatch]);

  const {
    startTime,
    endTime,
    title,
    users = [],
    color,
    isEvent,
    isDraft,
    appliedUsers,
  } = shift;

  const [currentUserStatus, setCurrentUserStatus] = useState();

  useEffect(() => {
    if (!appliedUsers?.length) return;
    const shiftStatus = appliedUsers.find((u) => u.userId === Permissions.id)?.status;
    setCurrentUserStatus(shiftStatus);
  }, [shift, appliedUsers]);

  const startDay = DateTime.fromMillis(startTime);
  const weekEnd = endDate || weekStart.plus({ days: 7 });

  const isWeekStart = weekStart.hasSame(day, 'day');
  const isStartDay = startDay.hasSame(day, 'day');
  const endDay = DateTime.fromMillis(endTime);

  const timeText = startTime && isStartDay && !startDay.equals(startDay.startOf('day'))
    ? `${startDay.toLocaleString(DateTime.TIME_SIMPLE)}` : '';

  let hasWrap = false; // Does this shift overflow to next week
  let ourEnd = endDay;
  let ourStart = startDay;
  let ourClass = hoverShift.id === shift.id
    ? 'schedule-month-multiday-entry-hover'
    : 'schedule-month-multiday-entry';
  if (endDay > weekEnd) {
    hasWrap = true;
    ourEnd = weekEnd;
    ourClass += ' schedule-month-multiday-flat-right';
  }
  if (startDay < weekStart) {
    ourStart = weekStart;
    ourClass += ' schedule-month-multiday-flat-left';
  }
  const dayDiff = Math.ceil(ourEnd.diff(ourStart).as('days'));
  const offset = hasWrap ? 0 : 8;

  const userMap = useMemo(() => getIdMap(stateUsers), [stateUsers]);
  const isMyShift = useMemo(() => users && users.includes(Permissions.id), [users]);
  const backgroundColor = getBackgroundColor(users, userMap, color, isMyShift, isEvent, isDraft);
  const textColor = getTextColor(backgroundColor);

  if (!isStartDay && !isWeekStart) {
    return (
      <div
        className="schedule-month-multiday-placeholder"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleClick}
        ref={drag}
        style={{ opacity: (isDragging || isDraft) ? 0.5 : 1 }}
      />
    );
  }

  return (
    <div
      className={ourClass}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        width: `calc(${dayDiff * 100}% - ${offset}px)`,
        backgroundColor,
        color: textColor,
        opacity: (isDragging || isDraft) ? 0.5 : 1,
      }}
      ref={drag}
    >
      <span>
        {timeText}
        {' '}
        {title}
        {isDraft ? getStatusIcon(currentUserStatus) : null}
      </span>
    </div>
  );
}
