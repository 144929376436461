import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Table } from 'antd';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import CompanySettingsCard from '../CompanySettingsCard';
import SettingsCardHeader from '../SettingsCardHeader';

import colors from '../../constants/Colors';

import BoardCardDrawer from './BoardCardDrawer';
import BoardCardDeleteModal from './BoardCardDeleteModal';

import { getCustomTables, getStatuses } from '../../forms/state/forms.actions';
import { getCustomFields } from '../../timecards/state/timecards.actions';
import { getProjectCustomFieldTemplate } from '../../projects/state/projects.actions';

const onHeaderCell = () => ({
  style: {
    backgroundColor: 'white',
  },
});

export default function Boards({
  visible,
}) {
  const dispatch = useDispatch();
  const boardCardTemplates = useSelector((state) => state.boards.cardTemplates);

  const [addDrawerOpen, setAddDrawerOpen] = useState(false);
  const [cardToDelete, setCardToDelete] = useState({});
  const [editData, setEditData] = useState({});

  const closeAddDrawer = useCallback(() => {
    setAddDrawerOpen(false);
    setEditData({});
  }, []);
  const openAddDrawer = useCallback(() => setAddDrawerOpen(true), []);
  const closeDeleteModal = useCallback(() => setCardToDelete({}), []);

  const boardCards = useMemo(() => (
    Object.values(boardCardTemplates)
  ), [boardCardTemplates]);

  useEffect(() => {
    if (!visible) setEditData({});
  }, [visible]);

  useEffect(() => {
    dispatch(getCustomTables());
    dispatch(getCustomFields());
    dispatch(getStatuses());
    dispatch(getProjectCustomFieldTemplate());
  }, []);

  const columns = useMemo(() => [{
    title: 'Name',
    dataIndex: 'title',
    onHeaderCell,
  },
  {
    dataIndex: 'edit',
    align: 'edit',
    onHeaderCell,
    width: 60,
    render: (_, record) => {
      const isEdit = record.labelId in editData;
      const icon = isEdit ? 'close' : 'edit';
      return (
        <BorderlessButton
          title=""
          icon={icon}
          color={colors.ONTRACCR_BLACK}
          onClick={() => {
            setEditData(record);
            setAddDrawerOpen(true);
          }}
          style={{
            paddingRight: 8,
            paddingLeft: 0,
          }}
        />
      );
    },
  },
  {
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: 60,
    onHeaderCell,
    render: (_, record) => (
      <BorderlessButton
        title=""
        icon="delete"
        color="red"
        onClick={() => setCardToDelete(record)}
        style={{
          paddingRight: 8,
          paddingLeft: 0,
        }}
      />
    ),
  }], []);

  return (
    <Row
      id="divisions-container"
      style={{
        width: '100%',
        height: '100%',
        marginLeft: 180,
        marginTop: 30,
      }}
    >
      <SettingsCardHeader title="Boards">
        <OnTraccrButton
          title="Add"
          type="primary"
          onClick={openAddDrawer}
        />
      </SettingsCardHeader>
      <CompanySettingsCard
        style={{
          position: 'absolute',
          top: 130,
          bottom: 20,
          padding: 0,
          height: 'auto',
        }}
      >
        <Table
          columns={columns}
          dataSource={boardCards}
          pagination={false}
          size="small"
          scroll={{ y: 'calc(100vh - 220px', x: 'hidden' }}
        />
      </CompanySettingsCard>
      <BoardCardDrawer
        visible={addDrawerOpen}
        onClose={closeAddDrawer}
        editData={editData}
      />
      <BoardCardDeleteModal
        visible={!!cardToDelete.id}
        id={cardToDelete.id}
        title={cardToDelete.title}
        onClose={closeDeleteModal}
      />
    </Row>
  );
}
