import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Progress } from 'antd';
import PropTypes from 'prop-types';

import {
  updateProgressSubContract,
  updateProjectScheduleOfValues,
  updateScheduleOfValuesRow,
  updateProgressSubContractRow,
  editProjectScheduleOfValuesPastBill,
} from '../state/projects.actions';
import colors from '../../constants/Colors';
import {
  getRowValue,
  isInputDirty,
} from './helpers';
import ScheduleOfValuesInput from './ScheduleOfValuesInput';
import { isNullOrUndefined } from '../../helpers/helpers';

const clamp = (x) => Math.max(Math.min(x, 100), -100);

const computeProgress = ({ percentageComplete = 0 }) => (percentageComplete * 100).toFixed(1);

export default function ProgressRow({
  projectId,
  templateId,
  rowId,
  readOnly,
  percentageComplete,
  isProgress,
  formId,
  timestamp,
  submissionId,
}) {
  const dispatch = useDispatch();

  const row = useSelector((state) => getRowValue({
    state,
    projectId,
    templateId,
    rowId,
    formId,
    isProgress,
    timestamp,
    submissionId,
  }));

  const [progress, setProgress] = useState(0);
  const [showPopover, setShowPopover] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setProgress(computeProgress({ percentageComplete }));
  }, [percentageComplete]);

  const onConfirm = useCallback(async () => {
    const payload = {
      itemNumber: row.itemNumber,
      description: row.description,
      contractAmount: row.contractAmount,
      percentageComplete: progress / 100,
      invoiceAmount: row.invoiceAmount,
      progressToDate: row.progressToDate,
      previousBillings: row.previousBillings,
      rowId,
      updatedField: 'percentageComplete',
      isChangeOrder: !!row.isChangeOrder,
      isSubContract: !!row.isSubContract,
      holdbackAmount: row.holdbackAmount,
      excludeHoldback: !!row.excludeHoldback,
      parentRowId: row.parentRowId,
    };

    if (submissionId) {
      payload.submissionId = submissionId;
      payload.oldInvoiceAmount = row.oldInvoiceAmount;
      payload.oldPercentageComplete = row.oldPercentageComplete;
    }

    if (isProgress) payload.formId = row.formId;

    let ourUpdateFunc;

    if (isProgress) {
      ourUpdateFunc = updateProgressSubContract;
    } else if (submissionId) {
      ourUpdateFunc = editProjectScheduleOfValuesPastBill;
    } else {
      ourUpdateFunc = updateProjectScheduleOfValues;
    }

    const result = await dispatch(ourUpdateFunc({
      projectId,
      templateId,
      payload,
    }));

    if (result) {
      setShowPopover(false);
    }
  }, [
    progress,
    dispatch,
    projectId,
    templateId,
    rowId,
    row,
    isProgress,
  ]);

  const onCancel = useCallback(() => {
    // Clear debounced onChange since we're cancelling all changes
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const originalValues = {
      progressToDate: !isNullOrUndefined(row.oldProgressToDate)
        ? row.oldProgressToDate
        : row.progressToDate,
      percentageComplete: !isNullOrUndefined(row.oldPercentageComplete)
        ? row.oldPercentageComplete
        : row.percentageComplete,
      contractAmount: !isNullOrUndefined(row.oldContractAmount)
        ? row.oldContractAmount
        : row.contractAmount,
    };

    if (!isProgress) {
      originalValues.invoiceAmount = !isNullOrUndefined(row.oldInvoiceAmount)
        ? row.oldInvoiceAmount
        : row.invoiceAmount;
    }

    const ourUpdateFunc = isProgress ? updateProgressSubContractRow : updateScheduleOfValuesRow;

    dispatch(ourUpdateFunc({
      projectId,
      templateId,
      row,
      payload: originalValues,
      formId,
    }));
    setShowPopover(false);
  }, [
    row,
    projectId,
    templateId,
    isProgress,
    formId,
  ]);

  const handleValidation = useCallback((value) => {
    const ourUpdateFunc = isProgress ? updateProgressSubContractRow : updateScheduleOfValuesRow;

    const newRow = { ...row };
    if (submissionId) newRow.submissionId = submissionId;

    dispatch(ourUpdateFunc({
      formId,
      projectId,
      templateId,
      row: newRow,
      payload: {
        percentageComplete: value / 100,
      },
    }));

    if (!isInputDirty(row, 'percentageComplete', value)) {
      setShowPopover(false);
    }

    timeoutRef.current = null;
  }, [
    row,
    projectId,
    templateId,
    isProgress,
    formId,
    submissionId,
  ]);

  const onChange = useCallback((newValue) => {
    setShowPopover(true);

    // Refresh debounce period
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Debounce with 250ms period
    const timeout = setTimeout(() => {
      handleValidation(newValue);
    }, 250);

    timeoutRef.current = timeout;
    setProgress(clamp(newValue));
  }, [handleValidation]);

  return (
    <Row style={{ width:'100%' }} align='middle' gutter={10}>
      <Col flex='auto'>
        <Progress
          percent={progress}
          showInfo={false}
          trailColor={colors.BREADCRUMB_BACKGROUND}
          strokeColor={colors.ONTRACCR_RED}
        />
      </Col>
      <Col
        flex={'80px'}
        style={{
          width: 80,
          height: 30,
          alignContent:'center'
        }}
      >
        {!readOnly
          ? <ScheduleOfValuesInput
              title='Update Progress?'
              onConfirm={onConfirm}
              onCancel={onCancel}
              onChange={onChange}
              value={progress}
              showPopover={showPopover}
              formatter={(val) => `${val} %`}
              min={-100}
              max={100}
              type={'number'}
            />
          : <div style={{ marginTop: '4px' }}>{`${progress} %`}</div>
        }
      </Col>
    </Row>
  );
}

ProgressRow.propTypes = {
  isProgress: PropTypes.bool,
  formId: PropTypes.string,
  submissionId: PropTypes.string,
  timestamp: PropTypes.number,
};

ProgressRow.defaultProps = {
  isProgress: false,
  formId: null,
  submissionId: null,
  timestamp: null,
};
