import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { DateTime } from 'luxon';
import titleOnlyConfigure from './titleOnlyConfigure';
import TitleRow from './TitleRow';

import OnTraccrDatePicker from '../../../common/inputs/OnTraccrDatePicker';

import ScheduleDateRepeatPopover from '../../../schedule/ScheduleDateRepeatPopover';
import FieldTriggerFlag from './FieldTriggerFlag';
import { localToZoneTS, zoneToLocalTS } from '../../../clock/ManualEntry/manualEntryHelpers';
import CheckboxRow from './CheckboxRow';

const configure = ({
  id,
  setConfigProps,
  configProps = {},
  setCanSubmit,
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  contactAddressBooks = {},
  labels = [],
  buckets = [],
} = {}) => {
  const titleOnlyConfig = titleOnlyConfigure({
    id,
    setConfigProps,
    configProps,
    setCanSubmit,
    sections,
    isExternalForm,
    templateId,
    divisions,
    projectId,
    name,
    isBoardCards,
    setFieldTriggerEditable,
    disableOptional,
    customers,
    projects,
    users,
    costcodes,
    phases,
    projectIdMap,
    vendors,
    equipment,
    formTemplates,
    contactAddressBooks,
    labels,
    buckets,
  });

  const updateCheckbox = (key) => (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      [key]: checked,
    });
  };

  return (
    <>
      {titleOnlyConfig}
      <CheckboxRow
        title="Single Date"
        checked={configProps.singleDay}
        onChange={updateCheckbox('singleDay')}
        hoverText="Check this box to limit selection to time on a single date"
      />
      <CheckboxRow
        title="Hide All Day Button"
        checked={configProps.hideAllDay}
        onChange={updateCheckbox('hideAllDay')}
        hoverText="Check this box to hide the All Day button from the field"
      />
      <CheckboxRow
        title="Hide Time"
        checked={configProps.hideTime}
        onChange={updateCheckbox('hideTime')}
        hoverText="Check this box to hide time selection"
      />
    </>
  );
};

const preview = ({
  id,
  configProps = {},
  responses = {}, // Actively responding
  previewProps = {}, // Completed form
  setResponses,
  responding = false,
  sections,
  projectId,
  templateId,
  divisions,
  isDisplay,
  onFieldTrigger,
} = {}) => {
  const {
    optional,
    title = 'Title goes here',
    fieldTrigger,
    hideAllDay = false,
    hideTime = false,
    singleDay = false,
  } = configProps;

  const {
    [id]: ourResponses = {},
  } = responses;

  const {
    startTime,
    endTime,
    repeat,
    repeatEndDate,
    timezone = DateTime.local().zoneName,
  } = responding ? ourResponses : previewProps;

  const parsedRepeatEndDate = repeatEndDate
    ? moment(zoneToLocalTS(repeatEndDate, timezone))
    : undefined;

  const updateData = (newData) => {
    if (!setResponses) return;
    const { [id]: oldResponses = {} } = responses;
    setResponses({
      ...responses,
      [id]: {
        ...oldResponses,
        ...newData,
        timezone,
      },
    });
  };

  const onRepeatEndDateChanged = (newRepeatEndDate) => {
    let parsedEndDate = null;
    if (moment.isMoment(newRepeatEndDate)) {
      parsedEndDate = localToZoneTS(newRepeatEndDate.valueOf(), timezone);
    }
    updateData({ repeatEndDate: parsedEndDate });
  };

  return (
    <div style={{ minWidth: 600 }}>
      <TitleRow
        title={title}
        optional={optional}
        filter={
          fieldTrigger && !isDisplay
            ? (
              <FieldTriggerFlag
                sections={sections}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                onFieldTrigger={onFieldTrigger}
              />
            ) : null
        }
      />
      <OnTraccrDatePicker
        onChange={([newStart, newEnd]) => updateData({ startTime: newStart, endTime: newEnd })}
        value={[startTime, endTime]}
        allowClear
        style={{ marginRight: 0 }}
        timezone={timezone}
        hideCheckbox={hideAllDay}
        hideTime={hideTime}
        singleDay={singleDay}
      />
      <ScheduleDateRepeatPopover
        startTime={startTime}
        value={repeat}
        onChange={(newRepeat) => updateData({ repeat: newRepeat })}
        timezone={timezone}
      />
      { repeat && (
        <div>
          <TitleRow title="Repeat End Date" />
          <DatePicker
            format="MMM Do YY"
            placeHolder="Repeat End Date"
            onChange={onRepeatEndDateChanged}
            value={parsedRepeatEndDate}
          />
        </div>
      )}
    </div>
  );
};

export default {
  configure,
  preview,
  title: 'Date/Time Range',
  description: 'This field allows users to pick from a date or time range',
};
