/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Space, Typography } from 'antd';

import TableDatePreload from './TableDatePreload';
import TableProjectPreload from './TableProjectPreload';
import TablePreloadSettings from './TablePreloadSettings';
import TableTimePreload from './TableTimePreload';

const validDataTypes = new Set([
  'TimeEntry',
  'Equipment',
  'Materials',
]);

export function TablePreload({
  configProps,
  updateConfig,
  sections,
  divisions,
  templateId,
}) {
  const {
    dataType,
    preloadExistingEntries = false,
  } = configProps || {};

  if (!validDataTypes.has(dataType)) return null;

  const isTimeEntry = dataType === 'TimeEntry';
  const showCheckbox = !isTimeEntry;

  return (
    <Space direction="vertical" style={{ marginTop: 15 }}>
      {showCheckbox && (
        <Checkbox
          checked={preloadExistingEntries}
          onChange={(e) => updateConfig({ preloadExistingEntries: e.target.checked })}
        >
          Enable Preloading Existing Entries
        </Checkbox>
      )}
      {(!showCheckbox || preloadExistingEntries) && (
        <>
          <Typography.Text strong>PRELOAD SETTINGS</Typography.Text>
          {isTimeEntry && (
            <>
              <TableDatePreload
                configProps={configProps}
                updateConfig={updateConfig}
                sections={sections}
              />
              <TableProjectPreload
                configProps={configProps}
                updateConfig={updateConfig}
                divisions={divisions}
                sections={sections}
              />
              <TableTimePreload
                configProps={configProps}
                updateConfig={updateConfig}
                sections={sections}
              />
            </>
          )}
          <TablePreloadSettings
            configProps={configProps}
            updateConfig={updateConfig}
            sections={sections}
            divisions={divisions}
            dataType={dataType}
            templateId={templateId}
          />
        </>
      )}
    </Space>
  );
}

TablePreload.propTypes = {
  configProps: PropTypes.shape({
    preloadEnabled: PropTypes.bool,
  }).isRequired,
  updateConfig: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
  templateId: PropTypes.string,
};

TablePreload.defaultProps = {
  sections: [],
  templateId: null,
};
