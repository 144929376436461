import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col, Table } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import BorderlessButton from '../../../common/buttons/BorderlessButton';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import { getIdMap } from '../../../helpers/helpers';
import DisplayText from '../../../common/text/DisplayText';

import CustomSavedTableAddDrawer from './CustomSavedTableAddDrawer';
import { generateId } from '../../formHelpers';
import useCheckTableMaxRows from '../../../common/hooks/useCheckTableMaxRows';
import { calculationTableRender } from './formFieldsHelpers';

export default function CustomSavedFormTablePreview({
  columns = [],
  previewProps = {},
  setPreviewProps,
  isDisplay,
  id,
  setResponses,
  responses = {},
  responding = false,
  configProps,
  customTables = {},
  showCondensedView,
}) {
  const {
    dataType,
    requiredColumns,
    hideAddNewButton,
    preventEdits,
  } = configProps;
  const {
    [dataType]: {
      options = [],
      identifier,
    } = {},
  } = customTables;
  const values = previewProps.values || []; // For Responses
  const {
    selected: previewSelected = [],
  } = previewProps;
  const {
    [id]: {
      values: responseSelected = [],
    } = {},
  } = responses;

  const selected = responding ? responseSelected : previewSelected;

  const [showAdd, setShowAdd] = useState(false);

  const showAddDrawer = useCallback(() => setShowAdd(true), []);
  const closeAddDrawer = useCallback(() => setShowAdd(false), []);

  const optionMap = useMemo(() => getIdMap(options), [options]);

  const onAddNew = useCallback(() => {
    const newSelected = selected.concat([{
      id: generateId(),
      isCustom: true,
    }]);
    if (responding) {
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          values: newSelected,
          columns,
        },
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
  }, [responding, selected, previewProps, responses]);

  const onSelect = useCallback((optionIds) => {
    const newSelected = selected.concat(
      optionIds.map((optionId) => {
        const {
          [optionId]: selectedOption = {},
        } = optionMap;
        return {
          ...selectedOption,
          id: `${optionId}.${DateTime.local().toMillis()}`, // unique ID because same row can appear multiple times
        };
      }),
    );
    if (responding) {
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          values: newSelected,
          columns,
        },
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
    setShowAdd(false);
  }, [id, responding, responses, previewProps, selected, optionMap]);

  const onDelete = useCallback((itemId) => {
    const newSelected = selected.filter((item) => item.id !== itemId);
    if (responding) {
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          values: newSelected,
          columns,
        },
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
  }, [id, responses, previewProps, selected]);

  const onValueChanged = useCallback(({ id: itemId, key }) => (e) => {
    const {
      target: {
        value: newValue,
      } = {},
    } = e;
    const newSelected = selected.map((item) => {
      if (item.id !== itemId) return item;
      return {
        ...item,
        [key]: newValue,
      };
    });
    if (responding) {
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          values: newSelected,
          columns,
        },
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
  }, [id, responses, previewProps, selected]);

  const tableColumns = useMemo(() => {
    const colWidth = columns.length > 0
      ? { maxWidth: `${75 / columns.length}vw` }
      : {};
    const cols = columns.map((col) => (
      {
        title: <div className={requiredColumns && 'form-required-field'}>{col.key}</div>,
        dataIndex: col.key,
        render: (value, item) => {
          if (col.isCalculation) {
            return calculationTableRender(col, isDisplay)(value);
          }
          return (
            isDisplay || preventEdits ? (
              <DisplayText
                title={value}
                style={{
                  width: 'fit-content',
                  wordBreak: 'break-word',
                  wordWrap: 'pre-wrap',
                  fontSize: 14,
                  display: 'table-cell',
                  verticalAlign: 'middle',
                  fontFamily: 'roboto-regular',
                  color: 'rbga(0, 0, 0, 0.65)',
                  ...colWidth,
                }}
              />
            ) : (
              <OnTraccrTextInput
                key={col.key + item.id}
                textarea
                value={value}
                onChange={onValueChanged({ id: item.id, key: col.key })}
                autoSize={isDisplay}
              />
            )
          );
        },
      }
    ));
    if (!isDisplay) {
      cols.push({
        title: '',
        dataIndex: '',
        width: 100,
        render: (_, record) => (
          <BorderlessButton
            iconNode={<DeleteOutlined style={{ color: 'red' }} />}
            onClick={() => onDelete(record.id)}
          />
        ),
      });
    }
    return cols;
  }, [isDisplay, columns, onDelete, requiredColumns, preventEdits]);

  const dataSource = useMemo(() => (
    isDisplay && !responding ? values : selected
  ), [isDisplay, responding, values, selected]);

  const {
    maxExistingAllowed,
    shouldAddButtonBeEnabled = true,
  } = useCheckTableMaxRows({
    configProps,
    currentRowsLength: dataSource?.length,
  });

  return (
    <>
      <Row style={{ marginTop: showCondensedView ? 0 : 15 }}>
        {!isDisplay && (
          <Row style={{ marginBottom: 10, width: '100%', marginLeft: 5 }} gutter={20}>
            <Col>
              <OnTraccrButton
                title="Add Option"
                onClick={showAddDrawer}
                icon={<PlusOutlined />}
                disabled={!shouldAddButtonBeEnabled}
              />
            </Col>
            <Col>
              { !hideAddNewButton && (
                <OnTraccrButton
                  title="Add New"
                  onClick={onAddNew}
                  icon={<PlusOutlined />}
                  disabled={!shouldAddButtonBeEnabled}
                />
              )}
            </Col>
          </Row>
        )}
        { !showCondensedView || dataSource?.length ? (
          <Table
            style={{ width: '100%', overflow: 'auto' }}
            columns={tableColumns}
            size="small"
            pagination={false}
            dataSource={dataSource}
            tableLayout="auto"
            rowClassName={() => 'form-saved-table-row'}
          />
        ) : (
          <DisplayText title="No Items Selected" style={{ marginBottom: 0 }} />
        )}
      </Row>
      <CustomSavedTableAddDrawer
        options={options}
        identifier={identifier}
        visible={showAdd}
        onClose={closeAddDrawer}
        onSubmit={onSelect}
        maxExistingAllowed={maxExistingAllowed}
      />
    </>
  );
}

CustomSavedFormTablePreview.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
  })),
  previewProps: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.shape({})),
    selected: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setPreviewProps: PropTypes.func,
  isDisplay: PropTypes.bool,
  id: PropTypes.string,
  setResponses: PropTypes.func,
  responses: PropTypes.shape({}),
  responding: PropTypes.bool,
  configProps: PropTypes.shape({
    dataType: PropTypes.string,
    requiredColumns: PropTypes.bool,
    hideAddNewButton: PropTypes.bool,
  }),
  customTables: PropTypes.shape({}),
  showCondensedView: PropTypes.bool,
};

CustomSavedFormTablePreview.defaultProps = {
  columns: [],
  previewProps: {},
  setPreviewProps: null,
  isDisplay: false,
  id: null,
  setResponses: null,
  responses: {},
  responding: false,
  configProps: null,
  customTables: {},
  showCondensedView: false,
};
