import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getOption } from '../../common/hooks/useFormEmailOptions';
import EmailRecipientSelector from '../../forms/FormWorkflows/selectors/EmailRecipientSelector';
import { sortByLocalCompare } from '../../helpers/helpers';

export default function ExternalRecipientsSelector({
  isDisplay,
  value,
  onChange,
  selectedDivisionId,
  selectedProjectId,
  reminderValue,
}) {
  const { t } = useTranslation();
  const projects = useSelector((state) => state.projects.projects);
  const customers = useSelector((state) => state.customers.customers);

  const options = useMemo(() => {
    const opts = [];

    projects.forEach((project) => {
      if (
        (selectedProjectId && project.id !== selectedProjectId)
        || !project.billingContact
        || !project.active
        || project.divisionId !== selectedDivisionId
      ) {
        return;
      }
      const opt = getOption({
        fieldId: `${project.id}-project`,
        title: `${project.name} - Billing Email`,
        isProject: true,
        isOffice: false,
        t,
        type: 'email',
      });
      opts.push(opt);
    });

    Object.values(customers).forEach((customer) => {
      if (
        !customer.active
        || !customer.divisionIds?.find((divisionId) => divisionId === selectedDivisionId)
      ) {
        return;
      }
      if (customer.officeEmail) {
        const opt1 = getOption({
          fieldId: `${customer.id}-office`,
          title: `${customer.name} - Office Email`,
          isCustomer: true,
          isOffice: true,
          t,
          type: 'email',
        });
        opts.push(opt1);
      }
      if (customer.billingEmail) {
        const opt2 = getOption({
          fieldId: `${customer.id}-billing`,
          title: `${customer.name} - Billing Email`,
          isCustomer: true,
          t,
          type: 'email',
        });
        opts.push(opt2);
      }
    });

    return opts.sort(sortByLocalCompare('title'));
  }, [projects, customers, t, selectedDivisionId, selectedProjectId]);

  return (
    <EmailRecipientSelector
      initialSelected={value}
      onChange={onChange}
      additionalOptions={options}
      hideUsers
      isDisplay={isDisplay}
      text={null}
      placeholder="Recipients"
      disabled={!reminderValue && reminderValue !== 0}
      reminderValue
    />
  );
}

ExternalRecipientsSelector.propTypes = {
  isDisplay: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  selectedDivisionId: PropTypes.string,
  selectedProjectId: PropTypes.string,
  reminderValue: PropTypes.number,
};

ExternalRecipientsSelector.defaultProps = {
  isDisplay: false,
  value: [],
  selectedDivisionId: undefined,
  selectedProjectId: undefined,
  reminderValue: undefined,
};
