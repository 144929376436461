import Permissions from '../../auth/Permissions';
import {
  GET_ALL_TEAMS,
  CREATE_TEAM,
  UPDATE_TEAM,
  ARCHIVE_TEAM,
  DELETE_TEAM,
  TRANSFER_DIVISIONS,
} from '../../state/actionTypes';

import sortByString, { updateState } from '../../helpers/helpers';
import { updateTeam } from '../teams.helpers';

const parseTeam = ({
  name,
  supervisors = [],
  members = [],
  projects = [],
  equipment = [],
  active = 1,
  id,
}) => ({
  id,
  name,
  members: supervisors.map((userId) => ({
    id: userId,
    teamRole: 'Supervisor',
  })).concat(members.map((userId) => ({
    id: userId,
    teamRole: 'Worker',
  }))),
  projects,
  equipment,
  active,
});

const initialState = {
  teams: [],
};

export default function teamsActions(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_TEAMS: {
      let teams = action.payload.teams || [];
      if (!Permissions.has('TEAMS_WRITE') && Permissions.has('TEAMS_WRITE_ASSIGNED')) {
        teams = teams.filter((team) => team.members.some((member) => member.id === Permissions.id && member.teamRole === 'Supervisor'));
      }
      return {
        ...state,
        teams,
      };
    }
    case CREATE_TEAM:
      return {
        ...state,
        teams: state.teams.concat([parseTeam(action.payload.team)]).sort(sortByString('name')),
      };
    case UPDATE_TEAM: {
      return {
        ...state,
        teams: state.teams.map((team) => {
          if (team.id !== action.payload.id) return team;
          return updateTeam(team, action.payload.team);
        }).sort(sortByString('name')),
      };
    }
    case ARCHIVE_TEAM: {
      const {
        payload: {
          active,
          id,
        },
      } = action;
      return {
        ...state,
        teams: updateState(state.teams, { id, active: active ? 1 : 0 }),
      };
    }
    case DELETE_TEAM: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      return {
        ...state,
        teams: state.teams.filter((team) => team.id !== id),
      };
    }
    case TRANSFER_DIVISIONS: {
      const {
        payload: {
          type,
          oldDivisionId,
          divisionId,
          keepOldDivision,
          ids = [],
        },
      } = action;
      const {
        teams: stateTeams = [],
      } = state;
      if (type !== 'teams') return state;
      const idSet = new Set(ids);

      const newTeams = stateTeams.map((team) => {
        const newTeam = { ...team };
        if (!idSet.has(newTeam.id)) return team;
        const safeDivisions = newTeam?.divisionIds?.filter((id) => id !== divisionId) ?? [];
        safeDivisions.push(divisionId);
        newTeam.divisionIds = keepOldDivision
          ? safeDivisions
          : safeDivisions.filter((id) => id !== oldDivisionId);

        return newTeam;
      });
      return {
        ...state,
        teams: newTeams,
      };
    }
    default:
      return state;
  }
}
