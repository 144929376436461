import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  getClockRows,
} from './timeTrackingConstants';

import CompanySettingsCard from '../CompanySettingsCard';
import CheckRows from '../CheckRows';
import SettingsService from '../state/settings.service';
import TimeTrackingSelect from './TimeTrackingSelect';

const MINUTE_INCREMENT_OPTIONS = [
  {
    label: '1 minute',
    value: 1,
  },
  {
    label: '15 minutes',
    value: 15,
  },
  {
    label: '30 minutes',
    value: 30,
  },
];

const renderGetView = ({
  settings,
  saveCompanySettings,
  clockRows,
}) => function innerRender(index) {
  if (index !== clockRows.length - 1) {
    return null;
  }

  return (
    <TimeTrackingSelect
      data={MINUTE_INCREMENT_OPTIONS}
      defaultValue={settings.minuteIncrement || MINUTE_INCREMENT_OPTIONS[0].value}
      onChange={(minuteIncrement) => saveCompanySettings({
        minuteIncrement,
      })}
      disabled={!settings.enableManualEntry}
      format={(value) => value.label}
      getValue={(value) => value.value}
    />
  );
};

export default function ClockSettings({ settings, saveCompanySettings }) {
  const { t } = useTranslation();
  const clockRows = getClockRows(t);

  const handleInteractions = async (item) => {
    const { key } = item;
    const isEnabled = settings[key];

    switch (key) {
      case 'enableClocking': {
        if (isEnabled) {
          saveCompanySettings({ enableBreaking: false });
          await SettingsService.clockOutAll();
        }
        break;
      }
      case 'enableBreaking': {
        if (isEnabled) await SettingsService.endBreakAll();
        break;
      }
      default:
    }
  };

  return (
    <CompanySettingsCard title="Clock Settings">
      <CheckRows
        data={clockRows}
        onChange={async (item) => {
          await handleInteractions(item);
          saveCompanySettings({ [item.key]: !settings[item.key] });
        }}
        isChecked={(item) => settings[item.key]}
        getView={renderGetView({ settings, saveCompanySettings, clockRows })}
        checkBoxDisabled={(item) => item.key === 'enableBreaking' && !settings.enableClocking}
      />
    </CompanySettingsCard>
  );
}

ClockSettings.propTypes = {
  settings: PropTypes.shape({
    enableClocking: PropTypes.bool,
    enableBreaking: PropTypes.bool,
    enableManualEntry: PropTypes.bool,
    minuteIncrement: PropTypes.number,
  }).isRequired,
  saveCompanySettings: PropTypes.func.isRequired,
};
