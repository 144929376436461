/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  FilePdfOutlined,
  HistoryOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import moment from 'moment';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import ProgressRow from './ProgressRow';
import { percentageFormatter } from '../../helpers/helpers';
import ScheduleOfValuesInputField from './ScheduleOfValuesInputField';
import { currencyFormatter } from '../../helpers/inputParsers';

import { getNumberText } from './helpers';

import colors from '../../constants/Colors';

const getScheduleOfValuesInputField = ({
  record = {},
  readOnly,
  field,
  title,
  text,
  additionalProps = {},
} = {}) => (
  !readOnly
    ? (
      <ScheduleOfValuesInputField
        {...record}
        field={field}
        type="number"
        formatter={currencyFormatter}
        title={title}
        min={Number.MIN_SAFE_INTEGER}
        {...additionalProps}
      />
    ) : <div>{currencyFormatter(Number(text).toFixed(2))}</div>
);

const getLiveSummaryColumns = ({
  readOnly = false,
  onClick,
  showHoldback = false,
  isScheduleOfValueLocked = false,
  isPastBill = false,
}) => {
  const columns = [
    {
      title: 'Contract Total',
      dataIndex: 'totalContractValue',
      width: 120,
      render: (text) => (
        <div>{currencyFormatter(Number(text).toFixed(2))}</div>
      ),
    },
    {
      title: 'Total Base Contract',
      dataIndex: 'totalContractAmount',
      width: 140,
      render: (text, record) => (
        getScheduleOfValuesInputField({
          readOnly: readOnly || isScheduleOfValueLocked || isPastBill,
          record,
          text,
          field: 'totalContractAmount',
          title: 'Total Base Contract Value',
        })
      ),
    },
    {
      title: 'Total Changes',
      dataIndex: 'totalChanges',
      width: 120,
      render: (text) => (
        <div>{currencyFormatter(Number(text).toFixed(2))}</div>
      ),
    },
  ];

  if (showHoldback) {
    columns.push(
      {
        title: 'Total Non-Holdback Items',
        dataIndex: 'totalNonHoldbackAmount',
        width: 120,
        render: (text) => (
          <div>{currencyFormatter(Number(text).toFixed(2))}</div>
        ),
      },
    );
  }

  columns.push(
    {
      title: 'Total Billed To Date',
      dataIndex: 'totalBilledToDate',
      width: 120,
      render: (text) => (
        <div>{currencyFormatter(Number(text).toFixed(2))}</div>
      ),
    },
    {
      title: 'Current Base Contract',
      dataIndex: 'currentContractValue',
      width: 120,
      render: (text) => (
        <div>{currencyFormatter(Number(text).toFixed(2))}</div>
      ),
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'invoiceAmount',
      width: 120,
      render: (text) => (
        <div>{currencyFormatter(Number(text).toFixed(2))}</div>
      ),
    },
  );

  if (showHoldback) {
    columns.push(
      {
        title: 'Total Holdback to Date',
        dataIndex: 'holdbackAmount',
        width: 140,
        render: (text) => (
          <div>{currencyFormatter(Number(text).toFixed(2))}</div>
        ),
      },
      {
        title: 'Holdback Percentage',
        dataIndex: 'holdbackPercentage',
        width: 120,
        render: (text, record) => (
          (!readOnly && !isScheduleOfValueLocked && !isPastBill)
            ? (
              <ScheduleOfValuesInputField
                {...record}
                field="holdbackPercentage"
                type="number"
                subType="percentage"
                formatter={(val) => `${val} %`}
                title="Holdback Percentage"
                min={0}
                max={100}
              />
            ) : <div>{percentageFormatter(text)}</div>
        ),
      },
    );
  }

  if (!isPastBill && !readOnly) {
    columns.push({
      title: '',
      dataIndex: 'submit',
      align: 'right',
      width: 40,
      render: () => (
        <OnTraccrButton
          title="Submit"
          onClick={onClick}
        />
      ),
    });
  }

  return columns;
};

const getLogColumns = ({
  onPdf,
  onEdit,
  onHistory,
}) => [
  {
    title: 'Date',
    dataIndex: 'timestamp',
    width: 120,
    render: (text) => (
      <div>{moment(text).format('MM/DD/YYYY hh:mm A')}</div>
    ),
  },
  {
    title: 'Invoice Amount',
    dataIndex: 'invoiceAmount',
    width: 120,
    render: (text) => (
      <div>{currencyFormatter(Number(text).toFixed(2))}</div>
    ),
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    width: 300,
    render: (text) => (
      <div>{text}</div>
    ),
  },
  {
    dataIndex: 'history',
    width: 20,
    render: (_, record) => (
      <BorderlessButton
        style={{ padding: 0, width: 10 }}
        iconNode={<HistoryOutlined style={{ marginLeft: 0 }} />}
        onClick={() => onHistory(record)}
      />
    ),
  },
  {
    dataIndex: 'pdf',
    width: 20,
    render: (_, record) => (
      <BorderlessButton
        style={{ padding: 0, width: 10 }}
        iconNode={<FilePdfOutlined style={{ marginLeft: 0 }} />}
        onClick={() => onPdf(record)}
      />
    ),
  },
  {
    dataIndex: 'edit',
    width: 20,
    render: (_, record) => (
      <BorderlessButton
        style={{ padding: 0, width: 10 }}
        iconNode={<EyeOutlined style={{ marginLeft: 0 }} />}
        onClick={() => onEdit(record)}
      />
    ),
  },
];

const getScheduleOfValueColumns = ({
  onHistory,
  onDelete,
  onInfo,
  readOnly = false,
  isSubContract = false,
  liveSummaryValues = [],
  isScheduleOfValueLocked = false,
  hideButtons = false,
  isProgress = false,
  isChanges = false,
  isPastBill = false,
}) => {
  const cols = [
    {
      title: 'Item #',
      dataIndex: 'itemNumber',
      width: 10,
      render: (_, record) => (
        <div style={{
          textAlign: 'left',
          width: '100%',
        }}
        >
          {getNumberText(record)}
        </div>
      ),
    },
    ...isChanges
      ? [{
        title: 'Number',
        dataIndex: 'number',
        width: 100,
        render: (number, record) => (
          !readOnly && !isScheduleOfValueLocked
            ? (
              <ScheduleOfValuesInputField
                {...record}
                field="number"
                type="text"
                title="Number"
                placeholder=""
              />
            ) : <div>{number}</div>
        ),
      }] : [],
    {
      title: 'Description',
      dataIndex: 'description',
      width: 200,
      render: (text, record) => (
        (!readOnly && !isScheduleOfValueLocked && !isPastBill)
          ? (
            <ScheduleOfValuesInputField
              {...record}
              field="description"
              type="text"
              title="Description"
              isProgress={isProgress}
            />
          ) : <div>{text}</div>
      ),
    },
    ...(isProgress && isSubContract)
      ? [{
        title: 'Contract Total',
        dataIndex: 'contractTotal',
        width: 140,
        render: (text) => (
          <div>{currencyFormatter(Number(text).toFixed(2))}</div>
        ),
      }] : [],
    {
      title: 'Contract Amount',
      dataIndex: 'contractAmount',
      width: 140,
      render: (text, record) => (
        getScheduleOfValuesInputField({
          readOnly: readOnly || isScheduleOfValueLocked || isPastBill,
          record,
          text,
          field: 'contractAmount',
          title: 'Contract Amount',
          additionalProps: { liveSummaryValues, isProgress },
        })
      ),
    },
    ...isSubContract
      ? [{
        title: 'Total Changes',
        dataIndex: 'totalChanges',
        width: 100,
        render: (text) => (
          <div>{currencyFormatter(Number(text).toFixed(2))}</div>
        ),
      }]
      : [],
    {
      title: '% Complete',
      dataIndex: 'percentComplete',
      width: 140,
      render: (_, record) => (
        <ProgressRow
          {...record}
          readOnly={readOnly}
          isProgress={isProgress}
        />
      ),
    },
    {
      title: 'Progress To Date',
      dataIndex: 'progressToDate',
      width: 100,
      render: (text) => (
        <div>{currencyFormatter(Number(text).toFixed(2))}</div>
      ),
    },
  ];

  if (!isProgress) {
    cols.push(
      {
        title: 'Previous Billings',
        dataIndex: 'previousBillings',
        width: 100,
        render: (text) => (
          <div>{currencyFormatter(Number(text).toFixed(2))}</div>
        ),
      },
      {
        title: 'Invoice',
        dataIndex: 'invoiceAmount',
        width: 140,
        render: (text, record) => (
          !readOnly
            ? (
              <ScheduleOfValuesInputField
                {...record}
                field="invoiceAmount"
                type="number"
                formatter={currencyFormatter}
                min={Number.MIN_SAFE_INTEGER}
                title="Invoice Amount"
              />
            ) : <div>{currencyFormatter(Number(text).toFixed(2))}</div>
        ),
      },
    );
  }

  if (!hideButtons) {
    cols.push({
      title: 'History',
      dataIndex: 'history',
      key: 'history',
      align: 'center',
      width: 10,
      render: (_, record) => (
        <BorderlessButton
          style={{ padding: 0, width: 10 }}
          iconNode={<HistoryOutlined style={{ marginLeft: 0 }} />}
          onClick={() => onHistory(record)}
        />
      ),
    });

    if (isSubContract) {
      cols.push({
        title: 'Info',
        dataIndex: 'info',
        key: 'info',
        align: 'center',
        width: 10,
        render: (_, record) => (
          <BorderlessButton
            style={{ padding: 0, width: 10 }}
            iconNode={<InfoCircleOutlined style={{ marginLeft: 0 }} />}
            onClick={() => onInfo(record)}
          />
        ),
      });
    }

    if (!readOnly && !isScheduleOfValueLocked) {
      cols.push({
        title: '',
        dataIndex: 'delete',
        key: 'delete',
        align: 'right',
        width: 10,
        render: (_, record) => (
          <BorderlessButton
            style={{ padding: 0, width: 10 }}
            iconNode={<DeleteOutlined style={{ marginLeft: 0, color: colors.ONTRACCR_RED }} />}
            onClick={() => onDelete(record)}
          />
        ),
      });
    }
  }

  return cols;
};

export {
  getLiveSummaryColumns,
  getLogColumns,
  getScheduleOfValueColumns,
};
