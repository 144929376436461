import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, Empty, message } from 'antd';
import PropTypes from 'prop-types';

import { getRow, getHistoryEntry } from '../helpers';
import HistoryEntry from '../../history/HistoryEntry';
import { getIdMap } from '../../../helpers/helpers';
import { updateProgressSubContract, updateProjectScheduleOfValues } from '../../state/projects.actions';
import { downloadFile } from '../../../files/fileHelpers';
import FullPhoto from '../../../common/photos/FullPhoto';
import HistoryDrawerHeader from '../../history/HistoryDrawerHeader';

export default function HistoryDrawer({
  visible,
  onClose,
  record: {
    projectId,
    templateId,
    rowId,
    description,
    id,
  } = {},
  isReadOnly,
  files,
  onPreviewClick,
  removeFile,
  addFile,
  setFiles,
  selectedFile,
  clearSelectedFile,
  updateFile,
  isProgress,
  formId,
}) {
  const dispatch = useDispatch();

  const isPastBill = rowId === 'log';

  const [notesValue, setNotesValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);

  const history = useSelector((state) => getRow({
    state,
    projectId,
    templateId,
    rowId,
    isProgress,
    formId,
  }));

  const filteredHistory = useMemo(() => {
    if (!isPastBill) return history.filter((entry) => !entry.isUncommitedRow);

    const pastBillHistory = history.find((entry) => entry.id === id);
    return pastBillHistory?.billEdits ?? [];
  }, [history, isPastBill, id]);

  const { projectName = '' } = useSelector((state) => {
    const currentProject = state.projects.projects.find((project) => project.id === projectId);
    return {
      projectName: currentProject && currentProject.name,
    };
  });

  const toggleFileUpload = () => setShowFileUpload(!showFileUpload);

  const users = useSelector((state) => state.users.users);
  const userMap = useMemo(() => getIdMap(users), [users]);

  const onSubmit = useCallback(async () => {
    if (isLoading) {
      return;
    }

    if ((notesValue === '' || notesValue === null) && files.length === 0) {
      message.error('Please enter a note or add a file.');
      return;
    }

    setIsLoading(true);
    const latestRow = filteredHistory[0];
    const update = {
      itemNumber: latestRow.itemNumber,
      description: latestRow.description,
      contractAmount: latestRow.contractAmount,
      percentageComplete: latestRow.percentageComplete,
      invoiceAmount: latestRow.invoiceAmount,
      progressToDate: latestRow.progressToDate,
      previousBillings: latestRow.previousBillings,
      rowId,
      updatedField: 'notes',
      isChangeOrder: !!latestRow.isChangeOrder,
      isSubContract: !!latestRow.isSubContract,
      notes: notesValue,
      holdbackAmount: latestRow.holdbackAmount,
      excludeHoldback: !!latestRow.excludeHoldback,
      parentRowId: latestRow.parentRowId,
    };

    if (isProgress) update.formId = latestRow.formId;

    const ourUpdateFunc = isProgress ? updateProgressSubContract : updateProjectScheduleOfValues;

    const result = await dispatch(ourUpdateFunc({
      projectId,
      templateId,
      payload: update,
      projectName,
      files,
    }));
    if (result) {
      setNotesValue('');
      setFiles([]);
    }

    setIsLoading(false);
  }, [notesValue, projectId, templateId, filteredHistory, isLoading, files]);

  return (
    <Drawer
      title={`History: ${description}`}
      visible={visible}
      onClose={onClose}
      width={650}
      push={false}
    >
      {!isReadOnly && !isPastBill && (
        <HistoryDrawerHeader
          onSubmit={onSubmit}
          isLoading={isLoading}
          notesValue={notesValue}
          setNotesValue={setNotesValue}
          toggleFileUpload={toggleFileUpload}
          files={files}
          removeFile={removeFile}
          onPreviewClick={onPreviewClick}
          showFileUpload={showFileUpload}
          addFile={addFile}
        />
      )}
      <div style={{ overflowY: 'auto' }}>
        {filteredHistory.length > 0 && filteredHistory.map((entry, index) => {
          const { id, updatedByUserId } = entry;
          const {
            [updatedByUserId]: user = {},
          } = userMap;
          return (
            <HistoryEntry
              {...entry}
              {...getHistoryEntry(entry)}
              isFirstEntry={(index === 0) && !isPastBill}
              key={id}
              user={user}
              isSov
            />
          );
        })}
        {filteredHistory.length === 0 && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
      <FullPhoto
        file={selectedFile.file}
        type={selectedFile.type}
        onClose={clearSelectedFile}
        onDownload={() => {
          downloadFile({ fileObject: selectedFile.file});
        }}
        onSave={updateFile}
        useApryse
      />
    </Drawer>
  );
}

HistoryDrawer.propTypes = {
  formId: PropTypes.string,
  isProgress: PropTypes.bool,
};

HistoryDrawer.defaultProps = {
  formId: null,
  isProgress: false,
};
