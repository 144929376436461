import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Typography,
  Card,
  Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';

import DateTimeWithZone from '../../common/text/DateTimeWithZone';
import Timer from '../../common/clock/Timer';

import TaskNote from './TaskNote';

import colors from '../../constants/Colors';

import { getIdMap } from '../../helpers/helpers';
import {
  msToString,
} from '../timecard.helpers';

const OPEN_HEIGHT = 150;
const CLOSED_HEIGHT = 67;
const NOTE_ICON_HEIGHT = 20;
const EMPTY_TEXT = '--';

const { Text } = Typography;
const titleStyle = {
  fontFamily: 'roboto-regular',
  fontSize: 8,
  color: colors.MODAL_COLOR,
  width: '100%',
};

const breakStyle = {
  fontSize: 12,
  width: '100%',
  fontFamily: 'raleway-semibold',
  color: colors.ONTRACCR_TITLE_BLACK,
};

const valueStyle = {
  fontFamily: 'raleway-semibold',
  fontSize: 10,
  color: colors.MODAL_COLOR,
  width: '100%',
};

const getBorder = (state, isPending) => {
  let border = '1px solid black';
  if (isPending) {
    border = '2px solid orange';
  } else if (state === 'submitted') {
    border = '2px solid yellow';
  } else if (state === 'approved') {
    border = '2px solid green';
  }
  return border;
};

const getBackgroundColor = (endTime, isBreak) => {
  let backgroundColor;
  if (isBreak) {
    backgroundColor = colors.ONTRACCR_DARK_YELLOW;
  } else {
    backgroundColor = endTime
      ? colors.ONTRACCR_OPACITY_GRAY
      : colors.ONTRACCR_TITLE_BLACK;
  }
  return backgroundColor;
};

const getProjectText = ({
  t,
  task,
  projectMap = {},
}) => {
  const {
    projectId,
    serviceLocation,
  } = task ?? {};
  if (serviceLocation) {
    // Task is a service task
    return {
      header: 'Service Location',
      text: serviceLocation,
    };
  }
  return {
    header: t('Project'),
    text: projectMap[projectId]?.name ?? EMPTY_TEXT,
  };
};
function WorkSlot({
  task,
  drawer,
  noteOpen,
  onNoteClick,
}) {
  const { t } = useTranslation();
  const {
    serviceLocation,
    costcodeId,
    classId,
    hourBased,
    note,
    type,
    state: taskState,
    timezone,
    endTime: regularEndTime,
    breakEndTime,
    otEndTime,
    doubleOTEndTime,
    ptoEndTime,
    isPending,
  } = task;
  const isOvertime = type === 'overtime';

  const projects = useSelector((state) => state.projects.projects);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const unionClasses = useSelector((state) => state.unions.classes);
  const {
    settings: {
      enableHourBasedTracking = false,
      showOriginalTimes = false,
    } = {},
  } = useSelector((state) => state.settings.company || {});

  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const costcodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const costcodeText = useMemo(() => {
    const {
      [costcodeId]: {
        code = EMPTY_TEXT,
      } = {},
    } = costcodeMap;
    return code;
  }, [costcodeId, costcodeMap]);

  const ourUnionClass = useMemo(() => (
    unionClasses.find((unionClass) => unionClass.id === classId)
  ), [unionClasses, classId]);

  const cardHeight = useMemo(() => {
    const h = noteOpen ? OPEN_HEIGHT : CLOSED_HEIGHT;
    return drawer && note ? h + NOTE_ICON_HEIGHT : h;
  }, [noteOpen, drawer, note]);

  const taskEndTime = useMemo(() => (
    TaskHelpers.getEndTime({
      ...task,
      endTime: showOriginalTimes && task.originalEnd ? task.originalEnd : task.endTime,
    })
  ), [task, showOriginalTimes]);

  const startTime = useMemo(() => (
    TaskHelpers.getStartTime({
      ...task,
      startTime: showOriginalTimes && task.originalStart ? task.originalStart : task.startTime,
    })
  ), [task, showOriginalTimes]);

  const endTime = task.endTime
    || task.otEndTime
    || task.doubleOTEndTime
    || task.breakEndTime
    || DateTime.local().toMillis();
  const isBreak = type === 'break' || (breakEndTime && !otEndTime && !doubleOTEndTime && !regularEndTime && !ptoEndTime);

  const {
    header: projectHeader,
    text: projectText,
  } = getProjectText({
    t, task, projectMap,
  });

  const runtimes = TaskHelpers.getRuntimes({
    ...task,
    endTime,
  }, false);
  const runtime = msToString(
    runtimes.regularTime
    + runtimes.overtime
    + runtimes.doubleOT
    + (isBreak ? runtimes.breakTime : 0)
    + runtimes.pto,
  );

  const colSpans = useMemo(() => {
    const spans = [0, 0, 0];
    let totalWidth = 24;

    if (runtimes.pto || (task?.ptoStartTime && task?.ptoEndTime)) {
      totalWidth -= 2;
      spans[1] = 2;
    }

    spans[0] = serviceLocation ? totalWidth : totalWidth / 2;
    spans[2] = serviceLocation ? 0 : totalWidth / 2;
    return spans;
  }, [serviceLocation, runtimes]);

  return (
    <Card
      hoverable={note}
      bodyStyle={{
        padding: isBreak ? 10 : 2,
      }}
      onClick={() => {
        if (drawer && note) onNoteClick(task.id);
      }}
      style={{
        borderRadius: 5,
        backgroundColor: getBackgroundColor(taskEndTime, isBreak),
        padding: '1px 1px 1px 1px',
        color: isBreak ? colors.ONTRACCR_TITLE_BLACK : 'white',
        border: getBorder(taskState, isPending),
        height: cardHeight,
        transition: 'height 0.5s',
      }}
    >
      {
        isBreak
          ? (
            <Row justify="space-between">
              <Col span={12}>
                <Row style={{ width: '100%', lineHeight: '10px' }}>
                  <Text style={breakStyle}>
                    Breaks
                  </Text>
                </Row>
              </Col>
            </Row>
          )
          : (
            <Row justify="space-between">
              <Col span={colSpans[0]}>
                <Row style={{ width: '100%', lineHeight: '10px' }}>
                  <Text style={titleStyle}>
                    {projectHeader}
                  </Text>
                </Row>
                <Row style={{ width: '100%', lineHeight: '10px' }}>
                  <Text ellipsis style={valueStyle}>
                    {projectText}
                  </Text>
                </Row>
              </Col>
              {!!(runtimes.pto || (task?.ptoStartTime && task?.ptoEndTime)) && (
                <Col span={colSpans[1]}>
                  <Tooltip title="This timecard contains PTO">
                    <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="5" cy="5" r="5" fill="blue" />
                    </svg>
                  </Tooltip>
                </Col>
              )}
              <Col span={colSpans[2]} style={{ textAlign: 'right' }}>
                <Row style={{ width: '100%', lineHeight: '10px' }}>
                  <Text style={titleStyle}>
                    Cost Code
                  </Text>
                </Row>
                <Row style={{ width: '100%', lineHeight: '10px' }}>
                  <Text style={valueStyle}>
                    {costcodeText}
                  </Text>
                </Row>
              </Col>
            </Row>
          )
      }
      {
        isOvertime
        && (
          <Row
            style={{
              position: 'absolute',
              left: 0,
              top: 3,
              right: 0,
              lineHeight: '10px',
              fontSize: 8,
              fontFamily: 'roboto-regular',
            }}
            justify="center"
          >
            OT
          </Row>
        )
      }
      <Row justify="space-around" type="flex">
        <Timer
          key={task.id + runtime}
          startTime={startTime}
          endTime={taskEndTime}
          clockTime={runtime}
          id={task.id}
          style={{
            fontSize: 14,
            width: '100%',
            textAlign: 'center',
            fontFamily: 'raleway-semibold',
            color: isBreak ? colors.ONTRACCR_TITLE_BLACK : colors.MODAL_COLOR,
          }}
        />
      </Row>
      {
        !isBreak
        && (
          <Row justify="space-between" style={{ margin: 5 }}>
            <Col span={ourUnionClass ? 8 : 12}>
              <Row style={{ width: '100%', lineHeight: '10px' }}>
                {hourBased && enableHourBasedTracking
                  ? null
                  : (
                    <DateTimeWithZone
                      startTime={startTime}
                      timezone={timezone}
                      hideSuffix
                      style={titleStyle}
                    />
                  )}
              </Row>
            </Col>
            {
              ourUnionClass
                ? (
                  <Col span={8} style={{ textAlign: 'center' }}>
                    <Row style={{ width: '100%', lineHeight: '10px' }}>
                      <Text style={titleStyle}>
                        {ourUnionClass.name}
                      </Text>
                    </Row>
                  </Col>
                )
                : null
            }
            <Col span={ourUnionClass ? 8 : 12} style={{ textAlign: 'right' }}>
              <Row style={{ width: '100%', lineHeight: '10px' }}>
                {
                  hourBased && enableHourBasedTracking
                    ? null
                    : (
                      <DateTimeWithZone
                        startTime={taskEndTime}
                        timezone={timezone}
                        hideSuffix
                        style={titleStyle}
                      />
                    )
                }
              </Row>
            </Col>
          </Row>
        )
      }
      {note && drawer
        ? (
          <Row justify="center" style={{ width: '100%' }}>
            {noteOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </Row>
        ) : null}
      {drawer
        && (
          <TaskNote
            note={note}
            noteOpen={noteOpen}
            style={{
              marginTop: 5,
              height: noteOpen ? 70 : 0,
              transition: 'height 0.5s',
              overflowY: 'scroll',
            }}
          />
        )}
    </Card>
  );
}

WorkSlot.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string,
    startTime: PropTypes.number,
    originalStart: PropTypes.number,
    endTime: PropTypes.number,
    originalEnd: PropTypes.number,
    timezone: PropTypes.string,
    note: PropTypes.string,
    serviceLocation: PropTypes.string,
    costcodeId: PropTypes.string,
    projectId: PropTypes.string,
    classId: PropTypes.string,
    hourBased: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]),
    type: PropTypes.string,
    state: PropTypes.string,
    breakEndTime: PropTypes.number,
    otEndTime: PropTypes.number,
    doubleOTEndTime: PropTypes.number,
    isPending: PropTypes.bool,
    ptoStartTime: PropTypes.number,
    ptoEndTime: PropTypes.number,
  }),
  drawer: PropTypes.bool,
  noteOpen: PropTypes.bool,
  onNoteClick: PropTypes.func,
};

WorkSlot.defaultProps = {
  task: {},
  drawer: false,
  noteOpen: false,
  onNoteClick: () => { },
};

export default WorkSlot;
