import React, {
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import {
  Form,
  Select,
  Row,
  Drawer,
} from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { filterSelectDropdown } from '../helpers/helpers';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import { getProjectOptions } from '../clock/ManualEntry/manualEntryHelpers';

import { filterData, getDefaultFilters, NONE_EQUIPMENT_TYPE } from './equipmentFilters';

export default function EquipmentFilterDrawer({
  visible,
  onClose,
  currentFilters = {},
  onFilterApply,
}) {
  const [form] = Form.useForm();

  const { settings = {} } = useSelector((state) => state.settings.company) ?? {};
  const projects = useSelector((state) => state.projects.projects);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const equipmentTypes = useSelector((state) => state.equipment.equipmentTypes);
  const statuses = useSelector((state) => state.equipment.statuses);
  const labels = useSelector((state) => state.labels);

  const ourLabels = useMemo(() => labels.filter((label) => label.type === 'equipment'), [labels]);
  const equipmentTypesWithNone = useMemo(() => [
    {
      id: NONE_EQUIPMENT_TYPE,
      name: 'None',
    },
    ...equipmentTypes.filter((eq) => !eq.isDefault),
  ], [equipmentTypes]);

  const ourFilterData = useMemo(() => (
    filterData({
      labels: ourLabels,
      statuses,
      equipmentTypes: equipmentTypesWithNone,
    })
  ), [ourLabels, statuses, equipmentTypesWithNone]);

  // ----------------
  // Dropdown Options
  // ----------------

  const projectOptions = useMemo(() => {
    const activeProjects = projects.filter(({ active }) => active);

    return getProjectOptions({
      activeProjects,
      divisionIds: selectedDivisions,
      settings,
    });
  }, [projects, selectedDivisions]);

  const resetFields = () => {
    form?.setFieldsValue(getDefaultFilters({
      labels: ourLabels,
      statuses,
      equipmentTypes: equipmentTypesWithNone,
    }));
  };

  useEffect(() => {
    if (form) {
      form.setFieldsValue(currentFilters);
    }

    return () => {
      resetFields();
    };
  }, [currentFilters]);

  const onApply = useCallback(() => {
    const values = form.getFieldsValue();

    onFilterApply(values);
  }, [onFilterApply]);

  const onCancel = useCallback(() => {
    form?.setFieldsValue(currentFilters);
    onClose();
  }, [currentFilters]);

  return (
    <Drawer
      title="Filters"
      visible={visible}
      onClose={onClose}
      width={900}
      destroyOnClose
    >
      <div>
        <Row>
          <h3>Equipment Filter</h3>
          <OnTraccrButton
            onClick={resetFields}
            style={{ marginLeft: 'auto' }}
            title="Reset"
          />
        </Row>
        <Form
          form={form}
          layout="vertical"
        >

          {
            ourFilterData.map((filterRow) => (
              <Form.Item
                name={filterRow.key}
                label={filterRow.label}
              >
                <Select
                  allowClear
                  options={filterRow.options}
                  placeholder={`Select ${filterRow.label}`}
                  mode={filterRow.mode ?? 'multiple'}
                  filterOption={filterSelectDropdown}
                />
              </Form.Item>
            ))
          }
          <Form.Item
            name="projects"
            label="Projects"
          >
            <Select
              allowClear
              options={projectOptions}
              placeholder="Select Projects"
              mode="multiple"
              filterOption={filterSelectDropdown}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="drawer-footer">
        <Row justify="end" gutter={10}>
          <div style={{ marginRight: 'auto' }}>
            <OnTraccrButton
              title="Cancel"
              type="cancel"
              onClick={onCancel}
            />
          </div>
          <OnTraccrButton
            title="Apply"
            onClick={onApply}
          />
        </Row>
      </div>
    </Drawer>
  );
}

EquipmentFilterDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  currentFilters: PropTypes.shape({
    projects: PropTypes.arrayOf(PropTypes.string),
  }),
  onFilterApply: PropTypes.func.isRequired,
};

EquipmentFilterDrawer.defaultProps = {
  visible: false,
  currentFilters: {},
};
