import { Collapse, Table } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DraggableTable from './DraggableTable';
import HistoryDrawer from './History/HistoryDrawer';
import { getLiveSummaryColumns, getScheduleOfValueColumns } from './ScheduleOfValuesColumns';

import ProjectScheduleOfValuesSubContract from './ProjectScheduleOfValuesSubContract';

const { Panel } = Collapse;

export default function ScheduleOfValuesTable({
  baseContractValues,
  changeOrderValues,
  nonHoldbackValues,
  liveSummaryValues,
  sectionValueMap,
  subContractsWithTotalChanges,
  currentHoldbackPercentage,
  readOnly,
  files,
  onPreviewClick,
  removeFile,
  addFile,
  setFiles,
  selectedFile,
  clearSelectedFile,
  updateFile,
  locked,
  onSetData,
  subContractCOs,
  isPastBill,
}) {
  const scheduleOfValueSections = useSelector((state) => state.projects.scheduleOfValueSections);

  const [selectedRecord, setSelectedRecord] = useState();
  const [showHistory, setShowHistory] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const onHistoryClick = useCallback((record) => {
    setSelectedRecord(record);
    setShowHistory(true);
  }, []);

  const onInfoClicked = useCallback((record) => {
    setSelectedRecord(record);
    setShowInfo(true);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setSelectedRecord();
    setShowHistory(false);
    setShowInfo(false);
  }, []);

  const columns = useMemo(() => (
    getScheduleOfValueColumns({
      onHistory: onHistoryClick,
      readOnly,
      isPastBill,
      hideButtons: isPastBill,
    })
  ), [
    onHistoryClick,
    readOnly,
    isPastBill,
  ]);

  const liveSummaryColumns = useMemo(() => getLiveSummaryColumns({
    readOnly,
    showHoldback: currentHoldbackPercentage,
    isPastBill,
    isScheduleOfValueLocked: locked,
  }), [currentHoldbackPercentage, readOnly, isPastBill, locked]);

  const subcontractColumns = useMemo(
    () => getScheduleOfValueColumns({
      onInfo: onInfoClicked,
      onHistory: onHistoryClick,
      readOnly,
      isSubContract: true,
      liveSummaryValues,
      isPastBill,
      hideButtons: isPastBill,
      isScheduleOfValueLocked: locked,
    }),
    [
      onHistoryClick,
      onInfoClicked,
      liveSummaryValues,
      readOnly,
      isPastBill,
      locked,
    ],
  );

  return (
    <>
      <div>
        <Table
          columns={liveSummaryColumns}
          dataSource={liveSummaryValues}
          pagination={false}
          rowKey="projectId"
          className="live-summary-table"
        />
      </div>
      <div className="project-contract-body">
        <Collapse defaultActiveKey={['1', '2', '3', '4']} className="project-sov-collapse">
          <Panel
            header="Base Contract"
            key="1"
          >
            <DraggableTable
              columns={columns}
              dataSource={baseContractValues}
              pagination={false}
              expandable={{
                defaultExpandAllRows: true,
              }}
              type="baseContract"
              setData={onSetData}
              isDraggable={!readOnly && !locked}
            />
          </Panel>
          <Panel
            header="Sub-Contracts"
            key="2"
          >
            <DraggableTable
              columns={subcontractColumns}
              dataSource={subContractsWithTotalChanges}
              pagination={false}
              expandable={{
                defaultExpandAllRows: true,
              }}
              type="subContract"
              setData={onSetData}
              isDraggable={!readOnly && !locked}
            />
          </Panel>
          <Panel
            header="Changes"
            key="3"
          >
            <DraggableTable
              columns={columns}
              dataSource={changeOrderValues}
              pagination={false}
              expandable={{
                defaultExpandAllRows: true,
              }}
              isDraggable={!readOnly && !locked}
              type="changeOrders"
              setData={onSetData}
            />
          </Panel>
          {currentHoldbackPercentage && (
            <Panel
              header="Non-Holdback Items"
              key="4"
            >
              <DraggableTable
                columns={columns}
                dataSource={nonHoldbackValues}
                pagination={false}
                expandable={{
                  defaultExpandAllRows: true,
                }}
                isDraggable={!readOnly && !locked}
                type="nonHoldbackItems"
                setData={onSetData}
              />
            </Panel>
          )}
          {scheduleOfValueSections.map((section) => (
            <Panel
              header={section.name}
              key={section.id}
            >
              <DraggableTable
                columns={columns}
                dataSource={sectionValueMap[section.id] || []}
                pagination={false}
                expandable={{
                  defaultExpandAllRows: true,
                }}
                type={section.id}
                setData={onSetData}
                isDraggable={!readOnly && !locked}
              />
            </Panel>
          ))}
        </Collapse>
      </div>
      <ProjectScheduleOfValuesSubContract
        visible={showInfo}
        onClose={onCloseDrawer}
        record={selectedRecord}
        isReadOnly={readOnly}
        liveSummaryValues={liveSummaryValues}
        newItemNumbers={{}}
        subContractCOs={subContractCOs}
        files={files}
        onPreviewClick={onPreviewClick}
        removeFile={removeFile}
        addFile={addFile}
        setFiles={setFiles}
        updateFile={updateFile}
        selectedFile={selectedFile}
        clearSelectedFile={clearSelectedFile}
        onSetData={onSetData}
      />
      <HistoryDrawer
        visible={showHistory}
        record={selectedRecord}
        onClose={onCloseDrawer}
        files={files}
        onPreviewClick={onPreviewClick}
        removeFile={removeFile}
        addFile={addFile}
        setFiles={setFiles}
        updateFile={updateFile}
        selectedFile={selectedFile}
        clearSelectedFile={clearSelectedFile}
      />
    </>
  );
}

ScheduleOfValuesTable.propTypes = {
  isPastBill: PropTypes.bool,
};

ScheduleOfValuesTable.defaultProps = {
  isPastBill: false,
};
