import React, { useState, useCallback, useMemo } from 'react';
import {
  Row,
  Col,
  Checkbox,
  Select,
} from 'antd';
import {
  CalendarOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import HoverHelp from '../../common/HoverHelp';
import OnTraccrCheckbox from '../../common/inputs/OnTraccrCheckbox';

import WorkflowHandle from './WorkflowHandle';
import WorkflowActionNode from './WorkflowActionNode';
import WorkflowConfigureDrawer from './WorkflowConfigureDrawer';

import { getType, updateData } from './workflowHelpers';

const HOVER_TEXT = `
Use this step to create a shift and progress the workflow when the assigned user clocks in/out from that shift.
`;

const DISABLED_TEXT = 'Add at least one of each: Text, Date/Time Range or a Shift Table to enable this step';

const getShiftFields = (t) => [
  { key: 'title', text: 'Title', type: 'text' },
  { key: 'description', text: 'Description', type: 'text' },
  { key: 'date', text: 'Date/Time', type: 'dateRange' },
  { key: 'userIds', text: 'Assigned User(s)', type: 'dropdown - Users' },
  { key: 'projectId', text: `${t('Project')}`, type: 'dropdown - Projects' },
  { key: 'costcodeId', text: 'Cost Code', type: 'dropdown - Costcodes' },
  { key: 'fileIds', text: 'Attachments', type: 'attachment' },
];

const requiredFields = new Set(['title', 'date']);

const CHECKBOX_STYLE = { color: 'black', marginLeft: 5, paddingBottom: 15 };

export default function WorkflowDispatchNode({
  setElements,
  setDataMap,
  isDisplay,
  name,
  sections = [],
} = {}) {
  return function _({
    id,
    draggable,
    disabled,
    data = {},
  }) {
    const { t } = useTranslation();
    const shiftFields = getShiftFields(t);
    const title = `Dispatch${disabled ? ' - DISABLED' : ''}`;
    const {
      fieldMappings: initialFieldMappings = {},
      useMultiShiftField,
      multiShiftFieldId,
      shouldLockClockIn: shouldLockClockInInitial,
      isDraft: isDraftInitial,
      duplicateWorkflow: isDuplicateInitial,
    } = data;

    const [shouldLockClockIn, setShouldLockClockIn] = useState(!!shouldLockClockInInitial);
    const [isDraft, setIsDraft] = useState(!!isDraftInitial);
    const [duplicateWorkflow, setDuplicateWorkflow] = useState(!!isDuplicateInitial);

    const onShouldLockClockInChange = useCallback(() => {
      setShouldLockClockIn(!shouldLockClockIn);
      setDataMap((dataMap) => ({
        ...dataMap,
        [id]: { ...dataMap[id], shouldLockClockIn: !shouldLockClockIn },
      }));
    }, [id, shouldLockClockIn]);

    const onIsDraftChange = useCallback(() => {
      setIsDraft(!isDraft);
      setDataMap((dataMap) => ({
        ...dataMap,
        [id]: { ...dataMap[id], isDraft: !isDraft },
      }));
    }, [id, isDraft]);

    const onDuplicateChanged = useCallback((newValue) => {
      setDuplicateWorkflow(newValue);
      setDataMap((dataMap) => ({
        ...dataMap,
        [id]: { ...dataMap[id], duplicateWorkflow: newValue },
      }));
    }, [id]);

    const shiftTables = useMemo(() => (
      sections.map(({ fields = [] }) => (
        fields.filter((field) => {
          const type = getType(field);
          return type === 'table - Shifts';
        })
      ))
        .flat()
        .map((field) => {
          const {
            configProps: { title: fieldTitle } = {},
            id: fieldId,
          } = field;
          return { label: fieldTitle, value: fieldId };
        })
    ), [sections]);

    const enableMultiShift = shiftTables.length > 0;
    const initialMultiShift = enableMultiShift && useMultiShiftField;

    const [showDrawer, setShowDrawer] = useState(false);
    const [fieldMappings, setFieldMappings] = useState(initialFieldMappings);
    const [stateUsingMultiShift, setStateUsingMultiShift] = useState(initialMultiShift);
    const [shiftTableId, setShiftTableId] = useState(multiShiftFieldId);

    const openDrawer = useCallback(() => setShowDrawer(true), []);
    const closeDrawer = useCallback(() => setShowDrawer(false), []);

    const onFieldMappingsChange = useCallback((newMappings) => {
      if (!setDataMap || !id) return;
      setFieldMappings(newMappings);
      setDataMap(updateData(id, { fieldMappings: newMappings }));
    }, [setDataMap, id]);

    const onCheckChanged = useCallback((e) => {
      const {
        target: {
          checked,
        } = {},
      } = e;
      setStateUsingMultiShift(checked);
      setDataMap(updateData(id, { useMultiShiftField: checked }));
      if (checked) {
        // Need to remove outbound edges for multi-shift dispatch
        setElements((elements) => (
          elements.filter((elem) => {
            const { sourceHandle } = elem;
            return !sourceHandle || !sourceHandle.startsWith(id);
          })
        ));
      }
    }, [setDataMap, id]);

    const onShiftTableSelect = useCallback((newFieldId) => {
      if (!setDataMap || !id) return;
      setShiftTableId(newFieldId);
      setDataMap(updateData(id, { multiShiftFieldId: newFieldId }));
    }, [setDataMap, id]);

    return (
      <WorkflowActionNode
        id={id}
        title={title}
        Icon={CalendarOutlined}
        type="dispatch"
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : {}}
      >
        {!draggable && (
        <div>
          {enableMultiShift
              && (
              <Row style={{ width: '100%', margin: '5px 0px' }}>
                <Col>
                  <Checkbox onChange={onCheckChanged} defaultChecked={enableMultiShift && useMultiShiftField} />
                </Col>
                <Col style={{ padding: '0px 5px' }}>
                  Use Shift Table?
                </Col>
                <Col>
                  <HoverHelp
                    content={(
                      <div style={{ width: 300 }}>
                        Check this box if you want to link this dispatch step to a Shift table.<br /> <br />
                        Leave this box unchecked if you want to link this dispatch step to individual fields.
                      </div>
                  )}
                  />
                </Col>
              </Row>
              )}
          <Row style={{ margin: '20px 0px' }}>
            {stateUsingMultiShift
              ? (
                <Select
                  options={shiftTables}
                  optionFilterProp="label"
                  value={shiftTableId}
                  onChange={onShiftTableSelect}
                />
              )
              : (
                <BorderlessButton
                  title="Configure"
                  style={{ margin: 5 }}
                  iconNode={<SettingOutlined />}
                  onClick={openDrawer}
                />
              )}
          </Row>
          <Checkbox
            checked={shouldLockClockIn}
            onChange={onShouldLockClockInChange}
            style={CHECKBOX_STYLE}
          >
            Lock Clock In Details
          </Checkbox>
          <Checkbox
            checked={isDraft}
            onChange={onIsDraftChange}
            style={CHECKBOX_STYLE}
          >
            Create as Draft
          </Checkbox>
          {stateUsingMultiShift
            && (
              <OnTraccrCheckbox
                textStyle={{ color: 'black' }}
                checkboxStyle={{ marginRight: 5 }}
                style={{ marginBottom: 15 }}
                label='Duplicate Workflows'
                value={duplicateWorkflow}
                onChange={onDuplicateChanged}
                hoverNode={
                  <div style={{ maxWidth: 300 }}>
                    Check this box if you want to create a new workflow for each clock in/out
                    action from this shift.
                    <br /> <br />
                    Leave this box unchecked if you want the workflow to be shared by all shift users
                  </div>
                }
              />
          )}
          <Row justify="space-between" style={{ marginBottom: 5 }}>
            <Col>
              Clock In
            </Col>
            <Col>
              Clock Out
            </Col>
          </Row>
          <WorkflowHandle
            type="source"
            position="bottom"
            style={{ left: '20%' }}
            id={`${id}-clockin`}
            disabled={isDisplay}
          />
          <WorkflowHandle
            type="source"
            position="bottom"
            style={{ left: '80%' }}
            id={`${id}-clockout`}
            disabled={isDisplay}
          />
          <WorkflowHandle type="target" position="top" disabled={isDisplay} />
          <WorkflowConfigureDrawer
            id={id}
            visible={showDrawer}
            onClose={closeDrawer}
            onSubmit={closeDrawer}
            sourceName={name}
            sourceSections={sections}
            fieldMappings={fieldMappings}
            onFieldMappingsChange={onFieldMappingsChange}
            data={data}
            setDataMap={setDataMap}
            fields={shiftFields}
            requiredFields={requiredFields}
          />
        </div>
        )}
      </WorkflowActionNode>
    );
  };
}
