import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Payroll } from 'ontraccr-common';
import PropTypes from 'prop-types';

import ReportDateSelector from '../reports/MainReportsContainer/ReportDateSelector';

import TimeCardDatePickerButton from './TimeCardDatePickerButton';

import {
  setCalendarType,
  setTimeRange as setTimecardTimeRange,
} from './state/timecards.actions';

import {
  getFirstPayrollDay,
  findPayrollStartAndEndDates,
  getNextPayroll,
} from '../helpers/payroll';

const headerIconStyle = { fontSize: 20, marginLeft: 0, color: 'black' };

function TimeCardDatePicker({
  onChange: propOnChange,
  onTypeChange: propOnTypeChange,
  value,
  type,
}) {
  const dispatch = useDispatch();
  const {
    calendarType: stateType,
    timeRange: stateRange,
  } = useSelector((state) => state.timecards);
  const {
    settings: {
      payPeriod,
      payPeriodDates,
      semiMonthlyPayPeriodDates,
    } = {},
  } = useSelector((state) => state.settings.company ?? {});

  const dispatchTimeRange = useMemo(() => (
    (...args) => dispatch(setTimecardTimeRange(...args))
  ), []);
  const setTimeRange = useMemo(() => (
    propOnChange ?? dispatchTimeRange
  ), [propOnChange, dispatchTimeRange]);

  const firstDayOfPayroll = useMemo(() => (
    getFirstPayrollDay({ payPeriod, payPeriodDates, semiMonthlyPayPeriodDates })
  ), [payPeriod, payPeriodDates, semiMonthlyPayPeriodDates]);

  const calendarType = type ?? stateType;
  const timeRange = value ?? stateRange ?? [];
  const [startTime, endTime] = timeRange;
  const now = DateTime.local();
  const isCustom = calendarType === 'custom';

  const onTypeChange = useCallback((newType) => {
    if (propOnTypeChange) {
      propOnTypeChange(newType);
      return;
    }
    dispatch(setCalendarType(newType));
  }, [dispatch, propOnTypeChange]);

  const onPrevious = useCallback(() => {
    if (calendarType === 'week') {
      return setTimeRange([startTime.minus({ days: 7 }), startTime.minus({ days: 1 })]);
    }
    const startDay = getNextPayroll({
      payPeriod,
      firstDay: startTime,
      forward: false,
      semiMonthlyPayPeriodDates,
    });
    const endDay = startTime.minus({ day: 1 }).endOf('day');
    return setTimeRange([startDay, endDay]);
  }, [setTimeRange, calendarType, startTime, payPeriod, semiMonthlyPayPeriodDates]);

  const onNext = useCallback(() => {
    if (calendarType === 'week') {
      return setTimeRange([startTime.plus({ days: 7 }), startTime.plus({ days: 14 })]);
    }
    const startDay = getNextPayroll({
      payPeriod,
      firstDay: payPeriod === 'Semi-Monthly' ? endTime : startTime,
      forward: true,
      semiMonthlyPayPeriodDates,
    }).startOf('day');
    const endDay = payPeriod === 'Semi-Monthly'
      ? Payroll.findSemiMonthlyPayPeriod({
        startDay,
        semiMonthlyPayPeriodDates,
      })[1]
      : getNextPayroll({
        payPeriod,
        firstDay: startDay,
        forward: true,
        semiMonthlyPayPeriodDates,
      }).minus({ day: 1 }).endOf('day');
    return setTimeRange([startDay, endDay]);
  }, [setTimeRange, calendarType, startTime, endTime, payPeriod, semiMonthlyPayPeriodDates]);

  const onChange = useCallback((newStart) => {
    const dt = DateTime.fromMillis(newStart.valueOf());
    if (calendarType === 'week') {
      if (dt.weekday === 7) {
        // 7 === Sunday.
        return setTimeRange([
          dt,
          dt.plus({ days: 7 }),
        ]);
      }
      return setTimeRange([
        dt.startOf('week').minus({ day: 1 }),
        dt.endOf('week').minus({ day: 1 }),
      ]);
    }
    // Has to be pay period here. Custom range uses onRangeChange
    return setTimeRange(findPayrollStartAndEndDates({
      startDay: dt,
      payPeriodFirstDay: firstDayOfPayroll,
      payPeriod,
      semiMonthlyPayPeriodDates,
    }));
  }, [setTimeRange, calendarType, payPeriod, firstDayOfPayroll, semiMonthlyPayPeriodDates]);

  const onRangeChange = useCallback((newRange) => {
    if (newRange.length !== 2 || !newRange.every(moment.isMoment)) return;
    const [start, end] = newRange;
    const startDT = DateTime.fromMillis(start.valueOf()).startOf('day');
    const endDT = DateTime.fromMillis(end.valueOf()).endOf('day');
    setTimeRange([startDT, endDT]);
  }, [setTimeRange, calendarType]);

  const range = useMemo(() => timeRange.map((dt) => moment(dt.toMillis())), [timeRange]);

  return (
    <>
      { !isCustom && (
        <TimeCardDatePickerButton
          icon={<LeftOutlined style={headerIconStyle} />}
          onClick={onPrevious}
          visible
        />
      )}
      <Col>
        <ReportDateSelector
          onTypeChange={onTypeChange}
          selectedType={calendarType}
          value={range[0]}
          hideCustom={false}
          onChange={onChange}
          range={range}
          onRangeChange={onRangeChange}
          allowClear={false}
          renderDropdownInBody
        />
      </Col>
      { (!isCustom && endTime < now) && (
        <TimeCardDatePickerButton
          icon={<RightOutlined style={headerIconStyle} />}
          onClick={onNext}
          visible
        />
      )}
    </>
  );
}

TimeCardDatePicker.propTypes = {
  onChange: PropTypes.func,
  onTypeChange: PropTypes.func,
  value: PropTypes.arrayOf(DateTime),
  type: PropTypes.string,
};

TimeCardDatePicker.defaultProps = {
  onChange: null,
  onTypeChange: null,
  value: null,
  type: null,
};

export default TimeCardDatePicker;
