import React, { useEffect, useMemo, useState } from 'react';
import { Drawer } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import ScheduleOfValuesTable from './ScheduleOfValuesTable';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import {
  getSubContractsWithTotalChanges,
} from './helpers';

export default function ScheduleOfValuesPastBillReview({
  snapshot = {},
  currentHoldbackPercentage,
  visible,
  onClose,
  files,
  onPreviewClick,
  removeFile,
  addFile,
  setFiles,
  selectedFile,
  clearSelectedFile,
  updateFile,
}) {
  const {
    baseContractValues,
    changeOrderValues,
    nonHoldbackValues,
    liveSummaryValues,
    subContractValues,
    sectionValueMap,
    subContractCOs,
    timestamp,
  } = snapshot;

  const [editing, setEditing] = useState(false);

  const date = useMemo(() => moment(timestamp).format('MM/DD/YYYY hh:mm A'), [timestamp]);

  const subContractsWithTotalChanges = useMemo(() => (
    getSubContractsWithTotalChanges({
      subContractValues,
      subContractCOs,
    })
  ), [subContractValues, subContractCOs]);

  useEffect(() => {
    if (!visible) {
      setEditing(false);
    }
  }, [visible]);

  return (
    <Drawer
      title={`${editing ? 'Edit' : 'Review'} Progress Bill ${timestamp ? date : ''}`}
      visible={visible}
      onClose={onClose}
      width={1300}
      push={false}
      className="schedule-of-values-review-drawer"
      bodyStyle={{ paddingBottom: 53 }}
    >
      <ScheduleOfValuesTable
        readOnly={!editing}
        baseContractValues={baseContractValues}
        changeOrderValues={changeOrderValues}
        nonHoldbackValues={nonHoldbackValues}
        liveSummaryValues={liveSummaryValues}
        sectionValueMap={sectionValueMap}
        subContractsWithTotalChanges={subContractsWithTotalChanges}
        subContractCOs={subContractCOs}
        currentHoldbackPercentage={currentHoldbackPercentage}
        files={files}
        onPreviewClick={onPreviewClick}
        removeFile={removeFile}
        addFile={addFile}
        setFiles={setFiles}
        selectedFile={selectedFile}
        clearSelectedFile={clearSelectedFile}
        updateFile={updateFile}
        locked
        isPastBill
      />
      <div className="footer">
        <OnTraccrButton
          title="Close"
          type="back"
          onClick={onClose}
        />
        <OnTraccrButton
          title={editing ? 'Finish Editing' : 'Edit'}
          onClick={() => setEditing(!editing)}
        />
      </div>
    </Drawer>
  );
}

ScheduleOfValuesPastBillReview.propTypes = {
  snapshot: PropTypes.shape({}),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  onPreviewClick: PropTypes.func,
  removeFile: PropTypes.func.isRequired,
  addFile: PropTypes.func.isRequired,
  setFiles: PropTypes.func.isRequired,
  selectedFile: PropTypes.shape({}),
  clearSelectedFile: PropTypes.func,
  updateFile: PropTypes.func,
  currentHoldbackPercentage: PropTypes.number.isRequired,
};

ScheduleOfValuesPastBillReview.defaultProps = {
  snapshot: {},
  onClose: null,
  files: [],
  onPreviewClick: null,
  selectedFile: {},
  clearSelectedFile: null,
  updateFile: null,
};
