import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Select } from 'antd';

import sortByString, { getIdMap } from '../../../helpers/helpers';

export default function FormSelector({
  divisionId,
  divisions = [],
  templateId,
  onChange,
  disable,
  selected = [],
  projectId,
  hideExternalForms = false,
  additionalFormOptions = [],
}) {
  const templates = useSelector(state => state.forms.templates);
  const projects = useSelector(state => state.projects.projects);

  const projectIdMap = useMemo(() => getIdMap(projects),[projects]);

  const divSet = useMemo(() => new Set(divisions), [divisions]);
  const divTemplates = useMemo(() => {
    const templateArr = Object.values(templates)
      .filter((template) => template.id !== templateId
        && (!projectId || template.projectId === projectId || !template.projectId)
        && (template.divisionId === divisionId || divSet.has(template.divisionId) || (!divisionId && !divSet.size))
        && template.active
        && template.notDeleted
        && (!hideExternalForms || !template.isExternalForm)
      );
    templateArr.sort(sortByString('name'));
    return templateArr;
  },[
    templates,
    divSet,
    divisionId,
    templateId,
    projectId,
    hideExternalForms,
  ]);

  const options = useMemo(() => (
    additionalFormOptions.concat(divTemplates.map((template) => {
      const {
        projectId: templateProjectId,
      } = template;
      let secondRow = null;
      if (templateProjectId && templateProjectId in projectIdMap) {
        const { [templateProjectId] : { name: projectName } = {} } = projectIdMap;
        secondRow = <div style={{ fontSize: 10, fontStyle: 'italic', lineHeight: '12px' }}>{projectName}</div>
      }
      return (
        <Select.Option key={template.id} value={template.id} label={template.name}>
          {template.name}
          {secondRow}
        </Select.Option>
      );
    }))
  ),[divTemplates, projectIdMap, additionalFormOptions])

  return (
    <>
      <Row style={{ marginTop: 5 }}>
          Select Form:
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <Select
          onChange={onChange}
          style={disable ? { pointerEvents:'none',  width: '100%' } : {  width: '100%' }}
          dropdownMatchSelectWidth={false}
          value={selected}
          showSearch
          optionFilterProp='label'
        >
          {options}
        </Select>
      </Row>
    </>
  );
}
