import React, { useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Select, TreeSelect } from 'antd';

import config from '../../../config';

import WorkflowTag from '../WorkflowTag';
import { AdditionalItem, AdditionalLabel } from '../AssignmentDropdownItems';

import { getUserFields } from '../workflowHelpers';
import { includesTerm, mergeSets } from '../../../helpers/helpers';

// CONSTANTS:
const TREE_SELECT_DEFAULT_TYPE = 'Default';
const TREE_SELECT_ROLE_TYPE = 'Roles';
const TREE_SELECT_FIELDS_OPTIONS_TYPE = 'Fields Options';

const AUTHOR_TITLE = 'Form Author';
const getFormAuthorItem = (helpText) => ({
  id: 'formAuthor',
  children: <AdditionalItem title={AUTHOR_TITLE} help={helpText} />,
  label: <AdditionalLabel title={AUTHOR_TITLE} help={helpText} />,
});
const OWNER_TITLE = 'Company Owner';
const getCompanyOwnerItem = (helpText) => ({
  id: 'companyOwner',
  children: <AdditionalItem title={OWNER_TITLE} help={helpText} />,
  label: <AdditionalLabel title={OWNER_TITLE} help={helpText} />,
});

/**
 * Gets child name for user assignment tree selector
 * @param {string} type
 * @param {boolean} useCompoundName
 * @param {string} groupTitle
 * @param {object} option
 * @returns {string}
 */
const getChildName = ({
  type,
  useCompoundName,
  groupTitle,
  option = {},
}) => {
  const { name, label } = option;
  switch (type) {
    case TREE_SELECT_ROLE_TYPE:
      return `Any ${name}`;
    case TREE_SELECT_FIELDS_OPTIONS_TYPE:
      return useCompoundName ? `${groupTitle} - ${label}` : label;
    default:
      return useCompoundName ? `${groupTitle} - ${name}` : name;
  }
};

/**
 * Creates grouped options
 * @param {string} type
 * @param {string} groupTitle
 * @param {string} groupValue
 * @param {boolean} useCompoundKey
 * @param {boolean} useCompoundName
 * @param {boolean} selectable - whether the node is selectable
 * @param {array} options
 * @returns {object}
 */
const createTreeSelectOption = ({
  type = TREE_SELECT_DEFAULT_TYPE,
  groupTitle,
  groupValue,
  useCompoundKey = false,
  useCompoundName = false,
  selectable = false,
  options = [],
}) => {
  const groupOption = {
    selectable,
    title: groupTitle,
    value: groupValue,
    children: [],
  };
  options.forEach((option) => {
    const { id } = option || {};
    const childKey = useCompoundKey ? `${groupValue}.${id}` : id;
    const childName = getChildName({
      useCompoundName,
      option,
      groupTitle,
      type,
    });
    groupOption.children.push({
      isLeaf: true,
      value: childKey,
      title: childName,
    });
  });
  return groupOption;
};

export default function ({
  isDisplay,
  draggable,
  divisions,
  onChange,
  selected = [],
  mode = 'multiple',
  showPositions,
  type,
  text = 'Select Recipient(s):',
  tokenSeparators = null,
  additionalOptions = [],
  showFields,
  sections = [],
  showTeams,
  showFormAuthor,
  formAuthorHelperText,
  showCompanyOwner,
  companyOwnerHelpText,
  isExternalForm,
  disabled,
  hideUsers,
  placeholder,
}) {
  const allDivisions = useSelector((state) => state.settings.divisions);
  const positionNames = useSelector((state) => state.settings.positionNames);
  const activeTeams = useSelector((state) => (state.teams.teams
    .filter((team) => team.active)));

  const activeUsers = useSelector((state) => {
    const {
      users: {
        users = [],
      } = {},
    } = state;
    return users.filter((user) => user.active);
  });

  const divUsers = useMemo(() => {
    if (hideUsers) return [];
    if (!divisions?.length || !config.showDivisions) return activeUsers;

    const selectedDivisions = new Set(divisions);

    const relevantDivisionUsers = mergeSets(
      Object.values(allDivisions)
        .filter(({ id: divisionId }) => selectedDivisions.has(divisionId))
        .map(({ users }) => users),
    );

    return activeUsers.filter((user) => relevantDivisionUsers.has(user.id));
  }, [activeUsers, allDivisions, divisions, hideUsers]);

  const userFields = useMemo(() => getUserFields(sections), [sections]);

  const fullAdditionalOptions = useMemo(() => {
    const formAuthorItems = showFormAuthor
      ? [getFormAuthorItem(formAuthorHelperText)]
      : [];
    if (showCompanyOwner) formAuthorItems.push(getCompanyOwnerItem(companyOwnerHelpText));
    return formAuthorItems.concat(additionalOptions);
  }, [
    additionalOptions,
    showFormAuthor,
    formAuthorHelperText,
    showCompanyOwner,
    companyOwnerHelpText,
  ]);

  const treeSelectOptions = useMemo(() => {
    const options = {};

    if (isExternalForm && type === 'users') {
      return [{
        title: 'External',
        value: 'external',
        selectable: true,
      }];
    }

    if (fullAdditionalOptions?.length > 0) {
      options.other = createTreeSelectOption({
        groupTitle: 'Other',
        groupValue: 'other',
        type: TREE_SELECT_FIELDS_OPTIONS_TYPE,
        options: fullAdditionalOptions,
      });
    }
    if (showFields && userFields?.length > 0) {
      options.fields = createTreeSelectOption({
        groupTitle: 'Fields',
        groupValue: 'fields',
        type: TREE_SELECT_FIELDS_OPTIONS_TYPE,
        options: userFields,
      });
    }
    if (showPositions && positionNames?.length > 0) {
      options.roles = createTreeSelectOption({
        groupTitle: 'Roles',
        groupValue: 'roles',
        type: TREE_SELECT_ROLE_TYPE,
        options: positionNames,
      });
    }
    if (showTeams && activeTeams?.length > 0) {
      options.teams = createTreeSelectOption({
        groupTitle: 'Teams',
        groupValue: 'teams',
        options: activeTeams,
      });
    }
    if (divUsers?.length > 0) {
      options.users = createTreeSelectOption({
        groupTitle: 'Users',
        groupValue: 'users',
        options: divUsers,
      });
    }

    return Object.values(options);
  }, [
    showFields,
    showPositions,
    showTeams,
    fullAdditionalOptions,
    userFields,
    positionNames,
    activeTeams,
    divUsers,
    isExternalForm,
    type,
  ]);

  const workflowTagRender = useCallback((props) => (
    <WorkflowTag
      closable={!isDisplay}
      itemProps={props}
      label={props.label}
      type={type}
    />
  ), [isDisplay, type]);

  useEffect(() => {
    if (isExternalForm && type === 'users') {
      onChange(['external']);
    }
  }, [isExternalForm, type, onChange]);

  return (
    <>
      {text && (
        <Row style={{ marginTop: 5 }}>
          {text}
        </Row>
      )}
      <Row style={{ margin: '10px 0px' }} className="nodrag">
        {tokenSeparators || mode === 'tags'
          ? (
            <Select
              placeholder={placeholder}
              showSearch
              tokenSeparators={tokenSeparators}
              mode={mode}
              style={draggable ? { pointerEvents: 'none', width: '100%' } : { width: '100%' }}
              onChange={onChange}
              value={selected}
              dropdownMatchSelectWidth={false}
              optionLabelProp="label"
              tagRender={workflowTagRender}
              disabled={disabled}
              filterOption={
                (input, option) => (
                  (typeof (option.props.children) === 'string' && includesTerm(option.props.children, input))
                  || (typeof (option.props.children) === 'object' && includesTerm(option.props.children.props?.title, input))
                )
              }
            >
              {
                fullAdditionalOptions.map((opt) => (
                  <Select.Option key={opt.id} value={opt.id} label={opt.label}>
                    {opt.children}
                  </Select.Option>
                ))
              }
              {
                showFields && userFields.map((opt) => (
                  <Select.Option key={opt.id} value={opt.id} label={opt.label}>
                    {opt.children}
                  </Select.Option>
                ))
              }
              {
                showPositions && positionNames
                && positionNames.map((position) => (
                  <Select.Option key={position.id} value={position.id} label={`Any ${position.name}`}>
                    {`Any ${position.name}`}
                  </Select.Option>
                ))
              }
              {divUsers.map((user) => (
                <Select.Option key={user.id} value={user.id} label={user.name}>
                  {user.name}
                </Select.Option>
              ))}
            </Select>
          )
          : (
            <TreeSelect
              allowClear={!isExternalForm}
              showSearch
              onChange={onChange}
              treeDefaultExpandAll={false}
              treeNodeFilterProp="title"
              filterTreeNode={(input, treeNode) => (
                typeof treeNode.title === 'string'
                  && includesTerm(treeNode.title, input)
              )}
              value={selected}
              multiple={mode === 'multiple' && !isExternalForm}
              treeData={treeSelectOptions}
              style={draggable || disabled ? { pointerEvents: 'none', width: '100%' } : { width: '100%' }}
              dropdownMatchSelectWidth={false}
              tagRender={workflowTagRender}
              disabled={isExternalForm}
            />
          )}
      </Row>
    </>
  );
}
