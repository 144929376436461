import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Select,
  Row,
  Col,
  Typography,
  Form,
} from 'antd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import FormSection from './FormSections/FormSection';
import FormSectionAdd from './FormSections/FormSectionAdd';

import {
  createNuxEntry,
} from '../../nux/state/nux.actions';

import { extractFormFieldOptions, generateId } from '../formHelpers';
import {
  FORMS_ADD_FIELDS_TYPE,
  FORMS_ADD_FIELDS_STEP_PREFIX,
} from '../../nux/nux.constants';

import { getCostcodeCustomFieldTemplate } from '../../costcodes/state/costcodes.actions';
import { getCustomers } from '../../contacts/customers/state/customers.actions';
import { getEquipment } from '../../equipment/state/equipment.actions';
import { getProjectScheduleOfValues } from '../../projects/state/projects.actions';
import { getTemplates } from '../state/forms.actions';
import { getUserCustomFieldTemplate } from '../../users/state/users.actions';
import { getBuckets } from '../../buckets/state/buckets.actions';

/*
 Section format:
 {
   id,
   name,
   fields: [{ ... },{ ... },...]
 }
*/

const { Text } = Typography;

const defaultSections = [{
  id: 'section-1',
  name: 'Default Section',
  fields: [],
}];

export default function FormBuilder({
  formId,
  onSectionsChanged,
  onValueFieldChanged,
  currentStep,
  signatureView, // For showing employee signature
  valueField = '',
  initialSections = defaultSections,
  isDisplay = false,
  isAdd = false,
  allowValueFieldSelection = false,
  style = {},
  isBoardCards, // Re-use for board card templates. Hide certain fields
  formType = {},
  isExternalForm = false,
  divisions,
  templateId,
  name,
  shouldHideCustomRenderingFields = false,
  enablePermissions,
  sectionPermissionMap,
  children,
  isDetailView = false,
  isTimeCard = false,
  canAddDynamicAttribute,
  showParentAttributeCheckbox = false,
  setDisplayFields,
}) {
  const dispatch = useDispatch();

  const activeNuxAction = useSelector((state) => state.nux.activeNuxAction);
  const nux = useSelector((state) => state.nux.nux);
  const forms = useSelector((state) => state.forms.forms);

  const [sections, setSections] = useState(initialSections);
  const [dragId, setDragId] = useState();

  const associatedProjectId = forms[formId] ? forms[formId].projectId : null;

  const onSectionAdd = useCallback((sectionName) => {
    setSections([
      ...sections,
      {
        name: sectionName,
        id: `section-${generateId()}`,
      },
    ]);
  }, [sections]);

  const onSectionDelete = useCallback((id) => {
    setSections(sections.filter(({ id: sectionId }) => sectionId !== id));
  }, [sections]);

  const onSectionRename = useCallback((id, newName) => {
    setSections(
      sections.map((section) => {
        if (section.id !== id) return section;
        return {
          ...section,
          name: newName,
        };
      }),
    );
  }, [sections]);

  const onSectionsSettingUpdated = useCallback((id, newSettings) => {
    setSections(
      sections.map((section) => {
        if (section.id !== id) return section;
        const fields = newSettings?.defaultCollapsed
          ? section?.fields?.map((field) => {
            const newField = {
              ...field,
              configProps: {
                ...field?.configProps ?? {},
                optional: true,
              },
            };
            if (field?.selectedType === 'table') {
              newField.configProps.requiredColumns = false;
            }
            return newField;
          })
          : section?.fields;
        return {
          ...section,
          settings: {
            ...(section.settings ?? {}),
            ...newSettings,
          },
          fields,
        };
      }),
    );
  }, [sections]);

  const onSectionDragStart = useCallback(({ draggableId }) => {
    setDragId(draggableId);
  }, []);

  const onSectionDragDrop = useCallback((dragEvent) => {
    setDragId();
    const { destination, source, type } = dragEvent;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) return;
    if (type === 'SECTION') {
      const newSections = [...sections];
      newSections.splice(source.index, 1);
      newSections.splice(destination.index, 0, sections[source.index]);
      setSections(newSections);
    } else {
      let ourField;

      sections.forEach((section) => {
        if (section.id !== source.droppableId) return;
        const { fields = [] } = section;
        ourField = fields[source.index];
        fields.splice(source.index, 1);
        section.fields = fields;
      });

      const newSections = sections.map((section) => {
        if (section.id !== destination.droppableId) return section;
        const newSection = { ...section };
        const { fields = [] } = newSection;
        fields.splice(destination.index, 0, ourField);
        newSection.fields = fields;
        return newSection;
      });
      setSections(newSections);
    }
  }, [sections]);

  const onFieldAdd = useCallback((id) => (field = {}) => {
    const { id: fieldId } = field;

    setSections(
      sections.map((section) => {
        if (section.id !== id) return section;
        const { fields = [] } = section;
        let newFields;

        if (fieldId) {
          newFields = fields.map((existingField) => {
            if (existingField.id !== fieldId) return existingField;
            return field;
          });
        } else {
          newFields = fields.concat({
            ...field,
            id: `field-${generateId()}`,
          });
        }

        return {
          ...section,
          fields: newFields,
        };
      }),
    );
  }, [sections]);

  const onFieldDelete = useCallback(({ sectionId, fieldId }) => {
    setSections(
      sections.map((section) => {
        if (section.id !== sectionId) return section;
        const { fields = [] } = section;
        return {
          ...section,
          fields: fields
            .map((field) => {
              if (field.configProps?.linkedTimeEntryTable === fieldId) {
                return {
                  ...field,
                  configProps: {
                    ...field.configProps,
                    linkedTimeEntryTable: null,
                  },
                };
              }
              return field;
            })
            .filter((field) => field.id !== fieldId),
        };
      }),
    );
    setDisplayFields?.((prevFields) => (prevFields.filter((field) => field.fieldId !== fieldId)));
  }, [sections]);

  const valueFieldOptions = useMemo(() => extractFormFieldOptions({ types: new Set(['text', 'calculation']), sections }), [sections]);

  const valueComponent = useMemo(() => {
    if (!allowValueFieldSelection) return null;
    const {
      name: typeName = 'PO',
    } = formType ?? {};
    if (!isDisplay) {
      return (
        <Row
          style={{
            marginLeft: 24,
            marginTop: 10,
            marginBottom: 10,
            width: '100%',
          }}
        >
          <Col style={{ marginTop: 3 }}>
            <Text style={{ fontWeight: 'bolder', fontSize: 14 }}>
              Choose a below field as the
              {` ${typeName} `}
              value
            </Text>
          </Col>
          <Col style={{ marginLeft: 10, width: '50%' }}>
            <Form.Item
              name="valueField"
              style={{ verticalAlign: 'center' }}
            >
              <Select
                onChange={onValueFieldChanged}
                options={valueFieldOptions}
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }

    const valueFieldOption = valueFieldOptions
      .find((field) => field.value && field.value === valueField);
    const valueFieldLabel = valueFieldOption?.label ?? 'None';
    return (
      <Row
        style={{
          marginLeft: 24,
          marginTop: 10,
          marginBottom: 10,
          width: '100%',
        }}
      >
        <Col>
          <Text style={{ fontWeight: 'bolder', fontSize: 14 }}>
            Field selected as
            {typeName}
            value:
          </Text>
        </Col>
        <Col style={{ marginLeft: 10 }}>
          <Text style={{ fontSize: 14 }}>{valueFieldLabel}</Text>
        </Col>
      </Row>
    );
  }, [allowValueFieldSelection, valueFieldOptions, onValueFieldChanged, formType, valueField]);

  const formFieldContainerStyling = useMemo(() => {
    if (allowValueFieldSelection) {
      return isDisplay ? 'form-field-container-po' : 'form-field-container-po-edit';
    }
    return isDisplay ? 'form-field-container' : 'form-field-container-border';
  }, [allowValueFieldSelection, isDisplay]);

  useEffect(() => {
    if (onSectionsChanged) onSectionsChanged(sections);
  }, [sections, onSectionsChanged]);

  useEffect(() => {
    setSections(initialSections);
  }, [initialSections]);

  useEffect(() => {
    if (
      currentStep === 1
      && isAdd
      && dispatch
      && !nux.has(FORMS_ADD_FIELDS_TYPE)
    ) {
      dispatch(createNuxEntry(FORMS_ADD_FIELDS_TYPE));
    }
  }, [isAdd, nux, dispatch, currentStep]);

  useEffect(() => {
    dispatch(getCostcodeCustomFieldTemplate());
    dispatch(getCustomers());
    dispatch(getEquipment());
    dispatch(getTemplates());
    dispatch(getProjectScheduleOfValues({}));
    dispatch(getUserCustomFieldTemplate());
    dispatch(getBuckets());
  }, []);

  const showNux = activeNuxAction?.startsWith(FORMS_ADD_FIELDS_STEP_PREFIX);
  return (
    <div
      style={{
        position: 'absolute',
        top: isDisplay ? 101 : 127,
        left: 14,
        right: 14,
        bottom: 53,
        zIndex: showNux ? 1000 : 1,
        pointerEvents: showNux ? 'none' : 'auto',
        ...style,
      }}
    >
      {valueComponent}
      {children}
      <div className={formFieldContainerStyling}>
        <DragDropContext onDragEnd={onSectionDragDrop} onDragStart={onSectionDragStart}>
          <Droppable droppableId="parent" type="SECTION">
            {({ droppableProps, innerRef, placeholder }) => (
              <div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...droppableProps}
                ref={innerRef}
                style={{ height: '100%', width: '100%' }}
              >
                {sections
                  .filter(({ id }) => !isDisplay
                    || (!sectionPermissionMap || sectionPermissionMap[id]?.canView))
                  .map(({
                    name: sectionName,
                    id,
                    fields = [],
                    hidden,
                    settings = {},
                  }, index) => (!hidden ? (
                    <FormSection
                      projectId={associatedProjectId}
                      title={sectionName}
                      key={id}
                      id={id}
                      index={index}
                      settings={settings}
                      fields={fields}
                      dragging={dragId === id}
                      onDelete={onSectionDelete}
                      onRename={onSectionRename}
                      onSectionsSettingUpdated={onSectionsSettingUpdated}
                      onFieldAdd={onFieldAdd(id)}
                      onFieldDelete={onFieldDelete}
                      isDisplay={isDisplay}
                      isAdd={isAdd}
                      sections={sections}
                      enablePermissions={enablePermissions}
                      isBoardCards={isBoardCards}
                      isExternalForm={isExternalForm}
                      divisions={divisions}
                      templateId={templateId}
                      name={name}
                      shouldHideCustomRenderingFields={shouldHideCustomRenderingFields}
                      isDetailView={isDetailView}
                      isTimeCard={isTimeCard}
                      canAddDynamicAttribute={canAddDynamicAttribute}
                      showParentAttributeCheckbox={showParentAttributeCheckbox}
                    />
                  ) : null))}
                {placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {
          !isDisplay && (
            <FormSectionAdd
              onSave={onSectionAdd}
              activeNuxAction={activeNuxAction}
            />
          )
        }
        {signatureView}
      </div>
    </div>
  );
}

FormBuilder.propTypes = {
  formId: PropTypes.string,
  onSectionsChanged: PropTypes.func,
  onValueFieldChanged: PropTypes.func,
  currentStep: PropTypes.number,
  signatureView: PropTypes.node,
  valueField: PropTypes.string,
  initialSections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  })),
  isDisplay: PropTypes.bool,
  isAdd: PropTypes.bool,
  allowValueFieldSelection: PropTypes.bool,
  style: PropTypes.shape({}),
  isBoardCards: PropTypes.bool,
  formType: PropTypes.shape({}),
  isExternalForm: PropTypes.bool,
  divisions: PropTypes.arrayOf(PropTypes.shape({})),
  templateId: PropTypes.string,
  name: PropTypes.string,
  shouldHideCustomRenderingFields: PropTypes.bool,
  enablePermissions: PropTypes.bool,
  sectionPermissionMap: PropTypes.shape({}),
  children: PropTypes.node,
  isDetailView: PropTypes.bool,
  isTimeCard: PropTypes.bool,
  canAddDynamicAttribute: PropTypes.bool,
  showParentAttributeCheckbox: PropTypes.bool,
  setDisplayFields: PropTypes.func.isRequired,
};

FormBuilder.defaultProps = {
  formId: null,
  onSectionsChanged: () => {},
  onValueFieldChanged: () => {},
  currentStep: 0,
  signatureView: null,
  valueField: '',
  initialSections: defaultSections,
  isDisplay: false,
  isAdd: false,
  allowValueFieldSelection: false,
  style: {},
  isBoardCards: false,
  formType: {},
  isExternalForm: false,
  divisions: [],
  templateId: '',
  name: '',
  shouldHideCustomRenderingFields: false,
  enablePermissions: false,
  sectionPermissionMap: undefined,
  children: null,
  isDetailView: false,
  isTimeCard: false,
  canAddDynamicAttribute: false,
  showParentAttributeCheckbox: false,
};
