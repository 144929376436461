import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { useDrag } from 'react-dnd';

import Permissions from '../../auth/Permissions';
import { getIdMap } from '../../helpers/helpers';
import { getBackgroundColor, getStatusIcon } from '../scheduleHelpers';

export default function ({
  user,
  shift = {},
  onClick,
}) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'MonthShiftDayEntry',
    item: { id: shift?.id, userId: user?.id, shift },
    isDragging: (monitor) => shift?.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: shift?.id === monitor?.getItem()?.id,
    }),
  }));

  const {
    startTime,
    title,
    users = [],
    isEvent,
    color,
    isDraft,
    appliedUsers,
  } = shift;
  const [currentUserStatus, setCurrentUserStatus] = useState();

  useEffect(() => {
    if (appliedUsers && appliedUsers.length === 0) return;
    const shiftStatus = appliedUsers?.find((u) => u.userId === Permissions.id)?.status;
    setCurrentUserStatus(shiftStatus);
  }, [shift, appliedUsers]);

  const timeText = startTime
    ? `${DateTime.fromMillis(startTime).toLocaleString(DateTime.TIME_SIMPLE)}` : '';

  const stateUsers = useSelector((state) => state.users.users);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    onClick(shift);
  }, [shift, onClick]);

  const userMap = useMemo(() => getIdMap(stateUsers), [stateUsers]);
  const isMyShift = useMemo(() => users && users.includes(Permissions.id), [users]);
  const backgroundColor = getBackgroundColor(users, userMap, color, isMyShift, isEvent, isDraft);

  return (
    <div
      className="schedule-month-day-entry"
      onClick={handleClick}
      ref={drag}
      style={{ opacity: (isDragging || isDraft) ? 0.5 : 1 }}
    >
      <div className={`schedule-month-day-entry-dot-${(isMyShift && !isDraft) ? 'mine' : 'other'}`} style={{ backgroundColor }} />
      <span>{timeText} <b>{title}</b> <b>{isDraft ? getStatusIcon(currentUserStatus) : null}</b></span>
    </div>
  );
}
