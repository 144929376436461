import React from 'react';
import PropTypes from 'prop-types';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Col, Form, Row, Select,
} from 'antd';
import { REMINDER_OPTIONS } from './reminder.constants';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import ExternalRecipientsSelector from './ExternalRecipientsSelector';

/**
 * Dynamic field which allows user to add an arbitrary number of inputs to an Antd Form 
 */
const DynamicRemindersField = function ({
  form,
  type = 'reminders',
  isDisplay = false,
  selectedDivisionId,
  selectedProjectId,
}) {
  const defaultReminder = REMINDER_OPTIONS.DEFAULT_REMINDER;
  const selectOptions = Object.values(REMINDER_OPTIONS);

  const title = type === 'reminders' ? 'Reminders' : 'External Reminders';

  return (
    <Form.List name={type}>
      {(fields, { add, remove }) => (
        <Col>
          <Row style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}>
            {title}
          </Row>
          {fields.map((field, index) => (
            <div key={field.key} className="schedule-form-reminder">
              <Row>
                <Col style={{ width: 20, marginTop: 15 }}>
                  { index !== 0 && <MinusCircleOutlined onClick={() => remove(field.name)} /> }
                </Col>
                <Col>
                  <Form.Item
                    name={type === 'externalReminders' ? [field.name, 'value'] : undefined}
                    {...(type === 'reminders' ? field : {})}
                    style={{ marginRight: 4 }}
                    className="schedule-form-reminder-item"
                  >
                    <Select
                      style={{ width: 175, margin: '10px 0px' }}
                      labelInValue
                      defaultValue={defaultReminder}
                      options={selectOptions}
                      disabled={isDisplay}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ width: 500 }}>
                  {type === 'externalReminders' && (
                    <Form.Item
                      name={[field.name, 'recipients']}
                      style={{ marginRight: 4 }}
                      className="schedule-form-reminder-item"
                    >
                      <ExternalRecipientsSelector
                        isDisplay={isDisplay}
                        selectedDivisionId={selectedDivisionId}
                        selectedProjectId={selectedProjectId}
                        reminderValue={form?.current?.getFieldValue([type, field.name, 'value'])?.value}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </div>
          ))}
          <Row>
            <Form.Item>
              <OnTraccrButton
                title="add reminder"
                onClick={() => add()}
                style={{ width: 200, marginLeft: 20 }}
                icon={<PlusOutlined />}
              />
            </Form.Item>
          </Row>
        </Col>
      )}
    </Form.List>
  );
};

DynamicRemindersField.propTypes = {
  type: PropTypes.string.isRequired,
  isDisplay: PropTypes.bool,
  selectedDivisionId: PropTypes.string,
  selectedProjectId: PropTypes.string,
  form: PropTypes.shape({}),
};

DynamicRemindersField.defaultProps = {
  isDisplay: false,
  selectedDivisionId: undefined,
  selectedProjectId: undefined,
  form: undefined,
};

export default DynamicRemindersField;
