/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

export default function TypeOfTimePicker({
  type,
  setType,
  enableManualOT = false,
}) {
  const options = useMemo(() => {
    const parsedOptions = [
      {
        value: 'regular',
        label: 'Regular',
      },
    ];
    if (enableManualOT) {
      parsedOptions.push(...[{
        value: 'ot',
        label: 'OT',
      }, {
        value: 'doubleOT',
        label: 'Double OT',
      }]);
    }
    parsedOptions.push({
      value: 'break',
      label: 'Break',
    });
    return parsedOptions;
  });
  return (
    <Select
      value={type}
      options={options}
      onSelect={setType}
      style={{
        width: '7em',
      }}
    />
  );
}

TypeOfTimePicker.propTypes = {
  type: PropTypes.string,
  setType: PropTypes.func.isRequired,
  enableManualOT: PropTypes.bool,
};

TypeOfTimePicker.defaultProps = {
  type: 'regular',
  enableManualOT: false,
};
