import React, { useMemo } from 'react';
import { Col, Row, Table } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import useToggle from '../common/hooks/useToggle';
import AddExistingBucketModal from './AddExisingBucketDrawer';
import { getBucketColumns } from './bucketHelpers';

export default function BucketList({
  currentTemplateId,
  bucketTemplate,
  selectedBucketIds,
  downstreamKey,
  onAddExistingBucket,
  onRemove,
}) {
  const buckets = useSelector((state) => state.buckets.buckets);
  const equipment = useSelector((state) => state.equipment.equipment);
  const selectedBucketIdSet = useMemo(() => new Set(selectedBucketIds), [selectedBucketIds]);

  const {
    toggle: toggleAddExistingBucketModal,
    isToggled: isAddExistingBucketModalVisible,
  } = useToggle();

  const {
    existingBucketList,
    bucketList,
  } = useMemo(() => (
    downstreamKey?.includes('equipment')
      ? equipment
      : buckets
  ).reduce((acc, bucket) => {
    const {
      id,
      bucketTemplateId,
      upstreamTypes,
    } = bucket;
    if (bucketTemplateId !== bucketTemplate?.id && !downstreamKey?.includes('equipment')) return acc;
    if (selectedBucketIdSet?.has(id)) {
      acc.bucketList.push(bucket);
    } else {
      const relevantType = upstreamTypes?.find((type) => type.includes(currentTemplateId));
      if (
        downstreamKey?.includes('equipment')
        || !relevantType?.includes('one')
        || (bucket[relevantType] && bucket[relevantType].length < 1)
      ) {
        acc.existingBucketList.push(bucket);
      }
    }

    return acc;
  }, {
    existingBucketList: [],
    bucketList: [],
  }), [
    buckets,
    bucketTemplate,
    selectedBucketIdSet,
    currentTemplateId,
    downstreamKey,
    equipment,
  ]);

  const bucketTableColumns = getBucketColumns({
    onRemove,
    downstreamKey,
  });

  const bucketTemplateName = useMemo(() => {
    if (downstreamKey?.includes('equipment')) return 'Equipment';
    if (bucketTemplate?.name) return bucketTemplate.name;
    return 'Bucket';
  }, [bucketTemplate, downstreamKey]);

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <span style={{ fontSize: 15 }}>{ bucketTemplate?.name }</span>
        </Col>
        <Col>
          <OnTraccrButton
            title={`Add Existing ${bucketTemplateName}`}
            icon={<PlusOutlined />}
            type="primary"
            onClick={toggleAddExistingBucketModal}
          />
        </Col>
      </Row>
      <Table
        columns={bucketTableColumns}
        dataSource={bucketList}
        pagination={false}
        size="small"
      />
      <AddExistingBucketModal
        title={`Add Existing ${bucketTemplate?.name}`}
        bucketOptions={existingBucketList}
        visible={isAddExistingBucketModalVisible}
        onClose={toggleAddExistingBucketModal}
        onSave={onAddExistingBucket}
        downstreamKey={downstreamKey}
      />
    </>
  );
}

BucketList.propTypes = {
  bucketTemplate: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  selectedBucketIds: PropTypes.arrayOf(PropTypes.string),
  downstreamKey: PropTypes.string.isRequired,
  onAddExistingBucket: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  currentTemplateId: PropTypes.string.isRequired,
};

BucketList.defaultProps = {
  bucketTemplate: undefined,
  selectedBucketIds: [],
};
