export default {};

export const NONE_EQUIPMENT_TYPE = -1;

export const filterData = ({
  labels = [],
  statuses = {},
  equipmentTypes = [],
}) => ([
  {
    key: 'active',
    label: 'Active/Archived',
    options: [{ value: 1, label: 'Active' }, { value: 0, label: 'Archived' }],
  },
  {
    key: 'availability',
    label: 'Availability',
    options: [
      {
        label: 'Available',
        value: 1,
      },
      {
        label: 'Checked Out',
        value: 0,
      },
    ],
  },
  {
    key: 'status',
    label: 'Status',
    options: Object.values(statuses).map(({ status, id }) => ({
      label: status,
      value: id,
    })),
  },
  {
    key: 'labels',
    label: 'Labels',
    options: labels.map(({ title, id }) => ({
      label: title,
      value: id,
    })),
  },
  {
    key: 'equipmentTypeId',
    label: 'Equipment Type',
    options: equipmentTypes.map(({ name, id }) => ({
      label: name,
      value: id,
    })).filter((type) => !type.isDefault),
  },
]);

export const getDefaultFilters = ({
  labels = [],
  statuses = {},
  equipmentTypes = [],
}) => ({
  active: [1],
  availability: [0, 1],
  labels: labels.map((label) => label.id),
  status: Object.values(statuses).map((status) => status.id),
  equipmentTypeId: equipmentTypes.map((type) => type.id),
  projects: [],
});

