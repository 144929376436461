import React, {
  useCallback, useEffect, useMemo, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import {
  EditOutlined,
  SaveOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';

import AnalyticsHeader from './AnalyticsHeader';
import AnalyticsBody from './AnalyticsBody';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';

import { getProjects } from '../projects/state/projects.actions';
import { getTemplates } from '../forms/state/forms.actions';
import {
  getSavedAnalyticsReports,
  createAnalyticsReport,
  updateAnalyticsReport,
  deleteAnalyticsReport,
  selectSavedReport,
} from './state/analytics.actions';
import { dateRangeToDayDiffs } from './analytics.helpers';

import colors from '../constants/Colors';

const CRUMBS = [{
  text: 'Analytics',
  icon: 'analytics',
}];

const DEFAULT_NAME = 'Untitled';
export default function () {
  const dispatch = useDispatch();

  const reportRef = useRef();

  const savedReports = useSelector((state) => state.analytics.savedReports);
  const selectedReportId = useSelector((state) => state.analytics.selectedReportId);
  const analyticsConfig = useSelector((state) => state.analytics);
  const {
    boardId,
    formTemplateId,
    category,
    dateRange,
    datePreset,
    dateBreakdown,
    fieldBreakdown,
    chartType,
    fieldId,
    aggregate,
    breakdown,
    filters,
    groups = {},
    filterByFieldUpdatedTime = false,
    statusFilter = [],
  } = analyticsConfig;

  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(DEFAULT_NAME);

  const onExport = useCallback(() => {
    if (reportRef && reportRef.current && reportRef.current.export) {
      reportRef.current.export(name);
    }
  }, [reportRef, name]);

  const onOpenReport = useCallback(({ key: reportId }) => {
    dispatch(selectSavedReport({
      id: reportId === DEFAULT_NAME ? null : reportId,
    }));
  }, [dispatch]);

  const onEditClicked = useCallback((e) => {
    e.stopPropagation();
    setEdit(true);
  }, []);
  const onEditBlur = useCallback(() => setEdit(false), []);

  const onTextChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setName(value);
  }, []);

  const onDeleteClicked = useCallback((e) => {
    e.stopPropagation();
    CustomConfirmModal({
      title: `Delete Report '${name}'?`,
      onOk: () => {
        dispatch(deleteAnalyticsReport({
          id: selectedReportId,
        }));
      },
      okText: 'Delete',
    });
  }, [dispatch, selectedReportId, name]);

  const onSaveClicked = useCallback((e) => {
    e.stopPropagation();
    const [startTime, endTime] = dateRangeToDayDiffs(dateRange);
    const payload = {
      title: name,
      startTime,
      endTime,
      datePreset,
      chartType,
      fieldId,
      aggregate,
      breakdown,
      filters,
      groups,
      filterByFieldUpdatedTime,
      statusFilter,
    };
    if (category) {
      payload.category = category;
    } else if (boardId) {
      payload.boardId = boardId;
    } else {
      payload.formTemplateId = formTemplateId;
    }
    if (dateBreakdown) {
      payload.dateBreakdown = dateBreakdown;
    } else {
      payload.fieldBreakdown = fieldBreakdown;
    }
    if (selectedReportId) {
      // Edit
      dispatch(updateAnalyticsReport({
        id: selectedReportId,
        payload,
      }));
    } else {
      // Create
      dispatch(createAnalyticsReport({ payload }));
    }
  }, [
    dispatch,
    selectedReportId,
    name,
    boardId,
    formTemplateId,
    dateRange,
    datePreset,
    dateBreakdown,
    fieldBreakdown,
    chartType,
    fieldId,
    aggregate,
    breakdown,
    filters,
    groups,
    filterByFieldUpdatedTime,
    statusFilter,
    category,
  ]);

  const selectedReport = useMemo(() => savedReports[selectedReportId] ?? {}, [selectedReportId, savedReports]);

  const canSave = useMemo(() => (
    (formTemplateId || boardId || category)
    && fieldId
    && (dateRange || datePreset)
    && (dateBreakdown || chartType === 'pie')
  ), [
    formTemplateId,
    boardId,
    fieldId,
    dateRange,
    datePreset,
    dateBreakdown,
    chartType,
    category,
  ]);

  useEffect(() => {
    if (selectedReport && selectedReport.title) {
      setName(selectedReport.title);
    } else {
      setName(DEFAULT_NAME);
    }
  }, [selectedReport]);

  useEffect(() => {
    dispatch(getSavedAnalyticsReports());
    dispatch(getTemplates());
    dispatch(getProjects());
  }, [dispatch]);

  const titleNode = useMemo(() => {
    const title = edit
      ? (
        <OnTraccrTextInput
          autoFocus
          defaultValue={name}
          onBlur={onEditBlur}
          onChange={onTextChange}
          style={{ width: 250 }}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') onEditBlur();
          }}
        />
      )
      : name;
    return (
      <span>
        <span {...edit ? {} : { id: 'board-crumb-button' }}>
          {title}
        </span>
        {!edit && (
          <span className="analytics-title-button">
            <EditOutlined onClick={onEditClicked} />
          </span>
        )}
        {!edit && canSave && (
          <span className="analytics-title-button">
            <SaveOutlined onClick={onSaveClicked} />
          </span>
        )}
        {!edit && selectedReportId && (
          <span className="analytics-title-button">
            <DeleteOutlined style={{ color: colors.ONTRACCR_RED }} onClick={onDeleteClicked} />
          </span>
        )}
      </span>
    );
  }, [
    name,
    edit,
    selectedReportId,
    onTextChange,
    onEditBlur,
    onEditClicked,
    onSaveClicked,
    onDeleteClicked,
    canSave,
  ]);

  const fullCrumbs = useMemo(() => {
    const reportList = Object.values(savedReports);
    if (reportList.length === 0) {
      return CRUMBS.concat([
        {
          text: titleNode,
        },
      ]);
    }
    return CRUMBS.concat([{
      text: (
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu onClick={onOpenReport}>
              <Menu.Item key={DEFAULT_NAME}>
                New Report
                {' '}
                <span><PlusOutlined /></span>
              </Menu.Item>
              {reportList.map((report) => (
                <Menu.Item
                  key={report.id}
                >
                  {report.title}
                </Menu.Item>
              ))}
            </Menu>
          )}
        >
          {titleNode}
        </Dropdown>

      ),
    }]);
  }, [
    titleNode,
    savedReports,
    onOpenReport,
  ]);

  return (
    <BreadCrumbContainer crumbs={fullCrumbs} bodyStyle={{ display: 'flex', flexDirection: 'column' }}>
      <AnalyticsHeader onExport={onExport} analyticsConfig={analyticsConfig} />
      <AnalyticsBody ref={reportRef} analyticsConfig={analyticsConfig} />
    </BreadCrumbContainer>
  );
}
