import React, { useCallback, useMemo } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateSageDefaultCostcode } from '../../../sage/state/sage.actions';
import { getCCLabel } from '../../../clock/ManualEntry/manualEntryHelpers';

const labelRender = (props) => `${props.label} ${props.detailText}`;

export default function SageDefaultCostcodeSelector({
  version,
  selectedIntegration,
}) {
  const dispatch = useDispatch();
  const integrations = useSelector((state) => state.sage.integrations);
  const costcodes = useSelector((state) => state.costcodes.costcodes);

  const {
    defaultCostcodeId,
    divisions = [],
  } = useMemo(() => (
    integrations.find(({ id }) => id === selectedIntegration?.id) ?? {}
  ), [selectedIntegration, integrations]);

  const divisionSet = useMemo(() => new Set(divisions), [divisions]);

  const onChange = useCallback((newSelected) => {
    if (selectedIntegration) {
      dispatch(updateSageDefaultCostcode(version, selectedIntegration.id, newSelected));
    }
  }, [selectedIntegration, version]);

  const globalSageCostcodes = useMemo(() => costcodes.filter(
    (cc) => !cc.projectId && cc.sageId && divisionSet.has(cc.divisionId),
  ), [costcodes, divisionSet]);

  const costcodeOptions = useMemo(() => globalSageCostcodes.map((cc) => ({
    label: getCCLabel(cc),
    value: cc.id,
  })), [globalSageCostcodes]);

  return (
    <Select
      mode="single"
      onChange={onChange}
      style={{ width: 350 }}
      value={defaultCostcodeId}
      showSearch
      optionFilterProp="label"
      options={costcodeOptions}
      labelRender={labelRender}
      allowClear
    />
  );
}

SageDefaultCostcodeSelector.propTypes = {
  version: PropTypes.string.isRequired,
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
};

SageDefaultCostcodeSelector.defaultProps = {
  selectedIntegration: {},
};
